import { logAnalyticsEventOfVariantList, isAFlavourStrength } from "../analytics/logAnalytics"

export function calculateTotalQuantityFromBasket(basket) {
  return basket.reduce((a, b) => a + (b.basket ?? 0), 0)
}

export function calculateTotalPriceFromBasket(basket) {
  return basket.reduce((a, b) => a + (b.basket ?? 0) * parseFloat(b.price), 0).toFixed(2)
}

export function logAnalyticsEventOfBasketProduct(eventName, product, isFromPdp=false, otherParams={}) {
  const variantList = product?.variants?.map(({title, ...item}) => ({
    ...item,
    product_id: product.id,
    item_strength: isAFlavourStrength(title) ? title : null,
    title: product.title
  })) || [];

  logAnalyticsEventOfBasketVariantList(eventName, variantList, isFromPdp, otherParams);
}

export function logAnalyticsEventOfBasketProductList(eventName, productList, isFromPdp=false, otherParams={}) {
  const variantList = productList?.map((product) => ({
    ...product,
    product_id: product.id,
    display_name: product.title,
  })) || [];

  logAnalyticsEventOfBasketVariantList(eventName, variantList, isFromPdp, otherParams);
}

export function logAnalyticsEventOfBasketVariant(eventName, variant, isFromPdp=false, otherParams={}) {
  logAnalyticsEventOfBasketVariantList(eventName, [variant], isFromPdp, otherParams);
}

export function logAnalyticsEventOfBasketVariantList(eventName, variantList, isFromPdp=false, otherParams={}) {
  logAnalyticsEventOfVariantList(eventName, variantList, {
    ...otherParams,
    screen_name: isFromPdp ? 'shop_pdp' : 'shop',
  })
}