import cx from "classnames";
import React, { useEffect, useState } from "react";
import { isSynchronized } from "../../_helpers/device";
import { StatusBar } from "../StatusBar";

const DeviceDrawer = ({
  device,
  connectedLabel,
  disconnectedLabel,
  className,
  children,
}) => {
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    setIsConnected(isSynchronized(device));
  }, [device]);

  return (
    <div className={cx("device-drawer", className)}>
      <StatusBar
        device={device}
        connectedLabel={connectedLabel}
        disconnectedLabel={disconnectedLabel}
      />
      <div className="device-drawer-body">{children}</div>
    </div>
  );
};

export { DeviceDrawer };
