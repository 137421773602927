export const flagStatus = {
    ACCEPTED: 'accepted',
    UNCHECKED: 'unchecked',
    REFUSED: 'refused'
}

export const appVersionStatus = {
    ENABLED: 'enabled',
    DISABLED: 'disabled',
    NEWVERSION: 'newversion'

}