import isNil from "lodash/isNil";
import axios from 'axios';
import axiosRetry from "axios-retry";
import { requestInterceptor } from "./requestInterceptor";
import { responseInterceptor } from "./responseInterceptor";

class AxiosInstanceHolder {
  static instance = null;

  static set(payload) {
    this.instance = payload;
  }

  static get() {
    if(isNil(this.instance)){
      const client = axios.create();
      requestInterceptor(client)
      responseInterceptor(client)
      axiosRetry(client, { retries: 0 });

      this.instance = client
    }
    return this.instance;
  }
}

export { AxiosInstanceHolder }