import { REQUEST, SUCCESS, FAILURE } from './action-type.util';
import { commonsConstants } from '../_constants';
import isNil from 'lodash/isNil';

export const initialState = {
    loading: false,
    error: null,
    isShownErrorAlert: false,
    things: [],
    deviceUniqueId: null,
    isUpdatingThingsToIoTPlatform: false,
    debug: [],
    debugAnalytics: []
};

export function commonsReducer(state = initialState, action) {
    switch (action.type) {

        case 'SET_DEVICE_ID':{
            return {
                ...state,
                deviceUniqueId: action.payload
            };
        }

        case 'LOGOUT':
        case 'RESET_REDUCER': {
            return {
                loading: false,
                error: null,
                isShownErrorAlert: false,
                things: [],
                deviceUniqueId: null,
                isUpdatingThingsToIoTPlatform: false,
                debug: [],
                debugAnalytics: []
            }
        }

        case REQUEST(commonsConstants.GET_TOKEN):
        case REQUEST(commonsConstants.GET_THINGS):
        case REQUEST(commonsConstants.SET_THINGS):
        case REQUEST(commonsConstants.GET_VENDORS):
        case REQUEST(commonsConstants.GET_GROUP): {

            return {
                ...state,
                loading: true
            };
        }

        case SUCCESS(commonsConstants.GET_TOKEN):
        case SUCCESS(commonsConstants.GET_VENDORS):
        case SUCCESS(commonsConstants.GET_GROUP): {

            return {
                ...state,
                loading: false
            };
        }


        case SUCCESS(commonsConstants.SET_THINGS): {
            //console.log("commonsConstants.SET_THINGS action", action);
            let newThings = state.things;
            let currentThings = state.things;
            const data = action.payload.data;

            if (data) {
                const currentThingIdx = currentThings.findIndex(thing => thing?.uuid === data.uuid && thing?.vendor === data.vendor);

                //console.log('currentThingIdx', currentThingIdx);
                if (currentThingIdx !== null && currentThingIdx !== -1) {
                    const currentPropertyIdx = currentThings[currentThingIdx].properties.findIndex(prop => prop.type === data.property_type);

                    //console.log('currentPropertyIdx', currentPropertyIdx);
                    if (currentPropertyIdx !== null && currentPropertyIdx !== -1) {
                        let currentProperty = currentThings[currentThingIdx].properties[currentPropertyIdx];
                        currentProperty.data = data.property_data;
                        currentThings[currentThingIdx].properties[currentPropertyIdx] = currentProperty;
                    } 
                    // else {
                    //     (currentThings[currentThingIdx].properties).push({property_data: data.property_data, property_type: data.property_type});
                    // }
                }

                newThings[currentThingIdx] = currentThings[currentThingIdx];
            }

            return {
                ...state,
                loading: false,
                things: newThings
            };
        }

        case SUCCESS(commonsConstants.GET_THINGS): {
            // console.log("commonsConstants.GET_THINGS action", action);

            let newThings = state.things;
            (action?.payload?.data).forEach((currentThing) => {
                const currentThings = state.things;
                let currentThingIdx = currentThings.findIndex((el) => el?.uuid === currentThing?.uuid);
                if (currentThingIdx !== null && currentThingIdx !== -1) {
                    newThings[currentThingIdx] = currentThing;
                } else {
                    newThings.push(currentThing)
                }
            });

            return {
                ...state,
                loading: false,
                things: newThings
            };
        }

        case SUCCESS(commonsConstants.ADD_THING): {
            console.log("commonsConstants.ADD_THING action", action);
            let newThings = state.things;
            (action?.payload?.data).forEach((currentThing) => {
                const currentThings = state.things;
                let currentThingIdx = currentThings.findIndex((el) => el?.uuid === currentThing?.uuid);
                if (currentThingIdx !== null && currentThingIdx !== -1) {
                    newThings[currentThingIdx] = currentThing;
                } else {
                    newThings.push(currentThing)
                }
            });

            return {
                ...state,
                things: newThings
            };
        }

        case SUCCESS(commonsConstants.DELETE_THING): {
            console.log("commonsConstants.DELETE_THING action", action);

            let newThings = state.things;
            newThings = newThings.filter(thing => thing.uuid !== action?.payload);
            return {
                ...state,
                things: newThings
            };
        }


        case FAILURE(commonsConstants.GET_TOKEN):
        case FAILURE(commonsConstants.GET_THINGS):
        case FAILURE(commonsConstants.SET_THINGS):
        case FAILURE(commonsConstants.GET_VENDORS):
        case FAILURE(commonsConstants.GET_GROUP): {

            return {
                ...state,
                error: action.error,
                loading: false
            };
        }

        case commonsConstants.SHOW_ERROR: {
            return {
                ...state,
                isShownErrorAlert: action.payload
            }
        }

        case commonsConstants.SET_IS_UPDATING_THINGS_TO_IOT_PLATFORM: {
            return {
                ...state,
                isUpdatingThingsToIoTPlatform: action.payload
            }
        }

        /*************DEBUG *****************/
        case commonsConstants.DEBUG_COMMONS: {

            let newLog = action.payload.log ?? 'Error on logging: null or undefined';
            let newDebug = (!isNil(state.debug) && Array.isArray(state.debug)) ? state.debug : [];
            if (newDebug?.length >= 500){
                newDebug.shift();
            }
            newDebug.push({time: action.payload.time, log: newLog});
            return {
                ...state,
                debug: newDebug
            };
        }
        
        case commonsConstants.RESET_DEBUG_COMMONS: {
            return {
                ...state,
                debug: []
            };
        }

        case commonsConstants.DEBUG_ANALYTICS: {
            let newLog = action.payload.log ?? 'Error on logging: null or undefined';
            let newDebug = (!isNil(state.debugAnalytics) && Array.isArray(state.debugAnalytics)) ? state.debugAnalytics : [];
            if (newDebug?.length >= 500){
                newDebug.shift();
            }
            newDebug.push({time: action.payload.time, log: newLog});
            return {
                ...state,
                debugAnalytics: newDebug
            };
        }

        case commonsConstants.RESET_DEBUG_ANALYTICS: {
            return {
                ...state,
                debugAnalytics: []
            };
        }


        default:
            return state;
    }
}