import React, { useEffect, useState } from "react";
import animationLoading from "../../_assets/lotties/Pairing_Icons_Loading.json";
import animationComplete from "../../_assets/lotties/Pairing_Icons_LoadingComplete.json";
import CustomLottie from "../CustomLottie/CustomLottie";

const STEP_VERIFYING = 0;
const STEP_VERIFIED = 1;

export function AsavLoader({ dictionary, show, hasClosingDelay }) {
  const [isOpen, setIsOpen] = useState(show);
  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(STEP_VERIFYING);

  useEffect(() => {
    setSteps([
      {
        animation: animationLoading,
        loop: true,
        label: dictionary.PAIRING_ASAV_VERIFYING_LABEL || "Verifying",
      },
      {
        animation: animationComplete,
        loop: false,
        label: dictionary.PAIRING_ASAV_VERIFIED_LABEL || "Verified",
        onComplete: () => setIsOpen(false),
      },
    ]);
  }, []);

  useEffect(() => setIsOpen(show), [show]);

  useEffect(() => {
    hasClosingDelay && setCurrentStep(STEP_VERIFIED);
  }, [hasClosingDelay]);

  return (
    <div
      className="pairing-loading-overlay"
      style={{
        display: isOpen ? "block" : "none",
        ...(isOpen && { zIndex: 1054 }),
      }}
    >
      <CustomLottie
        options={{
          loop: steps[currentStep]?.loop,
          autoplay: true,
          animationData: steps[currentStep]?.animation,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={75}
        width={75}
        eventListeners={[
          {
            eventName: "complete",
            callback: steps[currentStep]?.onComplete,
          },
        ]}
        style={{
          position: "absolute",
          top: "30%",
          left: "40%",
        }}
      />
      <span className="pairing-loading-overlay-label">
        {steps[currentStep]?.label}
      </span>
    </div>
  );
}
