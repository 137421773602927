import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import lottie from 'lottie-web'
import { isEqual } from 'lodash'

const CustomLottie = ({
  options,
  eventListeners = [],
  isStopped = false,
  isPaused = false,
  speed = 1,
  segments,
  direction,
  width,
  height,
  ariaRole = 'button',
  ariaLabel = 'animation',
  isClickToPauseDisabled = true,
  title = '',
  style,
}) => {
  const anim = useRef(null);
  const el = useRef(null);
  const prevOptions = useRef(null);

  useEffect(() => {
    const {
      loop,
      autoplay,
      animationData,
      rendererSettings,
    } = options;

    const animOptions = {
      container: el.current,
      renderer: 'svg',
      loop: loop !== false,
      autoplay: autoplay !== false,
      segments: segments !== false,
      animationData,
      rendererSettings,
    };

    if (!prevOptions.current || !isEqual(options.animationData, prevOptions.current.animationData)) {
      if (anim.current) {
        deRegisterEvents(eventListeners);
        destroy();
      }

      anim.current = lottie.loadAnimation({ ...animOptions, ...options });

      registerEvents(eventListeners);
    }

    prevOptions.current = options
  }, [options, eventListeners]);

  useEffect(() => {
    return () => {
      deRegisterEvents(eventListeners);
      destroy();
    }
  }, [])

  useEffect(() => {
    if (isStopped) {
      stop();
    } else if (segments) {
      playSegments();
    } else {
      play();
    }

    pause();
    setSpeed();
    setDirection();
  }, [isStopped, isPaused, speed, segments, direction]);

  const setSpeed = () => {
    anim.current.setSpeed(speed);
  };

  const setDirection = () => {
    anim.current.setDirection(direction);
  };

  const play = () => {
    anim.current.play();
  };

  const playSegments = () => {
    anim.current.playSegments(segments);
  };

  const stop = () => {
    anim.current.stop();
  };

  const pause = () => {
    if (isPaused && !anim.current.isPaused) {
      anim.current.pause();
    } else if (!isPaused && anim.current.isPaused) {
      anim.current.pause();
    }
  };

  const destroy = () => {
    anim.current.destroy();
  };

  const registerEvents = (eventListeners) => {
    eventListeners.forEach((eventListener) => {
      anim.current.addEventListener(eventListener.eventName, eventListener.callback);
    });
  };

  const deRegisterEvents = (eventListeners) => {
    eventListeners.forEach((eventListener) => {
      anim.current.removeEventListener(eventListener.eventName, eventListener.callback);
    });
  };

  const handleClickToPause = () => {
    if (anim.current.isPaused) {
      anim.current.play();
    } else {
      anim.current.pause();
    }
  };

  const getSize = (initial) => {
    let size;

    if (typeof initial === 'number') {
      size = `${initial}px`;
    } else {
      size = initial || '100%';
    }

    return size;
  };

  const lottieStyles = {
    width: getSize(width),
    height: getSize(height),
    overflow: 'hidden',
    margin: '0 auto',
    outline: 'none',
    ...style,
  };

  const onClickHandler = isClickToPauseDisabled ? () => null : handleClickToPause;

  return (
    <div
      ref={el}
      style={lottieStyles}
      onClick={onClickHandler}
      title={title}
      role={ariaRole}
      aria-label={ariaLabel}
      tabIndex="0"
    />
  );
};

CustomLottie.propTypes = {
  eventListeners: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.object.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isStopped: PropTypes.bool,
  speed: PropTypes.number,
  segments: PropTypes.arrayOf(PropTypes.number),
  direction: PropTypes.number,
  ariaRole: PropTypes.string,
  ariaLabel: PropTypes.string,
  isClickToPauseDisabled: PropTypes.bool,
  title: PropTypes.string,
  style: PropTypes.string,
}

export default CustomLottie