import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { routingConstants, aemPages, AnalyticsEvents, AnalyticsFieldNames, AnalyticsScreenEventNames } from '../../_constants';
import { AgeWarningDisclaimer } from '../AgeWarningDisclaimer';
import { CustomInlineAlert, PageSelectorCTA } from '..';
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';
import { store } from '../../_store';
import parse from 'html-react-parser';
import { Webview } from '../../_helpers/webview';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { convertObjectToQueryString } from '../../_helpers/utils';

export const LoginForm = (props) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const { register, handleSubmit, errors, setError, watch } = useForm();
    const { email, password } = watch();

    useEffect(() => {
        // code to run on component mount
    }, [])

    useEffect(() => {

        if (props.loginResult !== null) {
            if (props.loginResult === false) {
                setError("email", { type: "login" });
                setError("password", { type: "login" });
            }
        }
    }, [props.loginResult])

    const onSubmit = data => {
        if (typeof props.onSubmit === 'function') {
            props.onSubmit(data);
        }
    };

    //Custom validation
    const isValidField = (value, type) => {
        let isValidField = true;

        if (!value) {
            isValidField = false;
        } else if (type === 'email' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
            isValidField = false;
        } else if (type === 'password' && value.length < 8) {
            isValidField = false;
        }
        return isValidField;
    }

    //Enable/disabe submit button based on result
    const isFormValid = (email, password) => {
        if (!isValidField(email, 'email')) {
            return false;
        }
        if (!isValidField(password, 'password')) {
            return false;
        }
        return true;
    }

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    }

    const goToResetPassword = () => {
        history.push(buildURI(routingConstants.RESET_PASSWORD));
    }

    
    //let LOGIN_HEADER = props.page?.getComponent(aemPages.LOGIN, 'LOGIN_HEADER');
    let LOGIN_EMAIL_LABEL = props.page?.getComponent(aemPages.LOGIN, 'LOGIN_EMAIL_LABEL');
    let LOGIN_EMAIL_PLACEHOLDER = props.page?.getComponent(aemPages.LOGIN, 'LOGIN_EMAIL_PLACEHOLDER');
    let LOGIN_PASSWORD_LABEL = props.page?.getComponent(aemPages.LOGIN, 'LOGIN_PASSWORD_LABEL');
    let LOGIN_PASSWORD_PLACEHOLDER = props.page?.getComponent(aemPages.LOGIN, 'LOGIN_PASSWORD_PLACEHOLDER');
    let LOGIN_FORGOT_PWD = props.page?.getComponent(aemPages.LOGIN, 'LOGIN_FORGOT_PWD');
    let LOGIN_FAILURE_MESSAGE = props.page?.getComponent(aemPages.LOGIN, 'LOGIN_FAILURE_MESSAGE');
    let LOGIN_PRIVACY_POLICY = props.page?.getComponent(aemPages.LOGIN, 'LOGIN_PRIVACY_POLICY');
    let LOGIN_AGE_DISCLAIMER_TITLE = props.page?.getComponent(aemPages.LOGIN, 'LOGIN_AGE_DISCLAIMER_TITLE');
    let LOGIN_AGE_DISCLAIMER_CONTENT = props.page?.getComponent(aemPages.LOGIN, 'LOGIN_AGE_DISCLAIMER_CONTENT');
    let LOGIN_BTN_LOGIN = props.page?.getComponent(aemPages.LOGIN, 'LOGIN_BTN_LOGIN');
    let LOGIN_AVKO_DISCLAIMER_DETAIL_AGE = props.page?.getComponent(aemPages.LOGIN, 'LOGIN_AVKO_DISCLAIMER_DETAIL_AGE');
    let LOGIN_AVKO_DISCLAIMER_DETAIL = props.page?.getComponent(aemPages.LOGIN, 'LOGIN_AVKO_DISCLAIMER_DETAIL');
    //let LOGIN_BTN_NEED_HELP = props.page?.getComponent(aemPages.LOGIN, 'LOGIN_BTN_NEED_HELP');

    
    let LOGIN_BTN_LOGIN_ctaItems = LOGIN_BTN_LOGIN?.getRaw('ctaItems', null);
    let LOGIN_BTN_LOGIN_ctaLabel = null;

    if(LOGIN_BTN_LOGIN_ctaItems?.length > 0) {
        LOGIN_BTN_LOGIN_ctaLabel = LOGIN_BTN_LOGIN_ctaItems[0].ctaLabel;
    }

    const handleBlurEmail = () => {
        logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.EMAIL})
    }

    const handleBlurPassword = () => {
        logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.PASSWORD})
    }
    
    return (
        <div className="signup-wrapper h-100">
            <form className="signup-form h-100 d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
                <div className="signup-form-wrapper">
                    <div className="signup-form-row">
                        <label className="page-label" htmlFor="loginEmail">{LOGIN_EMAIL_LABEL.getRaw('text', null)}</label>
                        <input name="email"
                            id="loginEmail"
                            className={'form-control' + (errors.email ? ' error' : '')}
                            placeholder={LOGIN_EMAIL_PLACEHOLDER.getRaw('text', null)}
                            type="email"
                            autoComplete="off"
                            ref={register({ required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                            onBlur={handleBlurEmail}
                        /></div>

                    <div className="signup-form-row">
                        <label className="page-label" htmlFor="loginPassword">{LOGIN_PASSWORD_LABEL.getRaw('text', null)}</label>
                        <div className="form-control-toggle">
                            <span id="togglePassswordEye" className={`toggle-icon toggle-pw ${isPasswordVisible ? "show" : "hide"}`} onClick={togglePasswordVisibility}></span>
                            <input name="password"
                                id="loginPassword"
                                autoComplete="new-password"
                                type={isPasswordVisible ? "text" : "password"}
                                className={'form-control' + (errors.password ? ' error' : '')}
                                placeholder={LOGIN_PASSWORD_PLACEHOLDER.getRaw('text', null)}
                                ref={register({ required: true, minLength: 8 })} 
                                onBlur={handleBlurPassword}
                            />
                        </div></div>

                    <div className="signup-form-row">
                        <span className="url-text" onClick={goToResetPassword}>{LOGIN_FORGOT_PWD.getRaw('text', null)}</span>
                    </div>
                    <CustomInlineAlert isShown={props.loginResult === false} alertType="error">
                        {parse(LOGIN_FAILURE_MESSAGE?.getRaw('text', null) ?? '')}
                    </CustomInlineAlert>
                </div>
                <div className="mt-auto">
                    
                    <div className="signup-info" onClick={() => {
                        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsScreenEventNames.PRIVACY_POLICY}); 
                        history.push(buildURI(routingConstants.PRIVACY_POLICY) + convertObjectToQueryString({ canHandleBack: true }), {type: 'slide', backTo: routingConstants.LOGIN})
                        }
                    }>
                    {parse(LOGIN_PRIVACY_POLICY?.getRaw('text', null) ?? '')}
                    </div>
                    
                    <AgeWarningDisclaimer title={LOGIN_AVKO_DISCLAIMER_DETAIL_AGE?.getRaw("text")} content={LOGIN_AVKO_DISCLAIMER_DETAIL?.getRaw("text")} />
                    <PageSelectorCTA>
						<div className="d-grid">
                            {
                                props.isLoading ?
                                    <button className="btn btn-primary text-uppercase" id="logInBtn" type="submit" disabled={!isFormValid(email, password)}
                                        style={{ paddingTop: "0.6rem", paddingBottom: "0.6rem" }}>
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </button>
                                    :
                                    <button className="btn btn-primary text-uppercase" id="logInBtn" type="submit" disabled={!isFormValid(email, password)}>{LOGIN_BTN_LOGIN_ctaLabel}</button>
                            }
                        </div>
                    </PageSelectorCTA>
                </div>
            </form>


        </div>
    );

}