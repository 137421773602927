import { Commons } from "../_helpers/commons";

class errorLogModel {

    errors = null;       

    setError = (externalCustomerId, deviceUUID, device, error) => {
        this.errors = {
            id: Commons.generateRandomNumericId(10),
            deviceName: device?.deviceCustomName ?? null,
            externalCustomerId: null,
            deviceUUID,
            errorCode: error.errorCode,
            errorDescription: error.error,
            errorTimestampUtc: error.timestamp,
            firmwareVersion: device?.deviceInfo?.firmwareVersion ?? null
        };
    }

    payload = () => {
        return this.errors;
    }

}

export { errorLogModel };