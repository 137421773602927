import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { DevicePDP, FlavourPDP } from '.';
import { onboardingActions, shopActions } from '../../_actions';
import { AnalyticsEvents, productType } from '../../_constants';
import { logAnalyticsEventOfVariantList, logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { logAnalyticsEventOfBasketVariantList } from '../../_helpers/basket';
import { CheckoutHelper } from '../../_helpers/checkout';
import { Commons } from '../../_helpers/commons';
import { Notifications } from '../../_helpers/notifications';
import { ShopUtils } from '../../_helpers/shop';

export const ProductPDP = ({
    product,
    isOpen,
    onClosePdp,
    onOpenBasket,
    onStartCheckout,
    wishlist
}) => {

    const dispatch = useDispatch();
    const basketRdx = useSelector(state => state.shopReducer.basket);
    const userPin = useSelector(state => state.onboardingReducer.userPin);



    useEffect(() => {
        if (isOpen) {
            logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
                screen_name: product.title
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    const handleAddToCart = useCallback((thisBasket) => {

        const currentBasket = basketRdx;

        Promise.all(
            thisBasket.map(thisBasketEl => {
                return new Promise((resolve) => {
                    if (
                        currentBasket !== null &&
                        currentBasket !== undefined
                    ) {
                        //get index od variant in basket
                        const basketIdx = currentBasket.findIndex(basketEl => basketEl.variant_id === thisBasketEl.variant_id);
                        if (basketIdx !== -1) {
                            //if variant is present in basket update it with the new one
                            dispatch(shopActions.updateQuantityInBasket(thisBasketEl, resolve));
                        } else {
                            //if variant is not present in basket add to it
                            dispatch(shopActions.addVariantToBasket(thisBasketEl, resolve));
                        }
                    } else {
                        resolve();
                    }

                })
            })

        ).then(() => {
            // after adding all elements in the cart
            logAnalyticsEventOfBasketVariantList(
                AnalyticsEvents.ADD_TO_CART,
                thisBasket,
                true
            )
        })
    }, [basketRdx]);


    const handleToggleFavourite = useCallback((variant) => {
        return new Promise(async (resolve, reject) => {
            let currentWishlist = ShopUtils.getUserWishlist();
            const isRemoveFromWishlist = currentWishlist[variant?.variant_id] !== null && currentWishlist[variant?.variant_id] !== undefined;

            if (isRemoveFromWishlist) {
                delete currentWishlist[variant?.variant_id];
            } else {
                currentWishlist = {
                    ...currentWishlist,
                    [variant?.variant_id]: 1
                }
            }

            //update remote wishlist
            const tenantUserId = Commons.generateTenantUserId(userPin);
            dispatch(shopActions.setWishlist(tenantUserId, JSON.stringify(currentWishlist), () => {

                if (currentWishlist?.length <= 0) {
                    const orderReminderRule = Notifications.getOrderReminderNotificationRule();
                    if (orderReminderRule) {
                        dispatch(onboardingActions.deleteRules(tenantUserId, orderReminderRule.id));
                    }
                }

                //update local wishlist
                const variantInWishlist = {
                    ...variant,
                    product_id: product.id
                }
                dispatch(
                    shopActions.updateVariantInWhishlist(variantInWishlist, () => {
                        const eventName = isRemoveFromWishlist
                            ? AnalyticsEvents.REMOVE_FROM_FAVOURITES
                            : AnalyticsEvents.ADD_TO_FAVOURITES;

                        logAnalyticsEventOfVariantList(eventName, [variantInWishlist]);

                        resolve();
                    })
                );

            }, () => {
                reject();
            }));
        })
    }, [userPin, product]);

    const handleCheckout = (thisBasket) => {
        dispatch(shopActions.replaceBasket(thisBasket, () => handleStartCheckout(thisBasket)));
    }


    const handleStartCheckout = (thisBasket) => {
        if (typeof onStartCheckout === "function") {
            logAnalyticsEvent(AnalyticsEvents.BUY_NOW, {
                screen_name: 'shop_pdp',
                cart_token_event: CheckoutHelper.getANewAnalyticsToken()
            });
            onStartCheckout();
        }
    }

    const handleOpenBasket = () => {
        if(Commons.isValidArray(basketRdx)){
            if (typeof onOpenBasket === "function") {
                onOpenBasket();
            }
        }
    }

    const renderPdp = () => {

        // console.log("[ProductPDP] product", product);

        if (product?.product_type === productType.FLAVOURS) {
            return <FlavourPDP flavour={product}
                isOpen={isOpen}
                onClosePdp={onClosePdp}
                onAddToCart={handleAddToCart}
                onCheckout={handleCheckout}
                wishlist={wishlist}
                onOpenBasket={handleOpenBasket}
                onToggleFavourite={handleToggleFavourite}
            />

        } else {
            return <DevicePDP device={product}
                isOpen={isOpen}
                onClosePdp={onClosePdp}
                onAddToCart={handleAddToCart}
                onCheckout={handleCheckout}
                onOpenBasket={handleOpenBasket}
            />
        }
    }

    return (
        <React.Fragment>
            {renderPdp()}
        </React.Fragment>

    )

}