import cx from "classnames";
import { isNil } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { deviceActions, userActions } from "../../_actions";
import {
  CustomSlidingPanel,
  DeviceCustomName,
  Header,
  PageSelectorCTA,
} from "../../_components";
import {
  AnalyticsEvents,
  aemPages,
  crmUserSettings,
  routingConstants,
  DEVICE_NAME_ALLOWED_CHARS_RE,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import {
  logAnalyticsEvent,
  logAnalyticsEventForDevice,
} from "../../_helpers/analytics/logAnalytics";
import {
  getDeviceInstanceFromSN,
  isAdvertisingNameSupported,
  isSynchronized,
  isValidDeviceName,
} from "../../_helpers/device";
import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";
import { DeviceModel } from "../../_models";
import { SpinnerModal } from "../../_components/SpinnerModal/SpinnerModal";

class DeviceName extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDevice: null,
      deviceName: null,
      isAdvNameSupported: false,
      isSaveSettingsPanelOpen: false,
      saveSettingsError: null,
      spinnerLoading: false,
      spinnerError: false
    };

    this.handleSaveDevicesettings = this._handleSaveDevicesettings.bind(this);
    this.goToDeviceSettings = this._goToDeviceSettings.bind(this);
    this.handleContinue = this._handleContinue.bind(this);

    this.aem = new AEMHelper();
    this.dictionary = {
      ...this.aem.getDictionary(aemPages.DEVICE_SETTINGS, {
        DEVICE_SETT_HEADER: propertyHeadingDefaultEmpty,
        DEVICE_SETT_BTN_SAVE_CHANGES: propertyCtaItemsDefaultEmpty,
        DEVICE_SETT_DEVICE_NAME: propertyTextDefaultEmpty,
        DEVICE_SETT_ADV_NAME_ALERT_TITLE: propertyTextDefaultEmpty,
        DEVICE_SETT_ADV_NAME_ALERT_SUBTITLE: propertyTextDefaultEmptyParsed,
        DEVICE_SETT_ADV_NAME_EMPTY_ERROR: propertyTextDefaultEmptyParsed,
        DEVICE_SETT_ADV_NAME_CHARACTERS_ERROR: propertyTextDefaultEmptyParsed,
        DEVICE_SETT_ADV_NAME_DEVICE_SETTINGS_SUCCESS: propertyTextDefaultEmpty,
        DEVICE_SETT_ADV_NAME_DEVICE_SETTINGS_FAIL: propertyTextDefaultEmpty,
        DEVICE_SETT_ADV_NAME_BTN_CONTINUE: propertyCtaItemsDefaultEmpty,
      }),
    };
  }

  componentDidMount() {
    const device = (this.props.device) ? this.props.device : this.props.devices.find((device) => device.selected)
    
    if (device) {
      this.setState({ currentDevice: device });
    } else {
      history.push(buildURI(routingConstants.DASHBOARD));
      return;
    }
  }

  checkIsAdvNameSupported(device) {
    isAdvertisingNameSupported(device)
      .then((isSupported) => {
        console.debug(
          "DeviceCustonName - isAdvertisingNameSupported",
          isSupported
        );

        this.setState({
          ...this.state,
          isAdvNameSupported: isSupported,
        });
      })
      .catch((err) => console.debug(err));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.devices !== this.props.devices) {
      const device = this.props.devices.find((device) => device.selected);
      //debug(`New device: ${JSON.stringify(device)}`);
      this.setState({ currentDevice: device });
    }

    if (prevState.currentDevice !== this.state.currentDevice) {
      this.checkIsAdvNameSupported(this.state.currentDevice);
    }
  }

  _goToDeviceSettings() {
    const { location } = this.props;

    if (this.props.onClose) {
      this.props.onClose()
    } else if (location?.state?.isFrom) {
      const isClose = routingConstants.DASHBOARD === location?.state?.isFrom

      history.push(buildURI(routingConstants.DEVICE_SETTINGS), {type: 'slide-left', close: isClose, backTo: location?.state?.isFrom});
    } else {
      history.push(buildURI(routingConstants.DEVICE_SETTINGS), {type: 'slide-left', close: true, backTo: routingConstants.DASHBOARD});
    }
  }

  _handleContinue() {
    this.setState(
      {
        ...this.state,
        isSaveSettingsPanelOpen: false,
      },
      () => {
        if (!this.state.saveSettingsError) {
          this.goToDeviceSettings();
        }
      }
    );
  }

  _handleSaveDevicesettings() {
    const { addOrUpdateDevice, setUserSettings } = this.props;

    const { currentDevice, deviceName } =
      this.state;

    this.setState({ spinnerLoading: true })

    // logAnalyticsEvent(AnalyticsEvents.SAVE_CHANGES);
    logAnalyticsEvent(AnalyticsEvents.CLICK, {
      target: AnalyticsTargetEventsNames.SAVE
    });

    /* update device model in reducer */
    getDeviceInstanceFromSN(currentDevice.serialNumber).then(
      async (deviceInstance) => {
        if (null !== deviceInstance && deviceInstance !== undefined) {
          let devicePayload = { ...currentDevice };
          const prevCustomName = currentDevice?.deviceCustomName;
          let customName = deviceName ?? prevCustomName;

          if (isNil(customName)) {
            customName =
              currentDevice?.deviceInfo?.advertisingName ||
              currentDevice?.deviceInfo?.deviceName ||
              "ePod";
          }

          if (!isValidDeviceName(customName)) {
            this.setState({ spinnerLoading: false })
            return;
          }

          devicePayload = {
            ...devicePayload,
            deviceCustomName: customName,
          };

          if (this.state.isAdvNameSupported) {
            try {
              console.debug("ADV_NAME_UPDATE_DEVICE_NAME", customName);
              await deviceInstance.writeAdvName(customName);
              const deviceInfo = await deviceInstance.getDeviceInfo();
              console.debug("ADV_NAME_UPDATE_SUCCESS", deviceInfo);
              devicePayload = {
                ...devicePayload,
                deviceInfo: deviceInfo,
                deviceCustomName: customName,
              };
              console.debug("ADV_NAME_UPDATE_SUCCESS_PAYLOAD", devicePayload);
            } catch (err) {
              console.debug("ADV_NAME_UPDATE_FAILURE", err);
              this.setState({ spinnerLoading: false })
              return this.setState({
                ...this.state,
                isSaveSettingsPanelOpen: true,
                saveSettingsError:
                  this.dictionary.DEVICE_SETT_ADV_NAME_DEVICE_SETTINGS_FAIL,
              });
            }
          }

          addOrUpdateDevice(
            new DeviceModel({
              ...devicePayload,
            }),
            false,
            () => {
              console.debug("ADV_NAME_UPDATE_SUCCESS_REDUCER");
              logAnalyticsEvent(AnalyticsEvents.DEVICE_NAME_CHANGE_SUCCESS)

              setUserSettings(
                crmUserSettings.DEVICE_RENAME,
                JSON.stringify({
                  old: prevCustomName,
                  new: devicePayload.deviceCustomName,
                }),
                () => {
                  this.setState({ spinnerLoading: false })
                  this.setState({
                    ...this.state,
                    isSaveSettingsPanelOpen: true,
                    saveSettingsError: null,
                  });
                  this.goToDeviceSettings();
                },
                () => {
                    this.setState({ spinnerLoading: false })
                    this.setState({
                      ...this.state,
                      isSaveSettingsPanelOpen: true,
                      saveSettingsError: null,
                    });
                    this.goToDeviceSettings();
                }//this.setState({ spinnerError: true })
              );
            }
          );
        }
      }
    );
  }

  render() {
    const {
      currentDevice,
      deviceName,
      isSaveSettingsPanelOpen,
    } = this.state;

    const currentDeviceName = deviceName ?? (currentDevice?.deviceCustomName ?? currentDevice?.deviceInfo?.deviceName)

    const disableButton = currentDeviceName === "" || deviceName === currentDevice?.deviceCustomName || !DEVICE_NAME_ALLOWED_CHARS_RE.test(deviceName) || currentDeviceName !== deviceName

    const disableAll =
      currentDevice === null ||
      currentDevice === undefined ||
      (currentDevice && !isSynchronized(currentDevice));
    return (
      <React.Fragment>
        <div className="device-settings">
          <Header
            leftButton={{
              icon: <span className="bat-icon-back" />,
              onClick: this.goToDeviceSettings,
            }}
          >
            {this.dictionary.DEVICE_SETT_DEVICE_NAME}
          </Header>
          <div className="device-settings-form">
            <DeviceCustomName
              device={currentDevice}
              setDeviceName={(deviceName) => this.setState({ deviceName })}
              deviceName={currentDeviceName}
              placeholder={
                currentDevice?.deviceInfo?.advertisingName ||
                currentDevice?.deviceInfo?.deviceName
              }
              //title={this.dictionary.DEVICE_SETT_DEVICE_NAME}
              isAdvNameSupported={this.state.isAdvNameSupported}
              alertTitle={this.dictionary.DEVICE_SETT_ADV_NAME_ALERT_TITLE}
              alertSubtitle={
                this.dictionary.DEVICE_SETT_ADV_NAME_ALERT_SUBTITLE
              }
              emptyError={this.dictionary.DEVICE_SETT_ADV_NAME_EMPTY_ERROR}
              charactersError={
                this.dictionary.DEVICE_SETT_ADV_NAME_CHARACTERS_ERROR
              }
              disabled={disableAll}
              showMessages={true}
            />
          </div>

          <PageSelectorCTA style={{position: 'fixed', width: '100%'}}>
            <div className="d-grid">
              {
                disableButton ? (
                  <button
                    className="btn btn-primary text-uppercase"
                    disabled={true}
                    onClick={this.handleSaveDevicesettings}
                  >
                    {this.dictionary.DEVICE_SETT_BTN_SAVE_CHANGES_0_ctaLabel}
                  </button>
                ) : (
                  <button
                    className="btn btn-primary text-uppercase"
                    disabled={disableAll}
                    onClick={this.handleSaveDevicesettings}
                  >
                    {this.dictionary.DEVICE_SETT_BTN_SAVE_CHANGES_0_ctaLabel}
                  </button>
                )
              }
            </div>
          </PageSelectorCTA>

          <div className="page-linear-gradient-bg" style={{ zIndex: -1 }}></div>
        </div>

        <CustomSlidingPanel
          from="bottom"
          isOpen={isSaveSettingsPanelOpen && this.state.saveSettingsError}
          overlayClassName={"sliding-common-wrapper"}
          className="onboarding-panel mid-height-panel"
          title={this.dictionary.DEVICE_SETT_HEADER}
          backdropClose={false}
        >
          <div className="process-data-container">
            <div className="process-data-title">
              {this.state.saveSettingsError ||
                this.dictionary.DEVICE_SETT_ADV_NAME_DEVICE_SETTINGS_SUCCESS}
            </div>
            <div className="process-data-image">
              <span
                className={cx({
                  "process-end-icon": !this.state.saveSettingsError,
                  "process-removal": this.state.saveSettingsError,
                })}
              />
            </div>
            <PageSelectorCTA sticky={false} className="mt-auto ps-0 pe-0">
              <div className="d-grid gap-2">
                <button
                  className="btn btn-primary text-uppercase"
                  onClick={this.handleContinue}
                >
                  {this.dictionary.DEVICE_SETT_ADV_NAME_BTN_CONTINUE_0_ctaLabel}
                </button>
              </div>
            </PageSelectorCTA>
          </div>
        </CustomSlidingPanel>

        <SpinnerModal
          show={this.state.spinnerLoading}
          error={this.state.spinnerError}
          download={false}
          onClose={() => this.setState({ spinnerLoading: false, spinnerError: false })}
        />

      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    devices: state.deviceReducer.devices,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addOrUpdateDevice: (devicePayload, addOnTop, callBack) => {
      dispatch(
        deviceActions.addOrUpdateDevice(
          devicePayload.toObj(),
          addOnTop,
          callBack
        )
      );
    },
    setUserSettings: (settingName, value, successCb, failureCb) => {
      dispatch(
        userActions.setUserSettings(settingName, value, successCb, failureCb)
      );
    },
  };
}

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceName);
export { connectedComponent as DeviceName };
