import React, { forwardRef, useEffect, useRef, useState } from "react";
import { getNavbar } from "../../_actions/appConfig.actions";
import {
  AEMHelper,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { aemPages, dashboardTabsConstants } from "../../_constants";
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { AnalyticsEvents, AnalyticsTargetEventsNames } from '../../_constants';

export const NavBar = forwardRef((props, ref) => {
  const [activeTab, setActiveTab] = useState("device");
  const [navbar, setNavbar] = useState([]);
  const [dictionary, setDictionary] = useState({});

  useEffect(() => {
    setNavbar(getNavbar());

    const aem = new AEMHelper();
    setDictionary(
      aem.getDictionary(aemPages.HOMEPAGE, {
        HOME_MENU_VUSEWORLD: propertyTextDefaultEmpty,
        HOME_MENU_STORE: propertyTextDefaultEmpty,
        HOME_MENU_ACCOUNT: propertyTextDefaultEmpty,
        HOME_MENU_MORE: propertyTextDefaultEmpty,
      })
    );
  }, []);

  useEffect(() => {
    if (props.selected) {
      if (props.selected === 'store') {
        window.history.replaceState({ selected: false }, '');
        goToBuyNow()
      }
    }
  }, [props.selected])

  const goToBuyNow = (e) => {
    setActiveTab("store");
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.STORE_LOCATOR});
    props.onNavigate(dashboardTabsConstants.STORE_LOCATOR, 0);
  };

  const goToVuseWorld = (e) => {
    setActiveTab("vuseworld");
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.VUSE_WORLD});
    props.onNavigate(dashboardTabsConstants.VUSE_WORLD);
  };

  const goToDevice = (e) => {
    setActiveTab("device");
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.HOME});
    props.onNavigate(dashboardTabsConstants.DEVICE);
  };

  const goToAccount = (e) => {
    setActiveTab("account");
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.ACCOUNT});
    props.onNavigate(dashboardTabsConstants.ACCOUNT);
  };

  const goToMore = (e) => {
    setActiveTab("more");
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.MORE});
    props.onNavigate(dashboardTabsConstants.MENU);
  };

  useEffect(() => {
    if (props.isMoreMenuOpen) {
      setActiveTab("more")
    }
  }, [props.isMoreMenuOpen]);

  const parseNewLine = ({ text }) => {
    const formattedText = text?.split('\\n').map((line, index, array) => 
      index === array.length - 1 ? line : (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      )
    );
  
    return <>{formattedText}</>;
  };

  return (
    <div ref={ref} className="dashboard-navbar">
      {/*$dashboard-navigator*/}
      <div className="dashboard-navbar-buttons">
        {(navbar.includes("VUSE_WORLD")) && (
          <div
            className={`dashboard-navbar-button ${
              activeTab === "vuseworld" ? "active" : ""
            }`}
            onClick={goToVuseWorld}
          >
            <img
              src={require(`../../_assets/img/navbar/VuseWorld_${
                activeTab === "vuseworld" ? "Active" : "Inactive"
              }.svg`)}
              alt={dictionary.HOME_MENU_VUSEWORLD}
            />
            <div className="label">{parseNewLine({text:dictionary.HOME_MENU_VUSEWORLD})}</div>
          </div>
        )}
        {(navbar.includes("STORE_LOCATOR_AND_SHOP") ||
          navbar.includes("STORE_LOCATOR") ||
          navbar.includes("ONLY_STORE")) && (
          <div
            className={`dashboard-navbar-button ${
              activeTab === "store" ? "active" : ""
            }`}
            onClick={goToBuyNow}
          >
            <img
              src={require(`../../_assets/img/navbar/Store_${
                activeTab === "store" ? "Active" : "Inactive"
              }.svg`)}
              alt={dictionary.HOME_MENU_STORE}
            />
            <div className="label">{parseNewLine({text:dictionary.HOME_MENU_STORE})}</div>
          </div>
        )}
        {(navbar.findIndex(e => e == "ACCOUNT") >-1 && navbar.findIndex(e => e == "ACCOUNT") < 2) && (
          <div
            className={`dashboard-navbar-button ${
              activeTab === "account" ? "active" : ""
            }`}
            onClick={goToAccount}
          >
            <img
              src={require(`../../_assets/img/navbar/Account_${
                activeTab === "account" ? "Active" : "Inactive"
              }.svg`)}
              alt={dictionary.HOME_MENU_ACCOUNT}
            />
            <div className="label">{parseNewLine({text:dictionary.HOME_MENU_ACCOUNT})}</div>
          </div>
        )}
        <div
          className={`dashboard-navbar-button btn-center ${
            activeTab === "device" ? "active" : ""
          }`}
          onClick={goToDevice}
        >
          <img
            src={require(`../../_assets/img/navbar/MYVUSE_Active_Inactive.svg`)}
            alt="Device"
            className="device"
          />
        </div>
        {(navbar.findIndex(e => e == "ACCOUNT") >-1 && navbar.findIndex(e => e == "ACCOUNT") > 1) && (
          <div
            className={`dashboard-navbar-button ${
              activeTab === "account" ? "active" : ""
            }`}
            onClick={goToAccount}
          >
            <img
              src={require(`../../_assets/img/navbar/Account_${
                activeTab === "account" ? "Active" : "Inactive"
              }.svg`)}
              alt={dictionary.HOME_MENU_ACCOUNT}
            />
            <div className="label">{parseNewLine({text:dictionary.HOME_MENU_ACCOUNT})}</div>
          </div>
        )}
        <div
          className={`dashboard-navbar-button ${
            activeTab === "more" ? "active" : ""
          }`}
          onClick={goToMore}
        >
          <img
            src={require(`../../_assets/img/navbar/MoreMenu_${
              activeTab === "more" ? "Active" : "Inactive"
            }.svg`)}
            alt={dictionary.HOME_MENU_MORE}
          />
          <div className="label">{parseNewLine({text:dictionary.HOME_MENU_MORE})}</div>
        </div>
      </div>
    </div>
  );
});
