import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ProductPDP, ShopSorting } from '../../../../_components';
import { aemPages, AnalyticsEvents, shopSortingOptions } from '../../../../_constants';
import { AEMHelper, propertyTextDefaultEmpty } from '../../../../_helpers/aem/aemhelper';
import { logAnalyticsEvent } from '../../../../_helpers/analytics/logAnalytics';
import { logAnalyticsEventOfBasketVariant } from '../../../../_helpers/basket';
import { ShopUtils } from '../../../../_helpers/shop';

export const DevicesProducts = (props) => {

    const loading = useSelector((state) => state.shopReducer.loadingCatalog);

    const [isOpenDevicePDP, setIsOpenDevicePDP] = useState(false);
    const [currentDevice, setCurrentDevice] = useState(null);
    const [thisDevices, setThisDevices] = useState([]);


    const [sortingOption, setSortingOption] = useState(shopSortingOptions.SORTING_A2Z);

    const sortingOptions = [
        shopSortingOptions.SORTING_LOW2HIGH,
        shopSortingOptions.SORTING_HIGH2LOW,
        shopSortingOptions.SORTING_A2Z,
        shopSortingOptions.SORTING_Z2A
    ];

    const applySorting = (sortingOption) => {
        setSortingOption(sortingOption);
        setThisDevices(ShopUtils.sortListFromOption(sortingOption, thisDevices, 'display_name'));
        logAnalyticsEvent(AnalyticsEvents.SHOP_SORT_BY, {
            sort_by: sortingOption
        });
    }

    const toggleDevicePDP = (device) => {
        console.log("device", device);
        const newValue = !isOpenDevicePDP;

        setIsOpenDevicePDP(newValue);

        if (newValue) {
            setCurrentDevice(device);
        } else {
            //dalaying set to null for transition to be shown
            setTimeout(() => {
                setCurrentDevice(null);
            }, 500);
        }
    }

    const [dictionary, setDictionary] = useState({});

    useEffect(() => {
        const aem = new AEMHelper();
        setDictionary(
            aem.getDictionary(aemPages.SHOP, {
                SHOP_DEVICES_TITLE: propertyTextDefaultEmpty,
                SHOP_SORT_BY_LABEL: propertyTextDefaultEmpty,
                SHOP_NO_DEVICES: propertyTextDefaultEmpty
            })
        );
    }, [])

    useEffect(() => {
        console.log("props.devices", props.devices);
        setThisDevices(ShopUtils.sortListFromOption(sortingOption, props.devices, 'display_name'));
    }, [props.devices])


    const onShopListElementClick = (device) => {
        logAnalyticsEventOfBasketVariant(AnalyticsEvents.SELECT_ITEM, device, false);
        toggleDevicePDP(device);
    }

    return (
        <React.Fragment>
            <div className="label-title">
                {dictionary.SHOP_DEVICES_TITLE}
            </div>
            <div className="shop-filter">
                <div className="label-title">
                    {dictionary.SHOP_SORT_BY_LABEL}
                </div>
                <div className="d-flex">
                    <ShopSorting onApplySorting={applySorting}
                        sortingOption={sortingOption}
                        sortingOptions={sortingOptions} />
                </div>
            </div>

            <div className="shop-product-list" style={{
                paddingBottom: props.basket.length > 0 ? '6.90rem' : '1.25rem'
            }}>
                {
                    thisDevices.length > 0 ?

                        thisDevices.map((device, idx) => {

                            let productImg = device?.featured_image;
                            if (productImg === null || productImg === undefined) {
                                productImg = ShopUtils.getProductPropertyFromId(device.product_id, 'featured_image');
                            }
                            return (
                                <div key={`devices-el-${idx}`} className="card"
                                    onClick={() => onShopListElementClick(device)}>
                                    <div className="" style={{
                                        ...(device.gradient && device.gradient !== "" && { background: device.gradient })
                                    }}>
                                        <img src={productImg} className="card-img-top" alt="" />
                                    </div>
                                    <div className="card-body d-flex justify-content-between text-start">
                                        <h5 className="card-title">{device.display_name}</h5>
                                        <h5 className="card-title ps-2">${device.price}</h5>
                                    </div>
                                </div>
                            )
                        })
                        :
                        loading ?

                            <div className="d-flex justify-content-center mt-2">
                                <div className="spinner-border text-secondary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>

                            :

                            <span>{dictionary.SHOP_NO_DEVICES}</span>
                }

            </div>

            <ProductPDP isOpen={isOpenDevicePDP}
                onClosePdp={toggleDevicePDP}
                product={currentDevice}
                onOpenBasket={props.onOpenBasketPanel}
                onStartCheckout={props.onStartCheckout}
            />
        </React.Fragment>
    )
}