import parse from 'html-react-parser';
import {
    imagesConstants,
    MODE_BOLD,
    MODE_BALANCED,
    MODE_SMOOTH,
    PROFILE_EPOD2,
    PROFILE_WAWE2,
    notificationsCategoriesConstants,
    SKIN_COLOR_BLUE,
    SKIN_COLOR_RED,
    SKIN_COLOR_PINK,
    SKIN_COLOR_GOLD,
    SKIN_COLOR_CYAN,
    SKIN_COLOR_REFLEKTA,
    PROFILE_EPOD3,
    SKIN_COLOR_BLACK,
    tipPropertyData
} from '../../_constants';
import React from 'react';
import { getDeviceSpec, isCurrentCountryCanada, isCurrentCountryUK } from '../../_actions/appConfig.actions';
import { isLowPowerModeEnabledBy } from '../device';

export const MAINCOLORS = {
    WARM_YELLOW: "#F8AB28",
    MINT: "#00C7B1",
    RED: "#991F39",
}

export const BACKGROUND = {
    FULL_HEIGHT_WHITE: 'body-bg-white-root-h-100',
    FULL_HEIGHT_GRAY: 'body-bg-gray-root-h-100',
    FULL_HEIGHT_BLACK: 'body-root-h-100',
    BODY_CENTER: 'body-center',
    BODY_USER_PAGE: 'body-user-page',
    BODY_CENTER_WHITE: 'body-center-white',
}

export class Utils {

    static capitalizeSentence(sentence) {
        return sentence.charAt(0).toUpperCase() + sentence.substr(1).toLowerCase();
    }

    static showUkAddressFinder() {
        return isCurrentCountryUK();

    }
    static showCaAddressFinder() {
        return isCurrentCountryCanada();
    }

    static getLabelsFromCloudControlWattage(currentWattage, currentDevice) {

        // console.log("[getLabelsFromCloudControlWattage] currentWattage", currentWattage);
        // console.log("[getLabelsFromCloudControlWattage] currentDevice", currentDevice);

        if (currentWattage && currentDevice) {
            const deviceProfile = currentDevice?.deviceType === PROFILE_EPOD2 ? PROFILE_WAWE2 : currentDevice?.deviceType;
            const deviceSpec = getDeviceSpec(deviceProfile);
            // console.log("[getLabelsFromCloudControlWattage] deviceSpec", deviceSpec);

            const boldMode = deviceSpec?.cloudSize.find(cl => cl.type === MODE_BOLD);
            const balancedMode = deviceSpec?.cloudSize.find(cl => cl.type === MODE_BALANCED);
            const smoothMode = deviceSpec?.cloudSize.find(cl => cl.type === MODE_SMOOTH);
            const lowPowerMode = deviceSpec?.lowPower;

            // console.log("[getLabelsFromCloudControlWattage] boldMode", boldMode);
            // console.log("[getLabelsFromCloudControlWattage] balancedMode", balancedMode);
            // console.log("[getLabelsFromCloudControlWattage] smoothMode", smoothMode);
            // console.log("[getLabelsFromCloudControlWattage] lowPowerMode", lowPowerMode);

            const boldWattage = (boldMode?.value ?? 10) / 10;
            const balancedWattage = (balancedMode?.value ?? 10) / 10;
            const smoothWattage = (smoothMode?.value ?? 10) / 10;
            const lowPowerWattage = (lowPowerMode?.value ?? 10) / 10;

            // console.log("[getLabelsFromCloudControlWattage] boldWattage", boldWattage);
            // console.log("[getLabelsFromCloudControlWattage] balancedWattage", balancedWattage);
            // console.log("[getLabelsFromCloudControlWattage] smoothWattage", smoothWattage);
            // console.log("[getLabelsFromCloudControlWattage] lowPowerWattage", lowPowerWattage);

            return (currentWattage <= boldWattage && currentWattage > balancedWattage) ? { labelcs: boldMode.labelcs, labelfe: boldMode.labelfe, labelbu: boldMode.labelbu, labelHome: boldMode.type }
                : (currentWattage <= balancedWattage && currentWattage > smoothWattage) ? { labelcs: balancedMode.labelcs, labelfe: balancedMode.labelfe, labelbu: balancedMode.labelbu, labelHome: balancedMode.type }
                    : (currentWattage <= smoothWattage && currentWattage > lowPowerWattage) ? { labelcs: smoothMode.labelcs, labelfe: smoothMode.labelfe, labelbu: smoothMode.labelbu, labelHome: smoothMode.type }
                        : (currentWattage === lowPowerWattage) ? { labelcs: lowPowerMode.labelcs, labelfe: lowPowerMode.labelfe, labelbu: lowPowerMode.labelbu, labelHome: 'LOW_POWER' }
                            : { labelcs: "", labelfe: "", labelbu: "", labelHome: "" };
        } else {
            return { labelcs: "", labelfe: "", labelbu: "", labelHome: "" };
        }
    }

    static getBatteryIconFromPercentage(chargeInfo, cloudPower, lowPowerMode) {

        const currentCloudPower = parseInt(cloudPower);

        if (chargeInfo?.charging || currentCloudPower === 25 || lowPowerMode) {
            return "bat-icon-battery-in-charge";
        } else if (chargeInfo?.chargeLevel <= 2) {
            return "bat-icon-battery";
        } else if (chargeInfo?.chargeLevel <= 10) {
            return "bat-icon-battery-10";
        } else if (chargeInfo?.chargeLevel <= 30) {
            return "bat-icon-battery-30";
        } else if (chargeInfo?.chargeLevel <= 100) {
            return "bat-icon-battery-90";
        } else {
            return "";
        }
    }

    static getBatteryCircleColorFromPercentage(chargeInfo, cloudPower) {

        const currentCloudPower = parseInt(cloudPower);

        if (currentCloudPower === 25) {
            return "#ffff";
        } else if (chargeInfo?.chargeLevel < 10) {
            return MAINCOLORS.RED;
        } else if (chargeInfo?.chargeLevel < 30) {
            return MAINCOLORS.WARM_YELLOW;
        } else {
            return MAINCOLORS.MINT;
        }
    }

    static getBatteryColorFromPercentage(chargeLevel) {
        const level = parseInt(chargeLevel);

        if (level <= 10) {
            return MAINCOLORS.RED;
        } else if (level <= 30) {
            return MAINCOLORS.WARM_YELLOW;
        } else {
            return MAINCOLORS.MINT;
        }
    }

    static getMapOptions() {
        return {
            disableDefaultUI: true,
            styles: [
                { "elementType": "geometry", "stylers": [{ "color": "#212121" }] },
                { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] },
                { "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] },
                { "elementType": "labels.text.stroke", "stylers": [{ "color": "#212121" }] },
                { "featureType": "administrative", "elementType": "geometry", "stylers": [{ "color": "#757575" }] },
                { "featureType": "administrative.country", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] },
                { "featureType": "administrative.land_parcel", "stylers": [{ "visibility": "off" }] },
                { "featureType": "administrative.locality", "elementType": "labels.text.fill", "stylers": [{ "color": "#bdbdbd" }] },
                { "featureType": "poi", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] },
                { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#181818" }] },
                { "featureType": "poi.park", "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] },
                { "featureType": "poi.park", "elementType": "labels.text.stroke", "stylers": [{ "color": "#1b1b1b" }] },
                { "featureType": "road", "elementType": "geometry.fill", "stylers": [{ "color": "#2c2c2c" }] },
                { "featureType": "road", "elementType": "labels.text.fill", "stylers": [{ "color": "#8a8a8a" }] },
                { "featureType": "road.arterial", "elementType": "geometry", "stylers": [{ "color": "#373737" }] },
                { "featureType": "road.highway", "elementType": "geometry", "stylers": [{ "color": "#3c3c3c" }] },
                { "featureType": "road.highway.controlled_access", "elementType": "geometry", "stylers": [{ "color": "#4e4e4e" }] },
                { "featureType": "road.local", "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] },
                { "featureType": "transit", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] },
                { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#000000" }] },
                { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#3d3d3d" }] }
            ],
            minZoom: 2.2
        }
    }

    static getIconFromCategory(category, deviceColor, deviceType = false, id = false) {
        switch (category) {
            case notificationsCategoriesConstants.DEVICE_ERROR:
                return {
                    open: imagesConstants.NOTIFICATIONS_PROSMART_FIRMWARE_ERROR,
                    close: imagesConstants.NOTIFICATIONS_PROSMART_FIRMWARE_ERROR_CLOSE
                }
            case notificationsCategoriesConstants.FIRMWARE_UPDATE:
                return {
                    open: imagesConstants.NOTIFICATIONS_PROSMART_FIRMWARE_UPDATE,
                    close: imagesConstants.NOTIFICATIONS_PROSMART_FIRMWARE_UPDATE_CLOSE
                }
            case notificationsCategoriesConstants.SUGGESTIONS:
                let img = ''
                let imgClose = ''
                if (
                    id !== null &&
                    id !== undefined &&
                    id !== ""
                ) {
                    switch (id) {
                        case tipPropertyData.SUGGESTIONS_ENABLE_FIND_MY_VAPE: {
                            img = imagesConstants.NOTIFICATIONS_PROSMART_LOCATION
                            imgClose = imagesConstants.NOTIFICATIONS_PROSMART_LOCATION_CLOSE
                            break
                        }
                        case tipPropertyData.SUGGESTIONS_ENABLE_CLOUD_CONTROL: {
                            img = imagesConstants.NOTIFICATIONS_PROSMART_CLOUD
                            imgClose = imagesConstants.NOTIFICATIONS_PROSMART_CLOUD_CLOSE
                            break
                        }
                        case tipPropertyData.SUGGESTIONS_BATTERYSAVE: {
                            img = imagesConstants.NOTIFICATIONS_PROSMART_BATTERY
                            imgClose = imagesConstants.NOTIFICATIONS_PROSMART_BATTERY_CLOSE
                            break
                        }
                        case tipPropertyData.SUGGESTIONS_USAGE_TRACKER: {
                            img = imagesConstants.NOTIFICATIONS_PROSMART_USAGE
                            imgClose = imagesConstants.NOTIFICATIONS_PROSMART_USAGE_CLOSE
                            break
                        }
                        default: {
                            img = imagesConstants.NOTIFICATIONS_PROSMART_LOGO_GRADIENT
                            imgClose = imagesConstants.NOTIFICATIONS_PROSMART_LOGO_GRADIENT_CLOSE
                            break
                        }
                }
                return {
                    open: img,
                    close: imgClose
                }
            }
            case notificationsCategoriesConstants.BATTERY: {
                let img = ''
                let imgClose = ''
                if (
                    deviceColor !== null &&
                    deviceColor !== undefined &&
                    deviceColor !== ""
                ) {
                    switch (deviceColor) {
                        case SKIN_COLOR_BLUE: {
                            if (deviceType === PROFILE_EPOD2) {
                                img = imagesConstants.NOTIFICATIONS_EPOD2_BATTERY_BLUE
                                imgClose = imagesConstants.NOTIFICATIONS_EPOD2_BATTERY_BLUE_CLOSE
                            } else if (deviceType === PROFILE_EPOD3) {
                                img = imagesConstants.NOTIFICATIONS_PROSMART_BATTERY_BLUE
                                imgClose = imagesConstants.NOTIFICATIONS_PROSMART_BATTERY_BLUE_CLOSE
                            }
                            break;
                        }
                        case SKIN_COLOR_PINK: {
                            if (deviceType === PROFILE_EPOD2) {
                                img = imagesConstants.NOTIFICATIONS_EPOD2_BATTERY_PINK
                                imgClose = imagesConstants.NOTIFICATIONS_EPOD2_BATTERY_PINK_CLOSE
                            } else if (deviceType === PROFILE_EPOD3) {
                                img = imagesConstants.NOTIFICATIONS_PROSMART_BATTERY_PINK
                                imgClose = imagesConstants.NOTIFICATIONS_PROSMART_BATTERY_PINK_CLOSE
                            }
                            break;
                        }

                        case SKIN_COLOR_RED: {
                            if (deviceType === PROFILE_EPOD2) {
                                img = imagesConstants.NOTIFICATIONS_EPOD2_BATTERY_RED
                                imgClose = imagesConstants.NOTIFICATIONS_EPOD2_BATTERY_RED_CLOSE
                            } else if (deviceType === PROFILE_EPOD3) {
                                img = imagesConstants.NOTIFICATIONS_PROSMART_BATTERY_RED
                                imgClose = imagesConstants.NOTIFICATIONS_PROSMART_BATTERY_RED_CLOSE
                            }
                            break;
                        }
                        case SKIN_COLOR_GOLD: {
                            if (deviceType === PROFILE_EPOD2) {
                                img = imagesConstants.NOTIFICATIONS_EPOD2_BATTERY_GOLD
                                imgClose = imagesConstants.NOTIFICATIONS_EPOD2_BATTERY_GOLD_CLOSE
                            } else if (deviceType === PROFILE_EPOD3) {
                                img = imagesConstants.NOTIFICATIONS_PROSMART_BATTERY_GOLD
                                imgClose = imagesConstants.NOTIFICATIONS_PROSMART_BATTERY_GOLD_CLOSE
                            }
                            break;
                        }
                        case SKIN_COLOR_CYAN: {
                            if (deviceType === PROFILE_EPOD2) {
                                img = imagesConstants.NOTIFICATIONS_EPOD2_BATTERY_AQUA
                                imgClose = imagesConstants.NOTIFICATIONS_EPOD2_BATTERY_AQUA_CLOSE
                            } else if (deviceType === PROFILE_EPOD3) {
                                img = imagesConstants.NOTIFICATIONS_PROSMART_BATTERY_AQUA
                                imgClose = imagesConstants.NOTIFICATIONS_PROSMART_BATTERY_AQUA_CLOSE
                            }
                            break;
                        }
                        case SKIN_COLOR_BLACK: {
                            if (deviceType === PROFILE_EPOD2) {
                                img = imagesConstants.NOTIFICATIONS_EPOD2_BATTERY_BLACK
                                imgClose = imagesConstants.NOTIFICATIONS_EPOD2_BATTERY_BLACK_CLOSE
                            } else if (deviceType === PROFILE_EPOD3) {
                                img = imagesConstants.NOTIFICATIONS_PROSMART_BATTERY_BLACK
                                imgClose = imagesConstants.NOTIFICATIONS_PROSMART_BATTERY_BLACK_CLOSE
                            }
                            break;
                        }
                        case SKIN_COLOR_REFLEKTA: {
                            img = imagesConstants.NOTIFICATIONS_EPOD2_BATTERY_REFLEKTA
                            imgClose = imagesConstants.NOTIFICATIONS_EPOD2_BATTERY_REFLEKTA_CLOSE
                            break;
                        }
                        default:
                            break;
                    }
                }
                return {
                    open: img,
                    close:  imgClose
                }
            }
            /*case notificationsCategoriesConstants.DEVICE_STATUS:
            case notificationsCategoriesConstants.ORDER_REMINDER:
            case notificationsCategoriesConstants.ORDER_TRACKING:
            case notificationsCategoriesConstants.SUBSCRIPTIONS:
            case notificationsCategoriesConstants.FLAVOUR_FOR_YOU:
            case notificationsCategoriesConstants.MARKETING:*/
            default:
                return {
                    open: imagesConstants.NOTIFICATIONS_PROSMART_LOGO_BLACK,
                    close: imagesConstants.NOTIFICATIONS_PROSMART_LOGO_BLACK_CLOSE
                }
        }

    }

    static stringReplacePlaceholders(thisString = "", items = [], needToBeParsed = false) {

        if (!Array.isArray(items)) {
            items = [items];
        }

        let idx = 0;
        const finalString = thisString.replaceAll(/\$\d+/g, () => {
            const value = items[idx];
            idx++;
            return value;
        })

        return needToBeParsed ? parse(finalString) : finalString;
    }

    static stringReplacePlaceholdersFromConstants(thisString = "", items = {}, needToBeParsed = false) {

        const finalString = thisString.replaceAll(/[[a-zA-Z]+(?:_[a-zA-Z]+)*]/gm, (match) => {
            if (match) {
                const value = items[match];
                if (value) {
                    return value;
                }
                return "";
            }
            return "";
        })

        return needToBeParsed ? parse(finalString) : finalString;
    }

    /**
     * Unused method, can be useful in future
     * @param {*} videoUrl 
     */
    static preloadVideo(videoUrl) {
        const links = document.body.getElementsByTagName('link');
        console.log("links", links);
        if (links) {

            let found = false
            for (let link of links) {
                console.log("link.href", link.href);
                console.log("videoUrl", videoUrl);
                if (link.href === videoUrl) {
                    found = true;
                }
            }
            console.log("found", found)

            if (!found) {
                console.log("xxx", document.body.getElementsByTagName('link'))
                const link = document.createElement('link');

                const attributesAndValues = [{ key: 'rel', value: 'preload' }, { key: 'as', value: 'video' }, { key: 'type', value: "video/mp4" }];
                attributesAndValues.forEach(item => {
                    link.setAttribute(item.key, item.value);
                });
                link.href = videoUrl;

                document.body.appendChild(link);
            }

        }
    }
}