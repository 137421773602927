export const deviceConstants = {
  SET_DEVICE_PROFILE: "device/SET_DEVICE_PROFILE",
  SET_DEVICES: "device/SET_DEVICES",
  SET_DEVICE_INFO: "device/SET_DEVICE_INFO",
  SET_BATTERY_INFO: "device/SET_BATTERY_INFO",
  SET_LOCK_INFO: "device/SET_LOCK_INFO",
  SET_CLOUD_INFO: "device/SET_CLOUD_INFO",
  SET_CONNECTED: "device/SET_CONNECTED",
  SET_PAIRED: "device/SET_PAIRED",
  SET_ERROR: "device/SET_ERROR",
  SET_CONNECTING: "device/SET_CONNECTING",
  SET_CONNECTION_TIMED_OUT: "device/SET_CONNECTION_TIMED_OUT",
  SET_ERROR_LOG: "device/SET_ERROR_LOG",
  SET_FIRMWARE_VERSION: "device/SET_FIRMWARE_VERSION",
  SET_FLASH: "device/SET_FLASH",
  SET_LOCK: "device/SET_LOCK",
  SET_CLOUD: "device/SET_CLOUD",
  SET_TIMER: "device/SET_TIMER",
  SET_BATTERY: "device/SET_BATTERY",
  SET_LED: "device/SET_LED",

  ADD_UPDATE_DEVICE: "device/ADD_UPDATE_DEVICE",
  ADD_UPDATE_DEVICE_FIRST: "device/ADD_UPDATE_DEVICE_FIRST",
  SET_DEVICE_TYPE: "device/SET_DEVICE_TYPE",
  SET_INSTANCE: "device/SET_INSTANCE",
  SET_DEVICE_CUSTOM_NAME: "device/SET_DEVICE_CUSTOM_NAME",
  SET_DEVICE_ID: "device/SET_DEVICE_ID",
  SET_DEVICE_COLOR: "device/SET_DEVICE_COLOR",
  SET_SELECTED: "device/SET_SELECTED",
  REMOVE_SELECTED: "device/REMOVE_SELECTED",

  PROFILE_EPOD2: "ePod2",
  PROFILE_EPOD3: "ePod3",
  RESET_DEVICES: "device/RESET_DEVICES",
  REMOVE_DEVICE: "device/REMOVE_DEVICE",

  DEBUG: "device/DEBUG",
  DEBUG_ANALYTICS: "device/DEBUG_ANALYTICS",
  RESET_DEBUG: "device/RESET_DEBUG",
  RESET_DEBUG_ANALYTICS: "device/RESET_DEBUG_ANALYTICS",
};

export const MODE_BOLD = "BOLD";
export const MODE_BALANCED = "BALANCED";
export const MODE_SMOOTH = "SMOOTH";
export const MODE_LOWPOWER = "LOWPOWER";

export const SKIN_COLOR_BLACK = "SKIN_COLOR_BLACK";
export const SKIN_COLOR_RED = "SKIN_COLOR_RED";
export const SKIN_COLOR_BLUE = "SKIN_COLOR_BLUE";
export const SKIN_COLOR_CYAN = "SKIN_COLOR_CYAN";
export const SKIN_COLOR_PINK = "SKIN_COLOR_PINK";
export const SKIN_COLOR_GOLD = "SKIN_COLOR_GOLD";
export const SKIN_COLOR_REFLEKTA = "SKIN_COLOR_REFLEKTA";

export const deviceColorClass = {
  SKIN_COLOR_BLACK: "skin-black",
  SKIN_COLOR_BLUE: "skin-blue",
  SKIN_COLOR_CYAN: "skin-cyan",
  SKIN_COLOR_GOLD: "skin-gold",
  SKIN_COLOR_PINK: "skin-pink",
  SKIN_COLOR_RED: "skin-red",
  SKIN_COLOR_REFLEKTA: "skin-reflekta",
};

export const PROFILE_EPOD2 = "ePod2";
export const PROFILE_EPOD3 = "ePod3";
export const PROFILE_WAWE2 = "EpodWave2";

export const DEVICE_NAME_MAX_CHARS = 14;
export const DEVICE_NAME_ALLOWED_CHARS_RE = /^[\x20-\x23\x25-\x7D]+$/;
