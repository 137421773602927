import React, { useRef, useState } from 'react';
import Picker from '../WheelPicker';
import { AnalyticsEvents, AnalyticsFieldNames, AnalyticsTargetEventsNames } from '../../_constants';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { NeedHelpPanel } from '../NeedHelpPanel';
import { PageSelectorCTA } from '../PageSelectorCTA';

export const VaultSetupPin = (props) => {
    const {
        dictionary
    } = props;

    const [firstDigitPin, setFirstDigitPin] = useState(null);
    const [secondDigitPin, setSecondDigitPin] = useState(null);
    const [thirdDigitPin, setThirdDigitPin] = useState(null);
    const [fourthDigitPin, setFourthDigitPin] = useState(null);

    const digitRegex = /^[0-9]$/;


    const itemsRef = useRef([]);

    const handleBlur = () => {
        if(checkIsValidPin()){
            logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.PIN})
        }
    }

    const renderMultiplePicker = (valueGroups, onChangeMethod, idx) => {
        return (
            <div className="col-3">
                <input
                    ref={el => itemsRef.current[idx] = el}
                    className={`form-control vault-pin ${valueGroups !== null && valueGroups !== "" ? "success" : ""}`}
                    placeholder="--"
                    value={valueGroups}
                    type="number"
                    step="1"
                    pattern="[0-9]{1}"
                    min="0" max="9"
                    digitOnly
                    inputmode="numeric"
                    onKeyDown={(event) => {
                        const isBackspace = event.key === "Backspace";
                        if (digitRegex.test(event?.target?.value) && !isBackspace) {
                            event.preventDefault();
                        }
                    }}
                    onKeyUp={(event) => {
                        const isBackspace = event.key === "Backspace";
                        if(isBackspace){
                            handleFocusOnPrevDigit(idx);
                        } else {
                            handleFocusOnNextDigit(idx);
                        }
                    }}
                    onChange={(e) => {
                        if (e?.target?.value) {
                            let newValue = e.target.value;
                            newValue = newValue.toString().charAt(0);
                            newValue = parseInt(newValue);
                            onChangeMethod(newValue);
                        } else {
                            onChangeMethod(e.target.value);
                        }
                    }}
                    onBlur={handleBlur}
                />

                {/* <div className={`ios-picker ${valueGroups.digit !== "--" && "success"}`}>
                    <Picker
                        optionGroups={optionGroups}
                        valueGroups={valueGroups}
                        onChange={(label, value) => onChangeMethod({ [label]: value })}
                        itemHeight={digitHeight}
                        height={pickerHeight}
                        highlightHeight={highlightHeight} />
                </div> */}
            </div>
        )
    }

    const handleFocusOnNextDigit = (idx) => {
        if(itemsRef.current && itemsRef.current[idx] && itemsRef.current[idx]?.value){
            for (let i = idx + 1; i < itemsRef.current.length; i++) {
                if(itemsRef.current[i]){
                    if(!itemsRef.current[i]?.value){
                        itemsRef.current[i].focus();
                        break;
                    }
                }
            }
        }
    }
    
    const handleFocusOnPrevDigit = (idx) => {
        if(idx <= 0) {
            return;
        }

        const prevIndex = idx - 1;
        if(itemsRef.current && itemsRef.current[prevIndex]) {
            itemsRef.current[prevIndex].focus();
        }
    }

    const checkIsValidPin = () => {
        return digitRegex.test(firstDigitPin)
            && digitRegex.test(secondDigitPin)
            && digitRegex.test(thirdDigitPin)
            && digitRegex.test(fourthDigitPin);
    }

    const persistPin = () => {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CREATE_PIN});

        const newFirstDigit = parseInt(firstDigitPin);
        const newSecondDigit = parseInt(secondDigitPin);
        const newThirdDigit = parseInt(thirdDigitPin);
        const newFourthDigit = parseInt(fourthDigitPin);
        
        props.persistPin(`${newFirstDigit}${newSecondDigit}${newThirdDigit}${newFourthDigit}`);
        props.nextStep();
    }

    return (

        <div className="mt-auto">

            <div className="vault-wrapper">
                <div className="page-label">{props.isResetPinFlow ? dictionary.VAULT_RESET_PIN_TITLE : dictionary.VAULT_STEP_PIN_TITLE}</div>

                <div className="vault-ios-picker">
                    <div className="container container-vault-ios-picker">
                        <div className="row">
                            {
                                [
                                    { digit: firstDigitPin, setMethod: setFirstDigitPin },
                                    { digit: secondDigitPin, setMethod: setSecondDigitPin },
                                    { digit: thirdDigitPin, setMethod: setThirdDigitPin },
                                    { digit: fourthDigitPin, setMethod: setFourthDigitPin },
                                ].map((el, idx) =>
                                    renderMultiplePicker(el.digit, el.setMethod, idx)
                                )

                            }
                        </div>
                    </div>
                </div>

                <div className="vault-ios-picker-disclaimer d-flex align-items-center gap-2">
                    <span className="bat-icon-alert-circle" />
                    {props.isResetPinFlow ? dictionary.VAULT_RESET_PIN_AFTER_FORGETTING : dictionary.VAULT_FIRST_TIME_USER_CREATE_PIN}
                </div>
            </div>
            <PageSelectorCTA sticky={false} className="mt-5">
                <div className="d-grid">
                    <button className="btn btn-primary text-uppercase" onClick={persistPin}
                        disabled={!checkIsValidPin()}>{dictionary.VAULT_BTN_CONFIRM_PIN_0_ctaLabel}</button>
                </div>
                <div className="page-selector-cta-url">
                    <NeedHelpPanel
                        link={dictionary.VAULT_PIN_LINK}
                        title={dictionary.VAULT_INFO_HEADER}
                        body={dictionary.VAULT_INFO_CONTENT}
                        analyticsPin={true}
                    />
                </div>
            </PageSelectorCTA>
        </div>

    );
}