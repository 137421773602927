import React, { useEffect, useState } from "react";
import { useRef } from "react";

export const TUTORIAL_DISAPPEAR_TIMEOUT = 2500;

export function TutorialTip({
  className,
  show,
  hideAfter,
  content,
  onTimeout,
}) {
  const [isVisible, setIsVisible] = useState(show);
  const tId = useRef(null);

  useEffect(() => {
    tId.current =
      isVisible &&
      setTimeout(() => {
        setIsVisible(false);
        onTimeout && onTimeout();
      }, hideAfter || TUTORIAL_DISAPPEAR_TIMEOUT);

    return () => {
      clearTimeout(tId.current);
      tId.current = null;
    };
  }, [hideAfter, onTimeout, isVisible]);

  useEffect(() => {
    setIsVisible(show);
  }, [show]);

  return isVisible ? (
    <div className={className || "dashboard-carousel-tip"}>{content}</div>
  ) : null;
}
