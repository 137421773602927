
export const Events = {
    PAGE_VIEW:                  'page_view',
    APP_ERROR:                  'app_error',
    LOGIN:                      'login',
    SIGN_UP:                    'sign_up',
    SKIP_PAIRING:               'skip_pairing',
    RETRY_PAIRING:              'retry_pairing',
    PREFERENCES_UPDATE:         'preferences_update',
    FLAVOURS_SKIP:              'flavours_skip',
    ADD_TO_WISHLIST:            'add_to_wishlist',
    VIEW_ITEM:                  'view_item',
    VAPE_MOMENT_START:          'vape_moment_start',
    VAPE_MOMENT_STOP:           'vape_moment_stopped',
    VAPE_MOMENT_COMPLETE:       'vape_moment_completed',
    DEVICE_LOCK:                'device_lock',
    DEVICE_UNLOCK:              'device_unlock',
    VIEW_PROMO:                 'view_promotion',
    SELECT_PROMO:               'select_promotion',
    CONTROLS_UPDATE_MODE:       'controls_update_mode',
    CONTROLS_UPDATE_SETTINGS:   'controls_update_setting',
    VAPE_TIMER_UPDATE:          'vape_timer_update',
    ENABLE_LOCALIZATION:        'enable_localization',
    VIEW_ITEM_LIST:             'view_item_list',
    SELECT_ITEM:                'select_item',
    LOGOUT:                     'logout',
    VIEW_CART:                  'view_cart',
    ADD_TO_CART:                'add_to_cart',
    REMOVE_FROM_CART:           'remove_from_cart',
    BEGIN_CHECKOUT:             'begin_checkout',
    PURCHASE:                   'purchase',
    PAIRING_COMPLETED:          'pairing_completed',
    NOTIFICATION_UPDATE:        'notification_update',
    FIND_MY_VAPE:               'find_my_epod',

    ENABLE_USAGE_TRACKER:       'enable_usage_tracker',
    ENABLE_POD_TRACKER:         'enable_pod_tracker',

    ENABLE_AUTOMATION:          'enable_automation',
    DISABLE_AUTOMATION:         'disable_automation',
    FIND_MY_VAPE_UPDATE:        'find_my_vape_update',

    ENABLE_POD_THRESHOLD:       'enable_pod_threshold',
    DISABLE_POD_THRESHOLD:      'disable_pod_threshold',
    ENABLE_FLAVOURS_FOR_YOU:    'enable_flavours_for_you',

    SELECT_ACTION:              'select_action',
    SELECT_EVENT:               'select_event',
    COMPLETE_AUTOMATION:        'complete_automation',
    DELETE_AUTOMATION:          'delete_automation',
}

export const AnalyticsEvents = {
    SCREEN_VIEW:                        'screen_view',
    COUNTRY_SELECTOR:                   'country_selector',
    PROVINCE_SELECTOR:                  'province_selector',
    LANGUAGE_SELECTOR:                  'language_selector',
    FORM_CHANGED:                       'form_changed',
    LOG_IN:                             'log_in',
    LOG_IN_SUCCESS:                     'log_in_success',
    LOG_IN_FAILURE:                     'log_in_failure',
    ACCEPT_TERMS_CONDITIONS:            'accept_terms_conditions',
    CREATE_ACCOUNT:                     'create_account',
    PAIR_YOUR_DEVICE:                   'pair_your_device',
    BUY_A_DEVICE:                       'buy_a_device',
    MY_DEVICE_IS_BLINKING:              'my_device_is_blinking',
    DEVICE_PAIRING_SUCCESS:             'device_pairing_success',
    DEVICE_PAIRING_FAILURE:             'device_pairing_failure',
    DEVICE_MSS_SUCCESS:                 'device_mms_success',
    DEVICE_MSS_FAILURE:                 'device_mms_failure',
    SLIDE_TO_UNLOCK:                    'slide_to_unlock',
    CREATE_PIN:                         'create_pin',
    CONFIRM_PIN:                        'confirm_pin',
    NOTIFICATION_PREFERENCES:           'notification_preferences',
    SECURITY_ANSWER:                    'security_answer',
    ENABLE_ALL_NOTIFICATIONS:           'enable_all_notifications',
    CLOUD_CONTROL:                      'cloud_control',
    TAKE_ME_TO_MYVUSE:                  'take_me_to_myvuse',
    CLOUD_STRENGTH:                     'cloud_strength',
    MODE_LOWPOWER:                      'low_power_mode',
    LOW_BATTERY_ALERT:                  'low_battery_alert',
    CHECK_BATTERY_ALERT:                'check_battery',
    FULL_BATTERY_ALERT:                 'full_battery_alert',
    LOW_POWER_MODE:                     'low_power_mode',
    // TURN_BLUETOOTH_ON:                  'turn_bluetooth_on',
    FIND_MY_VAPE:                       'find_my_vape',
    TUTORIAL_BATTERY:                   'tutorial_battery',
    BATTERY:                            'battery',
    SAVE_NOTIFICATION_PREFERENCES:      'save_notification_preferences',
    SAVE_NEWS_UPDATES_PREFERENCES:      'save_news_updates_preferences',
    PING_DEVICE:                        'ping_device',
    I_HAVE_FOUND_MY_DEVICE:             'i_have_found_my_device',
    ENABLE_FIND_MY_VAPE:                'enable_find_my_vape',
    HOW_IT_WORKS_FINDMYVAPE_TUTORIAL:   'how_it_works_findmyvape_tutorial',
    ADVANCED_SETTINGS:                  'advanced_settings',
    SAVE_CHANGES:                       'save_changes',
    REMOVE_DEVICE:                      'remove_device',
    RESET_DATA:                         'reset_data',
    I_AM_SURE_REMOVE_DEVICE:            'i_am_sure_remove_device',
    CANCEL_REMOVE_DEVICE:               'cancel_remove_device',
    DELETE_PREFS_REMOVE_DEVICE:         'delete_prefs_remove_device',
    SKIP_PREFS_REMOVE_DEVICE:           'keep',
    SUCCESSFULLY_REMOVE_DEVICE:         'successfully_remove_device',
    FINISH_REMOVE_DEVICE:               'finish_remove_device',
    FACTORY_RESET_ATTENTION:            'factory_reset_attention',
    CANCEL_RESET_PREFERENCES:           'cancel_reset_preferences',
    DELETE_PREFS_RESET_PREFERENCES:     'delete_prefs_reset_preferences',
    SKIP_PREFS_RESET_PREFERENCES:       'keep',
    SUCCESSFULLY_RESET_PREFERENCES:     'successfully_reset_preferences',
    FINISH_RESET_PREFERENCES:           'finish_reset_preferences',
    APP_ERROR:                          'app_error',
    COMPLETE_HAGV:                      'complete_hagv',
    CALL_SUPPORT:                       'call_support',
    RETRY_HAGV:                         'retry_hagv',
    //EDIT_DETAILS:                       'edit_details', --> old
    CHANGE_PASSWORD:                    'change_password',
    DELETE_ACCOUNT:                     'delete_account',
    DOWNLOAD_FIRMWARE_UPDATE:           'download_firmware_update',
    DOWNLOAD_LATER_FIRMWARE_UPDATE:     'download_later_firmware_update',
    DOWNLOAD_COMPLETE_FIRMWARE_UPDATE:  'download_complete_firmware_update',
    DOWNLOAD_FAILED_FIRMWARE_UPDATE:    'download_failed_firmware_update',
    RECONNECT_MY_VAPE:                  'reconnect_my_vape',
    RETRY_FIRMWARE_UPDATE:              'retry_firmware_update',
    NEED_SOME_HELP:                     'need_some_help',
    EMAIL:                              'email',
    IN_APP:                             'in_app',
    SHOP_TAB_SELECTION:                 'shop_tab_selection',
    SHOP_SORT_BY:                       'shop_sort_by',
    ADD_TO_CART:                        'add_to_cart',
    VIEW_CART:                          'view_cart',
    REMOVE_FROM_CART:                   'remove_from_cart',
    BEGIN_CHECKOUT:                     'begin_checkout',
    BUY_NOW:                            'buy_now',
    SELECT_ITEM:                        'select_item',
    VIEW_ITEM_LIST:                     'view_item_list',
    CREATE_ACCOUNT_SUCCESS:             'create_account_success',
    REORDER:                            'reorder',
    SORT_BY:                            'sort_by',
    ORDER_ALL_FAVOURITES:               'order_all_favourites',
    MANAGE_FAVOURITES:                  'manage_favourites',
    SAVE_PREFERENCES_MANAGE_FAVOURITES: 'save_preferences_manage_favourites',
    REMIND_ME_TO_ORDER:                 'remind_me_to_order',
    SAVE_PREFERENCES_REMING_TO_ORDER:   'save_preferences_reming_to_order',
    ADD_FAVOURITES_NO_PAST_ORDERS:      'add_favourites_no_past_orders',
    ADD_FAVOURITES_VIEW_FLAVOURS:       'add_favourites_view_flavours',
    ENABLE_FLAVOUR_FAVOURITE:           'enable_flavour_favourite',
    SAVE_PREFERENCES:                   'save_preferences',
    ENABLE_FFY:                         'enable_ffy',
    REMOVED_FFY:                        'removed_ffy',
    REMOVE_FROM_FAVOURITES:             'remove_from_favourites',
    ADD_TO_FAVOURITES:                  'add_to_favourites',
    START_NEW_SUBSCRIPTION:             'start_new_subscription',
    VIEW_SUBSCRIPTION:                  'view_subscription',
    EDIT_SUBSCRIPTION:                  'edit_subscription',
    VW_CLICK_STORY_CATEGORY:            'vw_click_story_category',
    VW_CLICK_STORY_ITEM:                'vw_click_story_item',
    VW_SCROLL_CAROUSEL:                 'vw_scroll_carousel',
    VW_CLICK_CAROUSEL_ITEM:             'vw_click_carousel_item',
    NOTIFICATION_HISTORY_OPEN:          'notification_history_open',
    NOTIFICATION_IN_APP_OPEN:           'notification_in_app_open',
    NOTIFICATION_IN_APP_DISPLAYED:      'notification_in_app_displayed',
    NOTIFICATION_OPEN_WEB:              'notification_open_web',
    NOTIFICATION_OS_PERMISSION:         'push_notification_os_permission',
    NOTIFICATION_PWA_PERMISSION:        'push_notification_pwa_permission',
    TAP_HERE_TO_ADD_ANOTHER_DEVICE:     'tap_here_to_add_another_device',
    SWIPE_TO_SWITCH_DEVICE:             'swipe_to_switch_the_device',
    TAP_TO_REARRANGE_DEVICES:           'tap_to_select_rearrange_and_manage_dev',
    PAIR_DEVICE_CARD:                   'pair_device_card',
    REMOVE_DEVICE_CARD:                 'remove_device_card',
    HOLD_AND_DRAG_TO_REARRANGE_DEVICES: 'hold_and_drag_to_rearrange_devices',
    OPEN_DEVICE_SWITCHER:               'open_device_switcher',
    SORT_DEVICE:                        'sort_device',
    MANAGE_DEVICES_SELECT:              'device_selected',
    // DEVICE_TYPE_SELECTED:               'device_type_selected',
    START_ASAV:                         'start_asav',
    DEVICE_ALREADY_UNLOCKED:            'device_already_unlocked',
    ASAV_SUCCESS:                       'asav_success',
    ASAV_FAILURE:                       'asav_failure',
    ASAV_TIMEOUT:                       'asav_timeout',
    ASAV_RETRY:                         'asav_retry',
    ENABLE_USAGE_TRACKER:               'enable_usage_tracker',
    DISABLE_USAGE_TRACKER:              'disable_usage_tracker',
    SELECTED_DEVICE_USAGE_TRACKER:      'selected_device_usage_tracker',
    CLOSE_WHAT_IS_SESSION:              'close_what_is_session',
    OPEN_WHAT_IS_SESSION:               'open_what_is_session',
    YOTI_DENIED_CONSENT:                'yoti_denied_consent',
    YOTI_SCAN_TIPS:                     'yoti_scan_tips',
    YOTI_GO_BACK_FACE_SCAN:             'yoti_goback_facescan',
    YOTI_TRY_AGAIN:                     'yoti_tryagain_docscan',
    YOTI_AGE_GUESS_SUCCESS:             'ageguess_success',
    YOTI_TOO_CLOSE:                     'ageguess_too_close',
    YOTI_TOO_YOUNG:                     'ageguess_too_young',
    YOTI_ERROR_FACE_SCAN:               'yoti_error_face_scan',
    YOTI_GET_STARTED:                   'get_started_form_manual_ver',
    YOTI_GOT_IT:                        'form_complete_manual_ver',
    LOCATION_VERIFICATION_SUCCESS:      'location_verification_success',
    LOCATION_VERIFICATION_FAILURE:      'location_verification_failure',
    REVIEW_DETAILS:                     'review_details',
    CONTINUE_TO_LOGIN:                  'continue_to_login',
    I_DIDNT_RECEIVE_EMAIL:              'i_didnt_receive_email',
    RESEND_EMAIL:                       'resend_email',
    DEVICE_LOCK:                        'device_lock',
    DEVICE_UNLOCK:                      'device_unlock',
    // LOYALTY_OPTIN_VISIT:                'loyalty_optin_visit', --> old
    LOYALTY_EXT_VISIT:                  'loyalty_ext_visit',
    BATTERY_SAVER:                      'battery_saver',
    BATTERY_SAVER_THRESHOLD:            'battery_saver_threshold',
    BATTERY_SAVER_STATUS:               'battery_saver_status',
    PAIR_PREF_SAVE_SUCCESS:             'pair_pref_save_success',
    PAIR_PREF_SAVE_DEV_ERROR:           'pair_pref_save_dev_error',
    SETT_SAVE_SUCCESS:                  'sett_save_success',
    SETT_SAVE_DEV_ERROR:                'sett_save_dev_error',
    // DOWNLOAD_DATA_REQ:                  'download_data_request', --> old
    // DELETE_ACCOUNT_REQ:                 'delete_account_request', --> old
    CLICK:                              'click',
    OPT_IN:                             'opt_in',
    ADDRESS_SELECTION_UPDATED:          'address_selection_updated',
    VALIDATION_ERROR:                   'validation_error',
    VAULT_PIN_FAILURE:                  'vault_pin_failure',
    VAULT_PIN_SUCCESS:                  'vault_pin_success',
    VAULT_SECURITY_QUESTION_FAILED:     'vault_security_question_failure',
    VAULT_SECURITY_QUESTION_SUCCESS:    'vault_security_question_success',
    COMPLETE_SECURE_VAULT:              'complete_secure_vault',
    YOTI_FACE_SCAN_SUCCESS:             'yoti_face_scan_success',
    YOTI_FACE_SCAN_FAILURE:             'yoti_face_scan_failure',
    YOTI_FACE_SCAN_PROCESSING:          'yoti_face_scan_processing',
    YOTI_FACE_SCAN_PROBLEM:             'yoti_face_scan_problem',
    YOTI_DOCUMENT_SCAN_SUCCESS:         'yoti_document_scan_success',
    YOTI_DOC_SCAN_FAILURE:              'yoti_document_scan_failure',
    VIDEO_TUTORIAL:                     'video_tutorial',
    TUTORIAL:                           'tutorial',
    TUTORIAL_VIEW:                      'tutorial_view',
    DEVICE_NAME_CHANGE_SUCCESS:         'device_name_change_success',
    DEVICE_NAME_CHANGE_FAILURE:         'device_name_change_failure',
    DEVICE_RECONNECT_SUCCESS:            'device_reconnect_success',
    DEVICE_RECONNECT_FAILURE:           'device_reconnect_failure',
    EMAIL:                              'email',
    FAQ_VIEW:                           'faq_view'
}

export const AnalyticsScreenEventNames = {
    PAIR_YOUR_DEVICE:                       'pairing_activate',
    PAIRING_TURN_ON_BLUETOOTH:              'pairing_turn_on_bluetooth',
    PARING_BEFORE_WE_START:                 'pairing_before_we_start',
    PAIRING_ACTIVATE_NEW:                   'pairing_activate_new',
    PAIRING_CUSTOMIZE:                      'pairing_customize',
    PAIRING_MSS_CONNECT:                    'pairing_mss_connect',
    VAULT_CREATE_PIN_INTRO:                 'vault_create_pin_intro',
    VAULT_CONFIRM_PIN:                      'vault_confirm_pin',
    SECURITY_QUESTION:                      'vault_security_question',
    YOUR_DATA_IS_SECURE:                    'your_data_is_secure',
    ENTER_PIN:                              'open_vault_enter_pin',
    OPEN_VAULT_SUCCESS:                     'open_vault_success',
    OPEN_VAULT_SECURTY_QUESTION:            'open_vault_security_question',
    COUNTRY_SELECTOR:                       'country_selector',
    SOFT_AGE_VERIFICATION:                  'soft_age_verification',
    SOFT_AGE_VERIFICATION_FAILED:           'soft_age_verification_failed',
    BENEFITS:                               'benefits',
    CREATE_ACCOUNT:                         'create_account',
    LOGIN:                                  'login',
    TERMS_AND_CONDITIONS:                   'terms_and_conditions',
    TERMS_AND_CONDITIONS_AND_PRIVACY_POLICY: 'menu terms and conditions',
    NOTIFICATION_INTRO:                     'notification preferences',
    MANAGE_NOTIFICATIONS:                   'notification_preferences',
    MANAGE_DEVICES:                         'devices',
    // YOUR_PREFERENCES:                       'your preferences', --> old
    YOUR_PREFERENCES:                       'preferences',
    FIND_MY_VAPE:                           'find_my_vape',
    HOME:                                   'home',
    CLOUD_CONTROL:                          'cloud_control',
    CLOUD_CONTROL_TUTORIAL:                 'cloud_control',
    FIND_MY_VAPE_OPTIN:                     'find_my_vape_opt_in',
    FIND_MY_VAPE_OPTIN_INSTR:               'find my vape optin instr',
    PAIRING_SUCCESS:                        'pairing_success',
    MY_USAGE_STATS:                         'my usage stats',
    SLIDE_TO_UNLOCK:                        'slide to unlock',
    BATTERY_STRENGTH:                       'battery strength',
    MY_DEVICE:                              'my device',
    PAIR_A_NEW_DEVICE:                      'pair a new device',
    DEVICE_SETTINGS:                        'device_settings',
    DEVICE_NAME:                            'device_name',
    ADVANCED_SETTINGS:                      'advanced_settings',
    // MANAGE_ACCOUNT:                         'my account', --> old
    MANAGE_ACCOUNT:                         'account',
    REMOVE_DEVICE:                          'remove_device',
    RESET_PREFERENCES:                      'factory_reset',
    FACTORY_RESET_ATTENTION:                'factory_reset_attention',
    FAQ:                                    'faq',
    CONTACT_US:                             'contact us',
    VUSE_WORLD:                             'vuse world',
    FIRMWARE_UPDATE:                        'firmware update',
    ACTIVATE_YOUR_DEVICE:                   'activate your device',
    PAIRING_PROCESSING_ERROR:               'pairing_processing_error',
    MY_VUSE_SHOP:                           'my vuse shop',
    MY_VUSE_REORDER:                        'my vuse reorder',
    SUBSCRIPTIONS:                          'subscriptions',
    PRIVACY_POLICY:                         'privacy_policy',
    COOKIE_POLICY:                          'cookie policy',
    // TERMS_AND_CONDITIONS_LIST:              'my vuse tac', --> old
    TERMS_AND_CONDITIONS_LIST:              'terms_and_conditions',
    CONDITIONS_OF_SALE:                     'online cos',
    DEVICE_FAQ:                             'device faq',
    STORE_LOCATOR:                          'store locator',
    SUBSCRIPTION_TERMS_AND_CONDITIONS:      'sub tac',
    REORDER_ORDER_REMINDERS:                'reorder order reminders',
    NO_PAST_ORDERS:                         'no past orders',
    MANAGE_FAVOURITES:                      'manage favourites',
    PREVIOUS_ORDERS:                        'my orders',
    FAVOURITES_OPTIN:                       'favourites optin',
    FFY_OPTIN:                              'ffy optin',
    NOTIF_HISTORY:                          'notifhistory',
    PAIRING_SELECT_DEVICE_TYPE:             'pairing_select_device_type',
    LOYALTY:                                'loyalty',
    LOYALTY_NOT_JOINED:                     'loyalty_not_joined',
    USAGE_TRACKER_OPT_OUT:                  'usage_tracker_opt_out_help',
    USAGE_TRACKER_DAY:                      'usage_tracker_daily',
    USAGE_TRACKER_WEEK:                     'usage_tracker_weekly',
    USAGE_TRACKER_MONTH:                    'usage_tracker_monthly',
    VERIFY_EMAIL:                           'verify_email',
    SIGN_UP_EMAIL:                          'sign_up_email',
    SIGN_UP_PASSWORD:                       'sign_up_password',
    SIGN_UP_NAME:                           'sign_up_name',
    SIGN_UP_DOB:                            'sign_up_dob',
    SIGN_UP_PHONENUMBER_ADDRESS:            'sign_up_phonenumber_address',
    SIGN_UP_ADDRESS:                        'sign_up_address',
    SIGN_UP_CONSENTS:                       'sign_up_consents',
    SIGN_UP_PHONENUMBER_SA_ID:              'sign_up_phonenumber_sa_id',
    RESET_PASSWORD_EMAIL:                   'reset_password_email',
    RESET_PASSWORD_EMAIL_SENT:              'reset_password_email_sent',
    SIGN_UP_FAILED:                         'sign_up_failed',
    VAULT_SUCCESS:                          'vault_success',
    YOTI_INTRO:                             'yoti_intro',
    YOTI_FAQ:                               'yoti_faq',
    YOTI_FACE_SCAN_INTRO:                   'yoti_face_scan_intro',
    YOTI_FACE_SCAN:                         'yoti_face_scan',
    YOTI_FACE_SCAN_SUCCESS:                 'yoti_face_scan_success',
    YOTI_FACE_SCAN_FAILURE:                 'yoti_face_scan_failure',
    YOTI_FACE_SCAN_TOO_CLOSE:               'yoti_face_scan_failure_too_close',
    YOTI_DOCUMENT_SCAN_PROCESSING:          'yoti_document_scan_processing',
    YOTI_DOC_SCAN_PROCESSING_LONG:          'yoti_document_scan_processing_long',
    YOTI_DOC_SCAN_FAILURE:                  'yoti_document_scan_failure',
    FIND_OUT_MORE:                          'find_out_more',
    J1_VIDEO_TUTORIAL:                      'j1_video_tutorial',
    PAIRING_ACTIVATE_CHARGER:               'pairing_activate_charger',
    PAIRING_ACTIVATE_INSERT_POD:            'pairing_activate_insert_pod',
    NOTIFICATION_PREFERENCES_INTRO:         'notification_preferences_intro',
    MARKETING_NFORMATON:                    'marketing_information',
    NOTIFICATION_PREFERENCES:               'notification_preferences',
    PAIRING_BEFORE_WE_START_HELP:           'pairing_before_we_start_help',
    HOMESCREEN:                             'homescreen',
    USAGE_TRACKER_PUFF:                     'usage_tracker_puff_collecting_info',
    USAGE_TRACKER_OPT_IN:                   'usage_tracker_opt_in',
    USAGE_TRACKER:                          'usage_tracker',
    CLOUD_CONTROL:                          'cloud_control',
    RECHARGE_REMINDERS:                     'recharge_reminders',
    BATTERY_SAVER_PICKER:                   'battery_saver_threshold_picker',
    LOW_BATTERY_PICKER:                     'low_battery_alert_threshold_picker',
    REMOVE_DEVICE_DATA:                     'remove_device_data',
    REMOVE_DEVICE_PROGRESS:                 'remove_device_progress',
    FACTORY_RESET_PROGRESS:                 'factory_reset_progress',
    REMOVE_DEVICE_BLUETOOTH:                'remove_device_bluetooth',
    FACTORY_RESET_BLUETOOTH:                'factory_reset_bluetooth',
    REMOVE_DEVICE_SUCCESS:                  'remove_device_success',
    FACTORY_RESET_SUCCESS:                  'factory_reset_success',
    MORE_MENU:                              'more_menu',
    EDIT_ACCOUNT:                           'account_edit',
    PAIRING_ERROR:                          'pairing_device_selector_error',
    PAIRING_PROCESSING:                     'pairing_processing'
}


export const AnalyticsFieldNames = {
    COUNTRY:                                'country',
    POSTAL_CODE:                            'postal_code',
    LANGUAGE:                               'language',
    EMAIL:                                  'email',
    PASSWORD:                               'password',
    PASSWORD_CONFIRMATION:                  'password_confirmation',
    FIRST_NAME:                             'first_name',
    LAST_NAME:                              'last_name',
    DOB:                                    'dob',
    PHONENUMBER:                            'phonenumber',
    SA_ID:                                  'sa_id',
    ADDRESS:                                'address',
    SUBURB:                                'suburb',
    MARKETING_INFORMATION_EMAIL:            'marketing_information_email',
    PERSONAL_DATA_PROCESSING:               'personal_data_processing',
    TERMS_AND_CONDITIONS:                   'terms_and_conditions',
    LOYALTY_OPTIN:                          'loyalty_optin',
    MARKETING_INFORMATION_NOTIFICATIONS:    'marketing_information_notifications',
    PIN:                                    'pin',
    SECURITY_QUESTION:                      'security_question',
    SECURITY_QUESTION_ANSWER:               'security_question_answer',
    CREATE_NEW_PIN:                         'create_new_pin',
    GENDER:                                 'gender',
    NOTIFICATIONS:                          'notifications',
    DEVICE_ERRORS:                          'device_errors',
    DEVICE_STATUS:                          'device_status',
    FIRMWARE_UPDATE:                        'firmware_update',
    SUGGESTIONS:                            'suggestions',
    DEVICE_COLOR:                           'device_color',
    LED_BRIGHTNESS:                         'led_brightness',
    HIGH:                                   'high',
    MEDIUM:                                 'medium',
    LOW:                                    'low',
    BATTERY_SAVER:                          'battery_saver',
    BATTERY_SAVER_THRESHOLD:                'battery_saver_threshold',
    LOW_BATTERY_ALERT:                      'low_battery_alert',
    LOW_BATTERY_ALERT_THRESHOLD:            'low_battery_alert_threshold',
    NAME:                                   'name',
    DEVICE_NAME:                            'device_name',
    REMOVE_DEVICE_BLUETOOTH_CONFIRMATON:    'remove_device_bluetooth_confirmation',
    FACTORY_RESET_BLUETOOTH_CONFIRMATON:    'factory_reset_bluetooth_confirmation',
    MARKETING_EMAILS:                       'marketing_emails',
    MARKETING_SMS:                          'marketing_sms',
    FIND_MY_VAPE:                           'find_my_vape',
    USAGE_TRACKER:                          'usage_tracker',
    COOKIE_CONSENT:                         'cookie_consent_ga'
}

export const AnalyticsTargetEventsNames = {
    NEXT:                                   'next',
    PREVIOUS:                               'previous',
    CLOSE:                                  'close',
    CONFIRM:                                'confirm',
    KEEP:                                   'keep',
    CANCEL:                                 'cancel',
    HELP:                                   'help',
    DECLINE_LEGAL_AGE:                      'decline_legal_age',
    GO_BACK:                                'go_back',
    CREATE_ACCOUNT:                         'create_account',
    LOGIN_ACCOUNT:                          'login_account',
    PRIVACY_POLICY:                         'privacy_policy',
    PRIVACY_NOTICE:                         'privacy_notice',
    START_AGAIN:                            'start_again',
    LOGIN:                                  'login',
    TRY_AGAIN:                              'try_again',
    TAC_SECTION:                            'tac_section_',
    FAQ_SECTION:                            'faq_section_',
    CREATE_PIN:                             'create_pin',
    CONFIRM_PIN:                            'confirm_pin',
    SECURE_MY_DATA:                         'secure_my_data',
    FORGOT_PIN:                             'forgot_pin',
    CONFIRM_PIN:                            'confirm_pin',
    FAQ:                                    'faq',
    YOTI_LINK:                              'yoti_link',
    USE_FACE_SCAN:                          'use_face_scan',
    USE_ID:                                 'use_id',
    SCAN_TIPS:                              'scan_tips',
    CONTACT_US:                             'contact_us',
    RETRY:                                  'retry',
    USE_ID:                                 'use_id',
    EMAIL_NOT_RECIEVED:                     'email_not_received',
    RESEND_EMAIL:                           'resend_email',
    FIND_OUT_MORE:                          'find_out_more',
    ADD_DEVICE:                             'add_device',
    DEVICE:                                 'device',
    I_HAVE_NOT_USED:                        'i_have_not_used',
    I_HAVE_USED:                            'i_have_used',
    NOT_WORKNG:                             'not_working',
    CUSTOMISE:                              'customise',
    ACCEPT_ALL:                             'accept_all',
    CUSTOMIZE:                              'customize',
    DECLINE:                                'decline',
    VUSE_COM:                               'vuse_com',
    SAVE:                                   'save',
    NAME:                                   'name',
    NOTIFICATION_CENTRE:                    'notification_centre',
    RECONNECT:                              'reconnect',
    FIRMWARE_UPGRADE:                       'firmware_upgrade',
    SHOP:                                   'shop',
    LOCK_SLIDER:                            'lock_slider',
    USAGE_TRACKER:                          'usage_tracker',
    CLOUD_CONTROL:                          'cloud_control',
    RECHARGE_REMINDERS:                     'recharge_reminders',
    FIND_MY_VAPE:                           'find_my_vape',
    DEVICE_SETTINGS:                        'device_settings',
    CONNECTED_STATUS:                       'connected_status',
    BATTERY:                                'battery',
    LEARN_HOW:                              'learn_how',
    ENABLE:                                 'enable',
    USAGE_TREND:                            'usage_trend',
    USAGE_TREND_CLOSE:                      'usage_trend_close',
    USAGE_HELP:                             'usage_help',
    USAGE_HELP_CLOSE:                       'usage_help_close',
    SESSIONS_HELP:                          'sessions_help',
    SESSIONS_HELP_CLOSE:                    'sessions_help_close',
    PRESET_HELP:                            'preset_help',
    PRESET_HELP_CLOSE:                      'preset_help_close',
    DATE_BACK:                              'date_back',
    DATE_NEXT:                              'date_next',
    DAY_OF_WEEK:                            'day_of_week',
    TUTORIAL:                               'tutorial',
    PING:                                   'ping',
    STOP_PINGING:                           'stop_pinging',
    UPGRADE_FIRMWARE:                       'upgrade_firmware',
    REMOVE_DEVICE:                          'remove_device',
    ACCOUNT:                                'account',
    LOYALTY:                                'loyality',
    DEVICES:                                'devices',
    NOTIFICATIONS:                          'notifications',
    PREFERENCES:                            'preferences',
    DEVICE_FAQ:                             'device_faq',
    ACCOUNT_FAQ:                            'account_faq',
    CONTACT_US:                             'contact_us',
    TERMS_AND_CONDITIONS:                   'terms_and_conditions',
    PRIVACY_POLICY:                         'privacy_policy',
    WHY:                                    'why',
    CUSTOMER_SUPPORT_PHONENUMBER:           'customer_support_phonenumber',
    CUSTOMER_SUPPORT_EMAIL:                 'customer_support_email',
    NOT_WORKNG:                             'not_working',
    FACTORY_RESET:                          'factory_reset',
    HOME:                                   'home',
    VUSE_WORLD:                             'vuseworld',
    STORE:                                  'store',
    ACCOUNT:                                'account',
    MORE:                                   'more',
    STORE_LOCATOR:                          'store',
    EDIT:                                   'edit',
    DELETE:                                 'delete',
    DOWNLOAD:                               'download',
    COOKIE_MANAGEMENT_PORTAL:               'cookie_management_portal',
    LOGOUT:                                 'logout',
    BACK:                                   'back',
    TURN_BLUETOOTH_ON:                      'turn_bluetooth_on',
}

export const AnalyticsErrorNames = {
    WRONG_FORMAT:                           'wrong_format',
    ALREADY_USED:                           'already_used',
    EMPTY:                                  'empty',
    MIN_LENGHT:                             'min_length',
    INVALD_DATE:                            'invalid_date',
    TO_CLOSE_TO_AGE:                        'too_close_to_legal_age',
    FAKE_PICTURE:                           'fake_picture',
    REGISTRATION_PWD_MIN_LENGTH:            'registration_pwd_min_length',
    REGISTRATION_PWD_SPECIAL_CHAR:          'registration_pwd_special_char',
    REGISTRATION_PWD_NUMBER:                'regstration_pwd_number',
    REGISTRATION_PWD_LOWERCASE:             'regstartion_pwd_lowercase',
    REGISTRATION_PWD_UPPERCASE:             'registration_pwd_uppercase',
    REGISTRATION_PWD_MATCHING:              'registraion_pwd_matching',
}

export const AnalyticsTutorialNames = {
    LOCK_UNLOCK:                            'lock_unlock',
    USAGE_TRACKER:                          'usage_tracker',
    CLOUD_CONTROL:                          'cloud_control',
    RECHARGE_REMINDERS:                     'recharge_reminders',
    FIND_MY_VAPE:                           'find_my_vape',
}

export const AnalyticsStatusNames = {
    ENABLED:                    'enabled',
    DISABLED:                   'disabled',
}

export const AnalyticsPlatforms = {
    FIREBASE:   'firebase',
    CS:         'content_square',
    QUALAROO:   'qualaroo',
    GOOGLE:     'googleAnalytics'
};