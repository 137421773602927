import React from 'react';
import moment from 'moment';
import { history } from '../../_helpers/history';
import { buildExtPageURI } from '../../_helpers/navigation';
import { buildBlogArticleURL } from '../../_helpers/blog';

export const BlogArticleList = (props) => {
  const { blogArticles = [] } = props;

  const renderBlogArticle = (blogElement, index) => {
    const { image = {}, title = '', published_at = '', handle } = blogElement;

    const onBlogElementClick = (event) => {
      if (!!handle) {
        event.preventDefault();
        const url = buildExtPageURI({
          title,
          url: buildBlogArticleURL(handle),
        });
        history.push(url);
      }
    };
    
    const date = !!published_at
      ? moment(published_at).format('D MMMM YY')
      : '';

    return (
      <li
        className="blog-article-container"
        key={`blog-article-id-${index}`}
        onClick={onBlogElementClick}
      >
        <img className="blog-article-image" src={image.src} alt={image.alt} />
        <div className="blog-article-text-container">
          <p className="blog-article-date text-uppercase">{date}</p>
          <p className="blog-article-title text-uppercase">{title}</p>
        </div>
      </li>
    );
  };

  return (
    <ul className="blog-article-list">{blogArticles.map(renderBlogArticle)}</ul>
  );
};
