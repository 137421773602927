import React from 'react';
import { OnboardingHeader } from '../../_components';
import { aemPages, routingConstants } from '../../_constants';
import { AEMHelper, propertyTextDefaultEmpty } from '../../_helpers/aem/aemhelper';
import { buildURI } from '../../_helpers/navigation';
import Iframe from 'react-iframe';
import { Webview } from '../../_helpers/webview';


class PastOrders extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}

        this.handleBack = this._handleBack.bind(this);


        this.aem = new AEMHelper();
        this.dictionary = this.aem.getDictionary(aemPages.BURGER_MENU, {
            MENU_PAST_ORDERS: propertyTextDefaultEmpty,
        })
    }

    componentDidMount() {

    }

    _handleBack() {
        this.props.history.push({
            pathname: buildURI(routingConstants.DASHBOARD)
        });
    }

    render() {

        return (
            <div className="page w-100">
                <OnboardingHeader title={this.dictionary.MENU_PAST_ORDERS} action={this.handleBack} />
                <div className='h-100'>
                    <Iframe url={Webview.getRecentOrdersUrl()}
                        width="100%"
                        height="100%"
                        id="pastOrdersIframe"
                        className="pastOrdersIframe"
                        display="initial"
                        position="relative" />

                </div>
            </div>
        )
    }
}


export { PastOrders };