import React from "react";
import { isSupportedService } from "../../_actions/appConfig.actions";
import { servicesConstants } from "../../_constants";

export const AgeWarningDisclaimer = ({ title, content }) => {
  if (!isSupportedService(servicesConstants.ONBOARDING_AGE_WARNING)) {
    return null;
  }

  return (
    <div className="age-warning-disclaimer">
      <span>{title}</span>
      <span>{content}</span>
    </div>
  );
};
