import { routingConstants } from "../../_constants";

export const PAIRING_STEP_NAMES = {
  ACTIVATION: "activation",
  PAIRING: "pairing",
  PAIRING_ERROR: "pairingerror",
  UNLOCK: "unlock",
  SEARCHING_ERROR: "searchingerror",
};

// the order must respect the items in Pairing.jsx

export const PAIRING_STEPS = [
  { key: "pairingStep-1", stepName: PAIRING_STEP_NAMES.ACTIVATION },
  { key: "pairingStep-2", stepName: PAIRING_STEP_NAMES.PAIRING },
  { key: "pairingStep-3", stepName: PAIRING_STEP_NAMES.PAIRING_ERROR },
  { key: "pairingStep-4", stepName: PAIRING_STEP_NAMES.UNLOCK },
  { key: "pairingStep-5", stepName: PAIRING_STEP_NAMES.SEARCHING_ERROR },
];
