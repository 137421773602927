import cx from "classnames";
import { bool, func, object, string } from "prop-types";
import React from "react";

const CustomButton = ({
  variant = "primary",
  icon,
  full,
  disabled = false,
  className,
  style,
  onClick,
  children,
}) => {
  return (
    <button
      type="button"
      disabled={disabled}
      style={style}
      className={cx(
        `btn btn-${variant} d-flex gap-2 justify-content-center text-uppercase custom-btn`,
        { "w-100": !!full, "align-items-center": !!icon },
        className
      )}
      onClick={onClick}
    >
      {children}
      {icon && <span className={`bat-icon-${icon} custom-btn-icon`} />}
    </button>
  );
};

CustomButton.propTypes = {
  className: string,
  style: object,
  icon: string,
  full: bool,
  disabled: bool,
  onClick: func,
};

export { CustomButton };
