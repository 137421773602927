import React from 'react';
import { Header, OnboardingHeader } from '..';

export const ContentView = (props) => {
  const {
    content,
    title,
    handleBack,
    close,
    className
  } = props;


  return (
    <div className="page w-100">
      {
        (!!title || !!handleBack || !!close) && 
          <Header 
            {...(handleBack && {
              leftButton: {
                icon: <span className="bat-icon-back" />,
                onClick: handleBack
              }
            })}
            {...(close && {
              rightButton: {
                icon: <span className='bat-icon-close' />,
                onClick: close
              }
            })}
          >
            {title}
          </Header>
      }
      
      <div className="h-100 p-3" >
        <div className={className}>{content}</div>
      </div>
    </div>
  );
};
