import React, { useEffect, useRef, useState } from "react";
import { imagesConstants } from "../../_constants";
import BrowserHelper from "../../_helpers/browser/browser.helper";
import { CSSTransition } from "react-transition-group";
import { isNil } from "lodash";

const FRENCH_LANG_RE = /^fr\b/;

const dictionary = {
  en: {
    NUVIU_VERSION_ALERT_TITLE: "IMPORTANT",
    NUVIU_VERSION_ALERT_CONTENT_TITLE: "UPDATE REQUIRED",
    NUVIU_VERSION_ALERT_CONTENT_DESC:
      "The nuviu version you are using is outdated. Please open App Store and update your nuviu browser to the newiest version.",
    // NUVIU_VERSION_ALERT_CTA_BTN_LABEL: "UPDATE",
  },
  fr: {
    NUVIU_VERSION_ALERT_TITLE: "IMPORTANT",
    NUVIU_VERSION_ALERT_CONTENT_TITLE: "MISE À JOUR REQUISE",
    NUVIU_VERSION_ALERT_CONTENT_DESC:
      "La version actuelle du navigateur nuviu est expirée. Une mise à jour est requise afin de poursuivre. Veuillez accéder à l'App Store et mettre à jour le navigateur nuviu.",
    // NUVIU_VERSION_ALERT_CTA_BTN_LABEL: "MISE À JOUR",
  },
};

export function NuviuVersionAlert() {
  const [isOpen, setIsOpen] = useState(false);
  const [lang, setLang] = useState("en");
  const nodeRef = useRef(null);

  const goToAppleStore = () => {
    //
  };

  useEffect(() => {
    FRENCH_LANG_RE.test(navigator.language) && setLang("fr");

    const currentUrl = window.location.pathname;
    if (!isNil(currentUrl)) {
      if (
        currentUrl?.includes("android-pp") ||
        currentUrl?.includes("android-tac") ||
        currentUrl?.includes("gps-pp") || 
        currentUrl?.includes("account-deletion") || 
        currentUrl?.includes("faq")
      ) {
        return;
      }
    }

    BrowserHelper.isUpdateNeeded().then((res) => {
      setIsOpen(res);
    });
  }, []);

  return (
    <CSSTransition
      in={isOpen}
      nodeRef={nodeRef}
      timeout={500}
      classNames="nuviu-version-alert"
      unmountOnExit
    >
      <div
        ref={nodeRef}
        className="nuviu-version-alert d-flex flex-column h-100 w-100"
      >
        <div className="nuviu-version-alert-header">
          <h2>{dictionary[lang].NUVIU_VERSION_ALERT_TITLE}</h2>
        </div>
        <div className="nuviu-version-alert-content d-flex flex-column align-items-center justify-content-center">
          <img src={imagesConstants.NUVIU_BROWSER_ICON} alt="nuviu app icon" />
          <h3>
            <span className="bat-icon-alert-circle" />
            {dictionary[lang].NUVIU_VERSION_ALERT_CONTENT_TITLE}
          </h3>
          <p>{dictionary[lang].NUVIU_VERSION_ALERT_CONTENT_DESC}</p>
        </div>
        {/* <div className="nuviu-version-alert-footer">
          <button
            className="btn btn-primary w-100 text-uppercase"
            type="button"
            onClick={goToAppleStore}
          >
            {dictionary[lang].NUVIU_VERSION_ALERT_CTA_BTN_LABEL}
          </button>
        </div> */}
      </div>
    </CSSTransition>
  );
}
