import React, { useContext, useEffect, useState } from "react";
import { PageSelectorCTA } from "../../_components";
import { AppStories } from "../../_components/AppStories";
import { AnalyticsEvents, AnalyticsScreenEventNames, AnalyticsTargetEventsNames, aemPages, routingConstants } from "../../_constants";
import {
  AEMHelper,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { Commons } from "../../_helpers/commons";
import { DashboardContext } from "../Dashboard";
import { getSupportedDevices } from "../../_actions/appConfig.actions";
import { handleDeviceSelection } from "../../_helpers/device"
import { buildURI } from "../../_helpers/navigation";

const NoDevices = () => {
  const [stories, setStories] = useState([]);
  const [dictionary, setDictionary] = useState({});
  const [devices] = useState(getSupportedDevices());
  const { navbarHeight } = useContext(DashboardContext) || {};

  const goToSelectDevice = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.ADD_DEVICE});

    if (devices.length === 1){
      handleDeviceSelection(devices[0].profile);
      Commons.goTo(buildURI(routingConstants.PAIR_DEVICE), {type: 'pop'});
    } else {
      Commons.goTo(buildURI(routingConstants.SELECT_DEVICE));
    }
  };

  useEffect(() => {
    const aem = new AEMHelper();
    const urls = aem.getCarouselUrls(aemPages.HOMEPAGE, "carousel", "mobile");

    setStories(urls.map((url) => ({ url, type: "video", muted: true })));
    setDictionary(
      aem.getDictionary(aemPages.HOMEPAGE, {
        HOME_BTN_PAIR_YOUR_DEVICE: propertyTextDefaultEmpty,
      })
    );
  }, []);

  return (
    <div className="no-devices">
      <AppStories
        stories={stories}
        storiesHeight={`calc(100vh - ${navbarHeight}px)`}
        analyticsNoDevice={true}
      />
      <PageSelectorCTA sticky={false} style={{ bottom: `0px` }}>
        <div className="d-grid">
          <button
            className="btn btn-primary text-uppercase"
            onClick={goToSelectDevice}
          >
            {dictionary.HOME_BTN_PAIR_YOUR_DEVICE}
          </button>
        </div>
      </PageSelectorCTA>
    </div>
  );
};

export { NoDevices };
