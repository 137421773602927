import {useEffect, useCallback, useRef, useState} from 'react';

const useCallbackState = (initialValue) => {

    const counter = useRef(0); // used to fire the callback even if the value remains the same
    const cbContainer = useRef(null);

    const [state, setState] = useState({
        id: counter.current,
        value: initialValue
    });

    const setCallbackState = useCallback((newValue, cb = (s) => {}) => {

        //console.log("setting the callback");
        counter.current ++;
        cbContainer.current = cb;

        setState({
            value: newValue,
            id: counter.current
        });

    }, []);

    useEffect(() => {

        //console.log("state changed");
        if (cbContainer.current){
            //console.log("calling the callback");
            cbContainer.current(state);
            cbContainer.current = null;
        }

    }, [state]);

    return [state.value, setCallbackState];

}

export default useCallbackState;