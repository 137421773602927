import React from 'react';
import { imagesConstants } from '../../_constants';
import { CustomLoadingButton } from '../CustomLoadingButton';
import { PageSelectorCTA } from "../../_components";

export const WarningNotificationPermissionPanel = (props) => {
  const { dictionary, onContinue, isContinueButtonLoading } = props;

  return (
    <div className="notification-permission-panel">
      <div className="text">
        <span>{dictionary.IOS_MKT_NOTIF_WARNING_BODY}</span>
      </div>
      <div>
        <img src={imagesConstants.NOTIFICATION} alt="news" />
      </div>
      <div>
        <span className='text'>
          {dictionary.IOS_MKT_NOTIF_WARNING_CONTENT}
        </span>
        <PageSelectorCTA sticky={false}>
          <div className="d-grid gap-2 buttons-container">
            <CustomLoadingButton
              isLoading={isContinueButtonLoading}
              validationMethod={null}
              buttonLabel={dictionary.IOS_MKT_NOTIF_WARNING_BTN_CONTINUE_0_ctaLabel}
              onClickMethod={() => onContinue()}
              buttonClassName="btn btn-primary text-uppercase"
            />
          </div>
        </PageSelectorCTA>
      </div>
    </div>
  );
};
