import React from 'react';
import { AnalyticsEvents, AnalyticsTargetEventsNames } from '../../_constants';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { PAIRING_STEP_NAMES } from '../../_helpers/device';
import { PageSelectorCTA } from '../PageSelectorCTA';

/**
 * Pairing second step
 * @param {*} props 
 * @returns 
 */
export const DeviceActivation = (props) => {
    const {
        dictionary,
    } = props;

    const goToNextStep = () => {
        if (typeof props.goToNamedStep === 'function') {
            logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NEXT});
            props.goToNamedStep(PAIRING_STEP_NAMES.PAIRING);
        }
    }

    return (
        <>
            <div className="pairing-title">
                <h1>{dictionary.PAIRING_TITLE}</h1>
                <h2>{dictionary.PAIRING_BODY}</h2>
            </div>
            <PageSelectorCTA sticky={false} className="mt-auto">
                <p className="activate-device-info">{dictionary.PAIRING_PRESS_HOLD_BUTTON}</p>
                <div className="d-grid">
                    <button className="btn btn-primary text-uppercase" type="button"
                        onClick={goToNextStep}>
                        {dictionary.PAIRING_BTN_USED_0_ctaLabel}
                    </button>
                </div>
            </PageSelectorCTA>
            <div className="page-linear-gradient-bg"></div>
        </>
    );
}