import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { AnalyticsErrorNames, AnalyticsEvents, AnalyticsFieldNames, AnalyticsScreenEventNames, aemPages } from "../../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../../_helpers/aem/aemhelper";
import { useSignupFormContext } from "./SignupFormContext";
import { SignupFormStep } from "./SignupFormStep";
import { getRegistration, setRegistration } from "../../../_actions";
import { logAnalyticsEvent } from "../../../_helpers/analytics/logAnalytics";

const FIELD_NAME_PWD = "password";
const FIELD_NAME_PWD_CONFIRM = "confirm";
const HAS_SPECIAL_RE = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]+/;
const HAS_NUMBER_RE = /\d+/;
const HAS_UPPERCASE_CHAR_RE = /[A-Z]+/;
const HAS_LOWERCASE_CHAR_RE = /[a-z]+/;

export function PasswordStep(props) {
  const [dictionary, setDictionary] = useState({});
  const [isValid, setIsValid] = useState(false);
  const { formData } = useSignupFormContext();
  const { register, setValue } = useFormContext();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const { password } = useWatch(FIELD_NAME_PWD);
  const [confirmPassword, setConfirmPassword] = useState(
    formData[FIELD_NAME_PWD] || ""
  );
  const [hasMinLength, setHasMinLength] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasUppercaseChar, setHasUppercaseChar] = useState(false);
  const [hasLowercaseChar, setHasLowercaseChar] = useState(false);
  const [haveMatch, setHaveMatch] = useState(false);

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.SIGN_UP_PASSWORD})
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.REGISTRATION_PWD, {
        REGISTRATION_HEADER: propertyHeadingDefaultEmpty,
        REGISTRATION_PASSWORD_LABEL: propertyTextDefaultEmpty,
        REGISTRATION_FIELD_REQUIRED: propertyTextDefaultEmpty,
        REGISTRATION_PASSWORD_PLACEHOLDER: propertyTextDefaultEmpty,
        REGISTRATION_PASSWORD_PLACEHOLDER_CONF: propertyTextDefaultEmpty,
        REGISTRATION_PWD_MIN_LENGTH: propertyTextDefaultEmpty,
        REGISTRATION_PWD_SPECIAL_CHAR: propertyTextDefaultEmpty,
        REGISTRATION_PWD_NUMBER: propertyTextDefaultEmpty,
        REGISTRATION_PWD_UPPERCASE: propertyTextDefaultEmpty,
        REGISTRATION_PWD_LOWERCASE: propertyTextDefaultEmpty,
        REGISTRATION_PWD_MATCHING: propertyTextDefaultEmpty,
        SIGNUP_PRIVACY_POLICY_CONTENT: propertyTextDefaultEmptyParsed,
        REGISTRATION_BTN_NEXT: propertyCtaItemsDefaultEmpty,
      })
    );
  }, []);

  useEffect(() => {
    const password = formData[FIELD_NAME_PWD];

    if (password) {
      setValue(FIELD_NAME_PWD, password, { shouldDirty: true });
      setConfirmPassword(password);
    }
  }, [formData, setValue]);

  useEffect(() => {
    const hasMinLength = password?.length >= 8;
    const hasSpecial = HAS_SPECIAL_RE.test(password);
    const hasNumber = HAS_NUMBER_RE.test(password);
    const hasUppercase = HAS_UPPERCASE_CHAR_RE.test(password);
    const hasLowercase = HAS_LOWERCASE_CHAR_RE.test(password);
    const haveMatch =
      password !== "" && confirmPassword !== "" && password === confirmPassword;

    setHasMinLength(hasMinLength);
    setHasSpecialChar(hasSpecial);
    setHasNumber(hasNumber);
    setHasUppercaseChar(hasUppercase);
    setHasLowercaseChar(hasLowercase);
    setHaveMatch(haveMatch);

    setIsValid(
      hasMinLength &&
        hasSpecial &&
        hasNumber &&
        hasUppercase &&
        hasLowercase &&
        haveMatch
    );
  }, [password, confirmPassword]);

  useEffect(() => {
    if (isValid) {
      let registration = getRegistration()
      registration.password = password
      setRegistration(registration)
    }
  }, [password, isValid]);

  const handleBlurPasswordConfirmation = () => {
    logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.PASSWORD})
    logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.PASSWORD_CONFIRMATION})

    if(!hasMinLength){
      logAnalyticsEvent(AnalyticsEvents.VALIDATION_ERROR, {field_name: AnalyticsFieldNames.PASSWORD, error: AnalyticsErrorNames.REGISTRATION_PWD_MIN_LENGTH});
    }

    if(!hasSpecialChar){
      logAnalyticsEvent(AnalyticsEvents.VALIDATION_ERROR, {field_name: AnalyticsFieldNames.PASSWORD, error: AnalyticsErrorNames.REGISTRATION_PWD_SPECIAL_CHAR});
    }

    if(!hasNumber){
      logAnalyticsEvent(AnalyticsEvents.VALIDATION_ERROR, {field_name: AnalyticsFieldNames.PASSWORD, error: AnalyticsErrorNames.REGISTRATION_PWD_NUMBER});
    }

    if(!hasLowercaseChar){
      logAnalyticsEvent(AnalyticsEvents.VALIDATION_ERROR, {field_name: AnalyticsFieldNames.PASSWORD, error: AnalyticsErrorNames.REGISTRATION_PWD_LOWERCASE});
    }

    if(!hasUppercaseChar){
      logAnalyticsEvent(AnalyticsEvents.VALIDATION_ERROR, {field_name: AnalyticsFieldNames.PASSWORD, error: AnalyticsErrorNames.REGISTRATION_PWD_UPPERCASE});
    }

    if(!haveMatch){
      logAnalyticsEvent(AnalyticsEvents.VALIDATION_ERROR, {field_name: AnalyticsFieldNames.PASSWORD, error: AnalyticsErrorNames.REGISTRATION_PWD_MATCHING});
    }
  }

  return (
    <SignupFormStep
      title={dictionary.REGISTRATION_HEADER}
      privacyPolicyLabel={dictionary.SIGNUP_PRIVACY_POLICY_CONTENT}
      ctaLabel={dictionary.REGISTRATION_BTN_NEXT_0_ctaLabel}
      isValid={isValid}
      {...props}
    >
      <div className="signup-form-wrapper">
        <div className="signup-form-row">
          <span className="form-mandatory">
            {dictionary.REGISTRATION_FIELD_REQUIRED}
          </span>
          <label className="page-label" htmlFor={FIELD_NAME_PWD}>
            {dictionary.REGISTRATION_PASSWORD_LABEL}
          </label>
          <div className="form-control-toggle">
            <span
              className={cx("toggle-icon", "toggle-pw", {
                show: isPasswordVisible,
                hide: !isPasswordVisible,
              })}
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            />
            <input
              name={FIELD_NAME_PWD}
              id={FIELD_NAME_PWD}
              type={isPasswordVisible ? "text" : "password"}
              className="form-control"
              placeholder={dictionary.REGISTRATION_PASSWORD_PLACEHOLDER}
              autoComplete="new-password"
              ref={register}
            />
          </div>
          <div className="form-control-toggle form-control-toggle-confirm">
            <span
              className={cx("toggle-icon", "toggle-pw", {
                show: isConfirmVisible,
                hide: !isConfirmVisible,
              })}
              onClick={() => setIsConfirmVisible(!isConfirmVisible)}
            />
            <input
              name={FIELD_NAME_PWD_CONFIRM}
              id={FIELD_NAME_PWD_CONFIRM}
              type={isConfirmVisible ? "text" : "password"}
              className="form-control"
              placeholder={dictionary.REGISTRATION_PASSWORD_PLACEHOLDER_CONF}
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(ev) => setConfirmPassword(ev.target.value)}
              onBlur={handleBlurPasswordConfirmation}
            />
          </div>
        </div>
        <div className="password-validation-container">
          <div className="password-validation-row">
            <div
              className={cx("password-validation", {
                active: hasMinLength,
              })}
            >
              <span className="bat-icon-check" />
              {dictionary.REGISTRATION_PWD_MIN_LENGTH}
            </div>
            <div
              className={cx("password-validation", {
                active: hasSpecialChar,
              })}
            >
              <span className="bat-icon-check" />
              {dictionary.REGISTRATION_PWD_SPECIAL_CHAR}
            </div>
          </div>
          <div className="password-validation-row">
            <div
              className={cx("password-validation", {
                active: hasNumber,
              })}
            >
              <span className="bat-icon-check" />
              {dictionary.REGISTRATION_PWD_NUMBER}
            </div>
            <div
              className={cx("password-validation", {
                active: hasUppercaseChar,
              })}
            >
              <span className="bat-icon-check" />
              {dictionary.REGISTRATION_PWD_UPPERCASE}
            </div>
          </div>
          <div className="password-validation-row">
            <div
              className={cx("password-validation", {
                active: hasLowercaseChar,
              })}
            >
              <span className="bat-icon-check" />
              {dictionary.REGISTRATION_PWD_LOWERCASE}
            </div>
            <div
              className={cx("password-validation", {
                active: haveMatch,
              })}
            >
              <span className="bat-icon-check" />
              {dictionary.REGISTRATION_PWD_MATCHING}
            </div>
          </div>
        </div>
      </div>
    </SignupFormStep>
  );
}
