import { legacy_createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import rootReducer from "../_reducers";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  createTransformForBlacklisting,
  createExpirableTransform,
} from "./transform";

import { initialState as registrationInitialState } from "../_reducers/registration.reducer";
import { initialState as deviceInitialState } from "../_reducers/device.reducer";
import { initialState as commonsInitialState } from "../_reducers/commons.reducer";
import { initialState as loginInitialState } from "../_reducers/login.reducer";
import { initialState as shopInitialState } from "../_reducers/shop.reducer";
import { initialState as onboardingInitialState } from "../_reducers/onboarding.reducer";
import { initialState as dashboardInitialState } from "../_reducers/dashboard.reducer";
import { initialState as permissionsInitialState } from "../_reducers/permissions.reducer";
import { initialState as ageVerificationInitialState } from "../_reducers/age-verification.reducer";

const shopTransform = createExpirableTransform(
  "shopReducer",
  shopInitialState,
  ["catalog"],
  2,
  "hours",
  ["loading", "error"]
);
const registrationTransform = createTransformForBlacklisting(
  "registrationReducer",
  registrationInitialState,
  ["loading", "error", "registrationProfileData", "activeStep"]
);
const loginTransform = createTransformForBlacklisting(
  "loginReducer",
  loginInitialState,
  ["loading", "error"]
);
const deviceTransform = createTransformForBlacklisting(
  "deviceReducer",
  deviceInitialState,
  ["loading", "error"]
);
const dashboardTransform = createTransformForBlacklisting(
  "dashboardReducer",
  dashboardInitialState,
  ["notifications", "nextPage", "loadingNotifications"]
);
const commonsTransform = createTransformForBlacklisting(
  "commonsReducer",
  commonsInitialState,
  ["error", "isShownErrorAlert", "loading", "isUpdatingThingsToIoTPlatform"]
);
const onboardingTransform = createTransformForBlacklisting(
  "onboardingReducer",
  onboardingInitialState,
  ["loading", "error"]
);
const permissionsTransform = createTransformForBlacklisting(
  "permissionsReducer",
  permissionsInitialState,
  []
);

const ageVerificationTransform = createTransformForBlacklisting(
  "ageVerificationReducer",
  ageVerificationInitialState,
  ["loading", "error"]
);

const persistConfig = {
  key: "root",
  storage: storage,
  transforms: [
    registrationTransform,
    deviceTransform,
    commonsTransform,
    loginTransform,
    shopTransform,
    dashboardTransform,
    onboardingTransform,
    permissionsTransform,
    ageVerificationTransform,
  ],
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = legacy_createStore(pReducer, applyMiddleware(thunk));

export const persistor = persistStore(store);
