import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { AnalyticsEvents, AnalyticsScreenEventNames, AnalyticsTargetEventsNames, aemPages, servicesConstants, webviewUrl } from "../../../_constants";
import {
  AEMHelper,
  propertyTextDefaultEmpty,
} from "../../../_helpers/aem/aemhelper";
import { Tenants } from "../../../_helpers/tenants";
import { Webview } from "../../../_helpers/webview";
import { AgeWarningDisclaimer } from "../../AgeWarningDisclaimer";
import { useSignupFormContext } from "./SignupFormContext";
import { PageSelectorCTA } from "../../PageSelectorCTA";
import { getSupportedServices } from "../../../_actions/appConfig.actions";
import { logAnalyticsEvent } from "../../../_helpers/analytics/logAnalytics";

export function SignupFormStep({
  title,
  customClass,
  children,
  privacyPolicyLabel,
  createAccountLabel,
  contentDisclaimer,
  showAgeWarning = false,
  ctaLabel,
  isValid,
  isLast,
  currentStep,
  nextStep,
  className,
}) {
  const {
    isLoading,
    showPrivacyPolicyPanel,
    showCosPanel,
    updateForm,
    setRegistrationActiveStep,
    setTitle
  } = useSignupFormContext();
  const { getValues } = useFormContext();
  const [dictionary, setDictionary] = useState({});

  const handlePrivacyPolicyClick = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsScreenEventNames.PRIVACY_POLICY})
    if (Tenants.isCanadaDark()) {
      Webview.goToMyVusePrivacyPolicy();
    } else {
      showPrivacyPolicyPanel();
    }
  };

  const handleNextClick = () => {
    if (isValid) {
      if(currentStep === 6){
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CREATE_ACCOUNT})
      }else {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NEXT})
      }
      updateForm(getValues());

      const supportedServices = getSupportedServices()
      if (!Tenants.isGlobal() && supportedServices.includes(servicesConstants.ONBOARDING_AV_YOTI) && currentStep === 4) {
        setRegistrationActiveStep(7)
      } else if (Tenants.isSouthAfrica() && currentStep === 5) {
        setRegistrationActiveStep(8)
      } else if (Tenants.isSouthAfrica() && currentStep === 8) {
        setRegistrationActiveStep(6)
      } else {
        setRegistrationActiveStep(currentStep + 1);
        nextStep();
      }
    }
  };

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.REGISTRATION, {
        REGISTRATION_AVKO_DISCLAIMER_DETAIL_AGE: propertyTextDefaultEmpty,
        REGISTRATION_AVKO_DISCLAIMER_DETAIL: propertyTextDefaultEmpty,
      })
    );
  }, []);

  useEffect(() => {
    //if (title) {
      setTitle(title);
    //}
  }, [title, setTitle]);

  return (
    <>
      <div className={cx("h-100", customClass)}>{children}</div>
      <div className={className}>
        {isLast && contentDisclaimer && (
          <div
            className={cx("page-box-disclaimer", {
              "dark-disclaimer": Tenants.isCanadaDark(),
            })}
          >
            {contentDisclaimer}
          </div>
        )}
        {privacyPolicyLabel && (
          <div className={"signup-info " + (createAccountLabel ? 'mb-3' : '')}>
            <span
              onClick={handlePrivacyPolicyClick}
            >
              {privacyPolicyLabel}
            </span>
          </div>
        )}
        {createAccountLabel && (
          <div className="signup-info mt-0">
            <span
              onClick={showCosPanel}
            >
              {createAccountLabel}
            </span>
          </div>
        )}
      </div>
      {showAgeWarning && (
        <AgeWarningDisclaimer
          title={dictionary.REGISTRATION_AVKO_DISCLAIMER_DETAIL_AGE}
          content={dictionary.REGISTRATION_AVKO_DISCLAIMER_DETAIL}
        />
      )}
      {ctaLabel &&
      <PageSelectorCTA>
        <div className="d-grid">
          {isLoading ? (
            <button
              className="btn btn-primary text-uppercase"
              type="submit"
              disabled={!isValid}
              style={{ paddingTop: "0.6rem", paddingBottom: "0.6rem" }}
            >
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </button>
          ) : (
            <button
              type={isLast ? "submit" : "button"}
              className="btn btn-primary text-uppercase"
              disabled={!isValid}
              {...(!isLast && { onClick: handleNextClick })}
            >
              {ctaLabel}
            </button>
          )}
        </div>
      </PageSelectorCTA>
      }
    </>
  );
}
