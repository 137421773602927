import React from 'react';
import { debug } from "../../_helpers/debug";
import { getDeviceInstanceFromSN, getDeviceThingFromSN } from "../../_helpers/device";
import { AdditionalIntroductionStep, ConclusionStep, DataProcessPanel, InstructionsStep, IntroductionStep, ProgressStep } from "../../_components";
import { aemPages, AnalyticsEvents, AnalyticsScreenEventNames, CUSTOMER, imagesConstants, thingVuseProperties, servicesConstants, AnalyticsTargetEventsNames } from "../../_constants";
import { store } from "../../_store";
import { Commons } from "../../_helpers/commons";
import { commonsActions, deviceActions, getVuseUuid } from "../../_actions";
import { isSupportedService } from "../../_actions/appConfig.actions";
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyHeadingDefaultEmpty, propertyTextDefaultEmpty, propertyTextDefaultEmptyParsed } from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent, logAnalyticsEventForDevice } from "../../_helpers/analytics/logAnalytics";
import { extractUniqueTypesAndConcatenate } from "../../_helpers/utils";


class FactoryReset extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            progressPercentage: 0,
            resetData: false
        }

        this.doFactoryReset = this._doFactoryReset.bind(this);
        this.onProcessFinish = this._onProcessFinish.bind(this);
        this.deleteData = this._deleteData.bind(this);

        this.aem = new AEMHelper();
        this.dictionary = {
            ...this.aem.getDictionary(aemPages.RESET_DEVICE, {
                RESET_DEVICE_BTN_DELETE: propertyCtaItemsDefaultEmpty,
                RESET_DEVICE_CONFIRM_TITLE: propertyTextDefaultEmpty,
                RESET_DEVICE_CONFIRM_CONTENT: propertyTextDefaultEmptyParsed,
                RESET_DEVICE_BTN_CONTINUE: propertyCtaItemsDefaultEmpty,
                RESET_DEVICE_LIST_THIRD: propertyTextDefaultEmptyParsed,
                RESET_DEVICE_BTN_FINISH: propertyCtaItemsDefaultEmpty,
                RESET_DEVICE_ALERT_BODY: propertyTextDefaultEmpty,
                RESET_DEVICE_BTN_CANCEL: propertyCtaItemsDefaultEmpty,
                RESET_DEVICE_COMPLETE: propertyTextDefaultEmpty,
                RESET_DEVICE_LIST_SECOND: propertyTextDefaultEmptyParsed,
                RESET_DEVICE_CHECKBOX: propertyTextDefaultEmpty,
                RESET_DEVICE_PROCESSING_CONTENT: propertyTextDefaultEmpty,
                RESET_DEVICE_BTN_SURE: propertyCtaItemsDefaultEmpty,
                RESET_DEVICE_HEADER: propertyHeadingDefaultEmpty,
                RESET_DEVICE_ALERT_TITLE: propertyTextDefaultEmpty,
                RESET_DEVICE_LIST_FOURTH: propertyTextDefaultEmptyParsed,
                RESET_DEVICE_BODY: propertyTextDefaultEmpty,
                RESET_DEVICE_CONTENT: propertyTextDefaultEmpty,
                RESET_DEVICE_BTN_RESET: propertyCtaItemsDefaultEmpty,
                RESET_DEVICE_LIST_FIRST: propertyTextDefaultEmptyParsed,
                RESET_DEVICE_NOTICE: propertyTextDefaultEmpty,
                RESET_DEVICE_ATTENTION_CONTENT: propertyTextDefaultEmpty,
                RESET_DEVICE_ATTENTION_BTN_CONTINUE: propertyCtaItemsDefaultEmpty,
                RESET_DEVICE_OR_LABEL: propertyTextDefaultEmpty,
                RESET_DEVICE_ASAV_ALERT_TITLE: propertyTextDefaultEmpty,
                RESET_DEVICE_ASAV_ALERT_BODY: propertyTextDefaultEmpty,
            }),

            ...this.aem.getDictionary(aemPages.FW_UPDATE, {
                FW_UPDATE_ALERT_BATTERY: propertyTextDefaultEmpty,
            })
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.isOpen && !prevProps.isOpen) {
            logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
                screen_name: AnalyticsScreenEventNames.RESET_PREFERENCES
            });
        }
    }

    _doFactoryReset(thisProps) {

        const { device, devices } = this.props;
        const { resetData } = this.state;

        thisProps.next();

        const serialNumber = device?.serialNumber;

        setTimeout(() => {
            getDeviceInstanceFromSN(serialNumber).then(deviceInstance => {

                if (null !== deviceInstance && deviceInstance !== undefined) {

                    if(isSupportedService(servicesConstants.QUALTRICS)){
                        if (window?.dataLayer?.length > 0) {
                            console.log("[Qualtrics] paired_devices_counter: ", devices?.length - 1);
                            window.dataLayer.push({'paired_devices_counter': devices?.length - 1});
                            const devs = [
                                ...devices.filter(
                                  (device) => device.serialNumber !== serialNumber
                                ),
                              ];

                            console.log("[Qualtrics] deviceModels: ", devs?.length > 0 ? extractUniqueTypesAndConcatenate(devs) : '');
                            window.dataLayer.push({'deviceModels': devs?.length > 0 ? extractUniqueTypesAndConcatenate(devs) : ''});
                        }
                    }

                    //1. FACTORY RESET DEVICE
                    deviceInstance.setReset().then((result) => {
                        debug("[doFactoryReset] Factory Reset completed, result: ", result);
                        if (result === true) {

                            //2. remove device from reducer
                            this.setState({ progressPercentage: 30 }, () => {
                                store.dispatch(deviceActions.removeDevice(serialNumber));
                                debug("[doFactoryReset] Removing instance");
                                //remove device instance from global var...
                                let deviceInstanceIdx = window.deviceList?.findIndex(_ => _.serialNumber === serialNumber);
                                if (deviceInstanceIdx !== null && deviceInstanceIdx !== -1) {
                                    window.deviceList.splice(deviceInstanceIdx, 1);
                                }
                            });

                            const tenantUserId = Commons.generateTenantUserId(store.getState().onboardingReducer.userPin);

                            if (resetData) {
                                this.setState({ progressPercentage: 60 }, () => {

                                    //3. delete THING from BE
                                    const deviceThing = getDeviceThingFromSN(serialNumber);

                                    store.dispatch(commonsActions.deleteThing(tenantUserId, deviceThing?.uuid, () => {
                                        this.setState({ progressPercentage: 100 });
                                    }, () => {
                                        store.dispatch(commonsActions.showErrorAlert(true));
                                    }))
                                });
                            } else {
                                this.setState({ progressPercentage: 100 });
                            }

                            //extra: remove device type from customer thing
                            store.dispatch(
                                commonsActions.setThings(
                                    [{ type: thingVuseProperties.MKT_DEVICETYPE, data: "NO" }],
                                    tenantUserId,
                                    getVuseUuid(),
                                    CUSTOMER
                                ));

                        } else {
                            store.dispatch(commonsActions.showErrorAlert(true));
                        }

                    });
                } else {
                    store.dispatch(commonsActions.showErrorAlert(true));
                }
            });
        }, 100);
    }


    _deleteData(thisProps) {
        this.setState({ resetData: true }, () => {
            this.doFactoryReset(thisProps);
        })

    }

    _onProcessFinish(processEnded) {
        this.props.handleProcessEnd(processEnded);
    }

    onIntroductionStepStartProcess(thisProps) {
        const { device } = this.props;

        if (device?.batteryInfo?.chargeLevel < 10) {
            thisProps.next();
            return;
        }

        logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
            screen_name: AnalyticsScreenEventNames.FACTORY_RESET_ATTENTION
        });
        thisProps.goToStep(3);
    }

    onIntroductionStepCancelProcess() {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CANCEL})
        this.onProcessFinish(false);
    }

    onAdditionalIntroductionStepConfirmProcess(thisProps) {
        // logAnalyticsEvent(AnalyticsEvents.DELETE_PREFS_RESET_PREFERENCES);
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CONFIRM})
        this.deleteData(thisProps)
    }

    onAdditionalIntroductionStepCancelProcess(thisProps) {
        // logAnalyticsEvent(AnalyticsEvents.SKIP_PREFS_RESET_PREFERENCES);
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.KEEP})
        this.doFactoryReset(thisProps)
    }

    onInstructionsStepConfirmInstructionsProcess(thisProps) {
        logAnalyticsEvent(AnalyticsEvents.SUCCESSFULLY_RESET_PREFERENCES);
        thisProps.next();
    }

    onAdditionalInstructionsStepConfirmInstructionsProcess(thisProps) {

        thisProps.next();
    }

    onConclusionStepEndProcess() {
        
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CLOSE});

        logAnalyticsEvent(AnalyticsEvents.FINISH_RESET_PREFERENCES);
        this.onProcessFinish(true);
    }

    onConfirmBatteryIssue(thisProps) {
        this.onProcessFinish(false);
    }

    render() {

        const { isOpen } = this.props;
        const { progressPercentage } = this.state;

        return (
            <DataProcessPanel title={this.dictionary.RESET_DEVICE_HEADER} isOpen={isOpen}
                steps={[
                    <IntroductionStep
                        key="intro"
                        title={this.dictionary.RESET_DEVICE_CONTENT}
                        okButtonLabel={this.dictionary.RESET_DEVICE_BTN_SURE_0_ctaLabel}
                        cancelButtonLabel={this.dictionary.RESET_DEVICE_BTN_CANCEL_0_ctaLabel}
                        isOpenAlert={true}
                        alertContent={(
                            <>
                                <p className="mb-1"><b>{this.dictionary.RESET_DEVICE_ALERT_TITLE}</b></p>
                                {this.dictionary.RESET_DEVICE_ALERT_BODY}
                            </>
                        )}
                        onStartProcess={(thisProps) => this.onIntroductionStepStartProcess(thisProps)}
                        onCancelProcess={() => this.onIntroductionStepCancelProcess()}
                    />
                    ,
                    <InstructionsStep
                        key="intro-issue"
                        from="reset"
                        okButtonLabel={this.dictionary.RESET_DEVICE_BTN_CANCEL_0_ctaLabel}
                        onConfirmInstructionsProcess={(thisProps) => this.onConfirmBatteryIssue(thisProps)}
                        disableValidation
                        instructions={
                            <React.Fragment>
                                <div className="sliding-panel-mid-icon mb-3 text-center">
                                    <span className="bat-icon-battery" style={{ fontSize: "4rem" }}></span>
                                    <span className="bat-icon-close text-danger" style={{ position: "absolute" }}></span>
                                </div>
                                <div className="sliding-panel-mid-info text-center">
                                    {this.dictionary.FW_UPDATE_ALERT_BATTERY}
                                </div>
                            </React.Fragment>
                        }
                    />,
                    <AdditionalIntroductionStep
                        key="additional-introduction"
                        title={this.dictionary.RESET_DEVICE_BODY}
                        okButtonLabel={this.dictionary.RESET_DEVICE_BTN_DELETE_0_ctaLabel}
                        cancelButtonLabel={this.dictionary.RESET_DEVICE_BTN_RESET_0_ctaLabel}
                        onConfirmProcess={(thisProps) => this.onAdditionalIntroductionStepConfirmProcess(thisProps)}
                        onCancelProcess={(thisProps) => this.onAdditionalIntroductionStepCancelProcess(thisProps)}
                        onEndProcess={() => this.onIntroductionStepCancelProcess()}
                        endButtonLabel={this.dictionary.RESET_DEVICE_BTN_CANCEL_0_ctaLabel}
                    />
                    ,
                    <ProgressStep
                        key="progress"
                        from="reset"
                        title={this.dictionary.RESET_DEVICE_PROCESSING_CONTENT}
                        processCompleteLabel={this.dictionary.RESET_DEVICE_COMPLETE}
                        progressPerc={progressPercentage}
                        progressImage={progressPercentage === 100 ? <span className="process-end-icon"></span> : <span className="bat-icon-trash"></span>}
                    />
                    ,
                    <InstructionsStep
                        key="instuctions"
                        from="reset"
                        title={this.dictionary.RESET_DEVICE_NOTICE}
                        okButtonLabel={this.dictionary.RESET_DEVICE_BTN_CONTINUE_0_ctaLabel}
                        onConfirmInstructionsProcess={(thisProps) => this.onInstructionsStepConfirmInstructionsProcess(thisProps)}
                        checkboxLabel={this.dictionary.RESET_DEVICE_CHECKBOX}
                        instructions={
                            <div className="instructions-container">
                                <ol>
                                    <li><img src={imagesConstants.SYSTEM_ICON} alt="Settings logo" />{this.dictionary.RESET_DEVICE_LIST_FIRST}</li>
                                    <li><img src={imagesConstants.BLUETOOTH_ICON} alt="Bluetooth logo" />{this.dictionary.RESET_DEVICE_LIST_SECOND}</li>
                                    <li><span className="bat-icon-search"></span>{this.dictionary.RESET_DEVICE_LIST_THIRD}</li>
                                    <li><span className="bat-icon-info-circle"></span>{this.dictionary.RESET_DEVICE_LIST_FOURTH}</li>
                                </ol>
                            </div>
                        }
                    />
                    ,
                    <AdditionalIntroductionStep
                        introductionImage={
                            <div className="shipping-mode-instruction-img-wrapper mb-3">
                                <div className='row'>
                                    <div className='col-5 d-flex justify-content-end'>
                                        <img className='pt-2' src={require('../../_assets/img/2A_to_2B_00246_2.webp')} alt="Reset by tap" />
                                    </div>
                                    <div className='col-2 d-flex align-items-center justify-content-center'>
                                        <div className="vertical"></div>
                                        <h5>{this.dictionary.RESET_DEVICE_OR_LABEL}</h5>
                                    </div>
                                    <div className='col-5 d-flex justify-content-start'>
                                        <img src={require('../../_assets/img/2A_to_2B_00246_1.webp')} alt="Reset by plug" />
                                    </div>
                                </div>
                            </div>
                        }
                        key="additional-introduction"
                        title={this.dictionary.RESET_DEVICE_ATTENTION_CONTENT}
                        okButtonLabel={this.dictionary.RESET_DEVICE_ATTENTION_BTN_CONTINUE_0_ctaLabel}
                        onConfirmProcess={(thisProps) => this.onAdditionalInstructionsStepConfirmInstructionsProcess(thisProps)}
                    />
                    ,
                    <ConclusionStep
                        key="end"
                        from="reset"
                        title={this.dictionary.RESET_DEVICE_CONFIRM_TITLE}
                        description={this.dictionary.RESET_DEVICE_CONFIRM_CONTENT}
                        endButtonLabel={this.dictionary.RESET_DEVICE_BTN_FINISH_0_ctaLabel}
                        isOpenAlert={false}
                        alertContent={null}
                        image={<span className="process-end-icon"></span>}
                        onEndProcess={() => this.onConclusionStepEndProcess()}
                    />
                ]
                } />
        )
    }
}


export { FactoryReset };