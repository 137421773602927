import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ShopLastOrderBadge } from './Reorder/ShopLastOrderBadge';
import { ShopFavouritesList } from './Reorder/ShopFavouritesList';
import { ShopFlavourForYou } from './Reorder/ShopFlavourForYou';
import { Commons } from '../../../_helpers/commons';
import { ShopOrderReminder } from './Reorder/ShopOrderReminder';
import { getVuseUuid, shopActions } from '../../../_actions';
import { store } from '../../../_store';
import { shopServices } from '../../../_services';
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyHeadingDefaultEmpty, propertyImageSrcDefaultNull, propertyTextDefaultEmpty } from '../../../_helpers/aem/aemhelper';
import { ShopUtils } from '../../../_helpers/shop';
import {
    aemPages,
    AnalyticsEvents,
    flagStatus,
    imagesConstants,
    thingVuseProperties
} from '../../../_constants';
import { logAnalyticsEvent } from '../../../_helpers/analytics/logAnalytics';
import { getAEMAssetsUrl } from '../../../_actions/appConfig.actions';

class Reorder extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            flavoursForYouSuggestions: [],
            isFavouritesOptinAccepted: false,
            currentOrder: null,
            isOpenOrderDetailsModal: false,
            isOpenFavouritesOptinOpen: false,
            isOpenFlavourForYouOptinOpen: false
        }

        this.aem = new AEMHelper();
        this.dictionary = {
            ...this.aem.getDictionary(aemPages.HOMEPAGE, {
                HEALTH_WARNING: propertyImageSrcDefaultNull,
            }),
            ...this.aem.getDictionary(aemPages.FLAVOUR_FOR_YOU_OPTIN, {
                FLAV_FOR_YOU_BODY: propertyTextDefaultEmpty,
            }),
            ...this.aem.getDictionary(aemPages.MANAGE_FEATURES_AND_PREFERENCES, {
                MNG_SECTION_ALERT: propertyTextDefaultEmpty,
            }),
            ...this.aem.getDictionary(aemPages.SHOP, {
                SHOP_SELECTED_LABEL: propertyTextDefaultEmpty,
                SHOP_BTN_ADD_TO_CART: propertyCtaItemsDefaultEmpty,
                SHOP_BTN_BUY_NOW: propertyCtaItemsDefaultEmpty,
                SHOP_REORDER_TIME_ORDER_REMINDERS_AM: propertyTextDefaultEmpty,
                SHOP_REORDER_BTN_SAVE: propertyCtaItemsDefaultEmpty,
                SHOP_REORDER_IN_STOCK_LABEL: propertyTextDefaultEmpty,
                SHOP_REORDER_FREQ_ORDER_REMINDERS_3_WEEKS: propertyTextDefaultEmpty,
                SHOP_REORDER_START_ORDER_REMINDERS_TODAY: propertyTextDefaultEmpty,
                SHOP_REORDER_ORDER_REMINDERS_TITLE: propertyTextDefaultEmpty,
                SHOP_REORDER_MY_LAST_ORDERS: propertyTextDefaultEmpty,
                SHOP_REORDER_FREQ_ORDER_REMINDERS_CONTENT: propertyTextDefaultEmpty,
                SHOP_REORDER_FREQ_ORDER_REMINDERS_2_WEEKS: propertyTextDefaultEmpty,
                SHOP_REORDER_START_ORDER_REMINDERS_CONTENT: propertyTextDefaultEmpty,
                SHOP_REORDER_MANAGE_FAV_ADD_FAV_TITLE: propertyTextDefaultEmpty,
                SHOP_REORDER_NO_PAST_ORDERS_HEADER: propertyHeadingDefaultEmpty,
                SHOP_REORDER_TIME_ORDER_REMINDERS_PM: propertyTextDefaultEmpty,
                SHOP_REORDER_FREQ_ORDER_REMINDERS_HEADER: propertyHeadingDefaultEmpty,
                SHOP_REORDER_FREQ_ORDER_REMINDERS_DAILY: propertyTextDefaultEmpty,
                SHOP_REORDER_SEE_OLDER: propertyTextDefaultEmpty,
                SHOP_REORDER_UNAVAILABLE_TITLE: propertyTextDefaultEmpty,
                SHOP_REORDER_FLAV_FOR_YOU_BODY: propertyTextDefaultEmpty,
                SHOP_REORDER_ORDER_TITLE: propertyTextDefaultEmpty,
                SHOP_REORDER_MANAGE_FAV_HEADER: propertyHeadingDefaultEmpty,
                SHOP_REORDER_TIME_ORDER_REMINDERS_CONTENT: propertyTextDefaultEmpty,
                SHOP_REORDER_FREQ_ORDER_REMINDERS_WEEKLY: propertyTextDefaultEmpty,
                SHOP_REORDER_MY_FAVOURITES_TITLE: propertyTextDefaultEmpty,
                SHOP_REORDER_ORDER_LABEL: propertyTextDefaultEmpty,
                SHOP_REORDER_ORDER_REMINDERS_BODY: propertyTextDefaultEmpty,
                SHOP_REORDER_BTN_ORDER_ALL_FAV: propertyCtaItemsDefaultEmpty,
                SHOP_REORDER_START_ORDER_REMINDERS_2_DAYS: propertyTextDefaultEmpty,
                SHOP_REORDER_MANAGE_FAV_ADD_FAV_BODY: propertyTextDefaultEmpty,
                SHOP_REORDER_PREV_ORDERS_HEADER: propertyHeadingDefaultEmpty,
                SHOP_REORDER_BTN_REORDER: propertyCtaItemsDefaultEmpty,
                SHOP_REORDER_FLAV_FOR_YOU_TITLE: propertyTextDefaultEmpty,
                SHOP_REORDER_TITLE: propertyTextDefaultEmpty,
                SHOP_REORDER_IN_STOCK: propertyTextDefaultEmpty,
                SHOP_REORDER_PREV_ORDERS_SORT_BY_LABEL: propertyTextDefaultEmpty,
                SHOP_REORDER_MANAGE: propertyTextDefaultEmpty,
                SHOP_REORDER_TIME_ORDER_REMINDERS_BTN_SAVE: propertyCtaItemsDefaultEmpty,
                SHOP_REORDER_MANAGE_FAV_BTN_VIEW_FLAV: propertyCtaItemsDefaultEmpty,
                SHOP_REORDER_START_ORDER_REMINDERS_TOMORROW: propertyTextDefaultEmpty,
                SHOP_REORDER_UNAVAILABLE: propertyTextDefaultEmpty,
                SHOP_REORDER_ITEMS_AVAILABLE_REORDER_TITLE: propertyTextDefaultEmpty,
                SHOP_REORDER_NO_PAST_ORDERS_CONTENT: propertyTextDefaultEmpty,
                SHOP_REORDER_FREQ_ORDER_REMINDERS_MONTHLY: propertyTextDefaultEmpty,
                SHOP_REORDER_ITEMS: propertyTextDefaultEmpty,
                SHOP_REORDER_NO_PAST_ORDERS_BTN_EXPLORE: propertyCtaItemsDefaultEmpty,
                SHOP_REORDER_FREQ_ORDER_REMINDERS_BTN_NEXT: propertyCtaItemsDefaultEmpty,
                SHOP_REORDER_MY_FAVOURITES_CONTENT: propertyTextDefaultEmpty,
                SHOP_REORDER_FREQUENCY: propertyTextDefaultEmpty,
                SHOP_REORDER_DAY: propertyTextDefaultEmpty,
                SHOP_REORDER_TIME: propertyTextDefaultEmpty,
                SHOP_REORDER_NIC_LABEL: propertyTextDefaultEmpty,
                SHOP_REORDER_QTY_LABEL: propertyTextDefaultEmpty,
                SHOP_REORDER_ADD_FAV_HEADER: propertyHeadingDefaultEmpty,
                SHOP_REORDER_ADD_FAV_CONTENT: propertyTextDefaultEmpty,
                SHOP_REORDER_ADD_FAV_BTN_VIEW_FLAV: propertyCtaItemsDefaultEmpty,
                SHOP_BG_REORDER: propertyImageSrcDefaultNull,
                SHOP_BG_FAVOURITES: propertyImageSrcDefaultNull,
                SHOP_BG_LASTORDER: propertyImageSrcDefaultNull,
                SHOP_BG_SUGGESTIONS: propertyImageSrcDefaultNull,
            }),
        }

        this.handleAcceptOptinFavourites = this._handleAcceptOptinFavourites.bind(this);
        this.updateSuggestions = _.debounce(this._updateSuggestions.bind(this), 500);
        this.handleRemoveFlavourForYouProduct = this._handleRemoveFlavourForYouProduct.bind(this);
        this.handleCheckoutFavourites = this._handleCheckoutFavourites.bind(this);
        this.handleOpenBasketPanel = this._handleOpenBasketPanel.bind(this);
        this.handleOpenCheckoutPanel = this._handleOpenCheckoutPanel.bind(this);
        this.handleOpenFavouritesOptin = this._handleOpenFavouritesOptin.bind(this);
        this.handleOpenFlavourForYouOptinModal = this._handleOpenFlavourForYouOptinModal.bind(this);

    }

    componentDidMount() {

        this.updateSuggestions();

        this.setState({
            isFavouritesOptinAccepted: Commons.getCustomerProperty(thingVuseProperties.OPT_IN_FAVOURITES) === flagStatus.ACCEPTED
        });
        window.document.addEventListener("openFlavoursForYou", this.handleOpenFlavourForYouOptinModal);
        window.document.addEventListener("openFavourites", this.handleOpenFavouritesOptin);
    }

    componentWillUnmount() {
        window.document.removeEventListener("openFlavoursForYou", this.handleOpenFlavourForYouOptinModal);
        window.document.removeEventListener("openFavourites", this.handleOpenFavouritesOptin);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.wishlist !== this.props.wishlist) {
            //since suggestions are based on wishlist, when it's updated also suggestions must be updated
            this.updateSuggestions();
        }

        if (prevProps.activeViewIndex !== this.props.activeViewIndex) {
            if (this.props.activeViewIndex === 1) {
                this.setState({
                    isFavouritesOptinAccepted: Commons.getCustomerProperty(thingVuseProperties.OPT_IN_FAVOURITES) === flagStatus.ACCEPTED
                });
            }
        }

        if (prevState.flavoursForYouSuggestions !== this.state.flavoursForYouSuggestions) {
            console.log('[SHOP] flavoursForYouSuggestions', this.state.flavoursForYouSuggestions);
        }

    }

    /**
     * Load updated suggestions for Flavour For You feature
     */
    _updateSuggestions() {

        const tenantUserId = Commons.generateTenantUserId(store.getState().onboardingReducer.userPin);
        shopServices.getFlavourForYouProducts(tenantUserId, getVuseUuid())
            .then((response) => {
                if (response?.data?.result?.code === 0 && response?.data?.data?.products) {
                    this.setState({
                        flavoursForYouSuggestions: response.data.data.products,
                    });
                }
            })
    }

    _handleAcceptOptinFavourites() {
        Commons.acceptOptIn(thingVuseProperties.OPT_IN_FAVOURITES)
            .then(() => {
                logAnalyticsEvent(AnalyticsEvents.ENABLE_FLAVOUR_FAVOURITE)
                this.setState({ isFavouritesOptinAccepted: true });
            });
    }

    _handleRemoveFlavourForYouProduct(id) {
        const tenantUserId = Commons.generateTenantUserId(store.getState().onboardingReducer.userPin);
        let currentUnfavourites = Commons.getCustomerProperty(thingVuseProperties.UNFAVOURITES);
        if (
            currentUnfavourites !== null &&
            currentUnfavourites !== undefined
        ) {
            currentUnfavourites = Commons.parseJSONSafely(currentUnfavourites);
            if (Commons.isValidArray(currentUnfavourites)) {
                currentUnfavourites.push(id);
            } else {
                currentUnfavourites = [id];
            }
        } else {
            currentUnfavourites = [id];
        }

        store.dispatch(
            shopActions.removeFlavourForYouProduct(tenantUserId, JSON.stringify(currentUnfavourites),
                () => {
                    logAnalyticsEvent(AnalyticsEvents.REMOVED_FFY, {
                        item_id: id
                    })
                    //update current suggestions array
                    this.setState({
                        flavoursForYouSuggestions: this.state.flavoursForYouSuggestions.filter(item => item.id !== id)
                    })
                }
            )
        );
    }

    _handleCheckoutFavourites() {
        let currentBasket = store.getState().shopReducer.basket;
        let currentWishlist = store.getState().shopReducer.wishlist;

        // console.log('currentWishlist', currentWishlist);
        // console.log('currentBasket', currentBasket);

        const remoteWishlist = ShopUtils.getUserWishlist();
        // console.log('remoteWishlist', remoteWishlist);


        Promise.all(
            currentWishlist.map((wishlistEl) => {
                return new Promise((resolve) => {

                    /**
                     * Before acting on basket, check variant availability;
                     * if availability is <= 0 variant is not added to basket
                     */
                    if (wishlistEl.quantity > 0) {
                        const basketIdx = currentBasket.findIndex(basketEl => basketEl.variant_id === wishlistEl.variant_id);
                        if (basketIdx !== -1) {
                            //if variant is present in basket update it with the new one
                            store.dispatch(shopActions.updateQuantityInBasket({
                                ...currentBasket[basketIdx],
                                basket: wishlistEl.favouriteQty ?? remoteWishlist[wishlistEl.variant_id] //we don't sum here the quantity already in basket, it's done in reducer
                            }, resolve));
                        } else {
                            //if variant is not present in basket add to it
                            store.dispatch(shopActions.addVariantToBasket({
                                ...wishlistEl,
                                basket: wishlistEl.favouriteQty ?? remoteWishlist[wishlistEl.variant_id]
                            }, resolve));
                        }
                    }
                })
            })
        ).then(() => {
            this.handleOpenBasketPanel();
        });
    }

    _handleOpenBasketPanel() {
        if (typeof this.props.onOpenBasketPanel === "function") {
            this.props.onOpenBasketPanel();
        }
    }

    _handleOpenCheckoutPanel() {
        if (typeof this.props.onStartCheckout === "function") {
            this.props.onStartCheckout();
        }
    }

    _handleOpenFavouritesOptin() {
        this.setState({ isOpenFavouritesOptinOpen: false }, () => {
            if (Commons.getCustomerProperty(thingVuseProperties.OPT_IN_FAVOURITES) !== flagStatus.ACCEPTED) {
                this.setState({ isOpenFavouritesOptinOpen: true });
            }
        });
    }

    _handleOpenFlavourForYouOptinModal() {
        if (Commons.getCustomerProperty(thingVuseProperties.OPT_IN_FLAVOUR_DISC) !== flagStatus.ACCEPTED) {
            this.setState({ isOpenFlavourForYouOptinOpen: true });
        }
    }

    render() {

        const {
            basket,
            wishlist,
            lastOrder,
            navigateToFlavours,
            navigateToVuseWorld
        } = this.props;

        const {
            isFavouritesOptinAccepted,
            flavoursForYouSuggestions,
            isOpenFavouritesOptinOpen,
            isOpenFlavourForYouOptinOpen
        } = this.state;

        return (
            <div className="shop-home" style={{
                paddingBottom: basket.length > 0 ? '4.90rem' : isFavouritesOptinAccepted ? '3.5rem' : '0.5rem'
            }}>
                <div className="shop-reorder" style={{
                    backgroundImage: `url("${getAEMAssetsUrl()}${this.dictionary.SHOP_BG_REORDER?.desktop?.src}")`
                }}>
                    <ShopLastOrderBadge lastOrder={lastOrder}
                        dictionary={this.dictionary}
                        onOpenBasketPanel={this.handleOpenBasketPanel}
                        onStartCheckout={this.handleOpenCheckoutPanel}
                        onExplore={navigateToVuseWorld}
                    />
                </div>
                <div className='reorder-section'>
                    <ShopFavouritesList items={wishlist}
                        onAcceptOptin={this.handleAcceptOptinFavourites}
                        isAcceptedOptin={isFavouritesOptinAccepted}
                        navigateToFlavours={navigateToFlavours}
                        dictionary={this.dictionary}
                        onCheckoutFavourites={this.handleCheckoutFavourites}
                        onOpenBasketPanel={this.handleOpenBasketPanel}
                        onStartCheckout={this.handleOpenCheckoutPanel}
                        openOptinModal={isOpenFavouritesOptinOpen}
                    />
                    <ShopOrderReminder isDisabled={wishlist.length <= 0} dictionary={this.dictionary} />
                </div>
                <ShopFlavourForYou items={flavoursForYouSuggestions}
                    isAcceptedFavouritesOptin={isFavouritesOptinAccepted}
                    navigateToFlavours={navigateToFlavours}
                    dictionary={this.dictionary}
                    onRemoveProduct={this.handleRemoveFlavourForYouProduct}
                    onOpenBasketPanel={this.handleOpenBasketPanel}
                    onStartCheckout={this.handleOpenCheckoutPanel}
                    onOpenFavouritesOptin={this.handleOpenFavouritesOptin}
                    reloadSuggestions={this.updateSuggestions}
                    isOpenOptinModal={isOpenFlavourForYouOptinOpen}
                />
            </div>
        );

    }
}


function mapStateToProps(state) {
    return {
        wishlist: state.shopReducer.wishlist,
        basket: state.shopReducer.basket
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

const connnectedComponent = connect(mapStateToProps, mapDispatchToProps)(Reorder);
export { connnectedComponent as Reorder };