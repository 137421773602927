import React, { useEffect, useState } from "react";
import { aemPages, imagesConstants } from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { PageSelectorCTA } from "../PageSelectorCTA";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { AnalyticsEvents, AnalyticsTargetEventsNames, AnalyticsScreenEventNames } from "../../_constants";

export function ReconnectLoader() {
  const [dictionary, setDictionary] = useState({});
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isMSSStarted, setIsMSSStarted] = useState(false);
  const [isMSSSuccess, setIsMSSSuccess] = useState(false);
  const [isMSSFailure, setisMSSFailure] = useState(false);

  const showOverlay = () => {
    setIsOverlayVisible(true);
  };

  const hideOverlay = () => {
    setIsOverlayVisible(false);
  };

  const handleError = () => {
    console.debug("[MSS] - handleError");
    logAnalyticsEvent(AnalyticsEvents.DEVICE_MSS_FAILURE);
    setisMSSFailure(true);
  };

  const handleDisconnect = () => {
    logAnalyticsEvent(AnalyticsEvents.DEVICE_MSS_SUCCESS);
    setIsMSSSuccess(true);
  };

  const handleClose = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.RECONNECT}); 
    setIsMSSStarted(false);
    setIsMSSSuccess(false);
    setisMSSFailure(false);
    setIsOverlayVisible(false);
  };

  const handleMSSStart = () => {
    setIsMSSStarted(true);
  };

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.MSS, {
        MSS_UPDATE_COMPLETED_TITLE: propertyTextDefaultEmpty,
        MSS_UPDATE_FAILED_TITLE: propertyTextDefaultEmpty,
        MSS_UPDATE_CONTENT: propertyTextDefaultEmpty,
        MSS_BTN_CLOSE: propertyCtaItemsDefaultEmpty,
      })
    );

    window.document.addEventListener("pairingStart", showOverlay);
    window.document.addEventListener("pairingSuccess", hideOverlay);
    window.document.addEventListener("pairingError", handleError);
    window.document.addEventListener("searchingError", hideOverlay);
    window.document.addEventListener("disconnectEvent", handleDisconnect);
    window.document.addEventListener("mssStart", handleMSSStart);

    return () => {
      window.document.removeEventListener("pairingStart", showOverlay);
      window.document.removeEventListener("pairingSuccess", hideOverlay);
      window.document.removeEventListener("pairingError", handleError);
      window.document.removeEventListener("searchingError", hideOverlay);
      window.document.removeEventListener("disconnectEvent", handleDisconnect);
      window.document.removeEventListener("mssStart", handleMSSStart);
    };
  }, []);

  useEffect(() => { 
      if(isOverlayVisible){
          logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.PAIRING_MSS_CONNECT})
      }
  }, [isOverlayVisible]);

  return (
    isOverlayVisible && (
      <div className="connecting-overlay">
        {isMSSStarted && (isMSSSuccess || isMSSFailure) ? (
          <div className="d-flex flex-column h-100 w-100">
            <div className="connecting-overlay-wrapper">
              <img
                src={
                  isMSSSuccess
                    ? imagesConstants.SUCCESS_ICON
                    : imagesConstants.FAILURE_ICON
                }
                alt="mss status icon"
              />
              <span className="connecting-overlay-label">
                {isMSSSuccess
                  ? dictionary?.MSS_UPDATE_COMPLETED_TITLE || "Update completed"
                  : dictionary?.MSS_UPDATE_FAILED_TITLE || "Update failed"}
                <span>
                  {dictionary.MSS_UPDATE_CONTENT ||
                    "Please try to reconnect your device again."}
                </span>
              </span>
            </div>
            <PageSelectorCTA sticky={false} className="mt-auto">
              <div className="d-grid">
                <button
                  className="btn btn-primary text-uppercase"
                  onClick={handleClose}
                >
                  {dictionary?.MSS_BTN_CLOSE_0_ctaLabel || "CLOSE"}
                </button>
              </div>
            </PageSelectorCTA>
          </div>
        ) : (
          <div className="spinner-border text-secondary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
      </div>
    )
  );
}
