const REQUEST_TIMEOUT = 25

export function requestInterceptor(client) {
  client.interceptors.request.use(
    (config) => {
      console.log('%c[PQueue] Request was sent', 'color: red; font-weight:bold', config);

      //Set headers
      config = {
        ...config,
        timeout: (REQUEST_TIMEOUT * 1000),
        timeoutErrorMessage: 'Request timeout'
      }

      return config;
    },
    error => {
      // handle the error
      return Promise.reject(error)
    }
  )
}
