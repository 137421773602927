import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FadingList, Header, PageSelectorCTA } from "..";
import { AnalyticsErrorNames, AnalyticsEvents, AnalyticsFieldNames, AnalyticsTargetEventsNames, imagesConstants } from "../../_constants";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { Commons } from "../../_helpers/commons";
import { isNil } from "lodash";
import { CustomInlineAlert } from "../CustomInlineAlert";
import { setZip } from "../../_actions";
import { debug } from '../../_helpers/debug';
import { CustomLoadingButton } from '../CustomLoadingButton';

const POSTAL_RE = /^[ABCEGHJKLMNPRSTVXY]\d[A-Z] *\d[A-Z]\d$/

const FIELD_POSTALCODE = 'postalCode'

export const CountrySelectorForm = (props) => {
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [countryLabel, setCountryLabel] = useState(null);
  const [postalCode, setPostalCode] = useState('');
  const [isValidPostalCode, setIsValidPostalCode] = useState(true);
  const [afterBlurPostalCode, setAfterBlurPostalCode] = useState(false);

  useEffect(() => {
    if (props.countries?.length > 0) {
      setCountries(_.groupBy(props.countries, (item) => [item['country_iso'], item['country_label']]));

      /* If I receive the default countr iso read by the locale (only for Germany, filter done at the top)
         I simulate the click on the selection of the country */
      if(!isNil(props?.defaultCountry)){
        //console.log("Default Country: ", props?.defaultCountry);
        const defaultTenant = props.countries.find(e => e.country_iso.toLowerCase() == props.defaultCountry.toLowerCase());
        if(!isNil(defaultTenant)){
          onClickCountry([defaultTenant]);
        }
      }
    }
  }, [props.countries]);

  // useEffect(() => {
  //     console.log("selectedCountry");
  //     console.log(selectedCountry);
  //     console.log("selectedProvince");
  //     console.log(selectedProvince);
  //     console.log("selectedLanguage");
  //     console.log(selectedLanguage);
  //     console.log("------------------------------");
  // }, [selectedCountry, selectedProvince, selectedLanguage]);

  const onSubmit = () => {
    if (typeof props.onSubmit === "function") {

      logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NEXT})

      setZip(postalCode)

      props.onSubmit({
        language: selectedLanguage,
        country: selectedProvince ?? selectedCountry[0],
      });
    }
  };

  /**
   * Handle user click on country option
   * @param {*} country
   */
  const onClickCountry = (country) => {
    // console.log("[COUNTRY SELECTOR] selected country", country);
    logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.COUNTRY, field_value: country[0].country_iso})

    //if there is already an option selected, then revove this selection
    //and selection on province and locale
    if (selectedCountry) {
      setSelectedCountry(null);
      setSelectedProvince(null);
      setSelectedLanguage(null);
      setLanguages([]);
    } else {
      //if no country is already selected, set the one user clicked on as selected
      setSelectedCountry(country);

      //if country as 0 or 1 province
      if (country?.length === 1) {
        //if country as 1 province
        if (!!country[0].province_iso) {
          //set selected province
          setSelectedProvince(country[0]);
          //populate languages array
          setLanguages(country[0].supported_locales);
        } else {
          //if country as no provinces

          //if only one language is supported for current country
          //set it as selected language
          //and user will not be able to switch to another
          if (country[0]?.supported_locales?.length === 1) {
            setSelectedLanguage(country[0].supported_locales[0]);
            setLanguages(country[0].supported_locales);
            logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.LANGUAGE, field_value: JSON.stringify(country[0].supported_locales[0])})
          } else {
            //if there are multiple languages to choose for current country
            //populate languages array
            setLanguages(country[0].supported_locales);
            //if there is, in languages array, the language currently used from user
            //on his device, set it as preselected option
            //user will be able to switch deselecting preselected option
            if (Commons.isValidArray(navigator.languages)) {
              let found = false; //"found" used because in navigator.languages lesser is the index of the laungage found, more likely this is the one user will use

              if (Commons.isValidArray(country[0].supported_locales)) {
                country[0].supported_locales.forEach((language) => {
                  if (!found && Object.keys(language)[0]) {
                    const index = navigator.languages.indexOf(
                      Object.keys(language)[0].replace("_", "-").toLowerCase()
                    );
                    if (index !== -1) {
                      found = true;
                      setSelectedLanguage(language);
                    }
                  }
                });
              }
            }
          }
        }
      }
    }
  };

  const selectLanguage = (province) => {
    console.log("[COUNTRY SELECTOR] selected province", province);

    if (province) {
      //if there is no already selected province
      //set user preference as selected province and populate languages array
      //with its supported locales
      setLanguages(province.supported_locales);

      //if only one language is suspported for selected province
      //then set it as selected language
      if (province?.supported_locales?.length === 1) {
        setSelectedLanguage(province.supported_locales[0]);
        logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.LANGUAGE, field_value:province.supported_locales[0]})
      }

      //if there is, in languages array, the language currently used from user
      //on his device, set it as preselected option
      //user will be able to switch deselecting preselected option
      if (Commons.isValidArray(navigator.languages)) {
        let found = false;

        if (Commons.isValidArray(province.supported_locales)) {
          province.supported_locales.forEach((language) => {
            if (!found && Object.keys(language)[0]) {
              const index = navigator.languages.indexOf(
                Object.keys(language)[0].replace("_", "-").toLowerCase()
              );
              if (index !== -1) {
                found = true;
                setSelectedLanguage(language);
              }
            }
          });
        }
      }
    } else {
      setSelectedLanguage(null);
      setLanguages([]);
    }
  }

  useEffect(() => {
    // console.log("selectedCountry", selectedCountry);
    // console.log("selectedProvince",selectedProvince);
    // console.log("selectedLanguage", selectedLanguage);
    if (selectedProvince) {
      setCountryLabel(selectedProvince.country_iso);
    } else {
      if (Array.isArray(selectedCountry)) {
        setCountryLabel(selectedCountry[0]?.country_iso);
      } else if (selectedCountry) {
        setCountryLabel(selectedCountry.country_iso);
      }
    }
  }, [selectedCountry, selectedProvince]);

  /**
   * Handle user click on language option
   * @param {*} language
   * @returns
   */
  const onClickLanguage = (language) => {
    // console.log("[COUNTRY SELECTOR] selected language", language);

    //if there is only one language supported
    //then user will not be able to deselect is
    //as it is preselected and the only one supported
    if (languages?.length === 1) {
      logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.LANGUAGE, field_value:languages[0]})
      return;
    }

    if (selectedLanguage) {
      setSelectedLanguage(null);
    } else {
      setSelectedLanguage(language);
      logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.LANGUAGE, field_value:Object.keys(language)[0]})
    }
  };

  useEffect(() => {
    if (selectedCountry?.length > 1 && afterBlurPostalCode) {
      const foundProvince = selectedCountry.find(province => {
        if (province?.postal_codes) {
          return !isNil(province.postal_codes.find(postalCodeCharacter => postalCodeCharacter === postalCode.substring(0, 1)))
        } else {
          return false;
        }
      })

      if (foundProvince && POSTAL_RE.test(postalCode)) {
        setIsValidPostalCode(true)
        setSelectedProvince(foundProvince)
        selectLanguage(foundProvince)
      } else {
        setIsValidPostalCode(false)
        setSelectedProvince(null)
        selectLanguage(null)
      }
    }
  }, [postalCode, afterBlurPostalCode])

  const onChangePostalCode = (event) => {
    let target = event.target, position = target.selectionEnd, length = target.value.length

    target.value = target.value.replace(' ', '').replace(/(.{3})/, '$1 ').trim()
    target.selectionEnd = position += ((target.value.charAt(position - 1) === ' ' && target.value.charAt(length - 1) === ' ' && length !== target.value.length) ? 1 : 0)

    setPostalCode(target.value.toUpperCase())
  }

  const handleBlurPostalCode = () => {
    setAfterBlurPostalCode(true)
    if(isValidPostalCode === false){
      logAnalyticsEvent(AnalyticsEvents.VALIDATION_ERROR, {field_name: AnalyticsFieldNames.POSTAL_CODE, field_error: AnalyticsErrorNames.WRONG_FORMAT})
    } else {
      logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.POSTAL_CODE})
    }
  }

  return (
    <React.Fragment>
      <Header subHeader="Select Country" />
      <div className="country-selector-wrapper h-100">
        <div className="h-100 d-flex flex-column">
          <div className="country-selector-list">
            <div className="page-label">COUNTRY OF RESIDENCE</div>
            <div className="d-grid">
              <FadingList
                items={_.keys(countries)?.map((country, i) => {
                  return {
                    item: countries[country],
                    value: countries[country][0].country_label,
                    key: i,
                    flag:
                      imagesConstants?.[`FLAG_${countries[country][0].country_iso}`] ||
                      imagesConstants.FLAG_CA,
                  };
                })}
                selectedItem={selectedCountry}
                handleClick={onClickCountry}
              />
            </div>
          </div>

          {(selectedCountry?.length > 1 &&
            <div>
              <div className="country-selector-list">
                <label className="page-label" htmlFor={FIELD_POSTALCODE}>
                  POSTAL CODE
                </label>
                <input
                  name={FIELD_POSTALCODE}
                  id={FIELD_POSTALCODE}
                  autoComplete="off"
                  className="form-control"
                  placeholder={'Postal Code'}
                  type="text"
                  value={postalCode}
                  onChange={event => onChangePostalCode(event)}
                  onBlur={handleBlurPostalCode}
                  maxLength={7}
                />
              </div>

              <div className="information-block d-flex">
                <span className="bat-icon-alert-circle" style={{marginRight: '6px'}}></span>
                <span className="information-block-label">
                  Your postal code allows us to validate your legal age in your province of residence and helps us give you the most personalized experience.
                </span>
              </div>

              <div className="country-selector-list">
                <CustomInlineAlert isShown={isValidPostalCode === false} alertType="error" className="m-0">
                  {'Please enter a valid postal code'}
                </CustomInlineAlert>
              </div>
            </div>
          )}

          {languages.length > 0 && (
            <div className="country-selector-list" style={{marginTop: '10px'}}>
              <div className="page-label">LANGUAGE</div>
              <div className="d-grid">
                <FadingList
                  items={languages.map((language, i) => {
                    return {
                      item: language,
                      value: `${Object.values(language)[0]} (${countryLabel})`,
                      key: i,
                    };
                  })}
                  selectedItem={selectedLanguage}
                  handleClick={onClickLanguage}
                />
              </div>
            </div>
          )}

          {/**
                    <div className="information-block">
                        <span className="bat-icon-alert-circle"></span> <span className="information-block-label">Please refer against your ID before confirming</span>
                    </div>
                    */}
          <PageSelectorCTA>
            <div className="d-grid">
                {/*<button
                  className="btn btn-primary text-uppercase"
                  disabled={!selectedLanguage}
                  onClick={onSubmit}
                >
                  confirm
                 </button>*/}
                <CustomLoadingButton isLoading={props.loading}
                    validationMethod={!selectedLanguage ? false : selectLanguage}
                    buttonLabel={"confirm"}
                    onClickMethod={onSubmit} />
              </div>
          </PageSelectorCTA>
        </div>
      </div>
    </React.Fragment>
  );
};
