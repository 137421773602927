import { TimelineMax as Timeline, Power1, gsap } from 'gsap';
import { ANIMATION_DURATION, routingConstants } from '../../_constants';
import sdk from '../../_vendors/nodes';
import { history } from '../history';
import { convertQueryStringToObject, convertObjectToQueryString, convertFragmentIdentifierToObject } from '../utils';
/**
 * 
 * @param {*} path 
 */
export function buildURI(path) {
    return process.env.PUBLIC_URL + path;
}

export function buildExtPageURI(queryObj = {}) {
    const queryString = convertObjectToQueryString(queryObj);
    return buildURI(`/ext-page${queryString}`);
}

const duration = ANIMATION_DURATION / 1000

export function startSlideTimeline(node, parentNode) {
    if (node == null || parentNode == null) {
        return;
    }
    gsap.killTweensOf(node);
    // Set initial position and styles
    gsap.set(node, {
        position: "absolute",
        left: 0,
        x: '100%',
        zIndex: 10000
    });
    gsap.set(parentNode.current, { overflow: "hidden" })
    // Create the animation for the incoming component
    gsap.to(node, {
        duration: duration,
        x: 0,
        zIndex: 3,
        onComplete: completeCall,
        onCompleteParams: [node, parentNode.current],
        clearProps: "transform,zIndex"
    });
}

export function exitSlideTimeline(node, parentNode) {
    if (node == null || parentNode == null) {
        return;
    }
    gsap.killTweensOf(node);
    // Set initial position and styles
    gsap.set(node, {
        position: "absolute",
        left: 0,
        top: 0,
        x: 0,
        zIndex: 10000
    });
    // Create the animation for the incoming component
    gsap.to(node, {
        duration: duration,
        x: '100%',
        zIndex: 2,
        //clearProps: "transform"
    });
}

export function startSlideLeftTimeline(node, parentNode) {
    if (node == null || parentNode == null) {
        return;
    }
    gsap.killTweensOf(node);
    // Set initial position and styles
    gsap.set(node, {
        position: "absolute",
        left: 0,
        top: 0,
        x: '-100%',
        zIndex: 10000
    });
    // Create the animation for the incoming component
    gsap.to(node, {
        duration: duration,
        x: 0,
        zIndex: 3,
        clearProps: "transform"
    });
}

export function exitSlideLeftTimeline(node, parentNode) {
    if (node == null || parentNode == null) {
        return;
    }
    gsap.killTweensOf(node);
    // Set initial position and styles
    gsap.set(node, {
        position: "absolute",
        left: 0,
        top: 0,
        x: 0,
        zIndex: 10000
    });
    // Create the animation for the incoming component
    gsap.to(node, {
        duration: duration,
        x: '-100%',
        zIndex: 2,
        //clearProps: "transform"
    });
}

export function startPopTimeline(node, parentNode) {
    if (node == null || parentNode == null) {
        return;
    }
    gsap.killTweensOf(node);
    // Set initial position and styles
    gsap.set(node, {
        position: "absolute",
        left: 0,
        scale: 0,
        zIndex: 10000
    });
    // Create the animation for the incoming component
    gsap.to(node, {
        duration: duration,
        scale: 1,
        overflow: "hidden",
        
        onComplete: completeCall,
        onCompleteParams: [node, parentNode.current],
        clearProps: "transform,overflow,zIndex"
    });

    //fix for homepage drawer flashing
    const drawers = document.getElementsByTagName("reach-portal")
    gsap.to(drawers[0], {
        display: 'none',
        duration: duration
    })
}

export function exitPopTimeline(node, parentNode) {
    if (node == null || parentNode == null) {
        return;
    }
    gsap.killTweensOf(node);
    // Set initial position and styles
    gsap.set(node, {
        position: "absolute",
        left: 0,
        top: 0,
        scale: 1,
        overflow: "hidden",
        zIndex: 10000
    });
    // Create the animation for the incoming component
    gsap.to(node, {
        duration: duration,
        scale: 0,
        //zIndex: 2,
        clearProps: "overflow"
    });

    //fix for homepage drawer flashing
    const drawers = document.getElementsByTagName("reach-portal")
    /*gsap.to(drawers[0], {
        display: 'none',
        duration: 0
    })*/
    gsap.set(drawers[0], {
        position: "absolute",
        left: 0,
        top: 0,
        scale: 1,
        overflow: "hidden",
        zIndex: 10000,
        width: '100%',
        height: '100%'
    });
    // Create the animation for the incoming component
    gsap.to(drawers[0], {
        duration: duration,
        scale: 0,
        //zIndex: 2,
        clearProps: "overflow"
    });
}


export function startInstantTimeline(node, parentNode) {
    if (node == null || parentNode == null) {
        return;
    }
    gsap.killTweensOf(node);
    // Set initial position and styles
    gsap.set(node, {
        position: "absolute",
        left: 0,
        zIndex: 10000
    });
    // Create the animation for the incoming component
    gsap.to(node, {
        duration: duration,
        overflow: "hidden",
        
        onComplete: completeCall,
        onCompleteParams: [node, parentNode.current],
        clearProps: "transform,overflow,zIndex"
    });
}

export function exitInstantTimeline(node, parentNode) {
    if (node == null || parentNode == null) {
        return;
    }
    gsap.killTweensOf(node);
    // Set initial position and styles
    gsap.set(node, {
        position: "absolute",
        left: 0,
        top: 0,
        overflow: "hidden"
    });
    // Create the animation for the incoming component
    gsap.to(node, {
        duration: duration,
        display: 'none',
        //zIndex: 2,
        clearProps: "overflow"
    });
}


export function getDefaultTimeline(node, delay) {

    if (node) {
        const timeline = gsap.timeline({ paused: true });
        const content = node.querySelector('.test');
        timeline
            .from(node, { duration: 1.3, display: 'none', autoAlpha: 0, delay })
            .from(content, { duration: 0.15, autoAlpha: 0, y: 25 })
        return timeline;
    }
}

export function play(pathname, node, appears, parentNode, state) {
    if (node == null) {
        return;
    }

    const type = state?.type

    if (type === 'instant') {
        startInstantTimeline(node, parentNode);
    } else if (type === 'pop') {
        startPopTimeline(node, parentNode);
    } else if (type === 'slide') {
        startSlideTimeline(node, parentNode);
    } else if (type === 'slide-left') {
        startSlideLeftTimeline(node, parentNode)
    } else {
        startInstantTimeline(node, parentNode);
    }

}

export function exit(pathname, node, appears, parentNode, state) {
    if (node == null) {
        return;
    }

    const type = state?.type

    if (type === 'pop') {
        exitPopTimeline(node, parentNode)
    } else if (type === 'slide') {
        exitSlideTimeline(node, parentNode)
    } else if (type === 'slide-left') {
        exitSlideLeftTimeline(node, parentNode)
    } else {
        exitInstantTimeline(node, parentNode);
    }
}

export function completeCall(target, parent) {
    gsap.set(target, { clearProps: "position, width" });
    parent && gsap.set(parent, { clearProps: "overflow" });
};


export function disableFutureVisit () {
    window.onload = disableBack();
    window.onpageshow = function(evt) { if (evt.persisted) disableBack() };
}


function disableBack() { 
    window.history.forward();
}

export function getURLSearchObject(removeQuotes = true) {
    return convertQueryStringToObject(window.location.search, removeQuotes);
}

/**
 * Returns the location.hash as JSON object.
 *
 * @return {Object}
 */
export function getURLHashObject() {
    return convertFragmentIdentifierToObject(window.location.hash);
}


/**
 * if current selected device is null or undefined or its status is not "connected",
 * user must be redirected to homepage
 * @param {*} device 
 */
export function handleUIDeviceDisconnection(device){
    if (
        device == null ||
        device === undefined ||
        (
            sdk[device.deviceType].ConnectionState.Synchronized !== device.connectionState &&
            sdk[device.deviceType].ConnectionState.Connected !== device.connectionState
        )
    ) {
        history.push(buildURI(routingConstants.DASHBOARD));
    }
}