import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { ShopUtils } from '../../_helpers/shop';
import { productType } from '../../_constants';
import { calculateTotalPriceFromBasket } from '../../_helpers/basket';

export const ShopCheckoutElement = ({
    variant,
    readonly,
    updateVariantQuantity,
    removeVariantFromBasket
}) => {

    const [productTitle, setProductTitle] = useState("");
    const [productImg, setProductImg] = useState(null);
    const [isFlavour, setIsFlavour] = useState(false);
    const [variantTotal, setVariantTotal] = useState(0);

    useEffect(() => {
        const product = ShopUtils.getProductFromId(variant.product_id);

        if (product) {
            const isProductFlavour = product.product_type === productType.FLAVOURS;

            setIsFlavour(isProductFlavour);
            setVariantTotal(calculateTotalPriceFromBasket([variant]));

            if (isProductFlavour) {
                setProductTitle(product.title);
            } else {
                setProductTitle(variant.display_name);
            }

            let thisProductImage = variant?.featured_image;
            if (thisProductImage === null || thisProductImage === undefined) {
                setProductImg(product.featured_image);
            } else {
                setProductImg(thisProductImage);
            }
        }
        // console.log("variant", variant);
    }, [variant])


    return (
        <div className={`shop-basket-products-el ${variant.quantity <= 0 && 'disabled'}`}>
            <div className='row d-flex'>
                <div className='col-3'> {<img className="shop-pdp-list-item-img" src={productImg} alt={""} />} </div>
                <div className='col-9 d-flex align-items-center ps-4'>
                    <div className='mb-5'>
                        <span className='d-block mb-2'>{productTitle}</span>
                        {
                            isFlavour &&
                            <span className="shop-pdp-list-item-nic ms-0">
                                Nic: {variant.title}
                            </span>
                        }
                    </div>
                    <div className='align-self-start ms-auto text-end'>
                        {
                            !readonly &&
                            <button className='shop-pdp-list-iten-close-btn mb-2'
                                onClick={() => removeVariantFromBasket(variant)}>
                                <span className='bat-icon-close'></span>
                            </button>
                        }
                        {
                            variant.quantity <= 0 &&
                            <div className='shop-pdp-list-item-unavailable'>
                                <span>unavailable</span>
                            </div>
                        }
                    </div>

                </div>

            </div>
            <div className='row d-flex align-items-center mt-auto'>
                <div className='col-3'></div>
                {
                    readonly ?

                        <div className='col-6 shop-pdp-list-item-qty-readonly'>
                            <span>QTY:</span><h4>{variant.basket}</h4>
                        </div>

                        :

                        <div className='col-6 d-flex justify-content-center'>
                            <span className="shop-pdp-list-item-qty d-flex align-items-center align-self-center">
                                <button className="shop-pdp-list-item-qty-min"
                                    onClick={() => updateVariantQuantity(variant, false)}
                                    disabled={variant.basket <= 1}>-</button>
                                <span className="shop-pdp-list-item-qty-num">{variant.basket}</span>
                                <button className="shop-pdp-list-item-qty-add"
                                    onClick={() => updateVariantQuantity(variant, true)}
                                    disabled={variant.basket >= variant.quantity}>+</button>
                            </span>
                        </div>
                }
                <div className='col-3 d-flex justify-content-end'>
                    <span className='price'>${variantTotal}</span>
                </div>
            </div>
        </div>
    )

}