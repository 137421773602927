import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../_actions";
import { Header, ResetPasswordForm } from "../../_components";
import { AnalyticsEvents, AnalyticsScreenEventNames, aemPages, routingConstants } from "../../_constants";
import { AEMHelper } from "../../_helpers/aem/aemhelper";
import { buildURI } from "../../_helpers/navigation";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";

export const ResetPassword = (props) => {

    const [userEmail, setUserEmail] = useState("");
    const [resetPasswordResult, setResetPasswordResult] = useState(null);

    const aem = new AEMHelper();
    const page = aem.getPage(aemPages.RESET_PWD);

    let LOGIN_HEADER = page?.getComponent(
        aemPages.RESET_PWD,
        "LOGIN_HEADER"
      );

    const dispatch = useDispatch();

    useEffect(() => {
        document.body.classList.add("reset-pw");
        logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.RESET_PASSWORD_EMAIL});
        return () => {
            document.body.classList.remove("reset-pw");
        };
    }, []);

    const resetPassword = (email) => {
      
        setResetPasswordResult(null);

        dispatch(userActions.resetPassword(email,
            () => {
                setResetPasswordResult(true);
                setUserEmail(email);
            },
            () => {
                setResetPasswordResult(true);
                setUserEmail(email);
            }
        ));

    };

    return (
        <div className="page w-100">
            <Header
            leftButton={{
                icon: <span className="bat-icon-back" />,
                onClick: () => props.history.push(buildURI(routingConstants.LOGIN)),
            }}
            subHeader={LOGIN_HEADER.getRaw("heading", null)}
            />
            <ResetPasswordForm
            userEmail={userEmail}
            resetPasswordResult={resetPasswordResult}
            onForgotPassword={resetPassword}
            resetResult={() => setResetPasswordResult(null)}
            page={page}
            />
            <div className="page-linear-gradient-bg"></div>
        </div>
    );

};