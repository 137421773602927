import { COUNTRY_ISO_CODE } from '../_constants';
import { Tenants } from '../_helpers/tenants';

class RegisterModel {

    storeName = "vype"; //ca only
    email = null;
    password = null;
    firstName = null;
    lastName = null;

    address = null; //ca only
    geocodedAddress = true; //ca only
    city = null;
    province = null;
    country = null;
    postalCode = null;
    consent = null;
    emailSubscription = null;

    gender = null; //uk only
    company = null; //uk only
    street = null; //uk only

    telephone = null;

    email_newsletter = false;//uk only
    mobile_newsletter = false;//uk only

    tandc = null;//uk only
    myVuseEverLogged = null; //ca only

    getUKPayload = (data) => {
        this.email = data.email;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.gender = data.gender;
        this.password = data.password;
        this.dob = data.dob;
        //address
        this.city = data.city;
        this.country = data.country;
        this.company = data.company;
        this.postalCode = data.postalCode;
        this.province = data.state;
        this.street = data.street;
        this.telephone = data.phoneNumber;

        this.email_newsletter = data.consent_email_marketing;
        this.mobile_newsletter = data.consent_mobile;
        this.tandc = data.termsconditions;

        return {
            "firstName": this.firstName,
            "lastName": this.lastName,
            "ageVerified": false,
            "emailAddress": this.email,
            "birthDate": this.dob,
            "gender": this.gender,
            "mobileNumber": this.telephone,

            "billingAddressLine1": this.street[0] ?? "",
            "billingAddressLine2": this.street[1] ?? "",
            "billingAddressLine3": this.street[2] ?? "",
            "billingPostalCode": this.postalCode,
            "billingState": "",
            "billingCity": this.city,
            "billingCountry": this.country,
            "billingCompany": this.company,

            "emailOptIn": this.email_newsletter,
            "mobileOptIn": this.mobile_newsletter,
            "personalDataRegistrationAuthorisation": true,
            "status": "Consumer",
            "brand": "Vuse",
            "countryCode": COUNTRY_ISO_CODE,
            "accountSource": "MyVuseApp",
            "storeId": "2",
            "websiteId": "2",
            "redirectUrl": "https://api.non-prod.marketing.bat.net/region_eu/uat3/vuse_uk_en_gb/V1/customers/accounts",

            "password": this.password
        }
    }


    getCAPayload = (data) => {
        this.address = data.address;
        this.city = data.city;
        this.consent = data.consent;
        this.country = data.country;
        this.dob = data.dob;
        this.email = data.email;
        this.emailSubscription = data.emailSubscription;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.password = data.password;
        this.postalCode = data.postalCode;
        this.province = data.province;
        this.myVuseEverLogged = data.myVuseEverLogged;
        this.telephone = data.phoneNumber;
        this.mobile_newsletter = data.smsSubscription;


        return {
            storeName: this.storeName,
            user: {
                email: this.email,
                password: this.password,
                firstName: this.firstName,
                lastName: this.lastName,
                address: this.address,
                geocodedAddress: this.geocodedAddress,
                city: this.city,
                province: this.province,
                country: this.country,
                postalCode: this.postalCode,
                dob: this.dob,
                //consent: this.consent,
                myVuseEverLogged: this.myVuseEverLogged,
                phoneNumber: this.telephone,
                myvuseConnected: true,
                consentVype: true,
                source: "myvuse",
                darkMarket: Tenants.isCanadaDark(),
                emailSubscriptionVype: false,
                vuseItcanEmailOptIn: this.emailSubscription,
                vuseItcanAppOptIn: this.mobile_newsletter
            }
        }
    }


    payload = () => {
        return {
        }
    }

}

export { RegisterModel };
