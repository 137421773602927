import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { store } from '../../../../_store';
import { ShopSorting } from '../../../../_components';
import { ShopUtils } from '../../../../_helpers/shop';
import { CustomModal } from '../../../../_components/CustomModal';
import HealthWarning from '../../../../_components/HealthWarning';
import { imagesConstants, shopSortingOptions } from '../../../../_constants';

export const ShopOrdersHistory = ({
    isOpen,
    dictionary,
    closeFeatureModal,
    onReorder
}) => {

    const [sortingOption, setSortingOption] = useState(shopSortingOptions.SORTING_DATE_DESC);
    const [orders, setOrders] = useState([]);

    const sortingOptions = [
        shopSortingOptions.SORTING_DATE_ASC,
        shopSortingOptions.SORTING_DATE_DESC,
        shopSortingOptions.SORTING_LOW2HIGH_TOTAL,
        shopSortingOptions.SORTING_HIGH2LOW_TOTAL
    ];

    const applySorting = (thisSortingOption) => {
        setSortingOption(thisSortingOption);
        setOrders(ShopUtils.sortListFromOption(thisSortingOption, orders));
    }

    useEffect(() => {
        setOrders(store.getState().shopReducer.orders);
    }, []);

    useEffect(() => {
        // console.log("orders", orders);
    }, [orders]);

    return (
        <CustomModal
            isOpen={isOpen}
            className={"pdp-shop-modal"}>

            <div className="shop-pdp">
                <div className="fixed-top">
                    <HealthWarning>{dictionary.HEALTH_WARNING}</HealthWarning>
                    <div className="page-header w-100">
                        <header className="d-flex align-items-center justify-content-center">
                            <button className="page-header-icon" onClick={closeFeatureModal}><span className="bat-icon-back"></span></button>
                            <div className="page-header-logo"><img src={imagesConstants.MYVUSE_lOGO} alt="MUSE logo" /></div>
                        </header>
                    </div>
                </div>
                <div className="shop-pdp-wrapper shop-orders-history-wrapper">

                    <div className="shop-orders-history-image">
                        <img src={`${imagesConstants.SHOP_REORDER}`} alt="" />
                    </div>

                    <div className='shop-orders-history-title'>
                        <div className='row w-100'>
                            <div className='col-12'>
                                <h2>{dictionary.SHOP_REORDER_PREV_ORDERS_HEADER}</h2>

                                <div className="shop-filter">
                                    <div className="label-title">
                                        {dictionary.SHOP_REORDER_PREV_ORDERS_SORT_BY_LABEL}
                                    </div>
                                    <div className="d-flex">
                                        <ShopSorting onApplySorting={applySorting}
                                            sortingOption={sortingOption}
                                            sortingOptions={sortingOptions} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='shop-orders-history-list'>
                        {
                            orders !== null &&
                            orders !== undefined &&
                            orders.map((order, idx) => {
                                let qty = order.items.reduce((a, b) => a + (b.quantity ?? 0), 0)
                                return (
                                    <div key={`order-el-${idx}`}
                                        className="row d-flex justify-content-center">
                                        <div className="col-12">
                                            <div className={`shop-reorder-summary`} >
                                                <div className='shop-reorder-summary-number'>
                                                    <span>{dictionary.SHOP_REORDER_ORDER_LABEL} #{order.number}</span>
                                                </div>
                                                <div className='shop-reorder-summary-total'>
                                                    <h2>{moment(order.date).format('DD/MM/yyyy')}</h2>
                                                    <h2>{`$${order.total}`}</h2>
                                                </div>
                                                <div className='shop-reorder-summary-items'>
                                                    <span>{`${qty} items`}</span>
                                                    <button className='btn btn-primary btn-block text-uppercase' onClick={() => onReorder(order)}>{dictionary.SHOP_REORDER_BTN_REORDER_0_ctaLabel}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div>
        </CustomModal >
    );

}
