export const HARD_AGE_VERIFICATION_STEPS = {
  CONSENT: 1,
  CAPTURE: 2,
  NEGATE_CONSENT: 3,
  UNSUCCESS: 4,
  TOO_CLOSE: 5,
  DOC_SCAN: 6,
  DOC_SCAN_FAILURE: 7,

  INFO: 8,
  FAQ: 9,
  CONSENT_CA: 10,
  FACE_FAKE: 11
};

export const AGE_VERIFICATION_ACTIONS = {
  CREATE_SESSION: "age-verification/CREATE_SESSION",
  RESET_SESSION: "age-verification/RESET_SESSION",
  SET_UPLOADING_IS_COMPLETE: "age-verification/SET_UPLOADING_IS_COMPLETE",
  GET_VERIFICATION_RESULT: "age-verification/GET_VERIFICATION_RESULT",
};

export const ST_DEV_THRESHOLD = 6;
