import React from 'react';
import { ContentView } from '../../_components';
import { aemPages } from '../../_constants';
import { AEMHelper, propertyTextDefaultEmpty, propertyTextDefaultEmptyParsed } from '../../_helpers/aem/aemhelper';

class CookiePolicy extends React.Component {
    constructor(props) {
        super(props);

        this.aem = new AEMHelper();
        this.dictionary = this.aem.getDictionary(aemPages.COOKIE_POLICY, {
            COOKIE_NOTICE_TITLE: propertyTextDefaultEmpty,
            COOKIE_NOTICE_BODY: propertyTextDefaultEmptyParsed,
        });
    }

    render() {
        return (
            <ContentView
                title={this.dictionary.COOKIE_NOTICE_TITLE}
                content={this.dictionary.COOKIE_NOTICE_BODY}
            />
        );
    }
}

export { CookiePolicy };
