import React, { useEffect, useState } from "react";
import { FW_UPDATE_ERRORS_CODES, imagesConstants } from "../../_constants";
import { useFWUpdateContext } from "./FirmwareUpdateContext";
import cx from "classnames";
import { PageSelectorCTA } from "../PageSelectorCTA";

export function FirmwareUpdateFailure({
  icon,
  content,
  alert,
  errorCode,
  exit,
  contactSupport,
}) {
  const { dictionary, device, close, retry, retryCount, setTitle } =
    useFWUpdateContext();
  const [isCloseView, setIsCloseView] = useState(false);

  const isLowBatteryError = () =>
    errorCode === FW_UPDATE_ERRORS_CODES.LOW_BATTERY;

  useEffect(() => {
    setIsCloseView(
      exit ||
        (retryCount >= 3 && errorCode !== FW_UPDATE_ERRORS_CODES.BLE_LINK_LOSS)
    );
  }, [exit, retryCount]);

  useEffect(() => {
    if (isCloseView && !isLowBatteryError()) {
      setTitle(dictionary.FW_UPDATE_FAIL_HEADER);
    }
  }, [isCloseView]);

  return (
    <>
      <div className="sliding-panel-mid">
        {contactSupport && isCloseView ? (
          <>
            <div className="sliding-panel-mid-icon">
              <img
                src={imagesConstants.FW_UPDATE_WARNING_ERROR}
                alt="fw update icon"
              />
            </div>
            <div className="sliding-panel-mid-info">
              {dictionary.FW_UPDATE_FOTA_MSG_FOUR_CONTENT}
            </div>
            <div className="sliding-panel-mid-info text-start">
              <div className="py-3 border-top border-bottom border-secondary">
                <span className="bat-icon-phone me-3" />
                {dictionary.FW_UPDATE_FOTA_MSG_FOUR_PHONE_INFO}
              </div>
              <div className="mt-3 py-3 border-top border-bottom border-secondary">
                <img
                  src={imagesConstants.NEW_EMAIL}
                  alt="email icon"
                  className="me-3"
                  style={{ height: "14px" }}
                />
                {dictionary.FW_UPDATE_FOTA_MSG_FOUR_MAIL_INFO}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="sliding-panel-mid-icon">
              <img
                src={icon || imagesConstants.FW_UPDATE_FAILED}
                alt="fw update icon"
              />
            </div>
            <div className="sliding-panel-mid-info">{content}</div>
            <div className="sliding-panel-mid-info text-secondary">{alert}</div>
            {!isLowBatteryError() && device.dfuModeOn && (
              <div className="sliding-panel-mid-info text-secondary">
                {dictionary.FW_UPDATE_FOTA_DFU_MSG}
              </div>
            )}
          </>
        )}
      </div>
      <PageSelectorCTA sticky={false} ctaUrl={true} className="mt-auto">
        <div className="d-grid">
          <button
            className={cx("btn", "text-uppercase", {
              "btn-warning": !isCloseView,
              "btn-danger": isCloseView,
            })}
            type="button"
            onClick={() => (isCloseView ? close() : retry())}
          >
            {isCloseView
              ? dictionary.FW_UPDATE_FOTA_BTN_CLOSE_0_ctaLabel
              : dictionary.FW_UPDATE_BTN_RETRY_0_ctaLabel}
          </button>
        </div>
        {errorCode && (
          <div className="d-grid text-center mt-3">
            <small>
              {dictionary.FW_UPDATE_FOTA_ERROR_CODE} 0xC000{errorCode}
            </small>
          </div>
        )}
      </PageSelectorCTA>
    </>
  );
}
