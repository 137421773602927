import React from 'react';
import { getUrl } from '../../_actions/appConfig.actions';
import { aemPages, os, routingConstants, webviewUrl } from '../../_constants';
import { AEMHelper, propertyTextDefaultEmpty } from '../../_helpers/aem/aemhelper';
import { WebView } from '../../_components';
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';
import { Tenants } from '../../_helpers/tenants';
import { Commons } from '../../_helpers/commons';

export const ContactUs = () => {

    const aem = new AEMHelper();
    const dictionary = aem.getDictionary(aemPages.BURGER_MENU, {
        MENU_CONTACT_US: propertyTextDefaultEmpty,
    })

    const handleBack = () => {
        if (history?.location?.state?.backTo) {
            history.replace(buildURI(history.location.state.backTo), {type: 'slide-left'});
        } else {
            Commons.goToDashboard();
        }
    }

    return (
        <WebView
            title={dictionary.MENU_CONTACT_US}
            handleBack={handleBack}
            url={`${getUrl(webviewUrl.WEBVIEW_CONTACT_US)}${Tenants.isCanadaDark() ? os : ''}`}
            id="extPageIframe"
            className="extPageIframe"
        />
    )
}
