import React, { useEffect, useState } from 'react';
import { aemPages, flavourType, imagesConstants } from '../../_constants';
import { CustomModal } from '../../_components/CustomModal';
import HealthWarning from '../../_components/HealthWarning';
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyImageSrcDefaultNull, propertyTextDefaultEmpty } from '../../_helpers/aem/aemhelper';
import Badge from '@mui/material/Badge';
import { ShopUtils } from '../../_helpers/shop';
import { useSelector } from 'react-redux';

export const DevicePDP = ({
    device,
    isOpen,
    onClosePdp,
    onAddToCart,
    onCheckout,
    onOpenBasket
}) => {

    const basket = useSelector(state => state.shopReducer.basket);
    const [dictionary, setDictionary] = useState({});

    const [basketTotal, setBasketTotal] = useState(0);
    const [basketQty, setBasketQty] = useState(0);
    const [basketVariantQty, setBasketVariantQty] = useState(0);
    const [productImage, setProductImage] = useState(null);

    /**
     * Reset values when opening pdp
     */
    useEffect(() => {
        if (isOpen) {
            setBasketVariantQty(0);
            setBasketTotal(0);
        }
    }, [isOpen])

    useEffect(() => {
        if (device) {
            let thisProductImage = device?.featured_image;
            if (thisProductImage === null || thisProductImage === undefined) {
                setProductImage(ShopUtils.getProductPropertyFromId(device.product_id, 'featured_image'));
            } else {
                setProductImage(thisProductImage);
            }
        }
    }, [device])

    const closePdp = () => {
        if (typeof onClosePdp === 'function') {
            onClosePdp();
        }
    }

    const handleAddToCart = () => {
        if (typeof onAddToCart === 'function') {
            onAddToCart([{
                ...device,
                basket: basketVariantQty
            }]);
            setBasketVariantQty(0);
        }
    }

    const handleCheckout = () => {
        if (typeof onCheckout === 'function') {
            onCheckout([
                {
                    ...device,
                    basket: basketVariantQty
                }
            ]);
        }
    }

    useEffect(() => {
        const aem = new AEMHelper();
        setDictionary({
            ...aem.getDictionary(aemPages.HOMEPAGE, {
                HEALTH_WARNING: propertyImageSrcDefaultNull,
            }),
            ...aem.getDictionary(aemPages.SHOP, {
                SHOP_SELECTED_LABEL: propertyTextDefaultEmpty,
                SHOP_BTN_ADD_TO_CART: propertyCtaItemsDefaultEmpty,
                SHOP_BTN_BUY_NOW: propertyCtaItemsDefaultEmpty,
                SHOP_NEW: propertyTextDefaultEmpty,
                SHOP_UNAVAILABLE: propertyTextDefaultEmpty
            })
        });
    }, [])

    useEffect(() => {
        if(
            basket !== null &&
            basket !== undefined
        ){
            setBasketQty(basket.reduce((a, b) => a + (b.basket ?? 0), 0));
        }
    }, [basket]);

    /**
     * Update variant quantity in temporary basket
     * @param {*} variant 
     * @param {*} increment 
     */
    const updateCurrentVariantQuantity = (increment) => {
        let cuurrentQty = basketVariantQty + (1 * (increment ? 1 : -1));
        setBasketTotal((cuurrentQty * parseFloat(device.price)).toFixed(2));
        setBasketVariantQty(cuurrentQty);
    }

    return (

        <React.Fragment>
            {
                device &&
                <CustomModal
                    isOpen={isOpen}
                    className={"pdp-shop-modal"}>

                    <div className="shop-pdp" style={{
                        paddingBottom: basketVariantQty > 0 ? '130px' : '91px'
                    }}>
                        <div className="fixed-top">
                            <HealthWarning>{dictionary.HEALTH_WARNING}</HealthWarning>
                            <div className="page-header w-100">
                                <header className="d-flex align-items-center justify-content-center">
                                    <button className="page-header-icon" onClick={closePdp}><span className="bat-icon-back"></span></button>
                                    <div className="page-header-logo"><img src={imagesConstants.MYVUSE_lOGO} alt="MUSE logo" /></div>
                                </header>
                            </div>
                        </div>
                        <div className="shop-pdp-wrapper">
                            <div className="shop-pdp-image" style={{
                                ...(device.gradient && device.gradient !== "" && { background: device.gradient })
                            }}>
                                {device?.tags && (device.tags).includes(flavourType.FLAVOUR_NEW) && <span className="new">{dictionary.SHOP_NEW}</span>}
                                <img src={productImage} alt="" />
                            </div>
                            <div className="shop-pdp-title">
                                <div className="container-fluid p-0">
                                    <div className="row">
                                        <div className="col-8">
                                            {device.display_name}
                                        </div>
                                        <div className="col-4 text-end">
                                            <span className="price">${device.price}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="shop-pdp-description">{device.description}</div>

                            <div className="shop-pdp-list">
                                <div className="shop-pdp-list-item d-flex align-items-end">
                                    {
                                        device.quantity > 0 ?

                                            <span className="shop-pdp-list-item-qty d-flex align-items-center ms-auto">
                                                <button className="shop-pdp-list-item-qty-min"
                                                    onClick={() => updateCurrentVariantQuantity(false)}
                                                    disabled={basketVariantQty < 1}>-</button>
                                                <span className="shop-pdp-list-item-qty-num">{basketVariantQty}</span>
                                                <button className="shop-pdp-list-item-qty-add"
                                                    onClick={() => updateCurrentVariantQuantity(true)}
                                                >+</button>
                                            </span>

                                            :
                                            <span className="d-flex align-items-center ms-auto">
                                                <div className="alert alert-danger shop-product-tag" role="alert">
                                                    {dictionary.SHOP_UNAVAILABLE}
                                                </div>
                                            </span>

                                    }
                                </div>
                            </div>
                        </div>

                        <div className="shop-pdp-actions">
                            {
                                basketVariantQty > 0 &&
                                <div className='row d-flex align-content-between mb-3'>
                                    <div className='col-6'>
                                        <div className="alert alert-secondary shop-product-tag d-inline" role="alert">
                                            {dictionary.SHOP_SELECTED_LABEL.replace("$1", basketVariantQty)}
                                        </div>
                                    </div>
                                    <div className='col-6 text-end'><span className="price">${basketTotal}</span></div>
                                </div>
                            }

                            <div className='row d-flex align-items-center g-2'>
                                <div className='col-1' onClick={onOpenBasket}>
                                    <Badge color="secondary" badgeContent={basketQty}><span className='bat-icon-cart'></span></Badge>
                                </div>
                                <div className='col-6'><button className="btn btn-success flex-fill text-uppercase w-100"
                                    disabled={basketVariantQty <= 0}
                                    onClick={handleAddToCart}>
                                    {dictionary.SHOP_BTN_ADD_TO_CART_0_ctaLabel}
                                </button></div>
                                <div className='col-5'><button className="btn btn-primary flex-fill text-uppercase w-100"
                                    disabled={basketVariantQty <= 0}
                                    onClick={handleCheckout}>
                                    {dictionary.SHOP_BTN_BUY_NOW_0_ctaLabel}
                                </button></div>
                            </div>
                        </div>
                    </div>
                </CustomModal>
            }
        </React.Fragment>
    )

}