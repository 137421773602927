import React from 'react';
import { Header, OnboardingHeader } from '../../_components';
import { aemPages, imagesConstants } from '../../_constants';
import { AEMHelper, propertyHeadingDefaultEmpty, propertyTextDefaultEmpty, propertyTextDefaultEmptyParsed } from '../../_helpers/aem/aemhelper';
import { history } from '../../_helpers/history';

class LocationServices extends React.Component {
    
    constructor(props) {
        super(props);
        this.aem = new AEMHelper();
        this.dictionary = this.aem.getDictionary(
            aemPages.FIND_MY_VAPE,
            {
                FMV_IOS_LOCATION_CONTENT: propertyTextDefaultEmptyParsed,
                FMV_IOS_LOCATION_LIST_FIRST: propertyTextDefaultEmpty,
                FMV_IOS_LOCATION_LIST_SECOND: propertyTextDefaultEmpty,
                FMV_IOS_LOCATION_LIST_THIRD: propertyTextDefaultEmpty,
                FMV_IOS_LOCATION_LIST_FOURTH: propertyTextDefaultEmpty,
                FMV_IOS_LOCATION_HEADER: propertyHeadingDefaultEmpty
            }
        );
    }

    render() {
        return (
            <div className="page w-100 location-services-page">
                <Header leftButton={{
                    icon: <span className="bat-icon-back" />,
                    onClick: () => history.goBack()
                }}>
                    {this.dictionary.FMV_IOS_LOCATION_HEADER}
                </Header>
                <div className="page-wrapper optin-instructions-wrapper">
                    <div className="optin-instructions-descr">
                        {this.dictionary.FMV_IOS_LOCATION_CONTENT}
                    </div>

                    <div className="optin-instructions-content">
                        <ol>
                            <li>
                                <img className="instruction-img" src={imagesConstants.SYSTEM_ICON} alt="Settings logo" />{this.dictionary.FMV_IOS_LOCATION_LIST_FIRST}
                            </li>
                            <li>
                                <img className="instruction-img" src={imagesConstants.PRIVACY_ICON} alt="Settings logo" />{this.dictionary.FMV_IOS_LOCATION_LIST_SECOND}
                            </li>
                            <li>
                                <img className="instruction-img" src={imagesConstants.LOCATION_ICON} alt="Settings logo" />{this.dictionary.FMV_IOS_LOCATION_LIST_THIRD}
                            </li>
                            <li>
                                <span className="instruction-icon bat-icon-toggle" />{this.dictionary.FMV_IOS_LOCATION_LIST_FOURTH}
                            </li>
                        </ol>
                    </div>
                </div>

                <div className="page-linear-gradient-bg"></div>
            </div>
        );
    }
}

export { LocationServices };
