
import React, { useState, useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import "animate.css/animate.min.css";

export const CustomAlert = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [customClasses, setCustomClasses] = useState("");
    const [showDismissButton, setShowDismissButton] = useState(false);
    const [hideOkButton, setHideOkButton] = useState(false);
    const [closeOnClickOutside, setCloseOnClickOutside] = useState(false);
    const [buttonCloseLabel, setButtonCloseLabel] = useState('OK');
    const [buttonDismissLabel, setButtonDismissLabel] = useState('CANCEL');

    useEffect(() => {

        if(!props.isOpen){
            setIsClosing(true);
            setTimeout(() => {
                setIsOpen(props.isOpen);
                setIsClosing(false);
            }, 500);
        } else { 
            setIsOpen(props.isOpen);
        }
        
    }, [props.isOpen]);

    useEffect(() => {
        if (
            props.closeOnClickOutside != null &&
            props.closeOnClickOutside !== undefined
        ) {
            setCloseOnClickOutside(props.closeOnClickOutside);
        }
    }, [props.closeOnClickOutside]);

    useEffect(() => {
        if (props.customClasses) {
            setCustomClasses(props.customClasses);
        }
    }, [props.customClasses]);

    useEffect(() => {

        setShowDismissButton(props.showDismissButton);
    }, [props.showDismissButton]);

    useEffect(() => {
        if (props.buttonCloseLabel) {
            setButtonCloseLabel(props.buttonCloseLabel);
        }

    }, [props.buttonCloseLabel]);

    useEffect(() => {
        if (props.buttonDismissLabel) {
            setButtonDismissLabel(props.buttonDismissLabel);
        }

    }, [props.buttonDismissLabel]);

    useEffect(() => {

        if (props.hideOkButton === true) {
            setHideOkButton(true);
        } else if (props.hideOkButton === false) {
            setHideOkButton(false);
        }

    }, [props.hideOkButton]);



    const onAlertClose = () => {
        if (typeof props.onClose === 'function') {
            props.onClose('close');
        }
    }

    const onAlertDismiss = () => {
        if (typeof props.onClose === 'function') {
            props.onClose('dismiss');
        }
    }

    return (
        <div className='dialog-av-main'>
            <SweetAlert title={props.title || ''}
                onConfirm={onAlertClose}
                onCancel={onAlertClose}
                closeOnClickOutside={closeOnClickOutside ?? false}
                allowEscape={false}
                openAnim={props.openAnim || false}
                closeAnim={props.closeAnim || false}
                show={isOpen}
                customClass={`${customClasses} ${isClosing ? 'out' : ''}`}
                confirmBtnCssClass="btn-link ml-auto"
                {...(!props.closeAnim && {
                    customButtons: 
                            <React.Fragment>
                                {
                                    showDismissButton &&
                                    <span id="customAlertCloseBtn"
                                        onClick={onAlertDismiss}
                                        className="btn btn-lg btn-primary btn-link ml-auto">
                                        {buttonDismissLabel}
                                    </span>
                                }
                                {
                                    !hideOkButton &&
                                    <span id="customAlertOkBtn"
                                        onClick={onAlertClose}
                                        className="btn btn-lg btn-primary btn-link ml-auto">
                                        {buttonCloseLabel}
                                    </span>
                                }
                                {props.customButtons}
                            </React.Fragment>
                })}
                style={{
                    color: "black"
                }}
            >
                {props.children}
            </SweetAlert>
        </div>
    );
};