import React from 'react';
import { ContentView } from '../../_components';
import { aemPages, routingConstants } from '../../_constants';
import { AEMHelper, propertyHeadingDefaultEmpty, propertyTextDefaultEmptyParsed } from '../../_helpers/aem/aemhelper';
import { history } from '../../_helpers/history';
import { buildURI, getURLSearchObject } from '../../_helpers/navigation';
import { Commons } from '../../_helpers/commons';

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);

        this.aem = new AEMHelper();
        this.dictionary = this.aem.getDictionary(aemPages.PRIVACY_POLICY, {
            PRIVACY_POLICY_HEADER_TITLE: propertyHeadingDefaultEmpty,
            PRIVACY_POLICY_CONTENT: propertyTextDefaultEmptyParsed
        });
    }

    getBackHandler() {
        const { canHandleBack } = getURLSearchObject();
        const backTo = history?.location?.state?.backTo

        if (canHandleBack || backTo) {
            if (backTo) {
                return () => history.replace(buildURI(history.location.state.backTo), {type: 'slide-left'});
              } else {
                return () => history.goBack();
              }
        } else {
            return null;
        }
    }

    getCloseHandler() {
        if (this.props?.history?.location?.state?.canClose) {
            if (this.props?.history?.location?.state?.backToSignup) {
                return () => Commons.goTo(routingConstants.SIGN_UP)
            } else {
                return () => history.goBack();
            }
        } else if (this.props?.onBack) {
            return this.props?.onBack
        } else {
            return null;
        }
    }

    render() {
        const {
            isAPublicView
        } = this.props;
        return isAPublicView ? (
            <ContentView
                content={this.dictionary.PRIVACY_POLICY_CONTENT}
            />
        ) : (
            <ContentView
                title={this.dictionary.PRIVACY_POLICY_HEADER_TITLE}
                content={this.dictionary.PRIVACY_POLICY_CONTENT}
                handleBack={this.getBackHandler.bind(this)()}
                close={this.getCloseHandler.bind(this)()}
            />
        );
    }
}

export { PrivacyPolicy };
