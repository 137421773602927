import React, { useState, useEffect, useCallback } from 'react';
import { Commons } from '../../../../_helpers/commons';
import { OptInFlavoursForYou } from '../../../OptInFlavoursForYou';
import { CustomInlineAlert, ProductPDP, ShopFlavoursCarousel } from '../../../../_components';
import { AnalyticsEvents, flagStatus, imagesConstants, thingVuseProperties } from '../../../../_constants';
import { useSelector } from 'react-redux';
import { logAnalyticsEvent } from '../../../../_helpers/analytics/logAnalytics';
import { getAEMAssetsUrl } from '../../../../_actions/appConfig.actions';

export const ShopFlavourForYou = ({
    items,
    isAcceptedFavouritesOptin,
    navigateToFlavours,
    onOpenBasketPanel,
    onStartCheckout,
    onRemoveProduct,
    onOpenFavouritesOptin,
    reloadSuggestions,
    dictionary,
    isOpenOptinModal: isOpenOptin
}) => {

    const loading = useSelector((state) => state.shopReducer.loadingCatalog);

    const [optedIn, setOptedIn] = useState(false);
    const [isOpenProductPdp, setIsOpenProductPdp] = useState(false);
    const [isOpenOptinModal, setIsOpenOptinModal] = useState(false);
    const [currentFlavour, setCurrentFlavour] = useState(null);

    useEffect(() => {
        setOptedIn(Commons.getCustomerProperty(thingVuseProperties.OPT_IN_FLAVOUR_DISC) === flagStatus.ACCEPTED);
    }, []);

    const toggleOptin = useCallback(() => {
        if (optedIn || isAcceptedFavouritesOptin) {
            Commons.acceptOptIn(thingVuseProperties.OPT_IN_FLAVOUR_DISC).then(() => {
                setOptedIn(true);

                logAnalyticsEvent(AnalyticsEvents.ENABLE_FFY)

                if (typeof reloadSuggestions === 'function') {
                    reloadSuggestions();
                }
            });
        }
    }, [optedIn, isAcceptedFavouritesOptin]);

    const removeFlavourForYouById = (id) => {
        if (typeof onRemoveProduct === "function") {
            onRemoveProduct(id);
        }
    }

    const toggleProductPdp = (flavour) => {

        setIsOpenProductPdp(!isOpenProductPdp);

        if (isOpenProductPdp) {
            setTimeout(() => {
                setCurrentFlavour(null);
            }, 500);
        } else {
            setCurrentFlavour(flavour);
        }
    }

    const openOptinModal = () => {
        if (isAcceptedFavouritesOptin) {
            setIsOpenOptinModal(!isOpenOptinModal);
        }
    }

    useEffect(() => {
        openOptinModal();
    }, [isOpenOptin])


    const closeOptinModal = () => {
        setIsOpenOptinModal(false);
        document.body.classList.remove('pdp-open');
    }

    const renderOptinToggle = () => {
        return (
            <React.Fragment>
                <div className="shop-reorder-feature-toggle-wrapper mb-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-9">
                                <div className="reorder-favourites-text">
                                    {dictionary.SHOP_REORDER_FLAV_FOR_YOU_BODY}
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="flavoursOptinCheck"
                                        onChange={openOptinModal}
                                        checked={optedIn}
                                        value={optedIn} />
                                    <label className="form-check-label" htmlFor="flavoursOptinCheck"></label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <OptInFlavoursForYou isOpen={isOpenOptinModal}
                    onClose={closeOptinModal}
                    onDismiss={closeOptinModal}
                    onAcceptOptin={toggleOptin} />
            </React.Fragment>
        )
    }

    const renderNoFlavoursBadge = () => {
        return (
            <div className="row d-flex justify-content-start mx-2">
                <div className="col-12">
                    <div className="rounded-3" style={{
                        backgroundImage: `url("${getAEMAssetsUrl()}${dictionary.SHOP_BG_SUGGESTIONS?.desktop?.src}")`
                    }}>
                        <div className='shop-reorder-feature-badge' style={{
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)'
                        }}>
                            <h2>{dictionary.SHOP_REORDER_FLAV_FOR_YOU_TITLE}</h2>
                            <span className='shop-reorder-feature-badge-desc'>{dictionary.FLAV_FOR_YOU_BODY}</span>
                            <button className='btn btn-sm btn-primary w-50' onClick={navigateToFlavours}>{dictionary.SHOP_REORDER_MANAGE_FAV_BTN_VIEW_FLAV_0_ctaLabel}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderFlavoursList = () => {
        return (
            <React.Fragment>
                {optedIn &&
                    <div className='mt-2'>
                        <ShopFlavoursCarousel items={items}
                            onRemoveElement={removeFlavourForYouById}
                            onOpenPdp={toggleProductPdp}
                        />
                        <ProductPDP isOpen={isOpenProductPdp}
                            onClosePdp={toggleProductPdp}
                            product={currentFlavour}
                            onOpenBasket={onOpenBasketPanel}
                            onStartCheckout={onStartCheckout}
                        />
                    </div>
                }
            </React.Fragment>
        )
    }


    const renderLoading = () => {
        return (
            <div className="d-flex justify-content-center mt-2">
                <div className="spinner-border text-secondary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }


    return (
        <React.Fragment>
            <div className={`shop-reorder-feature ${!isAcceptedFavouritesOptin && ' disabled'}`}>
                <div className='d-flex justify-content-between py-3'>
                    <div className="label-title">
                        {dictionary.SHOP_REORDER_FLAV_FOR_YOU_TITLE}
                    </div>
                </div>
                {
                    !optedIn ?
                        renderOptinToggle()
                        :
                        loading ?
                            renderLoading()
                            :
                            items?.length > 0 ?
                                renderFlavoursList()
                                :
                                renderNoFlavoursBadge()
                }
            </div>
            <div className='mt-2 mx-3'>
                <CustomInlineAlert isShown={!isAcceptedFavouritesOptin} alertType="error">
                    <span onClick={onOpenFavouritesOptin}>
                        {dictionary.MNG_SECTION_ALERT}
                    </span>
                </CustomInlineAlert>
            </div>
        </React.Fragment >
    );

}

