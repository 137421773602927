import React from 'react';
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';
import { routingConstants, aemPages, AnalyticsEvents, AnalyticsTargetEventsNames } from '../../_constants';
import { setAcceptedTermsAndConditions } from '../../_actions/appData.actions';
import { AEMHelper } from '../../_helpers/aem/aemhelper';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { Tenants } from '../../_helpers/tenants';
import { PageSelectorCTA, TermsAndConditions } from '../../_components';

export const TermsConditions = (props) => {

    const aem = new AEMHelper();
    const dictionary = aem.getDictionary(Tenants.isDemo() ? aemPages.REGISTARTION_TAC_DEMO : aemPages.REGISTRATION_TAC, {
      TAC_ACCEPT_INFO: {
        propertyName: 'text',
        parsed: true,
        default: '',
      },
      TAC_BTN_ACCEPT: {
        propertyName: 'ctaItems',
        default: '',
        subPropertyNames: ['ctaLabel'],
      },
    });

    const goToNotificationPreferences = () => {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NEXT})
        setAcceptedTermsAndConditions(true);
        history.push(buildURI(routingConstants.VAULT));
    }

    return (
        <div className="page w-100 h-100">
          <TermsAndConditions />
          <PageSelectorCTA>
              <div className="signup-info p-0">
                {dictionary.TAC_ACCEPT_INFO}
              </div>
              <div className="d-grid">
                <button
                  className="btn btn-primary text-uppercase"
                  id=""
                  type="submit"
                  onClick={goToNotificationPreferences}
                >
                  {dictionary.TAC_BTN_ACCEPT_0_ctaLabel}
                </button>
              </div>
          </PageSelectorCTA>
        </div>
      );

};