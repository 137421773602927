import React, { useEffect } from "react";
import { CustomInlineAlert, PageSelectorCTA } from "..";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { AnalyticsEvents, AnalyticsScreenEventNames } from "../../_constants";

export const ConclusionStep = ({
  title,
  description,
  isOpenAlert,
  alertContent,
  image,
  endButtonLabel,
  onEndProcess,
  from
}) => {
  useEffect(() => {
    if(from === 'remove'){
      logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.REMOVE_DEVICE_SUCCESS})
    } else {
      logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.FACTORY_RESET_SUCCESS})
    }
  },[])

  return (
    <div className="process-data-container">
      <div className="process-data-title">
        <strong>{title}</strong>
        {!!title ? <br /> : null}
        <span>{description}</span>
      </div>
      <CustomInlineAlert isShown={isOpenAlert} alertType="error">
        <div>{alertContent}</div>
      </CustomInlineAlert>
      {/* <div className="process-data-image">{image}</div> */}
      <PageSelectorCTA sticky={false} className="mt-auto ps-0 pe-0">
        <div className="d-grid gap-2">
          <button
            className="btn btn-primary text-uppercase"
            onClick={onEndProcess}
          >
            {endButtonLabel}
          </button>
        </div>
      </PageSelectorCTA>
    </div>
  );
};
