import React from "react";
import { LoyaltyBadge } from "./LoyaltyBadge";

const LoyaltyBadges = ({ badgeGroups = [] }) => {
  const getActiveBadgesCount = (badges = []) => {
    return badges.reduce(
      (count, badge) => (badge?.isSafe && badge?.isActive ? count + 1 : count),
      0
    );
  };

  return badgeGroups.map((group, i) => (
    <div key={i} className="loyalty-badges-group">
      {group?.groupName && (
        <div className="loyalty-badges-group-header">
          <div className="loyalty-badges-group-icon">
            <img
              src={`data:image/png;base64,${group?.groupIcon}`}
              alt="Loyalty Badges Group Icon"
            />
          </div>
          <div className="loyalty-badges-group-count">
            <span>{getActiveBadgesCount(group?.badges)}</span>
            <span>/</span>
            <span>{group?.badges?.length}</span>
          </div>
          <div className="loyalty-badges-group-name">{group?.groupName}</div>
        </div>
      )}
      <div className="loyalty-badges-icons">
        {(group?.badges || []).map((badge, j) => (
          <LoyaltyBadge
            key={j}
            imageSrc={badge?.image}
            title={badge?.badgeTitle}
            subtitle={badge?.badgeSubtitle}
            isActive={badge?.isActive}
            isSafe={badge?.isSafe}
          />
        ))}
      </div>
    </div>
  ));
};

export { LoyaltyBadges };
