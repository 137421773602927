import cx from "classnames";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import {
  AnalyticsEvents,
  AnalyticsFieldNames,
  DEVICE_NAME_ALLOWED_CHARS_RE,
  DEVICE_NAME_MAX_CHARS,
  imagesConstants,
} from "../../_constants";
import { Tenants } from "../../_helpers/tenants";
import { CustomInlineAlert } from "../CustomInlineAlert";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";

export const DeviceCustomName = ({
  setDeviceName,
  deviceName,
  placeholder,
  title,
  isAdvNameSupported,
  alertTitle,
  alertSubtitle,
  emptyError,
  charactersError,
  showEditIcon,
  showMessages,
  disabled,
  onClick
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  const getIllegalChars = (str) => {
    const illegalChars = new Set();

    for (let i = 0, l = str.length; i < l; i++) {
      if (!DEVICE_NAME_ALLOWED_CHARS_RE.test(str[i])) {
        illegalChars.add(str[i]);
      }
    }

    return [...illegalChars.keys()];
  };

  const validate = (value, analytics) => {
    if (!value || /^\s+$/.test(value)) {
      setErrorMessage(emptyError);
    } else {
      const illegalChars = getIllegalChars(value);

      if (!isEmpty(illegalChars)) {
        setErrorMessage([...charactersError, " ", illegalChars.join(", ")]);
        if(analytics){
          logAnalyticsEvent(AnalyticsEvents.VALIDATION_ERROR, {field_name: AnalyticsFieldNames.NAME, field_value: errorMessage})
        }
      } else {
        setErrorMessage("");
      }
    }
  };

  const handleChange = (event) => {
    // On iOS the apostrophe is the right single quotation mark.
    // We have to replace it to avoid bugs during adv writing.
    const value = event.target.value.replace("’", "'");

    setDeviceName(value);
    validate(value);
  };

  const handleBlur = (e) => {
    const value = e.target.value
    logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.DEVICE_NAME, field_value: value})
    validate(value, true)
  }

  return (
    <div className="mb-5">
      {title &&
      <label className="page-label" htmlFor="nameYourDevice">
        {title}
      </label>
      }
      <input
        name="deviceName"
        id="nameYourDevice"
        className={cx("form-control device-settings-name", {
          error: !!errorMessage,
          'bg-edit-icon': showEditIcon
        })}
        placeholder={placeholder ?? ""}
        type="text"
        value={deviceName}
        disabled={disabled}
        onChange={handleChange}
        maxLength={DEVICE_NAME_MAX_CHARS}
        onClick={onClick}
        onBlur={handleBlur}
      />
      {showMessages &&
      <>
      {errorMessage ? (
        <CustomInlineAlert alertType="error" className="mt-2" isShown>
          {errorMessage}
        </CustomInlineAlert>
      ) : (
          <div className="device-settings-name-info mt-2">
            <span className="bat-icon-alert-circle alert-icon" />
            {alertTitle}
          </div>
        )
      }
      {isAdvNameSupported && (
        <div className="device-settings-adv-name-info mt-2">
          {alertSubtitle}
        </div>
      )}
      </>
      }
    </div>
  );
};
