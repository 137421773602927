import React from "react";
import cx from "classnames";
import { servicesConstants } from "../../_constants";
import {
  isSupportedService
} from "../../_actions/appConfig.actions";

const LoyaltyBadge = ({ title, subtitle, imageSrc, isActive, isSafe }) => {
  if (!isSafe) {
    return null;
  }

  return (
    <div
      className={cx("loyalty-badge", {
        "is-active": isActive,
      })}
    >
      <img src={imageSrc} alt="Loyalty Badge" />
      {(title && isSupportedService(servicesConstants.LOYALTY_BADGE_TITLE)) && (
        <div className="loyalty-badge-title">
          {title}
          {(subtitle && isSupportedService(servicesConstants.LOYALTY_BADGE_DESCRIPTION)) && <div className="loyalty-badge-subtitle">{subtitle}</div>}
        </div>
      )}
    </div>
  );
};

export { LoyaltyBadge };
