import React, { useEffect, useState } from 'react';
import animationLoading from '../../_assets/lotties/Pairing_Icons_Loading.json';
import animationComplete from '../../_assets/lotties/Pairing_Icons_LoadingComplete.json';
import CustomLottie from '../CustomLottie/CustomLottie';
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { AnalyticsEvents, AnalyticsScreenEventNames } from "../../_constants";

/**
 * Pairing step done only in global app, if deice is locked during pairing
 * @param {*} props 
 * @returns 
 */
export const PairingLoader = (props) => {
    const {
        dictionary,
        changeVideoActivation
    } = props;

    const [isShown, setIsShown] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(null);
    const statuses = [
        {
            isLoading: true, animation: animationLoading, loop: true,
            completeCallback: () => { }, label: dictionary.PAIRING_PAIRING_LABEL, waitingLabel: dictionary.PAIRING_WAITING_MSG_LABEL,
        },
        {
            isLoading: false, animation: animationComplete, loop: false,
            completeCallback: () => { setIsShown(false) }, label: dictionary.PAIRING_PAIRED_LABEL
        },
    ];
    /*
        useEffect(() => {
            if (props.isShown) {
                setIsShown(true);
            }
            setCurrentStatus(statuses.find((status => status.isLoading === props.isShown)));
        }, [props.isShown])
    
    
        useEffect(() => {
            closeOverlay();
        }, [props.hasError])
    */

    useEffect(() => {
        changeVideoActivation(!isShown);
    }, [isShown]);

    const closeOverlay = () => {
        setIsShown(false);
    }

    const setReconnectStatus = () => {
        setCurrentStatus({
            isLoading: true, animation: animationLoading, loop: true, zIndex: 1,
            completeCallback: () => { }, label: dictionary.PAIRING_TAP_CONNECT_TO_PAIR
        });
    }

    const showOverlay = () => {
        
        logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
            screen_name: AnalyticsScreenEventNames.PAIRING_PROCESSING
        });

        setIsShown(true);
        setCurrentStatus(statuses.find((status => status.isLoading === true)));
    }

    const delayCloseOverlay = () => {
        setCurrentStatus(statuses.find((status => status.isLoading === false)));
    }

    useEffect(() => {
        window.document.addEventListener("pairingStart", showOverlay);
        window.document.addEventListener("pairingSuccess", delayCloseOverlay);
        window.document.addEventListener("disconnectEvent", setReconnectStatus);
        window.document.addEventListener("pairingError", closeOverlay);
        window.document.addEventListener("searchingError", closeOverlay);

        /* cleanup listeners on unmount */
        return () => {
            window.document.removeEventListener("pairingStart", showOverlay);
            window.document.removeEventListener("pairingSuccess", delayCloseOverlay);
            window.document.removeEventListener("disconnectEvent", setReconnectStatus);
            window.document.removeEventListener("pairingError", closeOverlay);
            window.document.removeEventListener("searchingError", closeOverlay);
        };
    }, []);

    return (
        <div className="pairing-loading-overlay" style={{
            display: isShown ? "block" : "none",
            ...(currentStatus?.zIndex && { zIndex: currentStatus.zIndex })
        }}>
            <CustomLottie
                options={{
                    loop: currentStatus?.loop,
                    autoplay: true,
                    animationData: currentStatus?.animation,
                    rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice"
                    }
                }}
                height={75}
                width={75}
                eventListeners={[{
                    eventName: 'complete',
                    callback: currentStatus?.completeCallback,
                }]}
                style={{
                    position: 'absolute',
                    top: '30%',
                    left: '40%'
                }}
            />
            <span className="pairing-loading-overlay-label">
                {currentStatus?.label}
                {currentStatus?.waitingLabel && <span>{currentStatus?.waitingLabel}</span>}
            </span>
        </div>
    );
}