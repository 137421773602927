import React, { useState, useEffect } from 'react';
import { AEMHelper } from '../../_helpers/aem/aemhelper';
import parse from 'html-react-parser';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { aemPages, AnalyticsEvents, AnalyticsTargetEventsNames } from '../../_constants';
import { Tenants } from '../../_helpers/tenants';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { ConnectableObservable } from 'rxjs';

export const TermsAndConditionsAccordions = () => {
  const [carousels, setCarousels] = useState({});

  useEffect(() => {
    const aem = new AEMHelper();
    setCarousels(
      aem.getCarouselComponents(Tenants.isDemo() ? aemPages.REGISTARTION_TAC_DEMO : aemPages.REGISTRATION_TAC, ['TAC_CAROUSEL'])
    );
  }, []);
  

  const accordionList = carousels.TAC_CAROUSEL?.items ?? [];
  const accordions = accordionList.map((item, index) => {
    const {
      cta,
      headline,
      text,
    } = item;

    const accordionTitle = headline?.heading ?? "";
    const accordionText = parse(text?.text ?? "");
    const linkList =
      cta && cta.ctaItems && cta.ctaItems.length > 0 && !!cta.ctaItems[0].ctaLabel
        ? cta.ctaItems[0].ctaLabel.split(';').map((link, index) => (
            <p key={`${headline.heading}-link-${index}`}>
              [{index + 1}] <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
            </p>
          ))
        : [];

    const handleClick = (index) => {
      logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.TAC_SECTION + index})
    }

    return (
      <Accordion key={`accordion-item-${index}`} onChange={(e,t) => {if (t) handleClick(index + 1); }}>
        <AccordionSummary aria-controls="intro-content" id="intro-header">
          <span>{accordionTitle}</span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="d-flex flex-column" style={{width: "100%", wordWrap: "break-word"}}>
            <span>{accordionText}</span>
            {linkList}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  });

  return <div className="bat-accordion-wrapper">{accordions}</div>;
};
