import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import _ from 'lodash';
import isNil from 'lodash/isNil';
import cx from 'classnames';
import { getGoogleApiKey } from '../../_actions/appConfig.actions';
import { DeviceIcon } from '../DeviceIcon';

const LocationPin = (props) => {
    // console.debug('PROPS_FMV', props);

    return (
        
            (props.isSelected) ? 
                <div className="google-map-marker"
                    onClick={() => props.panMap(props.device.lastSyncedLocation, props.device)}
                >
                    <DeviceIcon 
                        device={props.device} 
                        showConnectionState={true}
                        className={cx("marker-icon-vuse", {
                            first: props.isFirst,
                            selected: props.isSelected,
                        })} 
                    />
                </div>
        : null 
    )
}

export const CustomMap = ({
    devices,
    panelHeigth,
    currentDevice,
    options,
    resizeToMarker,
    openPanel
}) => {

    const [map, setMap] = useState(null);
    const [maps, setMaps] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(undefined);
    const [mapLoaded, setMapLoaded] = useState(false);

    useEffect(() => {
        //when panel is opened or current selected location is changed
        //then center marker

        // current location is set to null when market is deselected 
         // console.log("[currentLocation] currentLocation ", currentLocation);
        if (mapLoaded && currentLocation !== null) {
            centerMarker();
        }
    }, [currentLocation, mapLoaded]);

    useEffect(() => {
        //when panel is opened or current selected location is changed
        //then center marker
        if (resizeToMarker !== null) {
            centerMarker();
        }

        console.log("[resizeToMarker] resizeToMarker ", resizeToMarker);
        // console.log("[resizeToMarker] currentLocation ", currentLocation);
    }, [resizeToMarker]);

    useEffect(() => {
        
        if (currentDevice) {
            
            if(!isNil(currentDevice.lastSyncedLocation)){
                setCurrentLocation(currentDevice.lastSyncedLocation);
            } else {
                devices.map((device, idx) => {
                    console.log(device.serialNumber);
                    console.log(currentDevice.serialNumber);
                    console.log(device.lastSyncedLocation);
                    if(device.serialNumber == currentDevice.serialNumber){
                        setCurrentLocation(device.lastSyncedLocation);
                    }
                });
            }
        }
    }, [currentDevice, devices]);

    /**
     * Center map to the selected marker
     *  
     */
    const centerMarker = () => {

        if (map && maps) {
            // console.log("[centerMarker] currentLocation ", currentLocation);
            // console.log("[centerMarker] test", currentLocation == null ||
            //     currentLocation == undefined ||
            //     currentLocation.lat == null ||
            //     currentLocation.lat === undefined ||
            //     currentLocation.lng === null ||
            //     currentLocation.lng === undefined);

            if (currentLocation == null ||
                currentLocation === undefined ||
                currentLocation.lat == null ||
                currentLocation.lat === undefined ||
                currentLocation.lng === null ||
                currentLocation.lng === undefined
            ) {
                //get selected device, if there is not one device selected get the first device in array
                let selectedDevice = currentDevice;

                if (isNil(selectedDevice)) {

                    selectedDevice = devices.find((device) => device.selected);

                    if (selectedDevice == null || selectedDevice === undefined) {
                        selectedDevice = devices[0];
                    }
                }

                setCurrentLocation(selectedDevice.lastSyncedLocation);
                return;
            }

            // console.log("[centerMarker] devices", devices);
            // console.log("[centerMarker] currentLocation", currentLocation);
            var centerPoint = getPointFromLatLng({ lat: map.getCenter().lat(), lng: map.getCenter().lng() });

            // console.log("window.innerHeight", window.innerHeight);
            // console.log("headerHeigth", headerHeigth);
            // console.log("panelHeigth", panelHeigth);
            // console.log("panelMinHeigth", panelMinHeigth);
            // console.log("point.y", locationPoint.y);
            // console.log("centerPoint.y", centerPoint.y);


            if (resizeToMarker) {
                // console.log("(window.innerHeight - headerHeigth - panelHeigth)= ", (window.innerHeight - headerHeigth - panelHeigth));
                // console.log("= result /2 = ", (window.innerHeight - headerHeigth - panelHeigth) / 2);
                // console.log("= window.innerHeight- result = ", window.innerHeight - ((window.innerHeight - headerHeigth - panelHeigth) / 2));
                // console.log("= result - point.y =  ", (window.innerHeight - ((window.innerHeight - headerHeigth - panelHeigth) / 2)) - centerPoint.y);
                
                if (map.getZoom() < 8) {
                    map.setZoom(8);
                }
                map.setCenter(currentLocation);
                map.panBy(0, (window.innerHeight - ((window.innerHeight - panelHeigth) / 2)) - centerPoint.y);
            } else {
                setCurrentLocation(null);
                const bounds = getMapBounds(map, maps, devices);
                map.fitBounds(bounds);

                //if there if only one device in the map set zoom to 18
                if (devices && devices.length === 1) {
                    map.setZoom(18);
                }
                /*
                map.panBy(0, -(centerPoint.y-locationPoint.y-headerHeigth));
                */
            }

        }
    }

    /**
     * Get Point obj from lat lng pair
     * @param {*} latLng {lat, lng}
     * @returns Point
     */
    const getPointFromLatLng = (latLng) => {
        var topRight = map.getProjection().fromLatLngToPoint(map.getBounds().getNorthEast());
        var bottomLeft = map.getProjection().fromLatLngToPoint(map.getBounds().getSouthWest());
        var scale = Math.pow(2, map.getZoom());
        var worldPoint = map.getProjection().fromLatLngToPoint(new maps.LatLng(latLng));
        var point = new maps.Point((worldPoint.x - bottomLeft.x) * scale, (worldPoint.y - topRight.y) * scale);
        return point;
    }

    const handleApiLoaded = (map, maps) => {
        // console.log("handleApiLoaded");
        // console.log("map", map);
        if (map) {
            setMap(map);
            setMaps(maps);
            const bounds = getMapBounds(map, maps, devices);
            // map.fitBounds(bounds);
            bindResizeListener(map, maps, bounds);
        }
    };

    const getMapBounds = (map, maps, devices) => {
        const bounds = new maps.LatLngBounds();

        devices.forEach((device) => {
            bounds.extend(
                new maps.LatLng(device?.lastSyncedLocation?.lat, device?.lastSyncedLocation?.lng),
            );
        });
        return bounds;
    };

    const bindResizeListener = (map, maps, bounds) => {
        maps.event.addDomListenerOnce(map, 'idle', () => {

            //if there if only one device in the map set zoom to 18
            if (devices && devices.length === 1) {
                map.setZoom(18);
            }

            maps.event.addDomListener(window, 'resize', () => {
                map.fitBounds(bounds);
            });
        });

        maps.event.addDomListenerOnce(map, 'bounds_changed', () => {
            // console.log("bounds_changed");
            // console.log(bounds);
        });
    };

    const panMap = (location, device) => {

        // console.log("[panMap] location ", location);
        // console.log("[panMap] currentDevice ", currentDevice);
        // console.log("[panMap] currentLocation", currentLocation);
        setCurrentLocation(location);

        /**
         * openPanel method is called when user clicks on one location pin (to force panel to update its content)
         */
        // if (
        //     !resizeToMarker || 
        //     currentDevice.lastSyncedLocation.lat !== location.lat ||
        //     currentDevice.lastSyncedLocation.lng !== location.lng
        // ) {
        openPanel(device);
        // }
    }

    const mapProps = devices.length > 0
        ? {
            yesIWantToUseGoogleMapApiInternals: true,
            zoom: 18,
            center: currentLocation,
            onGoogleApiLoaded: ({ map, maps }) => handleApiLoaded(map, maps),
            onChange: (e) => console.log(e),
            className: 'with-pins',
        } : {
            zoom: 18,
            center: {
                lat: 51.522754289075884,
                lng: -0.13183754805544975
            },
            className: 'without-pins',
        }


    return (
        <div className="map">
            <div className={`google-map ${mapProps.className}`}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: getGoogleApiKey() }}
                    options={options}
                    onTilesLoaded={() => {
                        setMapLoaded(true)
                    }}
                    {...mapProps}
                >
                    {currentDevice?.lastSyncedLocation &&
                        <LocationPin key={currentDevice?.serialNumber}
                            // isFirst={_.isEqual(device, currentDevice)}
                            isSelected={true}
                            device={currentDevice}
                            lat={currentDevice?.lastSyncedLocation?.lat}
                            panMap={panMap}
                            lng={currentDevice?.lastSyncedLocation?.lng}
                        />
                    }
                </GoogleMapReact>
            </div>
        </div>
    )
}