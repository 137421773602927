import React from 'react';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import { imagesConstants, routingConstants, aemPages, AnalyticsEvents, AnalyticsScreenEventNames, AnalyticsTargetEventsNames } from '../../_constants';
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';
import { AEMHelper } from '../../_helpers/aem/aemhelper';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { getRegistration, setRegistration } from '../../_actions';

class AgeVerificationFailure extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}

        this.goToSignup = this._goToSignup.bind(this);
        this.aem = new AEMHelper();
        this.page = this.aem.getPage(aemPages?.AV_FAILURE);
    }


    componentDidMount() {
        document.body.classList.add("signup-failure");
        logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.SIGN_UP_FAILED})
    }

    componentWillUnmount() {
        document.body.classList.remove("signup-failure");
    }

    _goToSignup() {
        logAnalyticsEvent(AnalyticsEvents.RETRY_HAGV);

        let registration = getRegistration()
        registration.isValid = null
        registration.done = null
        registration.verificationMethod = null
        registration.goToReview = true
        setRegistration(registration)

        history.push(buildURI(routingConstants.SIGN_UP));
    }

    render() {
        

        //let HEALTH_WARNING =  this.page?.getComponent(aemPages?.AV_FAILURE, 'HEALTH_WARNING');
        let REGISTRATION_IDENTITY_TITLE = this.page?.getComponent(aemPages?.AV_FAILURE, 'REGISTRATION_IDENTITY_TITLE');
        let REGISTRATION_IDENTITY_CONTENT = this.page?.getComponent(aemPages?.AV_FAILURE, 'REGISTRATION_IDENTITY_CONTENT');
        let REGISTRATION_IDENTITY_PHONE = this.page?.getComponent(aemPages?.AV_FAILURE, 'REGISTRATION_IDENTITY_PHONE');
        let REGISTRATION_IDENTITY_EMAIL = this.page?.getComponent(aemPages?.AV_FAILURE, 'REGISTRATION_IDENTITY_EMAIL');
        // let REGISTRATION_BTN_NEED_HELP = this.page?.getComponent(aemPages?.AV_FAILURE, 'REGISTRATION_BTN_NEED_HELP');
        let REGISTRATION_BTN_RESTART_ACCOUNT = this.page?.getComponent(aemPages?.AV_FAILURE, 'REGISTRATION_BTN_RESTART_ACCOUNT');

        let REGISTRATION_BTN_RESTART_ACCOUNT_ctaItems = REGISTRATION_BTN_RESTART_ACCOUNT.getRaw('ctaItems', null);
        let REGISTRATION_BTN_RESTART_ACCOUNT_ctaLabel = null;
    
        if(REGISTRATION_BTN_RESTART_ACCOUNT_ctaItems.length > 0) {
            REGISTRATION_BTN_RESTART_ACCOUNT_ctaLabel = REGISTRATION_BTN_RESTART_ACCOUNT_ctaItems[0].ctaLabel;
        }

        return (
            <div className="page w-100">
                <div className="page-header w-100">
                    <header className="d-flex align-items-center justify-content-center">
                        <div className="page-header-logo"><img src={imagesConstants.MYVUSE_lOGO} alt="MUSE logo" /></div>
                    </header>
                </div>

                <div className="failure-support-wrapper">
                    <div className="container">
                        <div className="row title-row">
                            <div className="col-12">{parse(REGISTRATION_IDENTITY_TITLE?.getRaw('text', '') ?? '')}</div>
                        </div>
                        <div className="row subtitle-row">
                            <div className="col-12">
                                {parse(REGISTRATION_IDENTITY_CONTENT?.getRaw('text', '') ?? '')}
                            </div>
                        </div>
                        {/*<div className="row subtitle-row">
                            <div className="col-12">
                                Please contact our customer service for further assistance:
                            </div>
                        </div>*/}
                        <div className="row support-row" onClick={() => logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CUSTOMER_SUPPORT_PHONENUMBER})}>
                            <div className="col-12">
                                <span className="bat-icon-phone"></span>{REGISTRATION_IDENTITY_PHONE?.getRaw('text', null) ?? ''}
                            </div>
                        </div>
                        <div className="row support-row" onClick={() => logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CUSTOMER_SUPPORT_EMAIL})}>
                            <div className="col-12">
                                <img src={imagesConstants.NEW_EMAIL} alt="email" /> {REGISTRATION_IDENTITY_EMAIL?.getRaw('text', null) ?? ''}
                            </div>
                        </div>
                    </div>

                </div>

                <div className="page-bottom-cta pt-4">
                    <div className="d-grid gap-2">
                        <button className="btn btn-primary text-uppercase" type="button" onClick={this.goToSignup}>{REGISTRATION_BTN_RESTART_ACCOUNT_ctaLabel}</button>
                    </div>
                    <div className="page-bottom-cta-url">
                        {/* <NeedHelpPanel link={REGISTRATION_BTN_NEED_HELP?.getRaw('text', null)}/> */}
                    </div>
                </div>

                <div className="page-linear-gradient-bg"></div>
            </div>

        );

    }
}

function mapStateToProps(state) {

    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)((AgeVerificationFailure));
export { connectedComponent as AgeVerificationFailure };