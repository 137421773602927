import React, { useState, useEffect } from 'react';
import _ from "lodash";
import { ShopUtils } from '../../../../_helpers/shop';
import { AEMHelper, propertyTextDefaultEmpty } from '../../../../_helpers/aem/aemhelper';
import { ProductPDP, ShopFilters, ShopSorting } from '../../../../_components';
import { logAnalyticsEventOfBasketProduct } from '../../../../_helpers/basket';
import { logAnalyticsEvent } from '../../../../_helpers/analytics/logAnalytics';
import { ShopListElement } from '../../../../_components/ShopListElement/ShopListElement';
import {
    aemPages,
    AnalyticsEvents,
    flavourType,
    shopSortingOptions
} from '../../../../_constants';
import { useSelector } from 'react-redux';
import { Commons } from '../../../../_helpers/commons';

export const FlavoursProducts = (props) => {

    const loading = useSelector((state) => state.shopReducer.loadingCatalog);

    const [isOpenFlavourPDP, setIsOpenFlavourPDP] = useState(false);
    const [currentFlavour, setCurrentFlavour] = useState(null);

    const [thisFlavours, setThisFlavours] = useState([]);

    const [filters, setFilters] = useState({});
    const [sortingOption, setSortingOption] = useState(shopSortingOptions.SORTING_A2Z);

    const sortingOptions = [
        shopSortingOptions.SORTING_A2Z,
        shopSortingOptions.SORTING_Z2A
    ];

    const [filtersOptions, setFiltersOptions] = useState({});

    const [dictionary, setDictionary] = useState({});

    useEffect(() => {
        const aem = new AEMHelper();
        setDictionary(
            aem.getDictionary(aemPages.SHOP, {
                SHOP_FLAVOURS_LABEL: propertyTextDefaultEmpty,
                SHOP_SORT_BY_LABEL: propertyTextDefaultEmpty,
                SHOP_NO_FLAVOURS: propertyTextDefaultEmpty,
                SHOP_NEW: propertyTextDefaultEmpty,
                SHOP_NIC_STRENGHT_LABEL: propertyTextDefaultEmpty
            })
        );

    }, [])

    useEffect(() => {
        setThisFlavours(ShopUtils.sortListFromOption(sortingOption, props.flavours));
        const tags = _.compact([...new Set([].concat(...props.flavours.map((o) => o.tags)))]);
        const nics = _.compact([...new Set([].concat(...props.flavours.map((o) => o.variants.map(vari => vari.title))))]);

        setFiltersOptions({
            "profile": tags,
            "nicotineStrenght": Commons.sortArrayAlphabetically(nics)
        });

    }, [props.flavours])

    const toggleFlavourPDP = (flavour) => {
        const newValue = !isOpenFlavourPDP;

        setIsOpenFlavourPDP(newValue);

        if (newValue) {
            setCurrentFlavour(flavour);
        } else {
            //delaying set to null for transition to be shown
            setTimeout(() => {
                setCurrentFlavour(null);
            }, 500);
        }
    }

    const applyFilters = (thisFilters) => {
        setFilters(thisFilters);
        filterProducts(thisFilters);
    }

    const applySorting = (thisSortingOption) => {
        setSortingOption(thisSortingOption);
        sortProducts(thisSortingOption);
        logAnalyticsEvent(AnalyticsEvents.SHOP_SORT_BY, {
            sort_by: thisSortingOption
        });
    }

    const sortProducts = (sortingOption, currentFlavours) => {
        console.log('[sortProducts] sortingOption', sortingOption);
        setThisFlavours(ShopUtils.sortListFromOption(sortingOption, currentFlavours ?? thisFlavours));
    }

    const filterProducts = (filters) => {

        console.log('[filterProducts] filters', filters);

        if (!_.isEmpty(filters)) {

            const profiles = filters.profile;
            const nicotine = filters.nicotineStrenght;

            const newFlavours = props.flavours.filter(flavour => {

                if (profiles.length > 0 && nicotine.length > 0) {
                    return flavour.variants.find(variant => nicotine.includes(variant.title)) &&
                        flavour.tags?.find(tag => profiles.includes(tag));
                } else if (profiles.length > 0) {
                    return flavour.tags?.find(tag => profiles.includes(tag));
                } else if (nicotine.length > 0) {
                    return flavour.variants.find(variant => nicotine.includes(variant.title));
                } else {
                    return true;
                }

            });
            console.log('[filterProducts] newFlavours', newFlavours);

            setThisFlavours([...newFlavours]);

            //if there was one sorting option active which is not "ALL", reapply it to the new set of flavours
            if (sortingOption !== null) {
                sortProducts(sortingOption, newFlavours);
            }

        }
    }

    const onShopListElementClick = (flavour) => {
        logAnalyticsEventOfBasketProduct(AnalyticsEvents.SELECT_ITEM, flavour, false);
        toggleFlavourPDP(flavour);
    }

    return (
        <React.Fragment>
            <div className="label-title">
                {dictionary.SHOP_FLAVOURS_LABEL}
            </div>
            <div className="shop-filter">
                <div className="label-title">
                    {dictionary.SHOP_SORT_BY_LABEL}
                </div>
                <div className="d-flex">
                    <ShopSorting onApplySorting={applySorting}
                        sortingOption={sortingOption}
                        sortingOptions={sortingOptions} />

                    <ShopFilters onApplyFilters={applyFilters} filters={filters} options={filtersOptions} />
                </div>

            </div>

            <div className="shop-product-list" style={{
                paddingBottom: props.basket.length > 0 ? '6.90rem' : '1.25rem'
            }}>
                {
                    thisFlavours?.length > 0 ?

                        thisFlavours.map((flavour, idx) =>
                            <ShopListElement key={`flavours-el-${idx}`}
                                product={flavour}
                                dictionary={dictionary}
                                isNew={flavour?.tags && (flavour.tags).includes(flavourType.FLAVOUR_NEW)}
                                onClickElement={(flavour) => onShopListElementClick(flavour)} />
                        )

                        :
                        loading ?

                            <div className="d-flex justify-content-center mt-2">
                                <div className="spinner-border text-secondary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            :
                            <span>{dictionary.SHOP_NO_FLAVOURS}</span>
                }
            </div>

            <ProductPDP isOpen={isOpenFlavourPDP}
                onClosePdp={toggleFlavourPDP}
                product={currentFlavour}
                onOpenBasket={props.onOpenBasketPanel}
                onStartCheckout={props.onStartCheckout}
            />
        </React.Fragment>
    )

}