import { registrationConstants } from "../_constants";
import { REQUEST, SUCCESS, FAILURE } from "./action-type.util";

export const initialState = {
  loading: false,
  data: null,
  error: null,
  registrationProfileData: {},
  activeStep: 1,
};

export function registrationReducer(state = initialState, action) {
  switch (action.type) {
    case "LOGOUT":
    case "RESET_REDUCER": {
      return {
        loading: false,
        data: null,
        error: null,
        registrationProfileData: {},
        activeStep: 1,
      };
    }

    /**
     * User registration
     */
    case REQUEST(registrationConstants.REGISTER_USER):
      return {
        ...state,
        loading: true,
      };

    case SUCCESS(registrationConstants.REGISTER_USER):
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case FAILURE(registrationConstants.REGISTER_USER):
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    /**
     * Check email validity
     */
    case REQUEST(registrationConstants.CHECK_EMAIL):
      return {
        ...state,
        //loading: true
      };

    case SUCCESS(registrationConstants.CHECK_EMAIL):
      return {
        ...state,
        //loading: false
      };

    case FAILURE(registrationConstants.CHECK_EMAIL):
      return {
        ...state,
        error: action.error,
        //loading: false
      };

    case registrationConstants.SET_REGISTRATION_PROFILE_DATA: {
      return {
        ...state,
        registrationProfileData: {
          ...state.registrationProfileData,
          ...action.data,
        },
      };
    }

    case registrationConstants.RESET_REGISTRATION_PROFILE_DATA: {
      return {
        ...state,
        registrationProfileData: {},
      };
    }

    case registrationConstants.SET_ACTIVE_STEP: {
      return {
        ...state,
        activeStep: action.data
      }
    }

    /**
     * Get address details
     */
    case REQUEST(registrationConstants.GET_ADDRESS_DETAILS):
      return {
        ...state,
        //loading: true
      };

    case SUCCESS(registrationConstants.GET_ADDRESS_DETAILS):
      return {
        ...state,
        addressDetails: action.payload,
        //loading: false
      };

    case FAILURE(registrationConstants.GET_ADDRESS_DETAILS):
      return {
        ...state,
        error: action.error,
        //loading: false
      };

    default:
      return state;
  }
}
