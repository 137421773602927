import React from "react";
import processingCompleteAnimation from "../../_assets/lotties/hard-age-verification/processing-complete.json";
import processingSpinnerAnimation from "../../_assets/lotties/hard-age-verification/processing-spinner.json";
import { imagesConstants } from "../../_constants";
import CustomLottie from "../CustomLottie/CustomLottie";

export function HardAgeVerificationProcessing({ isComplete, text }) {
  return (
    <div className="hard-age-verification-processing">
      <div className="hard-age-verification-lottie-wrapper">
        {isComplete ? (
          <>
            <CustomLottie
              options={{
                loop: true,
                autoplay: true,
                animationData: processingCompleteAnimation,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={250}
              width={250}
            />
            <div className="lottie-content">
              <img
                src={imagesConstants.HARD_AGE_VERIFICATION_SUCCESS_ICON}
                alt="Success Icon"
              />
            </div>
          </>
        ) : (
          <CustomLottie
            options={{
              loop: true,
              autoplay: true,
              animationData: processingSpinnerAnimation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={250}
            width={250}
          />
        )}
      </div>
      <div className="hard-age-verification-processing-text">{text}</div>
    </div>
  );
}
