import React, { useEffect, useState } from "react";
import {
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  aemPages,
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { PageSelectorCTA } from "../PageSelectorCTA";
import { Webview } from "../../_helpers/webview";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import parse from 'html-react-parser';

export function HardAgeVerificationFAQ({ nextStep, goToStep }) {
  const [dictionary, setDictionary] = useState({});
  const [carousels, setCarousels] = useState({});

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.YOTI_FAQ})
    const aem = new AEMHelper();

    setCarousels(
      aem.getCarouselComponents(aemPages.YOTI_FAQ, ['YOTI_FAQ_CAROUSEL'])
    );

    setDictionary({
      ...aem.getDictionary(aemPages.YOTI_FAQ, {
        YOTI_FAQ_BTN_FACE_SCAN: propertyCtaItemsDefaultEmpty,
        YOTI_FAQ_LINK: propertyTextDefaultEmptyParsed,
      }),
      ...aem.getDictionary(aemPages.YOTI_AGE_VERIFICATION, {
        YOTI_AV_BTN_NEXT: propertyCtaItemsDefaultEmpty
      })
    });
  }, []);

  const accordionList = carousels.YOTI_FAQ_CAROUSEL?.items ?? [];
  const accordions = accordionList.map((item, index) => {
    const {
      cta,
      headline,
      text,
    } = item;

    const accordionTitle = headline?.heading ?? "";
    const accordionText = parse(text?.text ?? "");
    const linkList =
      cta && cta.ctaItems && cta.ctaItems.length > 0 && !!cta.ctaItems[0].ctaLabel
        ? cta.ctaItems[0].ctaLabel.split(';').map((link, index) => (
            <p key={`${headline.heading}-link-${index}`}>
              [{index + 1}] <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
            </p>
          ))
        : [];

    return (
      <Accordion key={`accordion-item-${index}`}>
        <AccordionSummary aria-controls="intro-content" id="intro-header"  onClick={() => logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.FAQ_SECTION + (index + 1)})}>
          <span>{accordionTitle}</span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="d-flex flex-column">
            <span>{accordionText}</span>
            {linkList}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  });

  return (
    <div className="page w-100" style={{background: 'none'}}>
      <div className="page-wrapper d-flex flex-column h-100">
        <div className="d-flex flex-column justify-content-between flex-grow-1">

          <div className="bat-accordion-wrapper mt-3">
            {accordions}
          </div>
          
          <div className="hard-age-verification-info-bottom">
            <div>
              <a className="link-yoti" href={"https://www.yoti.com"} target="_blank" rel="noopener noreferrer" onClick={() => logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.YOTI_LINK})}>
                {dictionary.YOTI_FAQ_LINK}
              </a>
            </div>
          </div>
        </div>
        <PageSelectorCTA>
          <div className="d-grid">
            <button
              className="btn btn-primary text-uppercase"
              onClick={() => {
                logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NEXT});
                nextStep();
              }}
            >
              {dictionary.YOTI_FAQ_BTN_FACE_SCAN_0_ctaLabel}
            </button>
          </div>
        </PageSelectorCTA>
      </div>
    </div>
  );
}