import React, { useEffect } from 'react';
import { AnalyticsEvents } from '../../_constants';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { getDeviceInstanceFromSN, PAIRING_STEP_NAMES } from '../../_helpers/device';
import { Switch } from '../Switch';

/**
 * Pairing step done only in global app, if deice is locked during pairing
 * @param {*} props 
 * @returns 
 */
export const DeviceUnlock = (props) => {
    const {
        dictionary
    } = props;

    const goToNextStep = () => {

        if (typeof props.goToNamedStep === 'function') {
            props.goToNamedStep(PAIRING_STEP_NAMES.SUCCESS);
        }
    }

    useEffect(() => {
        if (props?.device?.lockInfo?.locked === false) {
            //set timeout to consent animation from locked to unlocked to be shown 
            setTimeout(() => goToNextStep(), 700);
        }
    }, [props.device])


    const unlockDevice = () => {

        getDeviceInstanceFromSN(props?.device?.serialNumber).then(deviceInstance => {
            if (null !== deviceInstance && deviceInstance !== undefined) {
                deviceInstance.setLockConfig(0, false);
                logAnalyticsEvent(AnalyticsEvents.SLIDE_TO_UNLOCK);
            }
        })
    }

    return (
        <React.Fragment>
            <div className="pairing-wrapper">
                <div className="pairing-title">
                    <h1>{dictionary.UNLOCK_LOCKED_LABEL}</h1>
                </div>
                <div className={`pairing-device pairing-device-${props?.device?.lockInfo?.locked ? "locked" : "unlocked"} m-auto`}></div>
                {/*OLD SLIDER
                <div className="pairing-unlock mt-auto">
                    <div className="d-flex align-items-center justify-content-center">
                        <span className="bat-icon-lock active"></span>
                        <div className="unlock-switch">
                            <input type="checkbox" name="unlockDevice" />
                            <label htmlFor="unlockDevice"><i></i></label>
                        </div>
                        <span className="bat-icon-unlock"></span>
                    </div>
                    <div className="unlock-text">{dictionary.UNLOCK_SLIDE_LABEL}</div>
                </div>
                */}
                <div className="pairing-unlock mt-auto">
                    <div className="unlock-text">{dictionary.UNLOCK_SLIDE_LABEL}</div>
                        <Switch onChange={unlockDevice} checked={!(props?.device?.lockInfo?.locked)} className={`react-switch ${!(props?.device?.lockInfo?.locked) ? "checked" : ""}`}
                            checkedIcon={<span style={{
                                marginLeft: '-24px'
                            }} className='bat-icon-lock-outline-check'></span>}
                            uncheckedHandleIcon={<span style={{ opacity: 1}} className='bat-icon-lock-outline-check'></span>}
                            checkedHandleIcon={<span style={{ opacity: 1}} className='bat-icon-unlock-outline'></span>}
                            uncheckedIcon={<span style={{
                                marginRight: '-24px'
                            }} className='bat-icon-unlock-outline'></span>}
                            handleDiameter={64}
                            height={64}
                            width={230}
                        />
                        {/*<div className="unlock-switch-outline lock-to-unlock">
                        <input type="checkbox" name="unlockDevice" checked={!(props?.device?.lockInfo?.locked)}
                            onChange={unlockDevice} />
                        <label htmlFor="unlockDevice"><i></i></label>
                    </div>
        */}
                </div>
            </div>
            <div className="page-linear-gradient-bg"></div>
        </React.Fragment>
    );
}