import Timeout from "await-timeout";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { AnalyticsEvents, imagesConstants } from "../../_constants";
import {
  FW_UPDATE_DEVICE_STATES,
  FW_UPDATE_DOWNLOAD_MAX_PERC_REF,
  FW_UPDATE_MIN_BATTERY_LEVEL,
  FW_UPDATE_PROGRESS_STEPS,
  FW_UPDATE_STEPS,
} from "../../_constants/firmware.constants";
import { logAnalyticsEventForDevice } from "../../_helpers/analytics/logAnalytics";
import { debug } from "../../_helpers/debug";
import { Utils } from "../../_helpers/graphic/utils";
import useDebounce from "../../_hooks/debounce.hook";
import { dashboardServices } from "../../_services";
import { useFWUpdateContext } from "./FirmwareUpdateContext";
import { PageSelectorCTA } from "../PageSelectorCTA";

export function FirmwareUpdateStart({ goToNamedStep }) {
  const {
    dictionary,
    device,
    deviceInstance,
    firmwareData,
    retryCount,
    setTitle,
    handleDoThisLater,
    updateDevice,
    handleLowBatteryError,
    handleBinaryNotFoundError,
  } = useFWUpdateContext();
  const [isStarted, setIsStarted] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [firmware, setFirmware] = useState(null);
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(
    FW_UPDATE_PROGRESS_STEPS.DOWNLOAD
  );

  const updatePercentage = (maxRef, value) => {
    setPercentage((maxRef * value) / 100);
  };

  const update = async (firmwareManager) => {
    // alert(`Device DFU Mode On: ${device.dfuModeOn}`);
    try {
      await firmwareManager.update(
        deviceInstance.getDevice(),
        firmware,
        device.dfuModeOn
      );

      setPercentage(100);
      updateDevice({
        updatable: false,
        dfuModeOn: false,
      });
      await Timeout.set(400);
      deviceInstance.disconnect();

      const deviceInstanceIndex = window.deviceList?.findIndex(
        (instance) => instance.serialNumber === device.serialNumber
      );

      if (deviceInstanceIndex !== -1) {
        window.deviceList.splice(deviceInstanceIndex, 1);
      }

      goToNamedStep(FW_UPDATE_STEPS.SUCCESS);
    } catch (err) {
      console.debug("[FW_UPDATE] - error during update", err);
    }
  };

  const setDeviceDFUMode = useDebounce((dfuModeOn) => {
    updateDevice({ dfuModeOn, ongoingUpdate: dfuModeOn });
  }, 500);

  const handleDeviceState = (event) => {
    console.debug("[FW_UPDATE] - handleDeviceState", event, event?.dfuModeOn);
    debug("[FW_UPDATE] - handleDeviceState", event, event?.dfuModeOn);
    if (event.detail === FW_UPDATE_DEVICE_STATES.DFU_STARTED) {
      setDeviceDFUMode(true);
      setActiveStep(FW_UPDATE_PROGRESS_STEPS.MAINTAINING_CONNECTION);
    } else if (event.detail === FW_UPDATE_DEVICE_STATES.DFU_COMPLETED) {
      setDeviceDFUMode(false);
    }
  };

  const handleDFUProgress = (event) => {
    // console.debug(
    //   "[FW_UPDATE] - handleDFUProgress",
    //   event,
    //   (event.currentBytes / event.totalBytes) * 100 + 10
    // );

    if (!isNil(event.object) && event.object === "firmware") {
      setActiveStep(FW_UPDATE_PROGRESS_STEPS.INSTALLING);
      // updatePercentage(
      //   100 - FW_UPDATE_DOWNLOAD_MAX_PERC_REF,
      //   FW_UPDATE_DOWNLOAD_MAX_PERC_REF +
      //     1 +
      //     parseInt(Math.round((event.currentBytes * 100) / event.totalBytes))
      // );

      if (isNil(event.currentBytes) || isNil(event.totalBytes)) {
        return;
      }

      // debug(
      //   JSON.stringify(event),
      //   (event.currentBytes / event.totalBytes) * 100 +
      //     FW_UPDATE_DOWNLOAD_MAX_PERC_REF
      // );

      setPercentage(
        (event.currentBytes / event.totalBytes) * 100 +
          FW_UPDATE_DOWNLOAD_MAX_PERC_REF
      );
    }
  };

  const downloadFirmware = async () => {
    try {
      logAnalyticsEventForDevice(
        device,
        AnalyticsEvents.DOWNLOAD_FIRMWARE_UPDATE,
        {
          Latest_version: firmwareData?.latestFirmware,
        }
      );

      const url = firmwareData?.firmwareSource || "";
      // const url =
      //   "https://pcphotobackup.s3.eu-central-1.amazonaws.com/original_fw/ePod2%2B_PreV.zip";
      const res = await dashboardServices.getPackageFirmwareUpdate(
        url,
        (downloadInfo) => {
          updatePercentage(
            FW_UPDATE_DOWNLOAD_MAX_PERC_REF,
            parseInt(
              Math.floor((downloadInfo.downloaded * 100) / downloadInfo.total)
            )
          );
        }
      );

      logAnalyticsEventForDevice(
        device,
        AnalyticsEvents.DOWNLOAD_COMPLETE_FIRMWARE_UPDATE,
        {
          Latest_version: firmwareData?.latestFirmware,
        }
      );

      if (!res?.data) {
        return handleBinaryNotFoundError();
      }

      setFirmware(
        new File(
          [new Blob([res?.data])],
          `MyVuse-Firmware-${firmwareData?.latestFirmware}.zip`,
          { type: res?.data?.type }
        )
      );
    } catch (err) {
      console.debug("[FW_UPDATE] - Error during FW download");
      logAnalyticsEventForDevice(
        device,
        AnalyticsEvents.DOWNLOAD_FAILED_FIRMWARE_UPDATE,
        {
          Latest_version: firmwareData?.latestFirmware,
        }
      );
      handleBinaryNotFoundError();
    }
  };

  const startUpdate = () => {
    if (device?.batteryInfo?.chargeLevel < FW_UPDATE_MIN_BATTERY_LEVEL) {
      handleLowBatteryError();
    } else {
      setIsStarted(true);
      updateDevice({ ongoingUpdate: true });
      downloadFirmware();
    }
  };

  useEffect(() => {
    setSteps([
      dictionary.FW_UPDATE_FOTA_STEP_ONE,
      dictionary.FW_UPDATE_FOTA_STEP_TWO,
      dictionary.FW_UPDATE_FOTA_STEP_THREE,
    ]);
  }, [dictionary]);

  useEffect(() => {
    if (!isStarted) {
      setTitle(dictionary.FW_UPDATE_FOTA_ATTENTION_HEADER);
    } else {
      setTitle(dictionary.FW_UPDATE_HEADER);
    }
  }, [isStarted, setTitle]);

  useEffect(() => {
    if (retryCount > 0) {
      startUpdate();
    }
  }, [retryCount]);

  useEffect(() => {
    if (firmware) {
      const firmwareManager = new deviceInstance.FirmwareManager();
      const dfu = firmwareManager.getDFU();

      dfu.addEventListener("progress", handleDFUProgress);
      window.addEventListener("device_state", handleDeviceState);

      update(firmwareManager);

      return () => {
        dfu.removeEventListener("progress", handleDFUProgress);
        window.removeEventListener("device_state", handleDeviceState);
      };
    }
  }, [firmware]);

  return (
    <>
      <div className="sliding-panel-mid">
        <div className="sliding-panel-mid-icon">
          <img src={imagesConstants.FW_UPDATE} alt="fw update icon" />
        </div>
        <div className="sliding-panel-mid-info disclaimer">
          {dictionary.FW_UPDATE_FOTA_START_UPDATE_CONTENT}
        </div>
        {!isStarted && (
          <div className="sliding-panel-mid-info">
            <div className="form-check align-items-start">
              <input
                id="fw-update-disclaimer-check"
                className="form-check-input"
                type="checkbox"
                checked={disclaimerAccepted}
                onChange={() => setDisclaimerAccepted(!disclaimerAccepted)}
              />
              <label
                className="form-check-label text-start text-secondary"
                htmlFor="fw-update-disclaimer-check"
              >
                {dictionary.FW_UPDATE_FOTA_START_UPDATE_INFO}
              </label>
            </div>
          </div>
        )}
      </div>
      <PageSelectorCTA sticky={false} ctaUrl={true} className="mt-auto">
        {isStarted ? (
          <>
            <div className="text-center mb-5">
              <div className="text-secondary">
                {Utils.stringReplacePlaceholders(
                  dictionary.FW_UPDATE_FOTA_STEP_TITLE,
                  [activeStep + 1, steps.length]
                )}
              </div>
              <div>{steps[activeStep]}</div>
            </div>
            <div className="text-center">
              <span>
                {Math.ceil(percentage) < 100
                  ? `${Math.ceil(percentage)}%`
                  : dictionary.FW_UPDATE_FOTA_STEP_END}
              </span>
              <div className="custom-progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${percentage}%` }}
                  aria-valuenow={percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="d-grid">
              <button
                disabled={!disclaimerAccepted}
                className="btn btn-warning text-uppercase"
                type="button"
                onClick={startUpdate}
              >
                {dictionary.FW_UPDATE_FOTA_BTN_START_UPDATE_0_ctaLabel}
              </button>
            </div>
            {firmwareData?.isMandatory === false && (
              <div className="page-selector-cta-url">
                <span className="url-text" onClick={handleDoThisLater}>
                  {dictionary.FW_UPDATE_SKIP_LINK}
                </span>
              </div>
            )}
          </>
        )}
      </PageSelectorCTA>
    </>
  );
}
