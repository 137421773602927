import cx from "classnames";
import { isEmpty, range } from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { getGenders, getLegalAge } from "../../../_actions/appConfig.actions";
import { AnalyticsErrorNames, AnalyticsEvents, AnalyticsFieldNames, AnalyticsScreenEventNames, aemPages } from "../../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../../_helpers/aem/aemhelper";
import { Utils } from "../../../_helpers/graphic/utils";
import { Tenants } from "../../../_helpers/tenants";
import Picker from "../../WheelPicker";
import { useSignupFormContext } from "./SignupFormContext";
import { SignupFormStep } from "./SignupFormStep";
import { CustomInlineAlert } from "../../CustomInlineAlert";
import { getRegistration, setRegistration } from "../../../_actions";
import { logAnalyticsEvent } from "../../../_helpers/analytics/logAnalytics";

const FIELD_NAME_BIRTHDAY = "dob";
const FIELD_NAME_GENDER = "gender";
const PICKER_OPEN_CLASS = "pdp-open";
const DIGIT_HEIGHT = 38;
const PICKER_HEIGHT = 180;
const HIGHLIGHT_HEIGHT = 40;
const DEFAULT_DAY_VALUE = "--";
const DEFAULT_MONTH_VALUE = "--";
const DEFAULT_YEAR_VALUE = "----";

export function BirthdayStep(props) {
  const [dictionary, setDictionary] = useState({});
  const [isValid, setIsValid] = useState(false);
  const { formData } = useSignupFormContext();
  const { register, setValue, formState } = useFormContext();
  const { dirtyFields, errors } = formState;
  const [optionGroups, setOptionGroups] = useState({
    day: [DEFAULT_DAY_VALUE],
    month: [DEFAULT_MONTH_VALUE],
    year: [DEFAULT_YEAR_VALUE],
  });
  const [valueGroups, setValueGroups] = useState({
    day: DEFAULT_DAY_VALUE,
    month: DEFAULT_MONTH_VALUE,
    year: DEFAULT_YEAR_VALUE,
  });
  const [displayValueGroups, setDisplayValueGroups] = useState(valueGroups);
  const [isDobValid, setIsDobValid] = useState(undefined)
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const genders = useMemo(() => getGenders(), []);
  const { gender } = useWatch(FIELD_NAME_GENDER);

  const toggleIsPickerOpen = () => {
    document.body.classList.toggle(PICKER_OPEN_CLASS);
    setIsPickerOpen(!isPickerOpen);
  };

  const handleChangePicker = (name, value) => {
    setValueGroups({
      ...valueGroups,
      [name]: value,
    });
  };

  const handleDone = () => {
    setDisplayValueGroups(valueGroups);
    toggleIsPickerOpen();
    setValue(
      FIELD_NAME_BIRTHDAY,
      `${valueGroups.day}/${moment(valueGroups.month, "MMMM").format("MM")}/${
        valueGroups.year
      }`,
      { shouldDirty: true, shouldValidate: true }
    );
  };

  const isBirthdayDateValid = (value) => {
    const date = moment(value, "DD/MM/YYYY")

    const result = date.isValid() && moment().subtract(getLegalAge(), "years") > date

    if(!result) {
      logAnalyticsEvent(AnalyticsEvents.VALIDATION_ERROR, {field_name: AnalyticsFieldNames.DOB, error: AnalyticsErrorNames.INVALD_DATE})      
    }else {
      logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.DOB})
    }

    setIsDobValid(result)

    return result
  };

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.SIGN_UP_DOB});
    const aem = new AEMHelper();

    setDictionary({
      ...aem.getDictionary(aemPages.REGISTRATION, {
        REGISTRATION_FIELD_REQUIRED: propertyTextDefaultEmpty,
      }),
      ...aem.getDictionary(aemPages.REGISTRATION_DETAIL_DOB, {
        REGISTRATION_HEADER: propertyHeadingDefaultEmpty,
        REGISTRATION_DATE_BIRTH_LABEL: propertyTextDefaultEmpty,
        REGISTRATION_GENDER_LABEL: propertyTextDefaultEmpty,
        REGISTRATION_GENDER_MALE_LABEL: propertyTextDefaultEmpty,
        REGISTRATION_GENDER_FEMALE_LABEL: propertyTextDefaultEmpty,
        REGISTRATION_GENDER_UNSPECIFIED_LABEL: propertyTextDefaultEmpty,
        REGISTRATION_FIELD_OPTIONAL: propertyTextDefaultEmpty,
        REGISTRATION_JANUARY: propertyTextDefaultEmpty,
        REGISTRATION_FEBRUARY: propertyTextDefaultEmpty,
        REGISTRATION_MARCH: propertyTextDefaultEmpty,
        REGISTRATION_APRIL: propertyTextDefaultEmpty,
        REGISTRATION_MAY: propertyTextDefaultEmpty,
        REGISTRATION_JUNE: propertyTextDefaultEmpty,
        REGISTRATION_JULY: propertyTextDefaultEmpty,
        REGISTRATION_AUGUST: propertyTextDefaultEmpty,
        REGISTRATION_SEPTEMBER: propertyTextDefaultEmpty,
        REGISTRATION_OCTOBER: propertyTextDefaultEmpty,
        REGISTRATION_NOVEMBER: propertyTextDefaultEmpty,
        REGISTRATION_DECEMBER: propertyTextDefaultEmpty,
        REGISTRATION_DARK_AV_WARNING: propertyTextDefaultEmpty,
        REGISTRATION_PRIVACY_POLICY: propertyTextDefaultEmptyParsed,
        REGISTRATION_BTN_NEXT: propertyCtaItemsDefaultEmpty,
        REGISTRATION_INVALID_DOB: propertyTextDefaultEmpty
      }),
    });
  }, []);

  useEffect(() => {
    console.debug("GENDER", gender);
  }, [gender]);

  useEffect(() => {
    register(FIELD_NAME_BIRTHDAY, {
      required: true,
      validate: isBirthdayDateValid,
    });
    register(FIELD_NAME_GENDER);
  }, [register]);

  useEffect(() => {
    !isEmpty(dictionary) &&
      setOptionGroups({
        day: [
          DEFAULT_DAY_VALUE,
          ...range(1, 32).map((v) => v.toString().padStart(2, "0")),
        ],
        month: [
          DEFAULT_MONTH_VALUE,
          ...[
            dictionary.REGISTRATION_JANUARY,
            dictionary.REGISTRATION_FEBRUARY,
            dictionary.REGISTRATION_MARCH,
            dictionary.REGISTRATION_APRIL,
            dictionary.REGISTRATION_MAY,
            dictionary.REGISTRATION_JUNE,
            dictionary.REGISTRATION_JULY,
            dictionary.REGISTRATION_AUGUST,
            dictionary.REGISTRATION_SEPTEMBER,
            dictionary.REGISTRATION_OCTOBER,
            dictionary.REGISTRATION_NOVEMBER,
            dictionary.REGISTRATION_DECEMBER,
          ],
        ],
        year: [
          DEFAULT_YEAR_VALUE,
          ...range((new Date().getFullYear() - getLegalAge()), (new Date().getFullYear() - 101), -1).map((v) => v.toString()),
        ],
      });
  }, [dictionary]);

  useEffect(() => {
    setIsValid(
      dirtyFields[FIELD_NAME_BIRTHDAY] && !errors[FIELD_NAME_BIRTHDAY]
    );
  }, [formState, dirtyFields, errors]);

  useEffect(() => {
    const birthday = formData[FIELD_NAME_BIRTHDAY];

    if (birthday) {
      const [day, month, year] = birthday.split("/");
      const monthName = optionGroups.month.find(
        (_, i) => i === parseInt(month)
      );

      if (monthName) {
        const values = { day, month: monthName, year };

        setValueGroups(values);
        setDisplayValueGroups(values);
        setValue(FIELD_NAME_BIRTHDAY, formData[FIELD_NAME_BIRTHDAY]);
      }
    }
  }, [formData, optionGroups, setValue]);

  useEffect(() => {
    if (isValid) {
      let monthNumber = optionGroups.month.findIndex(monthValue => monthValue === valueGroups.month)
      if (monthNumber < 10) {
        monthNumber = '0' + monthNumber
      }

      let registration = getRegistration()
      registration.birthday = {day: valueGroups.day, month: monthNumber, year: valueGroups.year}
      setRegistration(registration)
    }
  }, [valueGroups, isValid]);

  return (
    <SignupFormStep
      title={dictionary.REGISTRATION_HEADER}
      privacyPolicyLabel={dictionary.REGISTRATION_PRIVACY_POLICY}
      ctaLabel={dictionary.REGISTRATION_BTN_NEXT_0_ctaLabel}
      isValid={isValid}
      {...props}
    >
      <div className="signup-form-wrapper">
        <div className="signup-form-row">
          <span className="form-mandatory">
            {dictionary.REGISTRATION_FIELD_REQUIRED}
          </span>
          <label className="page-label" htmlFor={FIELD_NAME_BIRTHDAY}>
            {dictionary.REGISTRATION_DATE_BIRTH_LABEL}
          </label>
          <div className="signup-form-dob-wrapper">
            <div
              className={cx("signup-form-dob-content", {
                valid: isDobValid,
              })}
              onClick={toggleIsPickerOpen}
            >
              <span>{displayValueGroups.day}</span>
              <span>{displayValueGroups.month}</span>
              <span>{displayValueGroups.year}</span>
            </div>
            <div className="signup-form-dob-modal-container">
              <div
                className="picker-modal-mask"
                style={{ display: isPickerOpen ? "block" : "none" }}
                onClick={toggleIsPickerOpen}
              />
              <div
                className={cx("picker-modal", {
                  "picker-modal-toggle": isPickerOpen,
                })}
              >
                <header>
                  <div className="title">Choose your Date</div>
                  <span onClick={handleDone}>Done</span>
                </header>
                <Picker
                  itemHeight={DIGIT_HEIGHT}
                  height={PICKER_HEIGHT}
                  highlightHeight={HIGHLIGHT_HEIGHT}
                  optionGroups={optionGroups}
                  valueGroups={valueGroups}
                  onChange={handleChangePicker}
                />
              </div>
            </div>
          </div>
        </div>
        {!isEmpty(genders) && (
          <div className="signup-form-row">
            <span className="form-optional">
              {dictionary.REGISTRATION_FIELD_OPTIONAL}
            </span>
            <label className="page-label">
              {dictionary.REGISTRATION_GENDER_LABEL}
            </label>
            <div className="genders">
              {genders.map((gender, i) => (
                <Fragment key={i}>
                  <input
                    type="radio"
                    id={`gender_${Object.keys(gender)[0].toLowerCase()}`}
                    name={FIELD_NAME_GENDER}
                    value={Object.keys(gender)[0]}
                    ref={register()}
                    onClick={() => logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.GENDER})}
                  />
                  <label
                    htmlFor={`gender_${Object.keys(gender)[0].toLowerCase()}`}
                  >
                    {
                      dictionary[
                        `REGISTRATION_${Object.values(gender)[0]}_LABEL`
                      ]
                    }
                  </label>
                </Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
      {(Tenants.isCanadaDark() || Tenants.isCanadaLight()) && (
        <div className="information-block ">
          <div className="d-flex">
            <div className="flex-shrink-0">
              <span className="bat-icon-alert-circle fs-16" />
            </div>
            <div className="flex-grow-1 ms-2">
              <span className="information-block-label">
                {Utils.stringReplacePlaceholders(
                  dictionary.REGISTRATION_DARK_AV_WARNING,
                  getLegalAge(),
                  true
                )}
              </span>
            </div>
          </div>
        </div>
      )}
      {isDobValid === false &&
      <div className="information-block-x">
        <CustomInlineAlert isShown={true} alertType="error">
          {dictionary.REGISTRATION_INVALID_DOB}
        </CustomInlineAlert>
      </div>
      }
    </SignupFormStep>
  );
}
