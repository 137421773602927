import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { ShopOrderDetail } from './ShopOrderDetail';
import { ShopOrdersHistory } from './ShopOrdersHistory';
import { aemPages, AnalyticsEvents, imagesConstants } from '../../../../_constants';
import { Utils } from '../../../../_helpers/graphic/utils';
import { logAnalyticsEvent } from '../../../../_helpers/analytics/logAnalytics';
import { AEMHelper, propertyImageSrcDefaultNull } from '../../../../_helpers/aem/aemhelper';
import { getAEMAssetsUrl } from '../../../../_actions/appConfig.actions';

export const ShopLastOrderBadge = ({
    lastOrder,
    onExplore,
    onOpenBasketPanel,
    onStartCheckout,
    dictionary
}) => {

    const [isOpenOrdersHistoryModal, setisOpenOrdersHistoryModal] = useState(false);
    const [isOpenOrderDetailsModal, setIsOpenOrderDetailsModal] = useState(false);
    const [currentOrder, setCurrentOrder] = useState(null);
    const [orderQty, setOrderQty] = useState(0);

    const toggleOrdersHistory = () => {
        setisOpenOrdersHistoryModal(!isOpenOrdersHistoryModal);
    }

    const onToggleReorderModal = (order) => {
        setCurrentOrder(order);
        setIsOpenOrderDetailsModal(!isOpenOrderDetailsModal);
    }

    useEffect(() => {
        if (lastOrder?.items) {
            setOrderQty(lastOrder.items.reduce((a, b) => a + (b.quantity ?? 0), 0));
        }
    }, [lastOrder])

    const onReorderButtonClick = () => {
        logAnalyticsEvent(AnalyticsEvents.REORDER);
        onToggleReorderModal(lastOrder)
    }

    const onExploreButtonClick = (...args) => {
        logAnalyticsEvent(AnalyticsEvents.ADD_FAVOURITES_NO_PAST_ORDERS)
        if (typeof onExplore === 'function') {
            onExplore(...args);
        }
    }

    return (
        <React.Fragment>
            {
                lastOrder ?

                    <React.Fragment>
                        <div className='reorder-label reorder-label-order d-flex justify-content-between'>
                            <div className="label-title ps-0">
                                {dictionary.SHOP_REORDER_MY_LAST_ORDERS}
                            </div>
                            <button className='btn reorder-label-btn' onClick={toggleOrdersHistory}>{dictionary.SHOP_REORDER_SEE_OLDER}</button>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-12">
                                <div className={`shop-reorder-summary`} >
                                    <div className='shop-reorder-summary-number'>
                                        <span>{dictionary.SHOP_REORDER_ORDER_TITLE} #{lastOrder.number}</span>
                                    </div>
                                    <div className='shop-reorder-summary-total'>
                                        <h2>{moment(lastOrder.date).format('DD/MM/yyyy')}</h2>
                                        <h2>{`$${lastOrder.total}`}</h2>
                                    </div>
                                    <div className='shop-reorder-summary-items'>
                                        <span>{Utils.stringReplacePlaceholders(dictionary.SHOP_REORDER_ITEMS, [orderQty])}</span>
                                        <button className='btn btn-primary btn-block text-uppercase' onClick={onReorderButtonClick}>{dictionary.SHOP_REORDER_TITLE}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ShopOrdersHistory
                            isOpen={isOpenOrdersHistoryModal}
                            closeFeatureModal={toggleOrdersHistory}
                            dictionary={dictionary}
                            onReorder={onToggleReorderModal}
                        />

                        <ShopOrderDetail
                            order={currentOrder}
                            isOpen={isOpenOrderDetailsModal}
                            closeFeatureModal={onToggleReorderModal}
                            dictionary={dictionary}
                            onOpenBasketPanel={onOpenBasketPanel}
                            onStartCheckout={onStartCheckout}
                        />
                    </React.Fragment>

                    :

                    <React.Fragment>
                        <div className='reorder-label reorder-label-order d-flex justify-content-between'>
                            <div className="label-title ps-0">
                                {dictionary.SHOP_REORDER_MY_LAST_ORDERS}
                            </div>
                        </div>
                        <div className="row d-flex justify-content-start">
                            <div className="col-12">
                                <div className="shop-reorder-summary" style={{
                                    backgroundImage: `url("${getAEMAssetsUrl()}${dictionary.SHOP_BG_LASTORDER?.desktop?.src}")`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover'
                                }}>
                                    <div className='shop-reorder-feature-badge'>
                                        <h2>{dictionary.SHOP_REORDER_NO_PAST_ORDERS_HEADER}</h2>
                                        <span className='shop-reorder-feature-badge-desc'>{dictionary.SHOP_REORDER_NO_PAST_ORDERS_CONTENT}</span>
                                        <button className='btn btn-sm btn-primary' onClick={onExploreButtonClick}>{dictionary.SHOP_REORDER_NO_PAST_ORDERS_BTN_EXPLORE_0_ctaLabel}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
            }
        </React.Fragment >
    );

}

