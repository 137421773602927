import * as Uri from "../_constants/api/uri.constants";
import IoTPlatformUtils from "../_helpers/api/IoTPlatformUtils";
import UrlGenerator from "../_helpers/api/urlGenerator";
import { Commons } from "../_helpers/commons";
import { store } from "../_store";
import { axiosRequest } from "../_helpers/api/axiosRequest";

export const sendRawPuffRecords = async (payload) => {
  const userPin = store.getState().onboardingReducer.userPin;
  const tenantUserId = Commons.generateTenantUserId(userPin);

  try {
    const result = await axiosRequest({
      url: UrlGenerator.createIoTPlatformUrlFor(Uri.sendRawPuffRecords),
      method: "POST",
      credentials: "include",
      cache: "no-cache",
      headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
      data: payload,
    });
    return [result.data, null];
  } catch (error) {
    return [null, error];
  }
};
