import cx from "classnames";
import React from "react";
import { AnalyticsEvents, AnalyticsTargetEventsNames } from "../../_constants";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";

const DeviceConnectionIndicator = ({
  connected = false,
  connectedLabel,
  disconnectedLabel,
}) => {
  return (
    <div className={cx("device-connection-indicator", { connected })} onClick={() => logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CONNECTED_STATUS})}>
      {connected ? connectedLabel : disconnectedLabel}
    </div>
  );
};

export { DeviceConnectionIndicator };
