import UrlGenerator from "../_helpers/api/urlGenerator";
import * as Uri from "../_constants/api/uri.constants";
import { getAccessToken, getCustomerId } from "../_actions";
import { getTenantId, getCountry, getCrmEnrichments } from "../_actions/appConfig.actions";
import { Commons } from "../_helpers/commons";
import IoTPlatformUtils from "../_helpers/api/IoTPlatformUtils";
import { axiosEnqueuedRequest, axiosInstance, axiosRequest } from "../_helpers/api/axiosRequest";
import { AxiosCancelTokensHolder } from "../_helpers/api/axiosCancelTokensHolder";

export const userServices = {
  loginUk, //UK
  loginCa, //CA
  getConfirmationLink, //UK
  getCustomerData, //UK
  register, //UK
  CAregister, //CA
  CAsetUserSettings, //UK

  updateUserInfo, //CA
  // getUserInfo, //CA
  /** CA address finder services */
  getAddresses,
  getAddressDetails,

  // NEW
  checkEmail,
  resetPassword,
  resendActivationLink,
  signup,
  login,
  getUserInfo,
  setUserSettings,
  getSalesforceId
};

async function loginUk(token, data) {
  let requestOptions = {
    url: UrlGenerator.createVypeUrlFor(Uri.login),
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${token}`,
      channel: "Vuse",
      countryCode: "UK",
    },
    cache: "no-cache",
    data: {
      username: data.email,
      password: data.password,
    },
  };
  return axiosRequest(requestOptions);
}

async function loginCa(data) {
  let requestOptions = {
    url: UrlGenerator.createCAVypeUrlFor(Uri.CAlogin),
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    },
    cache: "no-cache",
    data: {
      email: data.email,
      password: data.password,
    },
  };
  return axiosRequest(requestOptions);
}

async function CAsetUserSettings(data) {
  let requestOptions = {
    url: UrlGenerator.createCAVypeUrlFor(Uri.CAuserSettings),
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    },
    cache: "no-cache",
    data,
  };
  return axiosRequest(requestOptions);
}

/**
 *
 * @param {String} token
 */
async function getCustomerData(JWTtoken, token) {
  let requestOptions = {
    url: UrlGenerator.createVypeUrlFor(Uri.UKgetCustomerData),
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${JWTtoken}`,
      channel: "Vuse",
      token: token,
    },
    cache: "no-cache",
  };
  return axiosRequest(requestOptions);
}

/**
 * Service to request email with account activation link
 *
 * @param {Object} data: {websiteId: string, email: string}
 */
async function getConfirmationLink(token, data) {
  let requestOptions = {
    url: UrlGenerator.createVypeUrlFor(Uri.UKgetConfirmationLink),
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${token}`,
      channel: "Vuse",
      countryCode: "UK",
    },
    cache: "no-cache",
    data: data,
  };

  return axiosRequest(requestOptions);
}

/**
 * Service for user registration
 *
 * @param {RegisterModel} data
 * @param {String} token
 * @returns
 */
async function register(token, data) {
  let requestOptions = {
    url: UrlGenerator.createVypeUrlFor(Uri.UKregistration),
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${token}`,
      channel: "Vuse",
      countryCode: "UK",
    },
    cache: "no-cache",
    data,
  };

  return axiosRequest(requestOptions);
}

/**
 *
 * @param {RegisterModel} data
 */
async function CAregister(data) {
  let requestOptions = {
    url: UrlGenerator.createCAVypeUrlFor(Uri.CAregistrationDarkProvinces),
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
      Authorization: process.env.REACT_APP_CANADA_REGISTRATION_DARK_TOKEN,
    },
    cache: "no-cache",
    data,
  };

  return axiosRequest(requestOptions);
}

function composeAddressPostes(baseUrl, apiKey, addressPart, lastId, locale) {
  let url = baseUrl

  url += `?Key=${encodeURIComponent(apiKey)}`;
  url += "&SearchTerm=" + encodeURIComponent(addressPart);
  url += "&Country=" + encodeURIComponent(getCountry());
  url += "&MaxResults=" + encodeURIComponent(8);
  url += "&LanguagePreference=" + encodeURIComponent(locale);
  if (lastId) {
    url += "&LastId=" + encodeURIComponent(lastId);
  }

  return {
    url,
    method: "GET",
    credentials: "include",
    cache: "no-cache",
  };
}

function composeAddressLoqate(baseUrl, apiKey, addressPart, lastId, locale) {
  let url = baseUrl

  url += `?Key=${encodeURIComponent(apiKey)}`;
  url += `&Text=${encodeURIComponent(addressPart)}`;
  url += `&Countries=${encodeURIComponent(getCountry())}`;
  url += `&Limit=${encodeURIComponent(8)}`;
  url += "&Language=" + encodeURIComponent(locale);
  if (lastId) {
    url += "&Container=" + encodeURIComponent(lastId);
  }
  
  return {
      url,
      method: 'POST',
      credentials: 'include',
      cache: 'no-cache',
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json',
      },
  };
}

async function getAddresses(addressPart, lastId) {
  let requestOptions = false

  let _appConfigString = localStorage.getItem("appConfig")
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString)
    const baseUrl = appConfig?.urls[appConfig.locale]?.ADDR_AUTO_COMPLETE_SEARCH
    const locale = Commons.getLocaleForMomentLocalization(appConfig)
    const apiKey = appConfig?.addr_auto_complete_key

    if (appConfig.supported_services.includes('ONBOARDING_ADDRESS_POSTES')) {
      requestOptions = composeAddressPostes(baseUrl, apiKey, addressPart, lastId, locale)
    } else if (appConfig.supported_services.includes('ONBOARDING_ADDRESS_LOQATE')) {
      requestOptions = composeAddressLoqate(baseUrl, apiKey,addressPart, lastId, locale)
    }
  }
  
  if (requestOptions) {
    return axiosRequest(requestOptions)
  } else {
    return null
  }
}

function composeAddressDetailsPostes(baseUrl, apiKey, addressId, locale) {
  let url = baseUrl

  url += `?Key=${encodeURIComponent(apiKey)}`;
  url += `&Id=${encodeURIComponent(addressId)}`;
  url += "&LanguagePreference=" + encodeURIComponent(locale);

  return {
    url,
    method: "GET",
    credentials: "include",
    cache: "no-cache",
  };
}

function composeAddressDetailsLoqate(baseUrl, apiKey, addressId, locale) {
  let url = baseUrl
  
  url += `?Key=${encodeURIComponent(apiKey)}`;
  url += "&Language=" + encodeURIComponent(locale);
  url += `&Id=${encodeURIComponent(addressId)}`;
  
  return {
      url,
      method: 'GET',
      credentials: 'include',
      cache: 'no-cache',
  };
}

async function getAddressDetails(addressId) {
  let requestOptions = false

  let _appConfigString = localStorage.getItem("appConfig")
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString)
    const baseUrl = appConfig?.urls[appConfig.locale]?.ADDR_AUTO_COMPLETE_DETAIL
    const locale = Commons.getLocaleForMomentLocalization(appConfig)
    const apiKey = appConfig?.addr_auto_complete_key

    if (appConfig.supported_services.includes('ONBOARDING_ADDRESS_POSTES')) {
      requestOptions = composeAddressDetailsPostes(baseUrl, apiKey, addressId, locale)
    } else if (appConfig.supported_services.includes('ONBOARDING_ADDRESS_LOQATE')) {
      requestOptions = composeAddressDetailsLoqate(baseUrl, apiKey, addressId, locale)
    }
  }

  if (requestOptions) {
    return axiosRequest(requestOptions)
  } else {
    return null
  }
}

// NEW

function checkEmail(email) {
  return async () =>
    axiosInstance().get(UrlGenerator.createRequestUrl(Uri.CHECK_EMAIL), {
      params: { email },
      headers: {
        "x-tenant-id": getTenantId(),
      },
    });
}

function resetPassword(email) {
  return async () =>
    axiosInstance().post(
      UrlGenerator.createRequestUrl(Uri.RESET_PASSWORD),
      { email },
      {
        headers: {
          "x-tenant-id": getTenantId(),
        },
      }
    );
}

function resendActivationLink(email) {
  return async () =>
    axiosInstance().post(
      UrlGenerator.createRequestUrl(Uri.RESEND_ACTIVATION_LINK),
      { email },
      {
        headers: {
          "x-tenant-id": getTenantId(),
        },
      }
    );
}

function signup(data) {
  return async () =>
    axiosInstance().post(UrlGenerator.createRequestUrl(Uri.USERS), data, {
      headers: {
        "x-tenant-id": getTenantId() || null,
      },
    });
}

function login(data) {
  return async () =>
    axiosInstance().post(UrlGenerator.createRequestUrl(Uri.LOGIN), data, {
      headers: {
        "x-tenant-id": getTenantId() || null,
      },
    });
}

function getUserInfo(userId) {
  return async () =>
    axiosInstance().get(UrlGenerator.createRequestUrl(`${Uri.USERS}/${userId}`), {
      headers: {
        Authorization: getAccessToken(),
        "x-tenant-id": getTenantId() || null,
        "x-customer-id": userId,
      },
    });
}

function updateUserInfo(userId, data) {
  return async () => {
    const requestId = window.crypto.randomUUID()
    const controller = new AbortController();

    AxiosCancelTokensHolder.add({
      abort: () => controller.abort('forceCancel'),
      requestId,
    });

    return axiosInstance().put(UrlGenerator.createRequestUrl(`${Uri.USERS}/${userId}`), data, {
      headers: {
        "x-tenant-id": getTenantId(),
        "x-customer-id": userId || getCustomerId(),
        Authorization: getAccessToken(),
      },
      signal: controller?.signal,
      requestId
    });
  }
}

function setUserSettings(userId, data) {
  return async () => {
    const crmEnrichments = getCrmEnrichments();

    if (crmEnrichments.includes(data?.settingName)) {
      let requestOptions = {
        url: UrlGenerator.createRequestUrl(`${Uri.USERS}/${userId}/settings`),
        method: "POST",
        headers: {
          "x-tenant-id": getTenantId(),
          "x-customer-id": userId || getCustomerId(),
          Authorization: getAccessToken(),
        },
        cache: "no-cache",
        data
      };

      axiosEnqueuedRequest(requestOptions);
    }
    return {data: true}
  }
}

function getSalesforceId(tenantUserId, email) {
  return axiosInstance().get(
      UrlGenerator.createIoTPlatformUrlFor(`${Uri.salesforceId}?email=` + email),
      {
        headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId)
      }
    );
}