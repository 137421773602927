import UrlGenerator from '../_helpers/api/urlGenerator';
import * as Uri from '../_constants/api/uri.constants';
import IoTPlatformUtils from '../_helpers/api/IoTPlatformUtils';
import { getAccessToken } from '../_actions';
import { axiosRequest } from '../_helpers/api/axiosRequest';

export const shopServices = {
    getFlavourForYouProducts, //UK
    getLastOrder, //UK
    getCatalog, //CA
    getOneTimeToken //CA
};

/**
 * Get wishlist from be
 * 
 * @param {*} token 
 * @param {*} userId 
 * @param {*} uuid user uuid
 * @returns 
 */
async function getFlavourForYouProducts(tenantUserId, uuid) {
    let requestOptions = {
        url: UrlGenerator.createIoTPlatformUrlFor(Uri.flavourForUSuggestions)+`?uuid=${uuid}`,
        method: 'GET',
        headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
        cache: 'no-cache'
    };

    return axiosRequest(requestOptions);
}

/**
 * Get one time token to procede with checkout

 * @returns 
 */
 async function getOneTimeToken() {
    let requestOptions = {
        url: UrlGenerator.createCAVypeUrlFor(Uri.oneTimeToken),
        method: 'GET',
        headers: {
            'Authorization': getAccessToken(),
            "Content-Type": "application/json; charset=utf-8",
            "Accept": "application/json",
        },
        cache: 'no-cache'
    };

    return axiosRequest(requestOptions);
}


/**
 * Uk service to get last order
 * @param {*} token 
 * @param {*} userId 
 * @returns 
 */
async function getLastOrder(token, userId) {
    
    // let requestOptions = {
    //     url: UrlGenerator.createIoTPlatformUrlFor(Uri.lastOrder),
    //     method: 'GET',
    //     headers: {
    //         'Authorization': token,
    //         'X-name': userId,
    //         'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
    //         "Content-Type": "application/json",
    //         "X-user-os": "IOS"
    //     },
    //     cache: 'no-cache'
    // };

    // return axiosRequest(requestOptions);
}

/**
 * Retrieve flavors catalog
 */
 async function getCatalog(tenantUserId, uuid) {
    let requestOptions = {
        url: UrlGenerator.createIoTPlatformUrlFor(Uri.catalog)+`?uuid=${uuid}`,
        method: 'GET',
        headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
        cache: 'no-cache'
    };

    return axiosRequest(requestOptions);
}
