import _ from 'lodash';
import moment from 'moment';

class SubscriptionModel {

    total = 0;
    quantity = 0;
    items = [];
    nextShippingDate = null;
    shippingAddress = null;
    paymentMethod = null;
    frequencyInDays = null;

    constructor(data) {

        let nextDeliveryDate = '-';
        if(data.pausedAt === null){
            if(data.nextShipDateOverride !== null){
                nextDeliveryDate = moment(data.nextShipDateOverride).format('DD/MM/YYYY');
            } else {
                if(data.lastPaymentAt !== null && moment(data.lastPaymentAt).isValid() )  {
                    nextDeliveryDate = moment(data.lastPaymentAt).add(data.frequencyInDays ?? 0, 'days').format('DD/MM/YYYY');     
                }
            }
        }

        this.quantity = data.items.reduce((o, r) => (r.quantity ?? 0) + o, 0);
        this.total = _.get(data, 'lastOrder.totalPrice', null) ?? _.get(data, 'lastPriceBreakdown.total', 0);
        this.items = data.items;
        this.nextShippingDate = nextDeliveryDate;
        this.shippingAddress = data.shippingAddress;
        this.paymentMethod = data.paymentMethod;
        this.frequencyInDays = data.frequencyInDays;
    }

    toObj = () => {
        return {
            total: this.total,
            items: this.items,
            nextShippingDate: this.nextShippingDate,
            shippingAddress: this.shippingAddress,
            paymentMethod: this.paymentMethod,
            quantity: this.quantity,
            frequencyInDays: this.frequencyInDays
        }
    }

}

export { SubscriptionModel };