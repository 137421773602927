import { groupBy, isArray, isFunction, isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { imagesConstants } from "../../_constants";
import { debug } from "../../_helpers/debug";
import { commonsServices } from "../../_services";
import { FadingList } from "../FadingList";

const PublicPageCountrySelector = ({ onSelect, content }) => {
  const [countries, setCountries] = useState({});
  const [provinces, setProvinces] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const handleCountryClick = (value) => {
    console.debug("[GPSPP] - handleCountryClick country", value);
    debug("[GPSPP] - handleCountryClick country: " + JSON.stringify(value));

    if (isNil(selectedCountry)) {
      const provinces = countries?.[value];

      setSelectedCountry(value);
      setProvinces(provinces);

      if (provinces?.length === 1) {
        setSelectedProvince(0);
        setLanguages(provinces[0].supported_locales);
      }
    } else {
      setSelectedCountry(null);
      setSelectedProvince(null);
      setSelectedLanguage(null);
      setProvinces([]);
      setLanguages([]);
    }
  };

  const handleProvinceClick = (value) => {
    console.debug("[GPSPP] - handleProvinceClick province", value);
    debug("[GPSPP] - handleProvinceClick province: " + JSON.stringify(value));

    if (isNil(selectedProvince)) {
      setSelectedProvince(value);
      setLanguages(provinces[value].supported_locales);
    } else {
      setSelectedProvince(null);
      setSelectedLanguage(null);
      setLanguages(null);
    }
  };

  const handleLanguageClick = (value) => {
    console.debug("[GPSPP] - handleLanguageClick language", value, languages);
    debug("[GPSPP] - handleLanguageClick language: " + JSON.stringify(value));

    if (languages.length === 1) {
      return;
    }

    if (isNil(selectedLanguage)) {
      setSelectedLanguage(value);
    } else {
      setSelectedLanguage(null);
    }
  };

  const handleSelect = () => {
    const province = provinces[selectedProvince];
    const language = languages[selectedLanguage];

    console.debug("[GPSPP] - handleSelect province", province);
    debug("[GPSPP] - handleSelect province: " + JSON.stringify(province));
    console.debug("[GPSPP] - handleSelect language", language);
    debug("[GPSPP] - handleSelect language: " + JSON.stringify(language));

    if (isFunction(onSelect)) {
      onSelect({
        province,
        language,
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await commonsServices.getCountries();

        if (res.data?.data) {
          const countries = res.data?.data;

          console.debug("[GPSPP] - getCountries res", countries);
          debug("[GPSPP] - getCountries res: " + JSON.stringify(countries));

          if (isArray(countries)) {
            setCountries(groupBy(countries, "country_iso"));
          }
        }
      } catch (err) {
        console.debug("[GPSPP] - getCountries err", err);
        debug("[GPSPP] - getCountries err: " + JSON.stringify(err));
      }
    })();
  }, []);

  useEffect(() => {
    if (languages?.length === 1) {
      setSelectedLanguage(0);
    }
  }, [languages]);

  return (
    <div className="gps-pp-card gps-pp-country-selector">
      <div className="gps-pp-country-selector-content">
        {content}
        <div className="gps-pp-country-selector-list">
          <div className="countries">
            <FadingList
              items={Object.entries(countries).map(([key, value], i) => ({
                item: key,
                value: value[0]?.country_label,
                key: i,
                flag:
                  imagesConstants?.[`FLAG_${value[0]?.country_iso}`] ||
                  imagesConstants.FLAG_CA,
              }))}
              selectedItem={selectedCountry}
              handleClick={handleCountryClick}
            />
          </div>
        </div>
        {provinces?.length > 1 && (
          <div className="gps-pp-country-selector-list">
            <div className="gps-pp-country-selector-label">Select Province</div>
            <div className="provinces">
              <FadingList
                items={provinces.map((province, i) => ({
                  item: i,
                  value: province?.province_label,
                  key: i,
                }))}
                selectedItem={selectedProvince}
                handleClick={handleProvinceClick}
              />
            </div>
          </div>
        )}
        {languages?.length > 0 && (
          <div className="gps-pp-country-selector-list">
            <div className="gps-pp-country-selector-label">Select Language</div>
            <div className="languages">
              <FadingList
                items={languages.map((language, i) => ({
                  item: i,
                  value: Object.values(language)[0],
                  key: i,
                }))}
                selectedItem={selectedLanguage}
                handleClick={handleLanguageClick}
              />
            </div>
          </div>
        )}
      </div>
      <div className="gps-pp-country-selector-cta">
        <button
          type="button"
          className="btn btn-primary"
          disabled={isNil(selectedLanguage)}
          onClick={handleSelect}
        >
          Select
        </button>
      </div>
    </div>
  );
};

export { PublicPageCountrySelector };
