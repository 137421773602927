import React from "react";
import { number, string } from "prop-types";

export function ProgressCircle({
  size = 100,
  strokeWidth = 2,
  trackColor = "lightgray",
  indicatorColor = "dodgerblue",
  value = 0,
}) {
  const wrapperSize = size + 2 * strokeWidth;
  const radius = size / 2;
  const length = 2 * Math.PI * radius;

  const styles = {
    cx: wrapperSize / 2,
    cy: wrapperSize / 2,
    r: radius,
    fill: "none",
    strokeWidth: strokeWidth,
  };

  return (
    <svg
      className="progress-circle"
      style={{
        height: wrapperSize,
        width: wrapperSize,
        transform: "rotate(-90deg)",
      }}
    >
      <circle
        className="progress-circle-track"
        style={{
          ...styles,
          stroke: trackColor,
        }}
      />
      <circle
        className="progress-circle-indicator"
        style={{
          ...styles,
          stroke: indicatorColor,
          strokeLinecap: "round",
          strokeDasharray: length,
          strokeDashoffset: length - (length * value) / 100,
        }}
      />
    </svg>
  );
}

ProgressCircle.propTypes = {
  size: number,
  strokeWidth: number,
  trackColor: string,
  indicatorColor: string,
  value: number,
};