import { onboardingConstants } from '../_constants';
import { REQUEST, SUCCESS, FAILURE } from './action-type.util';

export const initialState = {

    /************/
    oldUserTenantId: null,
    userPin: null,
    loading: false,
    acceptedCookies: true,
    /************/
    error: null,
    userProfile: null,
    isResetPinFlow: false,
    isCreatingNewPin: false,
};

export function onboardingReducer(state = initialState, action) {

    switch (action.type) {

        case 'LOGOUT': {
            return {
                ...state, 
                oldUserTenantId: null,
                userPin: null,
                loading: false,
                error: null,
                isResetPinFlow: false,
                isCreatingNewPin: false,
            };
        }

        case 'RESET_REDUCER': {
            return {
                oldUserTenantId: null,
                userPin: null,
                loading: false,
                error: null,
                acceptedCookies: true,
                isResetPinFlow: false,
                isCreatingNewPin: false,
            };
        }

        case onboardingConstants.SET_IS_RESET_PIN_FLOW: {
            return {
                ...state,
                isResetPinFlow: action.data
            }
        }

        case onboardingConstants.SET_IS_CREATING_NEW_PIN: {
            return {
                ...state,
                isCreatingNewPin: action.data
            }
        }

        case onboardingConstants.SET_ACTIVE_COOKIE_POLICY: {
            return {
                ...state,
                acceptedCookies: action.data
            }
        }

        case onboardingConstants.SET_USER_PIN: {
            return {
                ...state,
                userPin: action.data
            }
        }
        
        case REQUEST(onboardingConstants.UPDATE_USER):
        case REQUEST(onboardingConstants.PIN_VALIDATION):
        case REQUEST(onboardingConstants.QUESTION_VALIDATION):
        case REQUEST(onboardingConstants.UPDATE_PIN):
        case REQUEST(onboardingConstants.UPDATE_VAULT):
        case REQUEST(onboardingConstants.STORE_PIN):
        case REQUEST(onboardingConstants.SECURE_VAULT): {
            return {
                ...state,
                loading: true
            }
        }

        case SUCCESS(onboardingConstants.PIN_VALIDATION):
        case SUCCESS(onboardingConstants.SECURE_VAULT):
        case SUCCESS(onboardingConstants.UPDATE_PIN):
        case SUCCESS(onboardingConstants.UPDATE_VAULT):
        case SUCCESS(onboardingConstants.STORE_PIN):
        case SUCCESS(onboardingConstants.QUESTION_VALIDATION):
        case SUCCESS(onboardingConstants.UPDATE_USER):
        case FAILURE(onboardingConstants.UPDATE_USER):
        case FAILURE(onboardingConstants.PIN_VALIDATION):
        case FAILURE(onboardingConstants.QUESTION_VALIDATION):
        case FAILURE(onboardingConstants.UPDATE_PIN):
        case FAILURE(onboardingConstants.UPDATE_VAULT):
        case FAILURE(onboardingConstants.STORE_PIN):
        case FAILURE(onboardingConstants.SECURE_VAULT): {
            return {
                ...state,
                loading: false
            }
        }

        case onboardingConstants.SET_OLD_USER_TENANT_ID: {
            console.log("onboardingConstants.SET_OLD_USER_TENANT_ID", action.data);
            return {
                ...state,
                oldUserTenantId: action.data
            };
        }
        /*******************************************/

        case REQUEST(onboardingConstants.GET_USER_PROFILE):
            return {
                ...state,
                loading: true
            };

        case SUCCESS(onboardingConstants.GET_USER_PROFILE):
            return {
                ...state,
                userProfile: action.data,
                loading: false
            };

        case FAILURE(onboardingConstants.GET_USER_PROFILE):
            return {
                ...state,
                error: action.error,
                loading: false
            };

        case REQUEST(onboardingConstants.SEND_FAVOURITES_INFO):
            return {
                ...state,
                loading: true
            };

        case SUCCESS(onboardingConstants.SEND_FAVOURITES_INFO):
            return {
                ...state,
                loading: false
            };

        case FAILURE(onboardingConstants.SEND_FAVOURITES_INFO):
            return {
                ...state,
                error: action.error,
                loading: false
            };

        default:
            return state;
    }

}