
import React, { useState, useEffect } from 'react';
import { CustomAlert } from '../CustomAlert';
import { commonsServices } from '../../_services';
import { Commons } from '../../_helpers/commons';

/**
 * Alert component showed when the version the user is using is obsolete
 * @param {*} props 
 */
export const AlertAppVersion = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    /* when user opens app make check on app version */
    useEffect(() => {
        /* when app goes in foreground make check on app version */
        window.document.addEventListener("visibilitychange", handleCheckAppVersion);

        return () => {
            window.document.removeEventListener("visibilitychange", handleCheckAppVersion);
        }
    }, [])

    const handleCheckAppVersion = async() => {
        if (document.visibilityState === 'visible') {
            const [checkAppVersionResponse, checkAppVersionError] = await commonsServices.checkAppVersion();
            const flVersionEnabled = (checkAppVersionResponse?.data?.status === 'enabled');
            if (
                checkAppVersionError === null &&
                !flVersionEnabled
            ) {
                setIsOpen(true);
            }
        }
    }

    if (Commons.isLocalEnvironment()) {
        return null;
    }

    return (

        <CustomAlert isOpen={isOpen}
            title={"New version available"}
            hideOkButton={false} 
            onClose={() => window.location.reload(true)}
            buttonCloseLabel="REFRESH"
            customClasses="app-version-alert"
        >
            We have released a new version of MYVUSE. Please refresh the page to enjoy new version.
        </CustomAlert>
    );
};