import React from "react";
import { connect } from "react-redux";
import { buildURI } from "../../_helpers/navigation";
import { history } from "../../_helpers/history";
import { CountrySelectorForm } from "../../_components";
import { commonsActions } from "../../_actions";
import { Tenants } from "../../_helpers/tenants";
import AEMCreator from "../../_helpers/aem/aemCreator";
import { isSupportedService } from "../../_actions/appConfig.actions";
import { servicesConstants } from "../../_constants";
import { removeGTM } from "../../_vendors/googleAnalytics/index";
import { configureQualtrics, qualtricsLoader } from "../../_vendors/qualtrics";
import { isNil } from "lodash";
import { debug } from '../../_helpers/debug';

class CountrySelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countries: [],
      loading: false,
      defaultCountry : null,
    };

    this.handleLanguageSelection = this._handleLanguageSelection.bind(this);
  }

  componentDidMount() {
    const { getCountries } = this.props;

    /* Set the default country only for Germany */
    const browserLocale = navigator?.language;
    //debug("browserLocale: " + browserLocale);
    if(!isNil(browserLocale) && (browserLocale === 'de' || browserLocale === 'de-DE')){
      this.setState({defaultCountry: 'de'});
    }

    /**
     * Retrieve from BE coauntries and languages to show in UI
     */
    getCountries(
      (response) => {
        this.setState({ countries: response.data });
      },
      (e) => {
        this.props.showErrorAlert();
      }
    );

    document.body.classList.add("country-selector");
  }

  componentWillUnmount() {
    document.body.classList.remove("country-selector");
  }

  _handleLanguageSelection(formData) {
    if (formData?.country?.tenantId) {
      const { language, country } = formData;

      this.setState({ loading: true }, () => {
        AEMCreator.initAEM(country, language)
          .then(() => {
            this.setState({ loading: false }, async () => {
              //This tenant object is needed to drive the initial
              //app startup
              //await setTenant(tenant);

              //Store selected tenant for later use
              //setSelectedTenant(tenant);
              if (isSupportedService(servicesConstants.QUALTRICS)) {
                configureQualtrics()
                //qualtricsLoader()
              }
              const isDisabled = isSupportedService(
                servicesConstants.ANALYTICS_GOOGLE_DISABLED
              );
              if (isDisabled) {
                removeGTM();
              }

              history.push(buildURI(Tenants.getAfterCountrySelectionView()));
            });
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      });
    }
  }

  render() {
    const { countries, loading, defaultCountry } = this.state;

    return (
      <div className="page w-100">
        <CountrySelectorForm
          countries={countries}
          onSubmit={this.handleLanguageSelection}
          loading={loading || this.props.loading}
          defaultCountry={defaultCountry}
        />
        <div className="page-linear-gradient-bg"></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.commonsReducer.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCountries: (successCb, failureCb) => {
      dispatch(commonsActions.getCountries(successCb, failureCb));
    },
    showErrorAlert: () => {
      dispatch(commonsActions.showErrorAlert(true));
    },
  };
}

const connnectedCountrySelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(CountrySelector); //withTranslation()
export { connnectedCountrySelector as CountrySelector };
