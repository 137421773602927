import { Singleton } from "../../_classes/singleton";
import { Commons } from "../commons";

export class CheckoutHelper extends Singleton {
  constructor() {
    super();
    this.generateANewAnalyticsToken();
  }

  getAnalyticsToken() {
    return this.analyticsToken;
  }

  generateANewAnalyticsToken() {
    this.analyticsToken = Commons.generateSessionId(20);
  }

  static getAnalyticsToken() {
    return this.getInstance().getAnalyticsToken();
  }

  static getANewAnalyticsToken() {
    const checkout = this.getInstance();
    checkout.generateANewAnalyticsToken();
    return this.getAnalyticsToken();
  }
}