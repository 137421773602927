import {
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-moment";
import { isEmpty, isNil } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import {
  aemPages,
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  imagesConstants,
} from "../../_constants";
import environmentConstants from "../../_constants/environment/environment.constants";
import {
  AEMHelper,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { Commons } from "../../_helpers/commons";
import {
  getMonthlyStats,
  getUsageTrackerOptInDate,
} from "../../_helpers/usage-tracker";
import { DailyCollectionName, getDbInstance } from "../../_helpers/utils";
import { UsageTrackerPreset } from "./UsageTrackerPreset";
import { UsageTrackerPuffsSlider } from "./UsageTrackerPuffsSlider";
import { UsageTrackerTooltip } from "../UsageTrackerTooltip/UsageTrackerTooltip";

ChartJS.defaults.font.family = "Montserrat";

ChartJS.register(
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler
);

Tooltip.positioners.top = function (items) {
  const pos = Tooltip.positioners.average(items);

  // Happens when nothing is found
  if (pos === false) {
    return false;
  }

  return {
    x: pos.x,
    y: 0,
    xAlign: "center",
    yAlign: "bottom",
  };
};

const img = new Image();
img.src = imagesConstants.UT_MONTHLY_CHART_INDICATOR;

const verticalIndicatorPlugin = {
  id: "verticalIndicatorPlugin",
  afterDraw(chart) {
    if (chart.tooltip?._active?.length > 0) {
      const x = chart.tooltip._active[0].element.x;
      const yAxis = chart.scales.y;
      const ctx = chart.ctx;

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, yAxis.top);
      ctx.lineTo(x, yAxis.bottom);
      ctx.lineWidth = 1;
      ctx.strokeStyle = "rgba(255, 255, 255, 1)";
      ctx.stroke();
      ctx.restore();

      if (img.complete) {
        ctx.drawImage(img, x - 15, yAxis.bottom / 2 + 10, 30, 20);
      } else {
        img.onload = () =>
          ctx.drawImage(img, x - 15, yAxis.bottom / 2 + 10, 30, 20);
      }
    }
  },
};

const showCurrentTooltipPlugin = {
  id: "showCurrentTooltipPlugin",
  afterDatasetUpdate(chart, args, options) {
    let index = moment().date();
    const data = chart?.data?.datasets?.[0]?.data;

    console.debug(
      "showCurrentTooltipPlugin",
      chart.tooltip.getActiveElements(),
      data
    );

    // Need to reset otherwise the counter not update in real-time
    chart.tooltip.setActiveElements([]);

    if (!options?.isThisMonth) {
      const curr = Math.round(moment(options?.date).daysInMonth() / 2);

      console.debug("showCurrentTooltipPlugin_curr", curr, data[curr]);

      if (isNil(data[curr])) {
        index = moment(options.optinDate).date();
      } else {
        index = curr;
      }
    }

    chart.tooltip.setActiveElements([
      {
        datasetIndex: 0,
        index: index - 1,
      },
    ]);
  },
};

const chartOptions = (dictionary, date, isThisMonth, optinDate, data) => ({
  responsive: true,
  maintainAspectRatio: false,
  animations: false,
  interaction: {
    intersect: false,
    mode: "index",
  },
  layout: {
    padding: {
      left: 16,
      right: 16,
      top: 45,
    },
  },
  plugins: {
    showCurrentTooltipPlugin: {
      date,
      isThisMonth,
      optinDate,
    },
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: true,
      events: ["click", "mousemove", "touchmove"],
      position: "top",
      xAlign: "center",
      yAlign: "bottom",
      backgroundColor: "rgba(255, 255, 255, 1)",
      cornerRadius: 5,
      titleAlign: "center",
      titleColor: "rgba(185, 185, 185, 1)",
      titleFont: {
        size: "10px",
        lineHeight: "12px",
        weight: "700",
      },
      titleMarginBottom: 0,
      bodyAlign: "center",
      bodyColor: "rgba(36, 36, 36, 1)",
      bodyFont: {
        size: "10px",
        lineHeight: "12px",
        weight: "700",
      },
      displayColors: false,
      callbacks: {
        title: function (titles) {
          const label = titles?.[0]?.label;

          if (label) {
            return label.toUpperCase();
          }
        },
        label: function (tooltipItem) {
          return tooltipItem
            ? `${tooltipItem.formattedValue} ${
                dictionary.UT_PUFFS_LABEL || "PUFFS"
              }`
            : "";
        },
      },
    },
  },
  scales: {
    x: {
      type: "time",
      time: {
        unit: "week",
        round: "day",
        displayFormats: {
          week: "D",
        },
        tooltipFormat: "ddd ll",
      },
      grid: {
        color: false,
        tickColor: "rgba(255, 255, 255, 0.1)",
      },
      ticks: {
        color: "rgba(255, 255, 255, 1)",
        font: {
          size: "10",
          weight: "bold",
          lineHeight: "14px",
        },
        backdropPadding: {
          x: 10,
          y: 4,
        },
      },
    },
    y: {
      min: 0,
      suggestedMax: 100,
      position: "right",
      grid: {
        color: "rgba(255, 255, 255, 0.1)",
        drawBorder: false,
      },
      ticks: {
        precision: 0,
        stepSize: 100,
        count: 5,
        crossAlign: "far",
        color: "rgba(114, 114, 114, 1)",
        font: {
          size: "10",
          weight: "bold",
          lineHeight: "14px",
        },
        callback: function (value) {
          return value === 0 ? "" : value;
        },
      },
      border: {
        display: false
      }
    },
  },
});

const chartData = {
  datasets: [
    {
      type: "line",
      fill: true,
      borderWidth: 2,
      borderColor: function (context) {
        const chart = context.chart;
        const { ctx, chartArea } = chart;

        if (!chartArea) {
          return null;
        }

        const { top, bottom } = chartArea;
        const gradient = ctx.createLinearGradient(0, bottom, 0, top);

        gradient.addColorStop(0, "rgba(0, 95, 125, 1)");
        gradient.addColorStop(1, "rgba(0, 199, 177, 1)");

        return gradient;
      },
      backgroundColor: function (context) {
        const chart = context.chart;
        const { ctx, chartArea } = chart;

        if (!chartArea) {
          return null;
        }

        const { top, bottom } = chartArea;
        const gradient = ctx.createLinearGradient(0, bottom, 0, top);

        gradient.addColorStop(0, "rgba(0, 95, 125, 0.15)");
        gradient.addColorStop(1, "rgba(0, 199, 177, 0.15)");

        return gradient;
      },
      tension: 0.4,
      pointRadius: 0,
      pointHoverRadius: 0,
      datalabels: {
        display: false
      }
    },
  ],
};

const chartPlugins = [showCurrentTooltipPlugin, verticalIndicatorPlugin];

function UsageTrackerStatsByMonth({ devices, selectedDevice }) {
  const [dictionary, setDictionary] = useState({});
  const [date, setDate] = useState(moment().toDate());
  const [optinDate, setOptinDate] = useState();
  const [isThisMonth, setIsThisMonth] = useState(true);
  const [isOptinMonth, setIsOptinMonth] = useState(true);
  const [sessionTipIsOpen, setSessionTipIsOpen] = useState(false);
  const [xAxesValues, setXAxesValues] = useState([]);
  const [yAxesValues, setYAxesValues] = useState([]);
  const [puffsData, setPuffsData] = useState({});
  const [loaded, setLoaded] = useState(document.fonts.check("12px Montserrat"));
  const chartRef = useRef(null);

  const prev = () => {
    setDate(moment(date).subtract(1, "month").toDate());
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.DATE_BACK})
  };

  const next = () => {
    setDate(moment(date).add(1, "month").toDate());
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.DATE_NEXT})
  };

  const openSessionTip = () => {
    setSessionTipIsOpen(true);
  };

  const closeSessionTip = () => {
    setSessionTipIsOpen(false)
  };

  useEffect(() => {
    const aem = new AEMHelper();
    const handleLoading = () => setLoaded(true);

    document.body.style.setProperty("overflow-x", "hidden");
    document.fonts.addEventListener("loadingdone", handleLoading);

    setDictionary(
      aem.getDictionary(aemPages.USAGE_TRACKER, {
        UT_PUFFS_LABEL: propertyTextDefaultEmpty,
        UT_CC_PRESET_BREAKDOWN_LABEL: propertyTextDefaultEmpty,
        UT_LOW_LABEL: propertyTextDefaultEmpty,
        UT_MED_LABEL: propertyTextDefaultEmpty,
        UT_HIGH_LABEL: propertyTextDefaultEmpty,
        UT_PRESET_BREAKDOWN_TITLE: propertyTextDefaultEmpty,
        UT_PRESET_BREAKDOWN_CONTENT: propertyTextDefaultEmpty,
      })
    );

    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
      screen_name: AnalyticsScreenEventNames.USAGE_TRACKER_MONTH,
    });

    return () => {
      document.body.style.removeProperty("overflow-x");
      document.fonts.removeEventListener("loadingdone", handleLoading);
      if (window?.monthlyQuery) {
        window.monthlyQuery.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    let usageTrackerOptinDate = getUsageTrackerOptInDate();
    const xValues = [];

    setOptinDate(usageTrackerOptinDate);
    setIsThisMonth(moment(date).isSame(moment(), "month"));
    setIsOptinMonth(moment(date).isSame(usageTrackerOptinDate, "month"));

    for (let i = 0, l = moment(date).daysInMonth(); i < l; i++) {
      const curr = moment(date).date(i + 1);
      xValues.push(curr);
    }

    setXAxesValues(xValues);
    //logAnalyticsEvent('funzia?')
  }, [date]);

  useEffect(() => {
    const device = devices[selectedDevice];

    getMonthlyStats(device?.serialNumber, date, devices, (res) => {
      console.debug("getMonthlyStats_cb_res", res);
      const puffsPerDayOfMonth = res.puffsPerDayOfMonth;
      const yValues = [];

      console.debug("getMonthlyStats_cb_x_values", xAxesValues);

      xAxesValues.forEach((value) => {
        const curr = moment(value).startOf("day");

        if (
          curr.isBefore(moment(optinDate).startOf("day"), "day") ||
          curr.isAfter(moment().startOf("day"), "day")
        ) {
          yValues.push(null);
        } else {
          const dailyData = puffsPerDayOfMonth.find(
            (data) => data.day === curr.date()
          );

          if (dailyData) {
            yValues.push(dailyData.total);
          } else {
            yValues.push(0);
          }
        }
      });

      console.debug("getMonthlyStats_cb_data", yValues);
      setYAxesValues(yValues);
      setPuffsData(res);
    });
  }, [date, devices, selectedDevice, optinDate, xAxesValues]);

  useEffect(() => {
    if (!isThisMonth) {
      if (window?.monthlyQuery) {
        window.monthlyQuery.unsubscribe();
      }
    }
  }, [isThisMonth]);

  return (
    <>
      <UsageTrackerPuffsSlider
        className="monthly"
        title={moment(date).format("MMMM")}
        subtitle={moment(date).format("YYYY")}
        hideNavigationLeft={isOptinMonth}
        hideNavigationRight={isThisMonth}
        onPrev={prev}
        onNext={next}
      >
        <div className="chart">
          {loaded && !isEmpty(xAxesValues) && !isEmpty(yAxesValues) && (
            <Line
              ref={chartRef}
              options={chartOptions(
                dictionary,
                date,
                isThisMonth,
                optinDate,
                yAxesValues
              )}
              data={{
                ...chartData,
                labels: xAxesValues,
                datasets: [
                  {
                    ...chartData.datasets[0],
                    data: yAxesValues,
                  },
                ],
              }}
              plugins={chartPlugins}
            />
          )}
        </div>
      </UsageTrackerPuffsSlider>
      <div className="usage-tracker-section">
        <div className="usage-tracker-section-title d-flex align-items-center justify-content-between">
          {dictionary.UT_CC_PRESET_BREAKDOWN_LABEL ||
            "CLOUD CONTROL PRESET BREAKDOWN"}{" "}
            <span className="bat-icon-info info-icon" onClick={openSessionTip} />
        </div>
        <UsageTrackerTooltip isOpen={sessionTipIsOpen} title={dictionary.UT_PRESET_BREAKDOWN_TITLE} content={dictionary.UT_PRESET_BREAKDOWN_CONTENT} closeToolTip={closeSessionTip} />
        <div className="usage-tracker-section-content">
          <div className="d-flex flex-column gap-2">
            <UsageTrackerPreset
              label={dictionary.UT_LOW_LABEL || "LOW"}
              value={Commons.getPercentage(
                puffsData.totalLowMonth,
                puffsData.totalPuffsMonth
              )}
            />
            <UsageTrackerPreset
              label={dictionary.UT_MED_LABEL || "MED"}
              value={Commons.getPercentage(
                puffsData.totalMedMonth,
                puffsData.totalPuffsMonth
              )}
            />
            <UsageTrackerPreset
              label={dictionary.UT_HIGH_LABEL || "HIGH"}
              value={Commons.getPercentage(
                puffsData.totalHighMonth,
                puffsData.totalPuffsMonth
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  devices: state?.deviceReducer?.devices,
  selectedDevice: state?.dashboardReducer?.usageTracker?.selectedDevice,
});

const component = connect(mapStateToProps)(UsageTrackerStatsByMonth);
export { component as UsageTrackerStatsByMonth };
