import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { ShopUtils } from '../../_helpers/shop';

const FlavourCard = ({ variant, openPdp, dictionary }) => {

    let product = ShopUtils.getProductFromId(variant.product_id);

    return (
        <div className="card" onClick={() => openPdp(product)}>
            <div style={{
                ...(product?.gradient && product.gradient !== "" && { background: product.gradient }),
                borderRadius: '0.5rem 0.5rem 0 0'
            }}>
                <img src={product?.featured_image} className="card-img-top" alt=" " />
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col-12'>
                        <span className="card-title">{product?.title}</span>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-end'>
                    <span className="favourite-nicotine">
                        {dictionary.SHOP_REORDER_NIC_LABEL} {variant.title}
                    </span>
                    <div className='d-flex align-items-center'>
                        <span className='favourite-qty-label'>{dictionary.SHOP_REORDER_QTY_LABEL}</span> <h4 className='mb-0 ms-1'>{variant.favouriteQty}</h4>
                    </div>
                </div>
            </div>
            <div className={`${variant.quantity > 0 ? 'available' : 'out-of-stock'}`} style={{
                borderRadius: '0 0 0.5rem 0.5rem'
            }}>
                <span>
                    {variant.quantity > 0 ? dictionary.SHOP_REORDER_IN_STOCK : dictionary.SHOP_REORDER_UNAVAILABLE_TITLE}
                </span>
            </div>
        </div>
    )
}


export const ShopFavouritesCarousel = (props) => {

    const [elements, setElements] = useState([]);
    const [swiper, setSwiper] = useState(null);

    useEffect(() => {
        setElements([...props.items]);
    }, [props.items]);
    
    useEffect(() => {
        if(elements && swiper) {
            swiper.updateSlides();
        }
    }, [elements]);

    const handleOpenPdp = (flavour) => {
        if(typeof props.onOpenPdp === 'function'){
            props.onOpenPdp(flavour);
        }
    }

    // const log = (...args) => {
    //     console.log(`%c[ShopFavouritesCarousel]`, "color:chartreuse", ...args);
    // }

    return (
        <Swiper
            slidesPerView={'auto'}
            loop={false}
            centeredSlides={true}
            centeredSlidesBounds={true}
            centerInsufficientSlides={false}
            initialSlide={0}
            onTouchStart={(swiper) => {
                swiper.updateSlides();
            }}
            onInit={(swiper) => setSwiper(swiper)}
        >
            {(
                elements !== undefined &&
                elements !== null &&
                elements.length > 0
            ) ? (
                elements.map((item, idx) => {
                    return (
                        <SwiperSlide key={`${idx}`} className="swiper-slide-favourite"  style={{
                            ...( idx === 0 &&  { marginLeft: '0.9375rem' } )
                        }}>
                            <FlavourCard variant={item} openPdp={handleOpenPdp} dictionary={props.dictionary} />
                        </SwiperSlide>
                    )
                })
            ) : null}
            
        </Swiper>
    );

}

