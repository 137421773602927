import cx from "classnames";
import React, { useEffect, useState } from "react";

const POSITION_TRESHOLD = 5;

export function UsageTrackerAvg({ label, value, avg }) {
  const [position, setPosition] = useState(50);

  useEffect(() => {
    if (!avg) {
      return;
    }

    const p = Math.round((50 * value) / avg);

    if (p <= POSITION_TRESHOLD) {
      setPosition(POSITION_TRESHOLD);
    } else if (p >= 100 - POSITION_TRESHOLD) {
      setPosition(100 - POSITION_TRESHOLD);
    } else {
      setPosition(p);
    }
  }, [avg, value]);

  return (
    <div className="usage-tracker-stats-box avg">
      <div className="indicator" />
      <div
        className={cx("indicator current", {
          left: position < 50,
          right: position > 50,
        })}
        data-value={value}
        style={{ left: `${position}%` }}
      />
      <div className="bottom">{`${label} ${avg ?? "--"}`}</div>
    </div>
  );
}
