import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCallbackState from '../../_hooks/callbackState.hook';
import StepWizard from "react-step-wizard";
import { 
    commonsActions, 
    getUserData, 
    getUserEverLogged, 
    getVPAttempts, 
    onboardingActions, 
    setDoneNotificationPreferences, 
    setDoneVault, 
    setThings, 
    setVPAttempts, 
    setVuseUuid, 
    userActions
} from '../../_actions';
import { Header } from '../../_components';
import {
    VaultRestoreSecureQuestion,
    VaultRestorePin,
    VaultRestored
} from '../../_components/Vault';
import { 
    imagesConstants, 
    routingConstants, 
    aemPages, 
    AnalyticsEvents, 
    AnalyticsScreenEventNames, 
    CUSTOMER,
    thingVuseProperties,
    flagStatus,
    VUSE_DEVICE,
    crmUserSettings,
    os
} from '../../_constants'; 
import { Commons } from '../../_helpers/commons';
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';
import "animate.css/animate.min.css";
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyHeadingDefaultEmpty, propertyTextDefaultEmpty, propertyTextDefaultEmptyParsed } from '../../_helpers/aem/aemhelper';
import { logAnalyticsEvent, updateThingsInUserPropertiesAnalyticsEvents } from '../../_helpers/analytics/logAnalytics';
import { getActiveStep, renderAllSteps, triggerLandingOn } from '../../_helpers/multipleStepWiew';
import { store } from '../../_store';
import moment from 'moment';
import { getTruncateAppVersion } from '../../_helpers/utils';
import { configSalesforce } from '../../_helpers/salesforcce';

export const VaultRestore = (props) => {

    const [isPinValid, setIsPinValid] = useCallbackState(null);
    const [isAnswerValid, setIsAnswerValid] = useCallbackState(null);
    const [currentStep, setCurrentStep] = useCallbackState(1);
    const [remainingAttempts, setRemainingAttempts] = useCallbackState(3);

    const [loadingFirstStep, setLoadingFirstStep] = useState(false);
    const [loadingSecondStep, setLoadingSecondStep] = useState(false);
    const [loadingThirdStep, setLoadingThirdStep] = useState(false);

    const userPin = useSelector(state => state.onboardingReducer.userPin);
    const oldUserTenantId = useSelector(state => state.onboardingReducer.oldUserTenantId);
    const loadingCommons = useSelector(state => state.commonsReducer.loading);
    const loadingOnboarding = useSelector(state => state.onboardingReducer.loading);
    const isResetPinFlow = useSelector(state => state.onboardingReducer.isResetPinFlow);

    const dispatch = useDispatch();

    const aem = new AEMHelper();
    const dictionary = {
        ...aem.getDictionary(aemPages.VAULT_FIRST, {
            VAULT_STEP_QUESTION_1: propertyTextDefaultEmpty,
            VAULT_STEP_QUESTION_2: propertyTextDefaultEmpty,
            VAULT_STEP_QUESTION_3: propertyTextDefaultEmpty,
            VAULT_STEP_QUESTION_4: propertyTextDefaultEmpty,
        }),
        ...aem.getDictionary(aemPages.VAULT_AFTER, {
            VAULT_RESTORE_HEADER: propertyHeadingDefaultEmpty,
            VAULT_RESTORE_TITLE: propertyTextDefaultEmptyParsed,
            VAULT_RESTORE_CONTENT: propertyTextDefaultEmptyParsed,
            VAULT_RESTORE_ENTER_PIN: propertyTextDefaultEmptyParsed,
            VAULT_RESTORE_BTN_CONFIRM: propertyCtaItemsDefaultEmpty,
            VAULT_RESTORE_LINK_FORGOT: propertyTextDefaultEmpty,
            VAULT_RESTORE_ATTEMPTS: propertyTextDefaultEmpty,
            VAULT_RESTORE_LINK_NEW_CREATE: propertyTextDefaultEmpty,
            VAULT_RESTORE_SEC_ANSWER_LABEL: propertyTextDefaultEmptyParsed,
            VAULT_RESTORE_PIN_FAILURE: propertyTextDefaultEmptyParsed,
            VAULT_SUCCESS_BTN_CONTINUE: propertyCtaItemsDefaultEmpty,
            VAULT_SUCCESS_TITLE: propertyHeadingDefaultEmpty
        }),
    }

    useEffect(() => {
        triggerLandingOn(vaultRestoreSteps(), getActiveStep());
        document.body.classList.add("vault-restore");
        setRemainingAttempts(getVPAttempts());

        return () => {
            document.body.classList.remove("vault-restore");
            setVPAttempts(remainingAttempts);
        }
    }, []);

    const onStepChange = (stepConf) => {
        setCurrentStep(
            stepConf.activeStep,
            currentStep => { triggerLandingOn(vaultRestoreSteps(), currentStep); }
        );
    }

    /**
     * Handle next step after vault restore.
     * If user already expressed their preferences on notifications them will be redirected to homepage,
     * else will be forwarded to preferences intro
     */
    const goToNextStep = () => {
        setLoadingThirdStep(true);
        setDoneVault(null);

        if(
            oldUserTenantId !== null &&
            oldUserTenantId !== undefined &&
            oldUserTenantId !== ""
        ){
            
            setDoneVault(false);
            history.push(buildURI(routingConstants.VAULT));

        }
        else{
            const tenantUserId = Commons.generateTenantUserId(userPin);

            store.dispatch(
                userActions.setUserSettings(
                    crmUserSettings.LAST_SESSION,
                    (new Date()).toISOString()
                )
            )

            dispatch(commonsActions.getVendors(tenantUserId,
                (response) => {
    
                    if (response?.result?.code === 0) {
                        
                        //set localstorage things
                        setThings(response.data);

                        //load all the devices things
                        const vendorDevice = response.data.find(el => el.vendor === VUSE_DEVICE);
                        if (vendorDevice?.uuids?.length > 0) {
                            (vendorDevice.uuids).forEach((uuid) => {
                                dispatch(commonsActions.getThings( tenantUserId, uuid, VUSE_DEVICE, (response) => {
                                    if (response?.result?.code === 0 && response?.data[0]?.properties) {
                                        updateThingsInUserPropertiesAnalyticsEvents(response.data[0].properties)
                                    }
                                }));
                            });
                        }
    
                        //set customer uuid to localstorage for direct access
                        const vendorVuse = response.data.find(el => el.vendor === CUSTOMER);
                        if (vendorVuse?.uuids?.length > 0) {
                            setVuseUuid(vendorVuse.uuids[0]);
                        }
    
                        //load customer thing
                        dispatch(commonsActions.getThings(
                            tenantUserId,
                            vendorVuse.uuids[0],
                            CUSTOMER,
                            (response) => {
                                if( 
                                    response?.result?.code === 0 && 
                                    response?.data[0]?.properties
                                ){
                                    updateThingsInUserPropertiesAnalyticsEvents(response?.data[0]?.properties)
                                    dispatch(onboardingActions.getAllRules(tenantUserId, getUserData().group_id));

                                    //waiting for the things to update so I'll have them in the dashboard
                                    const goNext = () => {
                                        const notificationsPrefStep = response?.data[0]?.properties.find((pref) => pref.type === thingVuseProperties.MANAGENOTIFICATION );
                                        if(notificationsPrefStep.data === flagStatus.ACCEPTED){
                                            setDoneNotificationPreferences(true);
                                            goToDashboard();
                                        } else {
                                            history.push(buildURI(routingConstants.NOTIFICATION_INTRO));
                                        }
                                    }

                                    dispatch(commonsActions.setThings(
                                        [
                                            { type: thingVuseProperties.MKT_LASTACTIVE, data: moment().valueOf() },
                                            { type: thingVuseProperties.APPVERSION, data: (getTruncateAppVersion()).toString() },
                                            { type: thingVuseProperties.TIMEZONE, data: moment().utcOffset() / 60 },
                                            { type: thingVuseProperties.OS, data: os },
                                        ],
                                        tenantUserId,
                                        vendorVuse.uuids[0],
                                        CUSTOMER,
                                        () => goNext(),
                                        () => goNext()
                                    ))
                                    
                                } else {
                                    goToDashboard();
                                }
                                
                            }, () => {
                                goToDashboard();
                            }
                        ));
    
                    } else {
                        goToDashboard();
                    }
                }, () => {
                    goToDashboard();
                }));
        }
    };

    const goToDashboard = () => {
        configSalesforce()
        history.push(buildURI(routingConstants.DASHBOARD));
    };

    const validatePin = (pin) => {
        setLoadingFirstStep(true);

        dispatch(onboardingActions.setUserPin(pin, () => {
        
            setIsPinValid(
                null,
                isPinValid => {
                    
                    let newRemainingAttempts = remainingAttempts;
                    //Generate tenant-user-id
                    const tenantUserId = Commons.generateTenantUserId(pin);
                    // console.log(`[VAULT RESTORE] tenantUserId: ${tenantUserId}`);

                    //BE perform validation on inserted pin
                    dispatch(onboardingActions.validatePin(tenantUserId.toString(),
                        (response) => {

                            if (response?.result?.code === 0) {
                                setIsPinValid(
                                    true,
                                    isPinValid => {
                                        setRemainingAttempts(
                                            newRemainingAttempts,
                                            remainingAttempts => { setVPAttempts(remainingAttempts); }
                                        ); 
                                    }
                                );
                            } else {
                                dispatch(onboardingActions.setUserPin(null));
                                setIsPinValid(
                                    false,
                                    isPinValid => {
                                        setRemainingAttempts(
                                            newRemainingAttempts - 1,
                                            remainingAttempts => { setVPAttempts(remainingAttempts); }
                                        );
                                    }
                                );
                                
                            }
                            setLoadingFirstStep(false);

                        }, () => {
                            dispatch(onboardingActions.setUserPin(null));
                            setIsPinValid(
                                false,
                                isPinValid => {
                                    setRemainingAttempts(
                                        newRemainingAttempts - 1,
                                        remainingAttempts => {
                                            setLoadingFirstStep(false);
                                            setVPAttempts(remainingAttempts);
                                        }
                                    );
                                }
                            );
                        }
                    ));

                }
            );
        
        }));
    };

    const validateSecurityQuestion = (answer) => {
        setLoadingSecondStep(true);
        setIsAnswerValid(
            null,
            isAnswerValid => {

                const payload = {
                    securityAnswer: `${Commons.sanitizeSecurityAnswer(answer.response)}${answer?.question?.key}` ,
                    customerId: getUserData().id,
                    everLoggedTimestamp: getUserEverLogged()
                }

                dispatch(onboardingActions.validateSecurityQuestion("", payload,
                (response) => {
                    if(response?.result?.code === 0){
                        
                        /**
                         * if answer provided from user is valid, BE also returns old tenantUserId
                         * this id is sent when user inserts new couple pin/answer, to be updated with the new one
                         */
                        if(response?.data?.tenant_user_id){
                            dispatch(onboardingActions.setOldUserTenantId(response.data.tenant_user_id, () => {
                                setIsAnswerValid(true);
                            }));
                        } else {
                            setIsAnswerValid(false);
                        }
                    } else {
                        setIsAnswerValid(false);
                    }
                }, () => {
                    store.dispatch(commonsActions.showErrorAlert(true));
                }));

            }
        );
    };

    const vaultRestoreSteps = () => {

        return [
            {
                onLanding: () => {
                    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
                        screen_name: AnalyticsScreenEventNames.ENTER_PIN,
                    });
                },
                render: () => <VaultRestorePin key="vaultRestoreStep-1" dictionary={dictionary} setIsResetPinFlow={(value) => dispatch(onboardingActions.setIsResetPinFlow(value))} validatePin={validatePin} isPinValid={isPinValid} remainingAttempts={remainingAttempts} isLoading={loadingOnboarding || loadingFirstStep} />
            },
            {
                onLanding: () => {logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
                    screen_name: AnalyticsScreenEventNames.OPEN_VAULT_SECURTY_QUESTION,
                })},
                render: () => <VaultRestoreSecureQuestion key="vaultRestoreStep-2" dictionary={dictionary} setIsCreatingNewPin={(value) => dispatch(onboardingActions.setIsCreatingNewPin(value))} validateSecurityQuestion={validateSecurityQuestion} isValidData={isAnswerValid} isLoading={loadingOnboarding || loadingSecondStep} />
            },
            {
                onLanding: () => {
                    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
                        screen_name: AnalyticsScreenEventNames.OPEN_VAULT_SUCCESS,
                    });
                },
                render: () => <VaultRestored key="vaultRestoreStep-3" dictionary={dictionary} isResetPinFlow={isResetPinFlow} goToNextStep={goToNextStep} isLoading={loadingThirdStep || loadingCommons}/>
            }
        ]

    };

    return (
        <React.Fragment>
            <div className="page w-100">
                <Header subHeader={dictionary.VAULT_RESTORE_HEADER} />
                <StepWizard
                    onStepChange={onStepChange}
                    transitions={{
                        enterRight: `animate__animated animate__fadeIn`,
                        enterLeft: `animate__animated animate__fadeIn`,
                        exitRight: `animate__animated animate__fadeOut`,
                        exitLeft: `animate__animated animate__fadeOut`,
                    }}
                    className="h-100 vault-restored-bg"
                    isLazyMount={true}
                >
                    {renderAllSteps(vaultRestoreSteps())}
                </StepWizard>
            </div>
            <video className="vault-bg-video" src={imagesConstants.SECURED_Final}
                autoPlay muted playsInline preload={"auto"} loop={false}
                style={{ display: currentStep === 3 ? "block" : "none" }}> </video>
        </React.Fragment>
    );

};