import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash/lang';
import { findLastIndex } from 'lodash/array';
import { uniq } from 'lodash/array';
import get from 'lodash/get';
import {
    aemPages,
    AnalyticsEvents,
    AnalyticsScreenEventNames,
    routingConstants,
    vuseWorldAction,
    thingVuseProperties,
    CUSTOMER,
    tipsEvents
} from '../../_constants';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { AEMHelper, propertyImageSrcDefaultNull, propertyTextDefaultEmpty } from '../../_helpers/aem/aemhelper';
import { VuseWorldCarousel } from './VuseWorld/VuseWorldCarousel';
import { commonsActions, deviceActions, getVuseUuid } from '../../_actions';
import { buildURI } from '../../_helpers/navigation';
import { history } from '../../_helpers/history';
import { VuseWorldStories } from './VuseWorld/VuseWorldStories';
import { blogServices } from '../../_services';
import { getAccessToken } from '../../_actions/appData.actions';
import { Commons } from '../../_helpers/commons';
import { parseVuseWorldSource } from '../../_helpers/aem/aemVuseWorld';
import { store } from '../../_store';
import { NetworkConnectionLost } from '../../_components';
import { DashboardContext } from '../Dashboard';

// var mobileConsole = require('js-mobile-console');

export const VuseWorld = () => {

    const dispatch = useDispatch();
    const [carousels, setCarousels] = useState([]);
    const [stories, setStories] = useState([]);
    const [storiesSeen, setStoriesSeen] = useState([]);
    const [dictionary, setDictionary] = useState({});
    const { navbarHeight = 0 } = useContext(DashboardContext) || {};

    useEffect(() => {
        const aem = new AEMHelper();

        setDictionary({
            ...aem.getDictionary(aemPages.HOMEPAGE, {
                HEALTH_WARNING: propertyImageSrcDefaultNull
            }),
            ...aem.getDictionary(aemPages.VUSE_WORLD, {
                VUSEWORLD_LATEST_TITLE: propertyTextDefaultEmpty,
                VUSEWORLD_STORIES_TITLE: propertyTextDefaultEmpty,
                VUSEWORLD_FEATURED_TITLE: propertyTextDefaultEmpty,
                VUSEWORLD_ABOUT_VUSE_TITLE: propertyTextDefaultEmpty,
                VUSEWORLD_PROMOTIONS_TITLE: propertyTextDefaultEmpty,
            }),
        })

    }, [])

    useEffect(() => {

        //analytics
        logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
            screen_name: AnalyticsScreenEventNames.VUSE_WORLD,
        });

        //load ids of already seen stories
        let alreadySeenStories = Commons.getCustomerProperty(thingVuseProperties.VUSE_WORLD_STORIES);
        alreadySeenStories = Commons.parseJSONSafely(alreadySeenStories) ?? [];
        if (isEmpty(alreadySeenStories)) {
            alreadySeenStories = [];
        }
        setStoriesSeen([...alreadySeenStories]);

        //load ALL vuse world contents
        blogServices.getAemVuseWorld().then((response) => {
            if (response) {
                const vuseWorldContent = parseVuseWorldSource(response);
                setCarousels(vuseWorldContent.carousels);
                recalculateStories(vuseWorldContent.stories, alreadySeenStories);
            }
        }, () => {
            //if there is an error on content retrieval, load local file
            if (Commons.isLocalEnvironment()) {
                const src = require('../../_assets/aem/ca/vuse_world.json');
                const json = Object.assign({}, { data: src });
                const vuseWorldContent = parseVuseWorldSource(json);
                setCarousels(vuseWorldContent.carousels);
                recalculateStories(vuseWorldContent.stories, alreadySeenStories);
            }
        });
        // mobileConsole.options({
        //     showOnError: true,
        //     proxyConsole: true,
        //     isCollapsed: true,
        //     catchErrors: true
        // });
        // mobileConsole.show();
    }, [])

    /**
     * First time stories are loaded or every time stories seen status changes,
     * recaulculate stories statuses and ordering of categories and stories.
     *  
     * @param {*} stories 
     * @param {*} alreadySeenStories 
     */
    const recalculateStories = (stories, alreadySeenStories) => {
        setStories([...
            stories.map(story => {

                let lastStorySeenIdx = findLastIndex(story.stories, (thisStory) => alreadySeenStories.includes(thisStory.id));
                let completed = story.stories.length - 1 === lastStorySeenIdx;
                // console.log("story.stories.length", story.stories.length);
                // console.log("lastStorySeenIdx", lastStorySeenIdx);
                story.setCompleted(completed);
                story.setLastIndexSeen(completed ? 0 : lastStorySeenIdx + 1);
                story.setSeen(lastStorySeenIdx !== -1);
                story.setStories(story.stories.sort((a, b) => {
                    
                    /**
                     * Order is:
                     * 1. stories seen
                     * 2. stories not seen ordered by dateFrom asc
                     */
                    let seenStoryA = storiesSeen.includes(a.id);
                    let seenStoryB = storiesSeen.includes(b.id);
                    if (
                        (seenStoryA && seenStoryB) ||
                        (!seenStoryA && !seenStoryB)
                    ) {
                        if (a.dateFrom === b.dateFrom) {
                            return 0;
                        }
                        return a.dateFrom > b.dateFrom;
                    } else if (seenStoryA) {
                        return -1;
                    } else if (seenStoryB) {
                        return 1;
                    }
                    return 0;
                }))

                return story;

            }).sort((a, b) => {
                /**
                 * Order is:
                 * 1. storiesEl seen but no completed
                 * 2. storiesEl not seen (and not completed ofc)
                 * 3. storiesEl completed
                */
                if (a.seen && !a.completed) {
                    // console.log("1", b.completed ? -1 : b.seen ? 0 : 1);
                    return b.completed ? -1 : b.seen ? 0 : -1;
                } else if (!a.seen) {
                    // console.log("2", b.completed ? -1 : b.seen ? 1 : 0);
                    return b.completed ? -1 : b.seen ? 1 : 0;
                } else {
                    // console.log("3", b.completed ? 0 : 1);
                    return b.completed ? 0 : 1;
                }
            })
        ])
    }

    /**
     * Given ids of new seen stories, update corresponding backend property.
     * @param {*} ids 
     */
    const updateSeenStories = (ids) => {

        let alreadySeenStories = Commons.getCustomerProperty(thingVuseProperties.VUSE_WORLD_STORIES);
        alreadySeenStories = Commons.parseJSONSafely(alreadySeenStories) ?? [];
        if (isEmpty(alreadySeenStories)) {
            alreadySeenStories = [];
        }

        console.log("[updateSeenStories] alreadySeenStories", alreadySeenStories);
        console.log("[updateSeenStories] ids", ids);

        const newSeenStories = uniq([...alreadySeenStories, ...ids]);

        const tenantUserId = Commons.generateTenantUserId(store.getState().onboardingReducer.userPin);
        dispatch(
            commonsActions.setThings(
                [
                    {
                        type: thingVuseProperties.VUSE_WORLD_STORIES,
                        data: JSON.stringify(newSeenStories)
                    },
                ],
                tenantUserId,
                getVuseUuid(),
                CUSTOMER,
                () => {
                    setStoriesSeen(uniq([...storiesSeen, ...ids]));
                })
        )
    }

    useEffect(() => {
        console.log("[useEffect] stories", stories);
    }, [stories])
    useEffect(() => {
        console.log("[useEffect] carousels", carousels)
    }, [carousels])


    useEffect(() => {
        console.log("storiesSeen", storiesSeen);
        recalculateStories(stories, storiesSeen);
    }, [storiesSeen])

    const handleStoryElementAction = (el) => {
        console.log("[handleStoryElementAction] el", el);

        const action = el?.action;
        console.log("[handleStoryElementAction] action", action);

        let device = null;
        const devices = store.getState().deviceReducer.devices;
        if (Commons.isValidArray(devices)) {
            device = devices[0];
        }

        const actionsWithDevice = {
            [vuseWorldAction.FIRMWARE_NEW_FW_AVAILABLE]: routingConstants.DEVICE_SETTINGS,
            [vuseWorldAction.GO_TO_DEVICE_SETTINGS]: routingConstants.DEVICE_SETTINGS,
            [vuseWorldAction.SUGGESTIONS_ENABLE_FIND_MY_VAPE]: routingConstants.FIND_MY_VAPE_OPTIN,
            [vuseWorldAction.GO_TO_FIND_MY_VAPE]: routingConstants.FIND_MY_VAPE_OPTIN,
            [vuseWorldAction.SUGGESTIONS_GO_TO_CLOUD_CONTROL]: routingConstants.CLOUD_CONTROL_TUTORIAL,
            [vuseWorldAction.GO_TO_CLOUD_CONTROL]: routingConstants.CLOUD_CONTROL_TUTORIAL,
            [vuseWorldAction.SUGGESTIONS_GO_TO_RECHARGE_REMINDER]: routingConstants.BATTERY_SETTINGS_TUTORIAL,
            [vuseWorldAction.GO_TO_BATTERY_CONTROL]: routingConstants.BATTERY_SETTINGS_TUTORIAL,
        }

        const actionsWithRouting = {
            [vuseWorldAction.GO_TO_MY_ACCOUNT]: routingConstants.MANAGE_ACCOUNT,
            [vuseWorldAction.GO_TO_MANAGE_NOTIFICATIONS]: routingConstants.MANAGE_NOTIFICATIONS,
            [vuseWorldAction.GO_TO_MY_PREFERENCES]: routingConstants.YOUR_PREFERENCES,
            [vuseWorldAction.GO_TO_DEVICE_HELP]: routingConstants.DEVICE_FAQ,
            [vuseWorldAction.GO_TO_CONTACT_US]: routingConstants.CONTACT,
            [vuseWorldAction.GO_TO_STORE_LOCATOR]: routingConstants.STORE_LOCATOR,
        }

        const actionsWithNavigationByEvent = {
            [vuseWorldAction.SUGGESTIONS_GO_TO_FLAVORS]: tipsEvents.GO_TO_FLAVOUR_FOR_YOU,
            [vuseWorldAction.GO_TO_FLAVOUR_FOR_YOU]: tipsEvents.GO_TO_FLAVOUR_FOR_YOU,
            [vuseWorldAction.GO_TO_HOME]: tipsEvents.GO_TO_HOME,
            [vuseWorldAction.GO_TO_BUY_NOW]: tipsEvents.GO_TO_BUY_NOW,
            [vuseWorldAction.GO_TO_USAGE_TRACKER]: tipsEvents.GO_TO_HOME,
            [vuseWorldAction.GO_TO_SUBSCRIPTION]: tipsEvents.GO_TO_SUBSCRIPTION,
            [vuseWorldAction.GO_TO_VUSE_WORLD]: tipsEvents.GO_TO_VUSE_WORLD,
            [vuseWorldAction.ORDER_REMINDER_GO_TO_FAVOURITES]: tipsEvents.GO_TO_FAVOURITES,
            [vuseWorldAction.GO_TO_FAVOURITES]: tipsEvents.GO_TO_FAVOURITES,
            [vuseWorldAction.SUGGESTIONS_GO_TO_WISHLIST]: tipsEvents.GO_TO_FAVOURITES,
        }

        if (
            actionsWithNavigationByEvent[action] !== null &&
            actionsWithNavigationByEvent[action] !== undefined
        ) {
            window.document.dispatchEvent(new CustomEvent(actionsWithNavigationByEvent[action]));
        } else if (
            actionsWithRouting[action] !== null &&
            actionsWithRouting[action] !== undefined
        ) {
            history.push(buildURI(actionsWithRouting[action]));
        } else if (
            actionsWithDevice[action] !== null &&
            actionsWithDevice[action] !== undefined
        ) {
            console.log("[handleStoryElementAction] actionsWithDevice", actionsWithDevice[action]);
            console.log("[handleStoryElementAction] device", device);
            if (device !== null && device !== undefined) {
                //to go to fmv the device is not needed to be connected/syncronized
                if (
                    action === vuseWorldAction.GO_TO_FIND_MY_VAPE ||
                    action === vuseWorldAction.SUGGESTIONS_ENABLE_FIND_MY_VAPE
                ) {
                    store.dispatch(deviceActions.setSelected(device, () => {
                        history.push(buildURI(actionsWithDevice[action]));

                    }));
                } else {
                    Commons.goToPageWithSelectedDevice(device, actionsWithDevice[action]);
                }
            } else {
                window.document.dispatchEvent(new CustomEvent(tipsEvents.GO_TO_HOME));
            }
        } else if (
            action === vuseWorldAction.SUGGESTIONS_GO_TO_PAIR_DEVICE ||
            action === vuseWorldAction.GO_TO_PAIR_DEVICE
        ) {
            if (device === null || device === undefined) {
                history.push(buildURI(routingConstants.PAIR_DEVICE));
            } else {
                window.document.dispatchEvent(new CustomEvent(tipsEvents.GO_TO_HOME));
            }
        } else if (action === vuseWorldAction.GO_TO_FAQ_MYVUSE) {
            Commons.goToSupport();
        } else if (action === vuseWorldAction.GO_TO_WEBVIEW) {
            if (
                el.link !== null &&
                el.link !== undefined &&
                el.link !== ""
            ) {
                const url = `${el.link}${el.webviewRestricted ? `?token=${getAccessToken()}` : ''}`;
                window.open(url, "_blank");
            }
        }
    }

    const handleCarouselElementAction = (el) => {
        console.log("[handleCarouselElementAction] el", el);

        const url = get(el, 'cta.ctaItems[0].cta.url', null);
        if (url !== null) {
            window.open(url, "_blank");
        }
    }

    return (
        <div className='vuseworld-container' style={{ paddingBottom: `${navbarHeight}px` }}>
            {
                stories?.length > 0 &&

                <VuseWorldStories
                    title={dictionary.VUSEWORLD_STORIES_TITLE}
                    carousel={stories}
                    dictionary={dictionary}
                    setStorySeen={updateSeenStories}
                    handleStoryAction={handleStoryElementAction}
                />
            }
            {
                [
                    { key: "FEATURED", label: dictionary.VUSEWORLD_FEATURED_TITLE },
                    { key: "LATEST_FROM_VUSE", label: dictionary.VUSEWORLD_LATEST_TITLE },
                    { key: "PROMOTIONS_FOR_YOU", label: dictionary.VUSEWORLD_PROMOTIONS_TITLE },
                    { key: "ABOUT_VUSE", label: dictionary.VUSEWORLD_ABOUT_VUSE_TITLE },
                ].map(el =>
                    carousels[el.key] &&
                    <VuseWorldCarousel title={el.label} key={`carousel-${el.key}`}
                        carousel={carousels[el.key]}
                        handleElementAction={handleCarouselElementAction}
                        style={{
                            ...(carousels[el.key]?.items?.length <= 1 && { marginBottom: 0 })
                        }} />
                )
            }
            <div className="page-linear-gradient-bg" style={{ zIndex: -1 }}></div>
            {/* <NetworkConnectionLost icon="vuse-brand" /> */}
        </div>
    )
}
