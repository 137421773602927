import { isNil } from "lodash";
import moment from "moment";
import { flagStatus, thingVuseProperties } from "../../_constants";
import { Commons } from "../commons";

export function isUsageTrackerEnabled() {
  return (
    Commons.getCustomerProperty(thingVuseProperties.OPT_IN_USAGE_TRACKER) ===
    flagStatus.ACCEPTED
  );
}

export function isUsageTrackerTutorialComplete() {
  return (
    Commons.getCustomerProperty(thingVuseProperties.USAGE_TRACKER) ===
    flagStatus.ACCEPTED
  );
}

export function isUsageTrackerSessionTipSeen() {
  return (
    Commons.getCustomerProperty(
      thingVuseProperties.USAGE_TRACKER_SESSION_TIP
    ) === flagStatus.ACCEPTED
  );
}

export function getUsageTrackerOptInDate() {
  const usageTrackerOptinDate = Commons.getCustomerPropertyLastUpdate(
    thingVuseProperties.OPT_IN_USAGE_TRACKER
  );

  return moment.utc(usageTrackerOptinDate).local().toString();
}

export const calcAgeRange = (dob) => {
  console.debug("UT_TRACKER_DOB_CALC_AGE_RANGE: ", dob);
  if (isNil(dob)) {
    return "";
  } else {
    const years = moment().diff(moment(dob), "years", false);
    if (years >= 18 && years <= 24) {
      return "18-24";
    } else if (years >= 25 && years <= 34) {
      return "25-34";
    } else if (years >= 35 && years <= 44) {
      return "35-44";
    } else if (years >= 45 && years <= 54) {
      return "45-54";
    } else if (years >= 55 && years <= 64) {
      return "55-64";
    } else if (years >= 65) {
      return "65+";
    } else {
      return "";
    }
  }
};

export * from "./db";
