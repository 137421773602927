import UrlGenerator from "../_helpers/api/urlGenerator";
import { Commons } from "../_helpers/commons";
import { store } from "../_store";
import { getCustomerId } from "../_actions";
import IoTPlatformUtils from "../_helpers/api/IoTPlatformUtils";
import { axiosInstance } from "../_helpers/api/axiosRequest";

function getLoyaltyStatus() {
  const userPin = store.getState().onboardingReducer.userPin;
  const tenantUserId = Commons.generateTenantUserId(userPin);
  const userId = getCustomerId();

  if (!tenantUserId || !userId) {
    throw new Error(`tenantUserId: ${tenantUserId}, userId: ${userId}`);
  }

  return axiosInstance().get(
    UrlGenerator.createIoTPlatformUrlFor(`loyalty/users/${userId}/status`),
    {
      headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
    }
  );
}

function getLoyaltyData() {
  const userPin = store.getState().onboardingReducer.userPin;
  const tenantUserId = Commons.generateTenantUserId(userPin);
  const userId = getCustomerId();

  if (!tenantUserId || !userId) {
    throw new Error(`tenantUserId: ${tenantUserId}, userId: ${userId}`);
  }

  return axiosInstance().get(
    UrlGenerator.createIoTPlatformUrlFor(`loyalty/users/${userId}/data`),
    {
      headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
    }
  );
}

export const loyaltyServices = {
  getLoyaltyStatus,
  getLoyaltyData,
};
