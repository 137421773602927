export function isAnEmptyObject(obj = {}) {
  return Object.keys(obj).length === 0;
}

export function isNotAnEmptyObject(obj = {}) {
  return Object.keys(obj).length > 0;
}

export function extractUniqueTypesAndConcatenate(data) {
  const uniqueTypes = new Set();

  for (const item of data) {
    uniqueTypes.add(item.type);
  }

  return Array.from(uniqueTypes).join(';');
}