import React, { useMemo } from 'react';
import moment from 'moment';
import { Notifications } from '../../_helpers/notifications';
import cx from "classnames";

export const ToasterNotification = ({ notification, showTimeFrame, onClick, showClose = false }) => {

    const handleClick = (event) => {
        if (typeof onClick === 'function') {
            onClick(event);
        }
        Notifications.handleNotificationClick(notification);
    }

    const timeFrame = useMemo(() => {
        if (notification?.generatedAt) {
            let notifDate = moment(new Date(Date.parse(notification.generatedAt)));
            if (notifDate.isValid()) {
                let currentDate = moment();
                if (currentDate.diff(notifDate, 'days') >= 1) {
                    return notifDate.format("DD/MMM/YY");
                } else if (currentDate.diff(notifDate, 'hours') >= 1) {
                    return `${currentDate.diff(notifDate, 'hours')}h ago`;
                } else {
                    return `${currentDate.diff(notifDate, 'minutes')}m ago`;
                }
            }
            return "";
        }
        return "";
    }, [notification])

    return (
        <div className={cx('d-flex', {'is-banner': notification.new})} onClick={handleClick}>
            <div className="d-flex flex-column text-start">
                {showTimeFrame && <span className='notification-timeframe'>{timeFrame}</span>}
                <span className="notification-inner-title">{Notifications.stringReplace(notification, notification.title)}</span>
                <span className="notification-inner-body">{Notifications.stringReplace(notification, notification.body)}</span>
            </div>
            <img className='notification-icon' src={showClose ? notification.icon.close : notification.icon.open} />
        </div>
    )
}