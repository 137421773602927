
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

export const CustomModal = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [classNames, setClassNames] = useState("");
    const [closeTimeout, setCloseTimeout] = useState(2000);

    useEffect(() => {
        if (props.closeTimeout !== undefined) {
            setCloseTimeout(props.closeTimeout)
        }

        return () => {
            document.body.classList.remove('pdp-open');
        };
      }, []); 

      
    
    useEffect(() => {
        setIsOpen(props.isOpen);
        if (props.isOpen) {
            document.body.classList.add('pdp-open');
        } else {
            document.body.classList.remove('pdp-open');
        }
    }, [props.isOpen]);


    useEffect(() => {
        setClassNames(props.className);
    }, [props.className]);

    const onModalOpen = () => {
        document.body.classList.add('pdp-open');
        slideIn();
        setIsOpen(true);
    }

    const onModalClose = () => {
        document.body.classList.remove('pdp-open');
        setIsOpen(false);
        slideOut();
        
        if(typeof props.onClose === 'function') {
            props.onClose();
        }
    }

    /**
     * slideIn and slideOut method are set up as a workaround for issue:
     * https://github.com/reactjs/react-modal/issues/846
     */

    const slideIn = () => {
        setTimeout(() => {
            // Get React Modal's overlay
            const overlay = document.querySelector(`.${props.className}-overlay`);

            // If the modal hasn't rendered correctly and is null, exit the function to prevent errors
            if (!overlay) return;

            // Apply the transition, and slide the modal into view from the left
            setClassNames(`opened ${props.className}`);
        }, 0);
    };

    const slideOut = () => {
        setTimeout(() => {
            // Get React Modal's overlay
            const overlay = document.querySelector(`.${props.className}-overlay`);

            // If the modal hasn't rendered correctly and is null, exit the function to prevent errors
            if (!overlay) return;

            // Apply the transition, and slide the modal into view from the left
            setClassNames(`${props.className}`);
        }, 0);
    };

    return (
        <div>
            <Modal
                closeTimeoutMS={closeTimeout}
                isOpen={isOpen}
                onRequestClose={onModalClose}
                onAfterOpen={onModalOpen}
                className={classNames}
                overlayClassName={`${classNames}-overlay` + (props.isNotification ? ' notification' : '')}
                contentLabel="Modal Content">
                {props.children}
            </Modal>
        </div>
    );
};