import React, { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  getMobilePrefix
} from "../../../_actions/appConfig.actions";
import {
  AnalyticsEvents,
  AnalyticsFieldNames,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  AnalyticsErrorNames,
  aemPages,
} from "../../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../../_helpers/aem/aemhelper";
import { useSignupFormContext } from "./SignupFormContext";
import { SignupFormStep } from "./SignupFormStep";
import { CustomInlineAlert } from "../../CustomInlineAlert";
import { logAnalyticsEvent } from "../../../_helpers/analytics/logAnalytics";

const FIELD_NAME_PHONE = "phoneNumber";
const FIELD_NAME_ID_NUMBER = "idNumber";
const PHONE_RE = /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/;

export function ContactsStepZA(props) {
  const [dictionary, setDictionary] = useState({});
  const [isValid, setIsValid] = useState(false);
  const { formData } =
    useSignupFormContext();
  const { register, setValue, formState, getValues } = useFormContext();
  const { dirtyFields, errors } = formState;
  const mobilePrefix = useMemo(() => getMobilePrefix(), []);
  const [errorIdNumber, setErrorIdNumber] = useState(false);

  const isValidPhoneNumber = (value) => {
    // Remove leading zeros
    const phoneNumber = value.replace(/^0+/, '');

    // Check if the phone number consists only of digits
    if (!/^\d+$/.test(phoneNumber)) {
        return false; // Contains non-digit characters
    }

    // Check if the length is within the specified range
    if (phoneNumber.length > 11 || phoneNumber.length < 9) {
        return false; // Too long or too short
    }

    // If all conditions are met, return true
    return true;
  };

  const isValidIdNumber = (value) => {
    let result = false

    // example: 2969698963018
    // accept only digits, dashes or spaces
    if (!/[^0-9-\s]+/.test(value) && value.length === 13) {
      // The Luhn Algorithm
      var nCheck = 0, nDigit = 0, bEven = false
      value = value.replace(/\D/g, "")

      for (var n = value.length - 1; n >= 0; n--) {
        var cDigit = value.charAt(n),
          nDigit = parseInt(cDigit, 10)

        if (bEven) {
          if ((nDigit *= 2) > 9) nDigit -= 9
        }

        nCheck += nDigit
        bEven = !bEven
      }

      result = (nCheck % 10) == 0
    }

    setErrorIdNumber(!result)

    if(!result) { 
      logAnalyticsEvent(AnalyticsEvents.VALIDATION_ERROR, {field_name: AnalyticsFieldNames.SA_ID, error: AnalyticsErrorNames.WRONG_FORMAT});
    }

    return result
  }

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary({
      ...aem.getDictionary(aemPages.REGISTRATION_DETAIL_ADDR, {
        REGISTRATION_HEADER: propertyHeadingDefaultEmpty,
        REGISTRATION_FIELD_REQUIRED: propertyTextDefaultEmpty,
        REGISTRATION_PHONE_LABEL: propertyTextDefaultEmpty,
        SIGNUP_PRIVACY_POLICY_CONTENT: propertyTextDefaultEmptyParsed,
        REGISTRATION_BTN_NEXT: propertyCtaItemsDefaultEmpty,
        REGISTRATION_SA_ID_NUMBER: propertyTextDefaultEmptyParsed,
        REGISTRATION_ID_NUMBER: propertyTextDefaultEmptyParsed,
        REGISTRATION_ENTER_VALID_ID: propertyTextDefaultEmptyParsed
      }),
      ...aem.getDictionary(aemPages.REGISTRATION_DETAIL_TAC, {
        REGISTRATION_CONTENT_DISCLAIMER: propertyTextDefaultEmpty,
      }),
    });

    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.SIGN_UP_PHONENUMBER_SA_ID});
    
  }, []);

  useEffect(() => {
    const phone = formData[FIELD_NAME_PHONE];
    const idNumber = formData[FIELD_NAME_ID_NUMBER];

    if (phone) {
      setValue(FIELD_NAME_PHONE, removeLeadingZero(phone), { shouldDirty: true });
      formData[FIELD_NAME_PHONE] = removeLeadingZero(phone);
    }

    if (idNumber) {
      setValue(FIELD_NAME_ID_NUMBER, idNumber, { shouldDirty: true });
    }
  }, [formData, setValue]);

  useEffect(() => {
    const phoneValid = dirtyFields[FIELD_NAME_PHONE] && !errors[FIELD_NAME_PHONE] && getValues(FIELD_NAME_PHONE).length > 0

    const idNumberValid = dirtyFields[FIELD_NAME_ID_NUMBER] && !errors[FIELD_NAME_ID_NUMBER] && getValues(FIELD_NAME_ID_NUMBER).length > 0

    setIsValid(
      phoneValid &&
      idNumberValid
    );
  }, [formState, dirtyFields, errors]);

  const removeLeadingZero = (str) => {
    // Check if the first character is '0'
    if (str.charAt(0) === '0') {
        // Remove the first character using substring
        return str.substring(1);
    } else {
        // Return the string unchanged if the first character is not '0'
        return str;
    }
  }

  const handleBlurPhone = () => {
    logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.PHONENUMBER})
  }

  const handleBlurSaIdNumber = () => {
    logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.SA_ID})
  }

  return (
    <SignupFormStep
      title={dictionary.REGISTRATION_HEADER}
      privacyPolicyLabel={dictionary.SIGNUP_PRIVACY_POLICY_CONTENT}
      contentDisclaimer={dictionary.REGISTRATION_CONTENT_DISCLAIMER}
      ctaLabel={dictionary.REGISTRATION_BTN_NEXT_0_ctaLabel}
      isLast={false}
      isValid={isValid}
      {...props}
    >
      <div className="signup-form-wrapper">

        <div className="signup-form-row">
          <span className="form-mandatory">
            {dictionary.REGISTRATION_FIELD_REQUIRED}
          </span>
          <label className="page-label" htmlFor={FIELD_NAME_ID_NUMBER}>
            {dictionary.REGISTRATION_SA_ID_NUMBER}
          </label>
          <div className="form-address-wrapper">
            <input
              name={FIELD_NAME_ID_NUMBER}
              id={FIELD_NAME_ID_NUMBER}
              className="form-control"
              type="number"
              autoComplete="off"
              step="1"
              inputMode="numeric"
              placeholder={dictionary.REGISTRATION_ID_NUMBER}
              onChange={(e) => isValidIdNumber(e.target.value)}
              ref={register({
                required: true,
                validate: isValidIdNumber,
              })}
              onBlur={handleBlurSaIdNumber}
            />
          </div>
        </div>

        <CustomInlineAlert isShown={errorIdNumber} alertType="error">
          {dictionary.REGISTRATION_ENTER_VALID_ID}
        </CustomInlineAlert>

        <div className="signup-form-row">
          <span className="form-mandatory">
            {dictionary.REGISTRATION_FIELD_REQUIRED}
          </span>
          <label className="page-label" htmlFor={FIELD_NAME_PHONE}>
            {dictionary.REGISTRATION_PHONE_LABEL}
          </label>
          <div className="d-flex">
            {mobilePrefix && (
              <input
                className="form-control phone-placeholder"
                autoComplete="off"
                value={mobilePrefix}
                disabled
              />
            )}
            <input
              name={FIELD_NAME_PHONE}
              id={FIELD_NAME_PHONE}
              className="form-control"
              type="number"
              autoComplete="off"
              step="1"
              inputMode="numeric"
              ref={register({
                required: true,
                validate: isValidPhoneNumber,
              })}
              onBlur={handleBlurPhone}
            />
          </div>
        </div>
      </div>

    </SignupFormStep>
  );
}
