import React, { useEffect, useState } from "react";
import { aemPages } from "../../_constants";
import {
  AEMHelper,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import { ContentView } from "../ContentView";

const GPSAccountDeletionContent = () => {
  const [dictionary, setDictionary] = useState({});

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.ACCOUNT_DELETION, {
        ACCOUNT_DELETION_TITLE: propertyHeadingDefaultEmpty,
        ACCOUNT_DELETION_CONTENT: propertyTextDefaultEmptyParsed,
      })
    );
  }, []);

  return (
    <>
      <h1 className="gps-pp-wrapper">{dictionary.ACCOUNT_DELETION_TITLE}</h1>
      <div className="gps-pp-wrapper gps-pp-card gps-pp-content-account-deletion">
        {dictionary.ACCOUNT_DELETION_CONTENT}
      </div>
    </>
  );
};

export { GPSAccountDeletionContent };
