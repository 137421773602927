import _ from 'lodash';
import axios from 'axios';
import { routingConstants } from '../../_constants';
import UrlGenerator from '../../_helpers/api/urlGenerator';
import * as Uri from '../../_constants/api/uri.constants';
import { softResetAppData } from '../../_actions/appData.actions';
import { AxiosCancelTokensHolder } from './axiosCancelTokensHolder';
import { deleteSingleFromQueue } from './axiosQueue';

export const sanitizeUrl = (url) => {
    const a = document.createElement('a');
    a.href = url;

    // Only allow certain protocols
    const allowedProtocols = ['http:', 'https:'];
    if (!allowedProtocols.includes(a.protocol)) {
        return 'about:blank';
    }

    // Remove potentially harmful characters
    a.href = a.href.replace(/javascript:/gi, 'about:blank');
    a.href = a.href.replace(/data:/gi, 'about:blank');
    return a.href;
};

export function responseInterceptor(client = false) {
    // Response interceptors
    const axiosInstance = client ? client : axios;
    
    axiosInstance.interceptors.response.use(response => {
        const requestId = response?.config?.requestId
        if (requestId) {
            AxiosCancelTokensHolder.delete(requestId)
            deleteSingleFromQueue(requestId)
        }
        return response;
    }, err => {
        let response = err.response;

        if (err.code === "ERR_CANCELED") {
            if (err?.config?.signal?.reason === 'forceCancel') {
                const requestId = err.config.requestId
                if (requestId) {
                    AxiosCancelTokensHolder.delete(requestId)
                    deleteSingleFromQueue(requestId)
                }
            }
            return Promise.reject(err)
        }
        
        /* In case of 503, we need to manage the Maintenance page */
        if(
            _.get(response, 'status') === 503 
        ){
            const sanitazedUrl = sanitizeUrl(err?.config?.url);
            window.location = routingConstants.MAINTENANCE_PAGE+"?url="+sanitazedUrl;
            //return Promise.reject(err);
            return;
        }
        
        /* If the 401 is coming from the LOGIN url, it means the user/pwd were wrong */
        if(
            _.get(response, 'status') === 401 &&
            (response.config.url === UrlGenerator.createRequestUrl(Uri.LOGIN))
        ){
            return Promise.reject(err);
        }

        

        if (_.get(err.response, 'status') === 401 &&
            !err.config.__isRetryRequest)
        {
            
            softResetAppData();
            window.location = routingConstants.LOGIN;
            return Promise.reject(err);
            
        }

        if(
            _.get(response, 'status') === 404 //&&
            //(response.config.url === UrlGenerator.createRequestUrl(Uri.LOGIN))
        ){
            const requestId = err?.config?.requestId
            if (requestId) {
                AxiosCancelTokensHolder.delete(requestId)
                deleteSingleFromQueue(requestId)
            }
            return response;
        }
        
        return Promise.reject(err);
    });
    
}