import React, { useState, useEffect, useRef } from 'react';
import { CustomInlineAlert, PageSelectorCTA } from '..';
import { store } from '../../_store';
import { CustomLoadingButton } from '../CustomLoadingButton';
import { Utils } from '../../_helpers/graphic/utils';
import { setVPAttempts } from '../../_actions';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { AnalyticsEvents, AnalyticsFieldNames, AnalyticsTargetEventsNames } from '../../_constants';

export const VaultRestorePin = (props) => {
    const {
        dictionary,
        isLoading
    } = props;


    const [firstDigitPin, setFirstDigitPin] = useState(null);
    const [secondDigitPin, setSecondDigitPin] = useState(null);
    const [thirdDigitPin, setThirdDigitPin] = useState(null);
    const [fourthDigitPin, setFourthDigitPin] = useState(null);

    const [isPinCorrect, setIsPinCorrect] = useState(null);
    const itemsRef = useRef([]);

    const digitRegex = /^[0-9]$/;


    useEffect(() => {

        // console.log("[VAULT RESTORE] props.isPinValid", props.isPinValid);

        if (props.isPinValid !== null) {
            //set isPinCorrect for UI validation
            setIsPinCorrect(props.isPinValid);
        }

        //if pin is validated from BE, go to next step
        if (props.isPinValid) {
            setVPAttempts(3);
            props.goToStep(3);
            logAnalyticsEvent(AnalyticsEvents.VAULT_PIN_SUCCESS)
        }

        if (!props.isPinValid) {
            logAnalyticsEvent(AnalyticsEvents.VAULT_PIN_FAILURE, {attempts_left: props.remainingAttempts})
        }

    }, [props.isPinValid]);

    useEffect(() => {

        if (props.remainingAttempts <= 0) {
            setVPAttempts(props.remainingAttempts);
            props.nextStep();
        }

    }, [props.remainingAttempts]);

    useEffect(() => {
        setIsPinCorrect(null);
    }, [firstDigitPin, secondDigitPin, thirdDigitPin, fourthDigitPin]);

    const handleBlur = () => {
        if(checkIsValidPin()){
            logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.PIN})
        }
    }

    const renderMultiplePicker = (valueGroups, onChangeMethod, idx) => {
        return (
            <div className="col-3">
                <input
                    ref={el => itemsRef.current[idx] = el}
                    className={`form-control vault-pin ${isPinCorrect === false ? "error" : (isPinCorrect && "success")}`}
                    placeholder="--"
                    value={valueGroups}
                    type="number"
                    step="1"
                    pattern="[0-9]{1}"
                    min="0" max="9"
                    digitOnly
                    inputmode="numeric"
                    onKeyDown={(event) => {
                        const isBackspace = event.key === "Backspace";
                        if (digitRegex.test(event?.target?.value) && !isBackspace) {
                            event.preventDefault();
                        }
                    }}
                    onKeyUp={(event) => {
                        const isBackspace = event.key === "Backspace";
                        if(isBackspace){
                            handleFocusOnPrevDigit(idx);
                        } else {
                            handleFocusOnNextDigit(idx);
                        }
                    }}
                    onChange={(e) => {
                        if (e?.target?.value) {
                            let newValue = e.target.value;
                            newValue = newValue.toString().charAt(0);
                            newValue = parseInt(newValue);
                            onChangeMethod(newValue);
                        } else {
                            onChangeMethod(e.target.value);
                        }
                    }}
                    onBlur={handleBlur}
                />
                {/* <div className={`ios-picker ${isPinCorrect === false ? "error" : (isPinCorrect && "success")}`}>
                    <Picker
                        optionGroups={optionGroups}
                        valueGroups={valueGroups}
                        onChange={(label, value) => onChangeMethod({ [label]: value })}
                        itemHeight={digitHeight}
                        height={pickerHeight}
                        highlightHeight={highlightHeight} />
                </div> */}
            </div>
        )
    }

    const handleFocusOnNextDigit = (idx) => {
        if(itemsRef.current && itemsRef.current[idx] && itemsRef.current[idx]?.value){
            for (let i = idx+1; i < itemsRef.current.length; i++) {
                if(itemsRef.current[i]){
                    if(!itemsRef.current[i]?.value){
                        itemsRef.current[i].focus();
                        break;
                    }
                }
            }
        }
    }

    const handleFocusOnPrevDigit = (idx) => {
        if(idx <= 0) {
            return;
        }

        const prevIndex = idx - 1;
        if(itemsRef.current && itemsRef.current[prevIndex]) {
            itemsRef.current[prevIndex].focus();
        }
    }

    const checkIsValidPin = () => {
        return digitRegex.test(firstDigitPin)
            && digitRegex.test(secondDigitPin)
            && digitRegex.test(thirdDigitPin)
            && digitRegex.test(fourthDigitPin);
    }

    const validatePin = () => {
        //check attempts, if there are none left, go to security question, 
        //otherwise ask parent to perform validation
        const newFirstDigit = parseInt(firstDigitPin);
        const newSecondDigit = parseInt(secondDigitPin);
        const newThirdDigit = parseInt(thirdDigitPin);
        const newFourthDigit = parseInt(fourthDigitPin);

        props.validatePin(`${newFirstDigit}${newSecondDigit}${newThirdDigit}${newFourthDigit}`);
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CONFIRM_PIN})
    }

    return (
        <React.Fragment>
            <div className="vault-wrapper mt-4">
                <div className="page-label">{dictionary.VAULT_RESTORE_TITLE}</div>
                <div className="page-content">{dictionary.VAULT_RESTORE_CONTENT}</div>
                <div className="page-label">{dictionary.VAULT_RESTORE_ENTER_PIN}</div>

                <div className="vault-ios-picker">
                    <div className="container container-vault-ios-picker">
                        <div className="row">
                            {
                                [
                                    { digit: firstDigitPin, setMethod: setFirstDigitPin },
                                    { digit: secondDigitPin, setMethod: setSecondDigitPin },
                                    { digit: thirdDigitPin, setMethod: setThirdDigitPin },
                                    { digit: fourthDigitPin, setMethod: setFourthDigitPin },
                                ].map((el, idx) =>
                                    renderMultiplePicker(el.digit, el.setMethod, idx)
                                )

                            }
                        </div>
                    </div>
                </div>

                <CustomInlineAlert isShown={isPinCorrect === false && props.remainingAttempts > 0} alertType="error">
                    {Utils.stringReplacePlaceholders(dictionary.VAULT_RESTORE_ATTEMPTS, [props.remainingAttempts])}
                </CustomInlineAlert>

            </div>
            <PageSelectorCTA ctaUrl={true}>
                <div className="d-grid">
                    <CustomLoadingButton isLoading={isLoading}
                        validationMethod={checkIsValidPin()}
                        buttonLabel={dictionary.VAULT_RESTORE_BTN_CONFIRM_0_ctaLabel}
                        onClickMethod={validatePin} />
                </div>
                <div className="page-selector-cta-url">
                    <span className="url-text" onClick={() => {
                        props.setIsResetPinFlow(true);
                        props.nextStep();
                        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.FORGOT_PIN})
                    }}>{dictionary.VAULT_RESTORE_LINK_FORGOT}</span>
                </div>
            </PageSelectorCTA>
        </React.Fragment>
    );

}