import { getUserData } from "../../_actions";
import { flagStatus, NOTIFICATION_THINGS, thingVuseProperties } from "../../_constants";

export class ThingsHelper {

  static isAPushNotificationThing(thing) {
    return NOTIFICATION_THINGS.indexOf(thing.type) > -1;
  }

  static areAcceptedAnyPushNotificationThings(things = [], currentCrmPreferences = {}) {
    const crmPreferences = "userOptedInForPushNotifications" in currentCrmPreferences
      ? currentCrmPreferences
      : getUserData();

    const communicationNotificationsThing = {
      type: thingVuseProperties.COMMUNICATION_NOTIFICATIONS,
      data: crmPreferences?.userOptedInForPushNotifications ? flagStatus.ACCEPTED : flagStatus.REFUSED
    }

    return [
      ...things,
      communicationNotificationsThing
    ].some(thing => thing.data === flagStatus.ACCEPTED)
  }

  static distinctPush(oldThings, newThing) {
    return [
      ...oldThings.filter(thing => thing.type !== newThing.type),
      newThing
    ]
  }

}