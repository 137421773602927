import React, { forwardRef, useEffect, useState } from "react";
import { getAEMAssetsUrl } from "../../_actions/appConfig.actions";
import { aemPages } from "../../_constants";
import {
  AEMHelper,
  propertyImageSrcDefaultNull,
} from "../../_helpers/aem/aemhelper";
import { Tenants } from "../../_helpers/tenants";

/**
 * Render Health Warning banner
 */

const HealthWarning = forwardRef((_, ref) => {
  const [dictionary, setDictionary] = useState({});

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.HOMEPAGE, {
        HEALTH_WARNING: propertyImageSrcDefaultNull,
      })
    );
  }, []);

  if (!Tenants.isCanadaLight() || !dictionary?.HEALTH_WARNING?.mobile?.src) {
    return null;
  }

  return (
    <div ref={ref} className="page-health-disclaimer w-100">
      <img
        src={`${getAEMAssetsUrl()}${dictionary?.HEALTH_WARNING?.mobile?.src}`}
        alt="Health Warning"
      />
    </div>
  );
});

export default HealthWarning;
