class BasketElementModel {

    basket = 0;
    display_name = "";
    featured_image = null;
    price = "0";
    quantity = 0;
    sku = "";
    title = "";
    variant_id = "";
    variant_shopify_id = "";
    product_id = "";


    constructor(data) {
        this.display_name = data.display_name;
        this.title = data.title;
        this.featured_image = data.featured_image;
        this.price = data.price;
        this.quantity = data.quantity; //availability in ecommerce
        this.basket = data.basket; //quantity in basket
        this.product_id = data.id;
        this.sku = data.sku;
        this.variant_id = data.variant_id;
        this.variant_shopify_id = data.variant_shopify_id;
    }

    toObj = () => {
        return {
            display_name: this.display_name,
            featured_image: this.featured_image,
            title: this.title,
            price: this.price,
            quantity: this.quantity,
            basket: this.basket,
            product_id: this.product_id,
            sku: this.sku,
            variant_id: this.variant_id,
            variant_shopify_id: this.variant_shopify_id
        }
    }
}

export { BasketElementModel };