import React, { useEffect, useState } from "react";
import { aemPages } from "../../_constants";
import {
  AEMHelper,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import { ContentView } from "../ContentView";

const GPSPrivacyPolicyContent = () => {
  const [dictionary, setDictionary] = useState({});

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.PRIVACY_POLICY, {
        PRIVACY_POLICY_HEADER_TITLE: propertyHeadingDefaultEmpty,
        PRIVACY_POLICY_CONTENT: propertyTextDefaultEmptyParsed,
      })
    );
  }, []);

  return (
    <div className="gps-pp-wrapper gps-pp-card gps-pp-content-gps-pp">
      <h1>{dictionary.PRIVACY_POLICY_HEADER_TITLE}</h1>
      {dictionary.PRIVACY_POLICY_CONTENT}
    </div>
  );
};

export { GPSPrivacyPolicyContent };
