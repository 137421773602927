import React from 'react';
import { AnalyticsEvents, AnalyticsTargetEventsNames } from '../../_constants';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { PAIRING_STEP_NAMES } from '../../_helpers/device';

/**
 * Pairing second step
 * @param {*} props 
 * @returns 
 */
export const SearchingFailure = (props) => {
    const {
        dictionary,
        goBack
    } = props;

    return (
        <React.Fragment>
            <div className="failure-support-wrapper">
                <div className="container">
                    <div className="row title-row mt-3">
                        <div className="col-12">{dictionary.PAIRING_NOT_FOUND_TITLE}</div>
                    </div>
                    <div className="row subtitle-row">
                        <div className="col-12">
                            {dictionary.PAIRING_NOT_FOUND_SUBTITLE}
                        </div>
                    </div>
                    <div className="row support-row">
                        <div className="col-12">
                            {dictionary.PAIRING_NOT_FOUND_LIST_FIRST}
                        </div>
                    </div>
                    <div className="row support-row">
                        <div className="col-12">
                            {dictionary.PAIRING_LOCATION_ON_ALERT}
                        </div>
                    </div>
                    <div className="row support-row">
                        <div className="col-12">
                            {dictionary.PAIRING_NOT_FOUND_LIST_SECOND}
                        </div>
                    </div>
                    <div className="row support-row">
                        <div className="col-12">
                            {dictionary.PAIRING_NOT_FOUND_LIST_THIRD}
                        </div>
                    </div>
                    <div className="row support-row">
                        <div className="col-12">
                            {dictionary.PAIRING_NOT_FOUND_LIST_FOURTH}
                        </div>
                    </div>
                    <div className="row support-row">
                        <div className="col-12">
                            {dictionary.PAIRING_NOT_FOUND_LIST_FIFTH}
                        </div>
                    </div>
                    <div className="row support-row">
                        <div className="col-12">
                            {dictionary.PAIRING_NOT_FOUND_LIST_SIXTH}
                        </div>
                    </div>
                    <div className="row support-row">
                        <div className="col-12">
                            {dictionary.PAIRING_NOT_FOUND_LIST_SEVENTH}
                        </div>
                    </div>
                    <div className="row support-row">
                        <div className="col-12">
                            {dictionary.PAIRING_NOT_FOUND_LIST_EIGHTH}
                        </div>
                    </div>
                </div>

            </div>

            <div className="page-bottom-cta pt-4">
                <div className="d-grid gap-2">
                    <button className="btn btn-primary text-uppercase" type="button" onClick={goBack}>{dictionary.PAIRING_NOT_FOUND_BTN_CONTINUE_0_ctaLabel}</button>
                </div>
                <div className="page-bottom-cta-url">
                    {/* <NeedHelpPanel link={dictionary.PAIRING_S4_BTN_NEED_HELP} onLinkClick={() => onHelpLinkClick()} /> */}
                </div>
            </div>

            <div className="page-linear-gradient-bg"></div>
        </React.Fragment>
    );
}