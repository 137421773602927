import React, { useEffect, useState } from "react";
import { PAIRING_STEP_NAMES } from "../../_helpers/device";

export function AsavFailure({ dictionary, goToNamedStep }) {
  const [failureOptions, setFailureOptions] = useState([]);

  useEffect(() => {
    setFailureOptions([
      dictionary.PAIRING_ASAV_NOT_VERIFIED_LIST_FIRST ||
        "Your device is next to your phone",
      dictionary.PAIRING_ASAV_NOT_VERIFIED_LIST_SECOND ||
        "Make sure you've selected the correct product family (eg. ePod 2+, ePod 3+)",
      dictionary.PAIRING_ASAV_NOT_VERIFIED_LIST_THIRD ||
        "You've inserted the pod into your ePod 2+",
      dictionary.PAIRING_ASAV_NOT_VERIFIED_LIST_FOURTH ||
        "The ePod is glowing yellow after pushing the button 5 times",
      dictionary.PAIRING_ASAV_NOT_VERIFIED_LIST_FIFTH ||
        "The ePod 2+ is not paired to another phone",
    ]);
  }, []);

  const goBack = () => {
    goToNamedStep && goToNamedStep(PAIRING_STEP_NAMES.PAIRING);
  };

  return (
    <>
      <div className="failure-support-wrapper">
        <div className="container">
          <div className="row title-row mt-3">
            <div className="col-12">
              {dictionary.PAIRING_ASAV_NOT_VERIFIED_TITLE ||
                "ASAV - Oops, looks like we couldn't verify you"}
            </div>
          </div>
          <div className="row subtitle-row">
            <div className="col-12">
              {dictionary.PAIRING_ASAV_NOT_VERIFIED_SUBTITLE ||
                "Check the following:"}
            </div>
          </div>
          {failureOptions.map((option, index) => (
            <div key={index} className="row support-row">
              <div className="col-12">{option}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="page-bottom-cta pt-4">
        <div className="d-grid gap-2">
          <button
            className="btn btn-primary text-uppercase"
            type="button"
            onClick={goBack}
          >
            TRY AGAIN
          </button>
        </div>
        <div className="page-bottom-cta-url"></div>
      </div>
      <div className="page-linear-gradient-bg"></div>
    </>
  );
}
