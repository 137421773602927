import React, { useContext, useEffect, useState } from 'react';
import { AnalyticsEvents, AnalyticsScreenEventNames, aemPages } from '../../_constants';
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyHeadingDefaultEmpty, propertyTextDefaultEmptyParsed } from '../../_helpers/aem/aemhelper';
import { debug } from '../../_helpers/debug';
import { CustomSlidingPanel } from '../CustomSlidingPanel';
import { ErrorNotificationPermissionPanel } from './ErrorNotificationPermissionPanel';
import { MissingOSNotificationPermissionPanel } from './MissingOSNotificationPermissionPanel';
import { WarningNotificationPermissionPanel } from './WarningNotificationPermissionPanel';
import { DashboardContext } from '../../Views';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';

export const NOTIFICATION_PERMISSION_PANEL_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  MISSING_OS_NOTIFICATION_PERMISSION: 'missing_os_notification_permission',
};

/**
 * 
 * @param {
 *  type - it must to be one of NOTIFICATION_PERMISSION_PANEL_TYPES and it will decide which panel will be shown to user
 *  onClose - callback to close the panel
 *  onContinue - callback called when the user clicked on continue button of the panel
 *  onTryAgain - callback called when the user clicked on try again button of the panel
 * } props 
 * @returns 
 */
export const NotificationPermissionPanel = (props) => {
  const {
    type = '',
    onClose = () => {},
    onContinue = Promise.resolve,
    onTryAgain = () => {},
    overrideBottom = false
  } = props;

  const [isContinueButtonLoading, setIsContinueButtonLoading] = useState(false);
  const [dictionary, setDictionary] = useState({});
  const [navbarHeight, setNavbarHeight] = useState(0)
  const { navbarHeightOriginal = 0 } = useContext(DashboardContext) || {};

  useEffect(() => {
    const aem = new AEMHelper();
    setDictionary(
      aem.getDictionary(aemPages.IOS_MKT_NOTIFICATIONS, {
        IOS_MKT_NOTIF_ERROR_BTN_CONTINUE: propertyCtaItemsDefaultEmpty,
        IOS_MKT_NOTIF_WARNING_CONTENT: propertyTextDefaultEmptyParsed,
        IOS_MKT_NOTIF_ERROR_BODY: propertyTextDefaultEmptyParsed,
        IOS_MKT_NOTIF_WARNING_BODY: propertyTextDefaultEmptyParsed,
        IOS_MKT_NOTIF_WARNING_BTN_CONTINUE: propertyCtaItemsDefaultEmpty,
        IOS_MKT_NOTIF_ERROR_HEADER: propertyHeadingDefaultEmpty,
        IOS_MKT_NOTIF_ERROR_BTN_TRY_AGAIN: propertyCtaItemsDefaultEmpty,
        IOS_MKT_NOTIF_WARNING_HEADER: propertyHeadingDefaultEmpty,
        IOS_MKT_NOTIF_MISSING_PERMISSION_HEADER: propertyHeadingDefaultEmpty,
        IOS_MKT_NOTIF_MISSING_PERMISSION_BODY: propertyTextDefaultEmptyParsed,
        IOS_MKT_NOTIF_MISSING_PERMISSION_LIST_FIRST: propertyTextDefaultEmptyParsed,
        IOS_MKT_NOTIF_MISSING_PERMISSION_LIST_SECOND: propertyTextDefaultEmptyParsed,
        IOS_MKT_NOTIF_MISSING_PERMISSION_LIST_THIRD: propertyTextDefaultEmptyParsed,
        IOS_MKT_NOTIF_MISSING_PERMISSION_LIST_FOURTH: propertyTextDefaultEmptyParsed,
        IOS_MKT_NOTIF_MISSING_PERMISSION_BTN_CONTINUE: propertyCtaItemsDefaultEmpty,
      })
    );

    if (overrideBottom !== false) {
      setNavbarHeight(overrideBottom)
    } else if (DashboardContext) {
      setNavbarHeight(navbarHeightOriginal)
    }
    
  }, []);

  let isOpen = false;
  let Panel = () => null;
  let title = '';
  switch (type) {
    case NOTIFICATION_PERMISSION_PANEL_TYPES.ERROR:
      Panel = ErrorNotificationPermissionPanel;
      title = dictionary.IOS_MKT_NOTIF_ERROR_HEADER;
      isOpen = true;
      break;
    case NOTIFICATION_PERMISSION_PANEL_TYPES.WARNING:
      Panel = WarningNotificationPermissionPanel;
      title = dictionary.IOS_MKT_NOTIF_WARNING_HEADER;
      isOpen = true;
      break;
    case NOTIFICATION_PERMISSION_PANEL_TYPES.MISSING_OS_NOTIFICATION_PERMISSION:
      Panel = MissingOSNotificationPermissionPanel;
      title = dictionary.IOS_MKT_NOTIF_MISSING_PERMISSION_HEADER;
      isOpen = true;
      break;
    default:
      break;
  }

  const logToggleAnalytics = (fieldName, value) => {
    logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {
      field_name: fieldName,
      field_value: value ? "true" : "false"
    });
  };

  return (
    <CustomSlidingPanel
      from="bottom"
      isOpen={isOpen}
      className="full-height"
      additionalStyles={{height: `calc(100vh - ${navbarHeight}px)`}}
      title={title}
      overlayClassName="priority-high sliding-common-wrapper"
      backdropClose={false}
    >
      <div className="notification-permission-panel-wrapper">
        <Panel
          dictionary={dictionary}
          onClose={() => onClose()}
          onContinue={() => {
            debug("start onContinue");
            setIsContinueButtonLoading(true);
            return onContinue().finally(() => {
              setIsContinueButtonLoading(false);
              debug("end onContinue");
              onClose();
            });
          }}
          onTryAgain={() => {
            onClose();
            return onTryAgain();
          }}
          isContinueButtonLoading={isContinueButtonLoading}
        />
      </div>
      <div className="page-linear-gradient-bg"></div>
    </CustomSlidingPanel>
  );
};
