import UrlGenerator from "../_helpers/api/urlGenerator";
import * as Uri from "../_constants/api/uri.constants";
import IoTPlatformUtils from "../_helpers/api/IoTPlatformUtils";
import { getTruncateAppVersion } from "../_helpers/utils";
import { debug } from "../_helpers/debug";
import { store } from "../_store";
import { dashboardActions } from "../_actions";
import moment from "moment";
import { getTenantId } from "../_actions/appConfig.actions";
import { axiosEnqueuedRequest, axiosRequest } from "../_helpers/api/axiosRequest";
import { thingVuseProperties } from "../_constants";

export const commonsServices = {
  getToken,
  setDeviceLogError,
  setThing,
  getThings,
  //getThingProperties, UNUSED
  getGroups,
  getGroupDetails,
  getCountries,
  getCountryConfig,
  addThing,
  deleteThing,
  checkAppVersion,
  setDevices,
  sendCalculatedData,
  getCalculatedData,
  deleteCalculatedData,
};

/* UK on.ly */
async function getToken(scope) {
  let requestOptions = {
    url:
      UrlGenerator.createVypeUrlFor(Uri.token) +
      `?grant_type=client_credentials&scope=${scope}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
      Authorization: `Basic ${process.env.REACT_APP_VYPE_API_TOKEN_SECRET}`,
    },
    cache: "no-cache",
    data: {},
  };

  return axiosRequest(requestOptions);
}

/**
 * Set property value of a specific thing.
 *
 * @param {*} tenantUserId
 * @param {*} uuid
 * @param {*} vendor
 * @param {*} propertyType
 * @param {*} propertyData
 * @returns
 */
async function setThing(
  tenantUserId,
  uuid,
  vendor,
  propertyType,
  propertyData
) {
  let requestOptions = {
    url: UrlGenerator.createIoTPlatformUrlFor(Uri.commands),
    method: "POST",
    headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
    cache: "no-cache",
    data: {
      uuid: uuid,
      property_type: propertyType,
      property_data: propertyData,
      vendor: vendor,
    },
  };

  if (
    propertyType === thingVuseProperties.OPT_IN_FINDMYVAPE ||
    propertyType === thingVuseProperties.OPT_IN_USAGE_TRACKER
  ) {
    return axiosRequest(requestOptions)
  } else {
    return axiosEnqueuedRequest(requestOptions)
  }
}

/**
 * Get all propetries associated to some thing (user or device or...)
 * @param {*} tenantUserId
 * @param {*} uuid
 * @param {*} vendor
 * @returns
 */
async function getThings(tenantUserId, uuid, vendor) {
  let requestOptions = {
    url:
      UrlGenerator.createIoTPlatformUrlFor(Uri.things) +
      `?uuids=${uuid}&vendor=${vendor}`,
    method: "GET",
    headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
    cache: "no-cache",
  };
  return axiosRequest(requestOptions);
}

/**
 * Get only some thing properties, passed in params.
 *
 * @param {String} token
 * @param {String} userId
 * @param {String} uuid
 * @param {String} vendor
 * @param {Array<String>} properties properties to read
 * @returns
 */
// async function getThingProperties(token, userId, uuid, vendor, properties) {

//     const newProps = properties.map((prp) => "&properties=" + prp).join('');

//     let requestOptions = {
//         url: UrlGenerator.createIoTPlatformUrlFor(Uri.thingProperties) + `?uuids=${uuid}&vendor=${vendor}${newProps}`,
//         method: 'GET',
//         headers: {
//             'Authorization': token,
//             'X-name': userId,
//             'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
//             "Content-Type": "application/json",
//             "X-user-os": "IOS"
//         },
//         cache: 'no-cache'
//     };

//     return axiosRequest(requestOptions);
// }

async function addThing(tenantUserId, body) {
  let requestOptions = {
    url: UrlGenerator.createIoTPlatformUrlFor(Uri.things),
    method: "POST",
    headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
    cache: "no-cache",
    data: body,
  };
  return axiosRequest(requestOptions);
}

async function getGroups(tenantUserId) {
  let requestOptions = {
    url: UrlGenerator.createIoTPlatformUrlFor(Uri.groups),
    method: "GET",
    headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
    cache: "no-cache",
  };

  return axiosRequest(requestOptions);
}

async function getGroupDetails(tenantUserId, groupId) {
  let requestOptions = {
    url: UrlGenerator.createIoTPlatformUrlFor(Uri.groups) + "/" + groupId,
    method: "GET",
    headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
    cache: "no-cache",
  };

  return axiosRequest(requestOptions);
}

async function getCountries() {
  let requestOptions = {
    url: UrlGenerator.createIoTPlatformUrlFor(Uri.tenantsConfig),
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-user-os": "IOS",
    },
    cache: "no-cache",
  };

  return axiosRequest(requestOptions);
}

async function getCountryConfig(tenantId) {
  let requestOptions = {
    url: `${UrlGenerator.createIoTPlatformUrlFor(
      Uri.tenantsConfig
    )}/${tenantId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-user-os": "IOS",
    },
    cache: "no-cache",
  };

  return axiosRequest(requestOptions);
}

async function deleteThing(tenantUserId, uuid) {
  let requestOptions = {
    url: `${UrlGenerator.createIoTPlatformUrlFor(Uri.things)}/${uuid}`,
    method: "DELETE",
    headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
    cache: "no-cache",
  };
  return axiosEnqueuedRequest(requestOptions);
}

/**
 * Send BE error logs generated from device.
 *
 * @param {*} data error logs
 * @returns
 */
async function setDeviceLogError(tenantUserId, data) {
  let requestOptions = {
    url: `${UrlGenerator.createIoTPlatformUrlFor(Uri.deviceErrors)}`,
    method: "POST",
    headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
    cache: "no-cache",
    data,
  };

  return axiosRequest(requestOptions);
}

/**
 * Send BE error logs generated from device.
 *
 * @param {*} data error logs
 * @returns
 */
async function checkAppVersion() {
    try {
        const result = await axiosRequest({
            url: `${UrlGenerator.createIoTPlatformUrlFor(Uri.appVersion)}IOS/${getTruncateAppVersion()}`,
            method: 'GET',
            credentials: 'include',
            cache: 'no-cache',
            headers: {
                "X-tenant-id": getTenantId() || null
            }
        });
        return [result.data, null];
    } catch (error) {
        return [null, error];
    }
}

async function setDevices(payload, tenantUserId) {
  return axiosRequest({
    url: `${UrlGenerator.createIoTPlatformUrlFor(Uri.devices)}`,
    method: "POST",
    credentials: "include",
    cache: "no-cache",
    headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
    data: JSON.stringify(payload),
  });
}

async function sendCalculatedData(fileName, payload, tenantUserId) {
  const headers = IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId);

  try {
    const result = await axiosRequest({
      url: `${UrlGenerator.createIoTPlatformUrlFor(
        Uri.pushCalcData
      )}/${fileName}`,
      method: "PUT",
      headers: {
        ...headers,
        "Content-Type": "application/zip",
      },
      data: payload,
    });

    store.dispatch(
      dashboardActions.setUsageTrackerCalcDataPublishTs(`${moment().valueOf()}`)
    );

    console.log("[sendCalculatedData] result.data:", result?.data);
    return [result.data, null];
  } catch (error) {
    return [null, error];
  }
}

async function getCalculatedData(filename, tenantUserId) {
  const headers = IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId);

  try {
    const result = await axiosRequest({
      url: `${UrlGenerator.createIoTPlatformUrlFor(
        Uri.getCalcData
      )}/${filename}`,
      method: "GET",
      headers: {
        ...headers,
        Accept: "application/zip",
      },
      responseType: "arraybuffer",
      responseEncoding: null,
    });

    return [result.data, null];
  } catch (error) {
    debug(error);
    return [null, error];
  }
}

async function deleteCalculatedData(filename, tenantUserId) {
  const headers = IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId);

  try {
    const result = await axiosRequest({
      url: `${UrlGenerator.createIoTPlatformUrlFor(
        Uri.getCalcData
      )}/${filename}`,
      method: "DELETE",
      headers: {
        ...headers,
      },
      responseType: "arraybuffer",
      responseEncoding: null,
    });

    return [result.data, null];
  } catch (error) {
    debug(error);
    return [null, error];
  }
}
