const getPath = (path) => (!path.startsWith("/") ? `/${path}` : path);

const UrlGenerator = {
  createIoTPlatformUrlFor(URI) {
    if (!URI.startsWith("/")) {
      URI = `/${URI}`;
    }
    return process.env.REACT_APP_IOT_PLATFORM_BASEURL + URI;
  },

  createVypeUrlFor(URI) {
    if (!URI.startsWith("/")) {
      URI = `/${URI}`;
    }
    return process.env.REACT_APP_VYPE_API_BASEURL + URI;
  },

  createCAVypeUrlFor(URI) {
    if (!URI.startsWith("/")) {
      URI = `/${URI}`;
    }
    return process.env.REACT_APP_CANADA_VYPE_API_BASEURL + URI;
  },

  createRequestUrl(path) {
    return (
      process.env.REACT_APP_IOT_PLATFORM_BASEURL + "/endmarket" + getPath(path)
    );
  },
};

export default UrlGenerator;
