import React, { createContext } from "react";
import { useContext } from "react";

export const SignupFormContext = createContext(null);

export function SignupFormProvider({ value, children }) {
  return (
    <SignupFormContext.Provider value={value}>
      {children}
    </SignupFormContext.Provider>
  );
}

export function useSignupFormContext() {
  return useContext(SignupFormContext);
}
