import {
  getLegalAge,
  getLegalAgeThreshold,
} from "../../_actions/appConfig.actions";
import { ST_DEV_THRESHOLD } from "../../_constants";

export function isSuccess(data) {
  return (
    data?.antispoofing?.prediction === "real" &&
    data?.age?.age >= parseInt(getLegalAgeThreshold()) &&
    data?.age?.st_dev <= ST_DEV_THRESHOLD
  );
}

export function isUnsuccess(data) {
  return data?.age?.age < parseInt(getLegalAge());
}

export function isTooClose(data) {
  return (
    (data?.antispoofing?.prediction === "real" &&
      data?.age?.age >= parseInt(getLegalAgeThreshold()) &&
      data?.age?.st_dev > ST_DEV_THRESHOLD) ||
    (data?.antispoofing?.prediction === "real" &&
      data?.age?.age >= parseInt(getLegalAge()) &&
      data?.age?.age < parseInt(getLegalAgeThreshold()))
  );
}

export function isFake(data) {
  return (data?.antispoofing?.prediction == 'fake' || data?.antispoofing?.prediction == 'undetermined');
}

export function isError(data) {
  return !!data?.error_code;
}
