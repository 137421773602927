import React, { useState, useEffect } from "react";
import { routingConstants, aemPages, AnalyticsEvents, servicesConstants, AnalyticsTargetEventsNames } from '../../_constants';
import { setAgeVerified } from '../../_actions/appData.actions';
import { buildURI, disableFutureVisit } from '../../_helpers/navigation';
import { history } from '../../_helpers/history';
import { Header, PageSelectorCTA } from '../../_components';
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyHeadingDefaultEmpty, propertyImageSrcDefaultNull, propertyTextDefaultEmpty, propertyTextDefaultEmptyParsed } from '../../_helpers/aem/aemhelper';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { getLegalAge, getSupportedServices, isCurrentCountryDemo } from '../../_actions/appConfig.actions';
import { Utils } from '../../_helpers/graphic/utils';
import { CustomSlidingPanel } from "../../_components/CustomSlidingPanel";
import { CustomLoadingButton } from "../../_components/CustomLoadingButton";

export const SoftAgeVerification = (props) => {

    const [isDemoDisclaimerOpen, setIsDemoDisclaimerOpen] = useState(true);
    const [isLoadingRightAge, setIsLoadingRightAge] = useState(false);
    const [isLoadingWrongAge, setIsLoadingWrongAge] = useState(false);

    const aem = new AEMHelper();
    const dictionary = aem.getDictionary(aemPages.SOFT_AGE_VERIFICATION, {
        AV_HEADER_TITLE: propertyHeadingDefaultEmpty,
        AV_DISCLAIMER: propertyTextDefaultEmpty,
        AV_BTN_AGE_OK: propertyCtaItemsDefaultEmpty,
        AV_BTN_AGE_KO: propertyCtaItemsDefaultEmpty,

        AV_DEMO_HEADER: propertyHeadingDefaultEmpty,
        AV_DEMO_CONTENT: propertyTextDefaultEmpty,
        AV_DEMO_CONFIDENTIAL_NOTICE: propertyTextDefaultEmpty,
        AV_DEMO_BTN_CONTINUE: propertyCtaItemsDefaultEmpty
    });

    let legalAge = getLegalAge();

    useEffect(() => {

        document.body.classList.add("soft-age-verification");

        //double check if feature is enabled for current tenant
        if (!getSupportedServices().includes(servicesConstants.ONBOARDING_SOFT_AGE_VERIFICATION)) {
            history.push(buildURI(routingConstants.BENEFITS));
        }

        disableFutureVisit();

        return () => {
            document.body.classList.remove("soft-age-verification");
        }
    }, []);

    /**
     * Handle press event for 'im right age' case
     * store serialized component properties object for later use
     */
    const onImRightAge = () => {
        setIsLoadingRightAge(true);
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NEXT})
        setAgeVerified(true);
        history.push(buildURI(routingConstants.BENEFITS));
    }

    /**
     * Handle press event for 'im NOT right age' case
     * overwrite previous properties set, if any
     */
    const onImWrongAge = () => {
        setIsLoadingWrongAge(true);
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.DECLINE_LEGAL_AGE});
        history.push(buildURI(routingConstants.UNDERAGE));
    }

    return (

        <div className="page w-100">
            <Header subHeader={dictionary.AV_HEADER_TITLE} />
            <div className="soft-age-verification-wrapper">
                <div className="soft-age-verification-age">
                    <div className="soft-age-verification-age-value">
                        <span>{legalAge}</span>
                    </div>
                </div>
                <div className="soft-age-verification-disclaimer">
                    {/*To use the MyVuse app <strong>you must be aged 19 years or over</strong>. Please verify your age before entering the app.*/}
                    {Utils.stringReplacePlaceholders(dictionary.AV_DISCLAIMER, [legalAge], true)}
                </div>
            </div>
            <PageSelectorCTA>
                <div className="d-grid gap-2">
                    <CustomLoadingButton
                        isLoading = {isLoadingRightAge}
                        validationMethod = {true}
                        buttonLabel = {Utils.stringReplacePlaceholders(dictionary.AV_BTN_AGE_OK_0_ctaLabel, [legalAge])}
                        onClickMethod = {onImRightAge}
                        buttonClassName = "btn btn-primary text-uppercase"
                    />
                    <CustomLoadingButton
                        isLoading = {isLoadingWrongAge}
                        validationMethod = {true}
                        buttonLabel = {Utils.stringReplacePlaceholders(dictionary.AV_BTN_AGE_KO_0_ctaLabel, [legalAge])}
                        onClickMethod = {onImWrongAge}
                        buttonClassName = "btn btn btn-outline-secondary text-uppercase"
                    />
                    {/*<button className="btn btn-primary text-uppercase" onClick={onImRightAge}>{Utils.stringReplacePlaceholders(dictionary.AV_BTN_AGE_OK_0_ctaLabel, [legalAge])}</button>*/}
                    {/*<button className="btn btn btn-outline-secondary text-uppercase" onClick={onImWrongAge}>{Utils.stringReplacePlaceholders(dictionary.AV_BTN_AGE_KO_0_ctaLabel, [legalAge])}</button>*/}
                </div>
            </PageSelectorCTA>
            <div className="page-linear-gradient-bg"></div>
            <CustomSlidingPanel
                from="bottom"
                isOpen={isCurrentCountryDemo() && isDemoDisclaimerOpen}
                overlayClassName="sliding-common-wrapper priority"
                className="onboarding-panel mid-height-panel disclaimer-panel"
                title={dictionary.AV_DEMO_HEADER}
                onClose={() => setIsDemoDisclaimerOpen(false)}
                hideBottom={false}
                bottomLabel={dictionary.AV_DEMO_BTN_CONTINUE_0_ctaLabel}
            >
                <div>
                    <div>
                        {dictionary.AV_DEMO_CONTENT}
                    </div>
                    <div style={{"marginTop": "1rem"}}>
                        {dictionary.AV_DEMO_CONFIDENTIAL_NOTICE}
                    </div>
                </div>
            </CustomSlidingPanel>
        </div>

    );

}