import cx from "classnames";
import { isEmpty, isNil } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  aemPages,
} from "../../_constants";
import {
  AEMHelper,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { Commons } from "../../_helpers/commons";
import {
  getUsageTrackerOptInDate,
  getWeeklyStats,
} from "../../_helpers/usage-tracker";
import { UsageTrackerPreset } from "./UsageTrackerPreset";
import { UsageTrackerPuffsSlider } from "./UsageTrackerPuffsSlider";
import AnimateHeight from "react-animate-height";
import { UsageTrackerTooltip } from "../UsageTrackerTooltip/UsageTrackerTooltip";

function UsageTrackerStatsByWeek({ selectedDevice, devices, setDailyDate }) {
  const [dictionary, setDictionary] = useState({});
  const [date, setDate] = useState(moment().toDate());
  const [optinDate, setOptinDate] = useState();
  const [weekdays, setWeekdays] = useState([]);
  const [isThisWeek, setIsThisWeek] = useState(true);
  const [isOptinWeek, setIsOptinWeek] = useState(true);
  const [sessionTipIsOpen, setSessionTipIsOpen] = useState(false);
  const [puffsData, setPuffsData] = useState({});
  const [maxPuffsForWeek, setMaxPuffsForWeek] = useState(0);

  const prev = () => {
    setDate(moment(date).subtract(1, "week").toDate());
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.DATE_BACK})
  };

  const next = () => {
    setDate(moment(date).add(1, "week").toDate());
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.DATE_NEXT})
  };

  const openSessionTip = () => {
    setSessionTipIsOpen(true);
  };

  const closeSessionTip = () => {
    setSessionTipIsOpen(false)
  };

  const getDailyStatsFor = (dayOfWeek) => {
    console.debug("getDailyStatsFor", dayOfWeek, puffsData?.puffsPerDayOfWeek);
    if (!puffsData?.puffsPerDayOfWeek) {
      return null;
    }

    return puffsData?.puffsPerDayOfWeek.find(
      (data) => data?.dayOfWeek === dayOfWeek
    );
  };

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.USAGE_TRACKER, {
        UT_THIS_WEEK_LABEL: propertyTextDefaultEmpty,
        UT_CC_PRESET_BREAKDOWN_LABEL: propertyTextDefaultEmpty,
        UT_PUFFS_LABEL: propertyTextDefaultEmpty,
        UT_LOW_LABEL: propertyTextDefaultEmpty,
        UT_MED_LABEL: propertyTextDefaultEmpty,
        UT_HIGH_LABEL: propertyTextDefaultEmpty,
        UT_PRESET_BREAKDOWN_TITLE: propertyTextDefaultEmpty,
        UT_PRESET_BREAKDOWN_CONTENT: propertyTextDefaultEmpty,
      })
    );

    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
      screen_name: AnalyticsScreenEventNames.USAGE_TRACKER_WEEK,
    });

    return () => {
      if (window?.weeklyQuery) {
        window.weeklyQuery.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const usageTrackerOptinDate = getUsageTrackerOptInDate();

    setOptinDate(usageTrackerOptinDate);
    setIsThisWeek(moment(date).isSame(new Date(), "week"));
    setIsOptinWeek(moment(date).isSame(usageTrackerOptinDate, "week"));

    const weekStartDate = moment(date).startOf("week");
    const days = [];

    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStartDate).add(i, "days").toDate());
    }

    setWeekdays(days);
  }, [date]);

  useEffect(() => {
    if (!isThisWeek) {
      if (window?.weeklyQuery) {
        window.weeklyQuery.unsubscribe();
      }
    }
  }, [isThisWeek]);

  useEffect(() => {
    const device = devices[selectedDevice];

    getWeeklyStats(device?.serialNumber, date, devices, (data) => {
      console.debug("WEEKLY_STATS", data);
      setPuffsData(data);
    });
  }, [date, devices, selectedDevice]);

  useEffect(() => {
    if (!puffsData?.puffsPerDayOfWeek) {
      return;
    }

    const totals = puffsData?.puffsPerDayOfWeek.map((puffs) => puffs.total);

    if (isEmpty(totals)) {
      setMaxPuffsForWeek(0);
    } else {
      console.debug("WEEKLY_STATS_MAX_VALUE", totals, Math.max(...totals));
      setMaxPuffsForWeek(Math.max(...totals));
    }
  }, [puffsData]);

  return (
    <>
      <UsageTrackerPuffsSlider
        title={
          isThisWeek
            ? dictionary.UT_THIS_WEEK_LABEL || "THIS WEEK"
            : moment(date).startOf("week").format("DD MMM") +
              "-" +
              moment(date).endOf("week").format("DD MMM")
        }
        subtitle={
          isThisWeek
            ? moment(date).startOf("week").format("DD MMM") +
              "-" +
              moment(date).endOf("week").format("DD MMM") +
              " " +
              moment(date).format("YYYY")
            : moment(date).format("YYYY")
        }
        hideNavigationLeft={isOptinWeek}
        hideNavigationRight={isThisWeek}
        onPrev={prev}
        onNext={next}
      >
        <ul className="usage-tracker-weekdays">
          <li>{dictionary.UT_PUFFS_LABEL || "PUFFS"}</li>
          {weekdays.map((date, index) => {
            const data = getDailyStatsFor(moment(date).weekday());
            const puffs = data?.total;
            const noData =
              moment(date).isBefore(moment(optinDate), "day") ||
              moment(date).isAfter(moment(), "day");
            let barWidth = 0;

            if (maxPuffsForWeek) {
              barWidth = (puffs * 100) / maxPuffsForWeek;
            }

            return (
              <li
                key={index}
                className={cx({ "no-data": !!noData })}
                onClick={() => {logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.DAY_OF_WEEK}); setDailyDate(date)}}
              >
                <div className="weekday">{moment(date).format("ddd")}</div>
                <div className="bar">
                  <span
                    {...(barWidth && {
                      style: { width: `${barWidth}%` },
                    })}
                  />
                </div>
                <div className="puffs">
                  {noData ? "--" : isNil(puffs) ? 0 : puffs}
                </div>
              </li>
            );
          })}
        </ul>
      </UsageTrackerPuffsSlider>
      <div className="usage-tracker-section">
        <div className="usage-tracker-section-title d-flex align-items-center justify-content-between">
          {dictionary.UT_CC_PRESET_BREAKDOWN_LABEL ||
            "CLOUD CONTROL PRESET BREAKDOWN"}{" "}
            <span className="bat-icon-info info-icon" onClick={openSessionTip} />
        </div>
        <UsageTrackerTooltip isOpen={sessionTipIsOpen} title={dictionary.UT_PRESET_BREAKDOWN_TITLE} content={dictionary.UT_PRESET_BREAKDOWN_CONTENT} closeToolTip={closeSessionTip} />
        <div className="usage-tracker-section-content">
          <div className="d-flex flex-column gap-2">
            <UsageTrackerPreset
              label={dictionary.UT_LOW_LABEL || "LOW"}
              value={Commons.getPercentage(
                puffsData.totalLowWeek,
                puffsData.totalPuffsWeek
              )}
            />
            <UsageTrackerPreset
              label={dictionary.UT_MED_LABEL || "MED"}
              value={Commons.getPercentage(
                puffsData.totalMedWeek,
                puffsData.totalPuffsWeek
              )}
            />
            <UsageTrackerPreset
              label={dictionary.UT_HIGH_LABEL || "HIGH"}
              value={Commons.getPercentage(
                puffsData.totalHighWeek,
                puffsData.totalPuffsWeek
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  devices: state?.deviceReducer?.devices,
  selectedDevice: state?.dashboardReducer?.usageTracker?.selectedDevice,
});

const component = connect(mapStateToProps)(UsageTrackerStatsByWeek);
export { component as UsageTrackerStatsByWeek };
