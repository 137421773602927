import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { dashboardActions } from "../../_actions";
import { FeatureTutorial } from "../../_components";
import {
  AnalyticsEvents,
  AnalyticsTutorialNames,
  aemPages,
  routingConstants,
  thingVuseProperties,
} from "../../_constants";
import {
  AEMHelper,
  propertyHeadingDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { getCurrentDevice } from "../../_helpers/device";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";

function LockSwitchTutorial({ lockSwitchTutorial }) {
  const [dictionary, setDictionary] = useState({});
  const [stories, setStories] = useState([]);

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvents.TUTORIAL, {tutorial_name: AnalyticsTutorialNames.LOCK_UNLOCK})
    const aem = new AEMHelper();

    const device = getCurrentDevice();
    const stories = aem
      .getCarouselUrlsFor(device.deviceType, "LOCK_SWITCH_TUTORIAL")
      .map((url) => ({
        url,
        type: "video",
        muted: true
      }));

    setDictionary(
      aem.getDictionary(aem.getPageName("LOCK_SWITCH_TUTORIAL", device.deviceType) , {
        LOCK_UNLOCK_TUTORIAL_HEADER: propertyHeadingDefaultEmpty,
      })
    );
    setStories(stories);
  }, []);

  return (
    <FeatureTutorial
      title={dictionary.LOCK_UNLOCK_TUTORIAL_HEADER || "DEVICE LOCK"}
      stories={stories}
      iconAction={null}
      backAction={routingConstants.DASHBOARD}
      doneTutorialMethod={dashboardActions.setDoneTutorialLockSwitch}
      doneTutorialValue={lockSwitchTutorial}
      thing={thingVuseProperties.LOCK_TUTORIAL}
      tutorialName={AnalyticsTutorialNames.LOCK_UNLOCK}
    />
  )
}

const mapStateToProps = (state) => ({
  lockSwitchTutorial: state.dashboardReducer.tutorials.usageTracker,
});

const component = connect(mapStateToProps, null)(LockSwitchTutorial);
export { component as LockSwitchTutorial };
