import { combineReducers } from 'redux';
import { loginReducer } from './login.reducer';
import { registrationReducer } from './registration.reducer';
import { onboardingReducer } from './onboarding.reducer';
import { dashboardReducer }  from './dashboard.reducer';
import { deviceReducer } from './device.reducer';
import { commonsReducer } from './commons.reducer';
import { ageVerificationReducer } from './age-verification.reducer';
import { shopReducer } from './shop.reducer';
import { permissionsReducer } from './permissions.reducer';
import { aem } from './aem.reducer';

const appReducer = combineReducers({
    loginReducer,
    registrationReducer,
    onboardingReducer,
    dashboardReducer,
    deviceReducer,
    commonsReducer,
    shopReducer,
    permissionsReducer,
    ageVerificationReducer,
    aem
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;