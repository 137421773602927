import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  getAEMAssetsUrl,
  getCountryCurrency,
  getLoyaltyDashboardUrl,
} from "../../../_actions/appConfig.actions";
import {
  Header,
  LoyaltyBadges,
  LoyaltyLogo,
  LoyaltyTierIndicator,
  PageSelectorCTA
} from "../../../_components";
import { AnalyticsEvents, aemPages } from "../../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyImageSrcDefaultNull,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../../_helpers/analytics/logAnalytics";
import { Utils } from "../../../_helpers/graphic/utils";
import { convertObjectToQueryString } from "../../../_helpers/utils";

const LoyaltyDashboardDE = ({ handleBack, data, oneTimeToken }) => {
  const [dictionary, setDictionary] = useState({});
  const intl = useIntl();

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.LOYALTY, {
        LYLT_LOGO: propertyImageSrcDefaultNull,
        LYLT_TIER_EXPLORER: propertyImageSrcDefaultNull,
        LYLT_TIER_CHALLENGER: propertyImageSrcDefaultNull,
        LYLT_TIER_INNOVATOR: propertyImageSrcDefaultNull,
        LYLT_TIER_VIP: propertyImageSrcDefaultNull,
        LYLT_TIERS_COUNTER: propertyTextDefaultEmptyParsed,
        LYLT_YOUR_BADGES: propertyTextDefaultEmpty,
        LYLT_BTN_VISIT: propertyCtaItemsDefaultEmpty,
        LYLT_BTN_ICON: propertyImageSrcDefaultNull,
      })
    );
  }, []);

  return (
    <div className="page w-100 loyalty loyalty-dashboard de">
      <Header
        rightButton={{
          icon: <span className="bat-icon-close" />,
          onClick: handleBack,
        }}
      >
        <LoyaltyLogo />
      </Header>
      <div className="page-wrapper">
        <div className="loyalty-summary">
          <LoyaltyTierIndicator
            currentValue={data?.currentPointsValue}
            remainingValue={data?.valuesToNextTier}
            tierIcon={
              dictionary?.[`LYLT_TIER_${data?.currentTierName?.toUpperCase()}`]
                ?.desktop?.src
            }
            isLastTier={!data?.nextTierName}
            title={data?.currentTierName}
            subtitle={Utils.stringReplacePlaceholders(
              dictionary?.LYLT_TIERS_COUNTER,
              [
                `<span class="fw-bold text-light">${intl.formatNumber(
                  data?.valuesToNextTier,
                  {
                    style: "currency",
                    currency: getCountryCurrency(),
                  }
                )}</span>`,
                `<span class="fw-bold text-light text-uppercase">${data?.nextTierName}</span>`,
              ],
              true
            )}
          />
        </div>
        <div className="loyalty-badges">
          <div className="loyalty-badges-title">
            {dictionary?.LYLT_YOUR_BADGES}
          </div>
          <LoyaltyBadges badgeGroups={data?.badgeGroups} />
        </div>
      </div>
      <PageSelectorCTA sticky={false}>
        <div className="d-grid">
          <button
            className="d-flex align-items-center justify-content-center btn btn-primary text-uppercase"
            onClick={() => {
              logAnalyticsEvent(AnalyticsEvents.LOYALTY_EXT_VISIT);

              window.open(
                getLoyaltyDashboardUrl() +
                  convertObjectToQueryString({
                    ...(oneTimeToken && {
                      token: oneTimeToken,
                    }),
                  }),
                "_blank"
              );
            }}
          >
            {dictionary.LYLT_BTN_VISIT_0_ctaLabel}
            <img
              src={`${getAEMAssetsUrl()}${
                dictionary.LYLT_BTN_ICON?.desktop?.src
              }`}
              alt="External URL Icon"
              style={{
                height: "1rem",
                marginLeft: "0.938rem",
              }}
            />
          </button>
        </div>
      </PageSelectorCTA>
      <div className="page-linear-gradient-bg" />
    </div>
  );
};

export { LoyaltyDashboardDE };
