import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";

const DEFAULT_LOCALE = "en-CA";

const ConnectedIntlProvider = ({ locale, children }) => {
  const [currLocale, setCurrLocale] = useState(DEFAULT_LOCALE);

  useEffect(() => {
    setCurrLocale(locale.replace("_", "-"));
  }, [locale]);

  return (
    <IntlProvider
      key={currLocale}
      locale={currLocale}
      defaultLocale={DEFAULT_LOCALE}
    >
      {children}
    </IntlProvider>
  );
};

const mapStateToProps = (state) => ({
  locale: state?.aem?.locale || DEFAULT_LOCALE,
});

const connectedComponent = connect(mapStateToProps)(ConnectedIntlProvider);

export { connectedComponent as ConnectedIntlProvider };
