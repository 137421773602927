import React, { useEffect, useState } from "react";
import { getLegalAge } from "../../_actions/appConfig.actions";
import {
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  HARD_AGE_VERIFICATION_STEPS,
  aemPages,
  imagesConstants,
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { Utils } from "../../_helpers/graphic/utils";
import { Header } from "../Header";
import { PageSelectorCTA } from "../PageSelectorCTA";

export function HardAgeVerificationUnsuccess({ goToStep }) {
  const [dictionary, setDictionary] = useState({});

  useEffect(() => {
    const aem = new AEMHelper();

    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
      screen_name: AnalyticsScreenEventNames.YOTI_FACE_SCAN_FAILURE,
    });

    setDictionary(
      aem.getDictionary(aemPages.HARD_AGE_VERIFICATION, {
        YOTI_AV_FIRST_ERROR_TITLE: propertyTextDefaultEmpty,
        YOTI_AV_FIRST_ERROR_COUNTRY_CONTENT: propertyTextDefaultEmptyParsed,
        YOTI_AV_FIRST_ERROR_PRODUCT_CONTENT: propertyTextDefaultEmpty,
        YOTI_AV_FIRST_ERROR_SUPPORT_CONTENT: propertyTextDefaultEmpty,
        YOTI_AV_SUPPORT_NUMBER: propertyTextDefaultEmpty,
        YOTI_AV_SUPPORT_MAIL: propertyTextDefaultEmpty,
        YOTI_BTN_GO_BACK: propertyCtaItemsDefaultEmpty,
      })
    );
  }, []);

  return (
    <div className="page w-100" style={{background: 'none'}}>
      <div className="page-wrapper d-flex flex-column align-items-center h-100 overflow-auto">
        <div className="hard-age-verification-title text-center">
          <h1>
            {Utils.stringReplacePlaceholders(
              dictionary.YOTI_AV_FIRST_ERROR_TITLE,
              getLegalAge(),
              true
            )}
          </h1>
          <span className="bat-icon-alert-triangle alert-icon" />
        </div>
        <div className="hard-age-verification-failure-desc">
          <div>
            {Utils.stringReplacePlaceholders(
              dictionary.YOTI_AV_FIRST_ERROR_COUNTRY_CONTENT,
              getLegalAge(),
              true
            )}
          </div>
          <div>{dictionary.YOTI_AV_FIRST_ERROR_PRODUCT_CONTENT}</div>
        </div>
        <div className="hard-age-verification-support">
          {dictionary.YOTI_AV_FIRST_ERROR_SUPPORT_CONTENT}
          <ul>
            <li>
              <span className="bat-icon-phone me-3" />
              {dictionary.YOTI_AV_SUPPORT_NUMBER}
            </li>
            <li>
              <img
                src={imagesConstants.NEW_EMAIL}
                alt="email icon"
                className="me-3"
                style={{ height: "14px" }}
              />
              {dictionary.YOTI_AV_SUPPORT_MAIL}
            </li>
          </ul>
        </div>
      </div>
      <PageSelectorCTA>
        <div className="d-grid">
          <button
            className="btn btn-primary text-uppercase"
            onClick={() => {
              logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.RETRY});
              goToStep(HARD_AGE_VERIFICATION_STEPS.CAPTURE);
            }}
          >
            {dictionary.YOTI_BTN_GO_BACK_0_ctaLabel}
          </button>
        </div>
      </PageSelectorCTA>
    </div>
  );
}
