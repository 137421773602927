import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { AnalyticsEvents, AnalyticsFieldNames, AnalyticsScreenEventNames, aemPages, webviewUrl } from "../../../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../../../_helpers/aem/aemhelper";
import { useSignupFormContext } from "../SignupFormContext";
import { SignupFormStep } from "../SignupFormStep";
import { logAnalyticsEvent } from "../../../../_helpers/analytics/logAnalytics";

const FIELD_NAME_TAC = "tac";
const FIELD_MARKETING_CONSENT = "marketing";
const FIELD_NAME_EMAIL_CONSENT = "userOptedInForEmails";
const FIELD_NAME_SMS_CONSENT = "userOptedInForSMS";
const FIELD_NAME_NOTIFICATIONS_CONSENT = "userOptedInForPushNotifications";

export function ConsentsStepZA(props) {
  const [dictionary, setDictionary] = useState({});
  const { register } = useFormContext();
  const { updateForm, showPrivacyPolicyPanel, showTacPanel, showPPIPanel } = useSignupFormContext();
  const {
    [FIELD_NAME_TAC]: tac,
    [FIELD_NAME_EMAIL_CONSENT]: emailConsent,
    [FIELD_NAME_SMS_CONSENT]: smsConsent,
    [FIELD_MARKETING_CONSENT]: notificationConsent
  } = useWatch([FIELD_NAME_TAC, FIELD_NAME_EMAIL_CONSENT, FIELD_NAME_SMS_CONSENT, FIELD_MARKETING_CONSENT]);

  useEffect(() => {

    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.SIGN_UP_CONSENTS})

    const aem = new AEMHelper();

    setDictionary({
      ...aem.getDictionary(
        aemPages.REGISTRATION_DETAIL_TAC, {
          REGISTRATION_HEADER: propertyHeadingDefaultEmpty,
          REGISTRATION_BTN_CREATE_ACCOUNT: propertyCtaItemsDefaultEmpty,
          REGISTRATION_MARKETING_LABEL: propertyTextDefaultEmpty,
          REGISTRATION_ACCEPT_TAC_LINK: propertyTextDefaultEmptyParsed,
          REGISTRATION_HOW_PROCESS_DATA_LINK: propertyTextDefaultEmptyParsed,
          REGISTRATION_SMS_CHECKBOX: propertyTextDefaultEmptyParsed,
          REGISTRATION_EMAIL_CHECKBOX: propertyTextDefaultEmptyParsed,
          REGISTRATION_NOTIFICATIONS_CHECKBOX: propertyTextDefaultEmptyParsed,
          REGISTRATION_WITHDRAW_CONSENT_MARKETING_INFORMATION: propertyTextDefaultEmptyParsed,
          REGISTRATION_TO_OBJECT_PERSONAL_INFORMATION: propertyTextDefaultEmptyParsed,
          REGISTRATION_HOW_USE_DATA_PRIVACY_LINK: propertyTextDefaultEmptyParsed,
        }
      ),
      ...aem.getDictionary(
        aemPages.ONBOARD_NOTIF_PREF, {
          NOTIF_PREF_MKT_INFO_CONTENT: propertyTextDefaultEmptyParsed,
          
        }
      )
    });
  }, []);

  useEffect(() => {
    updateForm({
      [FIELD_NAME_EMAIL_CONSENT]: emailConsent,
      [FIELD_NAME_SMS_CONSENT]: smsConsent,
      [FIELD_NAME_NOTIFICATIONS_CONSENT]: notificationConsent
    });
  }, [updateForm, emailConsent, smsConsent, notificationConsent]);

  return (
    <SignupFormStep
      title={dictionary.REGISTRATION_HEADER}
      customClass="h-auto consents-step country-za"
      ctaLabel={dictionary.REGISTRATION_BTN_CREATE_ACCOUNT_0_ctaLabel}
      showAgeWarning
      isLast
      isValid={!!tac}
      {...props}
    >
      <div className="signup-form-wrapper">
        <div className="signup-form-row">
          <div className="form-check">
            <input
              type="checkbox"
              id={FIELD_NAME_TAC}
              name={FIELD_NAME_TAC}
              className="form-check-input me-3"
              ref={register({
                required: true,
              })}
            />
            <label onClick={() => showTacPanel()}>
              {dictionary.REGISTRATION_ACCEPT_TAC_LINK}
            </label>
          </div>
        </div>
        <div className="signup-form-row marketing-content add-bottom-2" onClick={() => showPPIPanel()}>
          {dictionary.REGISTRATION_HOW_PROCESS_DATA_LINK}
        </div>
        <div className="signup-form-row marketing-check-wrapper">
          <label className="page-label">
            {dictionary.REGISTRATION_MARKETING_LABEL}
          </label>
          <label className="marketing-content">
            {dictionary.NOTIF_PREF_MKT_INFO_CONTENT}
          </label>
          <div className="form-check">
            <input
              name={FIELD_NAME_EMAIL_CONSENT}
              id={FIELD_NAME_EMAIL_CONSENT}
              autoComplete="off"
              className="form-check-input"
              type="checkbox"
              ref={register}
            />
            <label
              className="form-check-label"
              htmlFor={FIELD_NAME_EMAIL_CONSENT}
            >
              {dictionary.REGISTRATION_EMAIL_CHECKBOX}
            </label>
          </div>
          <div className="form-check">
            <input
              name={FIELD_NAME_SMS_CONSENT}
              id={FIELD_NAME_SMS_CONSENT}
              autoComplete="off"
              className="form-check-input"
              type="checkbox"
              ref={register}
            />
            <label
              className="form-check-label"
              htmlFor={FIELD_NAME_SMS_CONSENT}
            >
              {dictionary.REGISTRATION_SMS_CHECKBOX}
            </label>
          </div>
          <div className="form-check">
            <input
              name={FIELD_NAME_NOTIFICATIONS_CONSENT}
              id={FIELD_NAME_NOTIFICATIONS_CONSENT}
              autoComplete="off"
              className="form-check-input"
              type="checkbox"
              ref={register}
            />
            <label
              className="form-check-label"
              htmlFor={FIELD_NAME_NOTIFICATIONS_CONSENT}
            >
              {dictionary.REGISTRATION_NOTIFICATIONS_CHECKBOX}
            </label>
          </div>
          <div className="marketing-content add-bottom">
            {dictionary.REGISTRATION_WITHDRAW_CONSENT_MARKETING_INFORMATION}
          </div>
          <div className="marketing-content add-bottom">
            {dictionary.REGISTRATION_TO_OBJECT_PERSONAL_INFORMATION}
          </div>
          <div className="marketing-content" onClick={() => showPrivacyPolicyPanel()}>
            {dictionary.REGISTRATION_HOW_USE_DATA_PRIVACY_LINK}
          </div>
        </div>
      </div>
    </SignupFormStep>
  );
}
