import isNil from "lodash/isNil";

/**
 * Returns a JSON object representing the query string (string composed by x=y joined by & . Ex: filters=a.b.c&PN=123 returns {filers : a.b.c, PN: 123} ).
 *
 * @param {string} queryString
 * @return {Object}
*/
export function convertStringToObject(str = "", separator = "&", removeQuotes = true) {
  // if param is empty return empty object,
  if (!str) {
    return {};
  }

  try {
    return decodeURIComponent(str)
      .split(separator)
      .reduce((acc, el) => {
        let [key, value = ""] = el.split("=");
        if(removeQuotes){
          value = value.replaceAll("'", "").replaceAll('"', "");
        }
        return {
          ...acc,
          [key]: value
        };
      }, {});
  } catch (err) {
    console.warn(`StringToObject: impossible to convert ${str} to object`);
    return {};
  }
}

export function convertQueryStringToObject(queryString = "", removeQuotes = true) {
  return convertStringToObject(queryString.replace(/^\?/g, ""), "&", removeQuotes);
}

export function convertFragmentIdentifierToObject(fragmentIdentifier = "") {
  return convertStringToObject(fragmentIdentifier.replace(/^#/g, ""), "&");
}

export function convertObjectToString(obj = {}, separator = "&") {
  const keys = Object.keys(obj);
  return keys.length > 0
    ? keys.map(key => `${key}=${obj[key]}`).join(separator)
    : ''
}

/**
 * Given an object return a query string valid format.
 *
 * @param {Object} object
 * @return {string}
 */
export function convertObjectToQueryString(object = {}) {
  const str = convertObjectToString(object);
  return str.length > 0 ? `?${str}` : str;
}

/**
 * Given an object return a fragment identifier valid format.
 *
 * @param {Object} object
 * @return {string}
 */
 export function convertObjectToFragmentIdentifier(object = {}) {
  const str = convertObjectToString(object);
  return str.length > 0 ? `#${str}` : str;
}

export function isValidString(thisString){
    return thisString !== null && thisString !== undefined && thisString !== "";
}

export function getTruncateAppVersion() {
    return !isNil(process.env.REACT_APP_APP_VERSION)
        && process.env.REACT_APP_APP_VERSION !== ""
        ? (process.env.REACT_APP_APP_VERSION).split('-')[0]
        : '';
}