import { getURLHashObject } from "../navigation";

export function getActiveStep() {
  const urlHash = getURLHashObject();
  const stepKey = Object.keys(urlHash).find(key => key.match("step")) || "step1";
  const stepString = stepKey.replace("step", "");
  const stepNumber = parseInt(stepString);
  return !isNaN(stepNumber) && stepNumber > 0
    ? stepNumber
    : -1;
}

// active step started with 1
export function triggerLandingOn(steps, activeStep = 1) {
  if (activeStep > 0) {
      const step = steps[activeStep - 1];
      return step.onLanding && step.onLanding();
  }
}

export function renderAllSteps(steps) {
  return steps.map(step => step.render && step.render());
}