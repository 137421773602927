import { REQUEST, SUCCESS, FAILURE } from '../_reducers/action-type.util';
import { commonsConstants, CUSTOMER, thingVuseProperties, shopConstants, crmUserSettings } from '../_constants';
import { isCurrentCountryCanada } from './appConfig.actions';
import { commonsServices, shopServices } from '../_services';
import { getVuseUuid, userActions } from '.';

export const shopActions = {
    //FE
    addVariantToBasket,
    updateVariantInBasket,
    replaceBasket,
    updateQuantityInBasket,
    deleteFromBasket,
    addVariantInWhishlist,
    updateVariantInWhishlist,
    resetVariantsInWhishlist,
    //BE
    getCatalog,
    getLastOrder,
    setWishlist,
    getFlavourForYouProducts,
    removeFlavourForYouProduct
};


function deleteFromBasket(variant, callBack) {
    return (dispatch, getState) => {
        dispatch({
            type: shopConstants.DELETE_FROM_BASKET,
            data: variant
        });

        if (typeof callBack === 'function') {
            callBack();
        }
    };
}

function addVariantInWhishlist(variant, callBack) {
    return (dispatch, getState) => {
        dispatch({
            type: shopConstants.ADD_VARIANT_IN_WISHLIST,
            data: variant
        });

        if (typeof callBack === 'function') {
            callBack();
        }
    };
}

function updateVariantInWhishlist(variant, callBack) {
    return (dispatch, getState) => {
        dispatch({
            type: shopConstants.UPDATE_VARIANT_IN_WISHLIST,
            data: variant
        });

        if (typeof callBack === 'function') {
            callBack();
        }
    };
}

function resetVariantsInWhishlist(newWishlist, callBack) {
    return (dispatch, getState) => {
        dispatch({
            type: shopConstants.RESET_WISHLIST,
            data: newWishlist
        });

        if (typeof callBack === 'function') {
            callBack();
        }
    };
}

function replaceBasket(basket, callBack) {
    return (dispatch, getState) => {
        dispatch({
            type: shopConstants.REPLACE_BASKET,
            data: basket
        });

        if (typeof callBack === 'function') {
            callBack();
        }
    };
}

function updateVariantInBasket(variant, callBack) {
    return (dispatch, getState) => {
        dispatch({
            type: shopConstants.UPDATE_IN_BASKET,
            data: variant
        });

        if (typeof callBack === 'function') {
            callBack();
        }
    };
}

function updateQuantityInBasket(variant, callBack) {
    return (dispatch, getState) => {
        dispatch({
            type: shopConstants.UPDATE_BASKET_QTY,
            data: variant
        });

        if (typeof callBack === 'function') {
            callBack();
        }
    };
}

function addVariantToBasket(variant, callBack) {
    return (dispatch, getState) => {
        dispatch({
            type: shopConstants.ADD_TO_BASKET,
            data: variant
        });

        if (typeof callBack === 'function') {
            callBack();
        }
    };
}

function getLastOrder(token, userId, successCb, failureCb) {

    if (isCurrentCountryCanada()) {
        return userActions.getUserInfo(token, successCb, failureCb);
    } else {
        return getLastOrderUK(token, userId, successCb, failureCb);
    }
}

function getLastOrderUK(token, userId, successCb, failureCb) {
    return (dispatch, getState) => {
        dispatch(request());

        shopServices.getLastOrder(token, userId).then(payload => {
            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(shopConstants.GET_LAST_ORDER) };
    }
    function success(payload) {
        return { type: SUCCESS(shopConstants.GET_LAST_ORDER), payload };
    }
    function failure(error) {
        return { type: FAILURE(shopConstants.GET_LAST_ORDER), error };
    }
}

export function getCatalog(tenantUserId, uuid, successCb, failureCb) {
    return (dispatch, getState) => {
        dispatch(request());

        shopServices.getCatalog(tenantUserId, uuid).then(payload => {
            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(shopConstants.GET_CATALOG) };
    }
    function success(payload) {
        return { type: SUCCESS(shopConstants.GET_CATALOG), payload };
    }
    function failure(error) {
        return { type: FAILURE(shopConstants.GET_CATALOG), error };
    }
}

export function getFlavourForYouProducts(tenantUserId, uuid, successCb, failureCb) {
    return (dispatch, getState) => {
        dispatch(request());

        shopServices.getFlavourForYouProducts(tenantUserId, uuid).then(payload => {
            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(shopConstants.GET_FLAVOUR_FOR_U) };
    }
    function success(payload) {
        return { type: SUCCESS(shopConstants.GET_FLAVOUR_FOR_U), payload };
    }
    function failure(error) {
        return { type: FAILURE(shopConstants.GET_FLAVOUR_FOR_U), error };
    }
}



export function removeFlavourForYouProduct(tenantUserId, data, successCb, failureCb) {
    return (dispatch, getState) => {
        dispatch(request());

        commonsServices.setThing(tenantUserId, getVuseUuid(), CUSTOMER, thingVuseProperties.UNFAVOURITES, data).then(payload => {
            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(commonsConstants.SET_THINGS) };
    }
    function success(payload) {
        return { type: SUCCESS(commonsConstants.SET_THINGS), payload };
    }
    function failure(error) {
        return { type: FAILURE(commonsConstants.SET_THINGS), error };
    }
}

function setWishlist(tenantUserId, data, successCb, failureCb) {
    return (dispatch, getState) => {
        dispatch(request());

        commonsServices.setThing(
            tenantUserId,
            getVuseUuid(),
            CUSTOMER,
            thingVuseProperties.FAVOURITES,
            data
        ).then(payload => {
            dispatch(success(payload.data));


            dispatch(
                userActions.setUserSettings(
                    crmUserSettings.FAVOURITES,
                    data
                )
            )
            
            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(commonsConstants.SET_THINGS) };
    }
    function success(payload) {
        return { type: SUCCESS(commonsConstants.SET_THINGS), payload };
    }
    function failure(error) {
        return { type: FAILURE(commonsConstants.SET_THINGS), error };
    }
}
