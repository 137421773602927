import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import parse from 'html-react-parser';
import { compact } from 'lodash/array';
import { getAEMAssetsUrl } from '../../../_actions/appConfig.actions';
import { CustomModal } from '../../../_components/CustomModal';
import { FeatureTutorial } from '../../../_components';
import HealthWarning from '../../../_components/HealthWarning';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from '../../../_actions';
import { debug } from '../../../_helpers/debug';
import { logAnalyticsEvent } from '../../../_helpers/analytics/logAnalytics';
import { AnalyticsEvents } from '../../../_constants';


const Story = ({ storyEL, action, isPaused, handleStoryAction }) => {

    const storyVideoRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            console.log("storyVideoRef", storyVideoRef)
            if (storyEL.storyType === 'video') {
                if (storyVideoRef?.current) {
                    action('pause');
                    storyVideoRef.current.pause();
                }
            }

        }, 0);
    }, []);

    if (!storyEL) {
        return null;
    }

    if (isPaused) {
        if (storyVideoRef?.current) {
            storyVideoRef.current.pause();
        }
    } else {
        if (storyVideoRef?.current) {
            storyVideoRef.current.play();
        }
    }

    const url = storyEL.storyType !== null &&
        storyEL.storyType !== undefined &&
        storyEL[storyEL.storyType] !== null &&
        storyEL[storyEL.storyType] !== undefined &&
        storyEL[storyEL.storyType]?.path
        ? `${getAEMAssetsUrl()}${storyEL[storyEL.storyType].path}`
        : null;


    return (
        <>
            {
                storyEL.storyType === 'video' ?

                    <div className='story-element-video-wrapper'>

                        <video className='story-element-video'
                            ref={storyVideoRef}
                            onPlay={() => {
                                action('play');
                            }}
                            onWaiting={() => {
                                action("pause");
                            }}
                            onLoadedData={(e) => {
                                debug("[video] onCanPlay");
                                if (storyVideoRef?.current) {
                                    storyVideoRef.current.play();
                                }
                            }}
                            onProgress={(e) => {
                                if (isNaN(e.target.duration))   // duration is NotaNumber at Beginning.
                                    return;
                                let progr = (e.target.currentTime / e.target.duration) * 100;
                                debug("[video] progress " + progr);
                            }}
                            playsInline muted preload
                            webkit-playsinline="true"
                        >
                            <source src={`${url}#t=0.001`} type="video/mp4" />
                        </video>
                    </div>
                    :
                    <div className='story-element-img-wrapper'>
                        <img className='story-element-img'
                            src={url} alt="" />
                    </div>
            }

            <div className='story-element-see-more-wrapper'>
                <button class="btn btn-primary see-more-btn"
                    onClick={() => {
                        action('pause');
                        handleStoryAction(storyEL);
                    }}>do action</button>
            </div>
        </>
    )
}

export const VuseWorldStories = (props) => {

    const {
        carousel: stories,
        title,
        dictionary
    } = props;

    const dispatch = useDispatch();
    const vuseWorldStories = useSelector((state) => state.dashboardReducer.vuseWorldStories);
    const [openStoryModal, setOpenStoryModal] = useState(false);
    const [currentStory, setCurrentStory] = useState(stories[0] ?? null);
    let currentIndex = 0;

    const renderCarousel = () => {
        
        return compact(stories
            .map((story, index) => {
                const onImageClick = () => {
                    setCurrentStory(story);
                    setOpenStoryModal(true);
                    logAnalyticsEvent(AnalyticsEvents.VW_CLICK_STORY_CATEGORY, {
                        category: story?.title
                    });
                };
                if (story.stories.length > 0) {
                    return (
                        <SwiperSlide
                            className="swiper-slide"
                            key={`stories-${index}`}
                        >
                            <div className="story-dot-container">
                                <div className={`story-dot ${story.completed ? '' : 'new'}`}
                                    style={{ backgroundImage: `url(${story.icon})` }}
                                    onClick={onImageClick} />
                                <span className='story-dot-label'>{parse(story?.title)}</span>
                            </div>
                        </SwiperSlide>
                    );
                } else {
                    return null;
                }

            })
        );
    };

    const closeStoriesModal = () => {
        props.setStorySeen(compact(currentStory?.stories.map((el, idx) => idx <= currentIndex ? el.id : null)));
        setOpenStoryModal(false);
        dispatch(dashboardActions.setStoriesSeen(currentStory?.title));
        setCurrentStory(null);
    }

    const handleStoryAction = (story) => {
        console.log("[handleStoryAction] story", story);
        if (typeof props.handleStoryAction === "function") {
            props.handleStoryAction(story);
        }
    }

    return stories?.length > 0 ? (
        <>
            <div className='vuseworld-section'>
                <div className='vuseworld-section-header'>
                    <p className='vuseworld-section-title'>{title}</p>
                </div>
                <Swiper
                    slidesPerView={'auto'}
                    loop={false}
                    centeredSlides={false}
                    centeredSlidesBounds={true}
                    centerInsufficientSlides={false}
                    spaceBetween={10}
                    initialSlide={0}
                    className="vuseworld-section-body vuseworld-stories-container"
                >
                    {renderCarousel()}
                </Swiper>
            </div>
            <CustomModal
                isOpen={openStoryModal}
                onCLose={closeStoriesModal}
                className={"story-modal"}
                isNotification={true}>
                <HealthWarning>{dictionary.HEALTH_WARNING}</HealthWarning>
                {
                    currentStory &&

                    <FeatureTutorial
                        title={currentStory?.title}
                        featureImage={currentStory?.icon}
                        stories={
                            // currentStory?.stories?.map((storyEL) => {
                            //     return { content: (props) => <Story storyEL={storyEL} handleStoryAction={handleStoryAction} {...props} /> }
                            // })
                            currentStory?.stories?.map((story) => {
                                return {
                                    url: `${getAEMAssetsUrl()}${story[story.storyType].path}`,
                                    type: story.storyType,
                                    seeMore: ({ close }) => {
                                        return <div onClick={close}>Hello, click to close this.</div>;
                                    },
                                    seeMoreCollapsed: ({ toggleMore, action }) => {
                                        if (
                                            story.action !== null &&
                                            story.action !== undefined &&
                                            story.action !== ""
                                        ) {
                                            return (<button className='btn btn-primary see-more-btn text-uppercase'
                                                onClick={() => {
                                                    action('pause');
                                                    handleStoryAction(story);
                                                    logAnalyticsEvent(AnalyticsEvents.VW_CLICK_STORY_ITEM, {
                                                        cta: story?.action,
                                                        item: (!!story?.analytics && story.analytics['dtm-1']) || null
                                                    });
                                                }}>{story.ctaLabel}</button>)
                                        } else {
                                            return null;
                                        }

                                    },
                                    muted: true

                                }
                            }) ?? []
                        }
                        iconAction={null}
                        backFn={closeStoriesModal}
                        currentIdx={currentStory?.lastIndexSeen}
                        endStoriesCallback={closeStoriesModal}
                        startStoryCallback={(idx, story) => {
                            //saving current story index to handle seen status
                            currentIndex = idx;
                        }}
                        endStoryCallback={(idx, story) => {
                            //saving current story index to handle seen status
                            currentIndex = idx;
                        }}
                        doneTutorialValue={vuseWorldStories && vuseWorldStories[currentStory?.title]}
                        loop={false}
                    />

                }

            </CustomModal>
        </>

    ) : <></>
};
