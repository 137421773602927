import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Swiper, SwiperSlide } from "swiper/react";
import useResizeObserver from "use-resize-observer";
import { deviceActions } from "../../_actions";
import { isSupportedService } from "../../_actions/appConfig.actions";
import {
  CustomButton,
  FeatureWidget,
  FeatureWidgetGroup,
  Header,
  LockSwitch,
  ReconnectCTA,
  ReconnectLoader,
  StatusBar,
} from "../../_components";
import {
  AnalyticsEvents,
  aemPages,
  routingConstants,
  servicesConstants,
  thingVuseProperties,
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import BrowserHelper from "../../_helpers/browser/browser.helper";
import { Commons } from "../../_helpers/commons";
import {
  clearAllLastSyncedLocations,
  getCurrentDevice,
  isDeviceConnected,
} from "../../_helpers/device";
import {
  getDeviceHomeScreenImg,
  getDeviceIconImg,
} from "../../_helpers/device/assets";
import { getElementHeight } from "../../_helpers/dom";
import { PermissionsHelper } from "../../_helpers/permissions/permissions.helper";

const DEVICE_IMG_MB = 24;
const DEVICE_SELECTOR_MB = 28;

const Test = ({ devices, setSelected }) => {
  const [dictionary, setDictionary] = useState({});
  const headerRef = useRef(null);
  const { ref: deviceImgRef, height: deviceImgHeight = 1 } =
    useResizeObserver();
  const reconnectCTARef = useRef(null);
  const deviceSelectorRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [deviceSelectorHeight, setDeviceSelectorHeight] = useState(0);
  const [currSlide, setCurrSlide] = useState(0);
  const [currDevice, setCurrDevice] = useState(null);

  const handleSlideChange = (swiper) => {
    const index = swiper.realIndex;

    setCurrSlide(index);
    setCurrDevice(devices[index]);
    setSelected(devices[index]);
  };

  const goToUsageTracker = () => {
    setSelected(currDevice, () => {
      Commons.goToFeaturePage({
        thingProperty: thingVuseProperties.OPT_IN_USAGE_TRACKER,
        route: {
          optinDone: routingConstants.USAGE_TRACKER,
          optinUndone: routingConstants.USAGE_TRACKER_TUTORIAL,
        },
      });
    });
  };

  const goToCloudControl = () => {
    Commons.goToFeaturePage({
      thingProperty: thingVuseProperties.CLOUDCONTROL,
      analytics: {
        eventName: AnalyticsEvents.CLOUD_CONTROL,
      },
      device: currDevice,
      route: {
        optinDone: routingConstants.CLOUD_CONTROL,
        optinUndone: routingConstants.CLOUD_CONTROL_TUTORIAL,
      },
    });
  };

  const goToBatterySettings = () => {
    Commons.goToFeaturePage({
      thingProperty: thingVuseProperties.BATTERYSAVE,
      analytics: {
        eventName: AnalyticsEvents.BATTERY,
      },
      device: currDevice,
      route: {
        optinDone: routingConstants.BATTERY_SETTINGS,
        optinUndone: routingConstants.BATTERY_SETTINGS_TUTORIAL,
      },
    });
  };

  const goToFindMyVape = () => {
    setSelected(currDevice, () => {
      Commons.goToFeaturePage({
        thingProperty: thingVuseProperties.OPT_IN_FINDMYVAPE,
        analytics: {
          eventName: AnalyticsEvents.FIND_MY_VAPE,
        },
        route: {
          optinUndone: routingConstants.FIND_MY_VAPE_TUTORIAL,
        },
        onOptinDone: async () => {
          const permission = await BrowserHelper.getLocationPermission();
          const routeState = {};

          if (PermissionsHelper.hasDeniedStatus(permission)) {
            clearAllLastSyncedLocations();
            routeState.isPermissionDenied = true;
          }

          Commons.goTo(routingConstants.FIND_MY_VAPE);
        },
      });
    });
  };

  const goToDeviceSettings = () => {
    setSelected(currDevice, () =>
      Commons.goTo(routingConstants.DEVICE_SETTINGS)
    );
  };

  useEffect(() => {
    document.body.classList.add("homescreen");

    setHeaderHeight(getElementHeight(headerRef.current));
    setDeviceSelectorHeight(getElementHeight(deviceSelectorRef.current));

    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.HOMEPAGE, {
        HOME_BTN_RECONNECT: propertyCtaItemsDefaultEmpty,
        HOME_LABEL_CONNECTED: propertyTextDefaultEmpty,
        HOME_LABEL_DISCONNECTED: propertyTextDefaultEmpty,
        HOME_LABEL_USAGE_TRACKER: propertyTextDefaultEmpty,
        HOME_LABEL_FMV: propertyTextDefaultEmpty,
      })
    );

    return () => {
      document.body.classList.remove("homescreen");
    };
  }, []);

  useEffect(() => {
    setCurrDevice(getCurrentDevice());
  }, [devices]);

  return (
    // <div className="homescreen-page">
    //   <Header ref={headerRef} transparent={true} />
    //   <Swiper
    //     className="device-carousel"
    //     onSlideChange={handleSlideChange}
    //     style={{ height: `${window.innerHeight}px` }}
    //   >
    //     {devices.map((device, i) => {
    //       const isConnected = isDeviceConnected(device);

    //       return (
    //         <SwiperSlide
    //           key={i}
    //           className="device-carousel-slide"
    //           style={{
    //             backgroundImage: `url(${getDeviceHomeScreenImg(device)})`,
    //             paddingTop: `${headerHeight}px`,
    //           }}
    //         >
    //           <img
    //             {...(i === currSlide && { ref: deviceImgRef })}
    //             className={cx("device-img", {
    //               disconnected: !isConnected,
    //             })}
    //             src={getDeviceIconImg(device)}
    //             alt="device icon"
    //           />
    //           {!isConnected && (
    //             <ReconnectCTA
    //               {...(i === currSlide && { ref: reconnectCTARef })}
    //               style={{
    //                 top: `${
    //                   headerHeight +
    //                   (deviceImgHeight / 2 -
    //                     getElementHeight(reconnectCTARef.current) / 2)
    //                 }px`,
    //               }}
    //               device={device}
    //             >
    //               {dictionary.HOME_BTN_RECONNECT_0_ctaLabel}
    //             </ReconnectCTA>
    //           )}
    //         </SwiperSlide>
    //       );
    //     })}
    //   </Swiper>
    //   <div
    //     ref={deviceSelectorRef}
    //     className="device-selector"
    //     style={{ top: `${headerHeight + deviceImgHeight + DEVICE_IMG_MB}px` }}
    //   />
    //   <BottomSheet
    //     style={{ "--rsbs-overlay-rounded": "25px" }}
    //     open={true}
    //     blocking={false}
    //     skipInitialTransition={true}
    //     snapPoints={({ maxHeight }) => [
    //       maxHeight -
    //         headerHeight -
    //         deviceImgHeight -
    //         DEVICE_IMG_MB -
    //         deviceSelectorHeight -
    //         DEVICE_SELECTOR_MB,
    //       maxHeight - headerHeight,
    //     ]}
    //     header={
    //       <StatusBar
    //         device={currDevice}
    //         connectedLabel={dictionary.HOME_LABEL_CONNECTED}
    //         disconnectedLabel={dictionary.HOME_LABEL_DISCONNECTED}
    //       />
    //     }
    //   >
    //     <LockSwitch className="home-switch" device={currDevice} />
    //     <FeatureWidgetGroup>
    //       <FeatureWidget
    //         isSupported={isSupportedService(servicesConstants.USAGE_TRACKER)}
    //         icon="usage"
    //         label={dictionary.HOME_LABEL_USAGE_TRACKER}
    //         onClick={goToUsageTracker}
    //       />
    //       <FeatureWidget
    //         disabled={!isDeviceConnected(currDevice)}
    //         isSupported={true}
    //         icon="puff"
    //         label={"CLOUD CONTROL"}
    //         onClick={goToCloudControl}
    //       />
    //       <FeatureWidget
    //         disabled={!isDeviceConnected(currDevice)}
    //         isSupported={true}
    //         icon="battery-in-charge"
    //         label={"RECHARGE REMINDERS"}
    //         onClick={goToBatterySettings}
    //       />
    //       <FeatureWidget
    //         isSupported={true}
    //         full={true}
    //         icon="find-my-vape"
    //         label={dictionary.HOME_LABEL_FMV}
    //         onClick={goToFindMyVape}
    //       />
    //     </FeatureWidgetGroup>
    //     <CustomButton
    //       variant="dark"
    //       full={true}
    //       icon="settings"
    //       className="homescreen-cta"
    //       onClick={goToDeviceSettings}
    //     >
    //       DEVICE SETTINGS
    //     </CustomButton>
    //   </BottomSheet>
    //   <ReconnectLoader />
    // </div>
    <div>TEST SCREEN!</div>
  );
};

const mapStateToProps = (state) => ({
  devices: state?.deviceReducer?.devices || [],
});

const mapDispatchToProps = (dispatch) => ({
  setSelected(device, successCb) {
    dispatch(deviceActions.setSelected(device, successCb));
  },
});

const connnectedComponent = connect(mapStateToProps, mapDispatchToProps)(Test);
export { connnectedComponent as Test };
