import { AnalyticsEvents } from "../../_constants";
import {SCREEN_VIEW_ANALYTICS} from "./screenView.analytics";

export const AnalyticsConfig = {
  [AnalyticsEvents.SCREEN_VIEW]: SCREEN_VIEW_ANALYTICS
}

export function getAnalyticsConfigBy(event_name = "", pathname = "") {
  return (AnalyticsConfig[event_name] && AnalyticsConfig[event_name][pathname]) || {
    event_params: {},
    user_params: {},
    platforms: []
  };
}