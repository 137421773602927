import React from 'react';

import _, { isNil, isNull } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';

import { BuyNow } from './Shop/BuyNow';
import { Reorder } from './Shop/Reorder';
import { CustomLoaderOverlay, ShopCheckout, SwipableViews } from '../../_components';
import { ShopBasket } from '../../_components/ShopBasket/ShopBasket';
import { getAccessToken, shopActions, getVuseUuid } from '../../_actions';
import { logAnalyticsEvent, logAnalyticsEventByCustomEvent } from '../../_helpers/analytics/logAnalytics';
import { aemPages, AnalyticsEvents, AnalyticsScreenEventNames, imagesConstants, servicesConstants, webviewUrl, routingConstants } from '../../_constants';
import { logAnalyticsEventOfBasketVariantList } from '../../_helpers/basket';
import { Subscriptions } from './Shop/Subscriptions';
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyImageSrcDefaultNull, propertyTextDefaultEmpty, propertyTextDefaultEmptyParsed, propertyTextDefaultNull } from '../../_helpers/aem/aemhelper';
import { Commons } from '../../_helpers/commons';
import { Tenants } from '../../_helpers/tenants';
import { CheckoutHelper } from '../../_helpers/checkout';
import { shopServices } from '../../_services';
import { getAEMAssetsUrl, getCountry, getUrl, isSupportedService } from '../../_actions/appConfig.actions';
import { convertObjectToQueryString, convertQueryStringToObject } from '../../_helpers/utils';
import cx from "classnames";
import { buildURI } from "../../_helpers/navigation";
import { history } from "../../_helpers/history";
import { DashboardContext } from '../Dashboard';

class Shop extends React.Component {

    constructor(props) {
        super(props);

        this.aem = new AEMHelper();
        this.dictionary = this.aem.getDictionary(aemPages.SHOP, {
            SHOP_NW_TITLE_DYN: propertyTextDefaultEmpty,
            SHOP_NW_BODY_DYN: propertyTextDefaultEmptyParsed,
            SHOP_NW_BG_DYN: propertyImageSrcDefaultNull,
            SHOP_NW_BTN_ICON_DYN: propertyImageSrcDefaultNull,
            SHOP_NW_BTN_DYN: propertyTextDefaultEmpty,
            SHOP_NW_BTN_FIND_STORE: propertyCtaItemsDefaultEmpty,
        });

        this.state = {
            activeViewIndex: -1,
            activeCategory: null,
            views: [
                {
                    id: 0,
                    label: this.dictionary.SHOP_BTN_BUY_NOW_0_ctaLabel,
                    analytics: {
                        [AnalyticsEvents.SCREEN_VIEW]: () => ({
                            screen_name: AnalyticsScreenEventNames.MY_VUSE_SHOP
                        })
                    }
                },
                {
                    id: 1,
                    label: 'reorder',
                    analytics: {
                        [AnalyticsEvents.SCREEN_VIEW]: () => ({
                            screen_name: !!this.state.lastOrder ? AnalyticsScreenEventNames.MY_VUSE_REORDER : AnalyticsScreenEventNames.NO_PAST_ORDERS
                        })
                    }
                },
                {
                    id: 2,
                    label: 'subscribe',
                    analytics: {
                        [AnalyticsEvents.SCREEN_VIEW]: () => ({
                            screen_name: AnalyticsScreenEventNames.SUBSCRIPTIONS
                        })
                    }
                },
            ],
            swipeableViewsRef: null,
            isOpenCheckoutPanel: false,
            isOpenBasketPanel: false,
            lastOrder: null,
            loading: false,
            oneTimeToken: null,
        }

        this.updateUserData = this._updateUserData.bind(this);
        this.handleGoToShop = this.handleGoToShop.bind(this);
    }


    componentDidMount() {

        // const {
        //     orders,
        //     userPin,
        //     persistExpiresAt: catalogExpiration,
        //     getCatalog,
        //     getLastOrder
        // } = this.props;

        // //Init last order
        // let lastOrder = this.state.lastOrder;
        // if (orders?.length > 0) {
        //     const resultOrder = orders.reduce((r, o) => r === null ? o : new Date(o.date) > new Date(r.date) ? o : r, null);
        //     if (resultOrder) {
        //         lastOrder = resultOrder;
        //     }
        // }

        // //Load most updated last order
        // getLastOrder(getAccessToken());

        // //this.setState({ activeViewIndex: this.props.activeSection});

        // //if catalog is expired reload it
        // if (
        //     !Tenants.isCanadaDark() && 
        //     catalogExpiration !== null &&
        //     catalogExpiration !== undefined &&
        //     moment().isAfter(catalogExpiration)
        // ) {
        //     const tenantUserId = Commons.generateTenantUserId(userPin);
        //     getCatalog(tenantUserId, getVuseUuid());
        // }

        // //Add initial component config
        // this.setState({
        //     lastOrder,
        //     activeViewIndex: 0,
        //     swipeableViewsRef: React.createRef()
        // }, () => {
        //     this.logAnalyticsEventFromCurrentView(AnalyticsEvents.SCREEN_VIEW);

        //     // console.log('[componentDidMount] this.props.activeSection', this.props.activeSection);
        //     // console.log('[componentDidMount] this.props.activeCategory', this.props.activeCategory);
        //     if (
        //         null !== this.props.activeSection && 
        //         undefined !== this.props.activeSection 
        //     ) {
        //         this.setState({ activeViewIndex: this.props.activeSection });
        //     }

        //     if (
        //         null !== this.props.activeCategory && 
        //         undefined !== this.props.activeCategory 
        //     ) {
        //         this.setState({ activeCategory: this.props.activeCategory });
        //     }
        // });

        if (isSupportedService(servicesConstants.SHOP_OTP)) {
            shopServices.getOneTimeToken().then((tokenResponse)=> {
                this.setState({
                    oneTimeToken: tokenResponse?.data?.oneTimeAuthToken || null
                });
            });
        } else {
            let analyticsToken = CheckoutHelper.getANewAnalyticsToken();
        
            let shopUrl = getUrl(webviewUrl.WEBVIEW_SHOP)
                        + `?utm_medium=myvuseapp`   
                        + `&reqOrigin=myvuse`
                        + `&analyticsToken=${analyticsToken}`;
        
            this.setState({'shopUrl': shopUrl, 'analyticsToken': analyticsToken});
        }

    }


    componentWillUnmount() { }

    componentDidUpdate(prevProps, prevState) {

        // if (!_.isEqual(prevProps.orders, this.props.orders)) {
        //     // console.log('[SHOP] prevProps.orders', prevProps.orders);
        //     // console.log('[SHOP] this.props.orders', this.props.orders);
        //     const resultOrder = this.props.orders.reduce((r, o) => r === null ? o : new Date(o.date) > new Date(r.date) ? o : r, null);
        //     if (resultOrder) {

        //         if (
        //             this.state.lastOrder === null ||
        //             !_.isEqual(new Date(resultOrder.date), new Date(this.state.lastOrder.date))
        //         ) {

        //             const { replaceBasket } = this.props;

        //             this.setState({
        //                 lastOrder: resultOrder
        //             }, () => {
        //                 console.log("resetting basket");
        //                 replaceBasket([]);
        //             });
        //         }

        //     }
        // }

        // if (prevState.lastOrder !== this.state.lastOrder) {
        //     // console.log('[SHOP] prevState.lastOrder', prevState.lastOrder);
        //     // console.log('[SHOP] this.state.lastOrder', this.state.lastOrder);
        // }

        // if (prevProps.activeSection !== this.props.activeSection) {
        //     this.setState({ activeViewIndex: this.props.activeSection });
        // }

        // if (prevProps.activeCategory !== this.props.activeCategory) {
        //     this.setState({ activeCategory: this.props.activeCategory});
        // }
        
    }

    /**
     * Handle orders update after checkout
     */
    _updateUserData() {

        document.body.style.overflow = '';

        const { getLastOrder } = this.props;

        //load updated user data
        this.setState({ loading: true }, () => {
            getLastOrder(getAccessToken(),
                () => {
                    this.setState({ loading: false }, () => {
                        setTimeout(() => {
                            this.setState({ isOpenCheckoutPanel: false });
                        }, 500);
                    });

                }, () => {
                    this.setState({ loading: false }, () => {
                        setTimeout(() => {
                            this.setState({ isOpenCheckoutPanel: false });
                        }, 500);
                    });
                })
        })

    }


    get currentView() {
        return this.state.views.find(view => view.id === this.state.activeViewIndex);
    }

    logAnalyticsEventFromCurrentView(eventName) {
        logAnalyticsEventByCustomEvent(eventName, this.currentView.analytics[eventName]());
    }

    onChangeView(index) {
        this.setState({ activeViewIndex: index }, () => {
            this.logAnalyticsEventFromCurrentView(AnalyticsEvents.SCREEN_VIEW);
        });
    }

    onCheckoutButtonClick() {
        logAnalyticsEventOfBasketVariantList(
            AnalyticsEvents.BEGIN_CHECKOUT,
            this.props.basket,
            this.state.isOpenBasketPanel,
            {
                cart_token_event: CheckoutHelper.getANewAnalyticsToken()
            }
        )
        this.setState({ isOpenCheckoutPanel: true });
    }

    handleNavigationBetweenTabs(activeIndex, activeCategory) {
        console.log('[SHOP] [handleNavigationBetweenTabs] activeCategory', activeCategory);
        window.scrollTo(0, 0);
        this.setState({
            activeViewIndex: activeIndex,
            ...(activeCategory !== null && activeCategory !== undefined && { activeCategory: activeCategory })
        });
    }

    handleGoToShop() {
        const analyticsToken = CheckoutHelper.getANewAnalyticsToken();
        const shopUrl = getUrl(webviewUrl.WEBVIEW_SHOP);

        logAnalyticsEvent(AnalyticsEvents.OPEN_SHOP, {
            cart_token_event: `myvuse_${analyticsToken}`
        });

        window.open(shopUrl + convertObjectToQueryString({
            "utm_medium": "myvuseapp",
            ...(this.state.oneTimeToken && {
                token: this.state.oneTimeToken
            }),
            reqOrigin: "myvuse",
            analyticsToken: `myvuse_${analyticsToken}`
        }), "_blank");
    }
    
    render() {

        const {
            activeViewIndex,
            views,
            swipeableViewsRef,
            isOpenCheckoutPanel,
            isOpenBasketPanel,
            loading,
            lastOrder,
            activeCategory,
            oneTimeToken
        } = this.state;

        const {
            basket,
            navigateToVuseWorld,
            showLocator
        } = this.props;

        // const isShownBasket = activeViewIndex < 2 && basket.length > 0;

        const healthWarningHeight = Tenants.isGlobal() ? "0vh" : "20vh";

        return (
            <div className={cx("dashboard-page shop-wrapper", (getCountry() || "").toLowerCase())} style={{
                height: `calc(100vh - ${healthWarningHeight} - ${this.context?.navbarHeight || 0}px)`,
                marginTop: `-${this.context?.headerHeight || 0}px`,
                backgroundImage: `linear-gradient(180deg, transparent 0%, rgba(13, 27, 35, 0.7) 100%), url(${getAEMAssetsUrl()}${this.dictionary.SHOP_NW_BG_DYN?.desktop?.src})`
            }}>
                <div className='dashboard-page-conten d-flex h-100 flex-column mx-0'>
                    <div className='shop-wrapper-copy' style={{paddingTop: `${this.context.headerHeight || 0}px`}}>
                        <h3>
                            {this.dictionary.SHOP_NW_TITLE_DYN ?? 'EXPLORE VUSE.COM'}
                        </h3>
                        <span>
                            {this.dictionary.SHOP_NW_BODY_DYN ?? 'Discover a world of flavours, products and services. Personalize your Vuse vaping experience by visiting vuse.com.'}
                        </span>
                    </div>
                    <div className="page-bottom-cta">
                        <div className="d-grid gap-2">
                            <button className="btn btn-primary text-uppercase d-flex justify-content-center align-items-center" 
                                onClick={this.handleGoToShop}
                                disabled={isSupportedService(servicesConstants.SHOP_OTP) && isNil(oneTimeToken)}
                            >
                                {this.dictionary.SHOP_NW_BTN_DYN ?? 'DISCOVER'} <img src={`${getAEMAssetsUrl()}${this.dictionary.SHOP_NW_BTN_ICON_DYN?.desktop?.src}`} alt="" style={{marginLeft: '0.9375rem'}}/>
                            </button>
                            {showLocator &&
                            <button className="btn btn-outline-secondary text-uppercase d-flex justify-content-center align-items-center" 
                                onClick={() => history.push(buildURI(routingConstants.STORE_LOCATOR))}
                            >
                                {this.dictionary.SHOP_NW_BTN_FIND_STORE_0_ctaLabel ?? ''}
                            </button>
                            }
                        </div>
                    </div>
                </div>
                {/* <SwipableViews onChangeView={(index) => this.onChangeView(index)}
                    ref={swipeableViewsRef}
                    activeViewIndex={activeViewIndex}
                    views={views}>
                    <div style={{ display: (activeViewIndex === 0) ? 'block' : 'none' }}>
                        <BuyNow onOpenBasketPanel={() => this.setState({ isOpenBasketPanel: true })}
                            onStartCheckout={() => this.setState({ isOpenCheckoutPanel: true })}
                            activeCategory={activeCategory} />
                    </div>

                    <div style={{ display: (activeViewIndex === 1) ? 'block' : 'none' }}>
                        <Reorder lastOrder={lastOrder}
                            activeViewIndex={activeViewIndex}
                            onOpenBasketPanel={() => this.setState({ isOpenBasketPanel: true })}
                            onStartCheckout={() => this.setState({ isOpenCheckoutPanel: true })}
                            navigateToFlavours={() => this.handleNavigationBetweenTabs(0, 0)}
                            navigateToVuseWorld={navigateToVuseWorld}
                        />
                    </div>
                    <div style={{ display: (activeViewIndex === 2) ? 'block' : 'none' }}>
                        <Subscriptions updateSubscriptions={this.updateUserData} />
                    </div>
                </SwipableViews>
                {
                    isShownBasket &&
                    <ShopBasket basket={basket} isOpenPanel={isOpenBasketPanel}
                        onClose={() => this.setState({ isOpenBasketPanel: false })}
                        onCheckout={() => this.onCheckoutButtonClick()} />
                }
                <ShopCheckout isOpen={isOpenCheckoutPanel}
                    onClose={this.updateUserData}
                    basket={basket}
                />

                {loading && <CustomLoaderOverlay />} */}
            </div>

        );
    }
}

Shop.contextType = DashboardContext;

function mapStateToProps(state) {
    return {
        basket: state.shopReducer.basket,
        orders: state.shopReducer.orders,
        persistExpiresAt: state.shopReducer.persistExpiresAt,
        userPin: state.onboardingReducer.userPin
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getLastOrder: (token, successCb, failureCb) => {
            dispatch(shopActions.getLastOrder(token, null, successCb, failureCb));
        },
        replaceBasket: (basket) => {
            dispatch(shopActions.replaceBasket(basket))
        },
        getCatalog: (tenantUserId, uuid, successCb, failureCb) => {
            dispatch(shopActions.getCatalog(tenantUserId, uuid, successCb, failureCb));
        }
    };
}

const connnectedComponent = connect(mapStateToProps, mapDispatchToProps)(Shop);
export { connnectedComponent as Shop };
