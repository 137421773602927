import cx from "classnames";
import React from "react";

export function DevicePanel({
  title,
  children,
  setContentRef,
  setPanelHeaderRef,
  className,
  backgroundImg,
  additionalStyles,
}) {
  const setToParentContentRef = (ref) => {
    if (typeof setContentRef === "function") {
      setContentRef(ref);
    }
  };

  const setToParentHeaderRef = (ref) => {
    if (typeof setPanelHeaderRef === "function") {
      setPanelHeaderRef(ref);
    }
  };

  return (
    <div
      className={cx("bat-slide-bottom", className)}
      style={{
        backgroundImage: `url(${backgroundImg})`,
        ...additionalStyles,
      }}
    >
      <div className="dashboard-carousel" ref={setToParentContentRef}>
        <div className="label-title" ref={setToParentHeaderRef}>
          {title}
        </div>
        {children}
      </div>
    </div>
  );
}
