import cx from "classnames";
import { isNil } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { RemoveDevice } from "../../Views/RemoveDevice";
import { deviceActions, userActions } from "../../_actions";
import {
  CustomSlidingPanel,
  ProgressCircle,
  SortableItem,
  SortableList,
  TutorialTip,
} from "../../_components";
import {
  aemPages,
  AnalyticsEvents,
  crmUserSettings,
  flagStatus,
  imagesConstants,
  routingConstants,
  thingVuseProperties,
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { Commons } from "../../_helpers/commons";
import { isDeviceConnected, handleDeviceSelection } from "../../_helpers/device";
import { getDeviceSwitcherImg } from "../../_helpers/device/assets";
import { Utils } from "../../_helpers/graphic/utils";
import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";
import { Tenants } from "../../_helpers/tenants";
import { useThrottle } from "../../_hooks/throttle.hook";
import { getSupportedDevices } from "../../_actions/appConfig.actions";

const ReorderIcon = () => (
  <div className="device-reorder-icon">
    {Array(8)
      .fill()
      .map((_, i) => (
        <span key={i} />
      ))}
  </div>
);

function DeviceSwitcher({
  devices,
  currentDevice,
  setDevices,
  setSelected,
  unpairDevice,
  customClass,
  title,
  onClose,
}) {
  const [items, setItems] = useState(
    devices.map((device) => ({
      id: device.serialNumber,
      device: device,
    }))
  );

  const [dictionary, setDictionary] = useState({});
  const [index, setIndex] = useState(0);
  const [isDragStart, setIsDragStart] = useState(false);
  const [activeDragIndex, setActiveDragIndex] = useState(0);
  const [showRemoveDevice, setShowRemoveDevice] = useState(false);
  const [reorderTutorialAccepted, setReorderTutorialAccepted] = useState(false);
  const [maxLimitPopupIsOpen, setMaxLimitPopupIsOpen] = useState(false);
  const [deviceToRemove, setDeviceToRemove] = useState(null);
  const [availableDevices] = useState(getSupportedDevices());

  const itemsRef = useRef([]);
  const listRef = useRef(null);
  const controlRef = useRef(null);

  useEffect(() => {
    const aem = new AEMHelper();

    logAnalyticsEvent(AnalyticsEvents.OPEN_DEVICE_SWITCHER);

    setDictionary({
      ...aem.getDictionary(aemPages.HOMEPAGE, {
        HOME_HOLD_AND_DRAG_LABEL: propertyTextDefaultEmpty,
        HOME_REMOVE_LABEL: propertyTextDefaultEmpty
      }),
      ...aem.getDictionary(aemPages.MAX_DEVICES_PAIRED, {
        MAX_DEVICES_PAIRED_HEADER: propertyHeadingDefaultEmpty,
        MAX_DEVICES_PAIRED_CONTENT: propertyTextDefaultEmpty,
        MAX_DEVICES_PAIRED_BODY: propertyTextDefaultEmpty,
        MAX_DEVICES_BTN_MANAGE_DEVICES: propertyCtaItemsDefaultEmpty,
        MAX_DEVICES_BTN_GO_BACK: propertyCtaItemsDefaultEmpty,
      }),
      ...aem.getDictionary(aemPages.MANAGE_DEVICES, {
        MANAGE_DEVICES_CONNECTED_LABEL: propertyTextDefaultEmpty,
        MANAGE_DEVICES_DISCONNECTED_LABEL: propertyTextDefaultEmpty,
      }),
    });

    if (listRef.current) {
      listRef.current.addEventListener("scroll", handleScroll);
    }

    setReorderTutorialAccepted(
      Commons.getCustomerProperty(
        thingVuseProperties.MULTI_DEV_TUT_REARRANGE_CARD
      ) === flagStatus.ACCEPTED
    );

    document.body.classList.add("device-switcher-page");

    return () => {
      if (listRef.current) {
        listRef.current.removeEventListener("scroll", handleScroll);
      }

      document.body.classList.remove("device-switcher-page");
    };
  }, []);

  const goToDeviceSelection = () => {
    if (availableDevices.length === 1){
      handleDeviceSelection(availableDevices[0].profile);
      history.push(buildURI(routingConstants.PAIR_DEVICE), {type: 'pop'});
    } else {
      history.push(buildURI(routingConstants.SELECT_DEVICE));
    }
  }

  const elementsOverlap = (el1, el2) => {
    if (isNil(el1) || isNil(el2)) {
      return false;
    }

    const domRect1 = el1.getBoundingClientRect();
    const domRect2 = el2.getBoundingClientRect();

    return !(
      domRect1.top > domRect2.bottom ||
      domRect1.right < domRect2.left ||
      domRect1.bottom < domRect2.top ||
      domRect1.left > domRect2.right
    );
  };

  const handleScroll = useThrottle(() => {
    /**
     * When user scrolls the container, we need to know what element is the current one, and its index.
     * To get it, we check for every element, if it overlaps the control square, its index will be the
     * the current active index.
     */
    items.forEach((_, idx) => {
      if (elementsOverlap(controlRef.current, itemsRef.current[idx])) {
        setIndex(idx);
      }
    });
  }, 500);

  const handleDragStart = (activeIndex) => {
    logAnalyticsEvent(AnalyticsEvents.HOLD_AND_DRAG_TO_REARRANGE_DEVICES);
    !reorderTutorialAccepted &&
      Commons.acceptOptIn(
        thingVuseProperties.MULTI_DEV_TUT_REARRANGE_CARD
      ).then(() => setReorderTutorialAccepted(true));
    setActiveDragIndex(activeIndex);
    setIsDragStart(true);
  };

  const handleDragEnd = () => {
    // window.setTimeout(() => setIsDragStart(false), 150);
    // setSelected(items[index].device);
    setIsDragStart(false);
  };

  const handleReorder = (items, activeIndex) => {
    logAnalyticsEvent(AnalyticsEvents.SORT_DEVICE);
    setIndex(activeIndex);

    let width = 0; //how much pixels container must scroll from left (starting from zero)

    /**
     * For each element, in previous position compared to activeIndex element, we get its width.
     * Widths are summed and used to say, to scroll container, to scroll from left for <width> pixels,
     * to have activeIndex element to be always the centered and visible one.
     * This because, if dnd doesn't causes scroll, the element centered would be the one in the last position, not in
     * activeIndex position.
     */
    for (var i = 0; i < activeIndex; i++) {
      width += itemsRef.current[i]?.getBoundingClientRect()?.width;
    }

    listRef.current.scrollLeft = width;

    setItems(items);
    // setSelected(items[0].device);
    setDevices(items.map((item) => item.device));
  };

  const handleRemoveDevice = (ev, index) => {
    ev.stopPropagation();
    logAnalyticsEvent(AnalyticsEvents.REMOVE_DEVICE_CARD);
    setDeviceToRemove({ ...devices[index] });
    setShowRemoveDevice(true);
  };

  const handlePairDevice = () => {
    if (items.length >= Tenants.getMaxDevicesLimit()) {
      //TODO: fixare massimo limite!!
      setMaxLimitPopupIsOpen(true);
    } else {
      logAnalyticsEvent(AnalyticsEvents.PAIR_DEVICE_CARD);
      // history.push({
      //   pathname: buildURI(routingConstants.PAIR_DEVICE),
      // });
      goToDeviceSelection();
    }
  };

  const handleManageDevices = () => {
    setMaxLimitPopupIsOpen(false);
    history.push({
      pathname: buildURI(routingConstants.MANAGE_DEVICES),
    });
  };

  /**
   * Method to calculate margin for first and last slide.
   * @param {*} index of slide to get margin calculated
   * @returns style object
   */
  const getMargin = (index) => {
    const margin = (window.innerWidth - 176) / 2;

    return index === 0
      ? { marginLeft: `${margin}px` }
      : index === items.length - 1
      ? { marginRight: `${margin}px` }
      : undefined;
  };

  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleEndRemoveDevice = () => {
    setShowRemoveDevice(false);

    setTimeout(() => {
      if (devices?.length > 1) {
        setItems(
          devices.map((device) => ({
            id: device.serialNumber,
            device: device,
          }))
        );
      } else {
        handleClose();
      }
    }, 0);
  };

  return (
    items && (
      <>
        <div className="device-switcher-backdrop" />
        <div className={cx("bat-slide-bottom", "device-switcher", customClass)}>
          <div className="label-title">
            {title}
            <span className="device-switcher-icon-close" onClick={handleClose}>
              <i className="bat-icon-close" />
            </span>
          </div>

          <div
            className="control-square"
            style={{
              width: "1.25rem",
              height: "1.25rem",
              // backgroundColor: 'red',
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
            ref={controlRef}
          ></div>

          <div className="dashboard-carousel">
            <SortableList
              ref={listRef}
              items={items}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onReorder={handleReorder}
            >
              {items.map((item, i) => {
                const isConnected = isDeviceConnected(item.device);
                const batteryPerc = item.device?.batteryInfo?.chargeLevel ?? 0;
                const cloudPower = item.device?.cloudInfo?.powerLevel ?? 10;
                const batteryIcon = Utils.getBatteryIconFromPercentage(
                  item.device?.batteryInfo,
                  cloudPower
                );
                const batteryColor = Utils.getBatteryCircleColorFromPercentage(
                  item.device?.batteryInfo,
                  cloudPower
                );
                const bg = item.device
                  ? `SWITCHER_PANEL_${item.device?.deviceColor}_${
                      Tenants.isCanadaDark()
                        ? "DARK"
                        : Tenants.isCanadaLight()
                        ? "LIGHT"
                        : "GLOBAL"
                    }`
                  : null;

                return (
                  <SortableItem
                    key={i}
                    id={item.id}
                    customStyle={{
                      ...getMargin(i),
                      ...(isDragStart && { scrollSnapAlign: "none" }),
                      ...(!reorderTutorialAccepted && {
                        paddingBottom: "47px",
                      }),
                    }}
                  >
                    <div
                      ref={(el) => (itemsRef.current[i] = el)}
                      className={cx("device-card", {
                        "selected-slide": isDragStart && i === activeDragIndex,
                      })}
                      style={{
                        backgroundImage: `url(${getDeviceSwitcherImg(item?.device)})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <div className="device-card-header">
                        <button
                          className="btn btn-remove-device"
                          type="button"
                          onClick={(ev) => handleRemoveDevice(ev, i)}
                        >
                          {dictionary.HOME_REMOVE_LABEL}
                        </button>
                      </div>
                      <div className="device-card-body">
                        <div className="device-card-name">
                          {item.device?.deviceCustomName}
                        </div>
                        <div className="device-card-info">
                          <span
                            className={cx("device-card-connection-status", {
                              connected: isConnected,
                            })}
                          >
                            {isConnected
                              ? dictionary.MANAGE_DEVICES_CONNECTED_LABEL
                              : dictionary.MANAGE_DEVICES_DISCONNECTED_LABEL}
                          </span>
                          <span
                            className={cx("device-card-battery-status", {
                              "dark-market": Tenants.isCanadaDark(),
                            })}
                          >
                            {Tenants.isCanadaDark() && (
                              <ProgressCircle
                                strokeWidth={5}
                                size={37}
                                trackColor="rgba(255, 255, 255, 0.25)"
                                indicatorColor={batteryColor}
                                value={batteryPerc}
                              />
                            )}
                            <i
                              className={batteryIcon}
                              style={{
                                fontSize: "18px",
                              }}
                            />
                            <span className="device-card-battery-status-label">
                              {isConnected ? batteryPerc : "--"}%
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <ReorderIcon />
                  </SortableItem>
                );
              })}
            </SortableList>

            <div
              className="device-switcher-footer"
              style={{
                ...(!reorderTutorialAccepted && {
                  background:
                    "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 43.75%)",
                  height: "8.375rem",
                  bottom: 0,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "end",
                  paddingBottom: "1.25rem",
                  position: "absolute",
                  width: "100%",
                }),
              }}
            >
              <div
                className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets"
                style={{ ...(!reorderTutorialAccepted && { display: "none" }) }}
              >
                {items.map((_, idx) => {
                  return (
                    <span
                      key={`pagination-bullet[${idx}]`}
                      className={cx("swiper-pagination-bullet", {
                        "swiper-pagination-bullet-active": index === idx,
                      })}
                    ></span>
                  );
                })}
              </div>

              <button
                className="btn btn-pair-device"
                style={{ ...(!reorderTutorialAccepted && { display: "none" }) }}
                type="button"
                onClick={handlePairDevice}
              >
                <i className="bat-icon-plus" />
              </button>
              <TutorialTip
                className="device-switcher-tip"
                show={!reorderTutorialAccepted}
                content={dictionary.HOME_HOLD_AND_DRAG_LABEL}
                onTimeout={() => {
                  !reorderTutorialAccepted &&
                    Commons.acceptOptIn(
                      thingVuseProperties.MULTI_DEV_TUT_REARRANGE_CARD
                    ).then(() => {
                      logAnalyticsEvent(
                        AnalyticsEvents.HOLD_AND_DRAG_TO_REARRANGE_DEVICES
                      );
                      setReorderTutorialAccepted(true);
                    });
                }}
              />
            </div>
          </div>
        </div>

        <RemoveDevice
          device={deviceToRemove}
          devices={this.props.devices}
          isOpen={showRemoveDevice}
          handleProcessEnd={handleEndRemoveDevice}
        />

        <CustomSlidingPanel
          className="onboarding-panel mid-height-panel"
          overlayClassName="sliding-common-wrapper device-switcher-max-limit-popup"
          title={dictionary.MAX_DEVICES_PAIRED_HEADER}
          from="bottom"
          isOpen={maxLimitPopupIsOpen}
        >
          <div className="d-grid">
            <p className="mt-4 mb-4">{dictionary.MAX_DEVICES_PAIRED_CONTENT}</p>
            <p className="mb-4">{dictionary.MAX_DEVICES_PAIRED_BODY}</p>
            <button className="btn btn-primary" onClick={handleManageDevices}>
              {dictionary.MAX_DEVICES_BTN_MANAGE_DEVICES_0_ctaLabel}
            </button>
            <button
              className="btn btn btn-outline-secondary mt-3"
              onClick={() => setMaxLimitPopupIsOpen(false)}
            >
              {dictionary.MAX_DEVICES_BTN_GO_BACK_0_ctaLabel}
            </button>
          </div>
        </CustomSlidingPanel>
      </>
    )
  );
}

const mapStateToProps = (state) => ({
  devices: state?.deviceReducer?.devices,
});

const mapDispatchToProps = (dispatch) => ({
  setDevices: (devices, callBack) =>
    dispatch(deviceActions.setDevices(devices, callBack)),
  setSelected: (device) => dispatch(deviceActions.setSelected(device)),
  unpairDevice: () =>
    dispatch(userActions.setUserSettings(crmUserSettings.DEVICEPAIR, "false")),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSwitcher);
