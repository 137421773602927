import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { history } from '../../_helpers/history';
import { useDispatch, useSelector } from 'react-redux';
import { commonsActions } from '../../_actions';
import isNil from 'lodash/isNil';
// import { useLongPress } from 'use-long-press';
import { Commons } from '../../_helpers/commons';

export function Debug(props) {


    const [logStrings, setLogStrings] = useState([]);
    const [logCat, setLogCat] = useState('debug');
    const [resetMethod, setResetMethod] = useState(null);
    const [selectedText, setSelectedText] = useState('');


    const dispatch = useDispatch();
    const _debug = useSelector((state) => state.commonsReducer?.debug);
    const _debugAnalytics = useSelector((state) => state.commonsReducer?.debugAnalytics);

    const logsCat = useMemo(() => {
        return {
            debug: { logs: _debug, resetMethod: commonsActions.resetDebugCommons() },
            analytics: { logs: _debugAnalytics, resetMethod: commonsActions.resetDebugAnalytics() },
        }
    }, [_debugAnalytics, _debug]);

    const _logStrings = useMemo(() => logStrings.map(({time, log}) => {
        const stringDate = !!time && moment(time).isValid()
            ? moment(time).format("DD/MM/YY HH:mm:ss:SSS")
            : 'No time';
        return [stringDate, log];
    }), [logStrings]);

    useEffect(() => {

        if(window?.location?.href){
            let splittedPath = window.location.href.split('/');
            if(!isNil(splittedPath[4])){
                setLogCat(splittedPath[4]);
            }
        }

    }, [])

    useEffect(() => {

        let _logs = logsCat['debug'].logs;
        let _resetMethod = logsCat['debug'].resetMethod;

        if(
            !isNil(logCat) && 
            !isNil(logsCat[logCat])
        ){

            _logs = logsCat[logCat].logs;
            _resetMethod = logsCat[logCat].resetMethod;
            
        }  

        setLogStrings(_logs);
        setResetMethod(_resetMethod);

    }, [logCat, _debug, _debugAnalytics]);
    
    const copyToClipboard = (selected = false) => {
        let copyOnClipboard = `LOGS of the user\n${logStrings.reduce((acc, log) => `${acc}\n${log}`, '')}`;

        if(selected) {
            copyOnClipboard = selectedText;
        }

        if (typeof navigator?.clipboard?.writeText === 'function') {
            navigator.clipboard.writeText(copyOnClipboard).then(function() {
                alert("Copied to clipboard successfully!");
            }, function() {
                alert("Unable to write to clipboard. :-(");
            });
        }
    }

    // const bind = useLongPress((e) => {
    //     if(!isNil(e?.target)){
    //         let node = e.target;
    //         let children = e.target.querySelectorAll('div');
    //         if(Commons.isValidArray(children)){
    //             node = children[0];
    //         } 
    
    //         setSelectedText(node?.innerHTML);
    //         alert("Text saved for copy!");
    //     }
        
    // }, {
    //     captureEvent: true
    // });

    return (
        <div style={{
            height: '100vh',
            backgroundColor: 'white',
            overflowY: 'scroll'
        }}>
            <div className='m-2 d-flex justify-content-start'>
                <button className='btn btn-success' onClick={copyToClipboard}>Copy All</button>
                <button className='btn btn-success ms-2' disabled={!(selectedText && selectedText !== '')} onClick={() => copyToClipboard(true)}>Copy Selected</button>
                <button className='btn btn-secondary ms-2' onClick={() => resetMethod && dispatch(resetMethod)}>Reset Logs</button>
                <button className='btn btn-light ms-2' onClick={() => history.goBack()}>Back</button>
            </div>

            {_logStrings.slice().reverse().map((logString, index) =>
                <div key={`log-${index}`} 
                className=' alert-secondary text-dark p-2' 
                style={{ 
                    lineBreak: 'anywhere',
                    margin: '0.4rem',
                    borderRadius: '0.6rem'
                }}
                >
                    <div
                    style={{
                            fontSize: '0.8rem',
                            fontWeight: '500',
                            color: 'gray'
                        }}
                    >{logString[0]}</div>

                    <div
                        style={{
                            fontSize: '0.9rem',
                        }}
                    >{logString[1]}</div>
                </div>
            )}
        </div>

    );

}
