import React from "react";
import { AnalyticsEvents, AnalyticsTargetEventsNames, routingConstants } from "../../_constants";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { PAIRING_STEP_NAMES } from "../../_helpers/device";
import { PageSelectorCTA } from "../PageSelectorCTA";

/**
 * Pairing second step
 * @param {*} props
 * @returns
 */
export const PairingFailure = (props) => {
  const { dictionary } = props;

  const goBack = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.TRY_AGAIN})
    if (typeof props.goToNamedStep === "function") {
      window.location.href = routingConstants.PAIR_DEVICE;
      // workaround to disconnect all active bluetooth connections
      window.location.reload(true);
    }
  };

  return (
    <React.Fragment>
      <div className="failure-support-wrapper">
        <div className="container">
          <div className="row title-row mt-3">
            <div className="col-12">{dictionary.PAIRING_EPOD_NOT_FOUND_TITLE}</div>
          </div>
          <div className="row subtitle-row">
            <div className="col-12">
              {dictionary.PAIRING_EPOD_NOT_FOUND_SUBTITLE}
            </div>
          </div>
          <div className="row support-row">
            <div className="col-12">
              {dictionary.PAIRING_EPOD_NOT_FOUND_LIST_FIRST}
            </div>
          </div>
          <div className="row support-row">
            <div className="col-12">
                {dictionary.PAIRING_LOCATION_ON_ALERT}
            </div>
          </div>
          <div className="row support-row">
            <div className="col-12">
              {dictionary.PAIRING_EPOD_NOT_FOUND_LIST_SECOND}
            </div>
          </div>
          <div className="row support-row">
            <div className="col-12">
              {dictionary.PAIRING_EPOD_NOT_FOUND_LIST_THIRD}
            </div>
          </div>
          <div className="row support-row">
            <div className="col-12">
              {dictionary.PAIRING_EPOD_NOT_FOUND_LIST_FOURTH}
            </div>
          </div>
          <div className="row support-row">
            <div className="col-12">
              {dictionary.PAIRING_EPOD_NOT_FOUND_LIST_FIFTH}
            </div>
          </div>
          <div className="row support-row">
            <div className="col-12">
              {dictionary.PAIRING_EPOD_NOT_FOUND_LIST_SIXTH}
            </div>
          </div>
          {/* <div className="row support-row">
            <div className="col-12">
              {dictionary.PAIRING_EPOD_NOT_FOUND_LIST_SEVENTH}
            </div>
          </div> */}
          <div className="row support-row">
            <div className="col-12">
              {dictionary.PAIRING_EPOD_NOT_FOUND_LIST_EIGHTH}
            </div>
          </div>
          <div className="row support-row">
            <div className="col-12">
              {dictionary.PAIRING_EPOD_NOT_FOUND_LIST_SEVENTH}
            </div>
          </div>
          <div className="row support-row">
            <div className="col-12">
              {dictionary.PAIRING_EPOD_NOT_FOUND_LIST_EIGHTH}
            </div>
          </div>
          <div className="row support-row">
            <div className="col-12">
              {dictionary.PAIRING_EPOD_NOT_FOUND_LIST_SEVENTH}
            </div>
          </div>
          <div className="row support-row">
            <div className="col-12">
              {dictionary.PAIRING_EPOD_NOT_FOUND_LIST_EIGHTH}
            </div>
          </div>
          <div className="row support-row">
            <div className="col-12">
              {dictionary.PAIRING_EPOD_NOT_FOUND_LIST_NINTH}
            </div>
          </div>
        </div>
      </div>

      <PageSelectorCTA sticky={false} className="pt-4"> 
        <div className="d-grid">
          <button
            className="btn btn-primary text-uppercase"
            type="button"
            onClick={goBack}
          >
            {dictionary.PAIRING_EPOD_NOT_FOUND_BTN_CONTINUE_0_ctaLabel}
          </button>
        </div>
      </PageSelectorCTA>

      <div className="page-linear-gradient-bg"></div>
    </React.Fragment>
  );
};
