import React from "react";
import { connect } from "react-redux";
import {
  CustomSlidingPanel,
  PageSelectorCTA,
  SignupForm,
  SignUpForm,
} from "../../_components";
import {
  routingConstants,
  aemPages,
  AnalyticsEvents,
  servicesConstants,
  imagesConstants,
  AnalyticsTargetEventsNames,
  AnalyticsScreenEventNames
} from "../../_constants";
import { buildURI } from "../../_helpers/navigation";
import environmentConstants from '../../_constants/environment/environment.constants';
import { setRegistration, userActions } from "../../_actions";
import { Tenants } from "../../_helpers/tenants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import {
  getSupportedServices,
  isSupportedService,
} from "../../_actions/appConfig.actions";
import { Commons } from "../../_helpers/commons";
import { isEmpty, isNil } from "lodash";
import moment from "moment";

class Signup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEmailValid: null,
      modalAvOpen: true,
      userEmail: "",
      isVerifyAccountPanelOpen: false,
      isResendEmail: false,
      loading: false
    };

    this.handleSubmit = this._handleSubmit.bind(this);
    this.toggleResendEmail = this.toggleResendEmail.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
    this.goToLogin = this.goToLogin.bind(this);

    this.aem = new AEMHelper();

    this.registrationPage = this.aem.getPage(aemPages.REGISTRATION);
    this.registrationDetailPage = this.aem.getPage(
      aemPages.REGISTRATION_DETAIL
    );
    this.registrationPwdPage = this.aem.getPage(aemPages.REGISTRATION_PWD);
    this.registrationDetailDobPage = this.aem.getPage(
      aemPages.REGISTRATION_DETAIL_DOB
    );
    this.registrationDetailAddrPage = this.aem.getPage(
      aemPages.REGISTRATION_DETAIL_ADDR
    );
    this.registrationDetailTacPage = this.aem.getPage(
      aemPages.REGISTRATION_DETAIL_TAC
    );

    this.dictionary = this.aem.getDictionary(aemPages.VERIFY_ACCOUNT, {
      REGISTRATION_VERIFY_ACCOUNT_HEADER: propertyHeadingDefaultEmpty,
      REGISTRATION_EMAIL_CONFIRMATION_CONTENT: propertyTextDefaultEmpty,
      REGISTRATION_BTN_EMAIL_NOT_RECEIVED: propertyTextDefaultEmpty,
      REGISTRATION_BTN_CONTINUE_LOGIN: propertyCtaItemsDefaultEmpty,
      REGISTRATION_VERIFY_ACCOUNT_CONTENT: propertyTextDefaultEmpty,
      REGISTRATION_BTN_RESEND_EMAIL: propertyCtaItemsDefaultEmpty,
    });
  }

  componentDidMount() {
    document.body.classList.add("signup");
  }

  componentWillUnmount() {
    document.body.classList.remove("signup");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingReducer !== this.props.loadingReducer) {
      if (this.props.loadingReducer) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    }
  }

  _handleSubmit(formData) {
    this.setState({
      loading: true
    });

    const { register, history, setRegistrationProfileData, myVuseCreate } =
      this.props;

    // push to reducer registration form formData
    setRegistrationProfileData({ ...formData, address: null });

    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CREATE_ACCOUNT});

    // In signup, if we have this field retrieved by branch.io/nuviu, we have to pass it
    const utm_data = localStorage.getItem('utm_data')
    if(!isNil(utm_data) && !isEmpty(utm_data)){
      const obj = JSON.parse(utm_data);
      obj['timestamp'] = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
      formData = {...formData,
        "utmData": JSON.stringify(obj)
      }
      localStorage.removeItem('utm_data')
    }

    /**
     * handle test scenario for provinces without yoti
     */
    if((Commons.isLocalEnvironment() || (process.env.REACT_APP_ENV_NAME !== environmentConstants.PRODUCTION))
      && Tenants.isCanadaDark()){
      formData = {
        ...formData,
        userisAgeVerified: true,
        verificationMethod:'AGE_ESTIMATION'
      }
    }

    myVuseCreate(
      formData,
      (response) => {
        if (response?.method === "idVerification") {
          if ((response?.error?.equifax?.success ?? false) === false) {
            logAnalyticsEvent(AnalyticsEvents.APP_ERROR);
            history.push({
              pathname: buildURI(routingConstants.SIGNUP_FAILED),
            });
            return;
          }
        }

        if (Object.keys(response).length === 0) {
          logAnalyticsEvent(AnalyticsEvents.APP_ERROR);
          history.push({ pathname: buildURI(routingConstants.SIGNUP_FAILED) });

          return
        }

        logAnalyticsEvent(AnalyticsEvents.COMPLETE_HAGV);
        logAnalyticsEvent(AnalyticsEvents.CREATE_ACCOUNT_SUCCESS);

        console.log("[register] response", response);

        setRegistration({})

        if (
          !isSupportedService(
            servicesConstants.ONBOARDING_EMAIL_REG_VERIFICATION
          )
        ) {
          history.push({
            pathname: buildURI(Tenants.getAfterRegistrationView(response)),
            state: {
              isAfterRegistration: true,
              userEmail: formData.email,
            },
          });
        } else {
          this.setState({
            userEmail: formData.email,
            isVerifyAccountPanelOpen: true
          });
          logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.VERIFY_EMAIL})
        }

        this.setState({
          loading: false
        });
      },
      () => {
        logAnalyticsEvent(AnalyticsEvents.APP_ERROR);
        history.push({ pathname: buildURI(routingConstants.SIGNUP_FAILED) });
        
        this.setState({
          loading: false
        });
      }
    );
  }

  hideVerifyAccountPanel() {
    this.setState({
      isVerifyAccountPanelOpen: false,
    });
  }

  toggleResendEmail() {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.EMAIL_NOT_RECIEVED})
    this.setState({
      isResendEmail: !this.state.isResendEmail,
    });
  }

  resendEmail() {
    this.props.resendActivationLink(
      this.state.userEmail,
      () => {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.RESEND_EMAIL})
        this.toggleResendEmail();
      },
      (err) => {
        console.debug(err);
      }
    );
  }

  goToLogin() {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NEXT})
    this.props.history.push({
      pathname: buildURI(routingConstants.LOGIN),
    });
  }

  render() {
    const { loadingReducer } = this.props;
    const { isVerifyAccountPanelOpen, isResendEmail, userEmail } = this.state;
    const isLoading = loadingReducer || this.state.loading;

    return (
      <React.Fragment>
        {isLoading && <div className="loader-overlay"></div>}
        {/* Remember: toggle "loader-overlay" with the style "overflow:hidden" to "<body>" */}
        <div className="page w-100">
          <SignupForm isLoading={isLoading} onSubmit={this.handleSubmit}/>
          <CustomSlidingPanel
            isOpen={isVerifyAccountPanelOpen}
            overlayClassName="sliding-common-wrapper"
            className="onboarding-panel mid-height-panel"
            title={this.dictionary.REGISTRATION_VERIFY_ACCOUNT_HEADER}
          >
            <div className="h-100 d-flex flex-column">
              <div className="sliding-panel-mid">
                <div className="sliding-panel-mid-icon">
                  <img src={imagesConstants.NEW_EMAIL} alt="email icon" />
                </div>
                <div className="sliding-panel-mid-info">
                  {isResendEmail
                    ? this.dictionary.REGISTRATION_VERIFY_ACCOUNT_CONTENT
                    : this.dictionary.REGISTRATION_EMAIL_CONFIRMATION_CONTENT}
                </div>
                <div className="sliding-panel-mid-value d-flex align-items-center justify-content-center">
                  <span className="text-truncate">{userEmail}</span>
                </div>
              </div>
              <PageSelectorCTA sticky={false} ctaUrl={true} className="mt-auto">
                <div className="d-grid gap-2">
                  <button
                    className="btn btn-primary text-uppercase"
                    type="button"
                    onClick={isResendEmail ? this.resendEmail : this.goToLogin}
                  >
                    {isResendEmail
                      ? this.dictionary.REGISTRATION_BTN_RESEND_EMAIL_0_ctaLabel
                      : this.dictionary
                          .REGISTRATION_BTN_CONTINUE_LOGIN_0_ctaLabel}
                  </button>
                </div>
                <div className="page-selector-cta-url">
                  {!isResendEmail && (
                    <span className="url-text" onClick={this.toggleResendEmail}>
                      {this.dictionary.REGISTRATION_BTN_EMAIL_NOT_RECEIVED}
                    </span>
                  )}
                </div>
              </PageSelectorCTA>
            </div>
          </CustomSlidingPanel>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    registrationReducer: { registrationProfileData, loading: loadingReducer },
  } = state;

  return {
    registrationProfileData,
    loadingReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setRegistrationProfileData: (formData) => {
      dispatch(userActions.setRegistrationProfileData(formData));
    },
    register: (formData, successCb, failureCb) => {
      dispatch(userActions.register(formData, successCb, failureCb));
    },
    checkEmail: (formData, successCb, failureCb) => {
      dispatch(userActions.checkEmail(formData, successCb, failureCb));
    },
    myVuseCreate: (formData, successCb, failureCb) =>
      dispatch(userActions.myVuseCreate(formData, successCb, failureCb)),
    resendActivationLink: (email, successCb, failureCb) =>
      dispatch(userActions.resendActivationLink(email, successCb, failureCb)),
  };
}

const connnectedSignup = connect(mapStateToProps, mapDispatchToProps)(Signup);
export { connnectedSignup as Signup };
