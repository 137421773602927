import { getAccessToken, getCustomerId } from "../../_actions";
import { getTenantId } from "../../_actions/appConfig.actions";
import { store } from "../../_store";

const IoTPlatformUtils = {
    createIoTPlatformHeaders(tenantUserId) {
        return {
            'Authorization': getAccessToken(),
            "Content-Type": "application/json",
            "X-user-os": "IOS",
            "x-pin" : store.getState().onboardingReducer.userPin,
            "x-customer-id": getCustomerId(),      
            'X-tenant-id': getTenantId(),
            'X-tenant-user-id': tenantUserId,
        }
    },
}

export default IoTPlatformUtils;