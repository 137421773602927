import { useCallback } from "react";
import _ from "lodash";

export const useThrottle = (fnToThrottle, durationInMs = 200) => {
    if (isNaN(durationInMs)) {
      throw new TypeError("durationInMs for debounce should be a number");
    }
  
    if (fnToThrottle == null) {
      throw new TypeError("fnToDebounce cannot be null");
    }
  
    if (typeof fnToThrottle !== "function") {
      throw new TypeError("fnToDebounce should be a function");
    }
  
    return useCallback(_.throttle(fnToThrottle, durationInMs), [fnToThrottle, durationInMs]);
  };
  