import { isNil } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import {
  getAEMAssetsUrl,
  getLoyaltyOptinUrl,
  isSupportedService,
} from "../../../_actions/appConfig.actions";
import { Header, PageSelectorCTA } from "../../../_components";
import {
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  aemPages,
  routingConstants,
  servicesConstants,
} from "../../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyImageSrcDefaultNull,
  propertyTextDefaultEmpty,
} from "../../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../../_helpers/analytics/logAnalytics";
import { Commons } from "../../../_helpers/commons";
import { debug } from "../../../_helpers/debug";
import { buildURI } from "../../../_helpers/navigation";
import { convertObjectToQueryString } from "../../../_helpers/utils";
import { loyaltyServices, shopServices } from "../../../_services";

const LoyaltyOptin = ({ history }) => {
  const [dictionary, setDictionary] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [oneTimeToken, setOneTimeToken] = useState(null);

  const getOneTimeToken = useCallback(async () => {
    try {
      const res = await shopServices.getOneTimeToken();
      const token = res?.data?.oneTimeAuthToken;

      if (token) {
        setOneTimeToken(token);
      }
    } catch (err) {
      console.debug("getOneTimeToken failure: ", err);
      debug("getOneTimeToken failure: " + JSON.stringify(err));
    }
  }, []);

  const getLoyaltyStatus = useCallback(async () => {
    try {
      const res = await loyaltyServices.getLoyaltyStatus();
      const hasOptedIn = res?.data?.data?.status;

      console.debug("getLoyaltyStatus success: ", res);
      debug("getLoyaltyStatus success: " + JSON.stringify(res));

      let screen_name;
      hasOptedIn ? screen_name = AnalyticsScreenEventNames.LOYALTY : screen_name = AnalyticsScreenEventNames.LOYALTY_NOT_JOINED;
      logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, { screen_name: screen_name });

      //logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
      //  screen_name: AnalyticsScreenEventNames.LOYALTY,
      //  optin: hasOptedIn,
      //}); --> old

      if (hasOptedIn) {
        history.push(buildURI(routingConstants.LOYALTY_DASHBOARD));
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      console.debug("getLoyaltyStatus failure: ", err);
      debug("getLoyaltyStatus failure: " + JSON.stringify(err));
    }
  }, [history]);

  useEffect(() => {
    if (isSupportedService(servicesConstants.MENU_LOYALTY)) {
      const loyaltyStatus = history?.location?.state?.loyaltyStatus;

      console.debug("history loyaltyStatus", loyaltyStatus);
      debug("history loyaltyStatus " + JSON.stringify(loyaltyStatus));

      if (isNil(loyaltyStatus)) {
        getLoyaltyStatus();
      } else if (loyaltyStatus === true) {
        history.push(buildURI(routingConstants.LOYALTY_DASHBOARD));
      } else {
        setIsLoading(false);
      }
    } else {
      history.push(buildURI(routingConstants.DASHBOARD));
    }
  }, [history, getLoyaltyStatus]);

  useEffect(() => {
    if (isSupportedService(servicesConstants.SHOP_OTP)) {
      getOneTimeToken();
    }
  }, [getOneTimeToken]);

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.LOYALTY_OPTIN, {
        LYLT_OPTIN_LOGO: propertyImageSrcDefaultNull,
        LYLT_OPTIN_CONTENT: propertyTextDefaultEmpty,
        LYLT_OPTIN_BTN_VISIT: propertyCtaItemsDefaultEmpty,
        LYLT_OPTIN_BTN_ICON: propertyImageSrcDefaultNull,
      })
    );
  }, []);

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className="page w-100 loyalty loyalty-optin">
      <Header
        rightButton={{
          icon: <span className="bat-icon-close" />,
          onClick: handleBack,
        }}
      />
      <div className="page-wrapper d-flex align-items-center h-100">
        <div className="d-flex flex-column align-items-center justify-content-center loyalty-optin-content">
          <img
            className="loyalty-logo"
            src={`${getAEMAssetsUrl()}${
              dictionary?.LYLT_OPTIN_LOGO?.desktop?.src
            }`}
            alt="Loyalty Opt-In Logo"
          />
          <div className="loyalty-optin-text">
            {dictionary?.LYLT_OPTIN_CONTENT}
          </div>
        </div>
      </div>
      <PageSelectorCTA sticky={false}>
        <div className="d-grid">
          <button
            className="d-flex align-items-center justify-content-center btn btn-primary text-uppercase"
            onClick={() => {
              // logAnalyticsEvent(AnalyticsEvents.LOYALTY_OPTIN_VISIT); --> old
              logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.VUSE_COM});

              window.open(
                getLoyaltyOptinUrl() +
                  convertObjectToQueryString({
                    ...(oneTimeToken && {
                      token: oneTimeToken,
                    }),
                  }),
                "_blank"
              );
            }}
          >
            {dictionary?.LYLT_OPTIN_BTN_VISIT_0_ctaLabel}
            <img
              src={`${getAEMAssetsUrl()}${
                dictionary?.LYLT_OPTIN_BTN_ICON?.desktop?.src
              }`}
              alt="External URL Icon"
              style={{
                height: "1rem",
                marginLeft: "0.938rem",
              }}
            />
          </button>
        </div>
      </PageSelectorCTA>
      <div className="page-linear-gradient-bg" />
    </div>
  );
};

export { LoyaltyOptin };
