import { isFunction } from "lodash";
import React, { useEffect } from "react";
import { AnalyticsEvents, AnalyticsScreenEventNames } from "../../_constants";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { CustomInlineAlert } from "../CustomInlineAlert";
import { CustomSlidingPanel } from "../CustomSlidingPanel";

export function UsageTrackerDisablePanel({
  dictionary,
  isOpen,
  onConfirm,
  onCancel,
}) {
  const disableFeature = async () => {
    if (isFunction(onConfirm)) {
      onConfirm();
    }
  };

  const handleConfirm = () => {
    logAnalyticsEvent(AnalyticsEvents.DISABLE_USAGE_TRACKER);
    disableFeature();
  };

  const handleCancel = () => {
    if (isFunction(onCancel)) {
      onCancel();
    }
  };

  useEffect(() => {
    if (isOpen) {
      logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
        screen_name: AnalyticsScreenEventNames.USAGE_TRACKER_OPT_OUT,
      });
    }
  }, [isOpen]);

  return (
    <CustomSlidingPanel
      from="bottom"
      isOpen={isOpen}
      overlayClassName="sliding-common-wrapper priority usage-tracker-disable-panel"
      className="onboarding-panel mid-height-panel"
      title={
        dictionary.MNG_SECTION_DIS_USAGE_TRACKER_HEADER ||
        "DISABLE USAGE TRACKER"
      }
    >
      <div className="process-data-container">
        <div className="process-data-title">
          <span>
            {dictionary.MNG_SECTION_DIS_USAGE_TRACKER_SUBTITLE ||
              "Are you sure you wish to disable Usage Tracker?"}
          </span>
        </div>
        <CustomInlineAlert isShown={true} alertType="error">
          <div>
            <div className="mb-2">
              <strong>
                {dictionary.MNG_SECTION_DIS_USAGE_TRACKER_ALERT_TITLE ||
                  "IMPORTANT"}
              </strong>
            </div>
            <div>
              {dictionary.MNG_SECTION_DIS_USAGE_TRACKER_ALERT_BODY ||
                "By disabling the Usage Tracker feature, all your historic usage data will be erased from the app and cannot be recovered."}
            </div>
          </div>
        </CustomInlineAlert>
        <div className="dark">
          {dictionary.MNG_SECTION_DIS_USAGE_TRACKER_NOTE_CONTENT ||
            "Note: If you disable this feature, usage data will still be collected and stored on your device only, but you will not be able to access or view this data through the app."}
        </div>
        <div className="process-data-cta d-grid gap-2">
          <button
            className="btn btn-primary text-uppercase"
            onClick={handleConfirm}
          >
            {dictionary.MNG_SECTION_DIS_USAGE_TRACKER_BTN_SURE_0_ctaLabel ||
              "I AM SURE"}
          </button>
          <button
            className="btn btn btn-outline-secondary text-uppercase"
            onClick={handleCancel}
          >
            {dictionary.MNG_SECTION_DIS_USAGE_TRACKER_BTN_CANCEL_0_ctaLabel ||
              "CANCEL"}
          </button>
        </div>
      </div>
    </CustomSlidingPanel>
  );
}
