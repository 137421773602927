import React, { useEffect, useState } from "react";
import { CustomLoadingButton } from "../../_components/CustomLoadingButton";
import {
  aemPages,
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  crmUserSettings,
  imagesConstants,
  routingConstants,
  thingVuseProperties,
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { Commons } from "../../_helpers/commons";
import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";
import {
  isUsageTrackerEnabled,
  isUsageTrackerTutorialComplete,
} from "../../_helpers/usage-tracker";
import { convertObjectToQueryString } from "../../_helpers/utils";
import { Header, PageSelectorCTA } from "../../_components";
import { store } from "../../_store";
import { userActions } from "../../_actions";

export function UsageTrackerOptin() {
  const [dictionary, setDictionary] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const goToDashboard = () => {
    history.push(buildURI(routingConstants.DASHBOARD));
  };

  const goToUsageTracker = (state) => {
    history.push(buildURI(routingConstants.USAGE_TRACKER), state);
  };

  const goToTutorial = () => {
    history.push({
      pathname: buildURI(routingConstants.USAGE_TRACKER_TUTORIAL),
      state: {
        backUrl: routingConstants.USAGE_TRACKER_OPTIN,
        type: 'slide'
      },
    });
  };

  const goToInstructions = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.LEARN_HOW})
    history.push(buildURI(routingConstants.USAGE_TRACKER_OPTIN_INSTR));
  };

  const goToMyVusePrivacyPolicy = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.PRIVACY_NOTICE})
    history.push(
      buildURI(routingConstants.PRIVACY_POLICY) +
        convertObjectToQueryString({ canHandleBack: true })
    );
  };

  const enableFeature = () => {
    setIsLoading(true);

    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.ENABLE})
    localStorage.setItem('puff_data_screenview', AnalyticsScreenEventNames.USAGE_TRACKER_PUFF)


    Commons.acceptOptIn(thingVuseProperties.OPT_IN_USAGE_TRACKER).then(() => {
      setIsLoading(false);
      goToUsageTracker({
        showPuffCountDisclaimer: true,
      });
      
      logAnalyticsEvent(AnalyticsEvents.OPT_IN, {target: AnalyticsScreenEventNames.USAGE_TRACKER})
      logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.USAGE_TRACKER_PUFF})
    });

    store.dispatch(
      userActions.setUserSettings(crmUserSettings.OPT_IN_USAGE_TRACKER, "true")
    );
  };

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.USAGE_TRACKER_OPTIN, {
        UT_OPTIN_HEADER: propertyHeadingDefaultEmpty,
        UT_OPTIN_CONTENT: propertyTextDefaultEmpty,
        UT_OPTIN_LINK_TUTORIAL: propertyTextDefaultEmpty,
        UT_OPTIN_LINK_PREFERENCES: propertyTextDefaultEmptyParsed,
        UT_OPTIN_LINK_PREFERENCES_LEARN_HOW: propertyTextDefaultEmptyParsed,
        UT_OPTIN_POWERED_BY_LABEL: propertyTextDefaultEmpty,
        UT_OPTIN_BTN_ENABLE: propertyCtaItemsDefaultEmpty,
        UT_OPTIN_LINK_PP: propertyTextDefaultEmptyParsed,
      })
    );
  }, []);

  useEffect(() => {
    if (isUsageTrackerEnabled()) {
      if (isUsageTrackerTutorialComplete()) {
        goToUsageTracker();
      } else {
        goToTutorial();
      }
    }

    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
      screen_name: AnalyticsScreenEventNames.USAGE_TRACKER_OPT_IN,
    });
  }, []);

  return (
    <div className="optin-wrapper">
      <Header
        leftButton={{
          className: "play-tutorial",
          icon: <span className="play-tutorial-icon" />,
          onClick: goToTutorial,
        }}
        rightButton={{
          icon: <span className="bat-icon-close" />,
          onClick: goToDashboard,
        }}
      >
        {dictionary.UT_OPTIN_LINK_TUTORIAL || "HOW IT WORKS"}
      </Header>
      <div className="optin-content">
        <div className="usage-tracker-stats-icon">
          <span className="bat-icon-usage" />
        </div>
        <div className="optin-title">
          <h1 className="usage-tracker-title">
            {dictionary.UT_OPTIN_HEADER || "USAGE TRACKER"}
          </h1>
        </div>
        <div className="optin-description">
          <div className="mt-3">
            {dictionary.UT_OPTIN_CONTENT ||
              "Note: If you do not enable this feature, usage data will be collected and stored on your device only, so you will not be able to access or view this data through the app."}
          </div>
        </div>
        <div className="optin-disclaimer">
          {dictionary.UT_OPTIN_LINK_PREFERENCES ||
            "You can disable this feature at anytime."}
          <b className="text-decoration-underline" onClick={goToInstructions}>
            {dictionary.UT_OPTIN_LINK_PREFERENCES_LEARN_HOW || "Learn How."}
          </b>
        </div>
      </div>
      <PageSelectorCTA ctaUrl={true} className="optin-selector-cta">
        <div className="usage-tracker-powered-by mb-3">
          {dictionary.UT_OPTIN_POWERED_BY_LABEL || "Powered By"}
          <img src={imagesConstants.MYVUSE_lOGO} alt="MyVuse Logo" />
        </div>
        <div className="d-grid">
          <CustomLoadingButton
            isLoading={isLoading}
            validationMethod={null}
            buttonLabel={dictionary.UT_OPTIN_BTN_ENABLE_0_ctaLabel || "ENABLE"}
            onClickMethod={enableFeature}
          />
        </div>
        <div
          className="optin-privacy-note mt-3"
          onClick={goToMyVusePrivacyPolicy}
        >
          {dictionary.UT_OPTIN_LINK_PP ||
            "Find out how we handle your personal data in our MYVUSE Privacy Policy"}
        </div>
      </PageSelectorCTA>
    </div>
  );
}
