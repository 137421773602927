import cx from "classnames";
import React from "react";

export function UsageTrackerPreset({ label = "", value }) {
  return (
    <div className="d-flex align-items-center justify-content-between gap-3 usage-tracker-stats-box">
      <div className="flex-grow-1">
        <div className="d-flex align-items-center justify-content-start usage-tracker-stats-box-top">
          <span
            className={cx("preset-icon", {
              [label.toLowerCase()]: !!label,
            })}
          >
            <span className="circle outer" />
            <span className="circle inner" />
          </span>
          <span className="usage-tracker-stats-box-label dark">{label}</span>
        </div>
        <div className="bar" style={{ width: `${value ?? 0}%` }} />
      </div>
      <div className="usage-tracker-stats-box-value">{value ?? "0"}%</div>
    </div>
  );
}
