export const webviewUrl = {
    WEBVIEW_CONDITIONS_OF_SALE:     "WEBVIEW_CONDITIONS_OF_SALE",
    WEBVIEW_CONTACT_US:             "WEBVIEW_CONTACT_US",
    WEBVIEW_DELETE_ACCOUNT:         "WEBVIEW_DELETE_ACCOUNT",
    WEBVIEW_FAQ:                    "WEBVIEW_FAQ",
    WEBVIEW_MYVUSE_PRIVACY_POLICY:  "WEBVIEW_MYVUSE_PRIVACY_POLICY",
    WEBVIEW_MY_ACCOUNT:             "WEBVIEW_MY_ACCOUNT",
    WEBVIEW_PAST_ORDER:             "WEBVIEW_PAST_ORDER",
    WEBVIEW_SUB_TAC:                "WEBVIEW_SUB_TAC",
    WEBVIEW_CHECKOUT:               'WEBVIEW_CART',
    WEBVIEW_COOKIE_NOTICE:          'WEBVIEW_COOKIE_POLICY',
    WEBVIEW_BLOG:                   'WEBVIEW_BLOG',
    WEBVIEW_DEVICE_FAQ:             'WEBVIEW_DEVICE_FAQ',
    WEBVIEW_STORE_LOCATOR:          'WEBVIEW_STORE_LOCATOR',
    SUBSCRIPTION_DETAIL:            'SUBSCRIPTION_DETAIL',
    SUBSCRIPTION_EDIT:              'SUBSCRIPTION_EDIT',
    SUBSCRIPTION_START:             'SUBSCRIPTION_START',
    WEBVIEW_SHOP:                   'WEBVIEW_SHOP',
    WEBVIEW_ACCOUNT_DELETION:       'WEBVIEW_ACCOUNT_DELETION',
    WEBVIEW_DATA_DOWNLOAD:          'WEBVIEW_DATA_DOWNLOAD',
    EXT_VUSE_TAC:                   'EXT_VUSE_TAC',
    LOYALTY_TAC:                    'LOYALTY_TAC'       
}