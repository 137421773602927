import React from 'react';
import { CustomModal } from '../CustomModal';
import { PrivacyPolicy } from '../../Views';

export const PrivacyPolicyInternalModal = (props) => {

    const {
        isOpen,
        onClose,
        modalClassName
    } = props;

    return (
        <CustomModal
            isOpen={isOpen}
            className={modalClassName}>
            <PrivacyPolicy onBack={onClose} />
        </CustomModal>
    );
};