import createTransform from "redux-persist/es/createTransform";
import moment from 'moment';

export function createTransformForBlacklisting(reducer, initialState, blacklisted) {
    return createTransform(
        // transform state on its way to being serialized and persisted.
        (inboundState, key) => {
            return inboundState;
        },
        // transform state being rehydrated
        (outboundState, key) => {
            const blacklisting = blacklisted.map(el => { return { [el]: initialState[el] } });
            return Object.assign({}, outboundState, ...blacklisting);
        },
        { whitelist: [reducer] }
    );
}

export function createExpirableTransform(reducer, initialState, expiring, expUnit, expGranularity, blacklisted) {
    return createTransform(
        // transform state on its way to being serialized and persisted.
        (inboundState, key) => {
            if (inboundState["persistExpiresAt"] === undefined) {
                return { ...inboundState, persistExpiresAt: moment().add(expUnit, expGranularity) };
            }
            return { ...inboundState };
        },
        // transform state being rehydrated
        (outboundState, key) => {

            const blacklisting = blacklisted.map(el => { return { [el]: initialState[el] } });
            let newState = Object.assign({}, outboundState, ...blacklisting);

            //Expired store
            if (moment().isAfter(outboundState.persistExpiresAt)) {
                const resetting = expiring.map(el => { return { [el]: initialState[el] } });
                return Object.assign({}, newState, ...resetting);
                
            }

            return Object.assign({}, newState);
        },
        { whitelist: [reducer] }
    );
}