import React from 'react';
import _ from 'lodash';
import { Header, SpinnerModal, UsageTrackerDisablePanel } from '../../_components';
import {
    aemPages,
    AnalyticsEvents,
    AnalyticsFieldNames,
    AnalyticsTargetEventsNames,
    CUSTOMER,
    flagStatus,
    thingVuseProperties,
    routingConstants,
    servicesConstants,
    crmUserSettings,
    webviewUrl
} from '../../_constants';
import { buildURI } from '../../_helpers/navigation';
import { Commons } from '../../_helpers/commons';
import { commonsActions, getVuseUuid, onboardingActions, userActions } from '../../_actions';
import { connect } from 'react-redux';
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyHeadingDefaultEmpty, propertyTextDefaultEmpty, propertyTextDefaultEmptyParsed } from '../../_helpers/aem/aemhelper';
import { CustomLoadingButton } from '../../_components/CustomLoadingButton';
import { getCountry, getLocale, getSupportedLocales, getSupportedServices, getUrl, isSupportedService } from '../../_actions/appConfig.actions';
import { store } from '../../_store';
import { clearAllLastSyncedLocations } from '../../_helpers/device';
import { logThingsInAnalyticsEvent, logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import configureGTM, { removeGTM } from '../../_vendors/googleAnalytics/index';
import { WebviewModal } from '../../_components/WebviewModal/WebviewModal';
import { history } from '../../_helpers/history';
import { convertObjectToQueryString } from '../../_helpers/utils';
import { deleteCalculatedData } from '../../_helpers/usage-tracker';
import { AxiosCancelTokensHolder } from '../../_helpers/api/axiosCancelTokensHolder';

class YourPreferences extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOptinFindMyVapeEnabled: false,
            isUsageTrackerSupported: false,
            isOptinUsageTrackerEnabled: false,
            isOnGTM: true,
            isOpenCookiePanel: false,
            isOpenUsageTrackerDisablePanel: false,
            isOneTrust: false,
            spinnerLoading: false,
            spinnerError: false,
            isLoading: true
        }

        this.handleBack = this._handleBack.bind(this);
        this.loadCustomerThing = this._loadCustomerThing.bind(this);
        this.handleSavePreferences = this._handleSavePreferences.bind(this);
        this.onChangeCookies = this._onChangeCookies.bind(this);


        this.aem = new AEMHelper();
        this.dictionary = this.aem.getDictionary(aemPages.MANAGE_FEATURES_AND_PREFERENCES, {
            MNG_SECTION_HEADER: propertyHeadingDefaultEmpty,
            MNG_SECTION_LANGUAGE: propertyTextDefaultEmpty,
            MNG_SECTION_FIND_MY_VAPE: propertyTextDefaultEmpty,
            MNG_SECTION_FIND_MY_VAPE_BODY: propertyTextDefaultEmpty,
            MNG_SECTION_COOKIES_ANALYTICS_COOKIES: propertyTextDefaultEmptyParsed,
            MNG_SECTION_COOKIES_APPDATA_TITLE: propertyTextDefaultEmptyParsed,
            MNG_SECTION_COOKIES_APPDATA_BODY: propertyTextDefaultEmptyParsed,
            MNG_SECTION_COOKIES_MANAGE_COOKIES_BODY: propertyTextDefaultEmptyParsed,
            MNG_SECTION_COOKIES_MANAGE_COOKIES_TITLE: propertyTextDefaultEmptyParsed,
            MNG_SECTION_COOKIES_NECESSARY_COOKIES: propertyTextDefaultEmptyParsed,
            MNG_SECTION_COOKIES_COOKIE_POLICY_LINK: propertyTextDefaultEmptyParsed,
            MNG_SECTION_COOKIES_GA_TITLE: propertyTextDefaultEmptyParsed,
            MNG_SECTION_COOKIES_GA_SUBTITLE: propertyTextDefaultEmptyParsed,
            MNG_SECTION_COOKIES_GA_BODY: propertyTextDefaultEmptyParsed,
            MNG_SECTION_USAGE_TRACKER: propertyTextDefaultEmpty,
            MNG_SECTION_USAGE_TRACKER_ENABLED: propertyTextDefaultEmpty,
            MNG_SECTION_USAGE_TRACKER_DISABLED: propertyTextDefaultEmpty,
            MNG_SECTION_USAGE_TRACKER_TC_CONTENT: propertyTextDefaultEmptyParsed,
            MNG_SECTION_USAGE_TRACKER_PP_CONTENT: propertyTextDefaultEmptyParsed,
            MNG_SECTION_DIS_USAGE_TRACKER_HEADER: propertyHeadingDefaultEmpty,
            MNG_SECTION_DIS_USAGE_TRACKER_SUBTITLE: propertyTextDefaultEmpty,
            MNG_SECTION_DIS_USAGE_TRACKER_ALERT_TITLE: propertyTextDefaultEmpty,
            MNG_SECTION_DIS_USAGE_TRACKER_ALERT_BODY: propertyTextDefaultEmpty,
            MNG_SECTION_DIS_USAGE_TRACKER_NOTE_CONTENT: propertyTextDefaultEmpty,
            MNG_SECTION_DIS_USAGE_TRACKER_BTN_SURE: propertyCtaItemsDefaultEmpty,
            MNG_SECTION_DIS_USAGE_TRACKER_BTN_CANCEL: propertyCtaItemsDefaultEmpty,
            MNG_SECTION_MANAGE_COOKIES_TITLE: propertyTextDefaultEmpty,
            MNG_SECTION_MANAGE_COOKIES_CONTENT: propertyTextDefaultEmpty,
            MNG_SECTION_MANAGE_COOKIES_BTN: propertyCtaItemsDefaultEmpty
        });

    }

    componentDidMount() {
        if (isSupportedService(servicesConstants.USAGE_TRACKER)) {
            this.setState({
                isUsageTrackerSupported: true,
            });
        }

        /* get from reducer current customer thing*/
        this.loadCustomerThing();

        /* Load most updated customer thing */
        const {
            userPin,
            getThings,
            devices
        } = this.props;

        const tenantUserId = Commons.generateTenantUserId(userPin);
        getThings(
            tenantUserId.toString(),
            getVuseUuid(),
            CUSTOMER,
            (response) => { (response?.result?.code === 0) && this.setState({isLoading: false});},
            (error) => { this.setState({ spinnerError: true }); }
        );

        this.currentLocale = getSupportedLocales().find((locale) => locale[getLocale()] !== null && locale[getLocale()] !== undefined);

        if (devices.length > 0 && Commons.getCustomerProperty(thingVuseProperties.BATTERYSAVE) !== flagStatus.ACCEPTED) {
            Commons.acceptOptIn(thingVuseProperties.BATTERYSAVE);
        }

        this.setState({ isOnGTM: store.getState().onboardingReducer.acceptedCookies });

        if (getSupportedServices().includes(servicesConstants.ONE_TRUST_COOKIE)) {
            this.setState({ isOneTrust: true })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.things !== this.props.things) {
            this.loadCustomerThing();
        }
    }

    _handleBack() {
        history.goBack();
    }

    /**
     * Update values based on most updated thing
     */
    _loadCustomerThing() {
        const customerThing = Commons.getCustomerThing();

        if (customerThing?.properties) {
            let isOptinFindMyVapeEnabled = this.state.isOptinFindMyVapeEnabled;
            let isOptinUsageTrackerEnabled = this.state.isOptinUsageTrackerEnabled;

            const newProperties = _.keyBy(customerThing.properties, (o) => o.type);

            isOptinFindMyVapeEnabled = newProperties[thingVuseProperties.OPT_IN_FINDMYVAPE]?.data === flagStatus.ACCEPTED;
            isOptinUsageTrackerEnabled = newProperties[thingVuseProperties.OPT_IN_USAGE_TRACKER]?.data === flagStatus.ACCEPTED;

            this.setState({
                isOptinFindMyVapeEnabled,
                isOptinUsageTrackerEnabled
            });
        }

    }

    _handleSavePreferences(callback = () => {}) {
        const {
            userPin,
            setThings
        } = this.props;

        const {
            isOptinFindMyVapeEnabled,
            isUsageTrackerSupported,
            isOptinUsageTrackerEnabled,
            isOnGTM
        } = this.state;

        this.setState({ spinnerLoading: true })

        let payload = [
            { type: thingVuseProperties.OPT_IN_FINDMYVAPE, data: isOptinFindMyVapeEnabled ? flagStatus.ACCEPTED : flagStatus.REFUSED }
        ];

        if (isUsageTrackerSupported) {
            payload.push(
                { type: thingVuseProperties.OPT_IN_USAGE_TRACKER, data: isOptinUsageTrackerEnabled ? flagStatus.ACCEPTED : flagStatus.REFUSED }
            );

            if (!isOptinUsageTrackerEnabled) {
                deleteCalculatedData();
            }
        }

        const tenantUserId = Commons.generateTenantUserId(userPin);
        setThings(
            payload,
            tenantUserId,
            getVuseUuid(),
            CUSTOMER,
            () => {

                logThingsInAnalyticsEvent(
                    AnalyticsEvents.SAVE_PREFERENCES,
                    payload
                )
                //HANDLE SOME ACTIONS BASED ON SAVED VALUES

                //If user opts out find my vape feature
                //remove last synced location from all devices
                if (!isOptinFindMyVapeEnabled) {
                    clearAllLastSyncedLocations();
                }

                Promise.all([
                    new Promise((resolve, reject) => {
                        store.dispatch(userActions.setUserSettings(
                            crmUserSettings.OPT_IN_FINDMYVAPE,
                            JSON.stringify(isOptinFindMyVapeEnabled),
                            resolve,
                            reject
                        ));
                    }),

                    new Promise((resolve, reject) => {
                        store.dispatch(userActions.setUserSettings(
                            crmUserSettings.OPT_IN_USAGE_TRACKER,
                            JSON.stringify(isOptinUsageTrackerEnabled),
                            resolve,
                            reject
                        ));
                    })
                ])
                .then(() => {
                    this.setState({ spinnerLoading: false });
                    callback();
                })
                .catch(() => {
                    this.setState({ spinnerError: true });
                })
            },
            () => this.setState({ spinnerError: true })
        );

        const isDisabled = isSupportedService(servicesConstants.ANALYTICS_GOOGLE_DISABLED)

        if (!isDisabled) {
            if (isOnGTM) {
                configureGTM();
            } else {
                removeGTM();
            }
        }
        store.dispatch(onboardingActions.setActivatedCookiePolicy(isOnGTM));
    }

    navigateTo(url, state = null) {
        history.push(buildURI(url) + convertObjectToQueryString({ canHandleBack: true }), state);
    }
    
    _onChangeCookies() {
        window.OneTrust.ToggleInfoDisplay();
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.COOKIE_MANAGEMENT_PORTAL});
    }


    render() {

        const {
            isOptinFindMyVapeEnabled,
            isUsageTrackerSupported,
            isOptinUsageTrackerEnabled,
            isOnGTM,
            isOpenCookiePanel,
            isOpenUsageTrackerDisablePanel,
            isOneTrust
        } = this.state;

        const {
            devices
        } = this.props;

        let areDeviceFeaturesDisabled = devices?.length <= 0;

        return (
            <div className="page w-100">
                <Header rightButton={{
                    icon: <span className="bat-icon-close" />,
                    onClick: this.handleBack
                }}>
                    {this.dictionary.MNG_SECTION_HEADER}
                </Header>
                    <div className='preferences-wrapper'>
                        <span className='preferences-label'>{this.dictionary.MNG_SECTION_FIND_MY_VAPE}</span>
    
                        <div className={`preferences-optin-wrapper ${areDeviceFeaturesDisabled ? 'disabled' : ''}`}>
                            <div className="d-flex align-items-center">
                                <div className="preferences-optin-text">
                                    {this.dictionary.MNG_SECTION_FIND_MY_VAPE_BODY}
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="findMyVapeSwitcher"
                                        checked={isOptinFindMyVapeEnabled}
                                        value={isOptinFindMyVapeEnabled}
                                        disabled={areDeviceFeaturesDisabled}
                                        onChange={() => {
                                            if(!areDeviceFeaturesDisabled){
                                                this.setState(
                                                    { isOptinFindMyVapeEnabled: !isOptinFindMyVapeEnabled },
                                                    () => this.handleSavePreferences()
                                                );
                                                logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {
                                                    field_name: AnalyticsFieldNames.FIND_MY_VAPE,
                                                    field_value: !isOptinFindMyVapeEnabled
                                                });
                                            }
                                        }} />
                                    <label className="form-check-label" htmlFor="findMyVapeSwitcher"></label>
                                </div>
                            </div>
                        </div>
                        
                        {/* USAGE TRACKER */}
                       {isUsageTrackerSupported && 
                            <React.Fragment>
                                <span className='preferences-label'>{this.dictionary.MNG_SECTION_USAGE_TRACKER || "USAGE TRACKER"}</span>
                                <div className={`preferences-optin-wrapper ${areDeviceFeaturesDisabled ? 'disabled' : ''}`}>
                                    <div className="d-flex align-items-center">
                                        <div 
                                            className="preferences-optin-text" 
                                            onClick={() => {
                                                logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.PRIVACY_POLICY});
                                                history.push(buildURI(routingConstants.PRIVACY_POLICY) + convertObjectToQueryString({ canHandleBack: true }), {type: 'slide', backTo: routingConstants.YOUR_PREFERENCES})
                                            }}
                                        >
                                            {this.dictionary.MNG_SECTION_USAGE_TRACKER_PP_CONTENT || "By enabling this feature you agree to the Terms & Conditions regarding the Usage Tracker feature."}
                                        </div>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="usageTrackerToggle"
                                                checked={isOptinUsageTrackerEnabled}
                                                value={isOptinUsageTrackerEnabled}
                                                disabled={areDeviceFeaturesDisabled}
                                                onChange={() => {
                                                    if(!areDeviceFeaturesDisabled){
                                                        console.debug('ONCHANGE_UT');
                                                        this.setState({ 
                                                            isOptinUsageTrackerEnabled: !isOptinUsageTrackerEnabled,
                                                            isOpenUsageTrackerDisablePanel: isOptinUsageTrackerEnabled  
                                                        },
                                                        () => (!isOptinUsageTrackerEnabled) ? this.handleSavePreferences() : null);
                                                        logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {
                                                            field_name: AnalyticsFieldNames.USAGE_TRACKER,
                                                            field_value: !isOptinUsageTrackerEnabled
                                                        });
                                                    }
                                                }} />
                                            <label className="form-check-label" htmlFor="usageTrackerToggle"></label>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                       }
    
                        {
                            isOneTrust && (
                                <div>
                                    <span className='preferences-label'>{this.dictionary.MNG_SECTION_MANAGE_COOKIES_TITLE}</span>
                                    <span className='preferences-description'>{this.dictionary.MNG_SECTION_MANAGE_COOKIES_CONTENT}</span>
                                    <CustomLoadingButton
                                        buttonClassName="btn btn-outline-secondary text-uppercase w-100"
                                        validationMethod={null}
                                        buttonLabel={this.dictionary.MNG_SECTION_MANAGE_COOKIES_BTN_0_ctaLabel}
                                        onClickMethod={this.onChangeCookies} />
                                </div>
                            )
                        }
    
                        {
                            !isOneTrust && (
                                <div>
                                    <span className='preferences-label'>{this.dictionary.MNG_SECTION_COOKIES_MANAGE_COOKIES_TITLE}</span>
                                    <span className='preferences-description'>{this.dictionary.MNG_SECTION_COOKIES_MANAGE_COOKIES_BODY}</span>
    
                                    <div className="preferences-optin-wrapper">
                                        <div className="d-flex align-items-center">
                                            <div className="preferences-optin-title">
                                                {this.dictionary.MNG_SECTION_COOKIES_NECESSARY_COOKIES}
                                            </div>
                                        </div>
                                        <div className="row align-items-center mt-2">
                                            <div className="col-12">
                                                <div className="preferences-optin-text">
                                                    <div className="preferences-optin-subtitle mb-2">
                                                        <b>{this.dictionary.MNG_SECTION_COOKIES_APPDATA_TITLE}</b>
                                                    </div>
                                                    {this.dictionary.MNG_SECTION_COOKIES_APPDATA_BODY}
                                                </div>
                                            </div>
    
                                        </div>
                                    </div>
    
                                    <div className="preferences-optin-wrapper">
                                        <div className="d-flex align-items-center">
                                            <div className="preferences-optin-title">
                                                {this.dictionary.MNG_SECTION_COOKIES_ANALYTICS_COOKIES}
                                            </div>
                                            <div className="form-check form-switch ms-auto">
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                                    checked={isOnGTM}
                                                    onChange={(e) => {
                                                        if (isOnGTM){ // cookies has been disabled
                                                            logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {
                                                                field_name: AnalyticsFieldNames.COOKIE_CONSENT,
                                                                field_value: !isOnGTM
                                                            }).then(() => {
                                                                this.setState({
                                                                    isOnGTM: !isOnGTM
                                                                },
                                                                () => this.handleSavePreferences()
                                                                );
                                                            });   
                                                        }
                                                        else{ // cookies has been enabled
                                                            this.setState({
                                                                isOnGTM: !isOnGTM
                                                            },
                                                            () => {
                                                                this.handleSavePreferences(() => {
                                                                    logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {
                                                                        field_name: AnalyticsFieldNames.COOKIE_CONSENT,
                                                                        field_value: !isOnGTM
                                                                    });
                                                                });
                                                            });
                                                        }
                                                        
                                                    } 
                                                }/>
                                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                                            </div>
                                        </div>
                                        <div className="align-items-center mt-2">
                                            <div className="preferences-optin-text">
                                                <div className="preferences-optin-subtitle mb-2">
                                                    <b>{this.dictionary.MNG_SECTION_COOKIES_GA_TITLE}</b>
                                                </div>
                                                {this.dictionary.MNG_SECTION_COOKIES_GA_SUBTITLE}
                                                <br />
                                                {this.dictionary.MNG_SECTION_COOKIES_GA_BODY}
                                            </div>
                                        </div>
                                    </div>
                                    <span className='preferences-description text-decoration-underline' onClick={() => this.setState({ isOpenCookiePanel: true })}>
                                        {this.dictionary.MNG_SECTION_COOKIES_COOKIE_POLICY_LINK}
                                    </span>
                                </div>
                            )
                        }
    
                        {
                            this.currentLocale &&
                            <React.Fragment>
                                <span className='preferences-label'>{this.dictionary.MNG_SECTION_LANGUAGE}</span>
                                <div className='preferences-language-box'><span>{`${this.currentLocale[getLocale()]} (${getCountry()})`}</span></div>
                            </React.Fragment>
                        }
    
    
                    </div>

                <div className="page-linear-gradient-bg"></div>

                <WebviewModal
                    webviewId="cnIframe"
                    webviewClassName="cnIframe"
                    webviewTitle={"MYVUSE COOKIE NOTICE"}
                    webviewuUrl={getUrl(webviewUrl.WEBVIEW_COOKIE_NOTICE)}
                    handleBack={() => this.setState({ isOpenCookiePanel: false })}
                    isModalOpen={isOpenCookiePanel}
                    modalClassName='webview-modal-priority'
                />

                {isUsageTrackerSupported && 
                    <UsageTrackerDisablePanel 
                        dictionary={this.dictionary}
                        isOpen={!isOptinUsageTrackerEnabled && isOpenUsageTrackerDisablePanel}
                        onConfirm={() => this.setState({
                            ...this.state,
                            isOptinUsageTrackerEnabled: false,
                            isOpenUsageTrackerDisablePanel: false,
                        },
                        () => this.handleSavePreferences()
                        )} 
                        onCancel={() => this.setState({
                            ...this.state,
                            isOptinUsageTrackerEnabled: true,
                            isOpenUsageTrackerDisablePanel: false,
                        })} 
                    />
                }
                <SpinnerModal
                    show={this.state.spinnerLoading || this.state.isLoading}
                    error={this.state.spinnerError}
                    onForceClose={() => {
                        const tokens = AxiosCancelTokensHolder.get()

                        tokens.forEach(token => {
                            token.abort()
                        })
                    }}
                />
            </div>

        )
    }


}

function mapStateToProps(state) {

    return {
        userPin: state.onboardingReducer.userPin,
        things: state.commonsReducer.things,
        devices: state.deviceReducer.devices
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setThings: (flags, tenantUserId, uuid, vendor, successCb, failureCb) => {
            dispatch(commonsActions.setThings(flags, tenantUserId, uuid, vendor, successCb, failureCb))
        },
        getThings: (tenantUserId, uuid, vendor, successCb, failureCb) => {
            dispatch(commonsActions.getThings(tenantUserId, uuid, vendor, successCb, failureCb));
        }
    };
}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)((YourPreferences));
export { connectedComponent as YourPreferences };