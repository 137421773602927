import { FAILURE, REQUEST, SUCCESS } from "../_reducers/action-type.util";

export function handleAsyncApiCall(dispatch, actionName, asyncApiCall, successCb, failureCb) {
  if ('then' in asyncApiCall && 'catch' in asyncApiCall) {
    dispatch(request());

    return asyncApiCall.then(payload => {
        dispatch(success(payload.data));

        if (typeof successCb === 'function') {
            successCb(payload.data);
        }

        return payload.data;
    }).catch(error => {
        dispatch(failure(error));

        if (typeof failureCb === 'function') {
            failureCb(error);
        }

        return Promise.reject(error);
    })
  } else {
    return Promise.reject("It's not a Promise: " + asyncApiCall);
  }

  function request() {
      return { type: REQUEST(actionName) };
  }
  function success(payload) {
      return { type: SUCCESS(actionName), payload };
  }
  function failure(error) {
      return { type: FAILURE(actionName), error };
  }
}