import cx from "classnames";
import React, { useEffect, useState } from "react";
import {
  DeviceBatteryIcon,
  DeviceConnectionIndicator,
} from "../../_components";
import { getDeviceName, isDeviceConnected } from "../../_helpers/device";
import { AnalyticsEvents, AnalyticsTargetEventsNames } from "../../_constants";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";

const StatusBar = ({
  device,
  connectedLabel,
  disconnectedLabel,
  className,
}) => {
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    setIsConnected(isDeviceConnected(device));
  }, [device]);

  return (
    <div className={cx("status-bar", className)}>
      <div className="d-flex align-items-center justify-content-between">
        <DeviceConnectionIndicator
          connected={isConnected}
          connectedLabel={connectedLabel}
          disconnectedLabel={disconnectedLabel}
        />
        <DeviceBatteryIcon
          chargeLevel={device?.batteryInfo?.chargeLevel}
          isDeviceConnected={isConnected}
        />
      </div>
      <div className="device-name" onClick={() => logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NAME})}>{getDeviceName(device)}</div>
    </div>
  );
};

export { StatusBar };
