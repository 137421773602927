import React from "react";
import { connect } from "react-redux";
import { dashboardActions } from "../../_actions";
import { FeatureTutorial } from "../../_components";
import {
  AnalyticsEvents,
  AnalyticsTutorialNames,
  aemPages,
  flagStatus,
  routingConstants,
  thingVuseProperties,
} from "../../_constants";
import {
  AEMHelper,
  propertyHeadingDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { Commons } from "../../_helpers/commons";
import { getCurrentDevice } from "../../_helpers/device";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";

class FindMyVapeTutorial extends React.Component {
  constructor(props) {
    super(props);

    this.aem = new AEMHelper();

    /* Retrieve the videos from AEM */
    const device = getCurrentDevice();

    this.dictionary = this.aem.getDictionary(
      this.aem.getPageName("FIND_MY_VAPE_TUTORIAL", device.deviceType), 
      {
        FMV_TUTORIAL_HEADER: propertyHeadingDefaultEmpty,
      }
    );

    const stories = this.aem
      .getCarouselUrlsFor(device.deviceType, "FIND_MY_VAPE_TUTORIAL")
      .map((url) => ({
        url,
        type: "video",
        muted: true
      }));

    /* Set the state */
    this.state = { stories };
  }

  componentDidMount(){
    logAnalyticsEvent(AnalyticsEvents.TUTORIAL, {tutorial_name: AnalyticsTutorialNames.FIND_MY_VAPE})
  }

  isFindMyVapeEnabled() {
    return (
      Commons.getCustomerProperty(thingVuseProperties.OPT_IN_FINDMYVAPE) ===
      flagStatus.ACCEPTED
    );
  }

  render() {
    return (
      <FeatureTutorial
        title={this.dictionary.FMV_TUTORIAL_HEADER}
        featureIcon={"location-mark"}
        stories={this.state.stories}
        iconAction={null}
        backAction={
          this.isFindMyVapeEnabled()
            ? routingConstants.FIND_MY_VAPE
            : routingConstants.FIND_MY_VAPE_OPTIN
        }
        doneTutorialMethod={dashboardActions.setDoneTutorialFindMyVape}
        doneTutorialValue={this.props.tutorials.findmyvape}
        thing={thingVuseProperties.FINDMYVAPE}
        tutorialName={AnalyticsTutorialNames.FIND_MY_VAPE}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    tutorials: state.dashboardReducer.tutorials,
  };
}

const component = connect(mapStateToProps)(FindMyVapeTutorial);
export { component as FindMyVapeTutorial };
