import { getSalesforceId, getUserData, setSalesforceId, userActions } from "../../_actions"
import { getAppConfig, isSupportedService } from "../../_actions/appConfig.actions"
import { servicesConstants } from "../../_constants"
import BrowserHelper from "../browser/browser.helper"
import { isNil } from "lodash";

export function configSalesforce() {
  if (BrowserHelper.isBLEBrowser() && isSupportedService(servicesConstants.SFMC_PUSH_NOTIFICATION)) {
     
    console.log("[configSalesforce] enter");
    const salesforceId = getSalesforceId();
    if (getUserData()?.id && isNil(salesforceId)) {

      console.log("[configSalesforce] no salesforceId, trying to retrieve it");

      userActions.getSalesforceId((response) => {

        console.log("[configSalesforce] salesforceId: ", response?.data);
        const contactId = response?.data?.contactId;

        if (contactId) {
          setSalesforceId(contactId);

          const config = getAppConfig()
          window.bleb.Salesforce.setConfiguration({
            appId: config.application_id_ios,
            accessToken: config.access_token_ios,
            appEndpoint: config.marketing_cloud_server_url_ios,
            mid: config.sender_id_ios,
            subscriberKey: contactId,
            pushEnabled: true
          })
          .then(data => {
            console.log(data)
          })
          .catch(data => {
            console.log(data)
          })
        }
      })
    }
  }
}

export function removeSalesforce() {
  if (BrowserHelper.isBLEBrowser() && isSupportedService(servicesConstants.SFMC_PUSH_NOTIFICATION)) {
      //check localstorage for id
      if(!isNil(getSalesforceId())){
        window.bleb.Salesforce.setConfiguration({
          pushEnabled: false
        });

        //delete id from localstorage
        setSalesforceId(null);
      }
  }
}