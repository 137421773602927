import axios from 'axios'
import axiosRetry from 'axios-retry'
import { AxiosInstanceHolder } from './axiosInstanceHolder'
import { enqueueRequest } from './axiosQueue'
import { AxiosCancelTokensHolder } from './axiosCancelTokensHolder'

const N_RETRIES = 10000

export const axiosRequest = async (data) => {
    const controller = new AbortController()
    const requestId = window.crypto.randomUUID()

    AxiosCancelTokensHolder.add({
        abort: () => controller.abort('forceCancel'),
        requestId
    })

    const axiosInstance = AxiosInstanceHolder.get()
    return await axiosInstance({
        ...data, 
        signal: controller.signal,
        requestId
    })
}

export const axiosInstance = () => {
    const axiosInstance = AxiosInstanceHolder.get()

    return axiosInstance
}

export const axiosEnqueuedRequest = async (data) => {
    const controller = new AbortController()
    const requestId = window.crypto.randomUUID()

    AxiosCancelTokensHolder.add({
        abort: () => controller.abort('forceCancel'),
        requestId
    })

    return await enqueueRequest({
        ...data,
        signal: controller?.signal,
        requestId,
        'axios-retry': {
            shouldResetTimeout: true,
            retries: N_RETRIES,
            retryDelay: axiosRetry.exponentialDelay,
            retryCondition: (error) => {
                console.log('axios-retry error', error)
                const result = axiosRetry.isNetworkOrIdempotentRequestError(error) ||
                    error?.message === "Request timeout" ||
                    error?.response?.status >= 500
                return result
            }
        }
    })
}