import React, { useState, useEffect } from 'react';
import { ProductPDP, ShopSorting } from '../../../../_components';
import { aemPages, AnalyticsEvents, shopSortingOptions } from '../../../../_constants';
import { logAnalyticsEvent } from '../../../../_helpers/analytics/logAnalytics';
import { ShopListElement } from '../../../../_components/ShopListElement/ShopListElement';
import { ShopUtils } from '../../../../_helpers/shop';
import { AEMHelper, propertyTextDefaultEmpty } from '../../../../_helpers/aem/aemhelper';
import { logAnalyticsEventOfBasketVariant } from '../../../../_helpers/basket';
import { useSelector } from 'react-redux';

export const AccessoriesProducts = (props) => {

    const loading = useSelector((state) => state.shopReducer.loadingCatalog);

    const [sortingOption, setSortingOption] = useState(shopSortingOptions.SORTING_A2Z);
    const sortingOptions = [
        shopSortingOptions.SORTING_LOW2HIGH,
        shopSortingOptions.SORTING_HIGH2LOW,
        shopSortingOptions.SORTING_A2Z,
        shopSortingOptions.SORTING_Z2A
    ];

    const [isOpenAccessoryPDP, setIsOpenAccessoryPDP] = useState(false);
    const [currentAccessory, setCurrentAccessory] = useState(null);

    const [thisAccessories, setThisAccessories] = useState([]);

    const applySorting = (sortingOption) => {
        setSortingOption(sortingOption);
        setThisAccessories(ShopUtils.sortListFromOption(sortingOption, thisAccessories, 'display_name'));
        logAnalyticsEvent(AnalyticsEvents.SHOP_SORT_BY, {
            sort_by: sortingOption
        });
    }

    const [dictionary, setDictionary] = useState({});

    useEffect(() => {
        const aem = new AEMHelper();
        setDictionary(
            aem.getDictionary(aemPages.SHOP, {
                SHOP_ACCESSORIES_TITLE: propertyTextDefaultEmpty,
                SHOP_SORT_BY_LABEL: propertyTextDefaultEmpty,
                SHOP_NO_ACCESSORIES: propertyTextDefaultEmpty,
                SHOP_NEW: propertyTextDefaultEmpty
            })
        );

    }, [])

    useEffect(() => {
        console.log("props.accessories", props.accessories);
        setThisAccessories(ShopUtils.sortListFromOption(sortingOption, props.accessories, 'display_name'));
    }, [props.accessories]);

    const toggleAccessoryPDP = (accessory) => {
        const newValue = !isOpenAccessoryPDP;

        setIsOpenAccessoryPDP(newValue);

        if (newValue) {
            setCurrentAccessory(accessory);
        } else {
            //dalaying set to null for transition to be shown
            setTimeout(() => {
                setCurrentAccessory(null);
            }, 500);
        }
    }

    const onShopListElementClick = (accessory) => {
        logAnalyticsEventOfBasketVariant(AnalyticsEvents.SELECT_ITEM, accessory, false);
        toggleAccessoryPDP(accessory);
    }

    return (
        <React.Fragment>
            <div className="label-title">
                {dictionary.SHOP_ACCESSORIES_TITLE}
            </div>
            <div className="shop-filter">
                <div className="label-title">
                    {dictionary.SHOP_SORT_BY_LABEL}
                </div>
                <div className="d-flex">
                    <ShopSorting onApplySorting={applySorting}
                        sortingOption={sortingOption}
                        sortingOptions={sortingOptions} />
                </div>
            </div>

            <div className="shop-product-list" style={{
                paddingBottom: props.basket.length > 0 ? '6.90rem' : '1.25rem'
            }}>

                {
                    thisAccessories?.length > 0 ?

                        thisAccessories.map((accessory, idx) => {

                            let productImg = accessory?.featured_image;
                            if (productImg === null || productImg === undefined) {
                                productImg = ShopUtils.getProductPropertyFromId(accessory.product_id, 'featured_image');
                            }

                            return (
                                <ShopListElement key={`accessories-el-${idx}`}
                                    product={accessory}
                                    dictionary={dictionary}
                                    titleProp="display_name"
                                    isNew={false}
                                    productImg={productImg}
                                    onClickElement={() => onShopListElementClick(accessory)} />
                            )
                        })

                        :

                        loading ?
                        
                            <div className="d-flex justify-content-center mt-2">
                                <div className="spinner-border text-secondary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>

                            :

                            <span>{dictionary.SHOP_NO_ACCESSORIES}</span>
                }
            </div>

            <ProductPDP isOpen={isOpenAccessoryPDP}
                onClosePdp={toggleAccessoryPDP}
                product={currentAccessory}
                onOpenBasket={props.onOpenBasketPanel}
                onStartCheckout={props.onStartCheckout}
            />

        </React.Fragment>
    )
}