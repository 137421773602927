import React, { useEffect, useState } from "react";
import { SignupFormStep } from "./SignupFormStep";
import { Tenants } from "../../../_helpers/tenants";
import { HARD_AGE_VERIFICATION_STEPS, aemPages } from "../../../_constants";
import StepWizard from "react-step-wizard";
import {
  HardAgeVerificationCapture,
  HardAgeVerificationConsent,
  HardAgeVerificationNegateConsent,
  HardAgeVerificationTooClose,
  HardAgeVerificationDocScan,
  HardAgeVerificationUnsuccess,
  HardAgeVerificationDocScanFailure,
  HardAgeVerificationInfo,
  HardAgeVerificationFAQ,
  HardAgeVerificationConsentCA,
  HardAgeVerificationFaceFake
} from "../../../_components/HardAgeVerification";
import { AEMHelper, propertyHeadingDefaultEmpty } from "../../../_helpers/aem/aemhelper";
import { getRegistration, setRegistration } from "../../../_actions";

export function HardAgeVerificationStep(props) {
  const INITIAL_STEP = Tenants.isGlobal() ? HARD_AGE_VERIFICATION_STEPS.CONSENT : HARD_AGE_VERIFICATION_STEPS.INFO;

  const [currentStep, setCurrentStep] = useState(INITIAL_STEP);
  const [title, setTitle] = useState(null);
  const [dictionary, setDictionary] = useState({});


  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary({
      ...aem.getDictionary(aemPages.YOTI_AGE_VERIFICATION, {
        YOTI_AV_TITLE: propertyHeadingDefaultEmpty
      }),
      ...aem.getDictionary(aemPages.YOTI_FAQ, {
        YOTI_FAQ_TITLE: propertyHeadingDefaultEmpty
      })
    })

    let registration = getRegistration()
    registration.done = true
    setRegistration(registration)
  }, []);

  const handleStepChange = ({ activeStep }) => {
    setCurrentStep(activeStep);
  }

  useEffect(() => {
    setTitle(null)

    if (currentStep === HARD_AGE_VERIFICATION_STEPS.INFO) {
      setTitle(dictionary.YOTI_AV_TITLE)
    } else if (currentStep === HARD_AGE_VERIFICATION_STEPS.FAQ) {
      setTitle(dictionary.YOTI_FAQ_TITLE)
    } else if (currentStep === HARD_AGE_VERIFICATION_STEPS.DOC_SCAN || currentStep === HARD_AGE_VERIFICATION_STEPS.DOC_SCAN_FAILURE) {
      props.setHideHeader(true)
    } else if (currentStep === HARD_AGE_VERIFICATION_STEPS.CAPTURE) {
      props.setTransparent(true)
    } else {
      props.setHideHeader(false)
      props.setTransparent(false)
    }

    if (currentStep === HARD_AGE_VERIFICATION_STEPS.INFO) {
      let registration = getRegistration()
      if (registration?.isValid) {
        props.goToStep(6)
      }
    }
  }, [currentStep, dictionary])

  return (
    <SignupFormStep
      title={title}
      ctaLabel={null}
      showAgeWarning
      isValid
      {...props}
    >
      <div className="hard-age-verification h-100">
        <StepWizard
            isLazyMount
            className="h-100"
            initialStep={INITIAL_STEP}
            onStepChange={handleStepChange}
        >
          <HardAgeVerificationConsent />
          <HardAgeVerificationCapture onSubmit={props.onSubmit} />
          <HardAgeVerificationNegateConsent />
          <HardAgeVerificationUnsuccess />
          <HardAgeVerificationTooClose />
          <HardAgeVerificationDocScan onSubmit={props.onSubmit} />
          <HardAgeVerificationDocScanFailure />

          {/* CANADA SPECIFIC */}
          <HardAgeVerificationInfo />
          <HardAgeVerificationFAQ />
          <HardAgeVerificationConsentCA />
          <HardAgeVerificationFaceFake />
        </StepWizard>
      </div>
    </SignupFormStep>
  );
}
