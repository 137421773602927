import React, { useState, useEffect } from 'react';
import { useMemo } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { PickerPanel } from '../../../../_components';
import { RuleModel } from '../../../../_models';
import { Commons } from '../../../../_helpers/commons';
import { getUserData, onboardingActions, tipsActions, userActions } from '../../../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import { logAnalyticsEvent } from '../../../../_helpers/analytics/logAnalytics';
import { AnalyticsEvents, AnalyticsScreenEventNames, crmUserSettings, orderReminderFrequency, tipPropertyData } from '../../../../_constants';
import { Notifications } from '../../../../_helpers/notifications';

export const ShopOrderReminder = ({ isDisabled, dictionary }) => {

    const selectorDefaultTime = {
        hour: '09',
        min: '15',
        meridiem: dictionary.SHOP_REORDER_TIME_ORDER_REMINDERS_PM
    }

    const frequency = {
        FREQUENCY_MONTHLY: dictionary.SHOP_REORDER_FREQ_ORDER_REMINDERS_MONTHLY,
        FREQUENCY_3_WEEKS: dictionary.SHOP_REORDER_FREQ_ORDER_REMINDERS_3_WEEKS,
        FREQUENCY_2_WEEKS: dictionary.SHOP_REORDER_FREQ_ORDER_REMINDERS_2_WEEKS,
        FREQUENCY_WEEKLY: dictionary.SHOP_REORDER_FREQ_ORDER_REMINDERS_WEEKLY,
        FREQUENCY_DAILY: dictionary.SHOP_REORDER_FREQ_ORDER_REMINDERS_DAILY,
    }

    const selectorAllFrequencies = [
        { key: orderReminderFrequency.EVERY_MONTH, label: frequency.FREQUENCY_MONTHLY },
        { key: orderReminderFrequency.EVERY_THREE_WEEKS, label: frequency.FREQUENCY_3_WEEKS },
        { key: orderReminderFrequency.EVERY_TWO_WEEKS, label: frequency.FREQUENCY_2_WEEKS },
        { key: orderReminderFrequency.EVERY_WEEK, label: frequency.FREQUENCY_WEEKLY },
        { key: orderReminderFrequency.EVERY_DAY, label: frequency.FREQUENCY_DAILY },
    ]

    const dispatch = useDispatch();
    const loading = useSelector((state) => state.shopReducer.loadingCatalog);
    const loadingRule = useSelector((state) => state.dashboardReducer.loading);
    const userPin = useSelector((state) => state.onboardingReducer.userPin);
    const rules = useSelector((state) => state.dashboardReducer.rules);

    const [isOpenSelectionFirstStep, setIsOpenSelectionFirstStep] = useState(false);
    const [isOpenSelectionSecondStep, setIsOpenSelectionSecondStep] = useState(false);
    const [isEnabledOrderReminder, setIsEnabledOrderReminder] = useState(false);


    const rule = useMemo(() => {
        return Notifications.getOrderReminderNotificationRule();
    }, [rules]);

    const timeOptions = {
        hour: (new Array(13).fill(0)).map((v, i) => (i).toString().padStart(2, '0')),
        min: (new Array(12).fill(0)).map((v, i) => (i * 5).toString().padStart(2, '0')),
        meridiem: [dictionary.SHOP_REORDER_TIME_ORDER_REMINDERS_AM, dictionary.SHOP_REORDER_TIME_ORDER_REMINDERS_PM]
    };

    const reminderFrequencyOptions = { currentFrequency: selectorAllFrequencies }

    const [timeSelected, setTimeSelected] = useState(selectorDefaultTime);
    const [defaultTime] = useState(selectorDefaultTime);

    const [defaultReminderFrequency] = useState({ key: '2W', label: frequency.FREQUENCY_2_WEEKS });
    const [reminderFrequencySelected, setReminderFrequencySelected] = useState({ currentFrequency: { key: '2W', label: frequency.FREQUENCY_2_WEEKS } });

    const [displayFrequency, setDisplayFrequancy] = useState("");
    const [displayTime, setDisplayTime] = useState("");

    const toggleOrderReminder = () => {
        if (!isDisabled) {
            logAnalyticsEvent(AnalyticsEvents.REMIND_ME_TO_ORDER, {
                screen_name: AnalyticsScreenEventNames.REORDER_ORDER_REMINDERS
            });
            if (isEnabledOrderReminder) {
                //Remove rule from BE
                const tenantUserId = Commons.generateTenantUserId(userPin);
                dispatch(onboardingActions.deleteRules(tenantUserId, rule.id));

                dispatch(userActions.setUserSettings(
                    crmUserSettings.OPT_IN_ORDER_REMINDER,
                    "false"
                ))
            } else {
                logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
                    screen_name: AnalyticsScreenEventNames.REORDER_ORDER_REMINDERS
                });
                setIsOpenSelectionFirstStep(true);
            }
        }
    }

    const onSaveOrderReminder = async () => {
        logAnalyticsEvent(AnalyticsEvents.SAVE_PREFERENCES_REMING_TO_ORDER, {
            value: `${reminderFrequencySelected.currentFrequency.label}, ${timeSelected.hour}:${timeSelected.min}${timeSelected.meridiem}`
        });

        //create reminder rule
        let newRule = new RuleModel(`order_rem_${Commons.generateRandomNumericId(10)}`, getUserData().group_id);
        newRule.setCustomerCommandForOrderReminder(tipPropertyData.ORDER_REMINDER_TIME_TO_RESTOCK_DATETIME);

        if (timeSelected && reminderFrequencySelected.currentFrequency) {

            let momentObj = moment();
            momentObj.minutes(parseInt(timeSelected.min));
            momentObj.hour(parseInt(timeSelected.hour) + (timeSelected.meridiem === "PM" ? 12 : 0));
            momentObj.utc();

            newRule.setCalendar(
                momentObj.format("mm"),
                momentObj.format("HH"),
                null,
                null,
                null,
                null,
                reminderFrequencySelected.currentFrequency.key === orderReminderFrequency.EVERY_DAY ? null : reminderFrequencySelected.currentFrequency.key
            );
        }

        newRule.setDescription(JSON.stringify({
            type: tipPropertyData.ORDER_REMINDER_TIME_TO_RESTOCK_DATETIME,
            frequency: reminderFrequencySelected.currentFrequency.key,
            time: `${timeSelected.hour}:${timeSelected.min} ${timeSelected.meridiem}`
        }));

        const tenantUserId = Commons.generateTenantUserId(userPin);
        //check if there is already present a rule for order reminder; if so delete it
        if (isEnabledOrderReminder) {
            await dispatch(onboardingActions.deleteRules(tenantUserId, rule.id));
        }
        //create new order reminder rule
        dispatch(
            tipsActions.createRuleForNotification(tenantUserId, newRule.payload(), () => {
                setIsOpenSelectionSecondStep(false);
                setIsOpenSelectionFirstStep(false);
                //dispatch crm setting
                dispatch(userActions.setUserSettings(
                    crmUserSettings.OPT_IN_ORDER_REMINDER,
                    "true"
                ))
            })
        );
    }

    useEffect(() => {

        const isPresentRule = rule !== null && rule !== undefined && !_.isEmpty(rule);
        setIsEnabledOrderReminder(isPresentRule);

        if (isPresentRule) {
            let descriptionObj = Commons.parseJSONSafely(rule.description);
            if (
                descriptionObj !== null &&
                descriptionObj !== undefined &&
                !_.isEmpty(descriptionObj)
            ) {
                const frequency = selectorAllFrequencies.find(freq => freq.key === descriptionObj?.frequency)
                if (frequency) {
                    setDisplayFrequancy(frequency.label);
                    setReminderFrequencySelected({ currentFrequency: frequency });
                }

                setDisplayTime(descriptionObj?.time);
                if(moment(descriptionObj?.time, 'hh:mm A').isValid()){
                    setTimeSelected({
                        hour: moment(descriptionObj?.time, 'hh:mm A').format('hh'),
                        min: moment(descriptionObj?.time, 'hh:mm A').format('mm'),
                        meridiem: moment(descriptionObj?.time, 'hh:mm A').format('A')
                    });
                }
            }
        }

    }, [rule])

    return (
        <React.Fragment>

            <div className={`shop-reorder-feature ${isDisabled && ' disabled'}`}>
                <div className="box"></div>
                <div className='d-flex justify-content-between py-3'>
                    <div className="label-title">
                        {dictionary.SHOP_REORDER_ORDER_REMINDERS_TITLE}
                    </div>
                </div>

                <React.Fragment>
                    {
                        loading ?

                            <div className="d-flex justify-content-center mt-2">
                                <div className="spinner-border text-secondary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>

                            :

                            <div className={`shop-reorder-feature-toggle-wrapper`}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-9">
                                            <div className="reorder-favourites-text">
                                                {dictionary.SHOP_REORDER_ORDER_REMINDERS_BODY}
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="orderReminderCheck"
                                                    checked={isEnabledOrderReminder}
                                                    onChange={toggleOrderReminder} />
                                                <label className="form-check-label" htmlFor="orderReminderCheck"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    isEnabledOrderReminder &&
                                    <div className='container mt-4'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div><span className='reminder-label'>{dictionary.SHOP_REORDER_FREQUENCY}</span></div>
                                            <div className='text-end' onClick={() => setIsOpenSelectionFirstStep(true)} >
                                                <span className='reminder-value'>{displayFrequency}</span>

                                            </div>
                                        </div>
                                        <div className='mt-4 d-flex align-items-center justify-content-between'>
                                            <div><span className='reminder-label'>{dictionary.SHOP_REORDER_TIME}</span></div>
                                            <div className='text-end' onClick={() => setIsOpenSelectionSecondStep(true)}>
                                                <span className='reminder-value'>{displayTime}</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                    }
                </React.Fragment>

            </div>


            <PickerPanel
                isOpenPanel={isOpenSelectionFirstStep}
                panelTitle={dictionary.SHOP_REORDER_FREQ_ORDER_REMINDERS_HEADER}
                panelDescription={dictionary.SHOP_REORDER_FREQ_ORDER_REMINDERS_CONTENT}
                onPanelDismiss={() => {
                    setIsOpenSelectionFirstStep(false);
                    setReminderFrequencySelected({ currentFrequency: defaultReminderFrequency });
                }}
                pickerOptions={reminderFrequencyOptions}
                pickerSelected={reminderFrequencySelected}
                onPickerSelection={(label, value) => {
                    setReminderFrequencySelected({ [label]: value });
                }}
                buttonLabel={
                    rule !== null && rule !== undefined
                        ? dictionary.SHOP_REORDER_TIME_ORDER_REMINDERS_BTN_SAVE_0_ctaLabel
                        : dictionary.SHOP_REORDER_FREQ_ORDER_REMINDERS_BTN_NEXT_0_ctaLabel
                }
                onPanelClose={() => {
                    if (rule !== null && rule !== undefined) {
                        onSaveOrderReminder();
                    } else {
                        setIsOpenSelectionFirstStep(false);
                        setIsOpenSelectionSecondStep(true);
                    }
                }}
            />

            <PickerPanel
                isOpenPanel={isOpenSelectionSecondStep}
                panelTitle={dictionary.SHOP_REORDER_FREQ_ORDER_REMINDERS_HEADER}
                panelDescription={dictionary.SHOP_REORDER_TIME_ORDER_REMINDERS_CONTENT}
                onPanelDismiss={() => {
                    setIsOpenSelectionSecondStep(false);
                    setTimeSelected(defaultTime);
                }}
                pickerOptions={timeOptions}
                pickerSelected={timeSelected}
                onPickerSelection={(label, value) => {
                    setTimeSelected({ ...timeSelected, [label]: value });
                }}
                buttonLabel={dictionary.SHOP_REORDER_TIME_ORDER_REMINDERS_BTN_SAVE_0_ctaLabel}
                onPanelClose={() => {
                    onSaveOrderReminder();
                }}
                isLoading={loadingRule}
            />

        </React.Fragment>

    );

}

