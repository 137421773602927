import React from 'react';
import { CustomInlineAlert, PageSelectorCTA } from '..';
import { logAnalytics, logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { AnalyticsEvents, AnalyticsTargetEventsNames } from '../../_constants';

export const IntroductionStep = ({
    title,
    okButtonLabel,
    cancelButtonLabel,
    isOpenAlert,
    alertType,
    alertContent,
    onStartProcess,
    onCancelProcess,
    nextStep,
    goToStep
}) => {

    const handleStartProcess = () => {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CONFIRM})
        onStartProcess({ next: nextStep, goToStep: goToStep })
    }

    return (
        <div className="process-data-container">
            <div className="process-data-title"><span>{title}</span></div>
            <CustomInlineAlert isShown={isOpenAlert} alertType={alertType ?? "error"}>
                <div>{alertContent}</div>
            </CustomInlineAlert>
            <PageSelectorCTA sticky={false} className="mt-auto ps-0 pe-0">
                <div className="d-grid gap-2">
                    <button className="btn btn-primary text-uppercase" onClick={handleStartProcess}>{okButtonLabel}</button>
                    <button className="btn btn btn-outline-secondary text-uppercase" onClick={onCancelProcess}>{cancelButtonLabel}</button>
                </div>
            </PageSelectorCTA>
        </div>
    );
}