export const FW_UPDATE_STEPS = {
  INTRO: "intro",
  START: "start",
  SUCCESS: "success",
  FAILURE: "failure",
  RETRY_ERROR: "retry-error",
};

export const FW_UPDATE_PROGRESS_STEPS = {
  DOWNLOAD: 0,
  MAINTAINING_CONNECTION: 1,
  INSTALLING: 2,
};

export const FW_UPDATE_ERRORS = {
  LOW_BATTERY: 0,
  BLE_LINK_LOSS: 1,
  NOT_ENOUGH_MEMORY: 2,
  PRE_VALIDATION: 3,
  POST_VALIDATION: 4,
  DEVICE_DISCONNECTED: 5,
  GENERIC: 6,
};

export const FW_UPDATE_ERRORS_CODES = {
  LOW_BATTERY: "2052",
  BLE_LINK_LOSS: "4106",
  BLE_LINK_LOSS_B: "4106-B",
  NOT_ENOUGH_MEMORY: "0516",
  PRE_VALIDATION: "0517",
  POST_VALIDATION: "1036",
  S2: "0002",
  S11: "0011",
};

export const FW_UPDATE_MIN_BATTERY_LEVEL = 20;

export const FW_UPDATE_DEVICE_STATES = {
  DFU_STARTED: 0,
  DFU_COMPLETED: 1,
};

export const FW_UPDATE_DOWNLOAD_MAX_PERC_REF = 10;
