import { onboardingConstants } from '../_constants';
import { REQUEST, SUCCESS, FAILURE } from '../_reducers/action-type.util';
import { onboardingServices, rulesServices } from '../_services';

export const onboardingActions = {
    validatePin, //VAULT RESTORE
    validateSecurityQuestion, //VAULT RESTORE
    secureVaultData, //VAULT SETUP
    storeVaultPin, //VAULT SETUP
    updateVaultData, //VAULT UPDATE
    updateVaultPin, //VAULT UPDATE
    setUserPin, //VAULT SETUP
    setOldUserTenantId, //VAULT RESTORE
    setActivatedCookiePolicy,//COOKIE POLICY
    getAllRules,
    deleteRules,
    setIsResetPinFlow,
    setIsCreatingNewPin
    //getProfileData,
};
/*
function getProfileData(token, userId, successCb, failureCb) {
    return (dispatch, getState) => {
        dispatch(request());

        onboardingServices.getProfileData(token, userId).then(payload => {
            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(onboardingConstants.GET_USER_PROFILE) };
    }
    function success(payload) {
        return { type: SUCCESS(onboardingConstants.GET_USER_PROFILE), payload };
    }
    function failure(error) {
        return { type: FAILURE(onboardingConstants.GET_USER_PROFILE), error };
    }
}
*/

/*********************************************************************************************** */
/*                                  NEW METHODS                                                  */
/*********************************************************************************************** */

export function validatePin(tenantUserId, successCb, failureCb) {
    return (dispatch, getState) => {
        dispatch(request());

        onboardingServices.validatePin(tenantUserId).then(payload => {
            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(onboardingConstants.PIN_VALIDATION) };
    }
    function success(payload) {
        return { type: SUCCESS(onboardingConstants.PIN_VALIDATION), payload };
    }
    function failure(error) {
        return { type: FAILURE(onboardingConstants.PIN_VALIDATION), error };
    }

}

export function validateSecurityQuestion(tenantUserId, payload, successCb, failureCb) {
    return (dispatch, getState) => {
        dispatch(request());

        onboardingServices.validateSecurityQuestion(tenantUserId, payload).then(payload => {
            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(onboardingConstants.QUESTION_VALIDATION) };
    }
    function success(payload) {
        return { type: SUCCESS(onboardingConstants.QUESTION_VALIDATION), payload };
    }
    function failure(error) {
        return { type: FAILURE(onboardingConstants.QUESTION_VALIDATION), error };
    }

}

export function secureVaultData(tenantUserId, successCb, failureCb) {
    return (dispatch, getState) => {
        dispatch(request());

        onboardingServices.secureVaultData(tenantUserId).then(payload => {
            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(onboardingConstants.SECURE_VAULT) };
    }
    function success(payload) {
        return { type: SUCCESS(onboardingConstants.SECURE_VAULT), payload };
    }
    function failure(error) {
        return { type: FAILURE(onboardingConstants.SECURE_VAULT), error };
    }

}


export function storeVaultPin(data, tenantUserId, successCb, failureCb) {
    return (dispatch, getState) => {
        dispatch(request());

        onboardingServices.storeVaultPin(data, tenantUserId).then(payload => {
            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(onboardingConstants.STORE_PIN) };
    }
    function success(payload) {
        return { type: SUCCESS(onboardingConstants.STORE_PIN), payload };
    }
    function failure(error) {
        return { type: FAILURE(onboardingConstants.STORE_PIN), error };
    }

}


export function updateVaultData(data, tenantUserId, successCb, failureCb) {
    return (dispatch, getState) => {
        dispatch(request());

        onboardingServices.updateVaultData(data, tenantUserId).then(payload => {
            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(onboardingConstants.UPDATE_VAULT) };
    }
    function success(payload) {
        return { type: SUCCESS(onboardingConstants.UPDATE_VAULT), payload };
    }
    function failure(error) {
        return { type: FAILURE(onboardingConstants.UPDATE_VAULT), error };
    }

}


export function updateVaultPin(data, tenantUserId, successCb, failureCb) {
    return (dispatch, getState) => {
        dispatch(request());

        onboardingServices.updateVaultPin(data, tenantUserId).then(payload => {
            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(onboardingConstants.UPDATE_PIN) };
    }
    function success(payload) {
        return { type: SUCCESS(onboardingConstants.UPDATE_PIN), payload };
    }
    function failure(error) {
        return { type: FAILURE(onboardingConstants.UPDATE_PIN), error };
    }

}


export function getAllRules(tenantUserId, groupId, successCb, failureCb) {
    return (dispatch, getState) => {
        dispatch(request());

        rulesServices.getAllRules(tenantUserId, groupId).then(payload => {
            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(onboardingConstants.GET_USER_RULES) };
    }
    function success(payload) {
        return { type: SUCCESS(onboardingConstants.GET_USER_RULES), payload };
    }
    function failure(error) {
        return { type: FAILURE(onboardingConstants.GET_USER_RULES), error };
    }
}

export function deleteRules(tenantUserId, ruleId, successCb, failureCb) {
    return (dispatch, getState) => {
        dispatch(request());

        rulesServices.deleteRule(tenantUserId, ruleId).then(payload => {
            dispatch(success(ruleId));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(onboardingConstants.DELETE_USER_RULE) };
    }
    function success(payload) {
        return { type: SUCCESS(onboardingConstants.DELETE_USER_RULE), payload };
    }
    function failure(error) {
        return { type: FAILURE(onboardingConstants.DELETE_USER_RULE), error };
    }

}


function setUserPin(pin, callBack) {
    return (dispatch, getState) => {
        dispatch({
            type: onboardingConstants.SET_USER_PIN,
            data: pin
        });

        if (typeof callBack === 'function') {
            callBack();
        }
    };
}


function setOldUserTenantId(id, callBack) {
    return (dispatch, getState) => {
        dispatch({
            type: onboardingConstants.SET_OLD_USER_TENANT_ID,
            data: id
        });

        if (typeof callBack === 'function') {
            callBack();
        }
    };
}

function setActivatedCookiePolicy(activated = true) {
    return (dispatch, getState) => {
        dispatch({
            type: onboardingConstants.SET_ACTIVE_COOKIE_POLICY,
            data: activated
        });
    };
}

function setIsResetPinFlow(value) {
    return {
      type: onboardingConstants.SET_IS_RESET_PIN_FLOW,
      data: value
    };
  }

function setIsCreatingNewPin(value) {
    return {
        type: onboardingConstants.SET_IS_CREATING_NEW_PIN,
        data: value
    };
}
