import React, { useEffect, useState } from "react";
import { getLegalAge, getProvinceLabel } from "../../_actions/appConfig.actions";
import consentAnimation from "../../_assets/lotties/hard-age-verification/consent.json";
import {
  AnalyticsEvents,
  HARD_AGE_VERIFICATION_STEPS,
  aemPages,
  imagesConstants,
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { Utils } from "../../_helpers/graphic/utils";
import { CustomAlert } from "../CustomAlert";
import { Header } from "../Header";
import { PageSelectorCTA } from "../PageSelectorCTA";
import parse from 'html-react-parser';
import CustomLottie from "../CustomLottie/CustomLottie";

export function HardAgeVerificationConsent({ nextStep, goToStep }) {
  const [dictionary, setDictionary] = useState({
    YOTI_AV_THIRD_INFO: ''
  });
  const [scanTipsIsOpen, setScanTipsIsOpen] = useState(false);

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.HARD_AGE_VERIFICATION, {
        YOTI_AV_TITLE: propertyTextDefaultEmptyParsed,
        //YOTI_AV_SUBTITLE: propertyTextDefaultEmpty,
        YOTI_AV_FIRST_INFO: propertyTextDefaultEmpty,
        YOTI_AV_SECOND_INFO: propertyTextDefaultEmpty,
        YOTI_AV_THIRD_INFO: propertyTextDefaultEmpty,
        YOTI_AV_SCAN_TIPS_LABEL: propertyTextDefaultEmpty,
        YOTI_POP_UP_TITLE: propertyTextDefaultEmpty,
        YOTI_POP_UP_LIGHT_CONTENT: propertyTextDefaultEmpty,
        YOTI_POP_UP_HAT_CONTENT: propertyTextDefaultEmpty,
        YOTI_POP_UP_GLASSES_CONTENT: propertyTextDefaultEmpty,
        YOTI_AV_BTN_CONSENT: propertyCtaItemsDefaultEmpty,
        YOTI_AV_BTN_ID_VERIFICATION: propertyCtaItemsDefaultEmpty
        //YOTI_AV_SKIP_LINK: propertyTextDefaultEmpty,
      })
    );
  }, []);

  return (
    <div className="page w-100 overflow-hidden">
      <Header />
      <div className="page-wrapper d-flex flex-column h-100 overflow-auto">
        <div className="d-flex flex-column justify-content-between flex-grow-1">
          <div className="hard-age-verification-title text-center">
            <h1>
              {Utils.stringReplacePlaceholders(
                dictionary.YOTI_AV_TITLE,
                `${getLegalAge()}+`,
                true
              )}
            </h1>
          </div>
          <div className="hard-age-verification-lottie-wrapper">
            <CustomLottie
              options={{
                loop: true,
                autoplay: true,
                animationData: consentAnimation,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={200}
              width={200}
            />
            <div className="lottie-content">
              <img
                src={imagesConstants.HARD_AGE_VERIFICATION_CAMERA}
                alt="Camera Icon"
              />
            </div>
            <div className="lottie-content">
              <span
                className="url-text"
                onClick={() => {
                  logAnalyticsEvent(AnalyticsEvents.YOTI_SCAN_TIPS);
                  setScanTipsIsOpen(true);
                }}
              >
                {dictionary.YOTI_AV_SCAN_TIPS_LABEL}
              </span>
            </div>
          </div>
          {dictionary.YOTI_AV_FIRST_INFO}
          <div className="hard-age-verification-info w-100">
            <ul>
              <li>
                <span className="bat-icon-alert-circle alert-icon" />
                
              </li>
              <li>{dictionary.YOTI_AV_SECOND_INFO}</li>
              
            </ul>
          </div>
          <li>{parse(dictionary.YOTI_AV_THIRD_INFO.replace("$1", getProvinceLabel()))}</li>
        </div>
        <PageSelectorCTA ctaUrl={true}>
          <div className="d-grid">
            <button
              className="btn btn-primary text-uppercase"
              onClick={() => {
                logAnalyticsEvent(AnalyticsEvents.YOTI_CONSENT_FACE_SCAN);
                nextStep();
              }}
            >
              {dictionary.YOTI_AV_BTN_CONSENT_0_ctaLabel}
            </button>
            <button
              className="btn btn-outline-secondary text-uppercase"
              onClick={() => {
                logAnalyticsEvent(AnalyticsEvents.YOTI_CONSENT_FACE_SCAN);
                nextStep();
              }}
            >
              {dictionary.YOTI_AV_BTN_ID_VERIFICATION_0_ctaLabel}
            </button>
          </div>
        </PageSelectorCTA>
      </div>
      <div className="page-linear-gradient-bg" />
      <CustomAlert
        isOpen={scanTipsIsOpen}
        hideOkButton
        customClasses="hard-age-verification-scan-tips"
      >
        <div className="hard-age-verification-scan-tips-header">
          {dictionary.YOTI_POP_UP_TITLE}
          <span
            className="bat-icon-close close-icon"
            onClick={() => setScanTipsIsOpen(false)}
          />
        </div>
        <div className="hard-age-verification-scan-tips-body">
          <ul>
            <li>
              <img
                src={imagesConstants.HARD_AGE_VERIFICATION_SUN_ICON}
                alt="sun icon"
              />
              {dictionary.YOTI_POP_UP_LIGHT_CONTENT}
            </li>
            <li>
              <img
                src={imagesConstants.HARD_AGE_VERIFICATION_HAT_ICON}
                alt="hat icon"
              />
              {dictionary.YOTI_POP_UP_HAT_CONTENT}
            </li>
            <li>
              <img
                src={imagesConstants.HARD_AGE_VERIFICATION_SUNGLASSES_ICON}
                alt="sunglasses icon"
              />
              {dictionary.YOTI_POP_UP_GLASSES_CONTENT}
            </li>
          </ul>
        </div>
      </CustomAlert>
    </div>
  );
}
