import { isEmpty, isFunction, isNil } from "lodash";
import moment from "moment";
import { calcAgeRange, getUsageTrackerOptInDate } from ".";
import {
  dashboardActions,
  getUserData,
} from "../../_actions";
import {
  getCountry,
  getDeviceSpec,
} from "../../_actions/appConfig.actions";
import {
  MODE_BALANCED,
  MODE_BOLD,
  MODE_SMOOTH,
  PROFILE_EPOD2,
  PROFILE_WAWE2,
} from "../../_constants";
import { commonsServices, sendRawPuffRecords } from "../../_services";
import { store } from "../../_store";
import { Commons } from "../commons";
import { addCollection, getDbInstance } from "../utils";
import initialize, {
  DailyCollectionName,
  RawCollectionName,
  removeDbInstance,
  StatsCollectionName,
  HourlyCollectionName
} from "../utils/initializeDb";
import JSZip from "jszip";
import CryptoJS from "crypto-js";
import { getDeviceThingFromSN } from "../device";

export async function updateStats(deviceSN, puffRecords) {
  const devices = store.getState().deviceReducer?.devices || [];
  const selectedDevice = devices.find(
    (device) => device.serialNumber === deviceSN
  );

  if (isNil(selectedDevice)) {
    return;
  }

  const db = await getDbInstance();

  const deviceProfile =
    selectedDevice?.deviceType === PROFILE_EPOD2
      ? PROFILE_WAWE2
      : selectedDevice?.deviceType;
  const deviceSpec = getDeviceSpec(deviceProfile);
  const boldMode = deviceSpec?.cloudSize.find((cl) => cl.type === MODE_BOLD);
  const balancedMode = deviceSpec?.cloudSize.find(
    (cl) => cl.type === MODE_BALANCED
  );
  const smoothMode = deviceSpec?.cloudSize.find(
    (cl) => cl.type === MODE_SMOOTH
  );

  //get last
  const lastRecordDaily = await db[DailyCollectionName].findOne({
    selector: {serial: deviceSN},
    sort: [
      {lastPuffTimestamp: 'desc'}
    ]
  }).exec()

  const lastRecordHourly = await db[HourlyCollectionName].findOne({
    selector: {serial: deviceSN},
    sort: [
      {lastPuffTimestamp: 'desc'}
    ]
  }).exec()

  console.log("[lastRecordDaily] lastRecordDaily: ", lastRecordDaily);
  let recordsDaily = []
  let recordsHourly = []

  let firstPuffOfDaily = null
  let firstPuffOfHourly = null

  for (const puffRecord of puffRecords) {
    const presets = {
      low: 0,
      med: 0,
      high: 0,
    };

    if (puffRecord?.powerLevel <= smoothMode?.value) {
      presets.low++;
      console.debug("[UT Daily Stats] - LOW: ", presets);
    } else if (
      puffRecord?.powerLevel > smoothMode?.value &&
      puffRecord?.powerLevel <= balancedMode?.value
    ) {
      presets.med++;
      console.debug("[UT Daily Stats] - MED: ", presets);
    } else if (
      puffRecord?.powerLevel > balancedMode?.value &&
      puffRecord?.powerLevel <= boldMode?.value
    ) {
      presets.high++;
      console.debug("[UT Daily Stats] - HIGH: ", presets);
    }

    const devicePuffTimestamp = moment(puffRecord.timestamp * 1000).format()

    //check for a record in the same DAY
    //if exist, update it
    //else create a new record for the day
    const foundDailyIndex = recordsDaily.findIndex(record => (
      parseInt(record.day) === parseInt(moment(devicePuffTimestamp).format("D")) &&
      parseInt(record.month) === parseInt(moment(devicePuffTimestamp).format("M")) &&
      parseInt(record.year) === parseInt(moment(devicePuffTimestamp).format("YY"))
    ))

    if (foundDailyIndex !== -1) {
      recordsDaily[foundDailyIndex].low += presets.low
      recordsDaily[foundDailyIndex].med += presets.med
      recordsDaily[foundDailyIndex].high += presets.high
      recordsDaily[foundDailyIndex].total += presets.low + presets.med + presets.high
      recordsDaily[foundDailyIndex].sessions += (moment(devicePuffTimestamp).diff(moment(recordsDaily[foundDailyIndex].lastPuffTimestamp)) > 2 * 60 * 1000) ? 1 : 0
      recordsDaily[foundDailyIndex].lastUpdate = Date.now()
      recordsDaily[foundDailyIndex].lastPuffTimestamp = devicePuffTimestamp
    } else {
      const record = {
        day: parseInt(moment(devicePuffTimestamp).format("D")),
        dayOfWeek: parseInt(moment(devicePuffTimestamp).weekday().toString()),
        month: parseInt(moment(devicePuffTimestamp).format("M")),
        year: parseInt(moment(devicePuffTimestamp).format("YY")),
        serial: deviceSN,
        ...presets,
        total: presets.low + presets.med + presets.high,
        sessions: 1,
        lastUpdate: Date.now(),
        lastPuffTimestamp: devicePuffTimestamp,
      };

      if(isNil(firstPuffOfDaily)){
        firstPuffOfDaily = devicePuffTimestamp
      }

      recordsDaily.push(record)
    }

    //check for a record in the same HOUR of the day
    //if exist, update it
    //else create a new record for the hour of the day
    const foundHourlyIndex = recordsHourly.findIndex(record => (
      parseInt(record.day) === parseInt(moment(devicePuffTimestamp).format("D")) &&
      parseInt(record.month) === parseInt(moment(devicePuffTimestamp).format("M")) &&
      parseInt(record.year) === parseInt(moment(devicePuffTimestamp).format("YY")) &&
      parseInt(record.hour) === parseInt(moment(devicePuffTimestamp).format("H"))+1
    ))

    if (foundHourlyIndex !== -1) {
      recordsHourly[foundHourlyIndex].low += presets.low
      recordsHourly[foundHourlyIndex].med += presets.med
      recordsHourly[foundHourlyIndex].high += presets.high
      recordsHourly[foundHourlyIndex].total += presets.low + presets.med + presets.high
      recordsHourly[foundHourlyIndex].sessions += (moment(devicePuffTimestamp).diff(moment(recordsHourly[foundHourlyIndex].lastPuffTimestamp)) > 2 * 60 * 1000) ? 1 : 0
      recordsHourly[foundHourlyIndex].lastUpdate = Date.now()
      recordsHourly[foundHourlyIndex].lastPuffTimestamp = devicePuffTimestamp
      recordsHourly[foundHourlyIndex].averagePowerLevel = 
        ((recordsHourly[foundHourlyIndex].averagePowerLevel * recordsHourly[foundHourlyIndex].total) + puffRecord?.powerLevel) / recordsHourly[foundHourlyIndex].total
    } else {
      let avgPowerLevel = puffRecord?.powerLevel;

      if(!isNil(lastRecordHourly)){
        let totOld = lastRecordHourly?.total ?? 0;

        if(parseInt(lastRecordHourly.day) === parseInt(moment(devicePuffTimestamp).format("D")) &&
            parseInt(lastRecordHourly.month) === parseInt(moment(devicePuffTimestamp).format("M")) &&
            parseInt(lastRecordHourly.year) === parseInt(moment(devicePuffTimestamp).format("YY")) &&
            parseInt(lastRecordHourly.hour) === (parseInt(moment(devicePuffTimestamp).format("H"))+1) &&
            lastRecordHourly.serial === deviceSN){
              avgPowerLevel = ( (lastRecordHourly.averagePowerLevel*totOld) + avgPowerLevel) / (lastRecordHourly.total + 1)
          }
      }

      const record = {
        hour: (parseInt(moment(devicePuffTimestamp).format("H"))+1),
        day: parseInt(moment(devicePuffTimestamp).format("D")),
        month: parseInt(moment(devicePuffTimestamp).format("M")),
        year: parseInt(moment(devicePuffTimestamp).format("YY")),
        serial: deviceSN,
        ...presets,
        total: presets.low + presets.med + presets.high,
        sessions: 1,
        lastUpdate: Date.now(),
        lastPuffTimestamp: devicePuffTimestamp,
        averagePowerLevel: avgPowerLevel
      };

      if(isNil(firstPuffOfHourly)){
        firstPuffOfHourly = devicePuffTimestamp
      }

      recordsHourly.push(record)
    }
  }

  let localBuffer = getLocalStorageBuffer(deviceSN)

  //start to check on record groups
  if (recordsDaily.length) {
    let toInsert = []
    for (const recordDaily of recordsDaily) {
      //
      if (
        !localBuffer.dailyUpdate &&
        lastRecordDaily &&
        parseInt(lastRecordDaily.day) === parseInt(recordDaily.day) &&
        parseInt(lastRecordDaily.month) === parseInt(recordDaily.month) &&
        parseInt(lastRecordDaily.year) === parseInt(recordDaily.year) &&
        lastRecordDaily.serial === deviceSN
      ) {
        const lpt = lastRecordDaily.lastPuffTimestamp;
        await lastRecordDaily
        .incrementalUpdate({
          $inc: {
            low: recordDaily.low,
            med: recordDaily.med,
            high: recordDaily.high,
            total: recordDaily.total,
            // TODO confrontare con il lastPuffTimestamp della PRIMA puffata, non l'ultima
            sessions: (moment(firstPuffOfDaily).diff(lpt) > 2 * 60 * 1000) ? recordDaily.sessions : (recordDaily.sessions-1)
          }
        })

        await lastRecordDaily
        .incrementalUpdate({
          $set: {
            lastPuffTimestamp: recordDaily.lastPuffTimestamp,
            lastUpdate: recordDaily.lastUpdate
          }
        })

        localBuffer.dailyUpdate = true
        setLocalStorageBuffer(deviceSN, localBuffer)
      } else {
        toInsert.push(recordDaily)
      }
    }
    if (toInsert.length && !localBuffer.dailyInsert) {
      await db[DailyCollectionName].bulkInsert(toInsert)

      localBuffer.dailyInsert = true
      setLocalStorageBuffer(deviceSN, localBuffer)
    }
  }

  if (recordsHourly.length) {
    let toInsert = []
    for (const recordHourly of recordsHourly) {
      if (
        !localBuffer.hourlyUpdate &&
        lastRecordHourly &&
        parseInt(lastRecordHourly.day) === parseInt(recordHourly.day) &&
        parseInt(lastRecordHourly.month) === parseInt(recordHourly.month) &&
        parseInt(lastRecordHourly.year) === parseInt(recordHourly.year) &&
        lastRecordHourly.serial === deviceSN &&
        parseInt(lastRecordHourly.hour) === parseInt(recordHourly.hour)
      ) {
        const lpt = lastRecordHourly.lastPuffTimestamp;
        await lastRecordHourly
        .incrementalUpdate({
          $inc: {
            low: recordHourly.low,
            med: recordHourly.med,
            high: recordHourly.high,
            total: recordHourly.total,
            sessions: (moment(firstPuffOfHourly).diff(lpt) > 2 * 60 * 1000) ? recordHourly.sessions : (recordHourly.sessions-1)
          }
        })

        await lastRecordHourly
        .incrementalUpdate({
          $set: {
            averagePowerLevel: recordHourly.averagePowerLevel,
            lastPuffTimestamp: recordHourly.lastPuffTimestamp,
            lastUpdate: recordHourly.lastUpdate
          }
        })

        localBuffer.hourlyUpdate = true
        setLocalStorageBuffer(deviceSN, localBuffer)
      } else {
        toInsert.push(recordHourly)
      }
    }
    if (toInsert.length && !localBuffer.hourlyInsert) {
      await db[HourlyCollectionName].bulkInsert(toInsert)

      localBuffer.hourlyInsert = true
      setLocalStorageBuffer(deviceSN, localBuffer)
    }
  }
}

function getLocalStorageBuffer(deviceSN) {
  const storageName = 'puffRecordsBuffer' + deviceSN
  const buffer = JSON.parse(localStorage.getItem(storageName))

  return buffer
}

function setLocalStorageBuffer(deviceSN, value) {
  const storageName = 'puffRecordsBuffer' + deviceSN

  localStorage.setItem(storageName, JSON.stringify(value))
}

export async function getDailyStats(deviceSN, date, callback) {
  const db = await getDbInstance();

  console.debug("dbInstance", db);

  let selector = {
    day: parseInt(moment(date).format("D")),
    month: parseInt(moment(date).format("M")),
    year: parseInt(moment(date).format("YY")),
  };

  if (!isNil(deviceSN)) {
    selector = {
      ...selector,
      serial: deviceSN,
    };
  }

  console.debug("dbcollection", db);

  if (!db[DailyCollectionName]) {
    return;
  }

  const query = db[DailyCollectionName].find({
    selector: selector,
  });

  if (moment().isSame(moment(date), "day")) {
    console.debug("[UT Daily Stats] - Same day");

    if (!isNil(window.dailyQuery)) {
      window.dailyQuery.unsubscribe();
    }

    // Subscribe to get live puff records
    window.dailyQuery = query.$.subscribe((res) => {
      if (!isNil(res)) {
        console.debug("[UT Daily Stats] - Res: ", res);
        isFunction(callback) && callback(res);
      }
    });
  } else {
    console.debug("[UT - Daily Stats]: Different day");

    const res = await query.exec();

    if (!isNil(res)) {
      console.debug("[UT Daily Stats] - Res: ", res);
      isFunction(callback) && callback(res);
    }
  }
}

export async function getHourlyStats(deviceSN, date, callback) {
  const db = await getDbInstance();

  console.debug("dbInstance", db);

  let selector = {
    //hour: (parseInt(moment(date).format("H"))+1),
    day: parseInt(moment(date).format("D")),
    month: parseInt(moment(date).format("M")),
    year: parseInt(moment(date).format("YY")),
  };

  console.log('deviceSN', deviceSN)
  if (!isNil(deviceSN)) {
    selector = {
      ...selector,
      serial: deviceSN,
    };
  }

  console.debug("dbcollection", db);

  if (!db[HourlyCollectionName]) {
    return;
  }

  const query = db[HourlyCollectionName].find({
    selector: selector,
  });

  if (moment().isSame(moment(date), "day")) {
    console.debug("[UT Hourly Stats] - Same day");

    if (!isNil(window.hourlyQuery)) {
      window.hourlyQuery.unsubscribe();
    }

    // Subscribe to get live puff records
    window.hourlyQuery = query.$.subscribe((res) => {
      if (!isNil(res)) {
        console.debug("[UT Hourly Stats] - Res: ", res);
        isFunction(callback) && callback(res);
      }
    });
  } else {
    console.debug("[UT - Hourly Stats]: Different day");

    const res = await query.exec();

    if (!isNil(res)) {
      console.debug("[UT Hourly Stats] - Res: ", res);
      isFunction(callback) && callback(res);
    }
  }
}

export async function getLastUpdate(deviceSN, callback) {
  const db = await getDbInstance();

  const selector = {
    serial: deviceSN,
  };

  console.debug("[UT Get Last Update] - Query");

  if (!db[DailyCollectionName]) {
    return;
  }

  const query = db[DailyCollectionName].find({
    selector: selector,
    sort: [{ lastUpdate: "desc" }],
    limit: 1,
  });

  const res = await query.exec();

  if (!isNil(res)) {
    console.debug("[UT Get Last Update] - Res: ", res);
    isFunction(callback) && callback(res?.[0]?.lastUpdate);
  }
}

export async function getDailyAvgTotal(deviceSerial, callback) {
  const db = await getDbInstance();

  let selector = {
    lastUpdated: moment().format("L"),
  };

  if (!db[StatsCollectionName]) {
    return;
  }

  // Check if daily stats for today are already available
  const queryDailyStats = db[StatsCollectionName].find({
    selector: selector,
  });
  const results = await queryDailyStats.exec();
  console.log("results di stats");
  console.log(results);
  if (!isNil(results) && results.length !== 0) {
    console.log("Trovati dati statistici");
    console.log(results);

    if (!isNil(deviceSerial)) {
      // Check if it's present for desired serial
      const found = results.find((e) => e.serial == deviceSerial);
      if (!isNil(found)) {
        console.log("dailyAvg: " + found.dailyAvg);
        console.log("dailySessAvg: " + found.dailySessAvg);
        isFunction(callback) && callback(found.dailyAvg, found.dailySessAvg);
      } else {
        manageCalcDailyAvg(db, deviceSerial, callback);
      }
    } else {
      // The request was for all devices, so I need to aggregate
      let dailyAvgAggregated = results.reduce((ret, object) => {
        return ret + (object.dailyAvg || 0);
      }, 0);
      let dailySessAvgAggregated = results.reduce((ret, object) => {
        return ret + (object.dailySessAvg || 0);
      }, 0);

      isFunction(callback) &&
        callback(
          // Math.round(dailyAvgAggregated / results.length),
          // Math.round(dailySessAvgAggregated / results.length)
          Math.round(dailyAvgAggregated),
          Math.round(dailySessAvgAggregated)
        );
    }
  } else {
    // The values have not been yet re-calculated for today, so I process them
    console.debug("DAILY_AVG_NOT_CALCULATED", db, deviceSerial);
    await manageCalcDailyAvg(db, deviceSerial, callback);
  }
}

export async function manageCalcDailyAvg(r, deviceSerial, callback) {
  // We have to exclude today
  let day = moment().format("D");
  let month = moment().format("M");
  let year = moment().format("YY");

  const regexp = "^((?!" + day + "\\|" + month + "\\|" + year + ").)*$";
  const selector = {
    id: {
      $regex: regexp,
    },
  };
  const queryAvg = r[DailyCollectionName].find({
    selector: selector,
  });

  const results = await queryAvg.exec();

  console.log("manageCalcDailyAvg results", results);
  console.log(results);
  if (null !== results && results.length > 0) {
    // Create an array with serial as key as the sum of the total puffs as values
    let dailyStatsDevices = [];
    dailyStatsDevices = Array.from(
      results.reduce(
        (m, { serial, total }) => m.set(serial, (m.get(serial) || 0) + total),
        new Map()
      ),
      ([serial, total]) => ({ serial, total })
    );

    // Create an array with serial as key as the sum of the sessions as values
    let dailyStatsSessDevices = [];
    dailyStatsSessDevices = Array.from(
      results.reduce(
        (m, { serial, sessions }) =>
          m.set(serial, (m.get(serial) || 0) + sessions),
        new Map()
      ),
      ([serial, sessions]) => ({ serial, sessions })
    );

    // Retrieve all the possibile serials and the number of day each
    var daysPerserials = results.reduce(function (obj, v) {
      // increment or set the property
      // `(obj[v.status] || 0)` returns the property value if defined
      // or 0 ( since `undefined` is a falsy value
      obj[v.serial] = (obj[v.serial] || 0) + 1;
      // return the updated object
      return obj;
      // set the initial value as an object
    }, {});

    console.log("dailyStatsDevices");
    console.log(dailyStatsDevices);
    console.log("dailyStatsSessDevices");
    console.log(dailyStatsSessDevices);
    console.log("daysPerSerials");
    console.log(daysPerserials);

    // For each device (serial), calc the avarages
    if (!isNil(daysPerserials)) {
      let statsDailyAvgTotal = 0;
      let statsDailySessTotal = 0;
      let counterDevice = 0;

      const usageTrackerOptinDate = getUsageTrackerOptInDate();
      
      const daysAfterOptin = moment()
        .startOf("day")
        .diff(moment(usageTrackerOptinDate).startOf("day"), "days");

      console.debug("Usage Tracker Days After Optin", daysAfterOptin);

      for (var serial of Object.keys(daysPerserials)) {
        //console.log(res[serial]);
        const found = dailyStatsDevices.find((e) => e.serial == serial);
        //console.log(found.total);
        //console.log("media di " + serial);
        let avgPuffs = 0;

        if (daysAfterOptin > 0) {
          avgPuffs = Math.round(found.total / daysAfterOptin);
        }

        console.log("Media puffate giornaliere " + serial + ": " + avgPuffs);

        const foundSess = dailyStatsSessDevices.find((e) => e.serial == serial);
        let avgSess = 0;

        if (daysAfterOptin > 0) {
          avgSess = Math.round(foundSess.sessions / daysAfterOptin);
        }

        console.log("Media sessioni giornalierie " + serial + ": " + avgSess);

        let objDevice = {
          serial: serial,
          dailyAvg: avgPuffs,
          dailySessAvg: avgSess,
          lastUpdated: moment().format("L"),
        };

        r[StatsCollectionName].upsert(objDevice);

        if (!isNil(deviceSerial) && deviceSerial == serial) {
          isFunction(callback) && callback(avgPuffs, avgSess);
        }

        statsDailyAvgTotal += avgPuffs;
        statsDailySessTotal += avgSess;
        counterDevice++;
      }

      if (isNil(deviceSerial)) {
        console.debug(
          "Valori della media se deviceSerial è null",
          statsDailyAvgTotal,
          statsDailySessTotal
        );
        isFunction(callback) &&
          callback(
            Math.round(statsDailyAvgTotal),
            Math.round(statsDailySessTotal)
          );
      }
    }
  } else {
    callback();
  }
}

export async function getExportedDb() {
  try {
    const db = await getDbInstance();

    const daily = await db[DailyCollectionName].exportJSON();
    console.log("[getExportedDb] daily", JSON.stringify(daily));

    const stats = await db[StatsCollectionName].exportJSON();
    console.log("[getExportedDb] stats", JSON.stringify(stats));

    const hourly = await db[HourlyCollectionName].exportJSON();
    console.log("[getExportedDb] hourly", JSON.stringify(hourly));

    return [JSON.stringify(daily), JSON.stringify(stats), JSON.stringify(hourly)];
  } catch (e) {
    console.log("[getExportedDb] error", e.message);
  }
}

export async function updateRawData(deviceSN, puffRecords) {
  const userData = getUserData();
  const devices = store.getState().deviceReducer?.devices || [];
  const selectedDevice = devices.find(
    (device) => device.serialNumber === deviceSN
  );

  if (isNil(selectedDevice) || isEmpty(userData)) {
    return;
  }

  const db = await getDbInstance();

  const userPin = store.getState().onboardingReducer.userPin;
  const tenantUserId = Commons.generateTenantUserId(userPin);
  const deviceThing = getDeviceThingFromSN(selectedDevice?.serialNumber);

  let records = []

  for (const puffRecord of puffRecords) {
    const objRaw = {
      puffId: `${puffRecord?.count}`,
      userId: tenantUserId,
      deviceId: deviceThing?.uuid ?? "",
      deviceFamilyName: selectedDevice?.deviceType,
      firmwareVersion: selectedDevice?.deviceInfo?.firmwareVersion ?? "",
      timestamp: !isNil(puffRecord?.timestamp)
        ? `${puffRecord?.timestamp}`
        : "na",
      duration: `${puffRecord?.duration}`,
      powerLevel: puffRecord?.powerLevel,
      ageRange: calcAgeRange(userData?.dateOfBirth),
      gender: (userData?.gender === "0" || isNil(userData?.gender)) ? "Unspecified" : userData?.gender,
      billingCountry: getCountry(),
      board: selectedDevice?.deviceInfo?.boardIdentifier ?? 1,
    };

    const isObjRawValid = Object.values(objRaw).every(
      (v) => !isNil(v) && v !== ""
    );

    if (isObjRawValid) {
      records.push(objRaw)
    }
  }

  db[RawCollectionName].bulkInsert(records);
}

export async function sendRawData(force = false) {
  const db = await getDbInstance();
  const lastRawDataPublishTs =
    store.getState().dashboardReducer?.usageTracker?.rawDataPublishTs;

  console.debug(
    "PUBLISH_RAW_DATA - lastRawDataPublishTs",
    lastRawDataPublishTs
  );

  if (isNil(lastRawDataPublishTs)) {
    console.debug("PUBLISH_RAW_DATA - isNil lastRawDataPublishTs");
    store.dispatch(
      dashboardActions.setUsageTrackerRawDataPublishTs(`${moment().valueOf()}`)
    );
  } else {
    const hours = moment().diff(moment(parseInt(lastRawDataPublishTs)), "h");

    console.debug("moment: ", moment().valueOf());
    console.debug("moment2: ", lastRawDataPublishTs);
    console.debug("hours: ", hours);

    if (hours >= 24 || force) {
      
      // PUSH to BE
      // Get all stored raw data
      const queryRawData = db[RawCollectionName].find();
      const results = await queryRawData.exec();

      console.debug(
        "%c[pushRawData] results",
        "color: red; font-weight:bold",
        results
      );

      if (!isNil(results) && results.length !== 0) {
        let records = []
        /* Try to push and delete the record */
        results.forEach((raw) => {
          const obj = {
            userID: raw?.userId,
            deviceID: raw?.deviceId,
            deviceFamilyName: raw?.deviceFamilyName,
            firmwareVersion: raw?.firmwareVersion,
            puffID: parseInt(raw?.puffId),
            timestamp: parseInt(raw?.timestamp),
            duration: +parseFloat(parseFloat(raw?.duration).toFixed(1)),
            powerLevel: raw?.powerLevel,
            ageRange: raw?.ageRange,
            gender: raw?.gender,
            billingCountry: raw?.billingCountry,
            boardClassification: raw?.board,
          };

          records.push(obj)
        })

        console.debug(
          "PUBLISH_RAW_DATA - SEND RAW",
          records
        );

        const chunkSize = 500
        for (let i = 0; i < records.length; i += chunkSize) {
          const chunk = records.slice(i, i + chunkSize)
          //send chunk of the size of 500 records max
          const [putRawResponse, putRawError] = await sendRawPuffRecords(chunk);

          if (isNil(putRawError)) {
            console.debug("PUBLISH_RAW_DATA - BE RES NO ERR");

            await db[RawCollectionName].bulkRemove(chunk.map(r => r.puffID))
          }
        }
      }

      // Update last push data to BE
      store.dispatch(
        dashboardActions.setUsageTrackerRawDataPublishTs(
          `${moment().valueOf()}`
        )
      );
    }
  }
}

export async function getWeeklyStats(deviceSN, date, devices, callback) {
  const db = await getDbInstance();

  console.debug("WEEKLY_STATS - date", date);

  // Prepare the filter for the selector with all the dates to query
  const days = [];
  let weekStartDate = moment(date).startOf("week");

  console.debug("WEEKLY_STATS - weekStartDay", weekStartDate.date());

  for (let i = 0; i <= 6; i++) {
    let currDate = moment(weekStartDate).add(i, "days");
    console.debug("WEEKLY_STATS - currDate", currDate.date());

    const obj = {
      day: Number(currDate.format("D")),
      month: Number(currDate.format("M")),
      year: Number(currDate.format("YY")),
    };

    days.push(obj);
  }

  console.debug("WEEKLY_STATS - days", days);

  let selector = {
    $or: days,
  };

  // If we have the serial of the device, add it to the selector
  if (!isNil(deviceSN)) {
    selector = {
      ...selector,
      serial: deviceSN,
    };
  }

  const queryWeekly = db[DailyCollectionName].find({
    selector: selector,
  });

  if (moment().format("L").toString() === moment(date).format("L").toString()) {
    if (!isNil(window.weeklyQuery)) {
      window.weeklyQuery.unsubscribe();
    }

    window.weeklyQuery = queryWeekly.$.subscribe((results) => {
      if (null !== results) {
        console.log(results);

        if (isFunction(callback)) {
          const data = manageWeeklyResult(deviceSN, results, devices);
          callback(data);
        }
      }
    });
  } else {
    const results = await queryWeekly.exec();
    if (null !== results) {
      console.log(results);

      if (isFunction(callback)) {
        const data = manageWeeklyResult(deviceSN, results, devices);
        callback(data);
      }
    }
  }
}

function manageWeeklyResult(deviceSN, results, devices) {
  console.debug("results_weekly", results);
  // Total puffs per day of week
  let puffsPerDayOfWeek = [];
  puffsPerDayOfWeek = Array.from(
    results.reduce(
      (m, { dayOfWeek, total }) =>
        m.set(dayOfWeek, (m.get(dayOfWeek) || 0) + total),
      new Map()
    ),
    ([dayOfWeek, total]) => ({ dayOfWeek, total })
  );

  puffsPerDayOfWeek = puffsPerDayOfWeek.sort(
    ({ dayOfWeek: a }, { dayOfWeek: b }) => a - b
  );
  console.log(puffsPerDayOfWeek);

  // Total puffs of the week
  let totalPuffsWeek = results.reduce((ret, object) => {
    return ret + (object.total || 0);
  }, 0);

  let totalLowWeek = results.reduce((ret, object) => {
    return ret + (object.low || 0);
  }, 0);
  let totalMedWeek = results.reduce((ret, object) => {
    return ret + (object.med || 0);
  }, 0);
  let totalHighWeek = results.reduce((ret, object) => {
    return ret + (object.high || 0);
  }, 0);
  // Total sessions of the week
  let totalSessionsWeek = results.reduce((ret, object) => {
    return ret + (object.sessions || 0);
  }, 0);

  // Avarage puffs per session
  let avgPuffsWeekDeviceSession = Math.round(
    totalPuffsWeek / totalSessionsWeek
  );

  // Avarage puffs per day
  // eg. if the week so far is just till thiursday, i divide the puffs by 5
  const multiplier = isNil(deviceSN) ? devices.length : 1;
  let avgPuffsWeek = Math.round(
    totalPuffsWeek / (puffsPerDayOfWeek.length * multiplier)
  );

  // Avarage number of session per week
  let avgDailySessionPerWeek = Math.round(
    totalSessionsWeek / (puffsPerDayOfWeek.length * multiplier)
  );

  let deviceWeeklySelected = "All";
  if (!isNil(deviceSN)) {
    deviceWeeklySelected = deviceSN;
  }

  return {
    totalPuffsWeek,
    totalLowWeek,
    totalMedWeek,
    totalHighWeek,
    totalSessionsWeek,
    avgPuffsWeekDeviceSession,
    puffsPerDayOfWeek,
    deviceWeeklySelected,
    avgPuffsWeek,
    avgDailySessionPerWeek,
  };
}

export async function sendCalculatedData(forceSending = false) {

  const lastCalcDataPublishTs =
        store.getState().dashboardReducer?.usageTracker?.calcDataPublishTs;

  if (isNil(lastCalcDataPublishTs) && !forceSending) {
    store.dispatch(
      dashboardActions.setUsageTrackerCalcDataPublishTs(
        `${moment().valueOf()}`
      )
    );
    return
  }

  const hours = moment().diff(
    moment(parseInt(lastCalcDataPublishTs)),
    "h"
  );

  console.debug("moment: ", moment().valueOf());
  console.debug("lastCalcDataPublishTs: ", lastCalcDataPublishTs);
  console.debug("hours: ", hours);

  if (forceSending || hours >= 24) {        
    const [jsonDailyDb, jsonStatsDb, jsonHourlyDb] = await getExportedDb();
    const userPin = store.getState().onboardingReducer.userPin;
    const tenantUserId = Commons.generateTenantUserId(userPin);

    console.debug("userPin", userPin);
    console.debug("tenantUserId", tenantUserId);

    console.debug("jsonDailyDb", jsonDailyDb);
    console.debug("jsonStatsDb", jsonStatsDb);
    console.debug("jsonHourlyDb", jsonHourlyDb);

    const jsonDailyDbEncrypted = CryptoJS.AES.encrypt(jsonDailyDb, `${userPin}`);
    const jsonStatsDbEncrypted = CryptoJS.AES.encrypt(jsonStatsDb, `${userPin}`);
    const jsonHourlyDbEncrypted = CryptoJS.AES.encrypt(jsonHourlyDb, `${userPin}`);

    console.debug("jsonDailyDbEncrypted", jsonDailyDbEncrypted);
    console.debug("jsonStatsDbEncrypted", jsonStatsDbEncrypted);
    console.debug("jsonHourlyDbEncrypted", jsonHourlyDbEncrypted);

    const filename = `${tenantUserId}.zip`;
    const zip = new JSZip();

    zip.file("daily.json", jsonDailyDbEncrypted.toString());
    zip.file("stats.json", jsonStatsDbEncrypted.toString());
    zip.file("hourly.json", jsonHourlyDbEncrypted.toString());

    zip
      .generateAsync({ type: "blob" })
      .then((content) => {

        console.debug("SEND_CALC_DATA", content);
        /* Get tenantUserId */
        const tenantUserId = Commons.generateTenantUserId(userPin);
        /* Send zip to BE */
        commonsServices.sendCalculatedData(filename, content, tenantUserId);
      })
      .catch((error) => {
        console.error("Errore durante la creazione del file zip:", error);
      });
    }
}

export async function getCalculatedData() {
  const userPin = store.getState().onboardingReducer.userPin;
  const tenantUserId = Commons.generateTenantUserId(userPin);

  const filename = `${tenantUserId}.zip`;
  const [zipResponse, zipError] = await commonsServices.getCalculatedData(
    filename,
    tenantUserId
  );

  if (!isNil(zipResponse)) {
    console.debug("[zip]zipResponse:", zipResponse);

    JSZip.loadAsync(zipResponse).then((zips) => {
      zips.forEach((relativePath, file) => {
        console.debug("[zip]relativePath:", relativePath);
        console.debug("[zip]file:", file);

        if (!file.dir) {
          file.async("string").then(async (content) => {
            const decryptedContent = CryptoJS.AES.decrypt(
              content,
              `${userPin}`
            );
            console.debug("***********");
            console.debug(
              "decryptedContent",
              decryptedContent.toString(CryptoJS.enc.Utf8)
            );

            const db = await getDbInstance();

            if (file.name.includes("daily")) {
              await importDailyDataFromJson(
                db,
                decryptedContent.toString(CryptoJS.enc.Utf8)
              );
            } else if (file.name.includes("stats")) {
              await importStatsDataFromJson(
                db,
                decryptedContent.toString(CryptoJS.enc.Utf8)
              );
            } else if (file.name.includes("hourly")) {
              await importHourlyDataFromJson(
                db,
                decryptedContent.toString(CryptoJS.enc.Utf8)
              );
            }
          });
        }
      });
    });
  }
}

export async function deleteCalculatedData() {
  const userPin = store.getState().onboardingReducer.userPin;
  const tenantUserId = Commons.generateTenantUserId(userPin);

  const filename = `${tenantUserId}.zip`;

  await commonsServices.deleteCalculatedData(filename, tenantUserId);

  const db = await getDbInstance();
  await db.remove();
  removeDbInstance();

  initialize();
}

export async function importDailyDataFromJson(db, data) {
  if (!data) {
    return;
  }

  if (db[DailyCollectionName]) {
    await db[DailyCollectionName].remove();
  }

  await addCollection(db, DailyCollectionName);
  const d = JSON.parse(data);
  console.debug("BE_DATA_DAILY", d);
  // We need to override the old hashSchema because when we remove a collection and then we recreate
  // a new one, we get an error during the import: "the imported json relies on a different schema".
  d.schemaHash = await db[DailyCollectionName].schema.hash;
  db[DailyCollectionName].importJSON(d);
}

export async function importStatsDataFromJson(db, data) {
  if (!data) {
    return;
  }

  if (db[StatsCollectionName]) {
    await db[StatsCollectionName].remove();
  }

  await addCollection(db, StatsCollectionName);
  const d = JSON.parse(data);
  console.debug("BE_DATA_STATS", d);
  // We need to override the old hashSchema because when we remove a collection and then we recreate
  // a new one, we get an error during the import: "the imported json relies on a different schema".
  d.schemaHash = await db[StatsCollectionName].schema.hash;
  db[StatsCollectionName].importJSON(d);
}

export async function importHourlyDataFromJson(db, data) {
  if (!data) {
    return;
  }

  if (db[HourlyCollectionName]) {
    await db[HourlyCollectionName].remove();
  }

  await addCollection(db, HourlyCollectionName);
  const d = JSON.parse(data);
  console.debug("BE_DATA_HOURLY", d);
  // We need to override the old hashSchema because when we remove a collection and then we recreate
  // a new one, we get an error during the import: "the imported json relies on a different schema".
  d.schemaHash = await db[HourlyCollectionName].schema.hash;
  db[HourlyCollectionName].importJSON(d);
}

export async function updateDb() {
  const db = await getDbInstance();

  console.debug("DB_INSTANCE_UPDATE", db);

  const query = db[DailyCollectionName].find({
    sort: [{ lastPuffTimestamp: "desc" }],
    limit: 1,
  });
  const results = await query.exec();

  console.debug("UPDATE_DB_RES", results);

  if (results.length <= 0) {
    getCalculatedData();
  }
}

export async function removeDb() {
  const db = await getDbInstance();
  db.remove();
}

export async function updateCalculatedData() {
  const db = await getDbInstance();

  console.debug("UPDATE_CALCULATED_DATA");
  console.debug("UPDATE_CALCULATED_DATA_DB", db);
  console.debug(
    "UPDATE_CALCULATED_DATA_COLLECTIONS",
    db[DailyCollectionName],
    db[StatsCollectionName],
    db[HourlyCollectionName]
  );

  if (db[DailyCollectionName] && db[StatsCollectionName] && db[HourlyCollectionName]) {
    sendCalculatedData(true);
  }
}

export async function getMonthlyStats(deviceSN, date, devices, callback) {
  const db = await getDbInstance();
  const days = [];

  for (let i = 0, l = moment(date).daysInMonth(); i < l; i++) {
    const currDate = moment(date).date(i + 1);

    days.push({
      day: parseInt(currDate.format("D")),
      month: parseInt(currDate.format("M")),
      year: parseInt(currDate.format("YY")),
    });
  }

  console.debug("getMonthlyStats_days", days);

  let selector = {
    $or: days,
  };

  if (!isNil(deviceSN)) {
    selector = {
      ...selector,
      serial: deviceSN,
    };
  }

  const queryMontly = db[DailyCollectionName].find({
    selector: selector,
  });

  if (moment().format("L").toString() === moment(date).format("L").toString()) {
    console.debug("getMonthlyStats_isCurrentDay", true);

    if (!isNil(window.monthlyQuery)) {
      window.monthlyQuery.unsubscribe();
    }

    window.monthlyQuery = queryMontly.$.subscribe((results) => {
      if (!isNil(results)) {
        console.debug("getMonthlyStats_monthlyQuery_results", results);
        const data = manageMontlyResult(deviceSN, results, devices);
        console.debug("getMonthlyStats_monthlyQuery_data", data);

        if (isFunction(callback)) {
          callback(data);
        }
      }
    });
  } else {
    console.debug("getMonthlyStats_isCurrentDay", false);

    const results = await queryMontly.exec();

    if (!isNil(results)) {
      console.debug("getMonthlyStats_monthlyQuery_results", results);
      const data = manageMontlyResult(deviceSN, results, devices);
      console.debug("getMonthlyStats_monthlyQuery_data", data);

      if (isFunction(callback)) {
        callback(data);
      }
    }
  }
}

export function manageMontlyResult(deviceSN, results, devices) {
  // Total puffs per day of week
  let puffsPerDayOfMonth = [];
  puffsPerDayOfMonth = Array.from(
    results.reduce(
      (m, { day, total }) => m.set(day, (m.get(day) || 0) + total),
      new Map()
    ),
    ([day, total]) => ({ day, total })
  );

  console.log(puffsPerDayOfMonth);
  puffsPerDayOfMonth = puffsPerDayOfMonth.sort(
    ({ day: a }, { day: b }) => a - b
  );

  // Total puffs of the month
  let totalPuffsMonth = results.reduce((ret, object) => {
    return ret + (object.total || 0);
  }, 0);

  let totalLowMonth = results.reduce((ret, object) => {
    return ret + (object.low || 0);
  }, 0);
  let totalMedMonth = results.reduce((ret, object) => {
    return ret + (object.med || 0);
  }, 0);
  let totalHighMonth = results.reduce((ret, object) => {
    return ret + (object.high || 0);
  }, 0);
  // Total sessions of the month
  let totalSessionsMonth = results.reduce((ret, object) => {
    return ret + (object.sessions || 0);
  }, 0);

  // Avarage puffs per session
  let avgPuffsMonthDeviceSession = Math.round(
    totalPuffsMonth / totalSessionsMonth
  );

  // Avarage puffs per day
  // eg. if the week so far is just till thiursday, i divide the puffs by 5
  const multiplier = isNil(deviceSN) ? devices.length : 1;

  let avgPuffsMonth = Math.round(
    totalPuffsMonth / (puffsPerDayOfMonth.length * multiplier)
  );

  // Avarage number of session per month
  let avgDailySessionPerMonth = Math.round(
    totalSessionsMonth / (puffsPerDayOfMonth.length * multiplier)
  );

  let deviceMontlySelected = "All";
  if (!isNil(deviceSN)) {
    deviceMontlySelected = deviceSN;
  }

  return {
    totalPuffsMonth,
    totalLowMonth,
    totalMedMonth,
    totalHighMonth,
    totalSessionsMonth,
    avgPuffsMonthDeviceSession,
    puffsPerDayOfMonth,
    deviceMontlySelected,
    avgPuffsMonth,
    avgDailySessionPerMonth,
  };
}
