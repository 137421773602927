export const onboardingConstants = {
    SEND_BATTERY_INFO:          'onboarding/SEND_BATTERY_INFO',
    SEND_FAVOURITES_INFO:       'onboarding/SEND_FAVOURITES_INFO',
    SEND_UNFAVOURITES_INFO:     'onboarding/SEND_UNFAVOURITES_INFO',
    GET_USER_PROFILE:           'onboarding/GET_USER_PROFILE',
    UPDATE_SELECTED_CATEGORIES: 'onboarding/UPDATE_SELECTED_CATEGORIES',
    GET_APP_VERSION_DATA:       'onboarding/GET_APP_VERSION_DATA',


    PIN_VALIDATION:             'vault/PIN_VALIDATION',
    QUESTION_VALIDATION:        'vault/QUESTION_VALIDATION',
    STORE_PIN:                  'vault/STORE_PIN',
    SECURE_VAULT:               'vault/SECURE_VAULT',
    UPDATE_PIN:                 'vault/UPDATE_PIN',
    UPDATE_VAULT:               'vault/UPDATE_VAULT',
    SET_USER_PIN:               'vault/SET_USER_PIN',
    SET_OLD_USER_TENANT_ID:     'vault/SET_OLD_USER_TENANT_ID',
    SET_IS_RESET_PIN_FLOW:      'vault/SET_IS_RESET_PIN_FLOW',
    SET_IS_CREATING_NEW_PIN:    'vault/SET_IS_CREATING_NEW_PIN',
    SET_USER_EVER_LOGGED:       'onboarding/SET_USER_EVER_LOGGED',
    SET_ACTIVE_COOKIE_POLICY:   'onboarding/SET_ACTIVE_COOKIE_POLICY',
    UPDATE_USER:                'onboarding/UPDATE_USER',
    GET_USER:                   'onboarding/GET_USER',
    GET_USER_RULES:             'onboarding/GET_USER_RULES',
    DELETE_USER_RULE:           'onboarding/DELETE_USER_RULE',
    UPDATE_USER_SETTINGS:       'onboarding/UPDATE_USER_SETTINGS',
}