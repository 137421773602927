import { blogConstants } from "../_constants";
import { blogServices } from "../_services";
import { handleAsyncApiCall } from "./_action.utils";

export const blogActions = {
  getBlogArticles
};

function getBlogArticles(options, successCb, failureCb) {
    return (dispatch) => handleAsyncApiCall(
        dispatch,
        blogConstants.GET_BLOG_ARTICLES,
        blogServices.getBlogArticles(options),
        successCb,
        failureCb
    )
}


