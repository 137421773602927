import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { dashboardActions } from "../../_actions";
import { FeatureTutorial } from "../../_components";
import {
  AnalyticsEvents,
  AnalyticsTutorialNames,
  aemPages,
  routingConstants,
  thingVuseProperties,
  AnalyticsScreenEventNames
} from "../../_constants";
import {
  AEMHelper,
  propertyHeadingDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { getCurrentDevice } from "../../_helpers/device";
import { isUsageTrackerEnabled } from "../../_helpers/usage-tracker";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";

function UsageTrackerTutorial({ usageTrackerTutorial }) {
  const [dictionary, setDictionary] = useState({});
  const [stories, setStories] = useState([]);

  useEffect(() => {
    // logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
    //   screen_name: AnalyticsScreenEventNames.USAGE_TRACKER,
    // });

    logAnalyticsEvent(AnalyticsEvents.TUTORIAL, {tutorial_name: AnalyticsTutorialNames.USAGE_TRACKER})
    const aem = new AEMHelper();

    const device = getCurrentDevice();
    const stories = aem
      .getCarouselUrlsFor(device.deviceType, "USAGE_TRACKER_TUTORIAL")
      .map((url) => ({
        url,
        type: "video",
        muted: true
      }));

    setDictionary(
      aem.getDictionary(aem.getPageName("USAGE_TRACKER_TUTORIAL", device.deviceType) , {
        UT_TUTORIAL_HEADER: propertyHeadingDefaultEmpty,
      })
    );
    setStories(stories);
  }, []);

  return (
    <FeatureTutorial
      title={dictionary.UT_TUTORIAL_HEADER || "USAGE TRACKER"}
      featureIcon={"usage"}
      stories={stories}
      iconAction={null}
      backAction={
        isUsageTrackerEnabled()
          ? routingConstants.USAGE_TRACKER
          : routingConstants.USAGE_TRACKER_OPTIN
      }
      doneTutorialMethod={dashboardActions.setDoneTutorialUsageTracker}
      doneTutorialValue={usageTrackerTutorial}
      thing={thingVuseProperties.USAGE_TRACKER}
      tutorialName={AnalyticsTutorialNames.USAGE_TRACKER}
    />
  )
}

const mapStateToProps = (state) => ({
  usageTrackerTutorial: state.dashboardReducer.tutorials.usageTracker,
});

const component = connect(mapStateToProps, null)(UsageTrackerTutorial);
export { component as UsageTrackerTutorial };
