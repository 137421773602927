import React from 'react';
import "animate.css/animate.min.css";
import { AnalyticsEvents, AnalyticsTargetEventsNames } from '../../_constants';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { NeedHelpPanel } from '../NeedHelpPanel';
import { PageSelectorCTA } from '../PageSelectorCTA';

export const VaultSetupIntro = (props) => {
    const {
        dictionary
    } = props;

    const onClickCreatePin = () => {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CREATE_PIN});
        props.nextStep();
    }

    return (
        <React.Fragment>
            <div className="mt-auto">
                <div className="vault-intro animate__animated animate__slideInDown">
                    <h1>{dictionary.VAULT_HEADER}</h1>
                    <p>{dictionary.VAULT_CONTENT}</p>
                </div>
                <PageSelectorCTA sticky={false} className="mt-4">
                    <div className="d-grid">
                        <button className="btn btn-primary text-uppercase" onClick={onClickCreatePin}>{dictionary.VAULT_BTN_CREATE_0_ctaLabel}</button>
                    </div>
                    <div className="page-selector-cta-url">
                        <NeedHelpPanel
                            link={dictionary.VAULT_PIN_LINK}
                            title={dictionary.VAULT_INFO_HEADER}
                            body={dictionary.VAULT_INFO_CONTENT}
                            analyticsPin={true}
                        />
                    </div>
                </PageSelectorCTA>
            </div>
        </React.Fragment>
    );
}