import React from "react";
import { imagesConstants } from "../../_constants";
import { useFWUpdateContext } from "./FirmwareUpdateContext";
import { PageSelectorCTA } from "../PageSelectorCTA";

export function FirmwareUpdateIntro({ nextStep }) {
  const { dictionary, firmwareData, handleDoThisLater } = useFWUpdateContext();

  return (
    <>
      <div className="sliding-panel-mid">
        <div className="sliding-panel-mid-icon">
          <img src={imagesConstants.FW_UPDATE} alt="fw update icon" />
        </div>
        <div className="sliding-panel-mid-info">
          {dictionary.FW_UPDATE_FOTA_UPDATE_CONTENT}
        </div>
        <div className="sliding-panel-mid-info text-secondary">
          {dictionary.FW_UPDATE_FOTA_UPDATE_INFO}
        </div>
        {firmwareData?.latestFirmware && (
          <div className="sliding-panel-mid-value d-flex align-items-center justify-content-center mt-5">
            <span className="text-truncate">
              {dictionary.FW_UPDATE_VERS}
              {firmwareData?.latestFirmware}
            </span>
          </div>
        )}
      </div>
      <PageSelectorCTA sticky={false} ctaUrl={true} className="mt-auto">
        <div className="d-grid">
          <button
            className="btn btn-warning text-uppercase"
            type="button"
            onClick={nextStep}
          >
            {dictionary.FW_UPDATE_FOTA_BTN_CONTINUE_0_ctaLabel}
          </button>
        </div>
        {firmwareData?.isMandatory === false && (
          <div className="page-selector-cta-url">
            <span className="url-text" onClick={handleDoThisLater}>
              {dictionary.FW_UPDATE_SKIP_LINK}
            </span>
          </div>
        )}
      </PageSelectorCTA>
    </>
  );
}
