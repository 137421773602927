import UrlGenerator from '../_helpers/api/urlGenerator';
import * as Uri from '../_constants/api/uri.constants';
import { CUSTOMER } from '../_constants';
import IoTPlatformUtils from '../_helpers/api/IoTPlatformUtils';
import { getUserData, getVuseUuid } from '../_actions';
import { getGoogleApiKey } from '../_actions/appConfig.actions';
import { axiosRequest } from '../_helpers/api/axiosRequest';

export const dashboardServices = {
    getFirmwareProperties,
    
    getLocationAddress,
    getPackageFirmwareUpdate,
    getNotificationHistory,
    setNotificationRead,
    deleteNotification
};


function getFirmwareProperties(tenantUserId, uuid, vendor) {
    let requestOptions = {
        url: UrlGenerator.createIoTPlatformUrlFor(Uri.firmwareProperties)+`?uuids=${uuid}&vendor=${vendor}`,
        method: 'GET',
        headers:IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
        cache: 'no-cache'
    };

    return axiosRequest(requestOptions);
}

/**
 * Get address for FMV with Google reverse geocoding
 * 
 * @param {Number} lat 
 * @param {Number} long 
 */
async function getLocationAddress(lat, long) {
    let requestOptions = {
        url: process.env.REACT_APP_GOOGLE_REVERSE_GEOCODING+`?latlng=${lat},${long}&key=${getGoogleApiKey()}`,
        method: 'GET',
        cache: 'no-cache'
    };
    return axiosRequest(requestOptions);
}


/**
 * Get package for firmware update
 * @param {String} url 
 */
async function getPackageFirmwareUpdate(url, callback) {
    let requestOptions = {
        url: url,
        method: 'GET',
        responseType: 'blob',
        cache: 'no-cache',
        onDownloadProgress: (event) => {
            callback && callback({
                downloaded: event.loaded,
                total: event.total,
            });
        }
    };
    return axiosRequest(requestOptions);
}



async function getNotificationHistory(tenantUserId, limit, nextPage) {
    let url = UrlGenerator.createIoTPlatformUrlFor(Uri.getNotificationHistory);
    url += `/${getUserData()?.group_id}`;
    url += `?limit=${limit}`;
    if(nextPage !== null && nextPage !== undefined){
        url += `&nextPage=${nextPage}`;
    }
    url += `&uuid=${getVuseUuid()}`;
    url += `&vendor=${CUSTOMER}`;


    let requestOptions = {
        url,
        method: 'GET',
        headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
        cache: 'no-cache'
    };

    return axiosRequest(requestOptions);
}

async function setNotificationRead(tenantUserId, ids) {
    let requestOptions = {
        url: UrlGenerator.createIoTPlatformUrlFor(Uri.notificationRead),
        method: 'POST',
        headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
        data: ids,
        cache: 'no-cache'
    };

    return axiosRequest(requestOptions);
}

async function deleteNotification(tenantUserId, id) {
    let requestOptions = {
        url: UrlGenerator.createIoTPlatformUrlFor(Uri.notificationHide),
        method: 'POST',
        headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
        data: {ids: [id]},
        cache: 'no-cache'
    };

    return axiosRequest(requestOptions);
}