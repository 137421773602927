import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OptInFavourites } from '../../..';
import { onboardingActions, shopActions } from '../../../../_actions';
import { getAEMAssetsUrl } from '../../../../_actions/appConfig.actions';
import { ProductPDP, ShopFavouritesCarousel } from '../../../../_components';
import { AnalyticsEvents, AnalyticsScreenEventNames } from '../../../../_constants';
import { logAnalyticsEvent, logAnalyticsEventOfVariantList } from '../../../../_helpers/analytics/logAnalytics';
import { Commons } from '../../../../_helpers/commons';
import { Notifications } from '../../../../_helpers/notifications';
import { ShopUtils } from '../../../../_helpers/shop';
import { store } from '../../../../_store';
import { ShopManageFavourites } from '../Reorder/ShopManageFavourites';

export const ShopFavouritesList = ({
    items,
    dictionary,
    isAcceptedOptin,
    onAcceptOptin,
    navigateToFlavours,
    onCheckoutFavourites,
    onOpenBasketPanel,
    onStartCheckout,
    openOptinModal
}) => {

    const dispatch = useDispatch();
    const loading = useSelector((state) => state.shopReducer.loadingCatalog);

    const [favourites, setFavourites] = useState([]);
    const [isOpenFavouritesManagementModal, setIsOpenFavouritesManagementModal] = useState(false);
    const [isOpenProductPdp, setIsOpenProductPdp] = useState(false);
    const [currentFlavour, setCurrentFlavour] = useState(null);
    const [isOpenOptinModal, setIsOpenOptinModal] = useState(false);
    const [isEmptyManagement, setIsEmptyManagement] = useState(false);


    useEffect(() => {
        setIsOpenOptinModal(false);
    }, [isAcceptedOptin])

    useEffect(() => {
        setIsOpenOptinModal(openOptinModal);
    }, [openOptinModal])

    useEffect(() => {
        if (isOpenOptinModal) {
            logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
                screen_name: AnalyticsScreenEventNames.FAVOURITES_OPTIN,
            })
        }
    }, [isOpenOptinModal])


    const toggleProductPdp = useCallback((flavour) => {
        console.log("[toggleProductPdp] flavour", flavour);
        console.log("[toggleProductPdp] isOpenProductPdp", isOpenProductPdp);
        console.log("[toggleProductPdp] new value", !isOpenProductPdp);

        if (isOpenProductPdp) {
            setTimeout(() => {
                setCurrentFlavour(null);
            }, 500);
        } else {
            setCurrentFlavour(flavour);
        }

        setIsOpenProductPdp(!isOpenProductPdp);
    }, [isOpenProductPdp]);


    useEffect(() => {
        console.log("[useEffect] isOpenProductPdp", isOpenProductPdp === true);

    }, [isOpenProductPdp])

    const toggleFavouritesManagementModal = () => {
        if (!isOpenFavouritesManagementModal) {
            logAnalyticsEvent(AnalyticsEvents.MANAGE_FAVOURITES)
        }
        setIsOpenFavouritesManagementModal(!isOpenFavouritesManagementModal);
    }

    /**
     * Handle wishlist change
     */
    useEffect(() => {

        console.log("[SHOP] favourites", items);

        if (!_.isEqual(favourites, items)) {
            if (items.length <= 0) {
                //if wishlist changed and turns empty, manage favourites modal doesn't hide
                //but must be cleared and a message is shown
                setIsEmptyManagement(true);
            }

            //reload wishlist
            let currentWishlist = ShopUtils.getUserWishlist();
            setFavourites(items.map(favourite => {
                return ({
                    ...favourite,
                    favouriteQty: currentWishlist[favourite.variant_id] ?? 1
                })
            }))
        }

    }, [items])

    const handleSaveWishlist = (wishlist) => {

        const newWishlist = wishlist.filter(el => !el.removed && el.favouriteQty > 0);

        logAnalyticsEventOfVariantList(AnalyticsEvents.SAVE_PREFERENCES_MANAGE_FAVOURITES, newWishlist);

        //update remote wishlist
        const arrayOfWishlist = newWishlist.map(el => {
            return {
                [el.variant_id]: el.favouriteQty
            }
        })

        const payload = JSON.stringify(Object.assign({}, ...arrayOfWishlist));
        const userPin = store.getState().onboardingReducer.userPin;
        const tenantUserId = Commons.generateTenantUserId(userPin);

        dispatch(shopActions.setWishlist(tenantUserId, payload, () => {

            //update local wishlist
            newWishlist.forEach(variant => {
                if ("removed" in variant) {
                    delete variant.removed;
                }
            })
            dispatch(shopActions.resetVariantsInWhishlist(newWishlist, () => {
                toggleFavouritesManagementModal();

                if (newWishlist?.length <= 0) {
                    const orderReminderRule = Notifications.getOrderReminderNotificationRule();
                    if (orderReminderRule) {
                        dispatch(onboardingActions.deleteRules(tenantUserId, orderReminderRule.id));
                    }
                }
            }));

        }));
    }

    const onCheckoutWishlist = () => {
        if (typeof onCheckoutFavourites === "function") {
            logAnalyticsEvent(AnalyticsEvents.ORDER_ALL_FAVOURITES)
            onCheckoutFavourites();
        }
    }

    const goToFlavoursFromManageFavourites = () => {
        setIsOpenFavouritesManagementModal(false);
        if (typeof navigateToFlavours === 'function') {
            navigateToFlavours();
        }
    }

    const renderOptinToggle = () => {
        return (
            <React.Fragment>
                <div className='reorder-label d-flex justify-content-between py-3'>
                    <div className="label-title">
                        {dictionary.SHOP_REORDER_MY_FAVOURITES_TITLE}
                    </div>
                </div>

                <div className="shop-reorder-feature-toggle-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-9">
                                <div className="reorder-favourites-text">
                                    {dictionary.SHOP_REORDER_MY_FAVOURITES_CONTENT}
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="favouritesOptCheck"
                                        checked={isAcceptedOptin}
                                        onChange={() => setIsOpenOptinModal(true)} />
                                    <label className="form-check-label" htmlFor="favouritesOptCheck"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <OptInFavourites isOpen={isOpenOptinModal}
                    onAcceptOptin={onAcceptOptin}
                    onClose={() => setIsOpenOptinModal(false)}
                    onDismiss={() => setIsOpenOptinModal(false)}
                />
            </React.Fragment>
        )
    }

    const renderNoFavouritesBadge = () => {
        const onViewFlavoursButtonClick = () => {
            logAnalyticsEvent(AnalyticsEvents.ADD_FAVOURITES_VIEW_FLAVOURS)
            navigateToFlavours();
        }
        return (
            <React.Fragment>
                <div className='reorder-label d-flex justify-content-between py-3'>
                    <div className="label-title">
                        {dictionary.SHOP_REORDER_MY_FAVOURITES_TITLE}
                    </div>
                </div>

                <div className="row d-flex justify-content-start mx-2">
                    <div className="col-12">
                        <div className="rounded-3" style={{
                            backgroundImage: `url("${getAEMAssetsUrl()}${dictionary.SHOP_BG_FAVOURITES?.desktop?.src}")`
                        }}>
                            <div className='shop-reorder-feature-badge' style={{
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)'
                            }}>
                                <h2>{dictionary.SHOP_REORDER_ADD_FAV_HEADER}</h2>
                                <span className='shop-reorder-feature-badge-desc'>{dictionary.SHOP_REORDER_ADD_FAV_CONTENT}</span>
                                <button className='btn btn-sm btn-primary w-50' onClick={onViewFlavoursButtonClick}>{dictionary.SHOP_REORDER_ADD_FAV_BTN_VIEW_FLAV_0_ctaLabel}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const renderLoading = () => {
        return (
            <React.Fragment>
                <div className='reorder-label d-flex justify-content-between py-3'>
                    <div className="label-title">
                        {dictionary.SHOP_REORDER_MY_FAVOURITES_TITLE}
                    </div>
                </div>

                <div className="d-flex justify-content-center mt-2">
                    <div className="spinner-border text-secondary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const renderFavouritesList = () => {
        return (
            <React.Fragment>
                <div className='reorder-label d-flex justify-content-between py-3'>
                    <div className="label-title">
                        {dictionary.SHOP_REORDER_MY_FAVOURITES_TITLE}
                    </div>
                    <button className='btn reorder-label-btn'
                        onClick={toggleFavouritesManagementModal}>
                        {dictionary.SHOP_REORDER_MANAGE}
                    </button>
                </div>
                <ShopFavouritesCarousel items={favourites} onOpenPdp={toggleProductPdp} dictionary={dictionary} />
                <button className='btn btn-primary d-block reorder-favourites-btn'
                    onClick={onCheckoutWishlist}>{dictionary.SHOP_REORDER_BTN_ORDER_ALL_FAV_0_ctaLabel}</button>

                <ProductPDP isOpen={isOpenProductPdp}
                    onClosePdp={toggleProductPdp}
                    product={currentFlavour}
                    wishlist={items}
                    onOpenBasket={onOpenBasketPanel}
                    onStartCheckout={onStartCheckout}
                />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {
                !isAcceptedOptin ?
                    renderOptinToggle() :
                    loading ?
                        renderLoading()
                        :
                        items?.length > 0 ?
                            renderFavouritesList()
                            : renderNoFavouritesBadge()
            }
            {
                (items?.length > 0 || isEmptyManagement) &&
                <ShopManageFavourites isOpen={isOpenFavouritesManagementModal}
                    closeFeatureModal={toggleFavouritesManagementModal}
                    dictionary={dictionary}
                    wishlist={items}
                    onSaveWishlist={handleSaveWishlist}
                    onOpenBasket={onOpenBasketPanel}
                    onStartCheckout={onStartCheckout}
                    navigateToFlavours={goToFlavoursFromManageFavourites}
                />
            }
        </React.Fragment >
    );

}

