import { loginConstants } from '../_constants';
import { REQUEST, SUCCESS, FAILURE } from './action-type.util'

export const initialState = {
    loading: false,
    data: null,
    error: null,

};

export function loginReducer(state = initialState, action) {
    switch (action.type) {


        case 'LOGOUT': {
            return {
                loading: false,
                data: null,
                error: null,
            };
        }

        /**
         * Do Something
         */
        case REQUEST(loginConstants.LOGIN):
            return {
                ...state,
                loading: true
            };

        case SUCCESS(loginConstants.LOGIN):
            return {
                ...state,
                data: action.payload,
                loading: false
            };

        case FAILURE(loginConstants.LOGIN):
            return {
                ...state,
                error: action.error,
                loading: false
            };

        case REQUEST(loginConstants.RESET_PASSWORD):
            return {
                ...state,
                loading: true
            };

        case SUCCESS(loginConstants.RESET_PASSWORD):
            return {
                ...state,
                data: action.payload,
                loading: false
            };

        case FAILURE(loginConstants.RESET_PASSWORD):
            return {
                ...state,
                error: action.error,
                loading: false
            };

        default:
            return state;
    }
}