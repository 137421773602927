import React, { useEffect, useState } from "react";
import { getUserData } from "../../_actions";

const UserAvatar = () => {
  const [initials, setInitials] = useState("");

  useEffect(() => {
    const userData = getUserData();
    const { firstName, lastName } = userData;

    if (!firstName || !lastName) {
      return;
    }

    setInitials(firstName.charAt(0) + lastName.charAt(0));
  }, []);

  return <div className="user-avatar">{initials}</div>;
};

export { UserAvatar };
