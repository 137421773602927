import React from 'react';
import { getUrl } from '../../_actions/appConfig.actions';
import { webviewUrl } from '../../_constants';
import { WebView } from '../../_components';
import { history } from '../../_helpers/history';
import { getURLSearchObject } from '../../_helpers/navigation';

export class SubscriptionTermsAndConditions extends React.Component {
  getBackHandler() {
    const { canHandleBack } = getURLSearchObject();
    if (canHandleBack) {
      return () => history.goBack();
    } else {
      return null;
    }
  }

  render() {
    return (
      <WebView
        title={'SUBSCRIPTION TERMS AND CONDITIONS'}
        handleBack={this.getBackHandler.bind(this)()}
        url={getUrl(webviewUrl.WEBVIEW_SUB_TAC)}
        id="webViewIframe"
        className="webViewIframe"
      />
    );
  }
}
