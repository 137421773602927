import cx from "classnames";
import React, { forwardRef, useEffect, useState } from "react";
import { FirmwareUpdate } from "./FirmwareUpdate";
import { updateDeviceInReducer } from "../../_helpers/device";
import { isFunction } from "lodash";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { AnalyticsEvents, AnalyticsTargetEventsNames } from "../../_constants";

const FirmwareUpdateCTA = forwardRef(
  ({ className, style, device, onStart = null, onSuccess = null, onError, children, isButtonVisible }, ref) => {
    const [isShownFirmwareUpgradePanel, setIsShownFirmwareUpgradePanel] = useState(false);

    return (
      <>
        {isButtonVisible &&
        <button
          ref={ref}
          type="button"
          {...(style && { style })}
          className={cx(
            "btn btn-warning text-uppercase firmware-cta",
            className
          )}
          onClick={() => {
            logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.FIRMWARE_UPGRADE})
            updateDeviceInReducer(device.serialNumber, { ongoingUpdate: true })
            setIsShownFirmwareUpgradePanel(true)
            isFunction(onStart) && onStart()
          }}
        >
          {children}
        </button>
        }
        {isShownFirmwareUpgradePanel && (
          <FirmwareUpdate
            show={isShownFirmwareUpgradePanel}
            onClose={() => {
              setIsShownFirmwareUpgradePanel(false)
              isFunction(onSuccess) && onSuccess()
            }}
            fromCTA={true}
          />
        )}  
      </>
    );
  }
);

export { FirmwareUpdateCTA };