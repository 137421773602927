
import { AnalyticsScreenEventNames, routingConstants } from "../../_constants";

export const SCREEN_VIEW_ANALYTICS = {
  [routingConstants.COUNTRY_SELECTOR]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.COUNTRY_SELECTOR,
    }
  },
  [routingConstants.SOFT_AGE_VERIFICATION]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.SOFT_AGE_VERIFICATION,
    }
  },
  [routingConstants.UNDERAGE]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.SOFT_AGE_VERIFICATION_FAILED,
    }
  },
  [routingConstants.BENEFITS]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.BENEFITS,
    }
  },
  [routingConstants.LOGIN]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.LOGIN,
    }
  },
  [routingConstants.TERMS_AND_CONDITIONS]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.TERMS_AND_CONDITIONS,
    }
  },
  [routingConstants.TERMS_AND_CONDITIONS_AND_PRIVACY_POLICY]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.TERMS_AND_CONDITIONS_AND_PRIVACY_POLICY,
    }
  },
  [routingConstants.NOTIFICATION_INTRO]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.NOTIFICATION_PREFERENCES_INTRO,
    }
  },
  [routingConstants.MANAGE_NOTIFICATIONS]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.MANAGE_NOTIFICATIONS,
    }
  },
  [routingConstants.YOUR_PREFERENCES]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.YOUR_PREFERENCES,
    }
  },
  [routingConstants.DEVICE]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.HOME,
    }
  },
  [routingConstants.MANAGE_DEVICES]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.MANAGE_DEVICES,
    }
  },
  [routingConstants.DEVICE_SETTINGS]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.DEVICE_SETTINGS,
    }
  },
  [routingConstants.DEVICE_NAME]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.DEVICE_NAME,
    }
  },
  [routingConstants.ADVANCED_SETTINGS]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.ADVANCED_SETTINGS,
    }
  },
  [routingConstants.CLOUD_CONTROL]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.CLOUD_CONTROL,
    }
  },
  [routingConstants.CLOUD_CONTROL_TUTORIAL]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.CLOUD_CONTROL_TUTORIAL,
    }
  },
  [routingConstants.FIND_MY_VAPE]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.FIND_MY_VAPE,
    }
  },
  [routingConstants.FIND_MY_VAPE_OPTIN]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.FIND_MY_VAPE_OPTIN,
    }
  },
  [routingConstants.BATTERY_SETTINGS]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.RECHARGE_REMINDERS,
    }
  },
  [routingConstants.MANAGE_ACCOUNT]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.MANAGE_ACCOUNT,
    }
  },
  [routingConstants.PREVIOUS_ORDERS]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.PREVIOUS_ORDERS,
    }
  },
  [routingConstants.CONTACT]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.CONTACT_US,
    }
  },
  [routingConstants.PRIVACY_POLICY]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.PRIVACY_POLICY,
    }
  },
  [routingConstants.COOKIE_POLICY]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.COOKIE_POLICY,
    }
  },
  [routingConstants.CONDITIONS_OF_SALE]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.CONDITIONS_OF_SALE,
    }
  },
  [routingConstants.SUBSCRIPTION_TERMS_AND_CONDITIONS]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.SUBSCRIPTION_TERMS_AND_CONDITIONS,
    }
  },
  [routingConstants.TERMS_AND_CONDITIONS_LIST]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.TERMS_AND_CONDITIONS_LIST,
    }
  },
  [routingConstants.DEVICE_FAQ]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.DEVICE_FAQ,
    }
  },
  [routingConstants.STORE_LOCATOR]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.STORE_LOCATOR,
    }
  },
  [routingConstants.EDIT_ACCOUNT]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.EDIT_ACCOUNT,
    }
  },
  [routingConstants.FAQ]: {
    event_params: {
      screen_name: AnalyticsScreenEventNames.FAQ,
    }
  },
};