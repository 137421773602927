import React, { useEffect } from 'react';
import { imagesConstants, aemPages, AnalyticsEvents, AnalyticsFieldNames, AnalyticsTargetEventsNames, routingConstants, AnalyticsScreenEventNames } from '../../_constants';
import { useForm } from "react-hook-form";
import { useState } from 'react';
import { CustomSlidingPanel, PageSelectorCTA } from '..';
import { store } from '../../_store';
import { buildURI } from '../../_helpers/navigation';
import { history } from '../../_helpers/history';
import parse from 'html-react-parser';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';

export const ResetPasswordForm = (props) => {

    const { register, handleSubmit, watch, setValue } = useForm();
    const { email } = watch();

    const [showResetRequestResult, setShowResetRequestResult] = useState(false);
    const [showCheckEmailPanel, setShowCheckEmailPanel] = useState(false);
    const [userEmail, setUserEmail] = useState("");

    useEffect(() => {
        if (props.userEmail) {
            setValue("email", props.userEmail);
        }
    }, [props.userEmail]);

    const onSubmit = () => {
        setUserEmail(email);
        if (typeof props.onForgotPassword === 'function') {
            props.onForgotPassword(email);
            logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NEXT})
        }
    };

    const isFormValid = (email) => {
        if (showResetRequestResult) {
            return false;
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            return false;
        }

        return true;
    }

    useEffect(() => {
        setShowResetRequestResult(props.resetPasswordResult);
    }, [props.resetPasswordResult]);

    useEffect(() => {
        setShowCheckEmailPanel(showResetRequestResult);

        if(showResetRequestResult === true) {
            logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.RESET_PASSWORD_EMAIL_SENT})
        }
    }, [showResetRequestResult]);


    const handleChangeEmail = () => {
        setShowResetRequestResult(false);
    }

    const goBackToLogin = () => {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NEXT});
        history.push(buildURI(routingConstants.LOGIN));
    }

    let LOGIN_RESET_CONTENT = props.page?.getComponent(aemPages.RESET_PWD, 'LOGIN_RESET_CONTENT');
    let LOGIN_EMAIL_LABEL = props.page?.getComponent(aemPages.RESET_PWD, 'LOGIN_EMAIL_LABEL');
    let LOGIN_EMAIL_PLACEHOLDER = props.page?.getComponent(aemPages.RESET_PWD, 'LOGIN_EMAIL_PLACEHOLDER');
    let LOGIN_BTN_RESEND_LINK = props.page?.getComponent(aemPages.RESET_PWD, 'LOGIN_BTN_RESEND_LINK');
    let PWD_RST_BTN_RESEND = props.page?.getComponent(aemPages.RESET_PWD, 'PWD_RST_BTN_RESEND');

    let LOGIN_BTN_RESEND_LINK_ctaItems = LOGIN_BTN_RESEND_LINK?.getRaw('ctaItems', null);
    let LOGIN_BTN_RESEND_LINK_ctaLabel = null;

    if (LOGIN_BTN_RESEND_LINK_ctaItems?.length > 0) {
        LOGIN_BTN_RESEND_LINK_ctaLabel = LOGIN_BTN_RESEND_LINK_ctaItems[0].ctaLabel;
    }

    /* Reset pwd confirm email */
    let PWD_RST_HEADER = props.page?.getComponent(aemPages.RESET_PWD, 'PWD_RST_HEADER');
    let PWD_RST_CONTENT = props.page?.getComponent(aemPages.RESET_PWD, 'PWD_RST_CONTENT');
    let PWD_RST_BTN_DISMISS = props.page?.getComponent(aemPages.RESET_PWD, 'PWD_RST_BTN_DISMISS');

    const handleBlur = () => {
        logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.EMAIL})
    }

    return (
        <React.Fragment>

            <div className="signup-wrapper h-100">

                <form className="signup-form h-100 d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>


                    <div className="signup-form-wrapper">


                        <div className="signup-form-disclaimer reset-passord">
                            {parse(LOGIN_RESET_CONTENT?.getRaw('text', '') ?? '')}
                        </div>

                        <div className="signup-form-row">
                            <label className="page-label" htmlFor="resetPasswordEmail">{parse(LOGIN_EMAIL_LABEL?.getRaw('text', '') ?? '')}</label>
                            <input name="email" id="resetPasswordEmail"
                                className="form-control"
                                type="email"
                                placeholder={LOGIN_EMAIL_PLACEHOLDER?.getRaw('text', null)}
                                ref={register({ required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                                onChange={handleChangeEmail} 
                                onBlur={handleBlur}    
                            />
                        </div>

                        <div className="form-cta-bottom">
                            <div className="form-group mb-0">
                            </div>
                        </div>
                    </div>
                    <PageSelectorCTA>
                        <div className="d-grid">
                            {
                                store.getState().loginReducer.loading ?
                                    <button className="btn btn-primary text-uppercase" id="sendResetPassswordBtn" type="button"
                                        style={{ paddingTop: "0.6rem", paddingBottom: "0.6rem" }}>
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </button>
                                    :
                                    <button className="btn btn-primary text-uppercase" id="sendResetPassswordBtn" type="submit" disabled={!isFormValid(email)}>{LOGIN_BTN_RESEND_LINK_ctaLabel}</button>
                            }

                        </div>
                    </PageSelectorCTA>
                </form>
            </div>
            <CustomSlidingPanel from="bottom"
                overlayClassName={"sliding-common-wrapper"}
                isOpen={showCheckEmailPanel === true}
                className="onboarding-panel mid-height-panel"
                title={PWD_RST_HEADER?.getRaw('heading', null)}
                onClose={() => props.resetResult()}>

                <div className="h-100 d-flex flex-column">

                    <div className="sliding-panel-mid">
                        <div className="sliding-panel-mid-icon">
                            <img src={imagesConstants.NEW_EMAIL} alt="email" />
                        </div>
                        <div className="sliding-panel-mid-info">
                            {parse(PWD_RST_CONTENT?.getRaw('text', null) ?? '')}
                        </div>
                        <div className="sliding-panel-mid-value d-flex align-items-center justify-content-center">
                            <span className="text-truncate">{userEmail}</span>
                        </div>
                    </div>
                    <PageSelectorCTA ctaUrl={true}>
                        <div className="d-grid">
                            <button className="btn btn-primary text-uppercase" type="button" onClick={goBackToLogin}>{PWD_RST_BTN_DISMISS?.getRaw('ctaItems', null)[0].ctaLabel}</button>
                        </div>
                        <div className="page-selector-cta-url">
                            <span className="url-text" onClick={onSubmit}>{PWD_RST_BTN_RESEND?.getRaw('text', null) ?? ''}</span>
                        </div>
                    </PageSelectorCTA>
                </div>
            </CustomSlidingPanel>
        </React.Fragment>
    );

}