import {
  dashboardConstants,
  notificationsConstants,
  onboardingConstants,
} from "../_constants";
import { REQUEST, SUCCESS, FAILURE } from "./action-type.util";
import { Commons } from "../_helpers/commons";
import _ from "lodash";

export const initialState = {
  tutorials: {
    battery: false,
    controls: false,
    findmyvape: false,
    unlock: false,
    usageTracker: false,
    lockSwitch: false,
  },
  vuseWorldStories: {},
  loading: false,
  error: null,
  shownLowBatteryTip: false,
  shownBatterySaverOnTip: false,
  shownBatterySaverOffTip: false,
  lastForecastBatteryTip: null,
  rules: [],
  //local notifications
  tips: [],
  //remote notifications
  notifications: [],
  nextPage: null,
  loadingNotifications: false,
  usageTracker: {
    selectedDevice: -1,
    rawDataPublishTs: null,
    calcDataPublishTs: null,
  },
};

export function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case "LOGOUT": {
      return {
        ...state,
        loading: false,
        error: null,
        notifications: [],
        nextPage: null,
        loadingNotifications: false,
      };
    }

    case "RESET_REDUCER": {
      return {
        tutorials: {
          battery: false,
          controls: false,
          findmyvape: false,
          unlock: false,
          usageTracker: false,
          lockSwitch: false,
        },
        loading: false,
        error: null,
        tips: [],
        shownLowBatteryTip: false,
        shownBatterySaverOnTip: false,
        shownBatterySaverOffTip: false,
        lastForecastBatteryTip: null,
        notifications: [],
        nextPage: null,
        loadingNotifications: false,
        vuseWorldStories: {},
        usageTracker: {
          selectedDevice: -1,
          rawDataPublishTs: null,
          calcDataPublishTs: null,
        },
      };
    }

    case dashboardConstants.SET_TUTORIAL_FMV: {
      return {
        ...state,
        tutorials: {
          ...state.tutorials,
          findmyvape: true,
        },
      };
    }

    case dashboardConstants.SET_TUTORIAL_BATTERY: {
      return {
        ...state,
        tutorials: {
          ...state.tutorials,
          battery: true,
        },
      };
    }
    case dashboardConstants.SET_TUTORIAL_CONTROLS: {
      return {
        ...state,
        tutorials: {
          ...state.tutorials,
          controls: true,
        },
      };
    }

    case dashboardConstants.SET_TUTORIAL_UNLOCK: {
      return {
        ...state,
        tutorials: {
          ...state.tutorials,
          unlock: true,
        },
      };
    }

    case dashboardConstants.SET_TUTORIAL_USAGE_TRACKER: {
      return {
        ...state,
        tutorials: {
          ...state.tutorials,
          usageTracker: true,
        },
      };
    }

    case dashboardConstants.SET_TUTORIAL_LOCK_SWITCH: {
      return {
        ...state,
        tutorials: {
          ...state.tutorials,
          lockSwitch: true,
        },
      };
    }

    case dashboardConstants.ADD_TIPS: {
      let toAddTip = action.data;

      // console.log("[dashboardConstants.ADD_TIPS] toAddTip", toAddTip);
      let newTips = [...state.tips];
      // console.log("[dashboardConstants.ADD_TIPS] newTips", JSON.parse(JSON.stringify(newTips)));

      let oldTipIndex = state.tips.findIndex((item) => item.id === toAddTip.id);
      // console.log("[dashboardConstants.ADD_TIPS] oldTipIndex", oldTipIndex);

      if (oldTipIndex !== null && oldTipIndex !== -1) {
        newTips[oldTipIndex] = toAddTip;
      } else {
        newTips = [...newTips, toAddTip];
      }

      // console.log("[dashboardConstants.ADD_TIPS] newTips", newTips);

      return {
        ...state,
        tips: newTips,
      };
    }

    case dashboardConstants.UPDATE_TIP: {
      let toUpdateTip = action.data;
      // console.log("[dashboardConstants.UPDATE_TIP] toAddTip", toUpdateTip);
      let currentTipIdx = state.tips.findIndex(
        (item) => item.id === toUpdateTip.id
      );
      // console.log("[dashboardConstants.UPDATE_TIP] currentTipIdx", currentTipIdx);
      let newTips = [...state.tips];
      // console.log("[dashboardConstants.UPDATE_TIP] newTips", JSON.parse(JSON.stringify(newTips)));
      newTips[currentTipIdx] = toUpdateTip;

      // console.log("[dashboardConstants.UPDATE_TIP] newTips", newTips);
      return {
        ...state,
        tips: newTips,
      };
    }

    case dashboardConstants.REMOVE_TIP: {
      let toRemoveTip = action.data;
      let newTips = state.tips.filter((item) => item.id !== toRemoveTip);

      return {
        ...state,
        tips: newTips,
        loading: false,
      };
    }

    case dashboardConstants.SET_LOW_BATTERY_TIP_SHOWN: {
      return {
        ...state,
        shownLowBatteryTip: action.data,
      };
    }

    case dashboardConstants.SET_BATTERY_SAVER_ON_TIP_SHOWN: {
      return {
        ...state,
        shownBatterySaverOnTip: action.data,
      };
    }

    case dashboardConstants.SET_BATTERY_SAVER_OFF_TIP_SHOWN: {
      return {
        ...state,
        shownBatterySaverOffTip: action.data,
      };
    }

    case dashboardConstants.SET_VUSE_WORLD_STORIES_SEEN: {
      let key = action.data;
      let prevstate = state.vuseWorldStories;
      if (
        state.vuseWorldStories == null ||
        state.vuseWorldStories === undefined
      ) {
        prevstate = {};
      }
      return {
        ...state,
        vuseWorldStories: { ...prevstate, ...{ [key]: true } },
      };
    }

    case dashboardConstants.SET_FORECAST_BATTERY_TIP_SHOWN: {
      return {
        ...state,
        lastForecastBatteryTip: action.data,
      };
    }

    case dashboardConstants.SET_USAGE_TRACKER_SELECTED_DEVICE: {
      console.debug("UT_ACTION", action);
      return {
        ...state,
        usageTracker: {
          ...state.usageTracker,
          selectedDevice: action?.data ?? -1,
        },
      };
    }

    case dashboardConstants.SET_USAGE_TRACKER_RAW_DATA_PUBLISH_TS: {
      console.debug("UT_ACTION", action);
      return {
        ...state,
        usageTracker: {
          ...state.usageTracker,
          rawDataPublishTs: action?.data,
        },
      };
    }

    case dashboardConstants.SET_USAGE_TRACKER_CALC_DATA_PUBLISH_TS: {
      console.debug("UT_ACTION", action);
      return {
        ...state,
        usageTracker: {
          ...state.usageTracker,
          calcDataPublishTs: action?.data,
        },
      };
    }

    case REQUEST(notificationsConstants.CREATE_RULE): {
      return {
        ...state,
        loading: true,
      };
    }

    case FAILURE(notificationsConstants.CREATE_RULE): {
      return {
        ...state,
        loading: false,
      };
    }

    case SUCCESS(notificationsConstants.CREATE_RULE): {
      const oldRules = state.rules ?? [];
      return {
        ...state,
        rules: [...oldRules, action?.payload?.data],
        loading: false,
      };
    }

    case SUCCESS(onboardingConstants.GET_USER_RULES): {
      return {
        ...state,
        rules: action?.payload?.data ?? [],
      };
    }
    case SUCCESS(onboardingConstants.DELETE_USER_RULE): {
      const newRules =
        (state.rules &&
          state.rules.filter((rule) => rule.id !== action?.payload)) ??
        [];
      return {
        ...state,
        rules: newRules,
      };
    }

    case REQUEST(dashboardConstants.GET_NOTIFICATION_HISTORY): {
      return {
        ...state,
        loadingNotifications: true,
      };
    }

    case FAILURE(dashboardConstants.GET_NOTIFICATION_HISTORY): {
      return {
        ...state,
        error: action.error,
        loadingNotifications: false,
      };
    }

    case SUCCESS(dashboardConstants.GET_NOTIFICATION_HISTORY): {
      return {
        ...state,
        notifications: _.uniqBy(
          [...action?.payload?.data?.events, ...state.notifications],
          "event_id"
        ),
        nextPage: action?.payload?.data?.next_page,
        loadingNotifications: false,
      };
    }

    case SUCCESS(dashboardConstants.SET_NOTIFICATION_READ): {
      let updatedNotifications = state.notifications;
      if (Commons.isValidArray(action.payload?.ids)) {
        updatedNotifications = updatedNotifications.map((notif) => {
          if (action.payload.ids.includes(notif.event_id)) {
            return {
              ...notif,
              read: true,
            };
          } else {
            return notif;
          }
        });
      }

      return {
        ...state,
        notifications: [...updatedNotifications],
      };
    }

    default:
      return state;
  }
}
