import React from 'react';

export const CustomLoadingButton = (props) => {
    const {
        isLoading,
        validationMethod,
        buttonLabel,
        onClickMethod,
        buttonClassName = "btn btn-primary text-uppercase w-100"
    } = props;

    return isLoading ? (
        <button
            className={buttonClassName}
            id="loadingBtn"
            type="button"
            style={{ paddingTop: "0.6rem", paddingBottom: "0.6rem" }}
        >
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </button>
    ) : (
        <button
            className={buttonClassName}
            id="loadingBtn"
            type="submit"
            onClick={onClickMethod}
            disabled={validationMethod !== null && !validationMethod}
        >{buttonLabel}</button>
    )
}