import { dashboardConstants, notificationsConstants } from '../_constants';
import { debug } from '../_helpers/device';
import { FAILURE, REQUEST, SUCCESS } from '../_reducers/action-type.util';
import { rulesServices } from '../_services';
/**
 * Actions on FE generated tips
 */
export const tipsActions = {
    updateTip,
    addTip,
    removeTip,
    setShownLowBatteryNotification,
    setShownBatterySaverOnNotification,
    setShownBatterySaverOffNotification,
    setShownForecastBatteryNotification,
    createRuleForNotification,
    addTipToQueue
};

function addTip(newTip){
    
    return {
        type: dashboardConstants.ADD_TIPS,
        data: newTip
    };
    
}

function updateTip(currentTip){
    
    return {
        type: dashboardConstants.UPDATE_TIP,
        data: currentTip
    };
    
}

function removeTip(tipIds){
    return {
        type: dashboardConstants.REMOVE_TIP,
        data: tipIds
    };
}

function setShownLowBatteryNotification(shown){
    return {
        type: dashboardConstants.SET_LOW_BATTERY_TIP_SHOWN,
        data: shown
    };
}

function setShownBatterySaverOnNotification(shown){
    return {
        type: dashboardConstants.SET_BATTERY_SAVER_ON_TIP_SHOWN,
        data: shown
    };
}

function setShownBatterySaverOffNotification(shown){
    return {
        type: dashboardConstants.SET_BATTERY_SAVER_OFF_TIP_SHOWN,
        data: shown
    };
}

function setShownForecastBatteryNotification(shown){
    return {
        type: dashboardConstants.SET_FORECAST_BATTERY_TIP_SHOWN,
        data: shown
    };
}

function createRuleForNotification(tenantUserId, ruleObj, successCb, failureCb){
    
    return (dispatch, getState) => {
        dispatch(request());

        rulesServices.createRule(tenantUserId, ruleObj).then(payload => {
            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(notificationsConstants.CREATE_RULE) };
    }
    function success(payload) {
        return { type: SUCCESS(notificationsConstants.CREATE_RULE), payload };
    }
    function failure(error) {
        return { type: FAILURE(notificationsConstants.CREATE_RULE), error };
    }

}


function addTipToQueue(newTip){
    
    window.document.dispatchEvent(new CustomEvent('checkTipsQueue'));
    
    return {
        type: dashboardConstants.ADD_TIPS_TO_QUEUE,
        data: newTip
    };
    
}