import { ePod, ePod2, ePod3 } from "@nodes-projects/bat-sdk-web";

const sdk = {
  epod: {
    connect: () => ePod.connect(),
  },
  ePod2: {
    connect: () => ePod2.connect(),
    setConnectionStateChanged: (callback) =>
      ePod2.setConnectionStateChanged(callback),
    setupConnectionConfig: (config) => ePod2.setupConnectionConfig(config),
    getDevice: () => ePod2.getDevice(),
    getDeviceInfo: () => ePod2.getDeviceInfo(),
    getDateTimeInfo: () => ePod2.getDateTimeInfo(),
    getLockInfo: () => ePod2.getLockInfo(),
    getBatteryInfo: () => ePod2.getBatteryInfo(),
    getLedInfo: () => ePod2.getLedInfo(),
    getFindVapeInfo: () => ePod2.getFindVapeInfo(),
    getCloudInfo: () => ePod2.getCloudInfo(),
    getHapticInfo: () => ePod2.getHapticInfo(),
    getPuffRecord: () => ePod2.getPuffRecord(),
    getErrorRecord: () => ePod2.getErrorRecord(),
    setDateTimeConfig: (unixTime) => ePod2.setDateTimeConfig(unixTime),
    setLockConfig: (value, withResponse = false) =>
      ePod2.setLockConfig(value, withResponse),
    setLedConfig: (value, withResponse = false) =>
      ePod2.setLedConfig(value, withResponse),
    setCloudConfig: (value, withResponse = false) =>
      ePod2.setCloudConfig(value, withResponse),
    setHapticInfo: (value, withResponse = false) =>
      ePod2.setHapticInfo(value, withResponse),
    getDeviceMSSPayload: () => ePod2.getDeviceMSSPayload(),
    setPayload: (value) => ePod2.setPayload(value),
    setPayloadVersion: (value) => ePod2.setPayloadVersion(value),
    setPayloadControl: (value) => ePod2.setPayloadControl(value),
    setPayloadChallenge: (value) => ePod2.setPayloadChallenge(value),
    requestPayload: (value) => ePod2.requestPayload(value),
    toggleFindMyVape: (value) => ePod2.toggleFindMyVape(value),
    setReset: () => ePod2.setReset(),
    subscribeToBatteryInfo: (listener) =>
      ePod2.subscribeToBatteryInfo(listener),
    subscribeToPayloadControl: (listener) =>
      ePod2.subscribeToPayloadControl(listener),
    subscribeToPayloadChallenge: (listener) =>
      ePod2.subscribeToPayloadChallenge(listener),
    subscribeToErrorRecord: (listener) =>
      ePod2.subscribeToErrorRecord(listener),
    subscribeToLogRecord: (listener) => ePod2.subscribeToLogRecord(listener),
    subscribeToPuffRecord: (listener) => ePod2.subscribeToPuffRecord(listener),
    subscribeToFindVapeInfo: (listener) =>
      ePod2.subscribeToFindVapeInfo(listener),
    subscribeToLockInfo: (listener) => ePod2.subscribeToLockInfo(listener),
    unsubscribeFromErrorRecord: () => ePod2.unsubscribeFromErrorRecord(),
    unsubscribeFromLogRecord: () => ePod2.unsubscribeFromLogRecord(),
    unsubscribeFromPayloadChallenge: () =>
      ePod2.unsubscribeFromPayloadChallenge(),
    unsubscribeFromPayloadControl: () => ePod2.unsubscribeFromPayloadControl(),
    unsubscribeFromPuffRecord: () => ePod2.unsubscribeFromPuffRecord(),
    unsubscribeFromFindVapeInfo: () => ePod2.unsubscribeFromFindVapeInfo(),
    unsubscribeFromBatteryInfo: () => ePod2.unsubscribeFromBatteryInfo(),
    unsubscribeFromLockInfo: () => ePod2.unsubscribeFromLockInfo(),
    ConnectionState: ePod2.ConnectionState,
    MSSProgressState: ePod2.MSSProgressState,

    setMarketSpecificSoftware: (value) =>
      ePod2.setMarketSpecificSoftware(value),
    setMSSProgressStateChanged: (listener) =>
      ePod2.setMSSProgressStateChanged(listener),
    setMSSPayloadUploadStateChanged: (listener) =>
      ePod2.setMSSPayloadUploadStateChanged(listener),
    getLoadedCharacteristics: () => ePod2.getLoadedCharacteristics(),
    getBleDevice: () => ePod2.getBleDevice(),
  },
  ePod3: {
    connect: () => ePod3.connect(),
    setConnectionStateChanged: (callback) =>
      ePod3.setConnectionStateChanged(callback),
    setupConnectionConfig: (config) => ePod3.setupConnectionConfig(config),
    getDevice: () => ePod3.getDevice(),
    getDeviceInfo: () => ePod3.getDeviceInfo(),
    getDateTimeInfo: () => ePod3.getDateTimeInfo(),
    getLockInfo: () => ePod3.getLockInfo(),
    getBatteryInfo: () => ePod3.getBatteryInfo(),
    getLedInfo: () => ePod3.getLedInfo(),
    getFindVapeInfo: () => ePod3.getFindVapeInfo(),
    getCloudInfo: () => ePod3.getCloudInfo(),
    getHapticInfo: () => ePod3.getHapticInfo(),
    getPuffRecord: () => ePod3.getPuffRecord(),
    getErrorRecord: () => ePod3.getErrorRecord(),
    setDateTimeConfig: (unixTime) => ePod3.setDateTimeConfig(unixTime),
    setLockConfig: (value, withResponse = false) =>
      ePod3.setLockConfig(value, withResponse),
    setLedConfig: (value, withResponse = false) =>
      ePod3.setLedConfig(value, withResponse),
    setCloudConfig: (value, withResponse = false) =>
      ePod3.setCloudConfig(value, withResponse),
    setHapticInfo: (value, withResponse = false) =>
      ePod3.setHapticInfo(value, withResponse),
    getDeviceMSSPayload: () => ePod3.getDeviceMSSPayload(),
    setPayload: (value) => ePod3.setPayload(value),
    setPayloadVersion: (value) => ePod3.setPayloadVersion(value),
    setPayloadControl: (value) => ePod3.setPayloadControl(value),
    setPayloadChallenge: (value) => ePod3.setPayloadChallenge(value),
    requestPayload: (value) => ePod3.requestPayload(value),
    toggleFindMyVape: (value) => ePod3.toggleFindMyVape(value),
    setReset: () => ePod3.setReset(),
    subscribeToBatteryInfo: (listener) =>
      ePod3.subscribeToBatteryInfo(listener),
    subscribeToPayloadControl: (listener) =>
      ePod3.subscribeToPayloadControl(listener),
    subscribeToPayloadChallenge: (listener) =>
      ePod3.subscribeToPayloadChallenge(listener),
    subscribeToErrorRecord: (listener) =>
      ePod3.subscribeToErrorRecord(listener),
    subscribeToLogRecord: (listener) => ePod3.subscribeToLogRecord(listener),
    subscribeToPuffRecord: (listener) => ePod3.subscribeToPuffRecord(listener),
    subscribeToFindVapeInfo: (listener) =>
      ePod3.subscribeToFindVapeInfo(listener),
    subscribeToLockInfo: (listener) => ePod3.subscribeToLockInfo(listener),
    unsubscribeFromErrorRecord: () => ePod3.unsubscribeFromErrorRecord(),
    unsubscribeFromLogRecord: () => ePod3.unsubscribeFromLogRecord(),
    unsubscribeFromPayloadChallenge: () =>
      ePod3.unsubscribeFromPayloadChallenge(),
    unsubscribeFromPayloadControl: () => ePod3.unsubscribeFromPayloadControl(),
    unsubscribeFromPuffRecord: () => ePod3.unsubscribeFromPuffRecord(),
    unsubscribeFromFindVapeInfo: () => ePod3.unsubscribeFromFindVapeInfo(),
    unsubscribeFromBatteryInfo: () => ePod3.unsubscribeFromBatteryInfo(),
    unsubscribeFromLockInfo: () => ePod3.unsubscribeFromLockInfo(),
    ConnectionState: ePod3.ConnectionState,
    MSSProgressState: ePod3.MSSProgressState,
    setMarketSpecificSoftware: (value) =>
      ePod3.setMarketSpecificSoftware(value),
    setMSSProgressStateChanged: (listener) =>
      ePod3.setMSSProgressStateChanged(listener),
    setMSSPayloadUploadStateChanged: (listener) =>
      ePod3.setMSSPayloadUploadStateChanged(listener),
    getLoadedCharacteristics: () => ePod3.getLoadedCharacteristics(),
    getBleDevice: () => ePod3.getBleDevice(),
  },
};

export default sdk;
