import { isNil } from "lodash";
import { AGE_VERIFICATION_ACTIONS } from "../_constants";
import { FAILURE, REQUEST, SUCCESS } from "../_reducers/action-type.util";
import { ageVerificationServices } from "../_services";

function createSession(successCb, failureCb) {
  return (dispatch) => {
    dispatch(request());

    ageVerificationServices
      .createSession()
      .then((res) => {
        if (!isNil(res?.data?.session_id) && !isNil(res?.data?.token)) {
          const session = {
            sessionId: res?.data?.session_id,
            token: res?.data?.token,
          };

          dispatch(success(session));

          if (typeof success === "function") {
            successCb(session);
          }
        } else {
          Promise.reject();
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof success === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(AGE_VERIFICATION_ACTIONS.CREATE_SESSION) };
  }

  function success(payload) {
    return { type: SUCCESS(AGE_VERIFICATION_ACTIONS.CREATE_SESSION), payload };
  }

  function failure(error) {
    return { type: FAILURE(AGE_VERIFICATION_ACTIONS.CREATE_SESSION), error };
  }
}

function setUploadingIsComplete(payload) {
  return {
    type: AGE_VERIFICATION_ACTIONS.SET_UPLOADING_IS_COMPLETE,
    payload,
  };
}

function resetSession() {
  return {
    type: AGE_VERIFICATION_ACTIONS.RESET_SESSION,
    session: null,
  };
}

function getVerificationResult(sessionId, successCb, failureCb) {
  return (dispatch) => {
    dispatch(request());

    ageVerificationServices
      .getVerificationResult(sessionId)
      .then((res) => {
        if (!isNil(res?.data)) {
          dispatch(success(res?.data));

          if (typeof success === "function") {
            successCb(res?.data);
          }
        } else {
          Promise.reject();
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(AGE_VERIFICATION_ACTIONS.GET_VERIFICATION_RESULT) };
  }

  function success(payload) {
    return {
      type: SUCCESS(AGE_VERIFICATION_ACTIONS.GET_VERIFICATION_RESULT),
      payload,
    };
  }

  function failure(error) {
    return {
      type: FAILURE(AGE_VERIFICATION_ACTIONS.GET_VERIFICATION_RESULT),
      error,
    };
  }
}

export const ageVerificationActions = {
  createSession,
  setUploadingIsComplete,
  getVerificationResult,
  resetSession,
};
