import { PROFILE_EPOD3, thingDeviceProperties } from "../_constants";

class NewDeviceThing {
  serialNumber = null;
  deviceType = null;
  groupId = null;
  vendor = "VUSE_DEVICE";
  firmwareVersion = null;

  constructor(data) {
    this.serialNumber = data.serialNumber;
    this.deviceType = data.deviceType;
    this.groupId = data.groupId;
    this.firmwareVersion = data.firmwareVersion;
  }

  payload = () => {
    return {
      codes: [
        {
          code: this.deviceType === PROFILE_EPOD3 ? "EPOD_3" : "EPOD_2",
          details: {
            [thingDeviceProperties.DEVICE_TYPE]: this.deviceType,
            [thingDeviceProperties.SERIAL_NUMBER]: this.serialNumber,
            [thingDeviceProperties.FIRMWARE_VERSION]: this.firmwareVersion,
          },
          name: "",
        },
      ],
      group_id: this.groupId,
      vendor: this.vendor,
    };
  };
}

export { NewDeviceThing };
