import React from 'react';
import { productType } from '../../_constants';

export const ShopListElement = ({
    product,
    isNew,
    onClickElement,
    titleProp,
    productImg,
    dictionary
}) => {

    /* USED FOR DEBUG */
    let variants = [
        {
            "variant_id": "5191",
            "variant_shopify_id": "gid://shopify/ProductVariant/31393672953917",
            "price": "13.99",
            "sku": "10111512",
            "title": "5.6 %",
            "display_name": "Blood Orange - 1.6%",
            "quantity": "951",
            "featured_image": "https://cdn.shopify.com/s/files/1/3037/7072/products/2302_GEO_Vuse_3D_VU_CA_EPD_CMR_TSR18_CTN01_PACKs_FRONT_R1.png?v=1648699269",
            "is_favourite": false
        },
        {
            "variant_id": "5191",
            "variant_shopify_id": "gid://shopify/ProductVariant/31393672953917",
            "price": "13.99",
            "sku": "10111512",
            "title": "7.6 %",
            "display_name": "Blood Orange - 1.6%",
            "quantity": "951",
            "featured_image": "https://cdn.shopify.com/s/files/1/3037/7072/products/2302_GEO_Vuse_3D_VU_CA_EPD_CMR_TSR18_CTN01_PACKs_FRONT_R1.png?v=1648699269",
            "is_favourite": false
        },
        {
            "variant_id": "5191",
            "variant_shopify_id": "gid://shopify/ProductVariant/31393672953917",
            "price": "13.99",
            "sku": "10111512",
            "title": "3.6%",
            "display_name": "Blood Orange - 1.6%",
            "quantity": "951",
            "featured_image": "https://cdn.shopify.com/s/files/1/3037/7072/products/2302_GEO_Vuse_3D_VU_CA_EPD_CMR_TSR18_CTN01_PACKs_FRONT_R1.png?v=1648699269",
            "is_favourite": false
        },
        {
            "variant_id": "5191",
            "variant_shopify_id": "gid://shopify/ProductVariant/31393672953917",
            "price": "13.99",
            "sku": "10111512",
            "title": "3.6%",
            "display_name": "Blood Orange - 1.6%",
            "quantity": "951",
            "featured_image": "https://cdn.shopify.com/s/files/1/3037/7072/products/2302_GEO_Vuse_3D_VU_CA_EPD_CMR_TSR18_CTN01_PACKs_FRONT_R1.png?v=1648699269",
            "is_favourite": false
        },
        {
            "variant_id": "5191",
            "variant_shopify_id": "gid://shopify/ProductVariant/31393672953917",
            "price": "13.99",
            "sku": "10111512",
            "title": "3.6%",
            "display_name": "Blood Orange - 1.6%",
            "quantity": "951",
            "featured_image": "https://cdn.shopify.com/s/files/1/3037/7072/products/2302_GEO_Vuse_3D_VU_CA_EPD_CMR_TSR18_CTN01_PACKs_FRONT_R1.png?v=1648699269",
            "is_favourite": false
        }
    ]

 
    let elems = [ ...product?.variants?.map((e, index) => {return e.title?.replace('%','').replace(/\s/g, "")}) ?? []].sort().reverse();
    /* Decomment for debug */
    //let elems = [ ...variants?.map((e, index) => {return e.title?.replace('%','').replace(/\s/g, "")})].filter(n => n).sort().reverse();
    if(elems?.length > 4){
        let totLength = elems.length;
        /* Cut the array */
        elems.length = 3;
        /* Insert the "More" element */
        elems.push(("+").toString() + (totLength-3).toString() + " More");
    }

    return (
        <div className="shop-product d-flex"
            onClick={() => onClickElement(product)}>
            <div className="shop-product-img" style={{
                ...(product.gradient && product.gradient !== "" && { background: product.gradient })
            }}>
                <img src={productImg ?? product.featured_image} alt={""} style={{
                ...(product.id !== undefined && { width: '4.3594rem' })
            }}/>
            </div>
            <div className="shop-product-description d-flex flex-column">
                <div className="shop-product-title text-start d-flex align-items-center">
                    {titleProp ? product[titleProp] : product.title}
                    {isNew && <span className="ms-auto">{dictionary.SHOP_NEW}</span>}
                </div>
                <div className="shop-product-measure-unit-nicotine text-start d-flex">
                    {dictionary.SHOP_NIC_STRENGHT_LABEL}
                </div>
                <div className="shop-product-details align-items-center d-flex align-items-center">
                    {
                       product?.product_type === productType.FLAVOURS ?
                       elems?.map( (e1, index) => {  
                                return(<span className="favourite-nicotine">{e1}</span> )
                            }
                        ) : null
                    }
                    <span className="ms-auto">${product.price}</span>
                </div>
            </div>
        </div>
    )

}