import { aemConstants } from '../_constants';

const initialState = {
    /**
     * Enable AEMHelper console.* calls
     */
    debug: false,

    /**
     * holds language_country components data
     * 
     * [
     *  { language: 'fr', country: 'CA', pages: [...] },
     *  { language: 'en', country: 'CA', pages: [...] },
     *  { language: 'de', country: 'DE', pages: [...] },
     * ]
     */
    languages: [],

    /**
     * current set locale
     */
    locale: null,

    /**
     * current set country
     */
    currentCountry: null,

    /**
     * current set language
     */
    currentLanguage: null,

    /**
     * already-localized objects that holds components data
     */
    pages: null,
    component: null,
};


export function aem(state = initialState, action) {
    switch (action.type) {


        case 'RESET_REDUCER': {
            return {
                debug: false,
                languages: [],
                locale: null,
                currentCountry: null,
                currentLanguage: null,
                pages: null,
                component: null,
            };
        }

        case aemConstants.SET_DEBUG:
            return {
                ...state,
                debug: action.payload
            };

        case aemConstants.SET_LANGUAGES:
            return {
                ...state,
                languages: action.payload
            };

        case aemConstants.ADD_LANGUAGE:

            const newLanguage = action.payload;
            //NB: every time we load a new language whe replace the previous version with the current one
            
            let newLanguages = state.languages;
            //filter languages getting only the ones that are not the current one 
            newLanguages = newLanguages.filter(lang => !(lang.language === newLanguage.language && lang.country === newLanguage.country));

            return {
                ...state,
                languages: [...newLanguages, newLanguage]
            };

        case aemConstants.SET_LOCALE:
            return {
                ...state,
                locale: action.payload
            };

        case aemConstants.SET_CURRENT_LANGUAGE:
            return {
                ...state,
                currentLanguage: action.payload
            };

        case aemConstants.SET_CURRENT_COUNTRY:
            return {
                ...state,
                currentCountry: action.payload
            };

        case aemConstants.SET_PAGE:
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [action.payload?.pageName]: {
                        ...action.payload
                    }
                }
            };

        case aemConstants.SET_COMPONENT:
            return {
                ...state,
                component: action.payload
            };

        default:
            return state;
    }
}