import React from "react";
import StepWizard from "react-step-wizard";
import {
  HardAgeVerificationCapture,
  HardAgeVerificationConsent,
  HardAgeVerificationNegateConsent,
  HardAgeVerificationTooClose,
  HardAgeVerificationDocScan,
  HardAgeVerificationUnsuccess,
  HardAgeVerificationDocScanFailure,
  HardAgeVerificationInfo,
  HardAgeVerificationFAQ,
  HardAgeVerificationConsentCA,
  HardAgeVerificationFaceFake
} from "../../_components/HardAgeVerification";
import { HARD_AGE_VERIFICATION_STEPS } from "../../_constants";
import { Tenants } from "../../_helpers/tenants";

export function HardAgeVerification() {
  return (
    <div className="hard-age-verification h-100">
      <StepWizard
        isLazyMount
        className="h-100"
        initialStep={Tenants.isGlobal() ? HARD_AGE_VERIFICATION_STEPS.CONSENT : HARD_AGE_VERIFICATION_STEPS.INFO}
      >
        <HardAgeVerificationConsent />
        <HardAgeVerificationCapture />
        <HardAgeVerificationNegateConsent />
        <HardAgeVerificationUnsuccess />
        <HardAgeVerificationTooClose />
        <HardAgeVerificationDocScan />
        <HardAgeVerificationDocScanFailure />

        {/* CANADA SPECIFIC */}
        <HardAgeVerificationInfo />
        <HardAgeVerificationFAQ />
        <HardAgeVerificationConsentCA />
        <HardAgeVerificationFaceFake />
      </StepWizard>
    </div>
  );
}
