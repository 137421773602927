import React, {useEffect, useState} from "react";
import { Route, Redirect } from "react-router-dom";
import AEMCreator from "../../_helpers/aem/aemCreator";

export function buildPublicRoutePath(path) {
    return ('/:localeId/:market/' + process.env.PUBLIC_URL + path).replace("//", "/");
}

export const PublicRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const {
            match: {
                params: {
                    localeId,
                    market
                }
            }
        } = props;

        if (!!localeId && !!market) {
            return <PublicRouteComponent component={Component} {...props} />;
        } else {
            // redirect to homepage if there are not any country and language parameters
            return <Redirect to={{
                pathname: '/',
                state: { from: props.location }
            }} />
        }
    }} />
);

const PublicRouteComponent = (props) => {
    const {
        component: Component,
        ...restProps
    } = props;

    const {
        match: {
            params: {
                localeId,
                market
            }
        }
    } = restProps;

    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        AEMCreator.initAEMPage(localeId, market).then(() => {
            setIsInitialized(true);
        })
        return () => {}
    }, []);

    return isInitialized
        ? <Component isAPublicView={true} {...restProps}></Component>
        : <div></div>
}


