import React from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from "moment";
import {
    aemPages,
    AnalyticsEvents,
    flagStatus,
    thingVuseProperties,
    notificationsCategoriesConstants,
    routingConstants,
    tipPropertyData,
    tipsActionConstants,
    tipsConstants,
    VUSE_DEVICE,
    tipsEvents,
    DevicePropertyPlaceholder,
    os
} from "../../_constants";
import { 
    NotificationModel, 
    RuleModel 
} from "../../_models";
import { ToasterNotification } from "../../_components";
import { Utils } from "../graphic/utils";
import {
    dashboardActions,
    deviceActions,
    getUserData,
    getUserEverLogged,
    getVuseUuid,
    onboardingActions,
    tipsActions
} from "../../_actions";
import { store } from "../../_store";
import { Commons } from "../commons";
import sdk from "../../_vendors/nodes";
import { logAnalyticsEvent, logAnalyticsEventForDevice } from "../analytics/logAnalytics";
import { history } from "../history";
import { buildURI } from "../navigation";
import BrowserHelper from "../browser/browser.helper";
import { dashboardServices } from "../../_services";
import { 
    getDeviceName, 
    getDeviceThingFromSN, 
    updateDeviceInReducer 
} from "../device";
import { debug } from "../debug";
import environmentConstants from "../../_constants/environment/environment.constants";
import { Tenants } from "../tenants";
import { 
    AEMHelper, 
    propertyHeadingDefaultEmptyParsed, 
    propertyTextDefaultEmptyParsed 
} from "../aem/aemhelper";
import { Singleton } from '../../_classes/singleton';
import { getAvailableNotificationsPrefs, getTriggers } from '../../_actions/appConfig.actions';
import { isNil } from 'lodash';

const CloseButton = ({ closeToast }) => (
    //<button className="btn notification-close-btn" onClick={closeToast}><span className="bat-icon-close"></span></button>
    <button className="notification-close-btn" onClick={closeToast}></button>
);

const AUTOCLOSE_TIME = 10000

export class Notifications extends Singleton {

    constructor() {
        super();
        this.dictionary = {};
        this.list = []
        this.triggeredReminder = []
    }

    getDictionary() {
        if (Object.keys(this.dictionary).length === 0) {
            const aem = new AEMHelper();
            this.dictionary = aem.getDictionary(aemPages.NOTIFICATION_LIST, {
                NOTIFICATION_BATT_ON_LOAD_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_BATT_ON_LOAD_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_BATT_OFF_LOAD_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_BATT_OFF_LOAD_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_USB_VOLTAGE_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_USB_VOLTAGE_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_CARTOMISER_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_CARTOMISER_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_INT_TEMP_LOW_TEXT:propertyTextDefaultEmptyParsed,
                NOTIFICATION_INT_TEMP_LOW_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_INT_TEMP_HIGH_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_INT_TEMP_HIGH_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_LOCKED_DEVICE_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_LOCKED_DEVICE_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_BLE_PAIRING_DISW_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_BLE_PAIRING_DISW_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_VAPE_LONGER_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_VAPE_LONGER_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_FUEL_FAILED_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_FUEL_FAILED_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_CHARGE_FAILED_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_CHARGE_FAILED_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_PRESSURE_FAILED_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_PRESSURE_FAILED_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_FULL_BATTERY_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_FULL_BATTERY_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_DEVICE_DISCONNECTED_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_DEVICE_DISCONNECTED_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_LOW_BATTERY_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_LOW_BATTERY_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_BATTERY_UPDATE_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_BATTERY_UPDATE_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_DAILY_BATTERY_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_DAILY_BATTERY_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_NEW_FMW_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_NEW_FMW_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_FAIL_FLASH_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_FAIL_FLASH_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_FAILED_CHARGE_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_FAILED_CHARGE_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_BS_MODE_ON_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_BS_MODE_ON_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_BS_MODE_OFF_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_BS_MODE_OFF_TEXT: propertyTextDefaultEmptyParsed,

                // NOT USED YET
                NOTIFICATION_BLE_OFF_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_BLE_OFF_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_FULL_EXP_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_FULL_EXP_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_CHARGE_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_CHARGE_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_NEW_FLAVOURS_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_NEW_FLAVOURS_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_EXPLORE_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_EXPLORE_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_CONTROL_VAPE_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_CONTROL_VAPE_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_REMINDER_FAV_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_REMINDER_FAV_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_ORDER_REMINDER_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_ORDER_REMINDER_HEADLINE: propertyHeadingDefaultEmptyParsed,
                NOTIFICATION_EYE_VAPE_TEXT: propertyTextDefaultEmptyParsed,
                NOTIFICATION_EYE_VAPE_HEADLINE: propertyHeadingDefaultEmptyParsed,
            });
        }
        return this.dictionary;
    }

    static getDictionary() {
        return this.getInstance().getDictionary();
    }

    /**
     * Dispatch toast notification
     * 
     * @param {NotificationModel} notification 
     */
    static dispatchNotification(notification, canCreateAPushNotification = null) {
        debug(`[Notification] dispatchNotification notification`, JSON.stringify(notification));
        debug(`[Notification] dispatchNotification notification !== null`, notification !== null);
        debug(`[Notification] dispatchNotification canCreateAPushNotification`, canCreateAPushNotification);
        if (notification !== null) {
            canCreateAPushNotification = canCreateAPushNotification ?? BrowserHelper.isBLEBrowserRunningInBackground();
            if (canCreateAPushNotification) {
                return BrowserHelper.createNotification(notification);
            } else {
                this.log(`dispatching ${JSON.stringify(notification)}`);

                let additionalClasses = ''
                if (notification.category === notificationsCategoriesConstants.DEVICE_ERROR) {
                    additionalClasses = 'notification-status-error'
                } else if (notification.category === notificationsCategoriesConstants.FIRMWARE_UPDATE) {
                    additionalClasses = 'notification-status-firmware'
                }

                let toastConfig = {
                    toastId: notification.id,
                    onClose: this.handleNotificationClose(notification),
                    onOpen: this.handleNotificationOpen(notification)
                }
                if (notification.sticky) {
                    //sticky
                    toastConfig = {
                        ...toastConfig,
                        //containerId: 'Sticky'
                        className: `notification-container notification-sticky ${additionalClasses}`,
                        autoClose: false,
                        draggable: false,
                        closeButton: CloseButton
                    };
                } else {
                    //temporary
                    toastConfig = {
                        ...toastConfig,
                        //containerId: 'Temporary'
                        className: `notification-container ${additionalClasses}`,
                        autoClose: AUTOCLOSE_TIME,
                        draggable: false,
                        closeButton: CloseButton
                    };
                }

                this.toastListCheck('add', toastConfig.toastId, notification.sticky)
                
                toast(
                    <ToasterNotification
                        notification={notification}
                        showClose={true}
                        //onClick={() => this.handleToastNotificationClick(notification)}
                    />,
                    toastConfig
                );
                
                store.dispatch(tipsActions.addTip(notification));
            }
        }

        return Promise.resolve(notification);
    }

    static toastListCheck(type, id, sticky) {
        if (!this.list) {
            this.list = []
        }

        
        //check for inactive elements and remove them
        this.list.forEach((el, index, array) => {
            if (!toast.isActive(el.id)) {
                array.splice(index, 1)
            }
        })

        if (this.list.length >= 3) {
            //if all active, remove last one and add new one on top
            //if (this.list.length >= 3) {
                toast.dismiss(this.list[2].id)
                this.list.splice(2, 1)
            //}
        }

        
        if (type === 'add') {
            this.list.unshift({id: id, sticky: sticky})
        }
        

        if (this.list.length > 0) {
            const lastSticky = this.list.findLastIndex(el => el.sticky)
            //if (lastSticky > 0) {
                for (let i = (this.list.length-1); i >= 0; i--) {
                    if (!this.list[i].sticky && lastSticky!== -1 && i >= lastSticky) {
                        toast.update(this.list[i].id,
                            {
                                autoClose: false
                            }
                        )
                    } else if (!this.list[i].sticky) {
                        toast.update(this.list[i].id,
                            {
                                autoClose: AUTOCLOSE_TIME
                            }
                        )
                    }
                }
            //}
        }
    }

    /**
     * handle click on in-app notification when is shows as toast notification
     * @param {*} notification 
     */
    static handleToastNotificationClick(notification) {
        logAnalyticsEvent(AnalyticsEvents.NOTIFICATION_IN_APP_OPEN, {
            label: notification.analytics || notification.type
        })
    }

    /**
     * Given notification obj, handle click on in-app notification
     * @param {*} notification 
     */
    static handleNotificationClick(notification) {
        this.log(`click ${JSON.stringify(notification.id)}`);

        this.handleNotificationAction(notification);

        if (!notification.local) {
            const tenantUserId = Commons.generateTenantUserId(store.getState().onboardingReducer.userPin);
            store.dispatch(dashboardActions.setNotificationRead(tenantUserId, { ids: [notification.id] }));
        }

        store.dispatch(tipsActions.updateTip(new NotificationModel({ ...notification.toObj(), read: true })));
    }

    static handleNotificationClose(notification) {
        const notificationObj = notification.toObj === 'function' ? notification.toObj() : notification;
        this.log(`close ${JSON.stringify(notificationObj.id)}`);

        //notifications of some categories, after being closed, must be removed also physically from reducer
        //because those shouldn't be visible in notification history
        if (
            notificationsCategoriesConstants.BATTERY === notificationObj.category ||
            notificationsCategoriesConstants.DEVICE_STATUS === notificationObj.category
        ) {
            store.dispatch(tipsActions.removeTip(notificationObj.id));
        }

        this.toastListCheck()
    }

    static handleNotificationOpen(notification) {
        const notificationObj = typeof notification?.toObj === 'function' ? notification.toObj() : notification;
        this.log(`open ${JSON.stringify(notificationObj.id)}`);
        logAnalyticsEvent(AnalyticsEvents.NOTIFICATION_IN_APP_DISPLAYED, {
            label: notificationObj.analytics || notificationObj.type
        })

        store.dispatch(
            tipsActions.updateTip(
                new NotificationModel({
                    ...notificationObj,
                    removed: false,
                    read: false
                })
            )
        );
    }

    /**
     * Handler for actions associated to in-app notifications interaction.
     * If notification is local, action is based on its type
     * If notification is remote, action is based on its "action" property
     */
    static handleNotificationAction(notification) {
        this.log(`action ${JSON.stringify(notification)}`);

        if (!notification?.local) {
            this.handleRemoteNotificationAction(notification);
        } else {
            switch (notification?.type) {

                case tipsConstants.BATTERY_FULL:
                case tipsConstants.BATTERY_LOWLEVEL:
                case tipsConstants.BATTERY_LIFESPAN:
                case tipsConstants.BATTERY_SAVER_ON:
                case tipsConstants.BATTERY_SAVER_OFF: {
                    const device = notification.device;
                    if (device) {
                        store.dispatch(deviceActions.setSelected(device, () => {
                            // logAnalyticsEvent(AnalyticsEvents.BATTERY);
                            logAnalyticsEventForDevice(device, AnalyticsEvents.BATTERY);
                            history.push(buildURI(routingConstants.BATTERY_SETTINGS));
                        }));
                    }
                    break;
                }

                case tipsConstants.DEVICE_STATUS:
                case tipsConstants.BATTERY_UNKNOWNLIFESPAN: {
                    const device = notification.device;
                    if (device) {
                        store.dispatch(deviceActions.setSelected(device));
                    }
                    break;
                }

                case tipsConstants.ERROR_VOLTAGE_ON_LOW:
                case tipsConstants.ERROR_VOLTAGE_OFF_LOW:
                case tipsConstants.ERROR_VOLTAGE_HIGH:
                case tipsConstants.ERROR_CARTOMISER_HIGH:
                case tipsConstants.ERROR_TEMPERATURE_LOW:
                case tipsConstants.ERROR_TEMPERATURE_HIGH:
                case tipsConstants.ERROR_CHARGE_FAIL:
                case tipsConstants.ERROR_DEVICE_LOCKED:
                case tipsConstants.ERROR_PAIRING_DISALLOWED:
                case tipsConstants.ERROR_VAPING_TOO_LONG:
                case tipsConstants.ERROR_FAILED_FLASH:
                case tipsConstants.ERROR_FAILED_CHARGING:
                case tipsConstants.ERROR_FAILED_BATTERY:
                case tipsConstants.ERROR_FAILED_SENSOR: {
                    history.push(buildURI(routingConstants.DEVICE_FAQ));
                    break;
                }

                case tipsConstants.FIRMWARE_UPDATE: {
                    const device = notification.device;
                    Commons.goToPageWithSelectedDevice(device, routingConstants.DEVICE_SETTINGS);
                    break;
                }

                default:
                    break;
            }
        }

    }

    static isHandlingAPushNotification() {
        return !!localStorage.getItem('__pushNotification')
    }

    static setRemoteNotificationActionFromPushNotification(notification) {
        localStorage.setItem('__pushNotification', JSON.stringify(notification));
    }

    static handleRemoteNotificationActionFromPushNotification() {
        if(this.isHandlingAPushNotification()) {
            const pushNotification = JSON.parse(
                localStorage.getItem('__pushNotification')
            );
            localStorage.removeItem('__pushNotification');
            this.handleRemoteNotificationAction(pushNotification);
        }
    }

    static handleRemoteNotificationAction(notification) {
        console.log("[handleRemoteNotificationAction] notification", notification);

        let device = null;
        const devices = store.getState().deviceReducer.devices;
        if (Commons.isValidArray(devices)) {
            device = devices[0];
        }

        const action = notification.action;

        const actionsWithDevice = {
            [tipsActionConstants.FIRMWARE_NEW_FW_AVAILABLE]: routingConstants.MANAGE_DEVICES,
            [tipsActionConstants.GO_TO_DEVICE_SETTINGS]: routingConstants.MANAGE_DEVICES,
            [tipsActionConstants.SUGGESTIONS_ENABLE_FIND_MY_VAPE]: routingConstants.FIND_MY_VAPE_OPTIN,
            [tipsActionConstants.GO_TO_FIND_MY_VAPE]: routingConstants.FIND_MY_VAPE_OPTIN,
            [tipsActionConstants.SUGGESTIONS_GO_TO_CLOUD_CONTROL]: routingConstants.CLOUD_CONTROL_TUTORIAL,
            [tipsActionConstants.GO_TO_CLOUD_CONTROL]: routingConstants.CLOUD_CONTROL_TUTORIAL,
            [tipsActionConstants.SUGGESTIONS_GO_TO_RECHARGE_REMINDER]: routingConstants.BATTERY_SETTINGS_TUTORIAL,
            [tipsActionConstants.GO_TO_BATTERY_CONTROL]: routingConstants.BATTERY_SETTINGS_TUTORIAL,
        }

        const actionsWithRouting = {
            [tipsActionConstants.GO_TO_MY_ACCOUNT]: routingConstants.MANAGE_ACCOUNT,
            [tipsActionConstants.GO_TO_MANAGE_NOTIFICATIONS]: routingConstants.MANAGE_NOTIFICATIONS,
            [tipsActionConstants.GO_TO_MY_PREFERENCES]: routingConstants.YOUR_PREFERENCES,
            [tipsActionConstants.GO_TO_DEVICE_HELP]: routingConstants.DEVICE_FAQ,
            [tipsActionConstants.GO_TO_CONTACT_US]: routingConstants.CONTACT,
            [tipsActionConstants.GO_TO_STORE_LOCATOR]: routingConstants.STORE_LOCATOR,
        }

        const actionsWithNavigationByEvent = {
            [tipsActionConstants.SUGGESTIONS_GO_TO_FLAVORS]: tipsEvents.GO_TO_FLAVOUR_FOR_YOU,
            [tipsActionConstants.GO_TO_FLAVOUR_FOR_YOU]: tipsEvents.GO_TO_FLAVOUR_FOR_YOU,
            [tipsActionConstants.GO_TO_HOME]: tipsEvents.GO_TO_HOME,
            [tipsActionConstants.GO_TO_BUY_NOW]: tipsEvents.GO_TO_BUY_NOW,
            [tipsActionConstants.GO_TO_USAGE_TRACKER]: tipsEvents.GO_TO_HOME,
            [tipsActionConstants.GO_TO_SUBSCRIPTION]: tipsEvents.GO_TO_SUBSCRIPTION,
            [tipsActionConstants.GO_TO_VUSE_WORLD]: tipsEvents.GO_TO_VUSE_WORLD,
            [tipsActionConstants.ORDER_REMINDER_GO_TO_FAVOURITES]: tipsEvents.GO_TO_FAVOURITES,
            [tipsActionConstants.GO_TO_FAVOURITES]: tipsEvents.GO_TO_FAVOURITES,
            [tipsActionConstants.SUGGESTIONS_GO_TO_WISHLIST]: tipsEvents.GO_TO_FAVOURITES,
        }

        if (
            actionsWithNavigationByEvent[action] !== null &&
            actionsWithNavigationByEvent[action] !== undefined
        ) {
            window.document.dispatchEvent(new CustomEvent(actionsWithNavigationByEvent[action]));
        } else if (
            actionsWithRouting[action] !== null &&
            actionsWithRouting[action] !== undefined
        ) {
            history.push(buildURI(actionsWithRouting[action]));
        } else if (
            actionsWithDevice[action] !== null &&
            actionsWithDevice[action] !== undefined
        ) {
            if (device !== null && device !== undefined) {

                //fmv and device setting are also offline features, 
                //device doesn't need to be connected for the user to enjoy those
                if (
                    action === tipsActionConstants.GO_TO_FIND_MY_VAPE ||
                    action === tipsActionConstants.SUGGESTIONS_ENABLE_FIND_MY_VAPE || 
                    action === tipsActionConstants.FIRMWARE_NEW_FW_AVAILABLE || 
                    action === tipsActionConstants.GO_TO_DEVICE_SETTINGS 
                ) {
                    store.dispatch(deviceActions.setSelected(device, () => {
                        history.push(buildURI(actionsWithDevice[action]));
                    }));
                } else {
                    Commons.goToPageWithSelectedDevice(device, actionsWithDevice[action]);
                }
            }else {
                window.document.dispatchEvent(new CustomEvent(tipsEvents.GO_TO_HOME));
            }
        } else if (
            action === tipsActionConstants.SUGGESTIONS_GO_TO_PAIR_DEVICE ||
            action === tipsActionConstants.GO_TO_PAIR_DEVICE
        ) {
            if (isNil(devices) || devices?.length < 3) {
                history.push(buildURI(routingConstants.PAIR_DEVICE));
            }
        } else if (action === tipsActionConstants.GO_TO_FAQ_MYVUSE) {
            Commons.goToSupport();
        } else if (action === tipsActionConstants.GO_TO_WEBVIEW) {
            window.document.dispatchEvent(new CustomEvent(tipsEvents.GO_TO_WEBVIEW, {
                detail: {
                    webview_title: notification.webview_title,
                    webview_url: notification.webview_url,
                    webview_restricted: notification.webview_restricted
                }
            }));

        }
    }

    /**
     * Given error code, return corresponding NotificationModel
     * @param {String} errorCode 
     * @param {DeviceModel} device DeviceModel, used to personalize in-app notification title/body
     * @returns NotificationModel
     */
    static getNotificationFromErrorCode(errorCode, device) {
        const dictionary = this.getDictionary();

        // console.log("getNotificationFromErrorCode device", device);

        let notificationBody = {
            id: null,
            type: null,
            timestamp: new Date(),
            title: "",
            body: "",
            sticky: true,
            category: notificationsCategoriesConstants.DEVICE_ERROR,
            icon: Utils.getIconFromCategory(notificationsCategoriesConstants.DEVICE_ERROR),
            local: true
        }

        switch (errorCode) {
            case 2: {
                notificationBody = {
                    ...notificationBody,
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.ERROR_VOLTAGE_OFF_LOW,
                    title: dictionary.NOTIFICATION_BATT_OFF_LOAD_HEADLINE,
                    body: dictionary.NOTIFICATION_BATT_OFF_LOAD_TEXT,
                    replaceStrings: {
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device)
                    },
                }
                break;
            }

            case 4: {
                notificationBody = {
                    ...notificationBody,
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.ERROR_VOLTAGE_ON_LOW,
                    title: dictionary.NOTIFICATION_BATT_ON_LOAD_HEADLINE,
                    body: dictionary.NOTIFICATION_BATT_ON_LOAD_TEXT,
                    replaceStrings: {
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device)
                    },
                }
                break;
            }

            case 5: {
                notificationBody = {
                    ...notificationBody,
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.ERROR_VOLTAGE_HIGH,
                    title: dictionary.NOTIFICATION_USB_VOLTAGE_HEADLINE,
                    body: dictionary.NOTIFICATION_USB_VOLTAGE_TEXT,
                    replaceStrings: {
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device)
                    },
                }
                break;
            }

            case 11: {
                notificationBody = {
                    ...notificationBody,
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.ERROR_CARTOMISER_HIGH,
                    title: dictionary.NOTIFICATION_CARTOMISER_HEADLINE,
                    body: dictionary.NOTIFICATION_CARTOMISER_TEXT,
                    replaceStrings: {
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device)
                    },
                }
                break;
            }

            case 12: {
                notificationBody = {
                    ...notificationBody,
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.ERROR_TEMPERATURE_LOW,
                    title: dictionary.NOTIFICATION_INT_TEMP_LOW_HEADLINE,
                    body: dictionary.NOTIFICATION_INT_TEMP_LOW_TEXT,
                    replaceStrings: {
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device)
                    },
                }
                break;
            }

            case 13: {
                notificationBody = {
                    ...notificationBody,
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.ERROR_TEMPERATURE_HIGH,
                    title: dictionary.NOTIFICATION_INT_TEMP_HIGH_HEADLINE,
                    body: dictionary.NOTIFICATION_INT_TEMP_HIGH_TEXT,
                    replaceStrings: {
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device)
                    },
                }
                break;
            }

            case 14: {
                notificationBody = {
                    ...notificationBody,
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.ERROR_CHARGE_FAIL,
                    title: dictionary.NOTIFICATION_FAILED_CHARGE_HEADLINE,
                    body: dictionary.NOTIFICATION_FAILED_CHARGE_TEXT,
                    replaceStrings: {
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device)
                    },
                }
                break;
            }

            case 15: {
                notificationBody = {
                    ...notificationBody,
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.ERROR_DEVICE_LOCKED,
                    title: dictionary.NOTIFICATION_LOCKED_DEVICE_HEADLINE,
                    body: dictionary.NOTIFICATION_LOCKED_DEVICE_TEXT,
                    replaceStrings: {
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device)
                    },
                }
                break;
            }

            case 16: {
                notificationBody = {
                    ...notificationBody,
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.ERROR_PAIRING_DISALLOWED,
                    title: dictionary.NOTIFICATION_BLE_PAIRING_DISW_HEADLINE,
                    body: dictionary.NOTIFICATION_BLE_PAIRING_DISW_TEXT,
                }
                break;
            }
            case 17: {
                notificationBody = {
                    ...notificationBody,
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.ERROR_VAPING_TOO_LONG,
                    title: dictionary.NOTIFICATION_VAPE_LONGER_HEADLINE,
                    body: dictionary.NOTIFICATION_VAPE_LONGER_TEXT,
                }
                break;
            }
            case 18: {
                notificationBody = {
                    ...notificationBody,
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.ERROR_VAPING_TOO_LONG,
                    title: dictionary.NOTIFICATION_FAIL_FLASH_HEADLINE,
                    body: dictionary.NOTIFICATION_FAIL_FLASH_TEXT,
                    replaceStrings: {
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device)
                    },
                }
                break;
            }
            case 19: {
                notificationBody = {
                    ...notificationBody,
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.ERROR_VAPING_TOO_LONG,
                    title: dictionary.NOTIFICATION_CHARGE_FAILED_HEADLINE,
                    body: dictionary.NOTIFICATION_CHARGE_FAILED_TEXT,
                    replaceStrings: {
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device)
                    },
                }
                break;
            }
            case 20: {
                notificationBody = {
                    ...notificationBody,
                    id: Commons.generateRandomNumericId(20),
                    ype: tipsConstants.ERROR_VAPING_TOO_LONG,
                    title: dictionary.NOTIFICATION_FUEL_FAILED_HEADLINE,
                    body: dictionary.NOTIFICATION_FUEL_FAILED_TEXT,
                    replaceStrings: {
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device)
                    },
                }
                break;
            }
            case 21: {
                notificationBody = {
                    ...notificationBody,
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.ERROR_VAPING_TOO_LONG,
                    title: dictionary.NOTIFICATION_PRESSURE_FAILED_HEADLINE,
                    body: dictionary.NOTIFICATION_PRESSURE_FAILED_TEXT,
                    replaceStrings: {
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device)
                    },
                }
                break;
            }
            default:
                return null;
        }

        return new NotificationModel(notificationBody);
    }

    /**
     * Given notification type, return corresponding NotificationModel
     * @param {tipsConstants} notificationType 
     * @param {DeviceModel} device DeviceModel, used to personalize in-app notification title/body
     * @returns NotificationModel
     */
    static createNotificationFromType(notificationType, device) {
        const dictionary = this.getDictionary();

        // console.log("createNotificationFromType device", device);

        switch (notificationType) {
            case tipsConstants.BATTERY_FULL: {
                return new NotificationModel({
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.BATTERY_FULL,
                    timestamp: new Date(),
                    title: dictionary.NOTIFICATION_FULL_BATTERY_HEADLINE,
                    body: dictionary.NOTIFICATION_FULL_BATTERY_TEXT,
                    sticky: false,
                    category: notificationsCategoriesConstants.BATTERY,
                    replaceStrings:  {
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device)
                    },
                    icon: Utils.getIconFromCategory(notificationsCategoriesConstants.BATTERY, device?.deviceColor, device?.deviceType),
                    local: true,
                    device
                })
            }

            case tipsConstants.DEVICE_STATUS: {
                return new NotificationModel({
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.DEVICE_STATUS,
                    timestamp: new Date(),
                    title: dictionary.NOTIFICATION_DEVICE_DISCONNECTED_HEADLINE,
                    body: dictionary.NOTIFICATION_DEVICE_DISCONNECTED_TEXT,
                    sticky: false,
                    category: notificationsCategoriesConstants.DEVICE_STATUS,
                    replaceStrings:  {
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device)
                    },
                    icon: Utils.getIconFromCategory(notificationsCategoriesConstants.DEVICE_STATUS, device?.deviceColor),
                    local: true,
                    device
                })
            }

            case tipsConstants.BATTERY_LOWLEVEL: {
                return new NotificationModel({
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.BATTERY_LOWLEVEL,
                    timestamp: new Date(),
                    title: dictionary.NOTIFICATION_LOW_BATTERY_HEADLINE,
                    body: dictionary.NOTIFICATION_LOW_BATTERY_TEXT,
                    sticky: false,
                    category: notificationsCategoriesConstants.BATTERY,
                    replaceStrings: {
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device),
                        [DevicePropertyPlaceholder.DEVICE_BATTERY_LEVEL]: device?.batteryInfo?.chargeLevel,
                    },
                    icon: Utils.getIconFromCategory(notificationsCategoriesConstants.BATTERY, device?.deviceColor, device?.deviceType),
                    local: true,
                    device
                })
            }


            case tipsConstants.BATTERY_UNKNOWNLIFESPAN: {
                return new NotificationModel({
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.BATTERY_UNKNOWNLIFESPAN,
                    timestamp: new Date(),
                    title: dictionary.NOTIFICATION_BATTERY_UPDATE_HEADLINE,
                    body: dictionary.NOTIFICATION_BATTERY_UPDATE_TEXT,
                    sticky: false,
                    category: notificationsCategoriesConstants.BATTERY,
                    replaceStrings: {
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device)
                    },
                    icon: Utils.getIconFromCategory(notificationsCategoriesConstants.BATTERY, device?.deviceColor, device?.deviceType),
                    local: true,
                    device
                })
            }

            case tipsConstants.BATTERY_LIFESPAN: {
                return new NotificationModel({
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.BATTERY_LIFESPAN,
                    timestamp: new Date(),
                    title: dictionary.NOTIFICATION_DAILY_BATTERY_HEADLINE,
                    body: dictionary.NOTIFICATION_DAILY_BATTERY_TEXT,
                    sticky: false,
                    category: notificationsCategoriesConstants.BATTERY,
                    replaceStrings: {
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device),
                        [DevicePropertyPlaceholder.DEVICE_BATTERY_LEVEL]: device?.batteryInfo?.chargeLevel,
                    },
                    icon: Utils.getIconFromCategory(notificationsCategoriesConstants.BATTERY, device?.deviceColor, device?.deviceType),
                    local: true,
                    device
                })
            }

            case tipsConstants.BATTERY_SAVER_ON: {
                return new NotificationModel({
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.BATTERY_SAVER_ON,
                    timestamp: new Date(),
                    title: dictionary.NOTIFICATION_BS_MODE_ON_HEADLINE,
                    body: dictionary.NOTIFICATION_BS_MODE_ON_TEXT,
                    sticky: false,
                    category: notificationsCategoriesConstants.BATTERY,
                    replaceStrings: {
                        [DevicePropertyPlaceholder.DEVICE_BATTERYSAVER_LEVEL]: device?.cloudInfo?.batterySavingThresholdValue  + "%",
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device),
                    },
                    icon: Utils.getIconFromCategory(notificationsCategoriesConstants.BATTERY, device?.deviceColor, device?.deviceType),
                    local: true,
                    device
                })
            }

            case tipsConstants.BATTERY_SAVER_OFF: {
                return new NotificationModel({
                    id: Commons.generateRandomNumericId(20),
                    type: tipsConstants.BATTERY_SAVER_OFF,
                    timestamp: new Date(),
                    title: dictionary.NOTIFICATION_BS_MODE_OFF_HEADLINE,
                    body: dictionary.NOTIFICATION_BS_MODE_OFF_TEXT,
                    sticky: false,
                    category: notificationsCategoriesConstants.BATTERY,
                    replaceStrings: {
                        [DevicePropertyPlaceholder.DEVICE_BATTERYSAVER_LEVEL]: device?.cloudInfo?.batterySavingThresholdValue + "%",
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device),
                    },
                    icon: Utils.getIconFromCategory(notificationsCategoriesConstants.BATTERY, device?.deviceColor, device?.deviceType),
                    local: true,
                    device
                })
            }

            case tipsConstants.FIRMWARE_UPDATE: {
                return new NotificationModel({
                    id: `${tipsConstants.FIRMWARE_UPDATE}-${device?.serialNumber}`, //prevent duplication but preserve multi device logic
                    type: tipsConstants.FIRMWARE_UPDATE,
                    timestamp: new Date(),
                    title: dictionary.NOTIFICATION_NEW_FMW_HEADLINE,
                    body: dictionary.NOTIFICATION_NEW_FMW_TEXT,
                    sticky: true,
                    category: notificationsCategoriesConstants.FIRMWARE_UPDATE,
                    replaceStrings: {
                        [DevicePropertyPlaceholder.DEVICE_NAME]: getDeviceName(device)
                    },
                    icon: Utils.getIconFromCategory(notificationsCategoriesConstants.FIRMWARE_UPDATE),
                    local: true,
                    action: tipsActionConstants.FIRMWARE_NEW_FW_AVAILABLE,
                    device
                })
            }

            default:
                return null;

        }
    }

    /**
     * Given notififcation type, return all in-app notifications of this type
     * @param {tipsConstants} notificationType 
     * @returns 
     */
    static getNotificationsFromType(notificationType) {
        const allNotifications = store.getState().dashboardReducer.tips;
        if (Commons.isValidArray(allNotifications)) {
            if (notificationType === null) {
                return allNotifications;
            } else {
                return allNotifications.filter(notification => notification.type === notificationType);
            }
        } else {
            return [];
        }
    }

    static handleLocalNotifications() {

        const customerThing = Commons.getCustomerThing();
        const areDeviceConnectionNotificationsEnabled = customerThing?.properties.find(prop => prop.type === thingVuseProperties.DEVICECONNECTION)?.data;
        const areBatteryForecastNotificationsEnabled = customerThing?.properties.find(prop => prop.type === thingVuseProperties.BATTERY_FORECAST_ENABLED)?.data;
        const forecastTime = customerThing?.properties.find(prop => prop.type === thingVuseProperties.SELECTED_TIME)?.data;
        // console.log("[handleLocalNotifications] areDeviceConnectionNotificationsEnabled", areDeviceConnectionNotificationsEnabled);
        // console.log("[handleLocalNotifications] areBatteryForecastNotificationsEnabled", areBatteryForecastNotificationsEnabled);
        // console.log("[handleLocalNotifications] forecastTime", forecastTime);

        //BATTERY LIFESPAN
        //BATTERY UNKNOWNLIFESPAN
        if (
            areBatteryForecastNotificationsEnabled === flagStatus.ACCEPTED ||
            areBatteryForecastNotificationsEnabled === "true" ||
            areBatteryForecastNotificationsEnabled === true
        ) {

            //get selected time to check
            if (forecastTime) {

                const lastNotificationDate = store.getState().dashboardReducer.lastForecastBatteryTip;
                const lastNotificationObj = moment(store.getState().dashboardReducer.lastForecastBatteryTip);

                //if last time notification was shown is null or is before today (and not in the future ofc)
                if (
                    lastNotificationDate === null ||
                    (lastNotificationObj.isValid() && !lastNotificationObj.startOf('day').isSame(new Date(), "day"))
                ) {

                    store.dispatch(tipsActions.setShownForecastBatteryNotification(new Date()));
                    var intervalInstance = null;
                    intervalInstance = setInterval(() => {

                        var time = new Date(forecastTime);
                        var currentTime = new Date();
                        time = moment(time);
                        currentTime = moment(currentTime);

                        var minutesOfForecastTime = time.minutes() + time.hours() * 60;
                        var minutesOfCurrentTime = currentTime.minutes() + currentTime.hours() * 60;

                        // if the time of the check is passed than show tip
                        // else check in the next sec
                        if (minutesOfCurrentTime == minutesOfForecastTime) {

                            clearInterval(intervalInstance);

                            (store.getState().deviceReducer.devices).forEach((device) => {
                                if (
                                    device.batteryInfo &&
                                    (
                                        sdk[device.deviceType].ConnectionState.Synchronized !== device.connectionState ||
                                        sdk[device.deviceType].ConnectionState.Connected !== device.connectionState
                                    )
                                ) {
                                    if (!this.triggeredReminder.includes(device.serialNumber)) {
                                        //show battery notification
                                        this.dispatchNotification(this.createNotificationFromType(tipsConstants.BATTERY_LIFESPAN, device));
                                        this.triggeredReminder.push(device.serialNumber)
                                    }
                                } else {
                                    // show unknown notification
                                    this.dispatchNotification(this.createNotificationFromType(tipsConstants.BATTERY_UNKNOWNLIFESPAN, device));
                                }

                            });

                        } else {
                            this.triggeredReminder = []
                        }
                    }, 1000);
                }
            }
        }

        //DEVICE_STATUS
        if (
            areDeviceConnectionNotificationsEnabled === flagStatus.ACCEPTED ||
            areDeviceConnectionNotificationsEnabled === "true" ||
            areDeviceConnectionNotificationsEnabled === true
        ) {
            (store.getState().deviceReducer.devices).forEach((device) => {
                if (device.lastConnection !== null) {
                    //if device wasn't connected for 7+ days
                    if (moment().diff(moment(device.lastConnection), 'days') >= 7) {
                        this.dispatchNotification(this.createNotificationFromType(tipsConstants.DEVICE_STATUS, device));
                    }
                }
            })
        }

        (store.getState().deviceReducer.devices).forEach((device) => {
            //check if there is any battery level notification to be shown when entering on homepage
            this.handleBatteryLevelNotifications(device.batteryInfo, device);
        })
    }

    /**
     * Manage battery notifications BA-01 and BA-02, dependent on battery laval changes
     * @param {*} chargeStatus 
     * @param {*} devicePayload 
     */
    static handleBatteryLevelNotifications(chargeStatus, devicePayload) {

        // console.log("handleBatteryLevelNotifications", devicePayload);

        const customerThing = Commons.getCustomerThing();
        const areBatteryFullNotificationsEnabled = customerThing?.properties.find(prop => prop.type === thingVuseProperties.FULL_CHARGE_ALERT)?.data;
        const areBatteryThresholdNotificationsEnabled = customerThing?.properties.find(prop => prop.type === thingVuseProperties.BATTERY_THRESHOLD_ENABLED)?.data;
        const batteryThreshold = customerThing?.properties.find(prop => prop.type === thingVuseProperties.BATTERY_THRESHOLD)?.data;


        //Manage full charge notification (BA-01)
        if (chargeStatus) {
            if (chargeStatus.charging) {

                store.dispatch(tipsActions.setShownLowBatteryNotification(false));
                if (chargeStatus.chargeLevel === 100) {

                    if (
                        areBatteryFullNotificationsEnabled === flagStatus.ACCEPTED ||
                        areBatteryFullNotificationsEnabled === "true" ||
                        areBatteryFullNotificationsEnabled === true
                    ) {
                        this.dispatchNotification(this.createNotificationFromType(tipsConstants.BATTERY_FULL, devicePayload));
                    }
                }

            } else {
                //nascondere tutte le notifiche in-app relative al type batteryfull
                const allBatteryNotifications = Notifications.getNotificationsFromType(tipsConstants.BATTERY_FULL);
                allBatteryNotifications.forEach(notification => {
                    toast.dismiss(notification.id);
                });

                //manage low battery notification (BA-02)
                if (!store.getState().dashboardReducer.shownLowBatteryTip) {
                    if (chargeStatus.chargeLevel <= batteryThreshold) {
                        if (
                            areBatteryThresholdNotificationsEnabled === flagStatus.ACCEPTED ||
                            areBatteryThresholdNotificationsEnabled === "true" ||
                            areBatteryThresholdNotificationsEnabled === true
                        ) {
                            this.dispatchNotification(this.createNotificationFromType(tipsConstants.BATTERY_LOWLEVEL, devicePayload));
                            store.dispatch(tipsActions.setShownLowBatteryNotification(true)); //setting this property causes the notification to be shown just one time
                            //then it will be shown only after the user has charged the device one more time
                            //to avoid notifications flooding
                        }
                    }
                }
            }
        }

    }

    static async handleBatterySaverNotifications(device) {
        const cloudInfo = device?.cloudInfo;

        console.debug("handleBatterySaverNotifications", cloudInfo);

        if (!store.getState().dashboardReducer.shownBatterySaverOnTip) {
            if (cloudInfo?.batterySavingOn === 1) {
                console.debug("handleBatterySaverNotifications - SHOW");
                const batterySaverOnNotifications = Notifications.getNotificationsFromType(tipsConstants.BATTERY_SAVER_OFF);
                batterySaverOnNotifications.forEach(notification => {
                    toast.dismiss(notification.id);
                });
                this.dispatchNotification(this.createNotificationFromType(tipsConstants.BATTERY_SAVER_ON, device));
                store.dispatch(tipsActions.setShownBatterySaverOnNotification(true));
                store.dispatch(tipsActions.setShownBatterySaverOffNotification(false));
            }
        } else if (!store.getState().dashboardReducer.shownBatterySaverOffTip) {
            if (cloudInfo?.batterySavingOn === 0) {
                console.debug("handleBatterySaverNotifications - HIDE");
                const batterySaverOnNotifications = Notifications.getNotificationsFromType(tipsConstants.BATTERY_SAVER_ON);
                batterySaverOnNotifications.forEach(notification => {
                    toast.dismiss(notification.id);
                });
                this.dispatchNotification(this.createNotificationFromType(tipsConstants.BATTERY_SAVER_OFF, device));
                store.dispatch(tipsActions.setShownBatterySaverOffNotification(true));
                store.dispatch(tipsActions.setShownBatterySaverOnNotification(false));
            }   
        }
    }

    static handleNotificationCenterNotifications(ignoreNextPage = false) {

        return new Promise((resolve, reject) => {
            if (getUserData()?.group_id) {
                const nextPage = store.getState().dashboardReducer.nextPage;
                const tenantUserId = Commons.generateTenantUserId(store.getState().onboardingReducer.userPin);

                store.dispatch(
                    dashboardActions.getNotificationHistory(tenantUserId, 30, ignoreNextPage ? null : nextPage,
                        (response) => {
                            console.log("[NOTIFICATIONS] history response", response);
                            resolve(response?.data?.events);
                        }, () => {
                            reject(null);
                        })
                );
            } else {
                resolve([])
            }
        })
    }

    static handleRemoteNotifications() {
        const canCreateAPushNotification = BrowserHelper.isBLEBrowserRunningInBackground();

        const vuseUUID = getVuseUuid();
        if (!Tenants.isCanadaDark() && vuseUUID) {
            this.handleNotificationCenterNotifications(true);
        }
    }

    static stringReplace(notification, thisString) {
        if (!_.isEmpty(notification.replaceStrings)) {
            //return Utils.stringReplacePlaceholders(thisString, notification.replaceStrings);
            return Utils.stringReplacePlaceholdersFromConstants(thisString, notification.replaceStrings);
        } else {
            return thisString;
        }
    }

    static getNotificationObjFromRemoteNotification(notification) {

        // console.log("[getNotificationObjFromRemoteNotification] notification", notification);
        if (
            notification !== null &&
            notification !== undefined &&
            !_.isEmpty(notification)
        ) {
            return new NotificationModel({
                id: notification.id,
                type: null,
                timestamp: notification.generatedAt,
                title: notification.heading,
                body: notification.description,
                sticky: notificationsCategoriesConstants.MARKETING === notification.category,
                category: notification.category,
                icon: Utils.getIconFromCategory(notification.category, false, false, notification.id),
                local: false,
                action: notification.cta_action,
                webview_restricted: notification.webview_restricted,
                webview_url: notification.webview_url,
                webview_title: notification.webview_title,
                analytics: notification.analytics,
            });
        }
    }

    static handleFirmwareNotifications(devicePayload) {

        console.log("[handleFirmwareNotifications] devicePayload", devicePayload);

        debug(`[Notification] handleFirmwareNotifications devicePayload`, devicePayload);
        if (
            devicePayload.connectionState === sdk[devicePayload.deviceType].ConnectionState.Synchronized &&
            devicePayload.serialNumber
        ) {
            const deviceThing = getDeviceThingFromSN(devicePayload.serialNumber);
            const tenantUserId = Commons.generateTenantUserId(store.getState().onboardingReducer.userPin);

            dashboardServices.getFirmwareProperties(tenantUserId, deviceThing?.uuid, VUSE_DEVICE)
                .then((response) => {
                    if (response?.data?.data) {

                        const deviceProperties = response?.data?.data[0];
                        debug(`[Notification] handleFirmwareNotifications deviceProperties`, deviceProperties);
                        debug(`[Notification] handleFirmwareNotifications thingVuseProperties.FIRMWAREUPDATE`, Commons.getCustomerProperty(thingVuseProperties.FIRMWAREUPDATE) === flagStatus.ACCEPTED);
                        if (
                            deviceProperties && 
                            deviceProperties.firmwareSource !== null &&
                            deviceProperties.firmwareSource !== undefined &&
                            deviceProperties.firmwareSource !== ""
                        ) {
                            if (Commons.getCustomerProperty(thingVuseProperties.FIRMWAREUPDATE) === flagStatus.ACCEPTED) {
                                //dispatch notification
                                debug("handleFirmwareNotifications DISPATCH!")
                                Notifications.dispatchNotification(this.createNotificationFromType(tipsConstants.FIRMWARE_UPDATE, devicePayload), false)
                            }
                            //set device as updatable
                            updateDeviceInReducer(devicePayload.serialNumber, { updatable: true });
                        } else {
                            updateDeviceInReducer(devicePayload.serialNumber, { updatable: false });
                        }
                    }
                })
        }

    }

    /**
     * Method used to setup rules for suggestions notifications to be sent
     */
    static handleSuggestionNotifications() {

        const userData = getUserData();
        const tenantUserId = Commons.generateTenantUserId(store.getState().onboardingReducer.userPin);
        const customerThing = Commons.getCustomerThing();
        const rules = store.getState().dashboardReducer.rules;
        const triggersDays = getTriggers()

        console.log("customerThing", customerThing);
        console.log("rules", rules);
        if (customerThing) {
            [
                { thing: thingVuseProperties.OPT_IN_FINDMYVAPE, property_data: tipPropertyData.SUGGESTIONS_ENABLE_FIND_MY_VAPE, days: 10 },
                { thing: thingVuseProperties.BATTERYSAVE, property_data: tipPropertyData.SUGGESTIONS_BATTERYSAVE, days: 4 },
                { thing: thingVuseProperties.USAGE_TRACKER, property_data: tipPropertyData.SUGGESTIONS_USAGE_TRACKER, days: 2 },
                { thing: thingVuseProperties.CLOUDCONTROL, property_data: tipPropertyData.SUGGESTIONS_ENABLE_CLOUD_CONTROL, days: 2 },
                { thing: thingVuseProperties.DEVICEPAIR, property_data: tipPropertyData.SUGGESTIONS_PAIR, days: 1 },
            ].forEach((el) => {
                const matchingTrigger = Object.keys(triggersDays).find(triggerKey => el.property_data.toUpperCase() === triggerKey.toUpperCase());
            
                if (matchingTrigger) {
                    var days = Math.round(triggersDays[matchingTrigger]/24)
                    el.days = days;
                }
                console.log("customerThing.properties.find(prop => prop.type === el.thing)?.data !== flagStatus.ACCEPTED", customerThing.properties.find(prop => prop.type === el.thing)?.data !== flagStatus.ACCEPTED);
                if (customerThing.properties.find(prop => prop.type === el.thing)?.data !== flagStatus.ACCEPTED) {

                    const ruleAlreadyExists = rules && rules.length > 0 && rules.find(rule => {
                        let descriptionObj = Commons.parseJSONSafely(rule.description);
                        return descriptionObj?.type === el.property_data;
                    });

                    console.log("ruleAlreadyExists", ruleAlreadyExists);
                    if (!ruleAlreadyExists) {
                        console.log("ruleAlreadyExists", ruleAlreadyExists);
                        const MYVUSEEverLoggedTimestamp = getUserEverLogged();
                        console.log("MYVUSEEverLoggedTimestamp", MYVUSEEverLoggedTimestamp);
                        if (
                            MYVUSEEverLoggedTimestamp !== null &&
                            MYVUSEEverLoggedTimestamp !== undefined &&
                            MYVUSEEverLoggedTimestamp !== ""
                        ) {
                            const MYVUSEEverLoggedMoment = moment(MYVUSEEverLoggedTimestamp);

                            /**
                             * Check difference in days from the time user completed onboarding and current time, 
                             * then subtract this result from the days expected for the rule. 
                             * If result is positive, there are still days for the rule to be executed, so it's created;
                             * otherwise no rule is created.
                             */

                            console.log("MYVUSEEverLoggedMoment", MYVUSEEverLoggedMoment);
                            const differenceInDays = moment().diff(MYVUSEEverLoggedMoment, 'days');
                            console.log("differenceInDays", differenceInDays);

                            if (differenceInDays >= 0) { //if everything works correctly this condition will always be evaluated to true

                                const ruleDays = el.days - differenceInDays;
                                console.log("ruleDays", ruleDays);

                                if (ruleDays > 0) {
                                    let rule = new RuleModel(`suggestion_${Commons.generateRandomNumericId(10)}`, userData.group_id);
                                    rule.setDescription(JSON.stringify({
                                        type: el.property_data
                                    }));
                                    rule.setCustomerCommandForNotification(el.property_data);
                                    //rule.setFullCalendar(moment().add(ruleDays*10, 'minutes'));
                                    rule.setFullCalendar(moment().add(ruleDays, 'days'));
                                    store.dispatch(tipsActions.createRuleForNotification(tenantUserId, rule.payload()));
                                }
                            }
                        }

                    }
                }
            })
        }
    }

    static removeAllSuggestionNotifications() {

        const tenantUserId = Commons.generateTenantUserId(store.getState().onboardingReducer.userPin);
        const rules = store.getState().dashboardReducer.rules;

        if (Commons.isValidArray(rules)) {
            rules.forEach(rule => {
                let descriptionObj = Commons.parseJSONSafely(rule.description);
                if (
                    descriptionObj?.type === tipPropertyData.SUGGESTIONS_ENABLE_FIND_MY_VAPE ||
                    descriptionObj?.type === tipPropertyData.SUGGESTIONS_ENABLE_CLOUD_CONTROL ||
                    descriptionObj?.type === tipPropertyData.SUGGESTIONS_BATTERYSAVE ||
                    descriptionObj?.type === tipPropertyData.SUGGESTIONS_PAIR
                ) {
                    store.dispatch(onboardingActions.deleteRules(tenantUserId, rule.id));
                }
            });
        }
    }

    /**
     * 
     * @param {tipPropertyData} type 
     */
    static removeSuggestionNotification(type) {
        if (type) {
            const tenantUserId = Commons.generateTenantUserId(store.getState().onboardingReducer.userPin);
            const rules = store.getState().dashboardReducer.rules;

            rules.forEach(rule => {
                let descriptionObj = Commons.parseJSONSafely(rule.description);
                if (descriptionObj?.type === type) {
                    store.dispatch(onboardingActions.deleteRules(tenantUserId, rule.id));
                }
            });
        }
    }

    static getOrderReminderNotificationRule() {
        const rules = store.getState().dashboardReducer.rules;
        if (Commons.isValidArray(rules)) {
            return rules.find(rule => {
                let descriptionObj = Commons.parseJSONSafely(rule.description);
                return descriptionObj?.type === tipPropertyData.ORDER_REMINDER_TIME_TO_RESTOCK_DATETIME;
            });
        } else {
            return null;
        }
    }

    static log(...args) {
        if (process.env.REACT_APP_ENV_NAME !== environmentConstants.PRODUCTION) {
            console.log(`%c[Notifications]`, "color:deeppink", ...args);
            debug(...args);
        }
    }

    //get all notifications enabled for current os
    static getAvailableNotificationsPrefsForCurrentOS() {
        let availableOptions = getAvailableNotificationsPrefs();
        if (Commons.isValidArray(availableOptions)) {
            availableOptions = availableOptions.filter(option => option?.os.includes(os));
        } else {
            availableOptions = [];
        }

        return availableOptions;
    }

    static getUnreadNotifications() {
        const notifications = store.getState().dashboardReducer.notifications;
        return notifications.filter(notification => !notification.read);
    }

    static areNotificationsUnread() {
        return this.getUnreadNotifications()?.length > 0;
    }
}