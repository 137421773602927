import React, { useState, useEffect } from 'react';
import { SwipeableDrawer } from '@mui/material';
import { aemPages, AnalyticsEvents, shopSortingOptions } from '../../_constants';
import { AEMHelper, propertyTextDefaultEmpty } from '../../_helpers/aem/aemhelper';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';

export const ShopSorting = ({
    sortingOption,
    onApplySorting,
    sortingOptions
}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [thisSortingOption, setThisSortingOption] = useState(null);

    const [dictionary, setDictionary] = useState({});

    useEffect(() => {
        const aem = new AEMHelper();
        setDictionary(
            aem.getDictionary(aemPages.SHOP, {
                SHOP_SORT_BY_LABEL: propertyTextDefaultEmpty,
                SHOP_SORT_BY_ALL: propertyTextDefaultEmpty,
                SHOP_SORT_BY_LOW_TO_HIGH: propertyTextDefaultEmpty,
                SHOP_SORT_BY_HIGH_TO_LOW: propertyTextDefaultEmpty,
                SHOP_SORT_BY_NAME_A_TO_Z: propertyTextDefaultEmpty,
                SHOP_SORT_BY_NAME_Z_TO_A: propertyTextDefaultEmpty,
                SHOP_REORDER_PREV_ORDERS_SORT_BY_DATE_DESCENDING: propertyTextDefaultEmpty,
                SHOP_REORDER_PREV_ORDERS_SORT_BY_DATE_ASCENDING: propertyTextDefaultEmpty,
                SHOP_REORDER_PREV_ORDERS_TP_HIGH_TO_LOW: propertyTextDefaultEmpty,
                SHOP_REORDER_PREV_ORDERS_TP_LOW_TO_HIGH: propertyTextDefaultEmpty,
            })
        );
    }, [])

    useEffect(() => {
        setThisSortingOption(sortingOption);
    }, [sortingOption]);

    const handleDrawerClose = () => {
        setIsOpen(false);
    }

    const isActiveOption = (sortingOpt) => {
        return thisSortingOption === sortingOpt;
    }

    const handleApplySorting = (sortingOpt) => {
        logAnalyticsEvent(AnalyticsEvents.SORT_BY, {
            sort_by_value: sortingOpt
        })
        setThisSortingOption(sortingOpt);
        setIsOpen(false);
        if(typeof onApplySorting === 'function'){
            onApplySorting(sortingOpt);
        }
    }

    const getLabelFromSortingOption = (option) => {
        return {
            [shopSortingOptions.SORTING_ALL]: dictionary.SHOP_SORT_BY_ALL,
            [shopSortingOptions.SORTING_LOW2HIGH]: dictionary.SHOP_SORT_BY_LOW_TO_HIGH,
            [shopSortingOptions.SORTING_HIGH2LOW]: dictionary.SHOP_SORT_BY_HIGH_TO_LOW,
            [shopSortingOptions.SORTING_A2Z]: dictionary.SHOP_SORT_BY_NAME_A_TO_Z,
            [shopSortingOptions.SORTING_Z2A]: dictionary.SHOP_SORT_BY_NAME_Z_TO_A,
            [shopSortingOptions.SORTING_LOW2HIGH_TOTAL]: dictionary.SHOP_REORDER_PREV_ORDERS_TP_LOW_TO_HIGH,
            [shopSortingOptions.SORTING_HIGH2LOW_TOTAL]: dictionary.SHOP_REORDER_PREV_ORDERS_TP_HIGH_TO_LOW,
            [shopSortingOptions.SORTING_DATE_ASC]: dictionary.SHOP_REORDER_PREV_ORDERS_SORT_BY_DATE_ASCENDING,
            [shopSortingOptions.SORTING_DATE_DESC]: dictionary.SHOP_REORDER_PREV_ORDERS_SORT_BY_DATE_DESCENDING,
        }[option] || dictionary.SHOP_SORT_BY_ALL;
    }

    return (
        <React.Fragment>

            <div className="shop-filter-all flex-grow-1 d-flex align-items-center text-uppercase"
                onClick={() => setIsOpen(true)}>
                {getLabelFromSortingOption(thisSortingOption)}
                <span className="bat-icon-chevron-down ms-auto"></span>
            </div>

            <SwipeableDrawer
                anchor={'bottom'}
                open={isOpen}
                onClose={handleDrawerClose}
                onOpen={handleDrawerClose}
                disableDiscovery={true} //iOS has a "swipe to go back" feature that interferes with the discovery feature, so discovery has to be disabled
            >
                <React.Fragment>
                    <div className='drawer-header'><span>{dictionary.SHOP_SORT_BY_LABEL}</span></div>
                    {
                        sortingOptions.map((option, idx) => 
                            <div key={`sort-option-${idx}`}
                            className={`filter-category-el ${isActiveOption(option) ? 'filter-selected' : ''}`}
                            onClick={() => handleApplySorting(option)}>{getLabelFromSortingOption(option)} </div>
                        )
                    }
                </React.Fragment>
            </SwipeableDrawer>
        </React.Fragment>
    )

}