import React from 'react';
import { TermsAndConditions } from '../../_components';
import { history } from '../../_helpers/history';
import { getURLSearchObject } from '../../_helpers/navigation';
import { Commons } from '../../_helpers/commons';

export const TermsConditionsList = (props) => {

    const getBackHandler = () => {
        const { canHandleBack } = getURLSearchObject();
        if (canHandleBack) {
          return () => history.goBack();
        } else {
          return null;
        }
    }

    const getCloseHandler = () => {
        if (props?.history?.location?.state?.canClose) {
            return () => history.goBack();
        } else {
            return null;
        }
    }

    return (
        <div className="page w-100 h-100">
            <TermsAndConditions 
            isAPublicView={props.isAPublicView} 
            handleBack={getBackHandler.bind(this)()} 
            close={getCloseHandler.bind(this)()}
            logo={props?.history?.location?.state?.logo} 
            />
        </div>
    );

}