export const shopConstants = {
    SET_WISHLIST:               'shop/SET_WISHLIST',
    GET_WISHLIST:               'shop/GET_WISHLIST',
    DELETE_WISHLIST:            'shop/DELETE_WISHLIST',
    GET_LAST_ORDER:             'shop/GET_LAST_ORDER',
    GET_CATALOG:                'shop/GET_CATALOG',
    GET_FLAVOUR_FOR_U:          'shop/GET_FLAVOUR_FOR_U',

    SET_SMART_REORDER:          'shop/SET_SMART_REORDER',
    GET_SMART_REORDER:          'shop/GET_SMART_REORDER',
    DEL_SMART_REORDER:          'shop/DEL_SMART_REORDER',
    UPDATE_SMART_REORDER:       'shop/UPDATE_SMART_REORDER',

    ADD_TO_BASKET:              'shop/ADD_TO_BASKET',
    UPDATE_IN_BASKET:           'shop/UPDATE_IN_BASKET',
    UPDATE_BASKET_QTY:          'shop/UPDATE_BASKET_QTY',
    DELETE_FROM_BASKET:         'shop/DELETE_FROM_BASKET',
    REPLACE_BASKET:             'shop/REPLACE_BASKET',
    ADD_VARIANT_IN_WISHLIST:    'shop/ADD_VARIANT_IN_WISHLIST',
    UPDATE_VARIANT_IN_WISHLIST: 'shop/UPDATE_VARIANT_IN_WISHLIST',
    RESET_WISHLIST:             'shop/RESET_WISHLIST',

}

export const flavourType = {
    FLAVOUR_NEW : 'New'
}

export const shopSortingOptions = {
    SORTING_ALL:                'SORTING_ALL',
    SORTING_LOW2HIGH_TOTAL:     'SORTING_LOW2HIGH_TOTAL',
    SORTING_HIGH2LOW_TOTAL:     'SORTING_HIGH2LOW_TOTAL',
    SORTING_LOW2HIGH:           'SORTING_LOW2HIGH',
    SORTING_HIGH2LOW:           'SORTING_HIGH2LOW',
    SORTING_A2Z:                'SORTING_A2Z',
    SORTING_Z2A:                'SORTING_Z2A',
    SORTING_DATE_ASC:           'SORTING_DATE_ASC',
    SORTING_DATE_DESC:          'SORTING_DATE_DESC',
}

export const productType = {
    ACCESORIES: 'accessories',
    DEVICES: 'devices',
    FLAVOURS: 'epod pods',
}

export const shopTabs = {
    ACCESSORIES: 'accessories',
    DEVICES: 'devices',
    FLAVOURS: 'flavours',
}

export const orderReminderFrequency = {
    EVERY_MONTH: '1M',
    EVERY_THREE_WEEKS: '3W',
    EVERY_TWO_WEEKS: '2W',
    EVERY_WEEK: '1W',
    EVERY_DAY: '1D'
}