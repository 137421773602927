import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { AnalyticsEvents, AnalyticsFieldNames, AnalyticsScreenEventNames, aemPages } from "../../../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../../../_helpers/aem/aemhelper";
import { useSignupFormContext } from "../SignupFormContext";
import { SignupFormStep } from "../SignupFormStep";
import { logAnalyticsEvent } from "../../../../_helpers/analytics/logAnalytics";

const FIELD_NAME_TAC = "tac";
const FIELD_MARKETING_CONSENT = "marketing";
const FIELD_NAME_EMAIL_CONSENT = "userOptedInForEmails";
const FIELD_NAME_NOTIFICATIONS_CONSENT = "userOptedInForPushNotifications";

export function ConsentsStepDE(props) {
  const [dictionary, setDictionary] = useState({});
  const { register } = useFormContext();
  const { updateForm } = useSignupFormContext();
  const { [FIELD_NAME_TAC]: tac, [FIELD_MARKETING_CONSENT]: marketing } =
    useWatch([FIELD_NAME_TAC, FIELD_MARKETING_CONSENT]);

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.SIGN_UP_CONSENTS})
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.REGISTRATION_DETAIL_TAC, {
        REGISTRATION_HEADER: propertyHeadingDefaultEmpty,
        REGISTRATION_BTN_CREATE_ACCOUNT: propertyCtaItemsDefaultEmpty,
        REGISTRATION_TAC_CHECKBOX: propertyTextDefaultEmptyParsed,
        REGISTRATION_MARKETING_LABEL: propertyTextDefaultEmpty,
        REGISTRATION_VUSE_INSIDERS_CLUB_CHECKBOX: propertyTextDefaultEmptyParsed,
        REGISTRATION_CUSTOMER_NOTE: propertyTextDefaultEmptyParsed,
        REGISTRATION_TAC_LINKS: propertyTextDefaultEmpty,
      })
    );
  }, []);

  useEffect(() => {
    updateForm({
      [FIELD_NAME_EMAIL_CONSENT]: marketing,
      [FIELD_NAME_NOTIFICATIONS_CONSENT]: marketing,
    });
  }, [marketing, updateForm]);

  const logToggleAnalytics = (fieldName, value) => {
    logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {
      field_name: fieldName,
      field_value: value ? "true" : "false"
    });
  };

  return (
    <SignupFormStep
      title={dictionary.REGISTRATION_HEADER}
      customClass="h-auto consents-step"
      ctaLabel={dictionary.REGISTRATION_BTN_CREATE_ACCOUNT_0_ctaLabel}
      showAgeWarning
      isLast
      isValid={!!tac}
      {...props}
    >
      <div className="signup-form-wrapper">
        <div className="signup-form-row">
          <div className="form-check">
            <input
              type="checkbox"
              id={FIELD_NAME_TAC}
              name={FIELD_NAME_TAC}
              className="form-check-input me-3"
              ref={register({
                required: true,
              })}
              onChange={(e) => logToggleAnalytics(AnalyticsFieldNames.TERMS_AND_CONDITIONS, e.target.checked)}
            />
            <label htmlFor={FIELD_NAME_TAC}>
              {dictionary.REGISTRATION_TAC_CHECKBOX}
            </label>
          </div>
        </div>
        <div className="signup-form-row marketing-check-wrapper">
          <label className="page-label">
            {dictionary.REGISTRATION_MARKETING_LABEL}
          </label>
          <div className="form-check">
            <input
              type="checkbox"
              id={FIELD_MARKETING_CONSENT}
              name={FIELD_MARKETING_CONSENT}
              className="form-check-input me-3"
              ref={register()}
              onChange={(e) => logToggleAnalytics(AnalyticsFieldNames.PERSONAL_DATA_PROCESSING, e.target.checked)}
            />
            <label htmlFor={FIELD_MARKETING_CONSENT}>
              {dictionary.REGISTRATION_VUSE_INSIDERS_CLUB_CHECKBOX}
            </label>
          </div>
          <div className="marketing-content">
            {dictionary.REGISTRATION_CUSTOMER_NOTE}
          </div>
          <div className="marketing-content mt-3">
            {(dictionary?.REGISTRATION_TAC_LINKS || "")
              .split(";")
              .map((link, i) => (
                <p key={i}>
                  [{i + 1}]{" "}
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {link}
                  </a>
                </p>
              ))}
          </div>
        </div>
      </div>
    </SignupFormStep>
  );
}
