/**
 * Store cancel tokens in order to cancel 
 * pending requests
 */
class AxiosCancelTokensHolder {
    static cancelTokens = [];

    static set(payload) {
        this.cancelTokens = payload;
    }

    static get() {
        return this.cancelTokens;
    }

    static add(payload) {
        this.cancelTokens = [...this.cancelTokens, payload];
    }

    static delete(requestId) {
        const filtered = this.cancelTokens.filter(_ => _.requestId !== requestId);

        this.cancelTokens = filtered
    }
}

export { AxiosCancelTokensHolder }