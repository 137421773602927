import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { AnalyticsEvents, AnalyticsFieldNames, AnalyticsScreenEventNames, aemPages, webviewUrl } from "../../../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../../../_helpers/aem/aemhelper";
import { useSignupFormContext } from "../SignupFormContext";
import { SignupFormStep } from "../SignupFormStep";
import { logAnalyticsEvent } from "../../../../_helpers/analytics/logAnalytics";
import { getUrl } from "../../../../_actions/appConfig.actions";

const FIELD_NAME_EMAIL_CONSENT = "userOptedInForEmails";
const FIELD_NAME_NOTIFICATIONS_CONSENT = "userOptedInForPushNotifications";
const FIELD_NAME_LOYALTY_OPTIN = "vusePlusOptIn";

export function ConsentsStepCA(props) {
  const [dictionary, setDictionary] = useState({});
  const { showContactPanel, updateForm } = useSignupFormContext();
  const { register } = useFormContext();
  const {
    [FIELD_NAME_EMAIL_CONSENT]: emailConsent,
    [FIELD_NAME_NOTIFICATIONS_CONSENT]: notificationConsent,
    [FIELD_NAME_LOYALTY_OPTIN]: vuseOptin,
  } = useWatch([FIELD_NAME_EMAIL_CONSENT, FIELD_NAME_NOTIFICATIONS_CONSENT, FIELD_NAME_LOYALTY_OPTIN]);

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.SIGN_UP_CONSENTS})
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.REGISTRATION_DETAIL_TAC, {
        REGISTRATION_HEADER: propertyHeadingDefaultEmpty,
        REGISTRATION_MARKETING_LABEL: propertyTextDefaultEmpty,
        REGISTRATION_CONSENT_1: propertyTextDefaultEmptyParsed,
        REGISTRATION_EMAIL_CHECKBOX: propertyTextDefaultEmpty,
        REGISTRATION_NOTIFICATIONS_CHECKBOX: propertyTextDefaultEmpty,
        REGISTRATION_CONTENT_DISCLAIMER: propertyTextDefaultEmpty,
        REGISTRATION_LINK_PP: propertyTextDefaultEmptyParsed,
        REGISTRATION_LINK_OCOS: propertyTextDefaultEmptyParsed,
        REGISTRATION_BTN_CREATE_ACCOUNT: propertyCtaItemsDefaultEmpty,
        REGISTRATION_YOTI_LOYALTY_TITLE: propertyTextDefaultEmpty,
        REGISTRATION_YOTI_LOYALTY_CHECKBOX: propertyTextDefaultEmptyParsed,
        REGISTRATION_LOYALTY_REWARDS: propertyTextDefaultEmpty
      })
    );
  }, []);

  useEffect(() => {
    updateForm({
      [FIELD_NAME_EMAIL_CONSENT]: emailConsent,
      [FIELD_NAME_NOTIFICATIONS_CONSENT]: notificationConsent,
      [FIELD_NAME_LOYALTY_OPTIN]: vuseOptin,
    });
  }, [updateForm, emailConsent, notificationConsent, vuseOptin]);

  const logToggleAnalytics = (fieldName, value) => {
    logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {
      field_name: fieldName,
      field_value: value ? "true" : "false"
    });
  };

  const handleURL = () =>
    window.open(
      `${getUrl(webviewUrl.LOYALTY_TAC)}`,
      "_blank"
    )

  return (
    <SignupFormStep
      title={dictionary.REGISTRATION_HEADER}
      privacyPolicyLabel={dictionary.REGISTRATION_LINK_PP}
      createAccountLabel={dictionary.REGISTRATION_LINK_OCOS}
      //contentDisclaimer={dictionary.REGISTRATION_CONTENT_DISCLAIMER}
      ctaLabel={dictionary.REGISTRATION_BTN_CREATE_ACCOUNT_0_ctaLabel}
      showAgeWarning
      isLast
      isValid
      className="signup-info-consents-step"
      {...props}
    >
      
      <div className="signup-consents-marketing-step">
        <div className="page-label">
          {dictionary.REGISTRATION_MARKETING_LABEL}
        </div>
        <div className="marketing-content hyperlinked" onClick={showContactPanel}>
          {dictionary.REGISTRATION_CONSENT_1}
        </div>
        <div className="marketing-check-wrapper">
          <div className="form-check">
            <input
              name={FIELD_NAME_EMAIL_CONSENT}
              id={FIELD_NAME_EMAIL_CONSENT}
              autoComplete="off"
              className="form-check-input"
              type="checkbox"
              ref={register}
              onChange={(e) => logToggleAnalytics(AnalyticsFieldNames.MARKETING_INFORMATION_EMAIL, e.target.checked)}
            />
            <label
              className="form-check-label"
              htmlFor={FIELD_NAME_EMAIL_CONSENT}
            >
              {dictionary.REGISTRATION_EMAIL_CHECKBOX}
            </label>
          </div>
          <div className="form-check">
            <input
              name={FIELD_NAME_NOTIFICATIONS_CONSENT}
              id={FIELD_NAME_NOTIFICATIONS_CONSENT}
              autoComplete="off"
              className="form-check-input"
              type="checkbox"
              ref={register}
              onChange={(e) => logToggleAnalytics(AnalyticsFieldNames.MARKETING_INFORMATION_NOTIFICATIONS, e.target.checked)}
            />
            <label
              className="form-check-label"
              htmlFor={FIELD_NAME_NOTIFICATIONS_CONSENT}
            >
              {dictionary.REGISTRATION_NOTIFICATIONS_CHECKBOX}
            </label>
          </div>
        </div>
      </div>
      <div className="signup-form-wrapper">
        <div className="loyalty-check">
          <div className="marketing-content py-2">
            {dictionary.REGISTRATION_CONTENT_DISCLAIMER}
          </div>
  
          <div className="page-label mt-3">
            {dictionary.REGISTRATION_YOTI_LOYALTY_TITLE}
          </div>
          <div className="marketing-content hyperlinked" onClick={handleURL}>{dictionary.REGISTRATION_YOTI_LOYALTY_CHECKBOX}</div>
          <div className="marketing-check-wrapper">
            <div className="form-check">
              <input
                name={FIELD_NAME_LOYALTY_OPTIN}
                id={FIELD_NAME_LOYALTY_OPTIN}
                autoComplete="off"
                className="form-check-input"
                type="checkbox"
                ref={register}
                style={{alignSelf: 'start'}}
                onChange={(e) => logToggleAnalytics(AnalyticsFieldNames.LOYALTY_OPTIN, e.target.checked)}
              />
              <label
                className="form-check-label"
                htmlFor={FIELD_NAME_LOYALTY_OPTIN}
              >
                {dictionary.REGISTRATION_LOYALTY_REWARDS}
              </label>
            </div>
          </div>
        </div>
      </div>
    </SignupFormStep>
  );
}
