import { isNil } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import {
  getCountry,
  isSupportedService,
} from "../../../_actions/appConfig.actions";
import { ConnectedIntlProvider, SpinnerModal } from "../../../_components";
import {
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  routingConstants,
  servicesConstants,
} from "../../../_constants";
import { logAnalyticsEvent } from "../../../_helpers/analytics/logAnalytics";
import { Commons } from "../../../_helpers/commons";
import { debug } from "../../../_helpers/debug";
import { buildURI } from "../../../_helpers/navigation";
import { loyaltyServices, shopServices } from "../../../_services";
import { LoyaltyDashboardCA } from "./LoyaltyDashboardCA";
import { LoyaltyDashboardDE } from "./LoyaltyDashboardDE";

const LoyaltyDashboard = ({ history }) => {
  const [data, setData] = useState({});
  const [country, setCountry] = useState("");
  const [oneTimeToken, setOneTimeToken] = useState(null);
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [spinnerLoadingOTP, setSpinnerLoadingOTP] = useState(true);
  const [spinnerError, setSpinnerError] = useState(false);

  const handleBack = () => {
    Commons.goToDashboard();
  };

  const getOneTimeToken = useCallback(async () => {
    try {
      const res = await shopServices.getOneTimeToken();
      const token = res?.data?.oneTimeAuthToken;

      if (token) {
        setOneTimeToken(token);
        setSpinnerLoadingOTP(false);
      }
    } catch (err) {
      console.debug("getOneTimeToken failure: ", err);
      debug("getOneTimeToken failure: " + JSON.stringify(err));
      setSpinnerError(true);
    }
  }, []);

  const getLoyaltyData = useCallback(async () => {
    try {
      const res = await loyaltyServices.getLoyaltyData();

      console.debug("getLoyaltyData success: ", res);
      debug("getLoyaltyData success: " + JSON.stringify(res));

      if (res.data?.data) {
        setData(res.data.data);
        setCountry(getCountry());
        setSpinnerLoading(false);
      }
    } catch (err) {
      console.debug("getLoyaltyData failure: ", err);
      debug("getLoyaltyData failure: " + JSON.stringify(err));
      setSpinnerError(true);
    }
  }, []);

  const getLoyaltyStatus = useCallback(async () => {
    try {
      const res = await loyaltyServices.getLoyaltyStatus();
      const hasOptedIn = res.data?.data?.status;

      console.debug("getLoyaltyStatus success: ", res);
      debug("getLoyaltyStatus success: " + JSON.stringify(res));

      let screen_name;
      hasOptedIn ? screen_name = AnalyticsScreenEventNames.LOYALTY : screen_name = AnalyticsScreenEventNames.LOYALTY_NOT_JOINED;
      logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, { screen_name: screen_name });

      //logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
      //  screen_name: AnalyticsScreenEventNames.LOYALTY,
      //  optin: hasOptedIn,
      //}); --> old

      if (hasOptedIn) {
        getLoyaltyData();
      } else {
        console.debug("redirect to LoyaltyOptin");
        debug("redirect to LoyaltyOptin");
        history.push(buildURI(routingConstants.LOYALTY_OPTIN));
      }
    } catch (err) {
      console.debug("getLoyaltyStatus failure: ", err);
      debug("getLoyaltyStatus failure: " + JSON.stringify(err));
      setSpinnerError(true);
    }
  }, [history, getLoyaltyData]);

  useEffect(() => {
    if (isSupportedService(servicesConstants.MENU_LOYALTY)) {
      const loyaltyStatus = history?.location?.state?.loyaltyStatus;

      console.debug("history loyaltyStatus", loyaltyStatus);
      debug("history loyaltyStatus " + JSON.stringify(loyaltyStatus));

      if (isNil(loyaltyStatus)) {
        getLoyaltyStatus();
      } else {
        if (loyaltyStatus === true) {
          getLoyaltyData();
        } else {
          history.push(buildURI(routingConstants.LOYALTY_OPTIN));
        }
      }
    } else {
      history.push(buildURI(routingConstants.DASHBOARD));
    }
  }, [history, getLoyaltyStatus, getLoyaltyData]);

  useEffect(() => {
    isSupportedService(servicesConstants.SHOP_OTP)
    ? getOneTimeToken()
    : setSpinnerLoadingOTP(false);
  }, [getOneTimeToken]);

  const renderLoyalty = () => {

    switch (country) {
      case "CA":
        return (
          <ConnectedIntlProvider>
            <LoyaltyDashboardCA {...{ handleBack, data, oneTimeToken }} />;
          </ConnectedIntlProvider>
        );
      case "DE":
        return (
          <ConnectedIntlProvider>
            <LoyaltyDashboardDE {...{ handleBack, data, oneTimeToken }} />
          </ConnectedIntlProvider>
        );
      default:
        return null;
    }

  }

  return (
    <>
    {renderLoyalty()}
    <SpinnerModal
        show={spinnerLoading || spinnerLoadingOTP}
        error={spinnerError}
        onForceClose={() => {
          setSpinnerError(false);
          setSpinnerLoading(false);
          setSpinnerLoadingOTP(false);
        }}
      />
      </>
  );
  
};

export { LoyaltyDashboard };
