import React, { useState, useEffect, useCallback, useRef } from 'react';
import _ from 'lodash';
import { userActions } from '../../_actions';
import { AnalyticsEvents, AnalyticsFieldNames, aemPages } from '../../_constants';
import { store } from '../../_store';
import { Commons } from '../../_helpers/commons';
import { isCurrentCountryGermany } from '../../_actions/appConfig.actions';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';

export const AddressFinder = (props) => {

    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [locations, setLocations] = useState([]);
    const [lastInsertedAddress, setLastInsertedAddress] = useState(null);
    const [address, setAddress] = useState(null);
    const [addressObj, setAddressObj] = useState(null);
    const [addresses, setAddresses] = useState(null);

    const inputRef = useRef(null);

    /* Phone & Address */
    let REGISTRATION_ADDRESS_LABEL = props.registrationDetailAddrPage?.getComponent(aemPages.REGISTRATION_DETAIL_ADDR, 'REGISTRATION_ADDRESS_LABEL');
    let REGISTRATION_ADDRESS_PLACEHOLDER = props.registrationDetailAddrPage?.getComponent(aemPages.REGISTRATION_DETAIL_ADDR, 'REGISTRATION_ADDRESS_PLACEHOLDER');
    let REGISTRATION_FIELD_REQUIRED = props.registrationDetailAddrPage?.getComponent(aemPages.REGISTRATION_DETAIL_ADDR, 'REGISTRATION_FIELD_REQUIRED');

    useEffect(() => {
        if(props.address){
            setAddressObj(props.address);
            setAddress(`${props.address['street[0]']} ${props.address['city']}, ${props.address['province']}, ${props.address['zip']}`);
        } else {
            setAddressObj(null)
            setAddress(null)
        }
    }, [props.address])

    useEffect(() => {
        if (addresses) {
            let names = _.compact(addresses.Items.map((n, idx) => {
                if(idx < 10) {
                    if (n.Error == null || n.Error === undefined) {
                        return {
                            value: n.Id,
                            label: n.Text,
                            description: n.Description,
                            next: (n.Next === 'Find' || (n.Type && n.Type !== 'Address')) ? true : false
                        };
                    }
                }
                return null;
            }))

            setLocations(names);
            setIsDropdownVisible(true);

        }

    }, [addresses])

    useEffect(() => {
        if (typeof props.onAddressSelection === 'function') {
            props.onAddressSelection(addressObj);
        }
    }, [addressObj])

    /**
     * Open address complete dropdown on focus input
     */
    const onFocus = () => {
        setIsDropdownVisible(true);
    }

    /**
     * Close dropdown on blur input
     */
    const onBlur = () => {
        logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.ADDRESS})
        setIsDropdownVisible(false);
    }

    const fetchMoreAddresses = (value, lastId) => {

        console.log("fetching more addresses...");
        store.dispatch(userActions.getAddresses(value, lastId,
            (response) => {
                if (response) {
                    setAddresses(response);
                }
            }, (error) => {
                console.log("error");
                console.log(error);
            }));
    }

    const debounceFetchMoreAddresses = useCallback(_.debounce(fetchMoreAddresses, 500), []);

    /**
     * Handle address selection from dropdown
     * @param {*} item 
     */
    const onAutocompleteSelection = (item) => {

        console.log("Selected address!");
        console.log(item);

        if (item) {
            if (item.next) {
                console.log("Get more addresses...");

                onNextFindAddress(item.value);
            } else {
                console.log("Yeah this is my address! Getting details...");
                store.dispatch(userActions.getAddressDetails(item.value,
                    (response) => {

                        console.log("Details");
                        console.log(response);

                        let address = response;
                        if (
                            response !== null &&
                            response !== undefined
                        ) {
                            if (Commons.isValidArray(response.Items)) {
                                address = response.Items[0];

                                setAddressObj({
                                    'company': address.Company,
                                    'street[0]': address.Line1,
                                    'street[1]': address.Line2,
                                    'street[2]': address.Line3,
                                    'city': address.City,
                                    'zip': address.PostalCode,
                                    'country': address.CountryIso2,
                                    'countryName': address.CountryName,
                                    'province': isCurrentCountryGermany() ? address.ProvinceName : address.ProvinceCode
                                });


                                console.log("inputRef", inputRef);
                                inputRef.current.focus();
                                inputRef.focus();
                                
                            }
                        }
                    },
                    (error) => {

                    }));

                setAddress(`${item.label} ${item.description}`);

                //Chiudo la tendina
                setIsDropdownVisible(false);
            }
        }

    }

    /**
 * Handle search for 2nd step search adddresses
 * @param {*} itemId 
 */
    const onNextFindAddress = (itemId) => {
        debounceFetchMoreAddresses(lastInsertedAddress, itemId);
    }

    /**
     * Handle change input address complete
     * @param {*} event 
     */
    const handleChange = (event) => {
        setLastInsertedAddress(event.target.value);
        debounceFetchMoreAddresses(event.target.value);
        setAddress(event.target.value);

        if(typeof props.onChangeText === 'function') {
            props.onChangeText(event);
        }
    }

    const renderAddressesResult = () => {

        if (locations === undefined || locations === null || locations.length === 0) {
            return null;
        }

        if (!isDropdownVisible) {
            return null;
        }

        logAnalyticsEvent(AnalyticsEvents.ADDRESS_SELECTION_UPDATED)

        return (
            <ul className="form-address-panel" >
                {
                    locations.map((locationEl, locationIndex) => {

                        return (
                            <li className="btn-address"
                                key={`location[${locationIndex}]`}
                                style={{ display: 'block' }}
                                onMouseDown={() => onAutocompleteSelection(locationEl)}>
                                <React.Fragment>
                                    {locationEl.label} {locationEl.description}

                                    {locationEl.next &&
                                        <span className="baticon-angle-right ml-auto"></span>
                                    }
                                </React.Fragment>
                            </li>
                        )
                    })
                }
            </ul>
        )
    }


    return (
        <div className="signup-form-row">
            <span className="form-mandatory">{REGISTRATION_FIELD_REQUIRED?.getRaw('text', null)}</span>
            <label className="page-label" htmlFor="registrationAddress">{REGISTRATION_ADDRESS_LABEL?.getRaw('text', null)}</label>
            <div className="form-address-wrapper">
                <input id="registrationAddress"
                    ref={inputRef}
                    className='form-control'
                    name="resAddress"
                    placeholder={REGISTRATION_ADDRESS_PLACEHOLDER?.getRaw('text', null)}
                    onChange={handleChange}
                    onClick={onFocus}
                    autoComplete="nofill"
                    onBlur={onBlur}
                    value={address} />
                {renderAddressesResult()}
            </div>
        </div>
    );
}