import React from 'react';
import { getUrl } from '../../_actions/appConfig.actions';
import { os, routingConstants, webviewUrl } from '../../_constants';
import { WebView } from '../../_components';
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';
import { Commons } from '../../_helpers/commons';

export const DeviceFaq = ({ history }) => {

    const handleBack = () => {
        history.goBack();
    }

    return (
        <WebView
            title={null}
            {...(history?.location?.state?.canClose ? { close: handleBack } : { handleBack } )}
            url={`${getUrl(webviewUrl.WEBVIEW_DEVICE_FAQ)}${os}`}
            id="extPageIframe"
            className="extPageIframe"
        />
    )
}
