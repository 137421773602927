import { Drawer } from "@mui/material";
import { isEmpty, isNil, uniq } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { getSupportedServices } from "../../../_actions/appConfig.actions";
import animationAvDocument from "../../../_assets/lotties/AV_document.json";
import { AnalyticsErrorNames, AnalyticsEvents, AnalyticsFieldNames, AnalyticsScreenEventNames, AnalyticsTargetEventsNames, aemPages, servicesConstants } from "../../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../../_helpers/aem/aemhelper";
import { CustomAlert } from "../../CustomAlert";
import { CustomInlineAlert } from "../../CustomInlineAlert";
import { useSignupFormContext } from "./SignupFormContext";
import { SignupFormStep } from "./SignupFormStep";
import { getRegistration, setRegistration } from "../../../_actions";
import { logAnalyticsEvent } from "../../../_helpers/analytics/logAnalytics";
import CustomLottie from "../../CustomLottie/CustomLottie";

const FIELD_NAME_FIRSTNAME = "firstName";
const FIELD_NAME_LASTNAME = "lastName";

const NAME_RE = /[A-Za-zÀ-ÖØ-öø-ÿ'’ -]{2,50}$/;

const NAME_RE_NO_SYMBOL = /[^A-Za-zÀ-ÖØ-öø-ÿ'’ -]+?/g;

export function NameStep(props) {
  const [dictionary, setDictionary] = useState({});
  const [isAVSupported, setIsAVSupported] = useState(false);
  const [isAVAlertOpen, setIsAVAlertOpen] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const { formData } = useSignupFormContext();
  const { register, setValue, formState } = useFormContext();
  const { dirtyFields, errors } = formState;
  const { firstName, lastName } = useWatch([
    FIELD_NAME_FIRSTNAME,
    FIELD_NAME_LASTNAME,
  ]);

  const [errorFirstname, setErrorFirstname] = useState("");
  const [errorLastname, setErrorLastname] = useState("");
  const [errorStringFirstname, setErrorStringFirstname] = useState("");
  const [errorStringLastname, setErrorStringLastname] = useState("");

  const [isFirstNameValid, setIsFirstNameValid] = useState(false);
  const [isLastNameValid, setIsLastNameValid] = useState(false);
  const [firstNameValue, setFirstNameValue] = useState()
  const [lastNameValue, setLastNameValue] = useState()

  useEffect(() => {
    const aem = new AEMHelper();
    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.SIGN_UP_NAME})

    setDictionary(
      aem.getDictionary(aemPages.REGISTRATION_DETAIL, {
        REGISTRATION_HEADER: propertyHeadingDefaultEmpty,
        REGISTRATION_FIELD_REQUIRED: propertyTextDefaultEmpty,
        REGISTRATION_FIRST_NAME_LABEL: propertyTextDefaultEmptyParsed,
        REGISTRATION_FIRST_NAME_PLACEHOLDER: propertyTextDefaultEmpty,
        REGISTRATION_LAST_NAME_LABEL: propertyTextDefaultEmptyParsed,
        REGISTRATION_LAST_NAME_PLACEHOLDER: propertyTextDefaultEmpty,
        REGISTRATION_AV_ALERT_HEADER: propertyHeadingDefaultEmpty,
        REGISTRATION_AV_ALERT_BODY: propertyTextDefaultEmpty,
        REGISTRATION_AV_ALERT_CONTENT: propertyTextDefaultEmpty,
        REGISTRATION_AV_ALERT_NOTE: propertyTextDefaultEmpty,
        REGISTRATION_MSG_PHOTO_ID: propertyTextDefaultEmpty,
        REGISTRATION_AV_ALERT_WHY_LINK: propertyTextDefaultEmpty,
        REGISTRATION_PRIVACY_POLICY: propertyTextDefaultEmptyParsed,
        REGISTRATION_BTN_NEXT: propertyCtaItemsDefaultEmpty,
        REGISTRATION_VALIDATION_ERROR_INVALID_CHRCTS_FIRST_NAME:
          propertyTextDefaultEmpty,
        REGISTRATION_VALIDATION_ERROR_FIRST_NAME: propertyTextDefaultEmpty,
        REGISTRATION_VALIDATION_ERROR_FIRST_NAME_EMPTY:
          propertyTextDefaultEmpty,
        REGISTRATION_VALIDATION_ERROR_INVALID_CHRCTS_LAST_NAME:
          propertyTextDefaultEmpty,
        REGISTRATION_VALIDATION_ERROR_LAST_NAME: propertyTextDefaultEmpty,
        REGISTRATION_VALIDATION_ERROR_LAST_NAME_EMPTY: propertyTextDefaultEmpty,
        REGISTRATION_AV_BTN_CONTINUE: propertyCtaItemsDefaultEmpty,
      })
    );

    const isAVSupported = getSupportedServices().includes(
      servicesConstants.ONBOARDING_AV_MODAL
    );

    setIsAVSupported(isAVSupported);
    setIsAVAlertOpen(isAVSupported);
  }, []);

  useEffect(() => {
    const firstname = formData[FIELD_NAME_FIRSTNAME];
    const lastname = formData[FIELD_NAME_LASTNAME];

    if (firstname && lastname) {
      setValue(FIELD_NAME_FIRSTNAME, firstname, { shouldDirty: true });
      setValue(FIELD_NAME_LASTNAME, lastname, { shouldDirty: true });
    }
  }, [formData, setValue]);

  const checkValidation = (value, setError, setErrorString, field) => {
    const resultSymbol = value.match(NAME_RE_NO_SYMBOL);
  
    if (Array.isArray(resultSymbol)) {
      setError("symbol");
      setErrorString(uniq(resultSymbol).join(""));
    } else if (value === "") {
      setError("empty");
    } else if (value.length < 2 || value.length > 50) {
      setError("length");
    } else {
      setError("");
      setErrorString("");
    }
  };

  const checkValidationAnalytics = (value, field) => {
    const resultSymbol = value.match(NAME_RE_NO_SYMBOL);
  
    if (Array.isArray(resultSymbol)) {
      logAnalyticsEvent(AnalyticsEvents.VALIDATION_ERROR, {field_name: field, error: AnalyticsErrorNames.WRONG_FORMAT})      
    } else if (value === "") {
      logAnalyticsEvent(AnalyticsEvents.VALIDATION_ERROR, {field_name: field, error: AnalyticsErrorNames.EMPTY})      
    } else if (value.length < 2 || value.length > 50) {
      logAnalyticsEvent(AnalyticsEvents.VALIDATION_ERROR, {field_name: field, error: AnalyticsErrorNames.MIN_LENGHT})      
    } else {
      logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: field})
    }
  };

  const handleFirstNameChange = (value) => {
    const trimmedValue = value.trim();
    setFirstNameValue(trimmedValue)
    checkValidation(trimmedValue, setErrorFirstname, setErrorStringFirstname, AnalyticsFieldNames.FIRST_NAME);

    const isFirstNameValid =
      (!firstName || firstName.length >= 2) &&
      trimmedValue.length >= 2 &&
      !errorFirstname &&
      !errors[FIELD_NAME_FIRSTNAME];

    setIsFirstNameValid(isFirstNameValid);
  };

const handleLastNameChange = (value) => {
  const trimmedValue = value.trim();
  setLastNameValue(trimmedValue)
  checkValidation(trimmedValue, setErrorLastname, setErrorStringLastname, AnalyticsFieldNames.LAST_NAME);


  const isLastNameValid =
    (!lastName || lastName.length >= 2) &&
    trimmedValue.length >= 2 &&
    !errorLastname &&
    !errors[FIELD_NAME_LASTNAME];

  setIsLastNameValid(isLastNameValid);
};

useEffect(() => {
  setIsValid(isFirstNameValid && isLastNameValid);
}, [isFirstNameValid, isLastNameValid])

useEffect(() => {
  const trimmedFirstName = (firstName || '').trim();
  const trimmedLastName = (lastName || '').trim();

  const isFirstNameValid =
    (!firstName || firstName.length >= 2) &&
    trimmedFirstName.length >= 2 &&
    !errorFirstname &&
    !errors[FIELD_NAME_FIRSTNAME];

  const isLastNameValid =
    (!lastName || lastName.length >= 2) &&
    trimmedLastName.length >= 2 &&
    !errorLastname &&
    !errors[FIELD_NAME_LASTNAME];

  setIsValid(isFirstNameValid && isLastNameValid);

}, [firstName, lastName, errorFirstname, errorLastname, errors]);


  useEffect(() => {
    if (isValid) {
      let registration = getRegistration();
      registration.firstName = firstName;
      registration.lastName = lastName;
      setRegistration(registration);
    }
  }, [firstName, lastName, isValid])

  const handleBlurFirstName = () => {
    checkValidationAnalytics(firstNameValue, AnalyticsFieldNames.FIRST_NAME)
  }

  const handleBlurSecondName = () => {
    checkValidationAnalytics(lastNameValue, AnalyticsFieldNames.LAST_NAME)
  }

  return (
    <SignupFormStep
      title={dictionary.REGISTRATION_HEADER}
      privacyPolicyLabel={dictionary.REGISTRATION_PRIVACY_POLICY}
      ctaLabel={dictionary.REGISTRATION_BTN_NEXT_0_ctaLabel}
      isValid={isValid}
      {...props}
    >
      <div className="signup-form-wrapper">
        {isAVSupported && (
          <Drawer
            anchor={"bottom"}
            open={isAVAlertOpen}
            onClose={() => null} //setIsAVAlertOpen(false)}
          >
            <div
              className="dialog-av"
              style={{
                margin: 0,
                width: "100%",
                borderRadius: 0,
                height: "auto",
              }}
            >
              <div className="dialog-av-wrapper">
                <div className="page-header-title d-flex align-items-center justify-content-center">
                  <h4>{dictionary.REGISTRATION_AV_ALERT_HEADER}</h4>
                </div>
                <div className="dialog-av-body">
                  <div className="dialog-av-image align-items-center justify-content-center">
                    <CustomLottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationAvDocument,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                      height={100}
                      width={155}
                    />
                  </div>
                  <div className="dialog-av-content-bold">
                    <p>{dictionary.REGISTRATION_AV_ALERT_BODY}</p>
                  </div>
                  <div className="dialog-av-content">
                    <p>{dictionary.REGISTRATION_AV_ALERT_CONTENT}</p>
                  </div>
                </div>
                <div class="page-selector-cta">
                  <div class="d-grid">
                    <button
                      type="button"
                      class="btn btn-primary text-uppercase"
                      onClick={() => setIsAVAlertOpen(false)}
                    >
                      {dictionary.REGISTRATION_AV_BTN_CONTINUE_0_ctaLabel}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        )}

        <div className="signup-form-row">
          <span className="form-mandatory">
            {dictionary.REGISTRATION_FIELD_REQUIRED}
          </span>
          <label className="page-label" htmlFor={FIELD_NAME_FIRSTNAME}>
            {dictionary.REGISTRATION_FIRST_NAME_LABEL}
          </label>
          <input
            name={FIELD_NAME_FIRSTNAME}
            id={FIELD_NAME_FIRSTNAME}
            autoComplete="off"
            className="form-control"
            placeholder={dictionary.REGISTRATION_FIRST_NAME_PLACEHOLDER}
            type="text"
            onChange={(e) => handleFirstNameChange(e.target.value)}
            ref={register({
              required: true,
              pattern: NAME_RE,
            })}
            maxLength={50}
            onBlur={handleBlurFirstName}
          />
        </div>
        <div className="signup-form-row">
          <span className="form-mandatory">
            {dictionary.REGISTRATION_FIELD_REQUIRED}
          </span>
          <label className="page-label" htmlFor={FIELD_NAME_LASTNAME}>
            {dictionary.REGISTRATION_LAST_NAME_LABEL}
          </label>
          <input
            name={FIELD_NAME_LASTNAME}
            id={FIELD_NAME_LASTNAME}
            autoComplete="off"
            className="form-control"
            placeholder={dictionary.REGISTRATION_LAST_NAME_PLACEHOLDER}
            type="text"
            onChange={(e) => handleLastNameChange(e.target.value)}
            ref={register({
              required: true,
              pattern: NAME_RE,
            })}
            maxLength={50}
            onBlur={handleBlurSecondName}
          />
        </div>
        {isAVSupported || !isEmpty(dictionary.REGISTRATION_MSG_PHOTO_ID) ?
        <div className="information-block p-0">
          <div className="d-flex">
            <div className="flex-grow-1">
              <span className="information-block-label">
                {isAVSupported ? (
                  <>
                    {dictionary.REGISTRATION_AV_ALERT_NOTE}{" "}
                    <span
                      className="url-text"
                      onClick={() => {setIsAVAlertOpen(true); logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.WHY})}}
                    >
                      {dictionary.REGISTRATION_AV_ALERT_WHY_LINK}
                    </span>
                  </>
                ) : !isNil(dictionary.REGISTRATION_MSG_PHOTO_ID) ? (
                  <div className="d-flex"><span className="bat-icon-alert-circle" style={{marginRight: '6px'}}></span>{dictionary.REGISTRATION_MSG_PHOTO_ID}</div>
                ) : null}
              </span>
            </div>
          </div>
        </div>
        : null }
        {isAVSupported && (
          <CustomAlert
            isOpen={false}
            onClose={() => setIsAVAlertOpen(false)}
            customClasses={"dialog-av"}
            hideOkButton
            closeOnClickOutside
          >
            <div className="dialog-av-wrapper">
              <div className="page-header-title d-flex align-items-center justify-content-center">
                <h2>{dictionary.REGISTRATION_AV_ALERT_HEADER}</h2>
                <button
                  type="button"
                  className="page-header-icon btn"
                  onClick={() => setIsAVAlertOpen(false)}
                >
                  <span className="bat-icon-close" />
                </button>
              </div>
              <div className="dialog-av-body">
                <div className="dialog-av-image align-items-center justify-content-center">
                  <CustomLottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: animationAvDocument,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={100}
                    width={155}
                  />
                </div>
                <div className="dialog-av-content-bold">
                  <p>{dictionary.REGISTRATION_AV_ALERT_BODY}</p>
                </div>
                <div className="dialog-av-content">
                  <p>{dictionary.REGISTRATION_AV_ALERT_CONTENT}</p>
                </div>
              </div>
            </div>
          </CustomAlert>
        )}
        <CustomInlineAlert
          isShown={errorFirstname === "symbol"}
          alertType="error"
        >
          {dictionary.REGISTRATION_VALIDATION_ERROR_INVALID_CHRCTS_FIRST_NAME +
            " " +
            errorStringFirstname}
        </CustomInlineAlert>
        <CustomInlineAlert
          isShown={errorFirstname === "length"}
          alertType="error"
        >
          {dictionary.REGISTRATION_VALIDATION_ERROR_FIRST_NAME}
        </CustomInlineAlert>
        <CustomInlineAlert
          isShown={errorFirstname === "empty"}
          alertType="error"
        >
          {dictionary.REGISTRATION_VALIDATION_ERROR_FIRST_NAME_EMPTY}
        </CustomInlineAlert>
        <CustomInlineAlert
          isShown={errorLastname === "symbol"}
          alertType="error"
        >
          {dictionary.REGISTRATION_VALIDATION_ERROR_INVALID_CHRCTS_LAST_NAME +
            " " +
            errorStringLastname}
        </CustomInlineAlert>
        <CustomInlineAlert
          isShown={errorLastname === "length"}
          alertType="error"
        >
          {dictionary.REGISTRATION_VALIDATION_ERROR_LAST_NAME}
        </CustomInlineAlert>
        <CustomInlineAlert
          isShown={errorLastname === "empty"}
          alertType="error"
        >
          {dictionary.REGISTRATION_VALIDATION_ERROR_LAST_NAME_EMPTY}
        </CustomInlineAlert>
      </div>
    </SignupFormStep>
  );
}
