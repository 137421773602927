import React from 'react';
import { connect } from 'react-redux';
import { dashboardActions } from '../../_actions';
import { FeatureTutorial } from '../../_components';
import { aemPages, thingVuseProperties, routingConstants, AnalyticsEvents, AnalyticsTargetEventsNames, AnalyticsTutorialNames } from '../../_constants';
import { AEMHelper, propertyHeadingDefaultEmpty } from '../../_helpers/aem/aemhelper';
import { getCurrentDevice } from '../../_helpers/device';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';

class BatterySettingsTutorial extends React.Component {

    constructor(props) {
        super(props);

        this.aem = new AEMHelper();

        /* Retrieve the videos from AEM */
        const device = getCurrentDevice();

        this.dictionary = this.aem.getDictionary(
            this.aem.getPageName("BATTERY_SETTINGS_TUTORIAL", device.deviceType), 
            {
                BATT_TUTORIAL_HEADER: propertyHeadingDefaultEmpty,
            }
        );

        let urls = this.aem?.getCarouselUrlsFor(device?.deviceType, 'BATTERY_SETTINGS_TUTORIAL');
        let stories = urls.map(url => {
            return {
                url: url,
                type: 'video',
                muted: true
            }
        })
        
        /* Set the state */
        this.state = {stories};
    }

    componentDidMount(){
        logAnalyticsEvent(AnalyticsEvents.TUTORIAL, {tutorial_name: AnalyticsTutorialNames.RECHARGE_REMINDERS})
    }

    render() {
        return (
            <FeatureTutorial title={this.dictionary.BATT_TUTORIAL_HEADER}
                featureIcon={"vuse-brand"}
                stories={this.state.stories}
                iconAction={null}
                backAction={this.props.history.location.state?.backUrl || routingConstants.BATTERY_SETTINGS}
                doneTutorialMethod={dashboardActions.setDoneTutorialBattery}
                doneTutorialValue={this.props.tutorials.battery}
                thing={thingVuseProperties.BATTERYSAVE}
                tutorialName={AnalyticsTutorialNames.RECHARGE_REMINDERS}
            />
        )
    }
}


function mapStateToProps(state) {
    return {
        tutorials: state.dashboardReducer.tutorials
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(BatterySettingsTutorial);
export { connectedComponent as BatterySettingsTutorial };