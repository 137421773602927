import _ from "lodash";
import { getAnalyticsUserPropertiesFromLocalStorage, getUserData, setAnalyticsUserPropertiesInLocalStorage } from "../_actions";
import { Singleton } from "../_classes/singleton";
import { getAnalyticsDeviceFrom, getDefaultAnalyticPreferences } from "../_helpers/analytics/logAnalytics";
//import { isDeviceConnected } from "../_helpers/device";
import { isAnEmptyObject } from "../_helpers/utils";
//import { store } from "../_store";

class AnalyticsEventModel extends Singleton{

    constructor() {
        super();
        this.eventName = "";
        this.eventProperties = {};
        this.userProperties = {};
    }        

    setEvent = (eventName, eventProperties = {}, userProperties = {}) => {
        this.setEventName(eventName);
        this.setEventProperties(eventProperties);
        this.setUserProperties(userProperties);
    }

    setEventName = (eventName) => {
        this.eventName = eventName;
    }

    setEventProperties = (eventProperties = {}) => {
        this.eventProperties = AnalyticsEventModel.sanitizeProperties(eventProperties);
    }

    setUserProperties = (userProperties = {}) => {
        //const { devices = [] } = store.getState().deviceReducer;
        //const connectedDevice = devices.find(isDeviceConnected);
        const userData = getUserData();

        this.userProperties = {
            ...this.userProperties, // old user properties
            // ...getAnalyticsDeviceFrom(connectedDevice), // new device properties
            ...AnalyticsEventModel.sanitizeProperties(userProperties), // new user properties
            //...(userData && { userID: userData?.id })
        };

        setAnalyticsUserPropertiesInLocalStorage(this.userProperties);
    }

    generateEvent = () => {
        const properties = !isAnEmptyObject(this.userProperties)
            ? { user_properties: this.userProperties }
            : {}

        return (
            {
                event: {
                    event_name: this.eventName,
                    event_params: {
                        ...this.userProperties,
                        ...this.eventProperties
                    }
                },
                ...properties
            }
        );
    }

    softResetAnalyticsUserProperties = () => {
        this.userProperties = {
            country: this.userProperties.country,
            province: this.userProperties.province,
            language: this.userProperties.language,
        };

        setAnalyticsUserPropertiesInLocalStorage(this.userProperties);
    }

    static softResetAnalyticsUserProperties() {
        AnalyticsEventModel.getInstance().softResetAnalyticsUserProperties();
    }

    static sanitizeProperties = (properties = {}) =>  {
        return Object.keys(properties).reduce((acc, propsKey) => {
            const propsValue = properties[propsKey];
            const sanitizedPropsKey = AnalyticsEventModel.sanitizeKey(propsKey);
            const sanitizedPropsValue = AnalyticsEventModel.sanitizeValue(propsValue, propsKey);
            
            return {
                ...acc,
                [sanitizedPropsKey]: sanitizedPropsValue
            }
        }, {})
    }

    static sanitizeKey = (key = "") => {
        return key.replace(/ /gi, "_");
    }

    static sanitizeValue = (value, propsKey) => {
        const WHITELIST_KEYS = ['screen_name', 'event_name']
        let newValue = value;
        if (!!value) {
            if (Array.isArray(value)) {
                newValue = value.map(v => AnalyticsEventModel.sanitizeValue(v, propsKey));
            } else if (_.isPlainObject(value)) {
                newValue = AnalyticsEventModel.sanitizeProperties(value);
            } else if (WHITELIST_KEYS.includes(propsKey)) {
                if (typeof value === "string") {
                    newValue = value.replace(/ /gi, "").toLowerCase()
                } 
            }
        }
        
        
        return newValue;
    }

}

export { AnalyticsEventModel };