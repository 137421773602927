import { PERMISSIONS_ACTION_TYPE } from '../_constants';

function setNotificationPermissionStatus(status) {
    return async (dispatch, getState) => {
        const {
            onboardingReducer: {
                hasUserAlreadyReplyForNotificationPermission
            }
        } = getState();
        if (!hasUserAlreadyReplyForNotificationPermission) {
            await dispatch({
                type: PERMISSIONS_ACTION_TYPE.SET_NOTIFICATION_PERMISSION_STATUS,
                data: status
            });
        }

        return hasUserAlreadyReplyForNotificationPermission;
    };
}

function resetNotificationPermissionStatus() {
    return (dispatch) => {
        return dispatch({
            type: PERMISSIONS_ACTION_TYPE.RESET_NOTIFICATION_PERMISSION_STATUS,
            data: null
        });
    };
}

function setOSNotificationPermissionStatus(status) {
    return {
        type: PERMISSIONS_ACTION_TYPE.SET_OS_NOTIFICATION_PERMISSION_STATUS,
        data: status
    };
}

export const permissionsActions = {
    setNotificationPermissionStatus,
    resetNotificationPermissionStatus,
    setOSNotificationPermissionStatus
}