import React from 'react';
import { WebView } from '../../_components';
import { getURLSearchObject } from '../../_helpers/navigation';


class ExtPage extends React.Component {
    handleBack() {
        this.props.history.goBack();
    }

    render() {
        const {
            url,
            title
        } = getURLSearchObject();

        return (
            <WebView
                title={title}
                handleBack={() => this.handleBack()}
                url={url}
                id="extPageIframe"
                className="extPageIframe"
            />
        )
    }
}


export { ExtPage };