import React, { useEffect, useState, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import {
  AnalyticsErrorNames,
  AnalyticsEvents,
  AnalyticsFieldNames,
  AnalyticsScreenEventNames,
  aemPages,
  routingConstants,
  servicesConstants,
} from "../../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../../_helpers/aem/aemhelper";
import { history } from "../../../_helpers/history";
import { buildURI } from "../../../_helpers/navigation";
import { Tenants } from "../../../_helpers/tenants";
import { userServices } from "../../../_services";
import { CustomInlineAlert } from "../../CustomInlineAlert";
import { useSignupFormContext } from "./SignupFormContext";
import { SignupFormStep } from "./SignupFormStep";
import { isSupportedService } from "../../../_actions/appConfig.actions";
import { getRegistration, setRegistration } from "../../../_actions";
import { debounce } from "lodash";
import { logAnalyticsEvent } from "../../../_helpers/analytics/logAnalytics";

const FIELD_NAME_EMAIL = "email";
// https://emailregex.com/
const EMAIL_RE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zAZ\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function EmailStep(props) {
  const [dictionary, setDictionary] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [emailAlertIsVisible, setEmailAlertIsVisible] = useState(false);
  const { formData } = useSignupFormContext();
  const { register, setValue, formState } = useFormContext();
  const { dirtyFields, errors } = formState;
  const { email } = useWatch(FIELD_NAME_EMAIL);
  const [errorEmail, setErrorEmail] = useState(false);
  const [emailTimeout, setEmailTimeout] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const initialCheck = useRef(true);

  const isEmailAvailable = async (email) => {
    try {
      const checkEmail = userServices.checkEmail(email);
      const { data: payload } = await checkEmail();
      setEmailAlertIsVisible(payload?.data?.exists);
      return !payload?.data?.exists;
    } catch (err) {
      setEmailAlertIsVisible(false);
      return false;
    }
  };

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.SIGN_UP_EMAIL})
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.REGISTRATION, {
        REGISTRATION_HEADER: propertyHeadingDefaultEmpty,
        REGISTRATION_EMAIL_LABEL: propertyTextDefaultEmpty,
        REGISTRATION_FIELD_REQUIRED: propertyTextDefaultEmpty,
        REGISTRATION_EMAIL_PLACEHOLDER: propertyTextDefaultEmpty,
        REGISTRATION_EMAIL_CONFIRMATION_ALERT: propertyTextDefaultEmpty,
        REGISTRATION_EMAIL_INFO: propertyTextDefaultEmpty,
        REGISTRATION_EMAIL_CHECK_FAILURE: propertyTextDefaultEmptyParsed,
        REGISTRATION_PRIVACY_POLICY: propertyTextDefaultEmptyParsed,
        REGISTRATION_BTN_NEXT: propertyCtaItemsDefaultEmpty,
        REGISTRATION_INVALID_EMAIL: propertyTextDefaultEmptyParsed,
      })
    );
    
  }, []);

  const debouncedValidateEmail = debounce(async (newEmail) => {
    const result = newEmail.match(EMAIL_RE);
  
    if (result) {
      setErrorEmail(false);
  
      try {
        const checkEmail = userServices.checkEmail(newEmail);
        const { data: payload } = await checkEmail();
        setEmailAlertIsVisible(payload?.data?.exists);
  
        if (payload?.data?.exists) {
          setIsButtonDisabled(true);
        } else {
          setIsButtonDisabled(false);
        }
      } catch (err) {
        setEmailAlertIsVisible(false);
        setIsButtonDisabled(true);
      }
    } else {
      setErrorEmail(true);
      setIsButtonDisabled(true);
    }
  }, 500);
  

  const handleEmailChange = (e) => {

    initialCheck.current = false;
    const newEmail = e.target.value;
    
    setValue(FIELD_NAME_EMAIL, newEmail, {
      shouldDirty: true,
      shouldValidate: true,
    });
  
    if (emailTimeout) {
      clearTimeout(emailTimeout);
    }
  
    const newEmailTimeout = setTimeout(async () => {
      const result = newEmail.match(EMAIL_RE);

      if (!result) {
        logAnalyticsEvent(AnalyticsEvents.VALIDATION_ERROR, {field_name: AnalyticsFieldNames.EMAIL, error: AnalyticsErrorNames.WRONG_FORMAT})      
      }else {
        logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.EMAIL})
      }
  
      if (result) {
        setErrorEmail(false);
  
        try {
          const checkEmail = userServices.checkEmail(newEmail);
          const { data: payload } = await checkEmail();
          setEmailAlertIsVisible(payload?.data?.exists);
  
          if (payload?.data?.exists) {
            logAnalyticsEvent(AnalyticsEvents.VALIDATION_ERROR, {field_name: AnalyticsFieldNames.EMAIL, error: AnalyticsErrorNames.ALREADY_USED})
            setIsButtonDisabled(true);
          } else {
            setIsButtonDisabled(false);
          }
        } catch (err) {
          setEmailAlertIsVisible(false);
          setIsButtonDisabled(true);
        }
      } else {
        setErrorEmail(true);
        setIsButtonDisabled(true);
      }
    }, 500);
  
    setEmailTimeout(newEmailTimeout);
  };
    

  useEffect(() => {
    const email = formData[FIELD_NAME_EMAIL];

    if (email) {
      setValue(FIELD_NAME_EMAIL, email, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [formData, setValue]);

  useEffect(() => {
    if (email !== undefined && isValid) {
      const result = email.match(EMAIL_RE);

      if (result) {
        setErrorEmail(false);
      } else {
        setErrorEmail(true);
      }
    }

    setIsValid(email && dirtyFields[FIELD_NAME_EMAIL] && !errors[FIELD_NAME_EMAIL]);
  }, [email, formState, dirtyFields, errors]);

  useEffect(() => {
    if (isValid) {
      let registration = getRegistration();
      registration.email = email;
      setRegistration(registration);
    }
  }, [email, isValid]);

  useEffect(() => {

    if (email && initialCheck.current){
      handleEmailChange({target: {value: email}});
      initialCheck.current = false;
    }

  }, [email]);

  return (
    <SignupFormStep
      title={dictionary.REGISTRATION_HEADER}
      privacyPolicyLabel={dictionary.REGISTRATION_PRIVACY_POLICY}
      ctaLabel={dictionary.REGISTRATION_BTN_NEXT_0_ctaLabel}
      isValid={!isButtonDisabled}
      showAgeWarning
      {...props}
    >
      <div className="signup-form-wrapper">
        <div className="signup-form-row">
          <span className="form-mandatory">
            {dictionary.REGISTRATION_FIELD_REQUIRED}
          </span>
          <label className="page-label" htmlFor={FIELD_NAME_EMAIL}>
            {dictionary.REGISTRATION_EMAIL_LABEL}
          </label>
          <input
            name={FIELD_NAME_EMAIL}
            id={FIELD_NAME_EMAIL}
            autoComplete="off"
            className={`form-control ${errorEmail ? "is-invalid" : ""}`}
            placeholder={dictionary.REGISTRATION_EMAIL_PLACEHOLDER}
            type="email"
            onChange={handleEmailChange}
            ref={register({
              required: true,
              pattern: EMAIL_RE,
              validate: isEmailAvailable,
            })}
          />
          <div className="mt-2">
            <CustomInlineAlert isShown={emailAlertIsVisible} alertType="info">
              <span
                className="email-info"
                onClick={() => history.push(buildURI(routingConstants.LOGIN))}
              >
                {dictionary.REGISTRATION_EMAIL_CHECK_FAILURE}
              </span>
            </CustomInlineAlert>
            <CustomInlineAlert isShown={errorEmail === true} alertType="error">
              {dictionary.REGISTRATION_INVALID_EMAIL}
            </CustomInlineAlert>
          </div>
        </div>
      </div>
      {isSupportedService(
        servicesConstants.ONBOARDING_EMAIL_REG_VERIFICATION
      ) && (
        <div className="information-block ">
          <div className="d-flex">
            <div className="flex-shrink-0">
              <span className="bat-icon-alert-circle fs-16" />
            </div>
            <div className="flex-grow-1 ms-2">
              <span className="information-block-label">
                {dictionary.REGISTRATION_EMAIL_CONFIRMATION_ALERT}
              </span>
            </div>
          </div>
        </div>
      )}
    </SignupFormStep>
  );
}
