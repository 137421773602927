import React, {useEffect, useState} from 'react';
import { getUrl } from '../../_actions/appConfig.actions';
import { aemPages, os, routingConstants, webviewUrl } from '../../_constants';
import { AEMHelper, propertyHeadingDefaultEmpty } from '../../_helpers/aem/aemhelper';
import { WebView } from '../../_components';
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';
import { Commons } from '../../_helpers/commons';

export const StoreLocator = () => {
    const [dictionary, setDictionary] = useState({});

    useEffect(() => {
        const aem = new AEMHelper()
        setDictionary(
            {
                ...aem.getDictionary(aemPages.STORE_LOCATOR, {
                    STORE_LOCATOR_HEADER: propertyHeadingDefaultEmpty,
                })
            }
        )
    }, [])

    const handleBack = () => {
        Commons.goToDashboard({
            selected: "store"
        });
    }

    return (
        <WebView
            title={dictionary.STORE_LOCATOR_HEADER}
            close={handleBack}
            url={`${getUrl(webviewUrl.WEBVIEW_STORE_LOCATOR)}`}
            id="extPageIframe"
            className="extPageIframe"
        />
    )
}
