class OrderModel {

    total = 0;
    number = '';
    items = [];
    date = null;

    constructor(data) {
        if(data.shopifyData){
            this.total = data.shopifyData.current_total_price;
            this.number = data.shopifyData.order_number;
            this.items = data.shopifyData.line_items;
            this.date = data.createdAt;
        }
    }        

    toObj = () => {
        return {
            total: this.total,
            number: this.number,
            items: this.items,
            date: this.date
           
        }        
    }

}

export { OrderModel };