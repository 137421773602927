import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

const FlavourCard = ({ flavour, idx, removeItem, openPdp }) => {
    return (
        <div className="card" style={{
            ...(flavour?.gradient && flavour.gradient !== "" && { background: flavour.gradient })
        }}>
            <div className="card-img-overlay d-flex align-items-center flex-column">
                <button type="button" className="btn align-self-end" aria-label="Close"
                    onClick={() => removeItem(idx, flavour.id)}>
                    <span className="bat-icon-close"></span>
                </button>
                <div className="card-body d-flex flex-column justify-content-start align-items-center pt-0"
                    onClick={() => openPdp(flavour)}>
                    <img src={flavour.featured_image} alt="" />
                    <h2 className='text-uppercase align-self-center'>{flavour.title}</h2>
                    <span>{flavour.description}</span>
                </div>
            </div>
        </div>
    )
}

export const ShopFlavoursCarousel = (props) => {

    const [elements, setElements] = useState([]);
    const [swiper, setSwiper] = useState(null);

    useEffect(() => {
        setElements([...props.items]);
    }, [props.items]);

    useEffect(() => {
        if (elements && swiper) {
            swiper.updateSlides();
        }
    }, [elements]);

    const removeItem = (idx, id) => {
        let tempElements = [...elements];
        tempElements.splice(idx, 1);
        setElements(tempElements);
        swiper.updateSlides();

        if (typeof props.onRemoveElement === 'function') {
            props.onRemoveElement(id);
        }
    }
    const openPdp = (flavour) => {
        if (typeof props.onOpenPdp === 'function') {
            props.onOpenPdp(flavour);
        }
    }
    return (
        <Swiper
            slidesPerView={'auto'}
            loop={false}
            centeredSlides={true}
            centeredSlidesBounds={true}
            initialSlide={0}
            centerInsufficientSlides={false}
            onInit={(swiper) => setSwiper(swiper)}
            onTouchStart={(swiper) => swiper.updateSlides()}
        >
            {(
                elements !== undefined &&
                elements !== null &&
                elements.length > 0
            ) ? (
                elements.map((item, idx) => {
                    return (
                        <SwiperSlide key={idx} className="swiper-slide-flavour" style={{
                            ...(idx === 0 && { marginLeft: '0.9375rem' })
                        }}>
                            <FlavourCard idx={idx} flavour={item} removeItem={removeItem} openPdp={openPdp} />
                        </SwiperSlide>
                    );
                })
            ) : null}

        </Swiper>
    );

}