import { vuse } from "@nodes-projects/bat-sdk-web";
import cx from "classnames";
import { isFunction } from "lodash";
import React, { forwardRef, useEffect, useState } from "react";
import { AnalyticsEvents, AnalyticsTargetEventsNames } from "../../_constants";
import { logAnalyticsEvent, logAnalyticsEventForDevice } from "../../_helpers/analytics/logAnalytics";
import {
  getDeviceInstanceFromSN,
  setDeviceConnectionStateChanged,
} from "../../_helpers/device";
import sdk from "../../_vendors/nodes";

const ReconnectCTA = forwardRef(
  ({ className, style, device, onSuccess = null, onError, children, isAbsolute = true, onStart = null }, ref) => {

    const [isConnecting, setIsConnecting] = useState([]);

    useEffect(() => {
      window.document.addEventListener("pairingStart", onStart);
      window.document.addEventListener("pairingSuccess", onSuccess);
    }, [])

    useEffect(() => {
      if (device.connectionState === sdk[device.deviceType].ConnectionState.Connecting) {
        setIsConnecting(true)
      } else {
        setIsConnecting(false)
      }
    }, [device])

    const handleReconnect = async () => {
      logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.RECONNECT})
      let deviceInstance = await getDeviceInstanceFromSN(device?.serialNumber);

      if (!deviceInstance) {
        deviceInstance = vuse.setDevice(device?.deviceType);
      }

      deviceInstance.setConnectionStateChanged((connectionState) =>
        {setDeviceConnectionStateChanged(connectionState, deviceInstance); 
          if(connectionState === 7){
          logAnalyticsEvent(AnalyticsEvents.DEVICE_RECONNECT_SUCCESS)
        }}
      );

      await deviceInstance.connect().catch((e) => {
        logAnalyticsEvent(AnalyticsEvents.DEVICE_RECONNECT_FAILURE)
        if (isFunction(onError)) {
          onError();
        }
      });

      if (isFunction(onSuccess)) {
        onSuccess();
      }
    };

    return (
      <button
        ref={ref}
        type="button"
        {...(style && { style })}
        className={cx(
          "btn btn-primary text-dark text-uppercase reconnect-cta",
          {"reconnect-cta-abs": isAbsolute},
          className
        )}
        onClick={!isConnecting ? handleReconnect : null}
      >
        {isConnecting ? 
          <div className="d-flex justify-content-center">
              <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
              </div>
          </div>
          :
          children
        }
      </button>
    );
  }
);

export { ReconnectCTA };
