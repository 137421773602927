import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class PickerColumn extends Component {
    static propTypes = {
        options: PropTypes.array.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        itemHeight: PropTypes.number.isRequired,
        columnHeight: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            isMoving: false,
            startTouchY: 0,
            startScrollerTranslate: 0,
            isDefaultOverflowHidden: document.body.style.overflow === 'hidden', //parent has already set to hidden overflow y?
            ...this.computeTranslate(props)
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isMoving) {
            return;
        }
        this.setState(this.computeTranslate(nextProps));
    }

    computeTranslate = (props) => {
        const { options, value, itemHeight, columnHeight } = props;
        let selectedIndex = options.findIndex(optionValue => _.isEqual(optionValue, value));
        if (selectedIndex < 0) {
            this.onValueSelected(options[0]);
            selectedIndex = 0;
        }
        return {
            scrollerTranslate: columnHeight / 2 - itemHeight / 2 - selectedIndex * itemHeight,
            minTranslate: columnHeight / 2 - itemHeight * options.length + itemHeight / 2,
            maxTranslate: columnHeight / 2 - itemHeight / 2
        };
    };

    onValueSelected = (newValue) => {
        this.props.onChange(this.props.name, newValue);
    };

    handleTouchStart = (event) => {
        if(!this.state.isDefaultOverflowHidden){
            document.body.style.overflow = 'hidden';
        }

        if(this.props.panelRef){
            this.props.panelRef.style.overflow = 'hidden';
        }

        this.safePreventDefault(event);

        const startTouchY = event.targetTouches[0].pageY;
        this.setState(({ scrollerTranslate }) => ({
            startTouchY,
            startScrollerTranslate: scrollerTranslate
        }));
    };

    handleTouchMove = (event) => {

        this.safePreventDefault(event);

        const touchY = event.targetTouches[0].pageY;
        this.setState(({ isMoving, startTouchY, startScrollerTranslate, minTranslate, maxTranslate }) => {
            if (!isMoving) {
                return {
                    isMoving: true
                }
            }

            let nextScrollerTranslate = startScrollerTranslate + touchY - startTouchY;

            if (nextScrollerTranslate < minTranslate) {
                nextScrollerTranslate = minTranslate - Math.pow(minTranslate - nextScrollerTranslate, 0.8);
            } else if (nextScrollerTranslate > maxTranslate) {
                nextScrollerTranslate = maxTranslate + Math.pow(nextScrollerTranslate - maxTranslate, 0.8);
            }

            return {
                scrollerTranslate: nextScrollerTranslate
            };
        });
    };

    handleTouchEnd = (event) => {

        if(!this.state.isDefaultOverflowHidden){
            document.body.style.overflow = '';
        }

        if(this.props.panelRef){
            this.props.panelRef.style.overflow = '';
        }

        if (!this.state.isMoving) {
            return;
        }
        this.setState({
            isMoving: false,
            startTouchY: 0,
            startScrollerTranslate: 0
        });

        setTimeout(() => {
            const { options, itemHeight } = this.props;
            const { scrollerTranslate, minTranslate, maxTranslate } = this.state;
            let activeIndex;
            if (scrollerTranslate > maxTranslate) {
                activeIndex = 0;
            } else if (scrollerTranslate < minTranslate) {
                activeIndex = options.length - 1;
            } else {
                activeIndex = - Math.floor((scrollerTranslate - maxTranslate) / itemHeight);
            }
            this.onValueSelected(options[activeIndex]);
        }, 0);
    };

    handleTouchCancel = (event) => {
        
        if(!this.state.isDefaultOverflowHidden){
            document.body.style.overflow = '';
        }
        
        if(this.props.panelRef){
            this.props.panelRef.style.overflow = '';
        }
        if (!this.state.isMoving) {
            return;
        }
        this.setState((startScrollerTranslate) => ({
            isMoving: false,
            startTouchY: 0,
            startScrollerTranslate: 0,
            scrollerTranslate: startScrollerTranslate
        }));
    };

    safePreventDefault = (event) => {
        const passiveEvents = ['touchstart', 'touchmove', 'wheel', 'touchend'];
        if (!passiveEvents.includes(event.type)) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    handleItemClick = (option) => {
        if (option !== this.props.value) {
            this.onValueSelected(option);
        } else {
            this.props.onClick(this.props.name, this.props.value);
        }
    };

    renderItems() {
        const { options, itemHeight, value } = this.props;
        return options.map((option, index) => {
            let optionValue = option;
            if(_.isObjectLike(option)){
                optionValue = option.label;
            }
            const style = {
                height: itemHeight / 16 + 'rem',
                lineHeight: itemHeight / 16 + 'rem'
            };
            const className = `wheel-picker-item${_.isEqual(option, value) ? ' wheel-picker-item-selected' : ''}`;

            return (
                <div
                    key={index}
                    className={className}
                    style={style}
                    onClick={() => this.handleItemClick(option)}>{optionValue}</div>
            );
        });
    }

    render() {
        const translateString = `translate3d(0, ${this.state.scrollerTranslate}px, 0)`;
        const style = {
            MsTransform: translateString,
            MozTransform: translateString,
            OTransform: translateString,
            WebkitTransform: translateString,
            transform: translateString
        };
        if (this.state.isMoving) {
            style.transitionDuration = '0ms';
        }
        return (
            <div className="wheel-picker-column">
                <div
                    className="wheel-picker-scroller"
                    style={style}
                    onTouchStart={this.handleTouchStart}
                    onTouchMove={this.handleTouchMove}
                    onTouchEnd={this.handleTouchEnd}
                    onTouchCancel={this.handleTouchCancel}>
                    {this.renderItems()}
                </div>
            </div>
        )
    }
}

export default class Picker extends Component {
    static propTyps = {
        optionGroups: PropTypes.object.isRequired,
        valueGroups: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        onClick: PropTypes.func,
        itemHeight: PropTypes.number,
        height: PropTypes.number,
        highlightHeight: PropTypes.number
    };

    renderInner() {
        const { optionGroups, valueGroups, itemHeight = 36, height = 216, onChange, onClick  = () => { }, highlightHeight,
            panelRef } = this.props;
        const highlightStyle = {
            height: highlightHeight ?? itemHeight,
            marginTop: -((highlightHeight ?? itemHeight) / 2)
        };
        const columnNodes = [];
        for (let name in optionGroups) {
            columnNodes.push(
                <PickerColumn
                    key={name}
                    name={name}
                    options={optionGroups[name]}
                    value={valueGroups[name]}
                    itemHeight={itemHeight}
                    columnHeight={height}
                    onChange={onChange}
                    onClick={onClick}
                    panelRef={panelRef} />
            );
        }
        return (
            <div className="wheel-picker-inner">
                {columnNodes}
                <div className="wheel-picker-cursor" style={highlightStyle}></div>
            </div>
        );
    }

    render() {
        const style = {
            height: this.props.height
        };

        return (
            <div className="wheel-picker-container" style={style}>
                {this.renderInner()}
            </div>
        );
    }
}
