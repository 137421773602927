import { isNil } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Header } from "../../_components";
import { AnalyticsEvents, aemPages, routingConstants, AnalyticsTargetEventsNames } from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { isDeviceConnected, isSynchronized } from "../../_helpers/device";
import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";
import { FactoryReset } from "../FactoryReset";

class AdvancedSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDevice: null,
      isShownFactoryResetPanel: false,
      isShownRemoveDevicePanel: false,
    };

    this.doFactoryReset = this._doFactoryReset.bind(this);
    this.onEndFactoryReset = this._onEndFactoryReset.bind(this);
    this.goBack = this._goBack.bind(this);

    this.aem = new AEMHelper();
    this.dictionary = this.aem.getDictionary(aemPages.DEVICE_SETTINGS, {
      DEVICE_SETT_REMOVE_DEVICE_TITLE: propertyTextDefaultEmpty,
      DEVICE_SETT_FACTORY_RESET_TITLE: propertyTextDefaultEmpty,
      DEVICE_SETT_FACTORY_RESET_BODY: propertyTextDefaultEmpty,
      DEVICE_SETT_MODEL_NAME: propertyTextDefaultEmpty,
      DEVICE_SETT_ADVANCED_SETTINGS_HEADER: propertyHeadingDefaultEmpty,
      DEVICE_SETT_BTN_RESET_DATA: propertyCtaItemsDefaultEmpty,
      DEVICE_SETT_DEVICE_INFORMATION: propertyTextDefaultEmpty,
      DEVICE_SETT_MAC: propertyTextDefaultEmpty,
      DEVICE_SETT_BTN_REMOVE_DEVICE: propertyCtaItemsDefaultEmpty,
      DEVICE_SETT_ID: propertyTextDefaultEmpty,
      DEVICE_SETT_REMOVE_DEVICE_BODY: propertyTextDefaultEmpty,
    });
  }

  componentDidMount() {
    //window.scrollTo(0, 0);
    const device = this.props.devices.find((device) => device.selected);
    console.log("[DEVICE SETTINGS] device", device);

    if (device) {
      this.setState({ currentDevice: device });
    } else {
      history.push(buildURI(routingConstants.DASHBOARD));
      return;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.devices !== this.props.devices) {
      if (
        this.state.isShownFactoryResetPanel === false && //during factory reset and device removal is normal for the device to be disconnected
        this.state.isShownRemoveDevicePanel === false
      ) {
        // If device is no more present, redirect to homepage
        const device = this.props.devices.find((device) => device.selected);
        this.setState({ currentDevice: device });
      }
    }

    if (prevState.currentDevice !== this.state.currentDevice) {
      if (
        isNil(this.state.currentDevice) ||
        !isDeviceConnected(this.state.currentDevice)
      ) {
        history.push(buildURI(routingConstants.DASHBOARD));
        return;
      }
    }
  }

  componentWillUnmount() {}

  _doFactoryReset() {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.FACTORY_RESET});
    this.setState({ isShownFactoryResetPanel: true });
  }

  _onEndFactoryReset(processEnded) {
    this.setState({ isShownFactoryResetPanel: false }, () => {
      if (processEnded) {
        //rimuovere device ovunque
        history.push(buildURI(routingConstants.DASHBOARD));
      }
    });
  }

  _goBack() {
    const { location } = this.props;

    this.props.history.push(
      buildURI(routingConstants.DEVICE_SETTINGS),
      {type: 'slide-left', backTo: location?.state?.isFrom}
    )
  }

  render() {
    const {
      currentDevice,
      isShownFactoryResetPanel,
      isShownRemoveDevicePanel,
    } = this.state;

    const deviceId = currentDevice?.deviceInfo?.deviceId;
    console.log("currentDevice", currentDevice);

    return (
      <React.Fragment>
        <div className="advanced-settings">
          <Header
            leftButton={{
              icon: <span className="bat-icon-back" />,
              onClick: () => this.goBack()
            }}
          >
            {this.dictionary.DEVICE_SETT_ADVANCED_SETTINGS_HEADER}
          </Header>
          <div className="advanced-settings-wrapper">
            <div className="page-label">
              {this.dictionary.DEVICE_SETT_DEVICE_INFORMATION}
            </div>
            <ol className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-start">
                <div>
                  {this.dictionary.DEVICE_SETT_MODEL_NAME}
                </div>
                <span>
                  {currentDevice?.deviceType}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-start">
                <div>
                  {this.dictionary.DEVICE_SETT_ID}
                </div>
                <span style={{'wordBreak': 'break-all', 'maxWidth': '80%'}}>
                  {deviceId}
                </span>
              </li>
              {/*
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="me-auto">
                                    {this.dictionary.DEVICE_SETT_MAC}
                                </div>
                                <span>ePod2+123456</span>
                            </li>
                             */}
            </ol>
            <div className="page-label">
              {this.dictionary.DEVICE_SETT_FACTORY_RESET_TITLE}
            </div>
            <div className="advanced-settings-warning">
              <span>{this.dictionary.DEVICE_SETT_FACTORY_RESET_BODY}</span>
              <div className="d-grid">
                <button
                  className="btn btn-dark text-uppercase"
                  disabled={
                    currentDevice === null ||
                    currentDevice === undefined ||
                    (currentDevice && !isSynchronized(currentDevice))
                  }
                  onClick={this.doFactoryReset}
                >
                  {this.dictionary.DEVICE_SETT_BTN_RESET_DATA_0_ctaLabel}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="page-linear-gradient-bg"></div>
        <FactoryReset
          devices={this.props.devices}
          device={currentDevice}
          isOpen={isShownFactoryResetPanel}
          handleProcessEnd={this.onEndFactoryReset}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    devices: state.deviceReducer.devices,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedSettings);
export { connectedComponent as AdvancedSettings };
