import { getAEMVuseWorldUrl, getUrl } from '../_actions/appConfig.actions';
import { shopifyUrl } from '../_constants';
import shopifyUtils from '../_helpers/api/shopifyUtils';
import { convertObjectToQueryString } from '../_helpers/utils';
import { axiosRequest } from '../_helpers/api/axiosRequest';

export const blogServices = {
    getBlogArticles,
    getAemVuseWorld
};

async function getBlogArticles(options = {}) {
    const url = getUrl(shopifyUrl.SHOPIFY_BLOG_ARTICLES) + convertObjectToQueryString(options);
    return axiosRequest({
        url,
        method: 'GET',
        headers: shopifyUtils.createBlogArticlesHeaders()
    });
}

async function getAemVuseWorld() {
    let requestOptions = {
        url: `${getAEMVuseWorldUrl()}?timestamp=${new Date().getTime()}`,
        method: 'GET',
        headers: {
            //'Cache-Control': 'no-cache',
            //'Pragma': 'no-cache',
            //'Expires': '0'
        }
    };

    return axiosRequest(requestOptions);
}