const configureGTM = () => {

    const headScript = () => {
        const script = document.createElement('script')
        script.id = 'gtm-head-script';
        script.innerHTML = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}');
        `
        return script;
    }

    const bodyScript = () => {
        const script = document.createElement('noscript')
        script.id = 'gtm-body-script';
        script.innerHTML = `
            <iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `
        return script;
    }

    document.head.insertBefore(headScript(), document.head.childNodes[0]);
    document.body.insertBefore(bodyScript(), document.body.childNodes[0]);

    window.dataLayer = window.dataLayer || [];

    // window[`ga-disable-${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}`] = false;
}

export const removeGTM = () => {

    // window[`ga-disable-${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}`] = true;

    const headScripts = document.head.querySelectorAll(':scope > script[id^="gtm-head-script"]');
    console.log("headScripts", headScripts);
    for (const p of headScripts) {
        console.log(p);
        document.head.removeChild(p);
    }

    const bodyScripts = document.body.querySelectorAll(':scope > noscript[id^="gtm-body-script"]');
    console.log("bodyScripts", bodyScripts);
    for (const p of bodyScripts) {
        console.log(p);
        document.body.removeChild(p);
    }

    window.dataLayer = undefined;

    document.cookie
        .split(";")
        .forEach((c) => {
            if(c.trim().startsWith("_ga")){
                document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            }
        });

}

export default configureGTM;