import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { commonsActions, userActions } from '../../_actions';
import { buildURI } from '../../_helpers/navigation';
import useCallbackState from '../../_hooks/callbackState.hook';
import {
    routingConstants,
    imagesConstants,
    aemPages,
    AnalyticsEvents,
    crmUserSettings,
    servicesConstants,
    AnalyticsTargetEventsNames
} from '../../_constants';
import {
    setAccessToken,
    setDoneVault,
    setUserData,
    setAcceptedTermsAndConditions,
    setRegistration,
} from '../../_actions/appData.actions';
import { 
    getTenantId,
    setAppConfig,
    getLocale,
    setLocale,
    getSupportedServices
} from '../../_actions/appConfig.actions'; 
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { Header, LoginForm, OnboardingHeader } from "../../_components";
import { AEMHelper } from '../../_helpers/aem/aemhelper';
import { setUserEverLogged } from '../../_actions/appData.actions';
import { store } from '../../_store';
import { Tenants } from '../../_helpers/tenants';

export const Login = (props) => {

    const [loginResult, setLoginResult] = useState(null);
    const [loading, setLoading] = useCallbackState(false);

    const aem = new AEMHelper();
    const page = aem.getPage(aemPages.LOGIN);

    const loadingReducer = useSelector(state => state.loginReducer.loading);
    const dispatch = useDispatch();

    let LOGIN_HEADER =page?.getComponent(aemPages.LOGIN, 'LOGIN_HEADER');

    const isLoading = loading || loadingReducer;

    //componentDidMount
    useEffect(() => {
        document.body.classList.add("login");

        const pictures = [
            imagesConstants.SPLASH_Loop,
            imagesConstants.PIN_ScaleDown,
            imagesConstants.PIN_LOOP,
            imagesConstants.PIN_ScaleUp,
            imagesConstants.SECURED_Final
        ];

        pictures.forEach((picture) => {
            const img = new Image();
            img.src = picture;
        });

        return () => {  //componentWillUnmount
            document.body.classList.remove("login");
        }

    }, []);

    //componentDidUpdate

    useEffect(() => {
        
        if (loadingReducer) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

    }, [loadingReducer]); 

    // success-error handlers

    const getUserInfoSuccess = (payload) => {
        console.debug('NEW_LOGIN', payload);

        const userData = payload.user

        const newUserData = {
            ...userData
        }

        if (userData?.externalCustomerId) {
            newUserData["id"] = userData.externalCustomerId
        }

        setUserData(newUserData);

        const userEverLogged = userData?.userExtra.find(prop => prop.key === "MYVUSE_EVER_LOGGED")?.value;
        const supportedServices = getSupportedServices();
        
        let nextStepUrl = "";

        if (Tenants.isGlobal() && supportedServices.includes(servicesConstants.ONBOARDING_AV_YOTI) && !userData?.isUserAgeVerified) {
            nextStepUrl = routingConstants.HARD_AGE_VERIFICATION;
        } else if(userEverLogged === true || userEverLogged === "true"){
            const userEverLoggedTimestamp = userData?.userExtra.find(prop => prop.key === "MYVUSE_EVER_LOGGED")?.createdAt;
            setUserEverLogged(userEverLoggedTimestamp);
            setDoneVault(true);
            setAcceptedTermsAndConditions(true);
            nextStepUrl = routingConstants.VAULT_RESTORE;
        } else {
            setUserEverLogged(null);
            setDoneVault(false);
            nextStepUrl = routingConstants.VAULT;
        }

        logAnalyticsEvent(AnalyticsEvents.LOG_IN_SUCCESS, {}, {
            userID: newUserData.id
        });

        //reload tenant configuration
        dispatch(commonsActions.getCountryConfig(getTenantId(), (countryConfig) => {
            if(countryConfig?.data){
                const currentLocale = getLocale();
                setAppConfig(countryConfig.data);
                setLocale(currentLocale);
            }
        }));

        //dispatch flag "myvuseConnected" (CA)  if we have to set to true
        let userMyvuseConnected = userData?.myvuseConnected;
        if(null!=userMyvuseConnected && userMyvuseConnected === false){
            dispatch(userActions.updateUserInfo());
        }

        //dispatch crm setting
        store.dispatch(userActions.setUserSettings(
            crmUserSettings.COOKIE_NOTICE,
            (store.getState().onboardingReducer.acceptedCookies === true).toString()
        ))

        store.dispatch(userActions.setUserSettings(
            crmUserSettings.APP_OS,
            "iOS"
        ))

        //goto dashboard
        props.history.push({
            pathname: buildURI(nextStepUrl),
            state: {
                isAfterRegistration: false
            },
        });
    }

    const getUserInfoError = () => {
        logAnalyticsEvent(AnalyticsEvents.LOG_IN_FAILURE);
        setLoginResult(false);
    }

    const loginSuccess = (payload) => {
        const { token, userId } = payload;

        setAccessToken(token);
        getUserData(userId);

        setRegistration({});
    }

    const loginError = () => {
        logAnalyticsEvent(AnalyticsEvents.LOG_IN_FAILURE);
        setLoginResult(false);
        setLoading(false);
    }

    // main functions

    const getUserData = (userId) => {
        dispatch(userActions.getUserInfo(
            userId, 
            getUserInfoSuccess, 
            getUserInfoError
        ));
    }

    const onLogin = (formData) => {
    
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.LOGIN});

        setLoading(true, loading => {

            dispatch(userActions.login(
                formData,
                loginSuccess,
                loginError
            ));

        });
    
    }

    return (
        <React.Fragment>
            {isLoading && <div className="loader-overlay"></div>}
            <div className="page w-100">
                <Header
                    leftButton={{
                        icon: <span className="bat-icon-back" />,
                        onClick: () => props.history.push(buildURI(routingConstants.BENEFITS))
                    }} 
                    subHeader={LOGIN_HEADER?.getRaw('heading', null) ?? ''} 
                />
                <LoginForm onSubmit={(formData) => onLogin(formData)}
                    loginResult={loginResult}
                    page={page}
                    isLoading={isLoading}
                />
                <div className="page-linear-gradient-bg"></div>
            </div>
        </React.Fragment>

    );

}