import React from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import { AnalyticsEvents, routingConstants } from '../../_constants';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { debug } from '../../_helpers/debug';
import { buildURI, getURLSearchObject } from '../../_helpers/navigation';
import { Notifications } from '../../_helpers/notifications';
import { UserHelper } from '../../_helpers/user/user.helper';
import { setDoneOnboardingBenefits } from '../../_actions';

class PushNotificationLandingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payload: this.getPayloadFromUrl(),
      pathname: this.getRedirectPathname(),
      redirectEnabled: false,
    }
  }
  
  componentDidMount() {
    const {
      payload
    } = this.state;

    if (
      _.isString(payload?.data?.cta) &&
      _.isString(payload?.data?.webview_title) &&
      _.isString(payload?.data?.webview_url)
    ) {
      Notifications.setRemoteNotificationActionFromPushNotification({
        action: payload?.data?.cta,
        webview_title: payload?.data?.webview_title,
        webview_url: payload?.data?.webview_url,
        webview_restricted: !!payload?.data?.webview_restricted
      })
    }
    
    if (
      _.isString(payload.fcm_options?.analytics_label)
    ) {
      logAnalyticsEvent(AnalyticsEvents.NOTIFICATION_OPEN_WEB, {
        label: payload?.fcm_options?.analytics_label
      });
    }
    
    this.setState({
      redirectEnabled: true
    })
  }

  getPayloadFromUrl() {
    const { payload: encodedPayload } = getURLSearchObject(false);
    let payload = {};
    try {
      payload = JSON.parse(decodeURIComponent(encodedPayload));  
      debug("[PushNotificationLandingPage] payload ", payload);
    } catch (error) {
      debug("ERROR: [PushNotificationLandingPage] Invalid payload ", payload);
    }

    return payload;
  }

  getRedirectPathname() {
    setDoneOnboardingBenefits(true);
    const pathname = UserHelper.isUserLoggedIn()
      ? buildURI(routingConstants.DASHBOARD)
      : buildURI(routingConstants.LOGIN)

    debug("[PushNotificationLandingPage] redirect to pathname ", pathname);
    return pathname;
  }

  render() {
    const {
      redirectEnabled,
      pathname
    } = this.state;

    return redirectEnabled
      ? <Redirect to={{ pathname }} />
      : <div></div>;
  }
}

export { PushNotificationLandingPage };
