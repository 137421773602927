import { imagesConstants } from "./images.constants";

export const deviceImages = {
  ePod2: {
    DEVICE_SELECTION: {
      DEVICE: imagesConstants.EPOD2_DEVICE_SELECTION_DEVICE,
      DEVICE_ORIGAMI: imagesConstants.EPOD2_DEVICE_SELECTION_DEVICE_ORIGAMI,
    },
    COLOR_SELECTION: {
      SKIN_COLOR_BLACK_ACTIVE:
        imagesConstants.EPOD2_COLOR_SELECTION_BLACK_ACTIVE,
      SKIN_COLOR_BLACK_INACTIVE:
        imagesConstants.EPOD2_COLOR_SELECTION_BLACK_INACTIVE,
      SKIN_COLOR_BLUE_ACTIVE: imagesConstants.EPOD2_COLOR_SELECTION_BLUE_ACTIVE,
      SKIN_COLOR_BLUE_INACTIVE:
        imagesConstants.EPOD2_COLOR_SELECTION_BLUE_INACTIVE,
      SKIN_COLOR_CYAN_ACTIVE: imagesConstants.EPOD2_COLOR_SELECTION_CYAN_ACTIVE,
      SKIN_COLOR_CYAN_INACTIVE:
        imagesConstants.EPOD2_COLOR_SELECTION_CYAN_INACTIVE,
      SKIN_COLOR_GOLD_ACTIVE: imagesConstants.EPOD2_COLOR_SELECTION_GOLD_ACTIVE,
      SKIN_COLOR_GOLD_INACTIVE:
        imagesConstants.EPOD2_COLOR_SELECTION_GOLD_INACTIVE,
      SKIN_COLOR_PINK_ACTIVE: imagesConstants.EPOD2_COLOR_SELECTION_PINK_ACTIVE,
      SKIN_COLOR_PINK_INACTIVE:
        imagesConstants.EPOD2_COLOR_SELECTION_PINK_INACTIVE,
      SKIN_COLOR_RED_ACTIVE: imagesConstants.EPOD2_COLOR_SELECTION_RED_ACTIVE,
      SKIN_COLOR_RED_INACTIVE:
        imagesConstants.EPOD2_COLOR_SELECTION_RED_INACTIVE,
      SKIN_COLOR_REFLEKTA_ACTIVE:
        imagesConstants.EPOD2_COLOR_SELECTION_REFLEKTA_ACTIVE,
      SKIN_COLOR_REFLEKTA_INACTIVE:
        imagesConstants.EPOD2_COLOR_SELECTION_REFLEKTA_INACTIVE,
    },
    FIND_MY_VAPE: {
      MARKER_SKIN_COLOR_BLACK_ACTIVE_DARK:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_BLACK_DARK_ACTIVE,
      MARKER_SKIN_COLOR_BLACK_INACTIVE_DARK:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_BLACK_DARK_INACTIVE,
      MARKER_SKIN_COLOR_BLACK_ACTIVE_GLOBAL:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_BLACK_GLOBAL_ACTIVE,
      MARKER_SKIN_COLOR_BLACK_INACTIVE_GLOBAL:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_BLACK_GLOBAL_INACTIVE,
      MARKER_SKIN_COLOR_BLUE_ACTIVE_DARK:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_BLUE_DARK_ACTIVE,
      MARKER_SKIN_COLOR_BLUE_INACTIVE_DARK:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_BLUE_DARK_INACTIVE,
      MARKER_SKIN_COLOR_BLUE_ACTIVE_GLOBAL:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_BLUE_GLOBAL_ACTIVE,
      MARKER_SKIN_COLOR_BLUE_INACTIVE_GLOBAL:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_BLUE_GLOBAL_INACTIVE,
      MARKER_SKIN_COLOR_CYAN_ACTIVE_DARK:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_CYAN_DARK_ACTIVE,
      MARKER_SKIN_COLOR_CYAN_INACTIVE_DARK:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_CYAN_DARK_INACTIVE,
      MARKER_SKIN_COLOR_CYAN_ACTIVE_GLOBAL:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_CYAN_GLOBAL_ACTIVE,
      MARKER_SKIN_COLOR_CYAN_INACTIVE_GLOBAL:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_CYAN_GLOBAL_INACTIVE,
      MARKER_SKIN_COLOR_GOLD_ACTIVE_DARK:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_GOLD_DARK_ACTIVE,
      MARKER_SKIN_COLOR_GOLD_INACTIVE_DARK:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_GOLD_DARK_INACTIVE,
      MARKER_SKIN_COLOR_GOLD_ACTIVE_GLOBAL:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_GOLD_GLOBAL_ACTIVE,
      MARKER_SKIN_COLOR_GOLD_INACTIVE_GLOBAL:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_GOLD_GLOBAL_INACTIVE,
      MARKER_SKIN_COLOR_PINK_ACTIVE_DARK:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_PINK_DARK_ACTIVE,
      MARKER_SKIN_COLOR_PINK_INACTIVE_DARK:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_PINK_DARK_INACTIVE,
      MARKER_SKIN_COLOR_PINK_ACTIVE_GLOBAL:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_PINK_GLOBAL_ACTIVE,
      MARKER_SKIN_COLOR_PINK_INACTIVE_GLOBAL:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_PINK_GLOBAL_INACTIVE,
      MARKER_SKIN_COLOR_RED_ACTIVE_DARK:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_RED_DARK_ACTIVE,
      MARKER_SKIN_COLOR_RED_INACTIVE_DARK:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_RED_DARK_INACTIVE,
      MARKER_SKIN_COLOR_RED_ACTIVE_GLOBAL:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_RED_GLOBAL_ACTIVE,
      MARKER_SKIN_COLOR_RED_INACTIVE_GLOBAL:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_RED_GLOBAL_INACTIVE,
      MARKER_SKIN_COLOR_REFLEKTA_ACTIVE_DARK:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_REFLEKTA_DARK_ACTIVE,
      MARKER_SKIN_COLOR_REFLEKTA_INACTIVE_DARK:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_REFLEKTA_DARK_INACTIVE,
      MARKER_SKIN_COLOR_REFLEKTA_ACTIVE_GLOBAL:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_REFLEKTA_GLOBAL_ACTIVE,
      MARKER_SKIN_COLOR_REFLEKTA_INACTIVE_GLOBAL:
        imagesConstants.EPOD2_FMV_MARKER_SKIN_COLOR_REFLEKTA_GLOBAL_INACTIVE,
      PANEL_SKIN_COLOR_BLACK: imagesConstants.EPOD2_FMV_PANEL_SKIN_COLOR_BLACK,
      PANEL_SKIN_COLOR_BLUE: imagesConstants.EPOD2_FMV_PANEL_SKIN_COLOR_BLUE,
      PANEL_SKIN_COLOR_CYAN: imagesConstants.EPOD2_FMV_PANEL_SKIN_COLOR_CYAN,
      PANEL_SKIN_COLOR_GOLD: imagesConstants.EPOD2_FMV_PANEL_SKIN_COLOR_GOLD,
      PANEL_SKIN_COLOR_PINK: imagesConstants.EPOD2_FMV_PANEL_SKIN_COLOR_PINK,
      PANEL_SKIN_COLOR_RED: imagesConstants.EPOD2_FMV_PANEL_SKIN_COLOR_RED,
      PANEL_SKIN_COLOR_REFLEKTA:
        imagesConstants.EPOD2_FMV_PANEL_SKIN_COLOR_REFLEKTA,
    },
    HOMESCREEN: {
      SKIN_COLOR_BLACK: imagesConstants.EPOD2_HOMESCREEN_SKIN_COLOR_BLACK,
      SKIN_COLOR_BLUE: imagesConstants.EPOD2_HOMESCREEN_SKIN_COLOR_BLUE,
      SKIN_COLOR_CYAN: imagesConstants.EPOD2_HOMESCREEN_SKIN_COLOR_CYAN,
      SKIN_COLOR_GOLD: imagesConstants.EPOD2_HOMESCREEN_SKIN_COLOR_GOLD,
      SKIN_COLOR_PINK: imagesConstants.EPOD2_HOMESCREEN_SKIN_COLOR_PINK,
      SKIN_COLOR_RED: imagesConstants.EPOD2_HOMESCREEN_SKIN_COLOR_RED,
      SKIN_COLOR_REFLEKTA: imagesConstants.EPOD2_HOMESCREEN_SKIN_COLOR_REFLEKTA,
    },
    PAIRING_COMPLETED: {
      SKIN_COLOR_BLACK_ORIGAMI:
        imagesConstants.EPOD2_PAIRING_COMPLETED_SKIN_COLOR_BLACK_ORIGAMI,
      SKIN_COLOR_BLACK:
        imagesConstants.EPOD2_PAIRING_COMPLETED_SKIN_COLOR_BLACK,
      SKIN_COLOR_BLUE_ORIGAMI:
        imagesConstants.EPOD2_PAIRING_COMPLETED_SKIN_COLOR_BLUE_ORIGAMI,
      SKIN_COLOR_BLUE: imagesConstants.EPOD2_PAIRING_COMPLETED_SKIN_COLOR_BLUE,
      SKIN_COLOR_CYAN_ORIGAMI:
        imagesConstants.EPOD2_PAIRING_COMPLETED_SKIN_COLOR_CYAN_ORIGAMI,
      SKIN_COLOR_CYAN: imagesConstants.EPOD2_PAIRING_COMPLETED_SKIN_COLOR_CYAN,
      SKIN_COLOR_GOLD_ORIGAMI:
        imagesConstants.EPOD2_PAIRING_COMPLETED_SKIN_COLOR_GOLD_ORIGAMI,
      SKIN_COLOR_GOLD: imagesConstants.EPOD2_PAIRING_COMPLETED_SKIN_COLOR_GOLD,
      SKIN_COLOR_PINK_ORIGAMI:
        imagesConstants.EPOD2_PAIRING_COMPLETED_SKIN_COLOR_PINK_ORIGAMI,
      SKIN_COLOR_PINK: imagesConstants.EPOD2_PAIRING_COMPLETED_SKIN_COLOR_PINK,
      SKIN_COLOR_RED_ORIGAMI:
        imagesConstants.EPOD2_PAIRING_COMPLETED_SKIN_COLOR_RED_ORIGAMI,
      SKIN_COLOR_RED: imagesConstants.EPOD2_PAIRING_COMPLETED_SKIN_COLOR_RED,
      SKIN_COLOR_REFLEKTA_ORIGAMI:
        imagesConstants.EPOD2_PAIRING_COMPLETED_SKIN_COLOR_REFLEKTA_ORIGAMI,
      SKIN_COLOR_REFLEKTA:
        imagesConstants.EPOD2_PAIRING_COMPLETED_SKIN_COLOR_REFLEKTA,
    },
    MANAGE_DEVICES: {
      SKIN_COLOR_BLACK: imagesConstants.EPOD2_MANAGE_DEVICES_SKIN_COLOR_BLACK,
      SKIN_COLOR_BLUE: imagesConstants.EPOD2_MANAGE_DEVICES_SKIN_COLOR_BLUE,
      SKIN_COLOR_CYAN: imagesConstants.EPOD2_MANAGE_DEVICES_SKIN_COLOR_CYAN,
      SKIN_COLOR_GOLD: imagesConstants.EPOD2_MANAGE_DEVICES_SKIN_COLOR_GOLD,
      SKIN_COLOR_PINK: imagesConstants.EPOD2_MANAGE_DEVICES_SKIN_COLOR_PINK,
      SKIN_COLOR_RED: imagesConstants.EPOD2_MANAGE_DEVICES_SKIN_COLOR_RED,
      SKIN_COLOR_REFLEKTA:
        imagesConstants.EPOD2_MANAGE_DEVICES_SKIN_COLOR_REFLEKTA,
    },
    DEVICE_SWITCHER: {
      SKIN_COLOR_BLACK_DARK:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_BLACK_DARK,
      SKIN_COLOR_BLACK_GLOBAL:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_BLACK_GLOBAL,
      SKIN_COLOR_BLACK_LIGHT:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_BLACK_LIGHT,
      SKIN_COLOR_BLUE_DARK:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_BLUE_DARK,
      SKIN_COLOR_BLUE_GLOBAL:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_BLUE_GLOBAL,
      SKIN_COLOR_BLUE_LIGHT:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_BLUE_LIGHT,
      SKIN_COLOR_CYAN_DARK:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_CYAN_DARK,
      SKIN_COLOR_CYAN_GLOBAL:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_CYAN_GLOBAL,
      SKIN_COLOR_CYAN_LIGHT:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_CYAN_LIGHT,
      SKIN_COLOR_GOLD_DARK:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_GOLD_DARK,
      SKIN_COLOR_GOLD_GLOBAL:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_GOLD_GLOBAL,
      SKIN_COLOR_GOLD_LIGHT:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_GOLD_LIGHT,
      SKIN_COLOR_PINK_DARK:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_PINK_DARK,
      SKIN_COLOR_PINK_GLOBAL:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_PINK_GLOBAL,
      SKIN_COLOR_PINK_LIGHT:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_PINK_LIGHT,
      SKIN_COLOR_RED_DARK:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_RED_DARK,
      SKIN_COLOR_RED_GLOBAL:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_RED_GLOBAL,
      SKIN_COLOR_RED_LIGHT:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_RED_LIGHT,
      SKIN_COLOR_REFLEKTA_DARK:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_REFLEKTA_DARK,
      SKIN_COLOR_REFLEKTA_GLOBAL:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_REFLEKTA_GLOBAL,
      SKIN_COLOR_REFLEKTA_LIGHT:
        imagesConstants.EPOD2_DEVICE_SWITCHER_SKIN_COLOR_REFLEKTA_LIGHT,
    },
    DEVICE_ICON: {
      SKIN_COLOR_BLACK: imagesConstants.EPOD2_DEVICE_ICON_SKIN_COLOR_BLACK,
      SKIN_COLOR_BLACK_ORIGAMI:
        imagesConstants.EPOD2_DEVICE_ICON_SKIN_COLOR_BLACK_ORIGAMI,
      SKIN_COLOR_BLUE: imagesConstants.EPOD2_DEVICE_ICON_SKIN_COLOR_BLUE,
      SKIN_COLOR_BLUE_ORIGAMI:
        imagesConstants.EPOD2_DEVICE_ICON_SKIN_COLOR_BLUE_ORIGAMI,
      SKIN_COLOR_CYAN: imagesConstants.EPOD2_DEVICE_ICON_SKIN_COLOR_CYAN,
      SKIN_COLOR_CYAN_ORIGAMI:
        imagesConstants.EPOD2_DEVICE_ICON_SKIN_COLOR_CYAN_ORIGAMI,
      SKIN_COLOR_GOLD: imagesConstants.EPOD2_DEVICE_ICON_SKIN_COLOR_GOLD,
      SKIN_COLOR_GOLD_ORIGAMI:
        imagesConstants.EPOD2_DEVICE_ICON_SKIN_COLOR_GOLD_ORIGAMI,
      SKIN_COLOR_PINK: imagesConstants.EPOD2_DEVICE_ICON_SKIN_COLOR_PINK,
      SKIN_COLOR_PINK_ORIGAMI:
        imagesConstants.EPOD2_DEVICE_ICON_SKIN_COLOR_PINK_ORIGAMI,
      SKIN_COLOR_RED: imagesConstants.EPOD2_DEVICE_ICON_SKIN_COLOR_RED,
      SKIN_COLOR_RED_ORIGAMI:
        imagesConstants.EPOD2_DEVICE_ICON_SKIN_COLOR_RED_ORIGAMI,
      SKIN_COLOR_REFLEKTA:
        imagesConstants.EPOD2_DEVICE_ICON_SKIN_COLOR_REFLEKTA,
      SKIN_COLOR_REFLEKTA_ORIGAMI:
        imagesConstants.EPOD2_DEVICE_ICON_SKIN_COLOR_REFLEKTA_ORIGAMI,
    },
    ICON: {
      SKIN_COLOR_BLACK: imagesConstants.EPOD2_DEVICE_ICON_RE_SKIN_COLOR_BLACK,
      SKIN_COLOR_BLUE: imagesConstants.EPOD2_DEVICE_ICON_RE_SKIN_COLOR_BLUE,
      SKIN_COLOR_CYAN: imagesConstants.EPOD2_DEVICE_ICON_RE_SKIN_COLOR_CYAN,
      SKIN_COLOR_GOLD: imagesConstants.EPOD2_DEVICE_ICON_RE_SKIN_COLOR_GOLD,
      SKIN_COLOR_PINK: imagesConstants.EPOD2_DEVICE_ICON_RE_SKIN_COLOR_PINK,
      SKIN_COLOR_RED: imagesConstants.EPOD2_DEVICE_ICON_RE_SKIN_COLOR_RED,
      SKIN_COLOR_REFLEKTA:
        imagesConstants.EPOD2_DEVICE_ICON_RE_SKIN_COLOR_REFLEKTA,
    },
  },
  ePod3: {
    DEVICE_SELECTION: {
      DEVICE: imagesConstants.EPOD3_DEVICE_SELECTION_DEVICE,
      DEVICE_ORIGAMI: imagesConstants.EPOD3_DEVICE_SELECTION_DEVICE_ORIGAMI,
    },
    COLOR_SELECTION: {
      SKIN_COLOR_BLACK_ACTIVE:
        imagesConstants.EPOD3_COLOR_SELECTION_BLACK_ACTIVE,
      SKIN_COLOR_BLACK_INACTIVE:
        imagesConstants.EPOD3_COLOR_SELECTION_BLACK_INACTIVE,
      SKIN_COLOR_BLUE_ACTIVE: imagesConstants.EPOD3_COLOR_SELECTION_BLUE_ACTIVE,
      SKIN_COLOR_BLUE_INACTIVE:
        imagesConstants.EPOD3_COLOR_SELECTION_BLUE_INACTIVE,
      SKIN_COLOR_CYAN_ACTIVE: imagesConstants.EPOD3_COLOR_SELECTION_CYAN_ACTIVE,
      SKIN_COLOR_CYAN_INACTIVE:
        imagesConstants.EPOD3_COLOR_SELECTION_CYAN_INACTIVE,
      SKIN_COLOR_GOLD_ACTIVE: imagesConstants.EPOD3_COLOR_SELECTION_GOLD_ACTIVE,
      SKIN_COLOR_GOLD_INACTIVE:
        imagesConstants.EPOD3_COLOR_SELECTION_GOLD_INACTIVE,
      SKIN_COLOR_PINK_ACTIVE: imagesConstants.EPOD3_COLOR_SELECTION_PINK_ACTIVE,
      SKIN_COLOR_PINK_INACTIVE:
        imagesConstants.EPOD3_COLOR_SELECTION_PINK_INACTIVE,
      SKIN_COLOR_RED_ACTIVE: imagesConstants.EPOD3_COLOR_SELECTION_RED_ACTIVE,
      SKIN_COLOR_RED_INACTIVE:
        imagesConstants.EPOD3_COLOR_SELECTION_RED_INACTIVE,
    },
    FIND_MY_VAPE: {
      MARKER_SKIN_COLOR_BLACK_ACTIVE_DARK:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_BLACK_DARK_ACTIVE,
      MARKER_SKIN_COLOR_BLACK_INACTIVE_DARK:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_BLACK_DARK_INACTIVE,
      MARKER_SKIN_COLOR_BLACK_ACTIVE_GLOBAL:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_BLACK_GLOBAL_ACTIVE,
      MARKER_SKIN_COLOR_BLACK_INACTIVE_GLOBAL:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_BLACK_GLOBAL_INACTIVE,
      MARKER_SKIN_COLOR_BLUE_ACTIVE_DARK:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_BLUE_DARK_ACTIVE,
      MARKER_SKIN_COLOR_BLUE_INACTIVE_DARK:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_BLUE_DARK_INACTIVE,
      MARKER_SKIN_COLOR_BLUE_ACTIVE_GLOBAL:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_BLUE_GLOBAL_ACTIVE,
      MARKER_SKIN_COLOR_BLUE_INACTIVE_GLOBAL:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_BLUE_GLOBAL_INACTIVE,
      MARKER_SKIN_COLOR_CYAN_ACTIVE_DARK:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_CYAN_DARK_ACTIVE,
      MARKER_SKIN_COLOR_CYAN_INACTIVE_DARK:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_CYAN_DARK_INACTIVE,
      MARKER_SKIN_COLOR_CYAN_ACTIVE_GLOBAL:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_CYAN_GLOBAL_ACTIVE,
      MARKER_SKIN_COLOR_CYAN_INACTIVE_GLOBAL:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_CYAN_GLOBAL_INACTIVE,
      MARKER_SKIN_COLOR_GOLD_ACTIVE_DARK:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_GOLD_DARK_ACTIVE,
      MARKER_SKIN_COLOR_GOLD_INACTIVE_DARK:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_GOLD_DARK_INACTIVE,
      MARKER_SKIN_COLOR_GOLD_ACTIVE_GLOBAL:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_GOLD_GLOBAL_ACTIVE,
      MARKER_SKIN_COLOR_GOLD_INACTIVE_GLOBAL:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_GOLD_GLOBAL_INACTIVE,
      MARKER_SKIN_COLOR_PINK_ACTIVE_DARK:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_PINK_DARK_ACTIVE,
      MARKER_SKIN_COLOR_PINK_INACTIVE_DARK:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_PINK_DARK_INACTIVE,
      MARKER_SKIN_COLOR_PINK_ACTIVE_GLOBAL:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_PINK_GLOBAL_ACTIVE,
      MARKER_SKIN_COLOR_PINK_INACTIVE_GLOBAL:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_PINK_GLOBAL_INACTIVE,
      MARKER_SKIN_COLOR_RED_ACTIVE_DARK:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_RED_DARK_ACTIVE,
      MARKER_SKIN_COLOR_RED_INACTIVE_DARK:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_RED_DARK_INACTIVE,
      MARKER_SKIN_COLOR_RED_ACTIVE_GLOBAL:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_RED_GLOBAL_ACTIVE,
      MARKER_SKIN_COLOR_RED_INACTIVE_GLOBAL:
        imagesConstants.EPOD3_FMV_MARKER_SKIN_COLOR_RED_GLOBAL_INACTIVE,
      PANEL_SKIN_COLOR_BLACK: imagesConstants.EPOD3_FMV_PANEL_SKIN_COLOR_BLACK,
      PANEL_SKIN_COLOR_BLUE: imagesConstants.EPOD3_FMV_PANEL_SKIN_COLOR_BLUE,
      PANEL_SKIN_COLOR_CYAN: imagesConstants.EPOD3_FMV_PANEL_SKIN_COLOR_CYAN,
      PANEL_SKIN_COLOR_GOLD: imagesConstants.EPOD3_FMV_PANEL_SKIN_COLOR_GOLD,
      PANEL_SKIN_COLOR_PINK: imagesConstants.EPOD3_FMV_PANEL_SKIN_COLOR_PINK,
      PANEL_SKIN_COLOR_RED: imagesConstants.EPOD3_FMV_PANEL_SKIN_COLOR_RED,
    },
    HOMESCREEN: {
      SKIN_COLOR_BLACK: imagesConstants.EPOD3_HOMESCREEN_SKIN_COLOR_BLACK,
      SKIN_COLOR_BLUE: imagesConstants.EPOD3_HOMESCREEN_SKIN_COLOR_BLUE,
      SKIN_COLOR_CYAN: imagesConstants.EPOD3_HOMESCREEN_SKIN_COLOR_CYAN,
      SKIN_COLOR_GOLD: imagesConstants.EPOD3_HOMESCREEN_SKIN_COLOR_GOLD,
      SKIN_COLOR_PINK: imagesConstants.EPOD3_HOMESCREEN_SKIN_COLOR_PINK,
      SKIN_COLOR_RED: imagesConstants.EPOD3_HOMESCREEN_SKIN_COLOR_RED,
    },
    PAIRING_COMPLETED: {
      SKIN_COLOR_BLACK_ORIGAMI:
        imagesConstants.EPOD3_PAIRING_COMPLETED_SKIN_COLOR_BLACK_ORIGAMI,
      SKIN_COLOR_BLACK:
        imagesConstants.EPOD3_PAIRING_COMPLETED_SKIN_COLOR_BLACK,
      SKIN_COLOR_BLUE_ORIGAMI:
        imagesConstants.EPOD3_PAIRING_COMPLETED_SKIN_COLOR_BLUE_ORIGAMI,
      SKIN_COLOR_BLUE: imagesConstants.EPOD3_PAIRING_COMPLETED_SKIN_COLOR_BLUE,
      SKIN_COLOR_CYAN_ORIGAMI:
        imagesConstants.EPOD3_PAIRING_COMPLETED_SKIN_COLOR_CYAN_ORIGAMI,
      SKIN_COLOR_CYAN: imagesConstants.EPOD3_PAIRING_COMPLETED_SKIN_COLOR_CYAN,
      SKIN_COLOR_GOLD_ORIGAMI:
        imagesConstants.EPOD3_PAIRING_COMPLETED_SKIN_COLOR_GOLD_ORIGAMI,
      SKIN_COLOR_GOLD: imagesConstants.EPOD3_PAIRING_COMPLETED_SKIN_COLOR_GOLD,
      SKIN_COLOR_PINK_ORIGAMI:
        imagesConstants.EPOD3_PAIRING_COMPLETED_SKIN_COLOR_PINK_ORIGAMI,
      SKIN_COLOR_PINK: imagesConstants.EPOD3_PAIRING_COMPLETED_SKIN_COLOR_PINK,
      SKIN_COLOR_RED_ORIGAMI:
        imagesConstants.EPOD3_PAIRING_COMPLETED_SKIN_COLOR_RED_ORIGAMI,
      SKIN_COLOR_RED: imagesConstants.EPOD3_PAIRING_COMPLETED_SKIN_COLOR_RED,
    },
    MANAGE_DEVICES: {
      SKIN_COLOR_BLACK: imagesConstants.EPOD3_MANAGE_DEVICES_SKIN_COLOR_BLACK,
      SKIN_COLOR_BLUE: imagesConstants.EPOD3_MANAGE_DEVICES_SKIN_COLOR_BLUE,
      SKIN_COLOR_CYAN: imagesConstants.EPOD3_MANAGE_DEVICES_SKIN_COLOR_CYAN,
      SKIN_COLOR_GOLD: imagesConstants.EPOD3_MANAGE_DEVICES_SKIN_COLOR_GOLD,
      SKIN_COLOR_PINK: imagesConstants.EPOD3_MANAGE_DEVICES_SKIN_COLOR_PINK,
      SKIN_COLOR_RED: imagesConstants.EPOD3_MANAGE_DEVICES_SKIN_COLOR_RED,
      SKIN_COLOR_REFLEKTA:
        imagesConstants.EPOD3_MANAGE_DEVICES_SKIN_COLOR_REFLEKTA,
    },
    DEVICE_SWITCHER: {
      SKIN_COLOR_BLACK_DARK:
        imagesConstants.EPOD3_DEVICE_SWITCHER_SKIN_COLOR_BLACK_DARK,
      SKIN_COLOR_BLACK_GLOBAL:
        imagesConstants.EPOD3_DEVICE_SWITCHER_SKIN_COLOR_BLACK_GLOBAL,
      SKIN_COLOR_BLACK_LIGHT:
        imagesConstants.EPOD3_DEVICE_SWITCHER_SKIN_COLOR_BLACK_LIGHT,
      SKIN_COLOR_BLUE_DARK:
        imagesConstants.EPOD3_DEVICE_SWITCHER_SKIN_COLOR_BLUE_DARK,
      SKIN_COLOR_BLUE_GLOBAL:
        imagesConstants.EPOD3_DEVICE_SWITCHER_SKIN_COLOR_BLUE_GLOBAL,
      SKIN_COLOR_BLUE_LIGHT:
        imagesConstants.EPOD3_DEVICE_SWITCHER_SKIN_COLOR_BLUE_LIGHT,
      SKIN_COLOR_CYAN_DARK:
        imagesConstants.EPOD3_DEVICE_SWITCHER_SKIN_COLOR_CYAN_DARK,
      SKIN_COLOR_CYAN_GLOBAL:
        imagesConstants.EPOD3_DEVICE_SWITCHER_SKIN_COLOR_CYAN_GLOBAL,
      SKIN_COLOR_CYAN_LIGHT:
        imagesConstants.EPOD3_DEVICE_SWITCHER_SKIN_COLOR_CYAN_LIGHT,
      SKIN_COLOR_GOLD_DARK:
        imagesConstants.EPOD3_DEVICE_SWITCHER_SKIN_COLOR_GOLD_DARK,
      SKIN_COLOR_GOLD_GLOBAL:
        imagesConstants.EPOD3_DEVICE_SWITCHER_SKIN_COLOR_GOLD_GLOBAL,
      SKIN_COLOR_GOLD_LIGHT:
        imagesConstants.EPOD3_DEVICE_SWITCHER_SKIN_COLOR_GOLD_LIGHT,
      SKIN_COLOR_PINK_DARK:
        imagesConstants.EPOD3_DEVICE_SWITCHER_SKIN_COLOR_PINK_DARK,
      SKIN_COLOR_PINK_GLOBAL:
        imagesConstants.EPOD3_DEVICE_SWITCHER_SKIN_COLOR_PINK_GLOBAL,
      SKIN_COLOR_PINK_LIGHT:
        imagesConstants.EPOD3_DEVICE_SWITCHER_SKIN_COLOR_PINK_LIGHT,
      SKIN_COLOR_RED_DARK:
        imagesConstants.EPOD3_DEVICE_SWITCHER_SKIN_COLOR_RED_DARK,
      SKIN_COLOR_RED_GLOBAL:
        imagesConstants.EPOD3_DEVICE_SWITCHER_SKIN_COLOR_RED_GLOBAL,
      SKIN_COLOR_RED_LIGHT:
        imagesConstants.EPOD3_DEVICE_SWITCHER_SKIN_COLOR_RED_LIGHT,
    },
    DEVICE_ICON: {
      SKIN_COLOR_BLACK: imagesConstants.EPOD3_DEVICE_ICON_SKIN_COLOR_BLACK,
      SKIN_COLOR_BLACK_ORIGAMI:
        imagesConstants.EPOD3_DEVICE_ICON_SKIN_COLOR_BLACK_ORIGAMI,
      SKIN_COLOR_BLUE: imagesConstants.EPOD3_DEVICE_ICON_SKIN_COLOR_BLUE,
      SKIN_COLOR_BLUE_ORIGAMI:
        imagesConstants.EPOD3_DEVICE_ICON_SKIN_COLOR_BLUE_ORIGAMI,
      SKIN_COLOR_CYAN: imagesConstants.EPOD3_DEVICE_ICON_SKIN_COLOR_CYAN,
      SKIN_COLOR_CYAN_ORIGAMI:
        imagesConstants.EPOD3_DEVICE_ICON_SKIN_COLOR_CYAN_ORIGAMI,
      SKIN_COLOR_GOLD: imagesConstants.EPOD3_DEVICE_ICON_SKIN_COLOR_GOLD,
      SKIN_COLOR_GOLD_ORIGAMI:
        imagesConstants.EPOD3_DEVICE_ICON_SKIN_COLOR_GOLD_ORIGAMI,
      SKIN_COLOR_PINK: imagesConstants.EPOD3_DEVICE_ICON_SKIN_COLOR_PINK,
      SKIN_COLOR_PINK_ORIGAMI:
        imagesConstants.EPOD3_DEVICE_ICON_SKIN_COLOR_PINK_ORIGAMI,
      SKIN_COLOR_RED: imagesConstants.EPOD3_DEVICE_ICON_SKIN_COLOR_RED,
      SKIN_COLOR_RED_ORIGAMI:
        imagesConstants.EPOD3_DEVICE_ICON_SKIN_COLOR_RED_ORIGAMI,
      SKIN_COLOR_REFLEKTA:
        imagesConstants.EPOD3_DEVICE_ICON_SKIN_COLOR_REFLEKTA,
      SKIN_COLOR_REFLEKTA_ORIGAMI:
        imagesConstants.EPOD3_DEVICE_ICON_SKIN_COLOR_REFLEKTA_ORIGAMI,
    },
    ICON: {
      SKIN_COLOR_BLACK: imagesConstants.EPOD3_DEVICE_ICON_RE_SKIN_COLOR_BLACK,
      SKIN_COLOR_BLUE: imagesConstants.EPOD3_DEVICE_ICON_RE_SKIN_COLOR_BLUE,
      SKIN_COLOR_CYAN: imagesConstants.EPOD3_DEVICE_ICON_RE_SKIN_COLOR_CYAN,
      SKIN_COLOR_GOLD: imagesConstants.EPOD3_DEVICE_ICON_RE_SKIN_COLOR_GOLD,
      SKIN_COLOR_PINK: imagesConstants.EPOD3_DEVICE_ICON_RE_SKIN_COLOR_PINK,
      SKIN_COLOR_RED: imagesConstants.EPOD3_DEVICE_ICON_RE_SKIN_COLOR_RED,
    },
  },
};
