import cx from "classnames";
import React from "react";

const FeatureWidgetGroup = ({ className, style, title, children }) => {
  return (
    <>
      {title && <div className="feature-widget-group-title">{title}</div>}
      <div style={style} className={cx("feature-widget-group", className)}>
        {children}
      </div>
    </>
  );
};

export { FeatureWidgetGroup };
