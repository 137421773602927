import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { getAEMAssetsUrl } from '../../../_actions/appConfig.actions';
import { AnalyticsEvents } from '../../../_constants';
import { logAnalyticsEvent } from '../../../_helpers/analytics/logAnalytics';

export const VuseWorldCarousel = (props) => {
    const {
        carousel = {
            items: [],
        },
        title,
    } = props;

    const onSlideChange = ({activeIndex}) => {
        const carouselItem = (carousel?.items && carousel.items[activeIndex]) || null;
        if (!!carouselItem) {
            logAnalyticsEvent(AnalyticsEvents.VW_SCROLL_CAROUSEL, {
                category: title,
                item: (!!carouselItem?.cta?.analytics && carouselItem?.cta?.analytics['dtm-1']) || null,
                index: activeIndex + 1
            });
        }
    }

    // console.log("carousel", carousel);
    // console.log("carousel.items", carousel.items);
    const renderCarousel = () => {
        return carousel.items
            .map((carouselItem, index) => {

                const onImageClick = () => {
                    if (typeof props.handleElementAction === "function") {
                        props.handleElementAction(carouselItem);
                        logAnalyticsEvent(AnalyticsEvents.VW_CLICK_CAROUSEL_ITEM, {
                            category: title,
                            item: (!!carouselItem?.cta?.analytics && carouselItem?.cta?.analytics['dtm-1']) || null
                        });
                    }
                };

                return (
                    <SwiperSlide
                        className="swiper-slide"
                        key={`carousel-${index}`}
                    >
                        <img src={`${getAEMAssetsUrl()}${carouselItem.image?.imageSrc?.desktop?.src ?? carouselItem?.image?.path}`}
                            alt=""
                            onClick={onImageClick}
                        />
                    </SwiperSlide>
                );
            });
    };

    return carousel.items.length > 0 ? (
        <div className='vuseworld-section' style={{ ...props.style }}>
            <div className='vuseworld-section-header'>
                <p className='vuseworld-section-title'>{title}</p>
            </div>
            <Swiper
                slidesPerView={'auto'}
                loop={false}
                centeredSlides={true}
                centeredSlidesBounds={true}
                centerInsufficientSlides={false}
                initialSlide={0}
                pagination={{ clickable: true }}
                runCallbacksOnInit={false} //not fire onSlideChange when initialSlide <> 0
                className="vuseworld-section-body vuseworld-carousel-container"
                onSlideChange={onSlideChange}
            >
                {renderCarousel()}
            </Swiper>
        </div>
    ) : <></>
};
