import { getTenantId } from "../_actions/appConfig.actions";
import { axiosInstance } from "../_helpers/api/axiosRequest";
import UrlGenerator from "../_helpers/api/urlGenerator";

export async function estimateAge(img) {
  return axiosInstance().post(
    UrlGenerator.createIoTPlatformUrlFor("yoti/age-estimation"),
    { img },
    {
      headers: {
        "x-tenant-id": getTenantId(),
      },
    }
  );
}

export async function createSession() {
  return axiosInstance().post(
    UrlGenerator.createIoTPlatformUrlFor("yoti/create-session"),
    {},
    {
      headers: {
        "x-tenant-id": getTenantId(),
      },
    }
  );
}

export async function getVerificationResult(sessionId) {
  return axiosInstance().get(
    UrlGenerator.createIoTPlatformUrlFor("yoti/identity-verification-result"),
    {
      headers: {
        "x-tenant-id": getTenantId(),
        session_id: sessionId,
      },
    }
  );
}

export const ageVerificationServices = {
  createSession,
  getVerificationResult,
};
