import React, { useEffect, useState } from "react";
import { Header } from "../../_components";
import { aemPages, routingConstants } from "../../_constants";
import {
  AEMHelper,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import { buildURI } from "../../_helpers/navigation";

export function UsageTrackerOptinInstructions({ history }) {
  const [dictionary, setDictionary] = useState({});

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.USAGE_TRACKER_OPTIN, {
        UT_OPTIN_MD_HEADER: propertyHeadingDefaultEmpty,
        UT_OPTIN_MD_CONTENT: propertyTextDefaultEmptyParsed,
        UT_OPTIN_MD_HOW: propertyTextDefaultEmpty,
        UT_OPTIN_MD_STEP1: propertyTextDefaultEmpty,
        UT_OPTIN_MD_STEP2: propertyTextDefaultEmpty,
        UT_OPTIN_MD_STEP3: propertyTextDefaultEmpty,
      })
    );
  }, []);

  const handleBack = () => {
    history.push(buildURI(routingConstants.USAGE_TRACKER_OPTIN));
  };

  return (
    <div className="page w-100">
      <Header
        leftButton={{
          icon: <span className="bat-icon-back" />,
          onClick: handleBack,
        }}
      >
        {dictionary.UT_OPTIN_MD_HEADER || "USAGE TRACKER"}
      </Header>
      <div className="page-wrapper optin-instructions-wrapper">
        <div className="optin-instructions-descr">
          {dictionary.UT_OPTIN_MD_CONTENT ||
            "You can enable and disable the “Usage Tracker” feature in your preference settings"}
          <br />
          {dictionary.UT_OPTIN_MD_HOW || "Here’s how:"}
        </div>
        <div className="optin-instructions-content">
          <ol>
            <li>
              <span className="bat-icon-hamburger"></span>
              {dictionary.UT_OPTIN_MD_STEP1 || "Tap the menu"}
            </li>
            <li>
              <span className="bat-icon-chevron-right"></span>
              {dictionary.UT_OPTIN_MD_STEP2 || "Select your preferences"}
            </li>
            <li>
              <span className="optin-instructions-on-off"></span>
              {dictionary.UT_OPTIN_MD_STEP3 || "Enable/Disable Usage Tracker"}
            </li>
          </ol>
        </div>
      </div>
      <div className="page-linear-gradient-bg" />
    </div>
  );
}
