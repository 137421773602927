import React, { useState } from 'react';
import { FadingList, PageSelectorCTA } from '..';
import { useForm } from "react-hook-form";
import { AnalyticsEvents, AnalyticsFieldNames, AnalyticsTargetEventsNames } from '../../_constants';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { CustomLoadingButton } from '../CustomLoadingButton';
import { store } from '../../_store';
import { NeedHelpPanel } from '../NeedHelpPanel';
import { VaultHelper } from '../../_helpers/vault';

export const VaultSetupSecureQuestion = (props) => {
    const {
        dictionary,
        isLoading,
    } = props;

    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const { register, handleSubmit, watch } = useForm();
    const { answer } = watch();

    /**
     * ask parent to secure vault data
     */
    const secureData = () => {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.SECURE_MY_DATA});

        props.secureData({
            question: selectedQuestion,
            answer
        });
    }

    const isFormValid = () => {
        return answer != null && answer !== undefined && answer !== "";
    }

    const handleBlur = () => {
        logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.SECURITY_QUESTION_ANSWER})   
    }

    return (
        <form onSubmit={handleSubmit(secureData)} className="mt-auto">
            <div className="vault-security-question">
                <div className="page-label">{dictionary.VAULT_STEP_QUESTION_TITLE}</div>
                <div className="d-grid vault-setup-security-question">
                    <FadingList
                        items={VaultHelper.getSecurityQuestionItemsBy(dictionary)}
                        selectedItem={selectedQuestion}
                        handleClick={(question) => { setSelectedQuestion(question === selectedQuestion ? null : question); logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.SECURITY_QUESTION}) }}
                    />
                    {selectedQuestion != null &&
                        <input name="answer"
                            className="form-control"
                            type="text"
                            style={{
                                zIndex: 1
                            }}
                            placeholder={selectedQuestion.placeholder}
                            ref={register()}
                            onBlur={handleBlur}
                        />
                    }
                </div>
            </div>
            <PageSelectorCTA sticky={false} ctaUrl={true} className="mt-5">
            <div className="d-grid">
                <CustomLoadingButton isLoading={isLoading}
                    validationMethod={isFormValid()}
                    buttonLabel={dictionary.VAULT_BTN_CONFIRM_QUESTION_0_ctaLabel}
                    onClickMethod={null} />
                </div>
                <div className="page-selector-cta-url">
                <NeedHelpPanel
                    link={dictionary.VAULT_PIN_LINK}
                    title={dictionary.VAULT_INFO_HEADER}
                    body={dictionary.VAULT_INFO_CONTENT}
                    analyticsPin={true}
                />
                </div>
            </PageSelectorCTA>
        </form>
    );
}