import React, { useContext, useEffect, useState } from 'react';
import Stories from 'react-insta-stories';
import { store } from '../../_store';
import { DashboardContext } from '../../Views';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { AnalyticsEvents, AnalyticsTargetEventsNames } from '../../_constants';

export const AppStories = (props) => {
    const dashboardContext = useContext(DashboardContext) || {};
    const [index, setIndex] = useState(0)
    const [tutorialName, setTutorialName] = useState(props?.tutorialName)
    //const screenName = localStorage.getItem('screen_view')

    //console.log('[AppStories] screenName', screenName);

    const handleAllStoriesEnd = (s, st) => {
        if (typeof props.doneTutorialMethod === 'function') {
            store.dispatch(props.doneTutorialMethod());
        }

        if (typeof props.endStoriesCallback === 'function') {
            props.endStoriesCallback();
        }
    }

    const handleStoryEnd = (idx, st) => {
        console.log('story ended', idx, st);
        setIndex(idx+ 1)
        /* if(props.analyticsNoDevice){
            logAnalyticsEvent(AnalyticsEvents.VIDEO_TUTORIAL,{tutorial_name: 'j1', step: idx + 1})
         }
        if(props.analyticsTutorial){
            logAnalyticsEvent(AnalyticsEvents.TUTORIAL_VIEW,{tutorial_name: tutorialName, step: idx + 1})
        }*/
        if (typeof props.endStoryCallback === 'function') {
            props.endStoryCallback(idx, st);
        }
    }

    const handleStoryStart = (idx, st) => {
        console.log('story started', idx, st);
        setIndex(idx+ 1)
        if(props.analyticsNoDevice){
            logAnalyticsEvent(AnalyticsEvents.VIDEO_TUTORIAL,{tutorial_name: 'j1', step: idx + 1}) 
        }
        /*if(props.analyticsTutorial){
            logAnalyticsEvent(AnalyticsEvents.TUTORIAL_VIEW,{tutorial_name: tutorialName, step: idx + 1})
        }*/
        if (typeof props.startStoryCallback === 'function') {
            props.startStoryCallback(idx, st);
        }
    }

    const handleNext = () => {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NEXT}); 
        let currentIndex = index
        if(currentIndex === props.stories.length && props.analyticsNoDevice){
            setIndex(1)
            // logAnalyticsEvent(AnalyticsEvents.VIDEO_TUTORIAL,{tutorial_name: 'j1', step: 1})
        }else {
            setIndex(index + 1)
            // logAnalyticsEvent(AnalyticsEvents.VIDEO_TUTORIAL,{tutorial_name: 'j1', step: index + 1})
        }
        
        if(currentIndex === props.stories.length && props.analyticsTutorial){
            setIndex(1)
            // logAnalyticsEvent(AnalyticsEvents.TUTORIAL_VIEW,{tutorial_name: tutorialName, step: 1})
        }else {
            setIndex(index + 1)
            // logAnalyticsEvent(AnalyticsEvents.TUTORIAL_VIEW,{tutorial_name: tutorialName, step: index + 1})
        }
    }

    const handlePrev = () => {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.PREVIOUS});
        let currentIndex = index 
        if(currentIndex === props.stories.length && props.analyticsNoDevice){
            setIndex(index - 1)
            // logAnalyticsEvent(AnalyticsEvents.VIDEO_TUTORIAL,{tutorial_name: 'j1', step: 1})
        }
        if(currentIndex === props.stories.length && props.analyticsTutorial){
            setIndex(index - 1)
            // logAnalyticsEvent(AnalyticsEvents.TUTORIAL_VIEW,{tutorial_name: tutorialName, step: index - 1})
        }
    }

    return (
        <React.Fragment>
            {props?.stories?.length > 0 &&
                <Stories
                    stories={props.stories}
                    defaultInterval={3000}
                    currentIndex={props.currentIdx ?? 0}
                    width={'100vw'}
                    height={props.storiesHeight ?? '100vh'}
                    loop={props.loop ?? true}
                    onStoryEnd={handleStoryEnd}
                    onAllStoriesEnd={handleAllStoriesEnd}
                    onStoryStart={handleStoryStart}
                    storyContainerStyles={{ overflow: 'hidden' }}
                    {...(dashboardContext?.headerHeight && { progressContainerStyles: { top: `${dashboardContext?.headerHeight}px` } })}
                    onNext={props.analyticsTutorial || props.analyticsNoDevice ? handleNext : null}
                    onPrevious={props.analyticsTutorial || props.analyticsNoDevice ? handlePrev : null}
                />
            }
        </React.Fragment>
    );
}
