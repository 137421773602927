import React, { useState, useEffect } from 'react';
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyHeadingDefaultEmpty, propertyTextDefaultEmptyParsed } from '../../_helpers/aem/aemhelper';
import { aemPages } from '../../_constants';
import { Header, TermsAndConditionsAccordions } from '..';
import { Tenants } from '../../_helpers/tenants';
import { Webview } from '../../_helpers/webview';

export const TermsAndConditions = (props) => {
    const { handleBack, close, isAPublicView, logo } = props;

    const [dictionary, setDictionary] = useState({});
    useEffect(() => {
        const aem = new AEMHelper();

        setDictionary(
            aem.getDictionary(Tenants.isDemo() ? aemPages.REGISTARTION_TAC_DEMO : aemPages.REGISTRATION_TAC, {
                TAC_BTN_ACCEPT: propertyCtaItemsDefaultEmpty,
                TAC_HEADER: propertyHeadingDefaultEmpty,
                TAC_CONTENT: propertyTextDefaultEmptyParsed,
                TAC_ACCEPT_INFO: propertyTextDefaultEmptyParsed,
                TAC_TITLE: propertyTextDefaultEmptyParsed,
            })
        );
    }, []);

    const parseNewLine = (texts) => {
        let result = []

        for (const text of texts) {
            if (typeof text === 'string') {
                const formattedText = text?.split('\\n').map((line, index, array) => 
                    index === array.length - 1 ? line : (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    )
                );

                result.push(<>{formattedText}</>)
            } else {
                result.push(text)
            }
        }

        return result
    };

    return isAPublicView ? (
        <>
            <div className="page-wrapper d-flex flex-column">
                {dictionary.TAC_CONTENT && 
                    <div className="page-box-disclaimer mb-0 mt-2">
                        <div className="page-label mb-3">{dictionary?.TAC_TITLE}</div>
                        <div onClick={() => Tenants.isCanadaDark() ? Webview.goToMyVusePrivacyPolicy() : null}>
                            {parseNewLine(dictionary.TAC_CONTENT)}
                        </div>
                    </div>}
                <TermsAndConditionsAccordions />
            </div>
            <div className="page-linear-gradient-bg"></div>
        </>
    ) : (
        <>
            <Header 
                {...(handleBack && {
                    leftButton: {
                        icon: <span className="bat-icon-back" />,
                        onClick: handleBack
                    }
                })}
                {...(close && {
                    rightButton: {
                        icon: <span className='bat-icon-close' />,
                        onClick: close
                    }
                })}
                {...(logo && {
                    subHeader: dictionary.TAC_HEADER
                })} 
            >
                {logo ? null : parseNewLine([dictionary.TAC_HEADER])}
            </Header>
            <div className="page-wrapper d-flex flex-column">
            {/*dictionary.TAC_TITLE && */dictionary.TAC_CONTENT && ////
                <div className="page-box-disclaimer mb-0 mt-2">
                    <div className="page-label mb-3">{dictionary.TAC_TITLE}</div>
                    <div onClick={() => Tenants.isCanadaDark() ? Webview.goToMyVusePrivacyPolicy() : null}>
                        {parseNewLine(dictionary.TAC_CONTENT)}
                    </div>
                </div>}
                <TermsAndConditionsAccordions />
            </div>
            <div className="page-linear-gradient-bg"></div>
        </>
    );
};
