import React, { forwardRef } from "react";
import cx from "classnames";

const PageSelectorCTA = forwardRef(
  ({ sticky = true, ctaUrl = false, className, style, children }, ref) => {
    return (
      <div
        ref={ref}
        className={cx(
          "page-selector-cta",
          {
            sticky,
            "mt-auto": !!sticky,
            transparent: !sticky,
            "with-url": !!ctaUrl,
          },
          className
        )}
        {...(style && { style })}
      >
        {children}
      </div>
    );
  }
);

export { PageSelectorCTA };
