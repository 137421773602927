import UrlGenerator from '../_helpers/api/urlGenerator';
import * as Uri from '../_constants/api/uri.constants';
import IoTPlatformUtils from '../_helpers/api/IoTPlatformUtils';
import { axiosRequest } from '../_helpers/api/axiosRequest';

export const onboardingServices = {
    //getProfileData,
    secureVaultData,
    storeVaultPin,
    validateSecurityQuestion,
    validatePin,
    updateVaultData,
    updateVaultPin,
};


/**
 * Servizio per recupero informazioni
 * sul profilo dell'utente
 * 
 * @param {*} data 
 */
/*
async function getProfileData(token, userId) {
    let requestOptions = {
        url: UrlGenerator.createIoTPlatformUrlFor(Uri.userProfile),
        method: 'GET',
        headers: {
            'Authorization': token,
            'X-name': userId,
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
            "Content-Type": "application/json",
            "X-user-os": "IOS"
        },
        cache: 'no-cache',
    };
    return axiosRequest(requestOptions);
}
*/

/********************************************************************************************************* */

/**
 * Save the tenant-user-id (first step vault setup)
 * @param {*} tenantUserId : x-tenant-user-id
 * @returns 
 */
async function secureVaultData(tenantUserId) {
    let requestOptions = {
        url: UrlGenerator.createIoTPlatformUrlFor(Uri.storeTenantUserId),
        method: 'POST',
        headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
        cache: 'no-cache',
    };
    return axiosRequest(requestOptions);
}

/**
 * Second step vault setup
 * @param {*} data "encryptedPin": "string"
 * @param {*} tenantUserId : x-tenant-user-id
 * @returns 
 */
async function storeVaultPin(data, tenantUserId) {
    let requestOptions = {
        url: UrlGenerator.createIoTPlatformUrlFor(Uri.storePin),
        method: 'POST',
        headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
        cache: 'no-cache',
        data: data,
    };
    return axiosRequest(requestOptions);
}

/**
 * First step vault recovery -> pin validation
 * @param {*} tenantUserId 
 * @returns 
 */
 async function validatePin(tenantUserId) {
    let requestOptions = {
        url: UrlGenerator.createIoTPlatformUrlFor(Uri.validatePin),
        method: 'GET',
        headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
        cache: 'no-cache',
    };
    return axiosRequest(requestOptions);
}

/**
 * Second step vault recovery -> answer validation
 * @param {*} tenantUserId 
 * @param {*} payload { "customerId": string,  "everLoggedTimestamp": string, "securityAnswer": string }
 * @returns 
 */
async function validateSecurityQuestion(tenantUserId, payload) {

    console.log("[validateSecurityQuestion] tenantUserId", tenantUserId);
    console.log("[validateSecurityQuestion] payload", payload);
    let requestOptions = {
        url: UrlGenerator.createIoTPlatformUrlFor(Uri.validateSecAnswer),
        method: 'POST',
        headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
        data: payload,
        cache: 'no-cache',
    };
    return axiosRequest(requestOptions);
}


/**
 * Save the tenant-user-id (first step vault reset/update)
 * @param {*} data 
 * @param {*} tenantUserId { "newTenantUserID": string }
 * @returns 
 */
 async function updateVaultData(data, tenantUserId) {
    let requestOptions = {
        url: UrlGenerator.createIoTPlatformUrlFor(Uri.updateTenantUserId),
        method: 'POST',
        headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
        data,
        cache: 'no-cache',
    };
    return axiosRequest(requestOptions);
}

/**
 * Second step vault reset/update, Update user pin.
 * @param {*} data 
 * @param {*} tenantUserId { "newEncryptedPin": string }
 * @returns 
 */
async function updateVaultPin(data, tenantUserId) {
    let requestOptions = {
        url: UrlGenerator.createIoTPlatformUrlFor(Uri.updatePin),
        method: 'POST',
        headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
        cache: 'no-cache',
        data: data,
    };
    return axiosRequest(requestOptions);
}


