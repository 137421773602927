import { AGE_VERIFICATION_ACTIONS } from "../_constants";
import { FAILURE, REQUEST, SUCCESS } from "./action-type.util";

export const initialState = {
  loading: false,
  session: null,
  error: null,
  uploadingIsComplete: false,
  verificationResult: null,
};

export function ageVerificationReducer(state = initialState, action) {
  switch (action.type) {
    case "RESET_REDUCER":
      return {
        loading: false,
        session: null,
        error: null,
        uploadingIsComplete: false,
        verificationResult: null,
      };
    case REQUEST(AGE_VERIFICATION_ACTIONS.CREATE_SESSION):
      return {
        ...state,
        loading: true,
      };
    case SUCCESS(AGE_VERIFICATION_ACTIONS.CREATE_SESSION):
      return {
        ...state,
        session: action.payload,
        loading: false,
      };
    case FAILURE(AGE_VERIFICATION_ACTIONS.CREATE_SESSION):
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case AGE_VERIFICATION_ACTIONS.SET_UPLOADING_IS_COMPLETE:
      return {
        ...state,
        uploadingIsComplete: action.payload,
      };
    case AGE_VERIFICATION_ACTIONS.RESET_SESSION:
      return {
        ...state,
        session: null,
      };
    case REQUEST(AGE_VERIFICATION_ACTIONS.GET_VERIFICATION_RESULT):
      return {
        ...state,
        loading: true,
      };
    case SUCCESS(AGE_VERIFICATION_ACTIONS.GET_VERIFICATION_RESULT):
      return {
        ...state,
        verificationResult: action.payload,
        loading: false,
      };
    case FAILURE(AGE_VERIFICATION_ACTIONS.GET_VERIFICATION_RESULT):
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
