import React from "react";

export function UsageTrackerSession({ label = "", value = 0 }) {
  return (
    <div className="usage-tracker-stats-box session">
      <div className="d-flex align-items-center justify-content-between">
        <span className="usage-tracker-stats-box-label dark">{label}</span>
      </div>
      <div className="usage-tracker-stats-box-value">{value}</div>
    </div>
  );
}
