import React, { useEffect, useState } from 'react';
import { aemPages, flagStatus, flavourType, imagesConstants, thingVuseProperties } from '../../_constants';
import { CustomModal } from '../../_components/CustomModal';
import HealthWarning from '../../_components/HealthWarning';
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyImageSrcDefaultNull, propertyTextDefaultEmpty } from '../../_helpers/aem/aemhelper';
import { BasketElementModel } from '../../_models';
import Badge from '@mui/material/Badge';
import { ShopUtils } from '../../_helpers/shop';
import { calculateTotalPriceFromBasket, calculateTotalQuantityFromBasket } from '../../_helpers/basket';
import { Commons } from '../../_helpers/commons';
import { OptInFavourites } from '../../Views';
import { useSelector } from 'react-redux';

export const FlavourPDP = ({
    flavour,
    isOpen,
    onClosePdp,
    wishlist,
    onAddToCart,
    onCheckout,
    onOpenBasket,
    onToggleFavourite
}) => {

    const basket = useSelector(state => state.shopReducer.basket);

    const [dictionary, setDictionary] = useState({});
    const [thisBasket, setThisBasket] = useState([]);

    const [basketTotal, setBasketTotal] = useState(0);
    const [basketQty, setBasketQty] = useState(0);
    const [basketVariantQty, setBasketVariantQty] = useState(0);
    const [isOpenOptinModal, setIsOpenOptinModal] = useState(false);
    const [savedVariant, setSavedVariant] = useState(null);
    const [variants, setVariants] = useState([]);

    const [totalSpanKey, setTotalSpanKey] = useState(`total-${Date.now()}`);
    
    useEffect(() => {

        // console.log("flavourxx", flavour);
        // console.log('wishlist', wishlist);


        if (flavour?.variants) {
            let remoteWishlist = ShopUtils.getUserWishlist();
            // console.log('currentWishlist', remoteWishlist);

            setVariants((flavour.variants).map(variant => {

                /**
                 * Handle, for each variant, its presence in wishlist. 
                 * If a valid wishlist is passed to this component use this for the iterations on product,
                 * if no wishlist is passed to component use the remote one.
                 */
                if (wishlist?.length > 0) { //wishlist is passed to component

                    let wishlistVariant = wishlist.find(wishlistEl => wishlistEl.variant_id === variant.variant_id);
                    if (wishlistVariant) { //variant is present in wishlist

                        variant.is_favourite = true;

                        if (variant.quantity > 0) { //if wishlist is passed to component also set favourite quantity as variant quantity in component

                            let basketQty = wishlistVariant.favouriteQty;
                            if (
                                basketQty === null ||  //favouriteQty can be undefined, because quantity is handled in remoteWishlist
                                basketQty === undefined
                            ) {
                                if (
                                    remoteWishlist[variant.variant_id] !== null &&
                                    remoteWishlist[variant.variant_id] !== undefined
                                ) {
                                    basketQty = remoteWishlist[variant.variant_id];
                                } else {
                                    basketQty = 1;
                                }
                            }
                            thisBasket.push(new BasketElementModel({ //add variant in pdp temp basket
                                ...variant,
                                basket: basketQty,
                                id: flavour.id,
                                price: flavour.price
                            }))
                        }
                    } else {
                        variant.is_favourite = false;
                    }

                } else { //if no wishlist is passed to component set variant as favourite if is present in remote wishlist
                    variant.is_favourite = remoteWishlist[variant.variant_id] !== null && remoteWishlist[variant.variant_id] !== undefined;
                }

                return variant;

            }))

            setThisBasket([...thisBasket]);
        }

    }, [flavour])

    const closePdp = () => {
        if (typeof onClosePdp === 'function') {
            onClosePdp();
        }
    }

    const handleAddToCart = () => {
        if (typeof onAddToCart === 'function') {
            onAddToCart(thisBasket);
            setThisBasket([]);
        }
    }

    const handleCheckout = () => {
        if (typeof onCheckout === 'function') {
            onCheckout(thisBasket);
        }
    }

    useEffect(() => {
        const aem = new AEMHelper();
        setDictionary({
            ...aem.getDictionary(aemPages.HOMEPAGE, {
                HEALTH_WARNING: propertyImageSrcDefaultNull
            }),
            ...aem.getDictionary(aemPages.SHOP, {
                SHOP_NICOTINE_STRENGHT_TITLE: propertyTextDefaultEmpty,
                SHOP_SELECTED_LABEL: propertyTextDefaultEmpty,
                SHOP_BTN_ADD_TO_CART: propertyCtaItemsDefaultEmpty,
                SHOP_BTN_BUY_NOW: propertyCtaItemsDefaultEmpty,
                SHOP_NEW: propertyTextDefaultEmpty,
                SHOP_UNAVAILABLE: propertyTextDefaultEmpty
            }),
        })
    }, [])

    useEffect(() => {
        if(
            basket !== null &&
            basket !== undefined
        ){
            setBasketQty(calculateTotalQuantityFromBasket(basket));
        }
    }, [basket]);

    /**
     * Update variant quantity in temporary basket
     * @param {*} variant 
     * @param {*} increment 
     */
    const updateCurrentVariantQuantity = (variant, increment, quantity) => {

        const variantIdx = thisBasket.findIndex(thisVariant => thisVariant.variant_id === variant.variant_id);

        if (quantity === null || quantity === undefined) {
            let thisVariantBasketQuantity = variantIdx !== -1 ? thisBasket[variantIdx].basket : 0;
            let newVariantBasketQuantity = thisVariantBasketQuantity;

            newVariantBasketQuantity = increment ? thisVariantBasketQuantity + 1
                : (thisVariantBasketQuantity > 0 ? thisVariantBasketQuantity - 1 : 0);

            if (variantIdx !== -1) {
                if (newVariantBasketQuantity === 0) {
                    thisBasket.splice(variantIdx, 1);
                } else {
                    thisBasket[variantIdx] = new BasketElementModel({
                        ...variant,
                        basket: newVariantBasketQuantity,
                        id: flavour.id,
                        price: flavour.price
                    })
                }
            } else {
                if (newVariantBasketQuantity > 0) {
                    thisBasket.push(new BasketElementModel({
                        ...variant,
                        basket: newVariantBasketQuantity,
                        id: flavour.id,
                        price: flavour.price
                    }))
                }
            }
        } else {
            if (variantIdx !== -1) {
                thisBasket[variantIdx] = new BasketElementModel({
                    ...variant,
                    basket: quantity,
                    id: flavour.id,
                    price: flavour.price
                })
            }
        }


        setThisBasket([...thisBasket]);

    }

    useEffect(() => {
        //calculate quantity and total basket
        setBasketTotal(calculateTotalPriceFromBasket(thisBasket));
        setBasketVariantQty(calculateTotalQuantityFromBasket(thisBasket));

        setTotalSpanKey(`total-${Date.now()}`);

    }, [thisBasket]);

    const toggleVariantFavourite = (variant) => {

        //check if user already accepted favourites optin
        if (Commons.getCustomerProperty(thingVuseProperties.OPT_IN_FAVOURITES) === flagStatus.ACCEPTED) {
            updateVariantInWishlist(variant.variant_id);
            onToggleFavourite(variant).catch(() => {
                //if something didn't worked in wishlist update reverte user preference
                updateVariantInWishlist(variant.variant_id);
            });
            setIsOpenOptinModal(false);
            setSavedVariant(null);
        } else {
            setSavedVariant(variant);
            setIsOpenOptinModal(true);
        }
    }

    const updateVariantInWishlist = (variantId) => {
        //update current product wishlist
        const variantIdx = variants.findIndex(el => el.variant_id === variantId);
        if (variantIdx !== -1) {
            variants[variantIdx].is_favourite = !variants[variantIdx].is_favourite;
        }

        setVariants([...variants]);

    }

    useEffect(() => {
        //console.log("this variants", variants);
    }, [variants])


    return (
        <React.Fragment>
            <React.Fragment>
                <OptInFavourites isOpen={isOpenOptinModal}
                    onClose={() => toggleVariantFavourite(savedVariant)}
                    onDismiss={() => setIsOpenOptinModal(false)}
                />
            </React.Fragment>
            {
                flavour &&
                <CustomModal
                    isOpen={isOpen}
                    className={"pdp-shop-modal"}>

                    <div className="shop-pdp" style={{
                        paddingBottom: basketVariantQty > 0 ? '130px' : '91px'
                    }}>
                        <div className="fixed-top">
                            <HealthWarning>{dictionary.HEALTH_WARNING}</HealthWarning>
                            <div className="page-header w-100">
                                <header className="d-flex align-items-center justify-content-center">
                                    <button className="page-header-icon" onClick={closePdp}><span className="bat-icon-back"></span></button>
                                    <div className="page-header-logo"><img src={imagesConstants.MYVUSE_lOGO} alt="MUSE logo" /></div>
                                </header>
                            </div>
                        </div>
                        <div className="shop-pdp-wrapper">
                            <div className="shop-pdp-image" style={{
                                ...(flavour.gradient && flavour.gradient !== "" && { background: flavour.gradient })
                            }}>
                                {flavour?.tags && (flavour.tags).includes(flavourType.FLAVOUR_NEW) && <span className="new">{dictionary.SHOP_NEW}</span>}
                                <img src={flavour.featured_image} alt={flavour.title} style={{ padding: '1.5625rem' }} />
                            </div>
                            <div className="shop-pdp-title text-uppercase">
                                <div className="container-fluid p-0">
                                    <div className="row">
                                        <div className="col-8">
                                            {flavour.title}
                                        </div>
                                        <div className="col-4 text-end">
                                            <span className="price">${flavour.price}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="shop-pdp-description">
                                {flavour.description}
                            </div>

                            <div className="label-title">
                                {dictionary.SHOP_NICOTINE_STRENGHT_TITLE}
                            </div>
                            <div className="shop-pdp-list">
                                {
                                    (Commons.sortArrayAlphabetically(variants, 'title')).map((variant, idx) => {

                                        let variantBasketQuantity = thisBasket.find(el => el.variant_id === variant.variant_id)?.basket ?? 0;

                                        return (
                                            <div key={`variant-${idx}`} className="shop-pdp-list-item d-flex align-items-center">
                                                {
                                                    variant?.is_favourite ?
                                                        <span className="bat-icon-star-filled" onClick={() => toggleVariantFavourite(variant)}></span>
                                                        :
                                                        <span className="bat-icon-star-outline" onClick={() => toggleVariantFavourite(variant)}></span>

                                                }
                                                <span className="shop-pdp-list-item-nic">
                                                    Nic: {variant.title}
                                                </span>

                                                {
                                                    variant.quantity > 0 ?

                                                        <span className="shop-pdp-list-item-qty d-flex align-items-center ms-auto">
                                                            <button className="shop-pdp-list-item-qty-min"
                                                                onClick={() => updateCurrentVariantQuantity(variant, false)}
                                                                disabled={variantBasketQuantity < 1}>-</button>
                                                            <span className="shop-pdp-list-item-qty-num">{variantBasketQuantity}</span>
                                                            <button className="shop-pdp-list-item-qty-add"
                                                                onClick={() => updateCurrentVariantQuantity(variant, true)}
                                                                disabled={variantBasketQuantity >= variant.quantity}>+</button>
                                                        </span>

                                                        :
                                                        <span className="d-flex align-items-center ms-auto">
                                                            <div className="alert alert-danger shop-product-tag" role="alert">
                                                                {dictionary.SHOP_UNAVAILABLE}
                                                            </div>
                                                        </span>
                                                }

                                            </div>
                                        )

                                    })
                                }
                            </div>
                        </div>

                        <div className="shop-pdp-actions">
                            {
                                basketVariantQty > 0 &&
                                <div className='row d-flex align-content-between mb-3'>
                                    <div className='col-6'>
                                        <div className="alert alert-secondary shop-product-tag d-inline" role="alert">
                                            {dictionary.SHOP_SELECTED_LABEL.replace("$1", basketVariantQty)}
                                        </div>
                                    </div>
                                    <div className='col-6 text-end'>
                                        <span className="price" key={totalSpanKey} >${basketTotal}</span>
                                    </div>
                                </div>
                            }

                            <div className='row d-flex align-items-center g-2'>
                                <div className='col-1' onClick={onOpenBasket}>
                                    <Badge color="secondary" badgeContent={basketQty}><span className='bat-icon-cart'></span></Badge>
                                </div>
                                <div className='col-6'><button className="btn btn-success flex-fill text-uppercase w-100"
                                    disabled={basketVariantQty <= 0}
                                    onClick={handleAddToCart}>
                                    {dictionary.SHOP_BTN_ADD_TO_CART_0_ctaLabel}
                                </button></div>
                                <div className='col-5'><button className="btn btn-primary flex-fill text-uppercase w-100"
                                    disabled={basketVariantQty <= 0}
                                    onClick={handleCheckout}>
                                    {dictionary.SHOP_BTN_BUY_NOW_0_ctaLabel}
                                </button></div>
                            </div>
                        </div>
                    </div>
                </CustomModal>
            }
        </React.Fragment>
    )

}