import React from 'react';
import { WebView } from '../../_components';
import { getURLSearchObject } from '../../_helpers/navigation';
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';
import { routingConstants } from '../../_constants';
import { axiosInstance } from '../../_helpers/api/axiosRequest';


class MaintenancePage extends React.Component {

    componentDidMount() {

        const {
            url
        } = getURLSearchObject();

        const { origin } = new URL(url);
        console.log("Origin: " + origin);

        axiosInstance().get(origin).then(res => {
            history.push(buildURI(routingConstants.DASHBOARD));
          });
    }

    render() {
         const {
             url
         } = getURLSearchObject();

        return (
            <WebView
                showHeader={false}
                title={''}
                handleBack={() => this.handleBack()}
                url={url}
                id="extPageIframe"
                className="extPageIframe"
            />
        )
    }
}


export { MaintenancePage };