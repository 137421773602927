import React, { useEffect, useState } from "react";
import { getAEMAssetsUrl } from "../../_actions/appConfig.actions";
import { aemPages } from "../../_constants";
import {
  AEMHelper,
  propertyImageSrcDefaultNull,
} from "../../_helpers/aem/aemhelper";

const LoyaltyLogo = () => {
  const [dictionary, setDictionary] = useState({});

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.LOYALTY, {
        LYLT_LOGO: propertyImageSrcDefaultNull,
      })
    );
  }, []);

  return (
    <img
      className="loyalty-logo"
      src={(dictionary.LYLT_LOGO?.desktop?.src) ? `${getAEMAssetsUrl()}${dictionary.LYLT_LOGO?.desktop?.src}` : ''}
      alt="Loyalty Logo"
    />
  );
};

export { LoyaltyLogo };
