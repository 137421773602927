import React from 'react';
import { connect } from 'react-redux';
import { dashboardActions } from '../../_actions';
import { FeatureTutorial } from '../../_components';
import { routingConstants, aemPages, thingVuseProperties, AnalyticsTutorialNames, AnalyticsEvents } from '../../_constants';
import { AEMHelper, propertyHeadingDefaultEmpty } from '../../_helpers/aem/aemhelper';
import { getCurrentDevice } from '../../_helpers/device';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';

class CloudControlTutorial extends React.Component {

    constructor(props) {
        super(props);

        this.aem = new AEMHelper();

        /* Retrieve the videos from AEM */
        const device = getCurrentDevice();

        this.dictionary = this.aem.getDictionary(
            this.aem.getPageName("CLOUD_CONTROL_TUTORIAL", device.deviceType), 
            {
                CLOUD_TUTORIAL_HEADER: propertyHeadingDefaultEmpty,
            }
        );

        let urls = this.aem?.getCarouselUrlsFor(device?.deviceType, "CLOUD_CONTROL_TUTORIAL");
        let stories = urls.map(url => {
            return {
                url: url,
                type: 'video',
                muted: true
            }
        })
        
        /* Set the state */
        this.state = {stories};
        
    }

    componentDidMount(){
        logAnalyticsEvent(AnalyticsEvents.TUTORIAL, {tutorial_name: AnalyticsTutorialNames.CLOUD_CONTROL})
    }

    render() {
        return (
            <FeatureTutorial title={this.dictionary.CLOUD_TUTORIAL_HEADER}
                featureIcon={"puff"}
                stories={this.state.stories}
                iconAction={null}
                backAction={this.props.history.location.state?.backUrl || routingConstants.CLOUD_CONTROL}
                doneTutorialMethod={dashboardActions.setDoneTutorialControls}
                doneTutorialValue={this.props.tutorials.controls}
                thing={thingVuseProperties.CLOUDCONTROL}
                tutorialName={AnalyticsTutorialNames.CLOUD_CONTROL}
            />
        )
    }
}


function mapStateToProps(state) {
    const {
        dashboardReducer: {
            tutorials
        }
    } = state;

    return {
        tutorials
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(CloudControlTutorial);
export { connectedComponent as CloudControlTutorial };