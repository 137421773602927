export class Singleton {

  static getInstance() {
    if (!!this._instance) {
        return this._instance;
    } else {
        this._instance = new this();
        return this._instance;
    }
  }

}