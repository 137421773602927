import { AgeVerification } from "@nodes-projects/bat-sdk-web";
import React, { useEffect, useState } from "react";
import { AsavLoader, PageSelectorCTA } from "..";
import { getAccessToken, getCustomerId } from "../../_actions";
import { getAsavUrl } from "../../_actions/appConfig.actions";
import { aemPages, AnalyticsEvents } from "../../_constants";
import {
  AEMHelper,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { PAIRING_STEP_NAMES } from "../../_helpers/device";

//TODO: change options
// const ASAV_OPTIONS = {
//   debugMode: true,
//   customer: {
//     id: getCustomerId(),
//     token: getAccessToken(),
//   },
//   secureAVUrl: getAsavUrl(),
//   intervalMs: 500,
// };

export function DeviceAsav({
  dictionary,
  isVideoEnded,
  goToNamedStep,
  onStart,
  onSuccess,
  onFailure,
}) {
  const [AV] = useState(
    new AgeVerification({
      debugMode: true,
      customer: {
        id: getCustomerId(),
        token: getAccessToken(),
      },
      secureAVUrl: getAsavUrl(),
      intervalMs: 500,
    })
  );
  const [logs, setLogs] = useState([]); //TODO: remove

  const startASAV = () => AV.startAV();

  const handleDeviceLedIsYellowClick = () => {
    onStart && onStart();
    startASAV();
    logAnalyticsEvent(AnalyticsEvents.START_ASAV);
  };

  const handleAVFlow = (event) => {
    const { detail } = event;

    setLogs((arr) => [...arr, detail]); //TODO: remove

    switch (detail?.step) {
      case AV.FlowSteps.ALREADY_UNLOCKED:
        logAnalyticsEvent(AnalyticsEvents.DEVICE_ALREADY_UNLOCKED);
        onSuccess && onSuccess();
        break;
      case AV.FlowSteps.SUCCESS:
        logAnalyticsEvent(AnalyticsEvents.ASAV_SUCCESS);
        onSuccess && onSuccess();
        break;
      case AV.FlowSteps.TIMEOUT:
        logAnalyticsEvent(AnalyticsEvents.ASAV_TIMEOUT);
        onFailure && onFailure();
        goToNamedStep && goToNamedStep(PAIRING_STEP_NAMES.PAIRING_ERROR);
        break;
      case AV.FlowSteps.FAILURE:
        logAnalyticsEvent(AnalyticsEvents.ASAV_FAILURE, {
          error_desc: detail?.message || "",
        });
        onFailure && onFailure();
        goToNamedStep && goToNamedStep(PAIRING_STEP_NAMES.PAIRING_ERROR);
        break;
    }
  };

  useEffect(() => {
    document.addEventListener(AV.AVEventsEnum.AV_FLOW, handleAVFlow);

    return () => {
      document.removeEventListener(AV.AVEventsEnum.AV_FLOW, handleAVFlow);
    };
  }, [AV]);

  return (
    <>
      {/* <!-- START REMOVE --> */}
      {/* <h2>LOGS</h2>
      {logs.map((item, key) => (
        <p key={"data" + key}>
          {`${item?.step} - ${item?.message}` || JSON.stringify(item)}
        </p>
      ))} */}
      {/* <!-- END REMOVE --> */}
      <div className="pairing-title">
        <h1>{dictionary.PAIRING_PAIR_YOUR_DEVICE}</h1>
        <h2>
          {isVideoEnded
            ? dictionary.PAIRING_ASAV_PAIRING_TITLE ||
              "Your device LED should glow yellow"
            : dictionary.PAIRING_PRESS_BUTTON_FIVE_TIMES}
        </h2>
      </div>
      {isVideoEnded && (
        <PageSelectorCTA sticky={false} className="mt-auto">
          <div className="pairing-content">
            {dictionary.PAIRING_ASAV_PAIRING_CONTENT ||
              "Make sure to keep your device close to your phone during the pairing process."}
          </div>
          <div className="d-grid">
            <button
              className="btn btn-primary text-uppercase"
              onClick={handleDeviceLedIsYellowClick}
            >
              {dictionary.PAIRING_ASAV_BTN_LED_YELLOW ||
                "MY DEVICE'S LED IS YELLOW"}
            </button>
          </div>
        </PageSelectorCTA>
      )}
    </>
  );
}
