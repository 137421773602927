import cx from "classnames";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  commonsActions,
  dashboardActions,
  deviceActions,
  getVuseUuid,
  userActions,
} from "../../_actions";
import {
  CustomSlidingPanel,
  DeviceIcon,
  DeviceIconGroup,
  Header,
  PageSelectorCTA,
} from "../../_components";
import {
  UsageTrackerReconnect,
  UsageTrackerStatsByDay,
  UsageTrackerStatsByMonth,
  UsageTrackerStatsByWeek,
} from "../../_components/UsageTracker";
import {
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  CUSTOMER,
  aemPages,
  crmUserSettings,
  deviceColorClass,
  routingConstants,
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { Commons } from "../../_helpers/commons";
import { buildURI } from "../../_helpers/navigation";
import { getDeviceName } from "../../_helpers/device";

function UsageTracker({
  history,
  devices,
  selectedDevice,
  setSelectedDevice,
  userPin,
  getThings,
  setUserSettings,
}) {
  const [dictionary, setDictionary] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [isDeviceSelectionOpen, setIsDeviceSelectionOpen] = useState(false);
  const [dailyDate, setDailyDate] = useState(null);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const goToDashboard = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CLOSE});
    history.push(buildURI(routingConstants.DASHBOARD));
  };

  const goToTutorial = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.TUTORIAL});
    history.push(buildURI(routingConstants.USAGE_TRACKER_TUTORIAL), {type: 'slide'});
  };

  const saveSelection = (index) => {
    setSelectedDevice(index);
    localStorage.setItem('analytics_device_index', index)
    setIsDeviceSelectionOpen(false);
    logAnalyticsEvent(AnalyticsEvents.SELECTED_DEVICE_USAGE_TRACKER, {
      device_to_track:
        index === -1
          ? "ALL DEVICES"
          : devices[index]?.deviceCustomName || "null",
    });
  };

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.USAGE_TRACKER, {
        UT_TITLE: propertyTextDefaultEmpty,
        UT_USAGE_STATS_LABEL: propertyTextDefaultEmpty,
        UT_ALL_DEVICES_LABEL: propertyTextDefaultEmpty,
        UT_DAY_LABEL: propertyTextDefaultEmpty,
        UT_WEEK_LABEL: propertyTextDefaultEmpty,
        UT_MONTH_LABEL: propertyTextDefaultEmpty,
        UT_TODAY_LABEL: propertyTextDefaultEmpty,
        UT_PUFF_COUNTS_TITLE: propertyHeadingDefaultEmpty,
        UT_PUFF_COUNTS_CONTENT: propertyTextDefaultEmpty,
        UT_PUFF_COUNTS_LINK: propertyTextDefaultEmptyParsed,
        UT_BTN_CONTINUE: propertyCtaItemsDefaultEmpty,
      })
    );
  }, []);

  useEffect(() => {
    setUserSettings(crmUserSettings.USAGE_TRACKER_USED, "true");
  }, [setUserSettings]);

  useEffect(() => {
    if (!isEmpty(dictionary)) {
      setTabs([
        dictionary.UT_DAY_LABEL || "Day",
        dictionary.UT_WEEK_LABEL || "Week",
        dictionary.UT_MONTH_LABEL || "Month",
      ]);
    }
  }, [dictionary]);

  useEffect(() => {
    if (dailyDate) {
      const today = moment();
      const date = moment(dailyDate);

      if (date.isValid() && date.isSameOrBefore(today)) {
        setActiveTab(0);
      }
    }
  }, [dailyDate]);

  useEffect(() => {
    const tenantUserId = Commons.generateTenantUserId(userPin);

    // Load updated things
    getThings(tenantUserId, getVuseUuid(), CUSTOMER);
  }, [userPin, getThings]);

  useEffect(() => {
    console.debug(
      "UT_DISCLAIMER",
      history?.location?.state?.showPuffCountDisclaimer
    );
    setShowDisclaimer(!!history?.location?.state?.showPuffCountDisclaimer);
  }, [history]);

  useEffect(() => {
    if (devices.length > 1) {
      const selectedDeviceIndex = devices.findIndex(
        (device) => device?.selected
      );

      setSelectedDevice(selectedDeviceIndex);
    } else if (devices.length === 1) {
      setSelectedDevice(0);
    } else {
      setSelectedDevice(-1);
    }
  }, []);

  return (
    <div className="usage-tracker usage-stats h-100 w-100">
      <Header
        leftButton={{
          className: "play-tutorial",
          icon: <span className="play-tutorial-icon" />,
          onClick: goToTutorial,
        }}
        rightButton={{
          icon: <span className="bat-icon-close" />,
          onClick: goToDashboard,
        }}
      >
        {dictionary.UT_TITLE || "USAGE TRACKER"}
      </Header>
      <div className="usage-tracker-device-select">
        {devices.length > 1 && (
          <div
            className={cx(
              "usage-tracker-device-select-btn",
              selectedDevice !== -1 &&
                deviceColorClass[devices[selectedDevice]?.deviceColor],
              selectedDevice !== -1 && devices[selectedDevice]?.deviceType
            )}
            onClick={() => setIsDeviceSelectionOpen(true)}
          >
            <div>
              {selectedDevice !== -1
                ? getDeviceName(devices[selectedDevice])
                : dictionary.UT_ALL_DEVICES_LABEL || "ALL DEVICES"}
            </div>
          </div>
        )}
      </div>
      <div className="usage-tracker-tabs">
        {tabs.map((tabName, index) => (
          <span
            key={index}
            className={cx({ active: activeTab === index })}
            onClick={() => setActiveTab(index)}
          >
            {tabName}
          </span>
        ))}
        <div
          className="cursor"
          style={{ transform: `translateX(${100 * activeTab}%)` }}
        />
      </div>
      <UsageTrackerReconnect device={devices[selectedDevice]} />
      {activeTab === 0 && (
        <UsageTrackerStatsByDay
          dailyDate={dailyDate}
          setDailyDate={setDailyDate}
          screenViewAnalytics={!showDisclaimer}
        />
      )}
      {activeTab === 1 && (
        <UsageTrackerStatsByWeek setDailyDate={setDailyDate} />
      )}
      {activeTab === 2 && <UsageTrackerStatsByMonth />}
      <CustomSlidingPanel
        className="onboarding-panel mid-height-panel"
        overlayClassName="sliding-common-wrapper usage-tracker-overlay"
        isOpen={isDeviceSelectionOpen}
        from="bottom"
        backdropClose={false}
        hideHeader={true}
      >
        <ul className="devices-list">
          <li
            className={cx(
              "d-flex align-items-center justify-content-between all-devices",
              {
                selected: selectedDevice === -1,
              }
            )}
            onClick={() => saveSelection(-1)}
          >
            <div className="device">
              <DeviceIconGroup
                devices={devices}
                compact={true}
                showDeviceCount={true}
              />
              <div className="device-name">
                {dictionary.UT_ALL_DEVICES_LABEL || "ALL DEVICES"}
              </div>
            </div>
            <div className="bullet" />
          </li>
          {devices.map((device, index) => (
            <li
              key={index}
              className={cx(
                "d-flex align-items-center justify-content-between",
                { selected: selectedDevice === index }
              )}
              onClick={() => saveSelection(index)}
            >
              <div className="device">
                <DeviceIcon device={device} />
                <div className="device-name">
                  {getDeviceName(device)}
                  {selectedDevice === -1 && (
                    <div className="device-number">Device {index + 1}</div>
                  )}
                </div>
              </div>
              <div className="bullet" />
            </li>
          ))}
        </ul>
      </CustomSlidingPanel>
      <CustomSlidingPanel
        className="onboarding-panel mid-height-panel"
        overlayClassName="sliding-common-wrapper"
        isOpen={showDisclaimer}
        from="bottom"
        backdropClose={false}
        title={dictionary.UT_PUFF_COUNTS_TITLE}
      >
        <div className="h-100 d-flex flex-column">
          <div className="sliding-panel-mid">
            <div className="sliding-panel-mid-icon">
              <div className="usage-tracker-stats-icon">
                <span className="bat-icon-usage" />
              </div>
            </div>
            <div className="sliding-panel-mid-info">
              {dictionary.UT_PUFF_COUNTS_CONTENT}
            </div>
            <div
              className="sliding-panel-mid-info mx-auto"
              style={{ maxWidth: "10.625rem" }}
            >
              {dictionary.UT_PUFF_COUNTS_LINK}
            </div>
          </div>
          <PageSelectorCTA sticky={false} className="mt-auto">
            <div className="d-grid">
              <button
                className="btn btn-primary text-uppercase"
                type="button"
                onClick={() => {
                  logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NEXT})
                  localStorage.removeItem('puff_data_screenview')
                  setShowDisclaimer(false);
                  // Clear location state to prevent the disclaimer appears on page reload.
                  window.history.replaceState({}, document.title);
                  logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
                    screen_name: AnalyticsScreenEventNames.USAGE_TRACKER_DAY,
                  })
                }}
              >
                {dictionary.UT_BTN_CONTINUE_0_ctaLabel}
              </button>
            </div>
          </PageSelectorCTA>
        </div>
      </CustomSlidingPanel>
      <div className="page-linear-gradient-bg" />
    </div>
  );
}

const mapStateToProps = (state) => ({
  devices: state?.deviceReducer?.devices,
  selectedDevice: state?.dashboardReducer?.usageTracker?.selectedDevice,
  userPin: state?.onboardingReducer?.userPin,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedDevice: (device) =>
    dispatch(dashboardActions.setUsageTrackerSelectedDevice(device)),
  getThings: (tenantUserId, uuid, vendor, successCb, failureCb) => {
    dispatch(
      commonsActions.getThings(tenantUserId, uuid, vendor, successCb, failureCb)
    );
  },
  setUserSettings: (settingName, value, successCb, failureCb) => {
    dispatch(
      userActions.setUserSettings(settingName, value, successCb, failureCb)
    );
  },
});

const component = connect(mapStateToProps, mapDispatchToProps)(UsageTracker);
export { component as UsageTracker };
