import React, { useEffect } from 'react';
import { AnalyticsEvents, AnalyticsFieldNames, flagStatus } from '../../_constants';
import parse from 'html-react-parser';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';

export const NotificationOptions = (props) => {
    const {
        options = {},
        preferences = [],
        getAEMComponent,
        updatePreference
    } = props;

    // useEffect(() => {
    //     console.log(options);
    // }, [options])

    // useEffect(() => {
    //     console.log(preferences);
    // }, [preferences])

    const optionKeys = Object.keys(options);

    const logToggleAnalytics = (value, name) => {
        var fieldName;
        console.log('analytics;', name)
        switch(true){
            case (name === "NOTIF_DEVICE_ERROR_TITLE"):
                fieldName = AnalyticsFieldNames.DEVICE_ERRORS;
                break;
            case (name === "NOTIF_DEVICE_CONNECTION_TITLE"):
                fieldName = AnalyticsFieldNames.DEVICE_STATUS;
                break;
            case (name === "NOTIF_FW_UPDATE_TITLE"):
                fieldName = AnalyticsFieldNames.FIRMWARE_UPDATE;
                break;
            case (name === "NOTIF_SUGGESTIONS_TITLE"):
                fieldName = AnalyticsFieldNames.SUGGESTIONS;
                break;
            default:
                break;
        }

        logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {
            field_name: fieldName,
            field_value: value ? "true" : "false"
          });
            
      };

    return (
        <React.Fragment>
            {
                optionKeys.map(optionKey => {
                    const optionValues = options[optionKey].filter(optionValue => getAEMComponent(optionValue.label) !== null);

                    return (
                        <React.Fragment key={`cat-${optionKey}`} >
                            <span className="notification-preferences-label">{getAEMComponent(optionKey)?.getRaw('text', null)}</span>
                            {
                                optionValues.map(optionValue => {
                                    console.log('analytics:', getAEMComponent(optionValue.label)?.getRaw('text', null))

                                    const checked = preferences.find(pref => optionValue.command === pref.type)?.data === flagStatus.ACCEPTED ?? flagStatus.REFUSED;
                                    
                                    return (
                                        <div key={`notif-${optionKey}-${optionValue.label}`} className="notification-preferences-box">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-9">
                                                        <div className="notification-preferences-text">
                                                            <strong>{getAEMComponent(optionValue.label)?.getRaw('text', null)}</strong><br />
                                                            {parse(getAEMComponent(optionValue.description)?.getRaw('text', null))}
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                                                checked={checked}
                                                                onChange={(e) => {updatePreference(optionValue.command, e.target.checked); logToggleAnalytics(e.target.checked, optionValue.label)}} />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </React.Fragment>
                    )
                })
            }
        </React.Fragment>
    )

}