import React, { useState } from "react";
import { useEffect } from "react";
import { getDeviceFamilyInfo } from "../../_actions/appConfig.actions";
import {
    imagesConstants, SKIN_COLOR_BLACK,
    SKIN_COLOR_BLUE, SKIN_COLOR_CYAN, SKIN_COLOR_PINK,
    SKIN_COLOR_RED, SKIN_COLOR_GOLD, SKIN_COLOR_REFLEKTA
} from '../../_constants';
import { getColorSelectionImg } from "../../_helpers/device/assets";

export const DeviceCustomColor = ({ setDeviceColor, deviceType, deviceColor, title, disabled }) => {

    const [colors, setColors] = useState([]);

    useEffect(() => {
        if (deviceType) {
            const deviceFamily = getDeviceFamilyInfo(deviceType);

            if (deviceFamily) {
                console.debug(deviceType, 'deviceFamily.device_colors', deviceFamily.device_colors);
                setColors(deviceFamily.device_colors);
            }
        }
    }, [deviceType])

    return (
        <React.Fragment>
            <label className="page-label">{title}</label>
            <div className="device-settings-color-wrapper">
                <div className="row">

                    {
                        colors.map((color, index) => <div key={`color-${index}`} className="col" onClick={() => (!disabled) ? setDeviceColor(color) : null}>
                            <div className={`device-settings-color ${deviceColor === color ? 'active' : ''}`}
                                style={{
                                    // backgroundImage: `url(${imagesConstants[color.img + `${deviceColor === color.skin ? "_ACTIVE" : "_DISACTIVE"}`]})`,
                                    backgroundImage: `url(${getColorSelectionImg(deviceType, color, deviceColor === color)})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}></div>
                        </div>
                        )

                    }

                </div>
            </div>
        </React.Fragment>
    );

};
