import React, { useState, useEffect } from 'react';
import { CustomInlineAlert, FadingList, PageSelectorCTA } from '..';
import { AnalyticsEvents, AnalyticsFieldNames, AnalyticsTargetEventsNames, routingConstants } from '../../_constants';
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';
import { useForm } from "react-hook-form";
import { setDoneVault } from '../../_actions';
import { CustomLoadingButton } from '../CustomLoadingButton';
import { store } from '../../_store';
import { VaultHelper } from '../../_helpers/vault';
import { isFunction } from 'lodash';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';

export const VaultRestoreSecureQuestion = (props) => {
    const {
        dictionary,
        setIsCreatingNewPin,
        isLoading
    } = props;

    const [isValidAnswer, setIsValidAnswer] = useState(null);
    const { register, handleSubmit, watch } = useForm();
    const { answer } = watch();

    const [selectedQuestion, setSelectedQuestion] = useState(null);

    const validateSecurityQuestion = () => {

        //double check on form valid
        if(isFormValid()){
            //ask parent to validate vault infos
            props.validateSecurityQuestion({
                question: selectedQuestion,
                response: answer
            });
        }
    }

    const goToVaultSetup = () => {
        logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.SECURITY_QUESTION_ANSWER})
        setDoneVault(false);
        setIsCreatingNewPin(true);
        history.push(buildURI(routingConstants.VAULT));
    }

    const isFormValid = () => {

        // console.log("[VaultRestoreSecureQuestion] isFormValid", answer != null && answer !== undefined && answer !== "" && props.isValidData !== false);
        // console.log("[VaultRestoreSecureQuestion] answer", answer);
        // console.log("[VaultRestoreSecureQuestion] props.isValidData ", props.isValidData );
        return answer != null && answer !== undefined && answer !== "";
    }

    /*
    * Handle when to move to the next step or show validation error on UI
    */
    useEffect(() => {

        // console.log("[VAULT RESTORE] props.isValidData", props.isValidData);

        if(props.isValidData !== null){
            setIsValidAnswer(props.isValidData);
            if (props.isValidData) {
                logAnalyticsEvent(AnalyticsEvents.VAULT_SECURITY_QUESTION_SUCCESS)
                props.nextStep();
            } else {
                logAnalyticsEvent(AnalyticsEvents.VAULT_SECURITY_QUESTION_FAILED)
            }
        }
    }, [props.isValidData]);

    const handleBlur = () => {
        logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.SECURITY_QUESTION_ANSWER})   
    }

    return (
        <form onSubmit={handleSubmit(validateSecurityQuestion)} 
        className="h-100 d-grid">
            <div className="vault-security-question mt-4">
                <div className="page-label">{dictionary.VAULT_RESTORE_SEC_ANSWER_LABEL}</div>
                
                <div className="d-grid mb-3">
                    <FadingList
                        items={VaultHelper.getSecurityQuestionItemsBy(dictionary)}
                        selectedItem={selectedQuestion}
                        highlightSelected={false}
                        handleClick={(question) => { setSelectedQuestion(question === selectedQuestion ? null : question) }}
                    />
                    {selectedQuestion != null &&
                         <input name="answer"
                         className={`form-control ${isValidAnswer === false && "error"}`}
                         type="text"
                         style={{
                            zIndex: 1
                        }}
                         ref={register()}
                         onChange={() => setIsValidAnswer(null)}
                         onBlur={handleBlur}
                        />
                    }
                </div>
                <CustomInlineAlert isShown={isValidAnswer === false} alertType="error">
                    <div>
                        {dictionary.VAULT_RESTORE_PIN_FAILURE}
                    </div>
                </CustomInlineAlert>
            </div>
            <PageSelectorCTA>
                <div className="d-grid">
                    <CustomLoadingButton isLoading={isLoading}
                        validationMethod={isFormValid()}
                        buttonLabel={dictionary.VAULT_SUCCESS_BTN_CONTINUE_0_ctaLabel} 
                        onClickMethod={() => logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NEXT})} />
                </div>
                <div className="page-selector-cta-url">
                    <span className="url-text" onClick={goToVaultSetup}>{dictionary.VAULT_RESTORE_LINK_NEW_CREATE}</span>
                </div>
            </PageSelectorCTA>
        </form>
    );
}