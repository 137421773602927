
import { thingVuseProperties, shopSortingOptions } from '../../_constants';
import { store } from '../../_store';
import { Commons } from '../commons';
import _ from 'lodash';

const subscriptionTier = {
    STANDARD: 'STANDARD',
    EXTRA: 'EXTRA',
    PREMIUM: 'PREMIUM',
}

export class ShopUtils {

    /**
     * Given product id and property name, return property value.
     * @param {String} id 
     * @param {String} property 
     * @returns 
     */
    static getProductPropertyFromId(id, property) {

        let thisProperty = null;
        const catalog = store.getState().shopReducer.catalog?.products;

        if (catalog?.length > 0) {
            let product = catalog.find(product => product.id === id);
            if (product !== null && product !== undefined) {
                return product[property] ?? null;
            }
        }

        return thisProperty;
    }

    /**
     * Given product id, return corresponding product from catalog.
     * @param {String} id 
     * @returns 
     */
    static getProductFromId(id) {

        const catalog = store.getState().shopReducer.catalog?.products;

        if (catalog?.length > 0) {
            let product = catalog.find(product => product.id === id);

            if (product !== null && product !== undefined) {
                return product ?? null;
            }
        }

        return null;
    }

    /**
     * Given variant sku, return variant from catalog.
     * @param {String} sku 
     * @returns 
     */
    static getVariantFromSku(sku) {

        const catalog = store.getState().shopReducer.catalog?.products;

        if (catalog?.length > 0) {
            let variants = _.compact(
                catalog.map(product => {
                    if(product.variants){
                        let variant = product.variants.find(variant => variant.sku === sku);
                        if(variant){
                            return {
                                ...variant, 
                                id: product.id, //adding product id to variant returned
                                price: product.price
                            }
                        }
                        return null;
                    }
                    return null;
                })
            );

            return variants[0] ? variants[0] : null;
        }

        return null;
    }

    static sortListFromOption(sortingOption, list, property) {
        // console.log('[sortListFromOption] sortingOption', sortingOption);
        // console.log('[sortListFromOption] list', list);

        switch (sortingOption) {
            case shopSortingOptions.SORTING_LOW2HIGH: {
                return [...Commons.sortArrayNumerically(list, "price")];
            }
            case shopSortingOptions.SORTING_HIGH2LOW: {
                return [...Commons.sortArrayNumerically(list, "price", true)];
            }
            case shopSortingOptions.SORTING_A2Z: {
                return [...Commons.sortArrayAlphabetically(list, property ?? "title")];
            }
            case shopSortingOptions.SORTING_Z2A: {
                return [...Commons.sortArrayAlphabetically(list, property ?? "title", true)];
            }
            case shopSortingOptions.SORTING_DATE_ASC: {
                return [...Commons.sortArrayAlphabetically(list, "date")];
            }
            case shopSortingOptions.SORTING_DATE_DESC: {
                return [...Commons.sortArrayAlphabetically(list, "date", true)];
            }
            case shopSortingOptions.SORTING_LOW2HIGH_TOTAL: {
                return [...Commons.sortArrayNumerically(list, "total")];
            }
            case shopSortingOptions.SORTING_HIGH2LOW_TOTAL: {
                return [...Commons.sortArrayNumerically(list, "total", true)];
            }

            default:
                return list;
        }
    }

    static getUserWishlist() {
        return Commons.parseJSONSafely(
            Commons.getCustomerProperty(thingVuseProperties.FAVOURITES)
        )
    }

    static getSubscriptionTierFromQuantity(quantity) {
        if(quantity >=5 && quantity <= 9 ){
            return subscriptionTier.STANDARD;
        } else if(quantity >=10 && quantity <= 14 ){
            return subscriptionTier.EXTRA;
        } else if(quantity >=15 && quantity <= 30 ){
            return subscriptionTier.PREMIUM;
        }
        return null;

    }
}
