import React, { createContext, useContext } from "react";

export const FirmwareUpdateContext = createContext(null);

export function FirmwareUpdateProvider({ value, children }) {
  return (
    <FirmwareUpdateContext.Provider value={value}>
      {children}
    </FirmwareUpdateContext.Provider>
  );
}

export function useFWUpdateContext() {
  return useContext(FirmwareUpdateContext);
}
