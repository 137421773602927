import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SwipeableViews from "react-swipeable-views";

const styles = {
    tabs: {
        color: "#fff"
    },
    tabsIndicator: {
        backgroundColor: '#00c7b1',
        height: "4px",
    },
    tab: {
        backgroundColor: '#1d1d1b',
        opacity: 1
    }
};

export const SwipableViews = forwardRef((props, ref) => {

    const [index, setIndex] = useState(null);
    const [swipeableActions, setSwipeableActions] = useState(0);

    useEffect(() => {

        setIndex(props.activeViewIndex);
    }, [props.activeViewIndex]);

    useImperativeHandle(ref, () => ({

        updateHeight() {
            updateSwipeableViewHeight();
        }
    }));

    const updateSwipeableViewHeight = () => {
        if (swipeableActions) swipeableActions.updateHeight();
    };

    const handleChange = (event, value) => {
        if (typeof props.onChangeView === 'function') {
            props.onChangeView(value);
        }
        setIndex(value);
        window.scrollTo(0, 0);
    };

    const handleChangeIndex = (index) => {
        if (typeof props.onChangeView === 'function') {
            props.onChangeView(index);
        }
        updateSwipeableViewHeight();
        setIndex(index);
    };


    return (
        <div className="swipable-views">
            <Tabs
                value={index}
                variant="fullWidth"
                onChange={handleChange}
                style={styles.tabs}
                TabIndicatorProps={{
                    style: {
                        ...styles.tabsIndicator
                    },
                }}
            >
                {props.views && props.views.map((tabEl, idx) =>
                    <Tab key={idx}
                        label={tabEl ? tabEl.label : ""}
                        style={styles.tab}
                    />
                )}

            </Tabs>
            <SwipeableViews
                index={index}
                onChangeIndex={handleChangeIndex}
                action={actions => (setSwipeableActions(actions))}
                ref={ref}
                style={{ height: '100%' }}
                disabled={true}
            >
                {
                    props.children
                }
            </SwipeableViews>
        </div>
    );

})
