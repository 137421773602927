import React from 'react';
import { imagesConstants } from '../../_constants';
import { CustomLoadingButton } from '../CustomLoadingButton';
import { PageSelectorCTA } from '../../_components';

export const ErrorNotificationPermissionPanel = (props) => {
  const { dictionary, onContinue, onTryAgain, isContinueButtonLoading } = props;

  return (
    <div className="notification-permission-panel">
      <div>
        <span className="text">
          {dictionary.IOS_MKT_NOTIF_ERROR_BODY}
        </span>
      </div>
      <div>
        <img src={imagesConstants.NOTIFICATION} alt="news" />
      </div>
      <PageSelectorCTA sticky={false}>
        <div className="d-grid gap-2">
          <CustomLoadingButton
            isLoading={isContinueButtonLoading}
            validationMethod={null}
            buttonLabel={dictionary.IOS_MKT_NOTIF_ERROR_BTN_CONTINUE_0_ctaLabel}
            onClickMethod={() => onContinue()}
            buttonClassName="btn btn-primary text-uppercase"
          />
          <CustomLoadingButton
            isLoading={false}
            validationMethod={null}
            buttonLabel={dictionary.IOS_MKT_NOTIF_ERROR_BTN_TRY_AGAIN_0_ctaLabel}
            onClickMethod={() => onTryAgain()}
            buttonClassName="btn btn-outline-secondary text-uppercase"
          />
        </div>
      </PageSelectorCTA>
    </div>
  );
};
