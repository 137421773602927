import cx from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { connect } from "react-redux";
import {
  aemPages,
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  servicesConstants,
  thingVuseProperties,
} from "../../_constants";
import {
  AEMHelper,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { Commons } from "../../_helpers/commons";
import {
  getDailyAvgTotal,
  getDailyStats,
  getLastUpdate,
  getUsageTrackerOptInDate,
  isUsageTrackerSessionTipSeen,
} from "../../_helpers/usage-tracker";
import { UsageTrackerAvg } from "./UsageTrackerAvg";
import { UsageTrackerPreset } from "./UsageTrackerPreset";
import { UsageTrackerPuffsSlider } from "./UsageTrackerPuffsSlider";
import { UsageTrackerSession } from "./UsageTrackerSession";
import { isDeviceConnected } from "../../_helpers/device";
import { UsageTrackerTooltip } from "../UsageTrackerTooltip/UsageTrackerTooltip";
import { UsageTrackerStatsByDayChart } from "./UsageTrackerStatsByDayChart";
import { isSupportedService } from "../../_actions/appConfig.actions";

const MIN_BUBBLE_SIZE = 145;
const MAX_BUBBLE_SIZE = 255;

function UsageTrackerStatsByDay({
  selectedDevice,
  devices,
  dailyDate,
  setDailyDate,
  screenViewAnalytics,
}) {
  const [dictionary, setDictionary] = useState({});
  const [date, setDate] = useState(moment().toDate());
  const [isToday, setIsToday] = useState(true);
  const [isOptinDate, setIsOptinDate] = useState(true);
  const [puffsData, setPuffsData] = useState({});
  const [avgData, setAvgData] = useState({});
  const [lastRefreshDate, setLastRefreshDate] = useState(null);
  const [bubbleSize, setBubbleSize] = useState();
  const [sessionTipStates, setSessionTipStates] = useState({});
  const [isConnected, setIsConnected] = useState(false);

  const prev = () => {
    setDate(moment(date).subtract(1, "d").toDate());
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.DATE_BACK})
  };

  const next = () => {
    setDate(moment(date).add(1, "d").toDate());
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.DATE_NEXT})
  };

  const openSessionTip = (index) => {
    setSessionTipStates((prevState) => ({
      ...prevState,
      [index]: true,
    }));
    console.log('analytics', index)
    switch(true){
      case(index === 'TREND GRAPH EXPLAINED'):
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.USAGE_TREND});
        break;
      case(index === 'USAGE EXPLAINED'):
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.USAGE_HELP});
        break;
      case(index === 'SESSIONS EXPLAINED'):
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.SESSIONS_HELP});
        break;
      case(index === 'PRESET BREAKDOWN EXPLAINED'):
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.PRESET_HELP});
        break;
      default:
        break;
    }
  };
  
  const closeSessionTip = (index) => {
    setSessionTipStates((prevState) => ({
      ...prevState,
      [index]: false,
    }));
    switch(true){
      case(index === 'TREND GRAPH EXPLAINED'):
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.USAGE_TREND_CLOSE});
        break;
      case(index === 'USAGE EXPLAINED'):
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.USAGE_HELP_CLOSE});
        break;
      case(index === 'SESSIONS EXPLAINED'):
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.SESSIONS_HELP_CLOSE});
        break;
      case(index === 'PRESET BREAKDOWN EXPLAINED'):
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.PRESET_HELP_CLOSE});
        break;
      default:
        break;
    }
  };
  

  const getAvgPuffCount = () => {
    if (puffsData?.total && puffsData?.totalSessions) {
      return Math.round(puffsData?.total / puffsData?.totalSessions);
    }

    return 0;
  };

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.USAGE_TRACKER, {
        UT_TODAY_LABEL: propertyTextDefaultEmpty,
        UT_PUFFS_LABEL: propertyTextDefaultEmpty,
        UT_LAST_REFRESH_LABEL: propertyTextDefaultEmpty,
        UT_CC_PRESET_BREAKDOWN_LABEL: propertyTextDefaultEmpty,
        UT_LOW_LABEL: propertyTextDefaultEmpty,
        UT_MED_LABEL: propertyTextDefaultEmpty,
        UT_HIGH_LABEL: propertyTextDefaultEmpty,
        UT_TODAY_USAGE_LABEL: propertyTextDefaultEmpty,
        UT_VS_LABEL: propertyTextDefaultEmpty,
        UT_DAILY_AVG_PUFFS_LABEL: propertyTextDefaultEmpty,
        UT_ALL_TIME_DAILY_AVG_LABEL: propertyTextDefaultEmpty,
        UT_TODAY_SESSIONS_LABEL: propertyTextDefaultEmpty,
        UT_SESSION_COUNT_LABEL: propertyTextDefaultEmpty,
        UT_AVG_PUFF_COUNT_LABEL: propertyTextDefaultEmpty,
        UT_SESSION_EXPLAINED_TITLE: propertyTextDefaultEmpty,
        UT_SESSION_EXPLAINED_CONTENT: propertyTextDefaultEmpty,
        UT_TREND_GRAPH_TITLE: propertyTextDefaultEmpty,
        UT_TREND_GRAPH_CONTEN: propertyTextDefaultEmpty,
        UT_USAGE_EXPLAINED_TITLE: propertyTextDefaultEmpty,
        UT_USAGE_EXPLAINED_CONTENT: propertyTextDefaultEmpty,
        UT_PRESET_BREAKDOWN_TITLE: propertyTextDefaultEmpty,
        UT_PRESET_BREAKDOWN_CONTENT: propertyTextDefaultEmpty,
        UT_USAGE_TREND_TITLE: propertyTextDefaultEmpty,
        UT_TREND_GRAPH_CONTENT: propertyTextDefaultEmpty
      })
    );
    let puff_info  = localStorage.getItem('puff_data_screenview')
    if(!puff_info){
      logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
        screen_name: AnalyticsScreenEventNames.USAGE_TRACKER_DAY,
      })
    }

    return () => {
      if (window?.dailyQuery) {
        window.dailyQuery.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (dailyDate) {
      setDate(dailyDate);
      setDailyDate(null);
    }
  }, [dailyDate, setDailyDate]);

  useEffect(() => {
    const usageTrackerOptinDate = getUsageTrackerOptInDate();

    setIsToday(moment(date).isSame(new Date(), "day"));
    setIsOptinDate(moment(date).isSame(usageTrackerOptinDate, "day"));
  }, [date]);

  useEffect(() => {
    if (!isToday) {
      if (window?.dailyQuery) {
        window.dailyQuery.unsubscribe();
      }
    }
  }, [isToday]);

  useEffect(() => {
    const device = devices[selectedDevice];

    getDailyStats(device?.serialNumber, date, (res) => {
      console.debug("UT - GET DAILY STATS", res);

      let total = res.reduce((ret, object) => {
        return ret + (object.total || 0);
      }, 0);

      let totalLow = res.reduce((ret, object) => {
        return ret + (object.low || 0);
      }, 0);
      let totalMed = res.reduce((ret, object) => {
        return ret + (object.med || 0);
      }, 0);
      let totalHigh = res.reduce((ret, object) => {
        return ret + (object.high || 0);
      }, 0);

      let totalSessions = res.reduce((ret, object) => {
        return ret + (object.sessions || 0);
      }, 0);

      setPuffsData({
        total,
        totalLow,
        totalMed,
        totalHigh,
        totalSessions,
      });
    });
  }, [date, devices, selectedDevice]);

  useEffect(() => {
    const device = devices[selectedDevice];

    getDailyAvgTotal(device?.serialNumber, (dailyPuffAvg, dailySessionAvg) => {
      console.debug("MEDIE", dailyPuffAvg, dailySessionAvg);
      setAvgData({
        dailyPuffAvg,
        dailySessionAvg,
      });
    });

    getLastUpdate(device?.serialNumber, (date) => {
      date && setLastRefreshDate(date);
    });

    setIsConnected(isDeviceConnected(devices[selectedDevice]));
  }, [selectedDevice, devices]);

  useEffect(() => {
    const usageTrackerOptinDate = getUsageTrackerOptInDate();

    if (moment().isSame(usageTrackerOptinDate, "day")) {
      return setBubbleSize(MIN_BUBBLE_SIZE);
    }

    if (puffsData?.total && avgData?.dailyPuffAvg) {
      let value = (puffsData?.total * MAX_BUBBLE_SIZE) / avgData?.dailyPuffAvg;

      if (value < MIN_BUBBLE_SIZE) {
        value = MIN_BUBBLE_SIZE;
      } else if (value > MAX_BUBBLE_SIZE) {
        value = MAX_BUBBLE_SIZE;
      }

      setBubbleSize(Math.round(value));
    }
  }, [puffsData, avgData, isToday]);

  return (
    <>
      <UsageTrackerPuffsSlider
        title={
          isToday
            ? dictionary.UT_TODAY_LABEL || "TODAY"
            : moment(date).format("ddd")
        }
        subtitle={moment(date).format("ll")}
        hideNavigationLeft={isOptinDate}
        hideNavigationRight={isToday}
        onPrev={prev}
        onNext={next}
      >
        <div
          className={cx("usage-tracker-bubble", {
            "no-puffs": !puffsData?.total,
            disconnected: !!puffsData?.total && !isConnected && selectedDevice !== -1,
          })}
          {...(puffsData?.total && {
            style: {
              width: bubbleSize + "px",
              height: bubbleSize + "px",
            },
          })}
        >
          <div>
            <div className="usage-tracker-puff-count">
              {puffsData?.total || 0}
            </div>
            <div className="usage-tracker-puff-label">
              {dictionary.UT_PUFFS_LABEL || "PUFFS"}
            </div>
          </div>
        </div>
      </UsageTrackerPuffsSlider>

      <div className="usage-tracker-section comparison">
        {isSupportedService(servicesConstants.USAGE_TRACKER_HOURLY_GRAPH) &&
        <>
        <div className="usage-tracker-section-title d-flex align-items-center justify-content-between">
          <div>
            <span>
              {dictionary.UT_USAGE_TREND_TITLE || "TODAY’S USAGE TREND"}
            </span>
          </div>
          <div><span className="bat-icon-info info-icon" onClick={() => openSessionTip(dictionary.UT_TREND_GRAPH_TITLE)} /></div>
        </div>
        <UsageTrackerTooltip isOpen={sessionTipStates[dictionary.UT_TREND_GRAPH_TITLE]} title={dictionary.UT_TREND_GRAPH_TITLE} content={dictionary.UT_TREND_GRAPH_CONTENT} closeToolTip={() => closeSessionTip(dictionary.UT_TREND_GRAPH_TITLE)} />
        <div className="usage-tracker-section-content">
          <UsageTrackerStatsByDayChart device={devices[selectedDevice]} date={date} />
        </div>
        </>
        }
        <div className="usage-tracker-section-title d-flex align-items-center justify-content-between">
          <div>
            <span>{dictionary.UT_TODAY_USAGE_LABEL || "TODAY'S USAGE"}</span>{" "}
            <span>{dictionary.UT_VS_LABEL || "vs"}</span>{" "}
            <span>
              {dictionary.UT_DAILY_AVG_PUFFS_LABEL || "DAILY AVERAGE (PUFFS)"}
            </span>
          </div>
          <div><span className="bat-icon-info info-icon" onClick={() => openSessionTip(dictionary.UT_USAGE_EXPLAINED_TITLE)} /></div>
        </div>
        <UsageTrackerTooltip isOpen={sessionTipStates[dictionary.UT_USAGE_EXPLAINED_TITLE]} title={dictionary.UT_USAGE_EXPLAINED_TITLE} content={dictionary.UT_USAGE_EXPLAINED_CONTENT} closeToolTip={() => closeSessionTip(dictionary.UT_USAGE_EXPLAINED_TITLE)} />
        <div className="usage-tracker-section-content">
          <UsageTrackerAvg
            label={dictionary.UT_ALL_TIME_DAILY_AVG_LABEL || "DAILY AVG."}
            value={puffsData?.total}
            avg={
              moment().isSame(getUsageTrackerOptInDate(), "day")
                ? null
                : avgData?.dailyPuffAvg
            }
          />
        </div>
      </div>
      <div className="usage-tracker-section sessions">
        <div className="usage-tracker-section-title d-flex align-items-center justify-content-between">
          {dictionary.UT_TODAY_SESSIONS_LABEL || "TODAY'S SESSIONS"}
          <span className="bat-icon-info info-icon" onClick={() => openSessionTip(dictionary.UT_SESSION_EXPLAINED_TITLE)} />
        </div>
        <UsageTrackerTooltip isOpen={sessionTipStates[dictionary.UT_SESSION_EXPLAINED_TITLE]} title={dictionary.UT_SESSION_EXPLAINED_TITLE} content={dictionary.UT_SESSION_EXPLAINED_CONTENT} closeToolTip={() => closeSessionTip(dictionary.UT_SESSION_EXPLAINED_TITLE)} />
        <div className="usage-tracker-section-content">
          <div className="d-flex align-items-center justify-content-between gap-2">
            <UsageTrackerSession
              label={dictionary.UT_SESSION_COUNT_LABEL || "SESSION COUNT"}
              value={puffsData?.totalSessions ?? 0}
            />
            <UsageTrackerSession
              label={dictionary.UT_AVG_PUFF_COUNT_LABEL || "AVG. PUFF COUNT"}
              value={getAvgPuffCount()}
            />
          </div>
        </div>
      </div>
      <div className="usage-tracker-section">
        <div className="usage-tracker-section-title d-flex align-items-center justify-content-between">
          {dictionary.UT_CC_PRESET_BREAKDOWN_LABEL ||
            "CLOUD CONTROL PRESET BREAKDOWN"}{" "}
            <span className="bat-icon-info info-icon" onClick={() => openSessionTip(dictionary.UT_PRESET_BREAKDOWN_TITLE)} />
        </div>
        <UsageTrackerTooltip isOpen={sessionTipStates[dictionary.UT_PRESET_BREAKDOWN_TITLE]} title={dictionary.UT_PRESET_BREAKDOWN_TITLE} content={dictionary.UT_PRESET_BREAKDOWN_CONTENT} closeToolTip={() => closeSessionTip(dictionary.UT_PRESET_BREAKDOWN_TITLE)} />
        <div className="usage-tracker-section-content">
          <div className="d-flex flex-column gap-2">
            <UsageTrackerPreset
              label={dictionary.UT_LOW_LABEL || "LOW"}
              value={Commons.getPercentage(puffsData.totalLow, puffsData.total)}
            />
            <UsageTrackerPreset
              label={dictionary.UT_MED_LABEL || "MED"}
              value={Commons.getPercentage(puffsData.totalMed, puffsData.total)}
            />
            <UsageTrackerPreset
              label={dictionary.UT_HIGH_LABEL || "HIGH"}
              value={Commons.getPercentage(
                puffsData.totalHigh,
                puffsData.total
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  devices: state?.deviceReducer?.devices,
  selectedDevice: state?.dashboardReducer?.usageTracker?.selectedDevice,
});

const component = connect(mapStateToProps)(UsageTrackerStatsByDay);
export { component as UsageTrackerStatsByDay };
