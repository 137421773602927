import { getVuseUuid } from "../_actions";
import { CUSTOMER, VUSE_DEVICE } from "../_constants";

class RuleModel {

    name = "";
    group_id = "";
    description = "";
    is_enabled = true;
    calendars = [];
    conditions = [];
    commands = [];


    constructor(name, group_id) {
        this.name = name;
        this.group_id = group_id;
    }

    setName = (name) => {
        this.name = name;
    }

    setDescription = (description) => {
        this.description = description;
    }

    setEnabled = (isEnabled) => {
        this.is_enabled = isEnabled;
    }

    setGroupId = (group_id) => {
        this.group_id = group_id;
    }

    setDeviceCommand = (uuid, property_data, property_type) => {
        this.commands.push({
            uuid,
            property_data,
            property_type,
            vendor: VUSE_DEVICE
        });
    }

    setCustomerCommand = (property_data, property_type) => {
        this.commands.push({
            uuid: getVuseUuid(),
            property_data,
            property_type,
            vendor: CUSTOMER
        });
    }

    setCustomerCommandForNotification = (property_data) => {
        this.commands.push({
            uuid: getVuseUuid(),
            property_data,
            property_type: "SEND_TIP",
            vendor: CUSTOMER
        });
    }

    setCustomerCommandForOrderReminder = (property_data) => {
        this.commands.push({
            uuid: getVuseUuid(),
            property_data,
            property_type: "SEND_TIP",
            vendor: CUSTOMER
        });
    }

    setFullCalendar = (momentObj) => {

        //momentObj = moment().add(1, 'm'); //only fror dev purpose
        momentObj.utc();

        let minute = this.round5(momentObj.minute());
        let hour = momentObj.hour();
        if (minute >= 60) {
            minute = minute - 60;
            hour = hour + 1;
        }

        this.calendars.push({
            minute: this.formatCalendarProperty(minute),
            hour: this.formatCalendarProperty(hour),
            day: this.formatCalendarProperty(momentObj.date()),
            month: this.formatCalendarProperty((momentObj.month() + 1)),
            year: this.formatCalendarProperty(momentObj.year()),
            week_days: ["*"]
        });
    }

    setCalendar = (minute, hour, day, month, year, weekday, frequency) => {
        this.calendars.push({
            minute: this.formatCalendarProperty(minute) ?? "*",
            hour: this.formatCalendarProperty(hour) ?? "*",
            day: this.formatCalendarProperty(day) ?? "*",
            month: this.formatCalendarProperty(month) ?? "*",
            year: this.formatCalendarProperty(year) ?? "*",
            week_days: weekday ?? ["*"],
            ...(frequency && { frequency: frequency })
        });
    }

    /**
     * Utility method to round numbers to 5. 
     * Currently used for minutes, because Rule Engine supports only minutes % 5 = 0
     * 
     * @param {*} x number to be rounded to the nearest multiplier of 5
     * @returns 
     */
    round5 = (x) => {
        return Math.ceil(x / 5) * 5;
    }

    formatCalendarProperty = (property) => {
        return property !== null && property !== undefined ? property.toString().padStart(2, '0') : null;
    }

    payload = () => {

        return (
            {
                "name": this.name,  //univoca
                "group_id": this.group_id,
                "description": this.description, //json
                "is_enabled": this.is_enabled,
                "calendars": this.calendars,
                "conditions": this.conditions,
                "commands": this.commands
            }

        );
    }

}

export { RuleModel };