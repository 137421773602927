import { isNil, isNull } from "lodash";
import { getAnalyticsConfigBy } from "../../_config/analytics";
import {
  AnalyticsPlatforms,
  AnalyticsEvents,
  AnalyticsStatusNames,
  THINGS_2_ANALYTICS_PREFERENCE_MAP,
} from "../../_constants";
import environmentConstants from "../../_constants/environment/environment.constants";
import { AnalyticsEventModel } from "../../_models/analyticsEvent.model";
import { store } from "../../_store";
import sdk from "../../_vendors/nodes";
import { debugAnalytics } from "../debug";
import { isDeviceConnected, isSynchronized } from "../device";
import { ShopUtils } from "../shop";
import { isNotAnEmptyObject } from "../utils";
import {
  getDeviceSpec,
  getCountry,
} from "../../_actions/appConfig.actions";
import { getCustomerId } from "../../_actions/appData.actions";
import { getTruncateAppVersion } from '../../_helpers/utils';
import { Commons } from "../../_helpers/commons";
import BrowserHelper from '../../_helpers/browser/browser.helper';

export function logThingsInAnalyticsEvent(event_name, things = []) {
  // const preferences = extractPreferencesFromThings(things);
  // logAnalyticsEvent(event_name, preferences, preferences);
}

export function updateThingsInUserPropertiesAnalyticsEvents(things = []) {
  //const preferences = extractPreferencesFromThings(things);
  //const eventModel = AnalyticsEventModel.getInstance();
  //eventModel.setUserProperties(preferences);
}

function extractPreferencesFromThings(things = []) {
  return things
    .filter(
      (preference) =>
        "type" in preference &&
        "data" in preference &&
        !!getAnalyticsPreferenceFrom(preference.type)
    )
    .reduce((acc, preference) => {
      const key = getAnalyticsPreferenceFrom(preference.type);
      const value =
        typeof preference.data === "object" &&
        !!preference.data._isAMomentObject
          ? preference.data.format("hh:mm A")
          : getAnalyticStatusBy(preference.data);
      return {
        ...acc,
        [key]: value,
      };
    }, {});
}

export function logScreenViewAnalyticsEventBy(location = {}) {
  const { event_params, user_params, platforms } = getAnalyticsConfigBy(
    AnalyticsEvents.SCREEN_VIEW,
    location.pathname
  );
  if (isNotAnEmptyObject(event_params)) {
    logAnalyticsEvent(
      AnalyticsEvents.SCREEN_VIEW,
      event_params,
      user_params,
      platforms
    );
  }
}

export function logAnalyticsEventByCustomEvent() {
  window.document.dispatchEvent(
    new CustomEvent("logAnalytics", { detail: arguments })
  );
}

export async function logAnalyticsEvent(
  eventName,
  eventProperties = {},
  userProperties = {},
  platforms = []
) {
  const analyticsPlatforms =
    platforms.length > 0 ? platforms : [AnalyticsPlatforms.GOOGLE];
  const { devices = [] } = store.getState().deviceReducer;
  let selectedDevice;
  let deviceIndex = localStorage.getItem('analytics_device_index') || 0
  let deviceProperties;
  let eventPropertiesEnanced;

  if (selectedDevice == null || selectedDevice === undefined) {
    selectedDevice = devices[deviceIndex];
  }

  if (devices.length > 0) {
    const selected = devices.find((device) => device.selected) || devices[0];

    // if (!isNil(selected)) {
    //   deviceProperties = getAnalyticsDeviceFrom(selected);
    // }
  }

  if(eventName === "screen_view"){
    console.log('[screen_view]', eventProperties?.screen_name)
    if(!isNil(eventProperties?.screen_name)){
      localStorage.setItem('screen_view', eventProperties?.screen_name)
      let screenView = localStorage.getItem('screen_view')
    }
    // if(eventProperties?.screen_name !== screenView){
    //   localStorage.setItem('screen_view', eventProperties?.screen_name)
    // }
  }

  if (canAnalyticBeLogged(analyticsPlatforms)) {
    if(eventName !== "screen_view"){
      let screenView = localStorage.getItem('screen_view')
      eventPropertiesEnanced = {...eventProperties, screen_name: screenView}
    }else {
      eventPropertiesEnanced = {...eventProperties}
    }

    if(!isNil(selectedDevice)){
      eventPropertiesEnanced = {
        ...eventProperties,
        ...eventPropertiesEnanced,
        device_name: !isNil(selectedDevice.deviceCustomName) ? selectedDevice.deviceCustomName : selectedDevice.deviceInfo.deviceName, 
        device_hash: selectedDevice.serialNumber,
        device_firmware: selectedDevice.deviceInfo.firmwareVersion,
        device_hardware: selectedDevice.deviceInfo.boardIdentifier,
        device_family: selectedDevice.deviceType}
    }

    /** "User properties (not really, but it's the idea behind)" */
    /* Set optional user properties - now as event params */
    let userProperties = {
      'country': getCountry(),
      'language': Commons.getLang(),
      'userId': getCustomerId(),
      'app_version': getTruncateAppVersion(),
      'os_version': 'iOS',
      'browser_version': await BrowserHelper.getAgentInfo()?.agentInfo?.nuviu_version,
      'environment': process.env.REACT_APP_ENV_NAME === environmentConstants.PRODUCTION ? 'prod' : 'test'
    }

    eventPropertiesEnanced = {
      ...eventPropertiesEnanced,
      ...userProperties
    }

    console.log(
      `[ANALYTICS ${eventName}]`,
      "new event props: ",
      eventPropertiesEnanced,
      " new user props: ",
      userProperties
    );
    const eventModel = AnalyticsEventModel.getInstance();
    eventModel.setEvent(eventName, eventPropertiesEnanced, {
      //...(devices.length === 1 && { device_number: 1 }),
      //...deviceProperties
    });

    logAnalytics(analyticsPlatforms, eventModel.generateEvent());
  }
}

export function logAnalyticsEventForDevice(
  device,
  eventName,
  userProperties = {}
) {
  const { devices = [] } = store.getState().deviceReducer;
  const deviceIndex = devices.findIndex(
    (d) => d.serialNumber === device.serialNumber
  );

  const deviceProps = {
      //...getAnalyticsDeviceFrom(device),
      //total_device_count: devices.length,
      //device_number: deviceIndex === -1 ? deviceIndex : deviceIndex + 1,
      ...userProperties,
    };
    
  logAnalyticsEvent(eventName, deviceProps, deviceProps);
}

/**
 * Wrapping function to log analytic events
 * @param {*} platforms
 * @param {*} event
 */
export function logAnalytics(platforms = [], event = {}) {
  if (canAnalyticBeLogged(platforms)) {
    if (platforms.includes(AnalyticsPlatforms.GOOGLE)) {
      logGoogleAnalytics(event);
    }
  }
}

function canAnalyticBeLogged(platforms = []) {
  return (
    platforms.length > 0 && store.getState().onboardingReducer.acceptedCookies
  );
}

/**
 * LOG GOOGLE TAG MANAGER
 * @param {*} evt
 */
function logGoogleAnalytics(evt = {}) {
  const {
    event = { event_name: null, event_params: {} },
    user_properties = {},
  } = evt;

  window.dataLayer = window.dataLayer || [];

  const lastEventProperties =
    window.dataLayer.length > 0
      ? window.dataLayer[window.dataLayer.length - 1]
      : {};

  const clearEventProperties = Object.keys(lastEventProperties).reduce(
    (acc, key) => ({
      ...acc,
      [key]: undefined,
    }),
    {}
  );

  // push user properties and clear event properties from dataLayer
  pushIntoDataLayer({
    ...clearEventProperties,
    ...user_properties,
  });

  if (!!event.event_name) {
    // push event properties in dataLayer
    pushIntoDataLayer({
      event: event.event_name,
      ...event.event_params,
    });
  }
}

function pushIntoDataLayer(props) {
  debugAnalytics(props);
  window.dataLayer.push(props);
}

export function getAnalyticStatusBy(status) {
  if (typeof status === "boolean") {
    return status
      ? AnalyticsStatusNames.ENABLED
      : AnalyticsStatusNames.DISABLED;
  } else if (status === "accepted") {
    return AnalyticsStatusNames.ENABLED;
  } else if (status === "refused") {
    return AnalyticsStatusNames.DISABLED;
  } else {
    return status;
  }
}

export function getAnalyticsPreferenceFrom(thingKey = "") {
  return THINGS_2_ANALYTICS_PREFERENCE_MAP[thingKey];
}

export function getDefaultAnalyticPreferences() {
  const analyticDefault = {
    environment:
      process.env.REACT_APP_ENV_NAME === environmentConstants.PRODUCTION
        ? "prod"
        : "test",
  };

  return Object.values(THINGS_2_ANALYTICS_PREFERENCE_MAP).reduce(
    (acc, val) => ({
      ...acc,
      [val]: "null",
    }),
    analyticDefault
  );
}

export function getAnalyticsDeviceFrom(connectedDevice) {
  return {
    device_connected_status: `${
      isNil(connectedDevice) ? null : isDeviceConnected(connectedDevice)
    }`,
    device_lock_status: `${
      isNil(connectedDevice?.lockInfo?.locked)
        ? null
        : connectedDevice?.lockInfo?.locked
        ? "locked"
        : "unlocked"
    }`,
    device_led_level: `${connectedDevice?.ledInfo?.brightness ?? null}`,
    device_cloud_level: `${connectedDevice?.cloudInfo?.powerLevel ?? null}`,
    device_name: `${connectedDevice?.deviceCustomName ?? null}`,
    device_battery_level: `${
      connectedDevice?.batteryInfo?.chargeLevel ?? null
    }`,
    device_battery_saver: `${
      !isNil(connectedDevice?.cloudInfo?.batterySavingEnabled)
        ? getAnalyticStatusBy(!!connectedDevice.cloudInfo.batterySavingEnabled)
        : null
    }`,
    device_bs_threshold: `${
      connectedDevice?.cloudInfo?.batterySavingThresholdValue ?? null
    }`,
    device_bs_engaged: `${
      !isNil(connectedDevice?.cloudInfo?.batterySavingOn)
        ? getAnalyticStatusBy(!!connectedDevice.cloudInfo.batterySavingOn)
        : null
    }`,
  };
}

export function logAnalyticsEventOfVariantList(
  eventName,
  variantList,
  otherParams = {}
) {
  logAnalyticsEvent(eventName, {
    ...otherParams,
    items: getAnalyticsVariantItems(variantList),
  });
}

function getAnalyticsVariantItems(variantList = []) {
  return variantList.map(getAnalyticsVariantItem);
}

function getAnalyticsVariantItem(variant = {}, index = 0) {
  const otherProps = {};
  if ("item_strength" in variant && !!variant.item_strength) {
    otherProps.item_strength = variant.item_strength;
  } else if (
    "title" in variant &&
    !!variant.title &&
    isAFlavourStrength(variant.title)
  ) {
    otherProps.item_strength = variant.title;
  }

  if ("basket" in variant || "quantity" in variant) {
    otherProps.quantity = variant.basket || variant.quantity || 0;
  } else {
    otherProps.quantity = null;
  }

  if ("title" in variant && !!variant.title) {
    otherProps.item_list_name = variant.title;
  }

  return {
    item_name: variant.display_name,
    item_id: variant.product_id,
    price: variant.price,
    item_variant: variant.variant_id,
    currency: "CAD", // TODO make it dynamic
    discount: 0, // TODO make it dynamic
    index: index + 1,
    ...otherProps,
  };
}

export function logAnalyticsEventOfSubscription(
  eventName,
  subscriptionModel,
  otherParams = {}
) {
  const subscription_products = (subscriptionModel?.items ?? [])
    .filter((item) => !!item?.lineItemData?.variant?.product)
    .map((item, index) => {
      const variant = {
        ...item.lineItemData.variant,
        quantity: item.lineItemData.quantity,
        variant_id: item.lineItemData.variant.id,
        product_id: item.lineItemData.variant.product.id,
        display_name: item.lineItemData.variant.product.handle,
      };
      return getAnalyticsVariantItem(variant, index);
    });

  logAnalyticsEvent(eventName, {
    ...otherParams,
    subscription_type: ShopUtils.getSubscriptionTierFromQuantity(
      subscriptionModel.quantity
    ),
    subscription_products,
    subscription_quantity: subscriptionModel.quantity,
    subscription_frequency: subscriptionModel.frequencyInDays,
    subscription_total: subscriptionModel.total,
  });
}

export function isAFlavourStrength(strength) {
  return !!strength.match(/^\d+\.?\d* ?%/);
}
