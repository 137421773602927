
import React, { useState, useEffect} from 'react';
import {CustomAlert} from '../CustomAlert';
import { store } from '../../_store';
import { commonsActions } from '../../_actions';

export const ErrorAlert = (props) => {

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        store.subscribe(()=>handleErrors());
    }, []);

    const handleErrors = () => {
        if(isOpen === false){
            setIsOpen(store.getState().commonsReducer.isShownErrorAlert);
        }
    }

    return (
        
        <CustomAlert 
            hideOkButton={true} 
            isOpen={isOpen} 
            closeOnClickOutside={true}
            onClose={()=> {
                setIsOpen(false);
                store.dispatch(commonsActions.showErrorAlert(true));
            }
            }>
            Something went wrong. Please, try again in few minutes.
        </CustomAlert>
       
    );
}