import React from "react";
import cx from "classnames";
import { Utils } from "../../_helpers/graphic/utils";
import { AnalyticsEvents, AnalyticsTargetEventsNames } from "../../_constants";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";

const DeviceBatteryIcon = ({ chargeLevel, isDeviceConnected }) => {
  return (
    <div
      className={cx("device-battery-icon", {
        disconnected: !isDeviceConnected,
      })}
      onClick={() => logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.BATTERY})}
    >
      <div
        className="bat-icon-battery-h"
        style={{
          ...(isDeviceConnected && {
            color: Utils.getBatteryColorFromPercentage(chargeLevel),
          }),
        }}
      />
      <div className="container-battery-level">
        {isDeviceConnected ? chargeLevel : "--"}
      </div>
    </div>
  );
};

export { DeviceBatteryIcon };
