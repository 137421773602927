import React from "react";
import { connect } from "react-redux";
import { CustomMap } from "../../_components/CustomMap";
import { DevicePanel } from "../../_components/DevicePanel/DevicePanel";
import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";
import { deviceActions, userActions } from "../../_actions";
import { dashboardServices } from "../../_services";
import moment from "moment";
import { getDeviceInstanceFromSN, getDeviceName, isSynchronized } from "../../_helpers/device";
import { Utils } from "../../_helpers/graphic/utils";
import { Commons } from "../../_helpers/commons";
import {
  aemPages,
  AnalyticsEvents,
  flagStatus,
  imagesConstants,
  thingVuseProperties,
  routingConstants,
  crmUserSettings,
  deviceColorClass,
  AnalyticsTargetEventsNames,
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import {
  logAnalyticsEvent,
  logAnalyticsEventForDevice,
} from "../../_helpers/analytics/logAnalytics";
import { withLastLocation } from "react-router-last-location";
import cx from "classnames";
import { CustomSlidingPanel, DeviceDrawer, DeviceIcon, Header, PageSelectorCTA } from "../../_components";
import { isEmpty, isEqual } from "lodash";

class FindMyVape extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenDetailsPanel: true,
      myRef: null,
      resizeToMarker: false,
      currentDevice: null,
      locationsLabel: [],
      isPermissionDenied: false,
      isDeviceSelectionOpen: false,
      deviceIndex: 0,
      isOnline: true
    };

    this.headerRef = React.createRef();
    this.panelMinRef = React.createRef();
    this.myRef = React.createRef();
    this.carouselRef = React.createRef();

    this.goToTutorial = this._goToTutorial.bind(this);
    this.goToDashboard = this._goToDashboard.bind(this);
    this.goToLocationServices = this._goToLocationServices.bind(this);
    this.toggleBlinking = this._toggleBlinking.bind(this);
    this.getLastSyncedDate = this.getLastSyncedDate.bind(this);
    this.getLastSyncedLocation = this.getLastSyncedLocation.bind(this);
    this.saveSelect = this.saveSelect.bind(this)

    this.aem = new AEMHelper();
    this.dictionary = this.aem.getDictionary(aemPages.FIND_MY_VAPE, {
      FMV_OFFLINE_HEADER: propertyHeadingDefaultEmpty,
      FMW_LABEL_DEVICE_DISCONNECTED: propertyTextDefaultEmpty,
      FMV_LABEL_LAST_SYNC: propertyTextDefaultEmpty,
      FMV_BTN_PING: propertyCtaItemsDefaultEmpty,
      FMV_LABEL_TAB_LABEL_DEVICE: propertyTextDefaultEmpty,
      FMV_BLE_OFFLINE_TITLE: propertyTextDefaultEmpty,
      FMV_BLE_OFFLINE_BODY: propertyTextDefaultEmpty,
      FMV_HEADER: propertyHeadingDefaultEmpty,
      FMW_LABEL_DEVICE_CONNECTED: propertyTextDefaultEmpty,
      FMV_MAP_OFFLINE_CONTENT: propertyTextDefaultEmpty,
      FMV_MAP_OFFLINE_LEARN_HOW: propertyTextDefaultEmptyParsed,
      FMV_MAP_OFFLINE_SUBCONTENT: propertyTextDefaultEmpty,
      FMV_BTN_FOUND: propertyCtaItemsDefaultEmpty,
      FMV_MAP_OFFLINE_CONTENT: propertyTextDefaultEmptyParsed,
      FMV_MAP_OFFLINE_TITLE: propertyTextDefaultEmpty,
      FMV_UNAVAILABLE: propertyTextDefaultEmpty,
      FMV_CONNECT_BLE: propertyTextDefaultEmpty,
      FMV_TODAY_AT: propertyTextDefaultEmpty,
      FMV_OFFLINE_CONTENT: propertyTextDefaultEmpty
    });
  }

  componentDidMount() {
    document.body.classList.add("find-my-vape");

    //when not coming from optin check if optin is accepted
    //if not go back to optin
    if (
      this.props.lastLocation?.pathname !== routingConstants.FIND_MY_VAPE_OPTIN
    ) {
      const optedIn = Commons.getCustomerProperty(
        thingVuseProperties.OPT_IN_FINDMYVAPE
      );
      if (optedIn !== flagStatus.ACCEPTED) {
        history.push(buildURI(routingConstants.FIND_MY_VAPE_OPTIN));
      }
    }

    const isPermissionDenied =
      this.props.history.location.state?.isPermissionDenied;
    this.setState({ isPermissionDenied });
    window.history.replaceState({ isPermissionDenied: null }, "");

    //set initial value currentDevice
    const { devices } = this.props;
    let selectedDevice = devices.find((device) => device.selected);
    if (selectedDevice == null || selectedDevice === undefined) {
      selectedDevice = devices[0];
    }
    let selectedDeviceIndex = devices.findIndex((device) => device?.selected);
    if (selectedDeviceIndex === -1) {
      selectedDeviceIndex = 0
    }

    //if there isn't any device to show go back to homepage
    if (selectedDevice == null || selectedDevice === undefined) {
      history.push(buildURI(routingConstants.DASHBOARD));
    }

    // create array with formatted address for each device
    devices.forEach((device) => {
      this.updateLocationLabels(device)
    });

    this.setState({ currentDevice: selectedDevice, deviceIndex: selectedDeviceIndex });

    //detect online status
    if (navigator.onLine) {
      this.setState({ isOnline: true })
    } else {
      this.setState({ isOnline: false })
    }

    window.addEventListener('online', () => {
      this.setState({ isOnline: true })
    })
    window.addEventListener('offline', () => {
      this.setState({ isOnline: false })
    })
  }

  componentDidUpdate(){
    const { devices } = this.props;
    let selectedDevice = devices.find((device) => device.isBlinking);
    if (selectedDevice == null || selectedDevice === undefined) {
      selectedDevice = devices[this.state.deviceIndex];
    }

    if (!isEqual(selectedDevice, this.state.currentDevice)) {
      if (!isEqual(selectedDevice?.lastSyncedLocation, this.state.currentDevice?.lastSyncedLocation)) {
        this.updateLocationLabels(selectedDevice)
      }

      this.setState({ currentDevice: selectedDevice}, () => {
        console.log('Updated: ', this.state.currentDevice)
      });
    }
  }

  componentWillUnmount() {
    const { devices } = this.props;

    document.body.classList.remove("find-my-vape");

    devices.forEach((device) => this.toggleBlinking(0, device));
  }

  updateLocationLabels(device) {
    if (device.lastSyncedLocation) {
      //invoke Google API to get address based on coordinates
      dashboardServices
        .getLocationAddress(
          device.lastSyncedLocation.lat,
          device.lastSyncedLocation.lng
        )
        .then((response) => {
          if (response?.data?.status === "OK") {
            this.setState((prevState) => ({
              locationsLabel: [
                ...prevState.locationsLabel.filter((label) => label.serialNumber !== device.serialNumber),
                {
                  serialNumber: device.serialNumber,
                  formattedAddress:
                    response?.data?.results[0]?.formatted_address,
                },
              ],
            }));
          }
        });
    }
  }

  _goToTutorial() {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.TUTORIAL});
    history.push(buildURI(routingConstants.FIND_MY_VAPE_TUTORIAL), {type: 'slide'});
  }

  _goToDashboard() {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CLOSE});
    history.push(buildURI(routingConstants.DASHBOARD));
  }

  _goToLocationServices() {
    history.push(buildURI(routingConstants.LOCATION_SERVICES));
  }

  _toggleBlinking(seconds, device) {
    // const { currentDevice } = this.state;
    const currentDevice = device || this.state.currentDevice;

    if (currentDevice !== null && currentDevice !== undefined) {
      getDeviceInstanceFromSN(currentDevice.serialNumber).then(
        (deviceInstance) => {
          if (null !== deviceInstance && deviceInstance !== undefined) {
            deviceInstance.toggleFindMyVape(seconds);
          }
        }
      );
    }
  }

  onFoundButtonClick() {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.STOP_PINGING})
    this.toggleBlinking(0);
  }

  onPingButtonClick() {
    // NEW PING
    // logAnalyticsEvent(AnalyticsEvents.PING_DEVICE);
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.PING})
    this.props.setUserSettings(crmUserSettings.FMV_PING, "true");
    this.toggleBlinking(60);
  }

  getLastSyncedDate(device) {
    const lastSyncedDate = device?.lastSyncedLocation?.date;

    if (!lastSyncedDate) {
      return this.dictionary.FMV_UNAVAILABLE;
    }

    if (moment(lastSyncedDate).isSame(new Date(), "day")) {
      return `${this.dictionary.FMV_TODAY_AT} ${moment(lastSyncedDate).format(
        "HH:mm"
      )}`;
    }

    return `${moment(lastSyncedDate).format("DD/MM/YYYY")} AT ${moment(
      lastSyncedDate
    ).format("HH:mm")}`;
  }

  getLastSyncedLocation(device) {
    const lastSyncedDate = device?.lastSyncedLocation?.date;

    if (!lastSyncedDate) {
      return this.dictionary.FMV_CONNECT_BLE;
    }

    const { locationsLabel } = this.state;

    if (!isEmpty(locationsLabel)) {
      return locationsLabel.find(
        (el) => el && el.serialNumber === device.serialNumber
      )?.formattedAddress;
    }

    return null;
  }

  saveSelect(index) {
    const { devices } = this.props

    let selectedDevice = devices[index]

    this.setState({
      isDeviceSelectionOpen: false,
      currentDevice: selectedDevice,
      deviceIndex: index,
    })
  }

  render() {
    const {
      isOpenDetailsPanel,
      currentDevice,
      isPermissionDenied,
      isDeviceSelectionOpen,
      deviceIndex,
      isOnline
    } = this.state;

    const { devices } = this.props;

    const syncDevices =
      devices && devices.length > 0
        ? devices.filter(
            (device) =>
              !!device.lastSyncedLocation?.lat &&
              !!device.lastSyncedLocation?.lng
          )
        : [];

    return (
      <div className="page w-100 find-my-vape-page">
        <div className="optin-wrapper">
          <Header
            ref={this.headerRef}
            leftButton={{
              className: "play-tutorial",
              icon: <span className="play-tutorial-icon" />,
              onClick: this.goToTutorial,
            }}
            rightButton={{
              icon: <span className="bat-icon-close" />,
              onClick: this.goToDashboard,
            }}
          >
            {this.dictionary.FMV_HEADER}
          </Header>
          <div className="usage-tracker-device-select" style={{zIndex: 7}}>
            {devices.length > 1 && (
              <div
                className={cx(
                  "usage-tracker-device-select-btn",
                  deviceColorClass[currentDevice?.deviceColor],
                  currentDevice?.deviceType
                )}
                onClick={() => {
                  if(currentDevice?.isBlinking) {
                    this.onFoundButtonClick();
                  }
                  this.setState({
                    isDeviceSelectionOpen: true
                  });
                }}
              >
                <div>
                  {getDeviceName(currentDevice)}
                </div>
              </div>
            )}
          </div>
          {/* if we are offline */}
          {!isOnline &&
            <div className="optin-content">
              <div className="optin-icon danger">
                {" "}
                <span
                  style={{
                    backgroundImage:
                      "url(" + imagesConstants.OPTIN_FIND_VAPE + ")",
                  }}
                ></span>
              </div>
              <div className="optin-title">
                <h1>
                  {this.dictionary.FMV_MAP_OFFLINE_TITLE}
                </h1>
              </div>
            </div>
          }
          {/* if we are online and there is not a device paired */}
          {isOnline && syncDevices.length === 0 && (
            <div className="optin-content">
              <div className="optin-icon danger">
                {" "}
                <span
                  style={{
                    backgroundImage:
                      "url(" + imagesConstants.OPTIN_FIND_VAPE + ")",
                  }}
                ></span>
              </div>
              <div className="optin-title">
                <h1>
                  {(isPermissionDenied || !isOnline)
                    ? this.dictionary.FMV_MAP_OFFLINE_TITLE
                    : this.dictionary.FMV_OFFLINE_HEADER}
                </h1>
              </div>
              <div className="optin-description">
                {isPermissionDenied ? (
                  <div>
                      {this.dictionary.FMV_MAP_OFFLINE_CONTENT}{" "}
                      <b onClick={this.goToLocationServices}>
                          <u>{this.dictionary.FMV_MAP_OFFLINE_LEARN_HOW}</u>
                      </b>
                      <br />
                      <br />
                      {this.dictionary.FMV_MAP_OFFLINE_SUBCONTENT}
                  </div>
                ) : (
                  <div>{this.dictionary.FMV_OFFLINE_CONTENT}</div>
                )}
              </div>
            </div>
          )}
        </div>

        <CustomMap
          zoomLevel={8}
          devices={isOnline ? syncDevices : []}
          options={Utils.getMapOptions()}
          resizeToMarker={isOpenDetailsPanel}
          panelHeigth={this.myRef?.clientHeight ?? 0}
          headerHeigth={this.headerRef?.clientHeight ?? 0}
          panelMinHeigth={this.panelMinRef?.clientHeight ?? 0}
          openPanel={(currentDevice) => {
            this.setState({
              isOpenDetailsPanel: true,
              currentDevice: currentDevice,
            });
          }}
          currentDevice={isOnline ? currentDevice : null}
        />
        <CustomSlidingPanel
          className="onboarding-panel mid-height-panel"
          overlayClassName="sliding-common-wrapper usage-tracker-overlay"
          from="bottom"
          backdropClose={false}
          hideHeader={true}
          isOpen={isDeviceSelectionOpen}
          additionalStyles={{
            height: "auto",
            paddingBottom: "10vh"
          }}
        >
         <ul className="devices-list">
          {devices.map((device, index) => (
            <li
              key={index}
              className={cx(
                "d-flex align-items-center justify-content-between",
                { selected: device === devices[deviceIndex] }
              )}
              onClick={() => {this.saveSelect(index); localStorage.setItem('analytics_device_index', index)}}
            >
              <div className="device">
                <DeviceIcon device={device} />
                <div className="device-name">
                  {getDeviceName(device)}
                </div>
              </div>
              <div className="bullet" />
            </li>
          ))}
        </ul>
        </CustomSlidingPanel>  

        <DeviceDrawer
          device={currentDevice}
          connectedLabel={this.dictionary.FMW_LABEL_DEVICE_CONNECTED}
          disconnectedLabel={this.dictionary.FMW_LABEL_DEVICE_DISCONNECTED}
        >
          <div className="swiper-slide-content">
            {isSynchronized(currentDevice) ? (
              <>
                <div className="d-grid">
                  <button
                    className={cx("btn text-uppercase", {
                      "btn-primary": !currentDevice?.isBlinking,
                      "text-dark": !currentDevice?.isBlinking,
                      "btn-outline-secondary": currentDevice?.isBlinking,
                    })}
                    onClick={() =>
                      currentDevice?.isBlinking
                        ? this.onFoundButtonClick()
                        : this.onPingButtonClick()
                    }
                  >
                    {currentDevice?.isBlinking
                      ? this.dictionary.FMV_BTN_FOUND_0_ctaLabel
                      : this.dictionary.FMV_BTN_PING_0_ctaLabel}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="last-synced-label">
                  {this.dictionary.FMV_LABEL_LAST_SYNC}
                </div>
                <div className="last-synced-date">
                  {this.getLastSyncedDate(currentDevice)}
                </div>
                <div className="last-synced-location">
                  {this.getLastSyncedLocation(currentDevice)}
                </div>
              </>
            )}
          </div>
        </DeviceDrawer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    devices: state.deviceReducer.devices,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addOrUpdateDevice: (devicePayload, addOnTop, callBack) => {
      dispatch(
        deviceActions.addOrUpdateDevice(
          devicePayload.toObj(),
          addOnTop,
          callBack
        )
      );
    },
    setUserSettings: (settingName, value, successCb, failureCb) => {
      dispatch(
        userActions.setUserSettings(settingName, value, successCb, failureCb)
      );
    },
  };
}

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(withLastLocation(FindMyVape));
export { connectedComponent as FindMyVape };
