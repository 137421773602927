export const CHECK_EMAIL = "/email-exists";
export const RESET_PASSWORD = "/reset-password";
export const RESEND_ACTIVATION_LINK = "/resend-activation-link";
export const USERS = "/users";
export const LOGIN = "/login";

export const UKcheckMail = "/customers/isEmailAvailable"; //not used

//CA vype
export const CAlogin = "/auth";
export const CAregistration = "/users";
export const CAregistrationDarkProvinces = "/users/myvuse-create";
export const CAauthentication = "/auth";
export const CAforgotPassword = "/auth/forgot-password";
export const CAuserInfo = "/users/me";
export const CAcheckEmail = "/users/email-exists/";
export const CAuserSettings = "/users/me/settings";

//UK vype
export const token = "/v1/oauth2/token";
export const UKregistration = "/ngp/v1/sputnik/consumers";
export const UKgetConfirmationLink = "/ngp/v1/sputnik/consumers/confirm";
export const UKgetCustomerData =
  "/ngp/v1/sputnik/consumers/me?countryCode=UK&brand=Vuse";
export const login = "/ngp/v1/sputnik/consumers/token";
export const forgotPassword = "/ngp/v1/sputnik/consumers/setpassword";
export const contact = "/ngp/v1/cases";

//UK be
export const userProfile = "/users/profile/bat";
export const modifyFlags = "/tandc";
export const favourites = "/things/catalog/favourite";
export const unfavourites = "/things/catalog/unfavourite";
export const resetFavourites = "/things/catalog/favourite/reset";
export const suggestion = "/things/catalog/suggestion";
export const appVersion = "/app_version/";
export const getPuffStats = "/things/puff/getstats";
export const getPodInventory = "/things/puff/getpodinventory";
export const setPodInventory = "/things/puff/setpreferences";
export const setPuffData = "/things/puff/storedata";
export const setWishlist = "/things/catalog/wishlist";
export const getWishlist = "/things/catalog/wishlist";
export const lastOrder = "/users/profile/order";
export const factoryReset = "/things/factoryReset";

//IoT Platform
export const tenantsConfig = '/tenants/configurations';
export const storePin = '/vault/pin/store';
export const updatePin = '/vault/pin/update';
export const validatePin = '/vault/pin/validate';
export const validateSecAnswer = '/vault/security-answer/validate';
export const storeTenantUserId = '/vault/tenant/user-id/store';
export const updateTenantUserId = '/vault/tenant/user-id/update';
export const groups = '/groups';
export const things= '/things';
export const commands = '/commands';
export const thingProperties = '/things/properties';
export const firmwareProperties = '/things/firmware';
export const catalog= '/ecommerce/catalog';
export const flavourForUSuggestions= '/ecommerce/suggestions';
export const rules = '/rules';
export const deviceErrors = '/device-error';
export const getNotificationHistory = '/events/history';
export const notificationRead = '/events/history/read';
export const notificationHide = '/events/history/hide';
export const devices = '/devices';
export const oneTimeToken = '/users/single-use-token';
export const pushCalcData = '/usage-tracker/calculated-data';
export const getCalcData = '/usage-tracker/calculated-data';
export const sendRawPuffRecords = '/usage-tracker/raw-data';

//Salesforce
export const salesforceId = '/users/salesforceId';

//UK token scopes
export const tokenScope = {
  SIGNUP: "create:consumers countryCode:UK",
  LOGIN: "create:consumers countryCode:UK",
  PROFILE: "read:consumers countryCode:UK",
  RESEND_LINK: "create:consumers countryCode:UK",
  FORGOT_PASSWORD: "update:consumers countryCode:UK",
};
