export const dashboardConstants = {
    SET_TUTORIAL_FMV:           'dashboard/SET_TUTORIAL_FMV',
    SET_TUTORIAL_CONTROLS:      'dashboard/SET_TUTORIAL_CONTROLS',
    SET_TUTORIAL_BATTERY:       'dashboard/SET_TUTORIAL_BATTERY',
    SET_TUTORIAL_UNLOCK:        'dashboard/SET_TUTORIAL_UNLOCK',
    SET_TUTORIAL_USAGE_TRACKER: 'dashboard/SET_TUTORIAL_USAGE_TRACKER',
    SET_TUTORIAL_LOCK_SWITCH:   'dashboard/SET_TUTORIAL_LOCK_SWITCH',
    GET_TIPS:                   'dashboard/GET_TIPS',
    UPDATE_TIP:                 'dashboard/UPDATE_TIP',
    ADD_TIPS:                   'dashboard/ADD_TIPS',
    REMOVE_TIP:                 'dashboard/REMOVE_TIP', //FE removal
    REMOVE_REMOTE_TIP:          'dashboard/REMOVE_REMOTE_TIP', //BE removal
    SET_LOW_BATTERY_TIP_SHOWN:  'dashboard/SET_LOW_BATTERY_TIP_SHOWN',
    SET_FORECAST_BATTERY_TIP_SHOWN: 'dashboard/SET_FORECAST_BATTERY_TIP_SHOWN',
    SET_BATTERY_SAVER_ON_TIP_SHOWN: 'dashboard/SET_BATTERY_SAVER_ON_TIP_SHOWN',
    SET_BATTERY_SAVER_OFF_TIP_SHOWN: 'dashboard/SET_BATTERY_SAVER_OFF_TIP_SHOWN',


    SET_TIMER:                  'dashboard/SET_TIMER', 
    GET_FAVOURITE_FLAVORS:      'dashboard/GET_FAVOURITE_FLAVORS',
    GET_SUGGESTED_FLAVORS:      'dashboard/GET_SUGGESTED_FLAVORS',
    RESET_FAVOURITE_FLAVORS:    'dashboard/RESET_FAVOURITE_FLAVORS',
    SET_END_TIMER_ACTION:       'dashboard/SET_END_TIMER_ACTION',
    GET_LAST_ORDER:             'dashboard/GET_LAST_ORDER',
    SHOW_ALERT_POD_TRACKING:    'dashboard/SHOW_ALERT_POD_TRACKING',

    GET_CONTACT_TOKEN:          'dashboard/GET_CONTACT_TOKEN',
    SEND_CONTACT_MESSAGE:       'dashboard/SEND_CONTACT_MESSAGE',
    GET_NOTIFICATION_HISTORY:   'dashboard/GET_NOTIFICATION_HISTORY',
    SET_NOTIFICATION_READ:      'dashboard/SET_NOTIFICATION_READ',
    RESET_VUSE_FLAGS:           'dashboard/RESET_VUSE_FLAGS',
    SET_VUSE_WORLD_STORIES_SEEN: 'dashboard/SET_VUSE_WORLD_STORIES_SEEN',
    
    ADD_TIPS_TO_QUEUE:           'dashboard/ADD_TIPS_TO_QUEUE',

    SET_USAGE_TRACKER_SELECTED_DEVICE: 'dashboard/SET_USAGE_TRACKER_SELECTED_DEVICE',
    SET_USAGE_TRACKER_RAW_DATA_PUBLISH_TS: 'dashboard/SET_USAGE_TRACKER_RAW_DATA_PUBLISH_TS',
    SET_USAGE_TRACKER_CALC_DATA_PUBLISH_TS: 'dashboard/SET_USAGE_TRACKER_CALC_DATA_PUBLISH_TS',
}