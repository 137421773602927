import React from "react";
import { isSupportedService } from "../../_actions/appConfig.actions";
import { Header } from "../../_components";
import {
  aemPages,
  routingConstants,
  servicesConstants,
} from "../../_constants";
import {
  AEMHelper,
  propertyHeadingDefaultEmptyParsed,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";
import { convertObjectToQueryString } from "../../_helpers/utils";
import { Commons } from "../../_helpers/commons";

export const TermsAndConditionsAndPrivacyPolicy = (props) => {

    const aem = new AEMHelper();
    const dictionary = aem.getDictionary(aemPages.TAC_AND_PP, {
        TAC_AND_PP_HEADER: propertyHeadingDefaultEmptyParsed,
        TAC_AND_PP_TAC: propertyTextDefaultEmptyParsed,
        TAC_AND_PP_SUBSCRIPTION: propertyTextDefaultEmptyParsed,
        TAC_AND_PP_SALE: propertyTextDefaultEmptyParsed,
        TAC_AND_PP_PP: propertyTextDefaultEmptyParsed,
    });
    
    const handleBack = () => {
        Commons.goToDashboard();
    }
    
    const navigateTo = (url) => {
        history.push(
        buildURI(url) + convertObjectToQueryString({ canHandleBack: true })
        );
    }
    
    return (
        <div className="page w-100">
          <Header
            rightButton={{
              icon: <span className="bat-icon-close" />,
              onClick: () => handleBack(),
            }}
          >
            {dictionary.TAC_AND_PP_HEADER}
          </Header>
          <div id="bm-menu-content h-100">
            <div className="bm-menu-wrapper">
              <div className="list-group">
                {isSupportedService(servicesConstants.TAC_ONLINE_TAC) && (
                  <a
                    className="list-group-item d-flex justify-content-between align-items-center"
                    onClick={() =>
                      navigateTo(routingConstants.TERMS_AND_CONDITIONS_LIST)
                    }
                  >
                    {dictionary.TAC_AND_PP_TAC}
                    <span className="bat-icon-chevron-right"></span>
                  </a>
                )}
                {isSupportedService(servicesConstants.TAC_MYVUSEPP) && (
                  <a
                    className="list-group-item d-flex justify-content-between align-items-center"
                    onClick={() =>
                      navigateTo(routingConstants.PRIVACY_POLICY)
                    }
                  >
                    {dictionary.TAC_AND_PP_PP}
                    <span className="bat-icon-chevron-right"></span>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      );
};