//import Axios from 'axios';
import _ from 'lodash';
import { commonsActions } from "../../_actions";
import { store } from "../../_store";
import { Commons } from "../commons";
import environmentConstants from "../../_constants/environment/environment.constants";


function logMessage(type = 'debug') {
    return (...args) => {
        if (process.env.REACT_APP_ENV_NAME !== environmentConstants.PRODUCTION) {
            const date = new Date();
            const message = args.map(arg => {
                if (_.isPlainObject(arg)) {
                    return JSON.stringify(arg)
                } else {
                    return arg;
                }
            }).join("");

            if (type === 'debugAnalytics') {
                store.dispatch(commonsActions.debugAnalytics(date, message));
            } else {
                store.dispatch(commonsActions.debugCommons(date, message));
            }
            
            if (!Commons.isLocalhost()) {
                const dateString = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}_${date.getMilliseconds()}`;
                // Axios.post(`/${type}-${dateString}.json`, {
                //     date,
                //     message
                // });
            }
        }
    }
}


function resetLoggingHistory(type = 'debug') {
    return (...args) => {
        if (process.env.REACT_APP_ENV_NAME !== environmentConstants.PRODUCTION) {
            if (type === 'debugAnalytics') {
                store.dispatch(commonsActions.resetDebugAnalytics());
            } else {
                store.dispatch(commonsActions.resetDebugCommons());
            }
        }
    }

}

export const debug = logMessage('debug');
export const debugAnalytics = logMessage('debugAnalytics');

export const resetDebug = resetLoggingHistory('debug');
export const resetDebugAnalytics = resetLoggingHistory('debugAnalytics');
