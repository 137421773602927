import React, { useEffect } from 'react';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { AnalyticsEvents, AnalyticsScreenEventNames } from '../../_constants';

export const ProgressStep = ({
    title,
    processCompleteLabel,
    progressImage, 
    progressPerc,
    nextStep,
    from
}) => {

    useEffect(() => {
        if(from === 'remove'){
            logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.REMOVE_DEVICE_PROGRESS})
        } else {
            logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.FACTORY_RESET_PROGRESS})
        }
        if(progressPerc >= 100){
            setTimeout(() => {
                nextStep();
            }, 1000);
        }
    }, [progressPerc])

    const processLabel = progressPerc < 100
        ? `${progressPerc}%`
        : processCompleteLabel || "Process Complete";

    return (
        <div className="process-data-container">
            <div className="process-data-title"><span>{title}</span></div>
            <div className="process-data-image">
                {progressImage}
            </div>
            <div className="text-center mt-auto">
                <span>{processLabel}</span>
                <div className="custom-progress">
                    <div className="progress-bar" role="progressbar" style={{ width: `${progressPerc}%` }} aria-valuenow={progressPerc} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
        </div>
    );
}