import React, { useEffect, useState } from 'react';
import { aemPages, imagesConstants } from '../../_constants';
import { CustomSlidingPanel } from "../CustomSlidingPanel";
import { AEMHelper, propertyHeadingDefaultEmpty, propertyTextDefaultEmpty, propertyTextDefaultEmptyParsed } from '../../_helpers/aem/aemhelper';

export const ContactPanel = ({ isOpen, onClose }) => {

    const [dictionary, setDictionary] = useState({});

    useEffect(() => {
        const aem = new AEMHelper();

        setDictionary({
            ...aem.getDictionary(aemPages.ONBOARD_NOTIF_PREF, {
                NOTIF_PREF_CUS_EMAIL: propertyTextDefaultEmpty,
                NOTIF_PREF_CUS_PHONE: propertyTextDefaultEmpty,
                NOTIF_PREF_CUS_BODY: propertyTextDefaultEmptyParsed,
                NOTIF_PREF_CUS_HEADER: propertyHeadingDefaultEmpty
            })
        })

    }, [])

    return (
        <CustomSlidingPanel from="bottom"
            isOpen={isOpen}
            overlayClassName={"sliding-common-wrapper title-left"}
            className="onboarding-panel low-height-panel priority"
            title={dictionary.NOTIF_PREF_CUS_HEADER}
            onClose={onClose}
            closeIcon='bat-icon-close'>
            <div className="failure-support-wrapper p-0">
                <div>
                    <div className="subtitle-row">
                        <div>
                            {dictionary.NOTIF_PREF_CUS_BODY}
                        </div>
                    </div>
                    <div className="support-row">
                        <div>
                            <span className="bat-icon-phone"></span> {dictionary.NOTIF_PREF_CUS_PHONE}
                        </div>
                    </div>
                    <div className="support-row">
                        <div>
                            <img src={imagesConstants.NEW_EMAIL} alt="email" /> {dictionary.NOTIF_PREF_CUS_EMAIL}
                        </div>
                    </div>
                </div>
            </div>
        </CustomSlidingPanel>
    );
}