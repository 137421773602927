import { deviceConstants } from "../_constants";
import { pickBy } from "lodash";
import isNil from "lodash/isNil";

const PROPS_TO_NOT_OVERRIDE = [
  "batteryInfo",
  "deviceInfo",
  "connected",
  "deviceType",
  "deviceCustomName",
  "serialNumber",
  "deviceColor",
  "marketSelectorCompleted",
  "isBlinking",
  "lastConnection",
  "uuid",
  "dfuModeOn",
  "updatable",
  "ongoingUpdate"
];

export const initialState = {
  deviceProfile: null,
  devices: [],
  loading: false,
  error: false,
  debug: [],
};

export function deviceReducer(state = initialState, action) {
  switch (action.type) {
    case deviceConstants.SET_DEVICES: {
      const { payload } = action;

      return {
        ...state,
        devices: [...payload],
      };
    }

    case "RESET_REDUCER": {
      return {
        deviceProfile: null,
        devices: [],
        loading: false,
        error: false,
        debug: [],
      };
    }

    case "device/DEBUG": {
      let newLog = action.payload.log ?? "Error on logging: null or undefined";
      let newDebug = state.debug;
      if (state?.debug?.length >= 500) {
        newDebug.shift();
      }
      newDebug.push({ time: action.payload.time, log: newLog });
      return {
        ...state,
        debug: newDebug,
      };
    }
    case "device/RESET_DEBUG": {
      return {
        ...state,
        debug: [],
      };
    }

    case deviceConstants.SET_DEVICE_PROFILE: {
      return {
        ...state,
        deviceProfile: action.payload.profile,
      };
    }

    case deviceConstants.SET_DEVICE_INFO:
      return {
        ...state,
        deviceInfo: action.payload,
      };

    case deviceConstants.SET_BATTERY_INFO:
      return {
        ...state,
        batteryInfo: action.payload,
      };

    case deviceConstants.SET_LOCK_INFO:
      return {
        ...state,
        lockInfo: action.payload,
      };

    case deviceConstants.SET_CLOUD_INFO:
      return {
        ...state,
        cloudInfo: action.payload,
      };

    case deviceConstants.SET_CONNECTED:
      return {
        ...state,
        //connected: action.payload,
        connectionTimedOut: false,
      };

    case deviceConstants.SET_CONNECTING:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case deviceConstants.SET_PAIRED:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case deviceConstants.SET_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case deviceConstants.SET_CONNECTION_TIMED_OUT:
      return {
        ...state,
        connectionTimedOut: action.payload,
        //connected: false
      };

    case deviceConstants.RESET_DEVICES:
      return {
        ...state,
        devices: [],
      };

    case deviceConstants.REMOVE_DEVICE: {
      return {
        ...state,
        devices: [
          ...state.devices.filter(
            (device) => device.serialNumber !== action.payload
          ),
        ],
      };
    }

    case deviceConstants.ADD_UPDATE_DEVICE:
      const idxad = state.devices.findIndex(
        (device) => device.serialNumber === action.payload.serialNumber
      );
      const devicesad = [...state.devices];
      console.debug("ADD_DEVICE_PAYLOAD", action.payload);
      /* Device not found */
      if (idxad === -1) {
        devicesad.push(action.payload);
      } else {
        console.debug("ADD_DEVICE_PAYLOAD_old", devicesad[idxad]);
        devicesad[idxad] = {
          ...devicesad[idxad],
          ...pickBy(
            action.payload,
            (value, key) =>
              !(PROPS_TO_NOT_OVERRIDE.includes(key) && isNil(value))
          ),
        };
      }
      return {
        ...state,
        devices: [...devicesad],
      };

    case deviceConstants.ADD_UPDATE_DEVICE_FIRST: {
      const idxad = state.devices.findIndex(
        (device) => device.serialNumber === action.payload.serialNumber
      );

      let devicesad = [...state.devices];

      /* Device not found */
      if (idxad === -1) {
        /* If devices is being added we set it as selected */
        devicesad = devicesad.map((device) => ({ ...device, selected: false }));
        devicesad.unshift({ ...action.payload, selected: true });
      } else {
        devicesad[idxad] = {
          ...devicesad[idxad],
          ...pickBy(
            action.payload,
            (value, key) =>
              !(PROPS_TO_NOT_OVERRIDE.includes(key) && isNil(value))
          ),
        };
      }

      return {
        ...state,
        devices: [...devicesad],
      };
    }

    case deviceConstants.SET_DEVICE_CUSTOM_NAME:
      const idxcn = state.devices.findIndex(
        (device) => device.serialNumber === action.payload.serialNumber
      );
      const devicescn = [...state.devices];
      if (idxcn !== -1) {
        devicescn[idxcn].deviceCustomName = action.payload.deviceCustomName;
      } else {
        devicescn.push(action.payload);
      }
      return {
        ...state,
        devices: devicescn,
      };

    case deviceConstants.SET_DEVICE_COLOR:
      const idxdc = state.devices.findIndex(
        (device) => device.serialNumber === action.payload.serialNumber
      );
      const devicesdc = [...state.devices];
      if (idxdc !== -1) {
        devicesdc[idxdc].deviceColor = action.payload.deviceColor;
      } else {
        devicesdc.push(action.payload);
      }
      return {
        ...state,
        devices: devicesdc,
      };

    /* Set the device viewable */
    case deviceConstants.SET_SELECTED: {
      const idxss = state.devices.findIndex(
        (device) => device.serialNumber === action.payload.serialNumber
      );
      let devicesss = [...state.devices];

      if (idxss !== -1) {
        /* First: reset the selected field for all the devices */
        devicesss = devicesss.map((device) => ({ ...device, selected: false }));
        /* Then activate the desired one */
        devicesss[idxss].selected = true;
      } else {
        devicesss.push(action.payload);
      }
      return {
        ...state,
        devices: [...devicesss],
      };
    }

    /* Set the device non-viewable */
    case deviceConstants.REMOVE_SELECTED: {
      const idxss = state.devices.findIndex(
        (device) => device.serialNumber === action.payload.serialNumber
      );
      let devicesss = [...state.devices];
      if (idxss !== -1) {
        devicesss[idxss].selected = false;
      }
      return {
        ...state,
        devices: [...devicesss],
      };
    }

    default:
      return state;
  }
}

// function pushNewLogTo(state, action, propName) {
//     const {
//         payload: {
//             log,
//             time
//         }
//     } = action;
//     let newDebug = [
//         ...(state[propName] ?? []),
//         {time, log: log ?? 'Error on logging: null or undefined'}
//     ]
//     if (newDebug.length > 500){
//         newDebug = newDebug.shift();
//     }

//     const newState = {
//         ...state,
//         [propName]: newDebug
//     }

//     return newState;
// }

// function resetLogsOf(state, propName) {
//     return {
//         ...state,
//         [propName]: []
//     };
// }
