import React from "react";
import Iframe from "react-iframe";
import { Header } from "..";

export const WebView = (props) => {
  const { id, className, title, url, handleBack, close, showHeader = true } = props;

  return (
    <div className="page w-100">
      {showHeader ? (
        <Header
          {...(handleBack && {
            leftButton: {
              icon: <span className="bat-icon-back" />,
              onClick: handleBack
            }
          })}
          {...(close && {
            rightButton: {
              icon: <span className='bat-icon-close' />,
              onClick: close
            }
          })}
        >
          {title}
        </Header>
      ) : null}
      <div className="h-100">
        {url && (
          <Iframe
            url={url}
            width="100%"
            height="100%"
            id={id}
            className={className}
            display="initial"
            position="relative"
            allow="geolocation *"
          />
        )}
      </div>
    </div>
  );
};
