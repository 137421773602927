import React, { useState, useEffect } from "react";
import { useTransition, animated } from "react-spring";
import _ from 'lodash';

/**
 * Component included in views where there are lists which options should fade out when one of those is selected
 * @param {*} props 
 * @returns 
 */
export const FadingList = (props) => {

    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        if(!_.isEqual(props.items, items)){
            setItems(props.items);
        }
    }, [props.items])

    useEffect(() => {
        setSelectedItem(props.selectedItem);
    }, [props.selectedItem])

    const filteredItems = items?.filter((tenantItem) => selectedItem === null ? true : _.isEqual(tenantItem.item, selectedItem));

    const transition = useTransition(filteredItems, {
        from: { opacity: 0, marginTop: 5 },
        enter: { opacity: 1, maxHeight: 100, marginTop: 0 },
        leave: { opacity: 0, maxHeight: 0, marginTop: 0 }
    });

    const fadeInListItems = transition((style, item) => {
        return (
            <animated.div style={style}>
                <Item data={item} selectedItem={selectedItem} handleClick={props.handleClick} />
            </animated.div>
        );
    });

    return (
        <React.Fragment>{fadeInListItems}</React.Fragment>
    );
}


const Item = (props) => {

    const handleClick = (e) => {
        if (typeof props.handleClick === 'function') {
            props.handleClick(e);
        }
    };

    return (
        <animated.div>
            <button
                className={`btn btn-outline-secondary text-start ${_.isEqual(props.data.item, props.selectedItem) ? "active" : ""}`}
                onClick={() => handleClick(props.data.item)}
                type="button"
                key={props.data.key}>
                    {props.data.flag && <span className="flag"><img src={props.data.flag} alt={props.data.value} /></span> }
                {props.data.value}
            </button>
        </animated.div>
    );
};
