import { commonsActions, setDoneCountrySelection } from "../../_actions";
import { setAppConfig, setLocale } from "../../_actions/appConfig.actions";
import { store } from "../../_store";
import { AEMHelper } from "./aemhelper";

export default class AEMCreator {
  static initAEMPage(localeId, market) {
    return new Promise((resolve, reject) => {
      const getCountriesSuccessCallback = (countriesRes) => {
        const path = window.location.pathname;
        const tenantId = path ? path.split("/").pop() : "";

        console.debug("AEM_PAGE_COUNTRY_TENANT_ID", tenantId);

        const country = countriesRes?.data?.find(
          (country) => country.tenantId === tenantId
        );

        console.debug("AEM_PAGE_COUNTRY", tenantId);

        if (!!country) {
          const language = country.supported_locales.find(
            (locale) => !!locale[localeId]
          );
          resolve(AEMCreator.initAEM(country, language));
        } else {
          reject("No country found");
        }
      };

      store.dispatch(
        commonsActions.getCountries(getCountriesSuccessCallback, reject)
      );
    });
  }

  static initAEM(country, language) {
    return new Promise((resolve, reject) => {
      const getCountryConfigSuccessCallback = (countryConfig) => {

        console.debug('COUNTRY CONFIG', countryConfig);

        if (countryConfig?.data) {
          const locale = Object.keys(language)[0];

          //update local storage
          setDoneCountrySelection(true); //set localstorage property for naviagtion purpose
          setAppConfig(countryConfig.data);
          setLocale(locale);

          //Load all translations and set default locale
          const aemHelper = new AEMHelper(locale);
          aemHelper.setCurrentCountry(country);
          aemHelper.setCurrentLanguage({
            locale,
            label: language[locale],
          });

          resolve(Promise.all([aemHelper.loadLanguageJSON()]));
        } else {
          reject("No data found in countryConfig");
        }
      };

      store.dispatch(
        commonsActions.getCountryConfig(
          country.tenantId,
          getCountryConfigSuccessCallback,
          reject
        )
      );
    });
  }
}
