/**
 * Model for in-app notifications.
 * 
 * 
 * Notifications can be in 4 states:
 * ¬READ, ¬REMOVED -> when displayed
 * READ, ¬REMOVED -> when user clicks on notification
 * ¬READ, REMOVED -> when user not interacts with notification, and it's closed
 * READ, REMOVED -> when user interacts with notification, and it's closed
 * 
 * REMOVED notifications are shown in notifications center
 * Some notifications can be also removed phisically, not only setting REMOVED true value
 */
class NotificationModel {

    id = null;
    type = null;
    timestamp = null;
    title = "";
    sticky = false;
    body = "";
    category = null;

    replaceStrings = [];
    icon = null;
    action = null;

    removed = false;
    local = false;
    read = false;

    device = null;

    webview_title = "";
    webview_url = null;
    webview_restricted = false;

    analytics = null;

    constructor(data) {
        this.id = data.id;
        this.body = data.body;
        this.title = data.title;
        this.sticky = data.sticky;
        this.category = data.category;
        this.timestamp = data.timestamp;
        this.replaceStrings = data.replaceStrings;
        this.icon = data.icon;
        this.type = data.type;
        this.removed = data.removed ?? false;
        this.local = data.local ?? false;
        this.read = data.read ?? false;
        this.device = data.device ?? null;
        this.action = data.action ?? null;
        this.webview_restricted = data.webview_restricted ?? false;
        this.webview_url = data.webview_url ?? null;
        this.webview_title = data.webview_title ?? '';
        this.analytics = data.analytics ?? null;
    }

    toObj = () => {
        return {
            id: this.id,
            body: this.body,
            title: this.title,
            sticky: this.sticky,
            category: this.category,
            timestamp: this.timestamp,
            replaceStrings: this.replaceStrings,
            icon: this.icon,
            removed: this.removed,
            local: this.local,
            read: this.read,
            type: this.type,
            device: this.device,
            action: this.action,
            webview_restricted: this.webview_restricted,
            webview_url: this.webview_url,
            webview_title: this.webview_title,
            analytics: this.analytics
        }
    }

}

export { NotificationModel };