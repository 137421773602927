import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { shopActions } from '../../../_actions';
import { getAEMAssetsUrl } from '../../../_actions/appConfig.actions';
import { aemPages, AnalyticsEvents, productType, shopTabs } from '../../../_constants';
import { AEMHelper, propertyImageSrcDefaultNull, propertyTextDefaultEmpty } from '../../../_helpers/aem/aemhelper';
import { logAnalyticsEvent } from '../../../_helpers/analytics/logAnalytics';
import { logAnalyticsEventOfBasketProductList, logAnalyticsEventOfBasketVariantList } from '../../../_helpers/basket';
import { BasketElementModel } from '../../../_models';
import { store } from '../../../_store';
import { AccessoriesProducts } from './Products/AccessoriesProducts';
import { DevicesProducts } from './Products/DevicesProducts';
import { FlavoursProducts } from './Products/FlavoursProducts';

class BuyNow extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            category: 0,
            catalogAccessories: [],
            catalogDevices: [],
            catalogFlavours: []
        }

        this.aem = new AEMHelper();
        this.dictionary = this.aem.getDictionary(aemPages.SHOP, {
            SHOP_FLAVOURS_TAB: propertyTextDefaultEmpty,
            SHOP_DEVICES_TAB: propertyTextDefaultEmpty,
            SHOP_ACCESSORIES_TAB: propertyTextDefaultEmpty,
            SHOP_BG_BUYNOW_ACCESSORIES: propertyImageSrcDefaultNull,
            SHOP_BG_BUYNOW_FLAVOURS: propertyImageSrcDefaultNull,
            SHOP_BG_BUYNOW_DEVICES: propertyImageSrcDefaultNull,
        });

        this.toggleProductsCategory = this._toggleProductsCategory.bind(this);
        this.updateCatalog = this._updateCatalog.bind(this);
        this.handleOpenBasketPanel = this._handleOpenBasketPanel.bind(this);
        this.handleCloseCheckoutPanel = this._handleOpenCheckoutPanel.bind(this);

    }

    componentDidMount() {
        this.updateCatalog(() => {
            this.currentCategoryTab.onLanding();
        });

        if (
            null !== this.props.activeCategory &&
            undefined !== this.props.activeCategory
        ) {
            this.setState({ category: this.props.activeCategory });
        }
    }

    componentWillUnmount() { }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.catalog !== this.props.catalog) {
            if (this.props.catalog) {
                this.updateCatalog();
            }
        }

        if (prevProps.activeCategory !== this.props.activeCategory) {
            if (this.props.activeCategory !== null && this.props.activeCategory !== undefined) {
                this.setState({ category: this.props.activeCategory });
            }
        }
    }

    _updateCatalog(callback = () => { }) {

        const {
            catalog,
            basket: currentBasket
        } = this.props;

        let thisCatalogAccessories = []; //list of all variants of accessories on sale
        let thisCatalogDevices = []; //list of all variants of devices on sale 
        let thisCatalogFlavours = []; //list of all flavours products on sale 
        let newBasket = []; //basket updated, purged from unavailable variants 

        if (catalog?.products?.length > 0) {
            store.dispatch(shopActions.resetVariantsInWhishlist([], () => {
                (catalog?.products).forEach(product => {

                    if (product?.variants?.length > 0) {

                        if (product?.product_type === productType.ACCESORIES) {

                            thisCatalogAccessories.push(...product.variants.map(variant => {

                                //update basket
                                let basketIndx = currentBasket.findIndex(el => el.variant_id === variant.variant_id);
                                if (basketIndx !== -1 && variant.quantity > 0) {
                                    newBasket.push(new BasketElementModel({
                                        ...variant,
                                        basket: currentBasket[basketIndx].basket,
                                        id: product.id
                                    }));
                                }

                                //add variant to accessories
                                let title = product.title;
                                if (product.variants.length > 1) {
                                    title = variant.display_name;
                                }
                                return {
                                    ...variant,
                                    product_id: product.id,
                                    display_name: title,
                                    gradient: product.gradient ?? variant.gradient,
                                    description: product.description ?? variant.description
                                };

                            }));

                        } else if (product?.product_type === productType.DEVICES) {

                            thisCatalogDevices.push(...product.variants.map(variant => {

                                //update basket
                                let basketIndx = currentBasket.findIndex(el => el.variant_id === variant.variant_id);
                                if (basketIndx !== -1 && variant.quantity > 0) {
                                    newBasket.push(new BasketElementModel({
                                        ...variant,
                                        basket: currentBasket[basketIndx].basket,
                                        id: product.id
                                    }));
                                }

                                //add variant to devices
                                let title = product.title;
                                if (product.variants.length > 1) {
                                    title = variant.display_name;
                                }
                                return {
                                    ...variant,
                                    product_id: product.id,
                                    display_name: title,
                                    gradient: product.gradient ?? variant.gradient,
                                    description: product.description ?? variant.description
                                };

                            }));

                        } else if (product?.product_type === productType.FLAVOURS) {

                            thisCatalogFlavours.push(product);

                            product.variants.forEach((variant) => {

                                //update basket
                                let basketIndx = currentBasket.findIndex(el => el.variant_id === variant.variant_id);
                                if (basketIndx !== -1 && variant.quantity > 0) {
                                    newBasket.push(new BasketElementModel({
                                        ...variant,
                                        basket: currentBasket[basketIndx].basket,
                                        id: product.id
                                    }));
                                }

                                //update wishlist
                                if (variant.is_favourite) {
                                    //insert in wishlist
                                    store.dispatch(shopActions.addVariantInWhishlist({
                                        ...variant,
                                        product_id: product.id
                                    }));
                                }
                            })

                        }
                    }
                })
            }))

            console.log('currentBasket', currentBasket);
            console.log('newBasket', newBasket);

            //replace basket only if is full
            if (currentBasket.length > 0) {
                store.dispatch(shopActions.replaceBasket(newBasket));
            }

        }

        console.log('[SHOP] catalog', _.groupBy(catalog?.products, 'product_type'));
        console.log('thisCatalogAccessories', thisCatalogAccessories);
        console.log('thisCatalogDevices', thisCatalogDevices);
        console.log('thisCatalogFlavours', thisCatalogFlavours);

        this.setState({
            catalogAccessories: thisCatalogAccessories,
            catalogDevices: thisCatalogDevices,
            catalogFlavours: thisCatalogFlavours,
        }, callback);

    }

    _toggleProductsCategory(catNum) {

        this.setState({ category: catNum }, () => {
            logAnalyticsEvent(AnalyticsEvents.SHOP_TAB_SELECTION, {
                tab: this.currentCategoryTab.id
            });
            this.currentCategoryTab.onLanding();
        });
    }

    _handleOpenBasketPanel() {
        if (typeof this.props.onOpenBasketPanel === "function") {
            this.props.onOpenBasketPanel();
        }
    }

    _handleOpenCheckoutPanel() {
        if (typeof this.props.onStartCheckout === "function") {
            this.props.onStartCheckout();
        }
    }

    get currentCategoryTab() {
        return this.categoryTabs[this.state.category];
    }

    get categoryTabs() {
        const {
            catalogAccessories,
            catalogDevices,
            catalogFlavours
        } = this.state;

        const {
            basket
        } = this.props;

        return [
            {
                id: shopTabs.FLAVOURS,
                title: this.dictionary.SHOP_FLAVOURS_TAB,
                iconClass: 'bat-icon-pod-notification',
                onLanding: () => {
                    logAnalyticsEventOfBasketProductList(AnalyticsEvents.VIEW_ITEM_LIST, catalogFlavours, false);
                },
                render: () => (
                    <FlavoursProducts
                        flavours={catalogFlavours}
                        basket={basket}
                        onStartCheckout={this.handleCloseCheckoutPanel}
                        onOpenBasketPanel={this.handleOpenBasketPanel}
                    />
                )
            },
            {
                id: shopTabs.DEVICES,
                title: this.dictionary.SHOP_DEVICES_TAB,
                iconClass: 'bat-icon-device',
                onLanding: () => {
                    logAnalyticsEventOfBasketVariantList(AnalyticsEvents.VIEW_ITEM_LIST, catalogDevices, false);
                },
                render: () => (
                    <DevicesProducts
                        devices={catalogDevices}
                        basket={basket}
                        onStartCheckout={this.handleCloseCheckoutPanel}
                        onOpenBasketPanel={this.handleOpenBasketPanel}
                    />
                )
            },
            {
                id: shopTabs.ACCESSORIES,
                title: this.dictionary.SHOP_ACCESSORIES_TAB,
                iconClass: 'bat-icon-accessories',
                onLanding: () => {
                    logAnalyticsEventOfBasketVariantList(AnalyticsEvents.VIEW_ITEM_LIST, catalogAccessories, false);
                },
                render: () => (
                    <AccessoriesProducts
                        accessories={catalogAccessories}
                        basket={basket}
                        onStartCheckout={this.handleCloseCheckoutPanel}
                        onOpenBasketPanel={this.handleOpenBasketPanel}
                    />
                )
            }
        ]
    }

    render() {
        const {
            category,
        } = this.state;

        const backgroundImage = category === 0
            ? `${getAEMAssetsUrl()}${this.dictionary.SHOP_BG_BUYNOW_FLAVOURS?.desktop?.src}`
            : category === 1
                ? `${getAEMAssetsUrl()}${this.dictionary.SHOP_BG_BUYNOW_DEVICES?.desktop?.src}`
                : `${getAEMAssetsUrl()}${this.dictionary.SHOP_BG_BUYNOW_ACCESSORIES?.desktop?.src}`;

        return (
            <React.Fragment>
                <div className="shop-home">
                    <div className="shop-category" style={{
                        backgroundImage: `url("${backgroundImage}")`,
                        backgroundPosition: 'center'
                    }}>
                        <div className="row">
                            {
                                this.categoryTabs.map((tab, index) => (
                                    <div className="col-4" key={tab.id}>
                                        <button className={`shop-category-item ${category === index ? 'selected' : ''}`}
                                            onClick={() => this.toggleProductsCategory(index)} >
                                            <span className={tab.iconClass}></span>
                                            <em>{tab.title}</em>
                                        </button>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    {this.currentCategoryTab.render()}
                </div>

            </React.Fragment>
        );

    }
}


function mapStateToProps(state) {

    const {
        shopReducer: {
            basket,
            catalog
        }
    } = state;

    return {
        basket,
        catalog
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(BuyNow);
export { connectedComponent as BuyNow };
