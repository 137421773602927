

import React from 'react';
import cx from "classnames";

export const CustomLoaderOverlay = (props) => {

    return (
        <div className={cx("custom-loader-overlay", {"force": props.forceShow})}>
            <div className="custom-loader-overlay-inner">
                <div className="custom-loader-overlay-content"><span className="spinner"></span></div>
            </div>
        </div>
    )
}