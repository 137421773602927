import React, { useEffect } from 'react';
import { useState } from 'react';
import _ from 'lodash';
import { PickerPanel } from '..';
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyHeadingDefaultEmpty, propertyTextDefaultEmpty } from '../../_helpers/aem/aemhelper';
import { aemPages, AnalyticsEvents, AnalyticsFieldNames, AnalyticsScreenEventNames, AnalyticsTargetEventsNames } from '../../_constants';
import { getAnalyticStatusBy, logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';

export const BatteryRemindersOptions = (props) => {
    const {
        currentIsActiveLowBatteryAlert,
        currentIsActiveCheckBatteryAlert,
        currentIsActiveFullBatteryAlert,
        currentDefaultPerc,
        currentPercSelected,
        currentTimeSelected,
        currentDefaultTime
    } = props;

    const [isActiveLowBatteryAlert, setIsActiveLowBatteryAlert] = useState(false);
    const [isActiveCheckBatteryAlert, setIsActiveCheckBatteryAlert] = useState(false);
    const [isActiveFullBatteryAlert, setIsActiveFullBatteryAlert] = useState(false);


    const [isOpenLowBatteryPanel, setIsOpenLowBatteryPanel] = useState(false);
    const [isOpenCheckBatteryPanel, setIsOpenCheckBatteryPanel] = useState(false);

    const [percOptions] = useState({
        currentPerc: _.map([...Array(19)], (_, idx) => `${(idx + 1) * 5}%`)
    });
    const [defaultPerc, setDefaultPerc] = useState('15%');
    const [percSelected, setPercSelected] = useState({ currentPerc: '15%' });

    const [timeOptions] = useState({
        hour: (new Array(13).fill(0)).map((v, i) => (i).toString().padStart(2, '0')),
        min: (new Array(60).fill(0)).map((v, i) => (i).toString().padStart(2, '0')),
        meridiem: ['AM', 'PM']
    });
    const [timeSelected, setTimeSelected] = useState({
        hour: '12',
        min: '56',
        meridiem: 'AM'
    });
    const [defaultTime, setDefaultTime] = useState({
        hour: '12',
        min: '57',
        meridiem: 'AM'
    });

    const [dictionary, setDictionary] = useState({});
    useEffect(() => {
        const aem = new AEMHelper();
        setDictionary(
            aem.getDictionary(aemPages.BATTERY_SETTINGS, {
                BATT_PREF_HEADER: propertyHeadingDefaultEmpty,
                BATT_PREF_FULL_BATTERY_ALERT_BODY: propertyTextDefaultEmpty,
                BATT_PREF_LOW_POWER_MODE: propertyTextDefaultEmpty,
                BATT_PREF_LOW_BATTERY_ALERT_BODY: propertyTextDefaultEmpty,
                BATT_PREF_CHECK_BATTERY_BODY: propertyTextDefaultEmpty,
                BATT_PREF_SAVE_BTN_CHOOSE: propertyCtaItemsDefaultEmpty,
                BATT_PREF_BATTERY_ALERT_SUBHEADER: propertyTextDefaultEmpty,
                BATT_PREF_CHECK_BATTERY_TITLE: propertyTextDefaultEmpty,
                BATT_PREF_RECHARGE_REMINDERS_TITLE: propertyTextDefaultEmpty,
                BATT_PREF_FULL_BATTERY_ALERT_TITLE: propertyTextDefaultEmpty,
                BATT_PREF_LOW_BATTERY_ALERT_TITLE: propertyTextDefaultEmpty,
                BATT_PREF_BATTERY_ALERT_HEADER: propertyHeadingDefaultEmpty,
                BATT_PREF_LOW_BATTERY_ALERT_SUBHEADER: propertyTextDefaultEmpty,
                BATT_PREF_RECHARGE_REMINDERS_BODY: propertyTextDefaultEmpty,
                BATT_PREF_LOW_BATTERY_ALERT_HEADER: propertyHeadingDefaultEmpty,
            })
        );
    }, [])

    useEffect(() => { setIsActiveLowBatteryAlert(currentIsActiveLowBatteryAlert) }, [currentIsActiveLowBatteryAlert]);
    useEffect(() => { setIsActiveCheckBatteryAlert(currentIsActiveCheckBatteryAlert) }, [currentIsActiveCheckBatteryAlert]);
    useEffect(() => { setIsActiveFullBatteryAlert(currentIsActiveFullBatteryAlert) }, [currentIsActiveFullBatteryAlert]);
    useEffect(() => { setDefaultPerc(currentDefaultPerc) }, [currentDefaultPerc]);
    useEffect(() => { setPercSelected(currentPercSelected) }, [currentPercSelected]);
    useEffect(() => { setDefaultTime(currentDefaultTime) }, [currentDefaultTime]);
    useEffect(() => { setTimeSelected(currentTimeSelected) }, [currentTimeSelected]);

    const toggleLowBatteryAlert = (...args) => {
        if (!props.disabled) {
            logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.LOW_BATTERY_ALERT, field_value: getAnalyticStatusBy(!isActiveLowBatteryAlert) === 'enabled' ? true : false});
            props.toggleLowBatteryAlert(...args);
        }
    }

    const toggleCheckBatteryAlert = (...args) => {
        if (!props.disabled) {
            props.toggleCheckBatteryAlert(...args);
        }
    }

    const toggleFullBatteryAlert = (...args) => {
        if (!props.disabled) {
            const analyticsData = {
                optin_fullbattery: getAnalyticStatusBy(!isActiveFullBatteryAlert),
            };

            props.toggleFullBatteryAlert(...args);
        }
    }

    const saveNewDefaultPercentage = (...args) => {
        if (!props.disabled) {
            logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.SAVE});
            props.saveNewDefaultPercentage(...args);
        }
    }

    const saveNewDefaultTime = (...args) => {
        if (!props.disabled) {
            props.saveNewDefaultTime(...args);
        }
    }

    const saveCurrentPercSeleted = () => {
        if (!props.disabled) {
            setIsOpenLowBatteryPanel(false);

            if (typeof saveNewDefaultPercentage === "function") {
                saveNewDefaultPercentage(percSelected.currentPerc);
                logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.RECHARGE_REMINDERS})
            }
        }
    }

    const saveCurrentTimeSeleted = () => {
        if (!props.disabled) {
            setIsOpenCheckBatteryPanel(false);

            if (typeof saveNewDefaultTime === "function") {
                saveNewDefaultTime(timeSelected);
            }
        }
    }

    return (
        <React.Fragment>
            <div className={`battery-settings-box ${props.disabled ? 'disabled' : ''}`}>
                <div className="d-flex align-items-center">
                    <div className="battery-settings-box-title">
                        {dictionary.BATT_PREF_LOW_BATTERY_ALERT_TITLE}
                    </div>
                    <div className="form-check form-switch ms-auto">
                        <input className="form-check-input" type="checkbox" id="low-battery-alert-toggle"
                            checked={isActiveLowBatteryAlert}
                            value={isActiveLowBatteryAlert}
                            disabled={props.disabled}
                            onChange={() => toggleLowBatteryAlert(!isActiveLowBatteryAlert)} />
                        <label className="form-check-label" htmlFor="low-battery-alert-toggle"></label>
                    </div>
                </div>
                <div className="row align-items-center mt-2">
                    <div className="col-8">
                        <div className="battery-settings-box-text">
                            {dictionary.BATT_PREF_LOW_BATTERY_ALERT_BODY}
                        </div>
                    </div>
                    <div className="col-4 text-end">
                        <button className="btn btn-outline-secondary"
                            onClick={() => {logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW,{screen_name: AnalyticsScreenEventNames.LOW_BATTERY_PICKER}); setIsOpenLowBatteryPanel(true)}}
                            disabled={!isActiveLowBatteryAlert || props.disabled}
                        >{defaultPerc}</button>
                    </div>
                </div>
            </div>
            <div className={`battery-settings-box ${props.disabled ? 'disabled' : ''}`}>
                <div className="d-flex align-items-center">
                    <div className="battery-settings-box-title">
                        {dictionary.BATT_PREF_CHECK_BATTERY_TITLE}
                    </div>
                    <div className="form-check form-switch ms-auto">
                        <input className="form-check-input" type="checkbox" id="check-battery-toggle"
                            checked={isActiveCheckBatteryAlert}
                            value={isActiveCheckBatteryAlert}
                            disabled={props.disabled}
                            onChange={() => toggleCheckBatteryAlert(!isActiveCheckBatteryAlert)} />
                        <label className="form-check-label" htmlFor="check-battery-toggle"></label>
                    </div>
                </div>
                <div className="row align-items-center mt-2">
                    <div className="col-7">
                        <div className="battery-settings-box-text">
                            {dictionary.BATT_PREF_CHECK_BATTERY_BODY}
                        </div>
                    </div>
                    <div className="col-5 text-end">
                        <button className="btn btn-outline-secondary time"
                            onClick={() => {setIsOpenCheckBatteryPanel(true)}}
                            disabled={!isActiveCheckBatteryAlert || props.disabled}
                        >{`${defaultTime.hour}:${defaultTime.min} ${defaultTime.meridiem}`}</button>
                    </div>
                </div>
            </div>
            <div className={`battery-settings-box ${props.disabled ? 'disabled' : ''}`}>
                <div className="d-flex align-items-center">
                    <div className="battery-settings-box-title">
                        {dictionary.BATT_PREF_FULL_BATTERY_ALERT_TITLE}
                    </div>
                    <div className="form-check form-switch ms-auto">
                        <input className="form-check-input" type="checkbox" id="full-battery-alert-toggle"
                            checked={isActiveFullBatteryAlert}
                            value={isActiveFullBatteryAlert}
                            disabled={props.disabled}
                            onChange={() => toggleFullBatteryAlert(!isActiveFullBatteryAlert)} />
                        <label className="form-check-label" htmlFor="full-battery-alert-toggle"></label>
                    </div>
                </div>
                <div className="row align-items-center mt-2">
                    <div className="col-9">
                        <div className="battery-settings-box-text">
                            {dictionary.BATT_PREF_FULL_BATTERY_ALERT_BODY}
                        </div>
                    </div>
                </div>
            </div>

            <PickerPanel
                isOpenPanel={isOpenLowBatteryPanel}
                panelTitle={dictionary.BATT_PREF_LOW_BATTERY_ALERT_HEADER}
                panelDescription={dictionary.BATT_PREF_LOW_BATTERY_ALERT_SUBHEADER}
                onPanelDismiss={() => {
                    setIsOpenLowBatteryPanel(false);
                    setPercSelected({ currentPerc: defaultPerc });
                }}
                pickerOptions={percOptions}
                pickerSelected={percSelected}
                onPickerSelection={(label, value) => {
                    setPercSelected({ [label]: value });
                    logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.LOW_BATTERY_ALERT_THRESHOLD, field_value: value} );
                }}
                buttonLabel={dictionary.BATT_PREF_SAVE_BTN_CHOOSE_0_ctaLabel}
                onPanelClose={() => saveCurrentPercSeleted()}
            />

            <PickerPanel
                isOpenPanel={isOpenCheckBatteryPanel}
                panelTitle={dictionary.BATT_PREF_BATTERY_ALERT_HEADER}
                panelDescription={dictionary.BATT_PREF_BATTERY_ALERT_SUBHEADER}
                onPanelDismiss={() => {
                    setIsOpenCheckBatteryPanel(false);
                    setTimeSelected(defaultTime);
                }}
                pickerOptions={timeOptions}
                pickerSelected={timeSelected}
                onPickerSelection={(label, value) => {
                    setTimeSelected({ ...timeSelected, [label]: value });
                }}
                buttonLabel={dictionary.BATT_PREF_SAVE_BTN_CHOOSE_0_ctaLabel}
                onPanelClose={saveCurrentTimeSeleted}
            />

        </React.Fragment>
    )

}