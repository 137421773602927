import React, { createRef } from 'react';
import { imagesConstants, routingConstants, aemPages, AnalyticsEvents, AnalyticsTargetNames, AnalyticsTargetEventsNames } from '../../_constants';
import { connect } from 'react-redux';
import { CustomSlidingPanel, Header, PageSelectorCTA } from '../../_components';
import { userActions, setDoneOnboardingBenefits } from '../../_actions';
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';
import { AppStories } from '../../_components/AppStories';
import { AEMHelper } from '../../_helpers/aem/aemhelper';
import parse from 'html-react-parser';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { Tenants } from '../../_helpers/tenants';
import { Webview } from '../../_helpers/webview';
import { convertObjectToQueryString } from '../../_helpers/utils';

class OnboardingBenefits extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showVerifyAccountPanel: false,
            userEmail: ""
        }

        this.goToSignup = this._goToSignup.bind(this);
        this.goToLogin = this._goToLogin.bind(this);
        this.goToPrivacyPolicy = this._goToPrivacyPolicy.bind(this)
        this.sendAgainConfirmationEmail = this._sendAgainConfirmationEmail.bind(this);


        this.aem = new AEMHelper();
        this.loginChoosePage = this.aem.getPage(aemPages.REG_LOGIN_CHOOSE);
        this.vagePage = this.aem.getPage(aemPages.VERIFY_ACCOUNT);

        let urls = this.aem?.getCarouselUrls(aemPages.REG_LOGIN_CHOOSE, 'carousel', 'mobile');
        let stories = urls.map(url => {
            return {
                url: url,
                type: 'video',
                muted: true
            }
        })

        this.stories = stories;

        this.healthWarningRef = createRef(null);
        this.pageSelectorCTARef = createRef(null);

    }

    componentDidMount() {
        document.body.classList.add("onboarding-benefits");
        setTimeout(() => {
            this.setState({ showVerifyAccountPanel: this.props.history.location.state?.isAfterRegistration });
            this.setState({ userEmail: this.props.history.location.state?.userEmail });
        }, 500);
    }

    componentWillUnmount() {
        document.body.classList.remove("onboarding-benefits");
    }

    _goToSignup() {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CREATE_ACCOUNT})
        setDoneOnboardingBenefits(true);
        history.push(buildURI(routingConstants.SIGN_UP));
    }

    _goToLogin() {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.LOGIN_ACCOUNT})
        setDoneOnboardingBenefits(true);
        history.push(buildURI(routingConstants.LOGIN));
    }

    _goToPrivacyPolicy() {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.PRIVACY_POLICY})
        Webview.goToMyVusePrivacyPolicy()
    }

    /**
     * Resent account confirmation email 
     */
    _sendAgainConfirmationEmail() {

        const {
            userEmail
        } = this.state;

        const {
            resendActivationLink
        } = this.props;

        resendActivationLink(userEmail, (res) => {
            if (res) {
                this.setState({ showVerifyAccountPanel: false });
            }
        })
    }

    render() {
        const {
            showVerifyAccountPanel,
            userEmail
        } = this.state;


        let USER_CHOICE_BTN_NEED_HELP = this.loginChoosePage?.getComponent(aemPages.REG_LOGIN_CHOOSE, 'USER_CHOICE_BTN_NEED_HELP');
        let SIGNUP_PRIVACY_POLICY_CONTENT = this.loginChoosePage?.getComponent(aemPages.REG_LOGIN_CHOOSE, 'SIGNUP_PRIVACY_POLICY_CONTENT');
        let USER_CHOICE_BTN_LOGIN = this.loginChoosePage?.getComponent(aemPages.REG_LOGIN_CHOOSE, 'USER_CHOICE_BTN_LOGIN');
        let USER_CHOICE_BTN_CREATE = this.loginChoosePage?.getComponent(aemPages.REG_LOGIN_CHOOSE, 'USER_CHOICE_BTN_CREATE');
        let USER_CHOICE_ALREADY_EXISTS = this.loginChoosePage?.getComponent(aemPages.REG_LOGIN_CHOOSE, 'USER_CHOICE_ALREADY_EXISTS');

        let USER_CHOICE_BTN_LOGIN_ctaItems = USER_CHOICE_BTN_LOGIN?.getRaw('ctaItems', null);
        let USER_CHOICE_BTN_LOGIN_ctaLabel = null;

        if (USER_CHOICE_BTN_LOGIN_ctaItems?.length > 0) {
            USER_CHOICE_BTN_LOGIN_ctaLabel = USER_CHOICE_BTN_LOGIN_ctaItems[0].ctaLabel;
        }

        let USER_CHOICE_BTN_CREATE_ctaItems = USER_CHOICE_BTN_CREATE?.getRaw('ctaItems', null);
        let USER_CHOICE_BTN_CREATE_ctaLabel = null;

        if (USER_CHOICE_BTN_CREATE_ctaItems?.length > 0) {
            USER_CHOICE_BTN_CREATE_ctaLabel = USER_CHOICE_BTN_CREATE_ctaItems[0].ctaLabel;
        }


        let REGISTRATION_EMAIL_CONFIRMATION_HEADER = this.vagePage?.getComponent(aemPages.VERIFY_ACCOUNT, 'REGISTRATION_EMAIL_CONFIRMATION_HEADER');
        let REGISTRATION_EMAIL_CONFIRMATION_CONTENT = this.vagePage?.getComponent(aemPages.VERIFY_ACCOUNT, 'REGISTRATION_EMAIL_CONFIRMATION_CONTENT');
        let REGISTRATION_BTN_EMAIL_NOT_RECEIVED = this.vagePage?.getComponent(aemPages.VERIFY_ACCOUNT, 'REGISTRATION_BTN_EMAIL_NOT_RECEIVED');
        let REGISTRATION_BTN_CONTINUE_LOGIN = this.vagePage?.getComponent(aemPages.VERIFY_ACCOUNT, 'REGISTRATION_BTN_CONTINUE_LOGIN');

        let USER_CHOICE_DARK_HEADING = this.vagePage?.getComponent(aemPages.REG_LOGIN_CHOOSE, 'USER_CHOICE_DARK_HEADING');

        let REGISTRATION_BTN_CONTINUE_LOGIN_ctaItems = REGISTRATION_BTN_CONTINUE_LOGIN?.getRaw('ctaItems', null);
        let REGISTRATION_BTN_CONTINUE_LOGIN_ctaLabel = null;

        if (REGISTRATION_BTN_CONTINUE_LOGIN_ctaItems?.length > 0) {
            REGISTRATION_BTN_CONTINUE_LOGIN_ctaLabel = REGISTRATION_BTN_CONTINUE_LOGIN_ctaItems[0].ctaLabel;
        }

        const isDarkMarket = Tenants.isCanadaDark();

        const pageSelectorCTARect = this.pageSelectorCTARef.current?.getBoundingClientRect();
        const pageSelectorCTAHeight = pageSelectorCTARect?.height || 0;

        return (
            <div className="h-100">
                <div className="onboarding-page">
                    <Header transparent={true} />
                    <div className="onboarding-page-stories">
                        <div className="onboarding-page-stories-video" style={{ height: `calc(100vh - ${pageSelectorCTAHeight}px)` }}>
                            <AppStories stories={this.stories} analyticsNoDevice={true}/>
                        </div>
                    </div>
                    <PageSelectorCTA ref={this.pageSelectorCTARef} ctaUrl={true}>
                        {!isDarkMarket && <p>{parse(USER_CHOICE_ALREADY_EXISTS?.getRaw('text', null) ?? '')}</p>}
                        <div className="d-grid gap-2">
                            <button className="btn btn-primary text-uppercase" onClick={this.goToSignup}>{USER_CHOICE_BTN_CREATE_ctaLabel}</button>
                            <button className="btn btn btn-outline-secondary text-uppercase" onClick={this.goToLogin}>{USER_CHOICE_BTN_LOGIN_ctaLabel}</button>
                        </div>
                        <div className="page-bottom-cta-url">
                            <span className="url-text" onClick={() => {
                                logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.PRIVACY_POLICY})
                                history.push(buildURI(routingConstants.PRIVACY_POLICY) + convertObjectToQueryString({ canHandleBack: true }), {type: 'slide', backTo: routingConstants.BENEFITS});
                                }
                            }>{USER_CHOICE_BTN_NEED_HELP?.getRaw('text', null)}</span>
                        </div>
                    </PageSelectorCTA>
                    {/* <div className="onboarding-welcome onboarding-welcome-benefits">
                        {!isDarkMarket && <span>{parse(USER_CHOICE_ALREADY_EXISTS?.getRaw('text', null) ?? '')}</span>}
                        <div className="page-bottom-cta p-0 mt-2">
                            <div className="d-grid gap-2">
                                <button className="btn btn-primary text-uppercase" onClick={this.goToSignup}>{USER_CHOICE_BTN_CREATE_ctaLabel}</button>
                                <button className="btn btn btn-outline-secondary text-uppercase" onClick={this.goToLogin}>{USER_CHOICE_BTN_LOGIN_ctaLabel}</button>
                            </div>
                            <div className="page-bottom-cta-url">
                                <span className="url-text" onClick={() => Webview.goToMyVusePrivacyPolicy()}>{USER_CHOICE_BTN_NEED_HELP?.getRaw('text', null)}</span>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="onboarding-page-benefits-bg"></div>
                <CustomSlidingPanel from="bottom"
                    isOpen={showVerifyAccountPanel}
                    overlayClassName={"sliding-common-wrapper"}
                    className="onboarding-panel mid-height-panel"
                    title={REGISTRATION_EMAIL_CONFIRMATION_HEADER?.getRaw('heading', null)}>

                    <div className="h-100 d-flex flex-column">

                        <div className="sliding-panel-mid">
                            <div className="sliding-panel-mid-icon">
                                <img src={imagesConstants.NEW_EMAIL} alt="email" />
                            </div>
                            <div className="sliding-panel-mid-info">
                                {parse(REGISTRATION_EMAIL_CONFIRMATION_CONTENT?.getRaw('text', null) ?? '')}
                            </div>
                            <div className="sliding-panel-mid-value d-flex align-items-center justify-content-center">
                                <span className="text-truncate">{userEmail}</span>
                            </div>
                        </div>
                        <PageSelectorCTA sticky={false} ctaUrl={true} className="mt-auto">
                            <div className="d-grid gap-2">
                                <button className="btn btn-primary text-uppercase" type="button" onClick={this.goToLogin}>
                                    {REGISTRATION_BTN_CONTINUE_LOGIN_ctaLabel}
                                </button>
                            </div>
                            <div className="page-selector-cta-url">
                                <span className="url-text" onClick={this.sendAgainConfirmationEmail}>
                                    {parse(REGISTRATION_BTN_EMAIL_NOT_RECEIVED?.getRaw('text', null) ?? '')}
                                </span>
                            </div>
                        </PageSelectorCTA>
                    </div>
                </CustomSlidingPanel>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getConfirmationLink: (formData, successCb, failureCb) => {
            dispatch(userActions.getConfirmationLink(formData, successCb, failureCb));
        },
        resendActivationLink: (email, successCb, failureCb) => dispatch(userActions.resendActivationLink(email, successCb, failureCb)) 
    };
}

const connnectedOnboardingBenefits = connect(mapStateToProps, mapDispatchToProps)((OnboardingBenefits));
export { connnectedOnboardingBenefits as OnboardingBenefits };