export const VUSE_WORLD_CAROUSELS = {
  VUSEWORLD_CAROUSEL_FEATURED: 'VUSEWORLD_CAROUSEL_FEATURED',
  VUSEWORLD_CAROUSEL_LATESTFROMVUSE: 'VUSEWORLD_CAROUSEL_LATESTFROMVUSE',
  VUSEWORLD_CAROUSEL_ABOUTVUSE: 'VUSEWORLD_CAROUSEL_ABOUTVUSE',
}

export const vuseWorldAction = {
    FIRMWARE_NEW_FW_AVAILABLE: 'FIRMWARE:NEW_FW_AVAILABLE',
    SUGGESTIONS_ENABLE_FIND_MY_VAPE: 'SUGGESTIONS:ENABLE_FIND_MY_VAPE',
    SUGGESTIONS_GO_TO_FLAVORS: 'SUGGESTIONS:GO_TO_FLAVORS',
    SUGGESTIONS_GO_TO_CLOUD_CONTROL: 'SUGGESTIONS:GO_TO_CLOUD_CONTROL',
    SUGGESTIONS_GO_TO_PAIR_DEVICE: 'SUGGESTIONS:GO_TO_PAIR_DEVICE',
    SUGGESTIONS_GO_TO_RECHARGE_REMINDER: 'SUGGESTIONS:GO_TO_RECHARGE_REMINDER',
    SUGGESTIONS_GO_TO_WISHLIST: 'SUGGESTIONS:GO_TO_FAVOURITES',
    ORDER_REMINDER_GO_TO_FAVOURITES: 'ORDER_REMINDER:GO_TO_FAVOURITES',

    GO_TO_FIND_MY_VAPE: 'CTA:GO_TO_FIND_MY_VAPE',
    GO_TO_CLOUD_CONTROL: 'CTA:GO_TO_CLOUD_CONTROL',
    GO_TO_PAIR_DEVICE: 'CTA:GO_TO_PAIR_DEVICE',
    GO_TO_BATTERY_CONTROL: 'CTA:GO_TO_BATTERY_CONTROL',
    GO_TO_REORDER: 'CTA:GO_TO_REORDER',
    GO_TO_FLAVOUR_FOR_YOU: 'CTA:GO_TO_FLAVOUR_FOR_YOU',
    GO_TO_FAVOURITES: 'CTA:GO_TO_FAVOURITES',
    GO_TO_DEVICE_SETTINGS: 'CTA:GO_TO_DEVICE_SETTINGS',
    GO_TO_WEBVIEW: 'CTA:GO_TO_WEBVIEW',
    GO_TO_USAGE_TRACKER: 'CTA:GO_TO_USAGE_TRACKER',
    GO_TO_SUBSCRIPTION: 'CTA:GO_TO_SUBSCRIPTION',
    GO_TO_VUSE_WORLD: 'CTA:GO_TO_VUSE_WORLD',
    GO_TO_MY_ACCOUNT: 'CTA:GO_TO_MY_ACCOUNT',
    GO_TO_MANAGE_NOTIFICATIONS: 'CTA:GO_TO_MANAGE_NOTIFICATIONS',
    GO_TO_MY_PREFERENCES: 'CTA:GO_TO_MY_PREFERENCES',
    GO_TO_FAQ_MYVUSE: 'CTA:GO_TO_FAQ_MYVUSE',
    GO_TO_DEVICE_HELP: 'CTA:GO_TO_DEVICE_HELP',
    GO_TO_CONTACT_US: 'CTA:GO_TO_CONTACT_US',
    GO_TO_HOME: 'CTA:GO_TO_HOME',
    GO_TO_BUY_NOW: 'CTA:GO_TO_BUY_NOW',
    GO_TO_STORE_LOCATOR: 'CTA:GO_TO_STORE_LOCATOR',
}