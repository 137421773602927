import React, { useState, useEffect, useCallback, useMemo} from 'react';
import _ from 'lodash';
import InfiniteScroll from "react-infinite-scroll-component";
import { dashboardActions, tipsActions } from '../../_actions';
import { CustomModal } from '../../_components/CustomModal';
import { AnalyticsEvents, AnalyticsScreenEventNames, aemPages, imagesConstants, notificationsCategoriesConstants, routingConstants } from '../../_constants';
import { store } from '../../_store';
import { Commons } from '../../_helpers/commons';
import { Notifications } from '../../_helpers/notifications';
import { Header, ToasterNotification } from '../../_components';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { AEMHelper, propertyHeadingDefaultEmpty, propertyTextDefaultEmpty } from '../../_helpers/aem/aemhelper';
import { debug } from "../../_helpers/debug";
import { SwipeToDelete } from '../../_components/SwipeToDelete';
import { NotificationPermissions } from '../NotificationPermissions';
import { NotificationPermissionHandler } from '../../_components/NotificationPermissionPanels/NotificationPermissionHandler';

export const NotificationCenter = (props) => {
    const [dictionary, setDictionary] = useState({});

    const [notifications, setNotifications] = useState([]);
    const [isOpenPanel, setIsOpenPanel] = useState(false);
    const [notifPermissionActivePanelType, setNotifPermissionActivePanelType] = useState(null);

    /**
     * When panel is mounted ask parent to load first chunck of notifications, for:
     * - get the "read" information for bell icon in homepage
     * - load something to be shown the first time user opens notification center
     */
    useEffect(() => {
        const aem = new AEMHelper();

        setDictionary(aem.getDictionary(aemPages.HOMEPAGE, {
            HOME_NOTIF_CENTRE_HEADER: propertyHeadingDefaultEmpty,
            HOME_NO_NOTIFICATIONS: propertyTextDefaultEmpty,
            HOME_SWIPE_LEFT: propertyTextDefaultEmpty
        }));

        //Notifications.handleNotificationCenterNotifications();
    }, []);

    useEffect(() => {
        if (isOpenPanel) {
            logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
                screen_name: AnalyticsScreenEventNames.NOTIF_HISTORY
            })
            //setNotificationsRead();
        }
    }, [isOpenPanel]);

    useEffect(() => {
        setNotifications(prepareNotifications());
        console.log('[NOTIFICATIONS] history nextPage', props.nextPage);
    }, [props.notifications]);

    useEffect(() => {
        setIsOpenPanel(props.isShown);
        /*if (props.isShown) {
            setNotificationsRead();
        }*/
    }, [props.isShown]);

    const onClose = () => {
        setNotificationsRead();

        props.onClose()
    }

    const fetchNotifications = () => {

        const {
            fetchNotifications: fetchNewNotificationsChunck,
        } = props;

        fetchNewNotificationsChunck()//.then((events) => setNotificationsRead(events))
    }

    const setNotificationsRead = (events) => {

        const {
            notifications: currentNotifications
        } = props;

        const userPin = store.getState().onboardingReducer.userPin;
        const tenantUserId = Commons.generateTenantUserId(userPin);

        let allNotifications = currentNotifications;
        if (events !== null && events !== undefined) {
            allNotifications = events;
        }
        const ids = _.compact(allNotifications.map((el) => el.read ? null : el.event_id));

        if (ids.length > 0) {
            store.dispatch(dashboardActions.setNotificationRead(tenantUserId, { ids: ids }));
        }
    }

    const prepareNotifications = () => {
        const { notifications: currentNotifications } = props;

        let result = [];

        if (currentNotifications) {
            currentNotifications.forEach((notificationObj) => {
                debug(`[Notification] prepareNotifications notification`, notificationObj);
                let notification = notificationObj?.message_args[0]
                    ? {
                        ...Notifications.getNotificationObjFromRemoteNotification(
                            Commons.parseJSONSafely(notificationObj?.message_args[0])
                        ),
                        generatedAt: notificationObj.timestamp,
                        id: notificationObj?.event_id,
                        new: !notificationObj.read
                    }
                    : null;

                    result.push(notification);
            });
        }

        result = Commons.sortArrayAlphabetically(result, "generatedAt", true);
        return result;
    }

    const handleNotificationAction = (notification) => {
        logAnalyticsEvent(AnalyticsEvents.NOTIFICATION_HISTORY_OPEN, {
            label: notification.analytics || notification.type
        })
        Notifications.handleRemoteNotificationAction(notification);
    }

    const handleNotificationClose = (notification) => {
        notification = notification.toObj === 'function' ? notification.toObj() : notification;

        const tenantUserId = Commons.generateTenantUserId(store.getState().onboardingReducer.userPin);
        store.dispatch(dashboardActions.deleteRemoveNotification(tenantUserId, notification.id));

        //notifications of some categories, after being closed, must be removed also physically from reducer
        //because those shouldn't be visible in notification history
        if (
            notificationsCategoriesConstants.BATTERY === notification.category ||
            notificationsCategoriesConstants.DEVICE_STATUS === notification.category
        ) {
            store.dispatch(tipsActions.removeTip(notification.id));
        }
    }

    const renderNotificationsList = () => {
        return (
            <React.Fragment>
                <div className="notifications-container">
                {
                    notifications.map((notification, idx) => {
                        let additionalClasses = "notification-container";
                        additionalClasses += notification.sticky
                            ? ' notification-sticky'
                            : '';
                        additionalClasses += notification.category === notificationsCategoriesConstants.DEVICE_STATUS
                            ? " notification-status"
                            : "";

                        return (
                            <SwipeToDelete key={notification.id} onDelete={() => handleNotificationClose(notification)} background={<div className="delete-container"><span className="bat-icon-trash"></span></div>}>
                                <div key={`notif-${idx}`}
                                    className={`Toastify__toast Toastify__toast-theme--light Toastify__toast--default ${additionalClasses}`}>
                                    <div role="alert" className="Toastify__toast-body notification-body ps-0">
                                        <ToasterNotification
                                            onClick={() => handleNotificationAction(notification)}
                                            notification={notification}
                                            showTimeFrame
                                        />
                                    </div>
                                </div>
                            </SwipeToDelete>
                        );
                    })
                }
                </div>
            </React.Fragment>
        )
    }

    //fix: not getting a new page if the loader is not outside the scroll
    //https://github.com/ankeetmaini/react-infinite-scroll-component/issues/391
    const useFixMissingScroll = ({ nextPage, fetchMoreItems }) => {
        const mainElement = useMemo(() => document.querySelector('.infinite-scroll-component'), [])
      
        const fetchCb = useCallback(() => {
            fetchMoreItems()
        }, [fetchMoreItems])
      
        useEffect(() => {
            const hasScroll = mainElement ? mainElement.scrollHeight > mainElement.clientHeight : false
            if (!hasScroll && !_.isEmpty(nextPage)) {
                setTimeout(() => {
                    fetchCb()
                }, 100)
            }
        }, [nextPage])
    }

    useFixMissingScroll({
        nextPage: props.nextPage,
        fetchMoreItems: fetchNotifications
    })

    return (
        <>
            <CustomModal
                isOpen={isOpenPanel}
                isNotification={true}
                className={"pdp-notification-center-modal"}>

                <Header
                    rightButton={{
                        icon: <span className="bat-icon-close" />,
                        onClick: onClose,
                    }}
                >
                    {dictionary?.HOME_NOTIF_CENTRE_HEADER}
                </Header>

                <div className="notifications-center-wrapper">
                    {!props.loading && _.isEmpty(notifications) &&
                    <div className="updates-empty">
                        <div className="updates-empty-icon">
                            <span className="bat-icon-bell" />
                        </div>
                        <div className="updates-empty-title">{dictionary?.HOME_NO_NOTIFICATIONS}</div>
                    </div>
                    }
                    {props.loading || !_.isEmpty(notifications) &&
                    <>
                        <InfiniteScroll
                            dataLength={notifications.length}
                            next={fetchNotifications}
                            hasMore={!_.isEmpty(props.nextPage)}
                            loader={
                                <div className="d-flex justify-content-center mt-2">
                                    <div className="spinner-border text-secondary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            }>
                            <div className="notifications-center-container">
                                {
                                    !_.isEmpty(notifications) &&

                                        <React.Fragment>
                                            {renderNotificationsList(notifications)}
                                        </React.Fragment>

                                }
                            </div>
                        </InfiniteScroll>
                        <div className="swipe-info">
                            {dictionary.HOME_SWIPE_LEFT}
                        </div>
                    </>
                    }
                </div>
                <div className="page-linear-gradient-bg"></div>

                <NotificationPermissionHandler
                    notifPermissionActivePanelType={notifPermissionActivePanelType}
                    overrideBottom={0}
                />

            </CustomModal>

            {isOpenPanel ? 
            <NotificationPermissions title={dictionary?.HOME_NOTIF_CENTRE_HEADER} from={routingConstants.NOTIFICATION_HISTORY_OPEN} setNotifPermissionActivePanelType={setNotifPermissionActivePanelType} onClose={props.onClose}/>
            : null }
        </>
    );

}