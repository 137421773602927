import React from "react"
import AnimateHeight from "react-animate-height"


export const UsageTrackerTooltip = ({isOpen, title, content, closeToolTip}) => {
    return (
        <AnimateHeight height={isOpen ? "auto" : 0}>
          <div className="session-info">
            <div className="usage-icon">
              <span className="bat-icon-usage" />
            </div>
            <div className="session-info-title">
              {title}
            </div>
            <div className="session-info-content">
              {content}
            </div>
            <div className="session-close-icon">
              <span className="bat-icon-close" onClick={closeToolTip} />
            </div>
          </div>
        </AnimateHeight>
    )
}