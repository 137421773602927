import { isEmpty, isNil } from "lodash";
import moment from "moment/moment";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useInterval, useTimeout } from "usehooks-ts";
import {
  ageVerificationActions,
  getRegistration,
  getUserData,
  setRegistration,
  userActions,
} from "../../_actions";
import { getLegalAge, getTenantId } from "../../_actions/appConfig.actions";
import { HardAgeVerificationProcessing } from "../../_components/HardAgeVerification/HardAgeVerificationProcessing";
import {
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  HARD_AGE_VERIFICATION_STEPS,
  aemPages,
  imagesConstants,
  routingConstants,
} from "../../_constants";
import {
  AEMHelper,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { debug } from "../../_helpers/debug";

import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";
import { Header } from "../Header";
import { Tenants } from "../../_helpers/tenants";

const POLLING_INTERVAL = 10 * 1000;
const WAITING_TIMEOUT = 15 * 1000;

function HardAgeVerificationDocScan({
  session,
  uploadingIsComplete,
  userData,
  createSession,
  resetSession,
  setUploadingIsComplete,
  getVerificationResult,
  updateUserInfo,
  goToStep,
  onSubmit
}) {
  const [dictionary, setDictionary] = useState({});
  const [sessionData, setSessionData] = useState();
  const [verificationIsComplete, setVerificationIsComplete] = useState(false);
  const [verificationText, setVerificationText] = useState("");
  const [showContacts, setShowContacts] = useState(false);

  const isAgeVerified = useCallback(
    (data) => {
      const checks = data.checks;
      let recommendation = checks[0].report?.recommendation?.value
      let registration = getRegistration()
      const attempt = registration?.docScanAttempts
      const documentMediaContent = data.document_media_content.find(
        (doc) => !isEmpty(doc)
      );

      if (!documentMediaContent) {
        logAnalyticsEvent(AnalyticsEvents.YOTI_DOC_SCAN_FAILURE, {error: 'missing_media', attempts_left: attempt})
        return false;
      }

      const lastname = documentMediaContent.family_name;
      const dateOfBirth = documentMediaContent.date_of_birth;

      if (!Tenants.isGlobal()) {
        userData = getRegistration()
      }

      debug("userData: ", JSON.stringify(userData));

      debug(
        "CHECKS PASSED: ",
        checks.every((c) => c.report.recommendation.value === "APPROVE")
      );
      debug(
        "LASTNAME PASSED: ",
        lastname.toLowerCase() === userData?.lastName.toLowerCase()
      );
      debug(
        "AGE PASSED: ",
        moment().diff(dateOfBirth, "years") >= getLegalAge()
      );

      /**
       * registration.birthday = {day: valueGroups.day, month: monthNumber, year: valueGroups.year}
        setRegistration(registration)
       */
      if(
        isNil(recommendation) || 
        recommendation === 'REJECT' ||
        recommendation === 'NOT_AVAILABLE'
      ){
        logAnalyticsEvent(AnalyticsEvents.YOTI_DOC_SCAN_FAILURE, {error: recommendation, attempts_left: attempt})
      }

      if(  
        lastname.toLowerCase().trim() === userData?.lastName.toLowerCase().trim() &&
        moment().diff(dateOfBirth, "years") >= getLegalAge() && 
        moment(dateOfBirth, "YYYY-MM-DD").isSame(moment(userData?.birthday?.day + '/' + userData?.birthday?.month + '/' + userData?.birthday?.year, "DD/MM/YYYY"))
      ){

      }else {
        logAnalyticsEvent(AnalyticsEvents.YOTI_DOC_SCAN_FAILURE, {error: 'data_mismatch', attempts_left: attempt})
      }

      return (
        checks.every((c) => c.report.recommendation.value === "APPROVE") &&
        lastname.toLowerCase().trim() === userData?.lastName.toLowerCase().trim() &&
        moment().diff(dateOfBirth, "years") >= getLegalAge() && 
        moment(dateOfBirth, "YYYY-MM-DD").isSame(moment(userData?.birthday?.day + '/' + userData?.birthday?.month + '/' + userData?.birthday?.year, "DD/MM/YYYY"))
      );
    },
    [userData]
  );

  const addScanAttempt = (registration) => {
    let attempt = 1
    if (registration?.docScanAttempts) {
      attempt = parseInt(registration?.docScanAttempts) + 1
    }

    registration.docScanAttempts = attempt
    registration.docScanLast = Date.now()

    return registration
  }

  const askForVerificationResult = useCallback(() => {
    getVerificationResult(
      sessionData.sessionId,
      (data) => {
        //debug("TenantId", getTenantId());
        debug("Verification Result: ", data);

        if (data?.state === "COMPLETED") {
          // setUploadingIsComplete(false);

          if (isAgeVerified(data)) {
            debug("State: Age Verified!");
            logAnalyticsEvent(AnalyticsEvents.YOTI_DOCUMENT_SCAN_SUCCESS);
            setVerificationIsComplete(true);
            setVerificationText(dictionary.YOTI_AV_VERIFIED_LABEL);
            setShowContacts(false);
            resetSession();

            if (Tenants.isGlobal()) {
              updateUserInfo(
                { isUserAgeVerified: true },
                () => {
                  history.push(buildURI(routingConstants.TERMS_AND_CONDITIONS));
                },
                (err) => {
                  console.debug(err);
                  debug(err);
                }
              );
            } else {
              let registration = getRegistration()
              registration.isValid = true
              registration.verificationMethod = 'ID_VERIFICATION'
              registration = addScanAttempt(registration)
              setRegistration(registration)

              if (Tenants.isCanadaDark()) {
                onSubmit()
              } else {
                history.push(buildURI(routingConstants.SIGN_UP))
              }
            }
          } else {
            let registration = getRegistration()
            registration = addScanAttempt(registration)
            setRegistration(registration)

            debug("State: One or more checks failed");
            setUploadingIsComplete(false);
            resetSession();
            goToStep(HARD_AGE_VERIFICATION_STEPS.DOC_SCAN_FAILURE);
          }
        }
      },
      (err) => {
        debug("ERROR: ", err);
        setUploadingIsComplete(false);
        resetSession();
        goToStep(HARD_AGE_VERIFICATION_STEPS.DOC_SCAN_FAILURE);
      }
    );
  }, [
    sessionData,
    getVerificationResult,
    setUploadingIsComplete,
    dictionary.YOTI_AV_VERIFIED_LABEL,
    isAgeVerified,
    goToStep,
    resetSession,
    updateUserInfo,
  ]);

  useTimeout(
    () => {
      debug("This might take up to 5 minutes.");
      logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
        screen_name: AnalyticsScreenEventNames.YOTI_DOC_SCAN_PROCESSING_LONG,
      })
      setVerificationIsComplete(false);
      setVerificationText(dictionary.YOTI_AV_WAITING_TIME_CONTENT);
      setShowContacts(true);
    },
    uploadingIsComplete && !verificationIsComplete ? WAITING_TIMEOUT : null
  );

  useInterval(
    () => {
      debug("askForVerificationResult...");
      askForVerificationResult();
    },
    uploadingIsComplete ? POLLING_INTERVAL : null
  );

  const handleSuccess = useCallback(
    (data) => {
      debug("YOTI_SCAN_SUCCESS", data);
      askForVerificationResult();
      setUploadingIsComplete(true);
    },
    [setUploadingIsComplete, askForVerificationResult]
  );

  const handleFailure = useCallback(
    (data) => {
      debug("YOTI_SCAN_FAILURE", data);
      setUploadingIsComplete(false);
      resetSession();
      goToStep(HARD_AGE_VERIFICATION_STEPS.DOC_SCAN_FAILURE);
    },
    [goToStep, setUploadingIsComplete, resetSession]
  );

  const handleMessage = useCallback(
    (event) => {
      const eventType = event.data.eventType;

      if (eventType === "SUCCESS") {
        handleSuccess(event.data);
      } else if (eventType === "ERROR") {
        handleFailure(event.data);
      }
    },
    [handleSuccess, handleFailure]
  );

  useEffect(() => {
    const registration = getRegistration()

    if (registration?.docScanAttempts) {
      const attempt = registration?.docScanAttempts

      if (attempt >= 3) {
        if (registration?.docScanLast) {
          const lastAttempt = new Date(registration?.docScanLast)
  
          const twoDaysAgo = new Date();
          twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
  
          if (lastAttempt >= twoDaysAgo) {
            goToStep(HARD_AGE_VERIFICATION_STEPS.DOC_SCAN_FAILURE);
          }
        }
      }
    }

    const aem = new AEMHelper();
    const dict = aem.getDictionary(aemPages.HARD_AGE_VERIFICATION, {
      YOTI_AV_PROCESSING_LABEL: propertyTextDefaultEmpty,
      YOTI_AV_WAITING_TIME_CONTENT: propertyTextDefaultEmpty,
      YOTI_AV_VERIFIED_LABEL: propertyTextDefaultEmpty,
      YOTI_AV_FIRST_ERROR_SUPPORT_CONTENT: propertyTextDefaultEmpty,
      YOTI_AV_SUPPORT_NUMBER: propertyTextDefaultEmpty,
      YOTI_AV_SUPPORT_MAIL: propertyTextDefaultEmpty,
    });

    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
      screen_name: AnalyticsScreenEventNames.YOTI_DOCUMENT_SCAN_PROCESSING
    });

    setDictionary(dict);
    setVerificationText(dict.YOTI_AV_PROCESSING_LABEL);
  }, []);

  useEffect(() => {
    if (isNil(sessionData)) {
      createSession(
        (data) => {
          debug("createSessionOk: ", data);
          setSessionData(data);
        },
        (err) => {
          debug("createSessionKo:", err);
        }
      );
    } else {
      setSessionData(session);
    }
  }, [createSession, session, sessionData]);

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handleMessage]);

  useEffect(() => {
    if (verificationIsComplete) {
      debug("verificationIsComplete");
    }

    if (verificationText) {
      debug("verificationText");
    }
  }, [verificationIsComplete, verificationText]);

  return (
    <div className="page page-linear-gradient-bg w-100" style={{background: 'none'}}>
      {!uploadingIsComplete && sessionData?.sessionId && sessionData?.token && (
        <iframe
          title="Yoti Verification Identity"
          className="h-100 w-100"
          src={`https://api.yoti.com/idverify/v1/web/index.html?sessionID=${sessionData.sessionId}&sessionToken=${sessionData.token}`}
          allow="camera"
        />
      )}
      {uploadingIsComplete && (
        <>
          <Header />
          <HardAgeVerificationProcessing
            isComplete={verificationIsComplete}
            text={verificationText}
          />
          {showContacts && (
            <div className="hard-age-verification-support alt">
              <small>{dictionary.YOTI_AV_FIRST_ERROR_SUPPORT_CONTENT}</small>
              <ul>
                <li>
                  <span className="bat-icon-phone me-3" />
                  {dictionary.YOTI_AV_SUPPORT_NUMBER}
                </li>
                <li>
                  <img
                    src={imagesConstants.NEW_EMAIL}
                    alt="email icon"
                    className="me-3"
                    style={{ height: "14px" }}
                  />
                  {dictionary.YOTI_AV_SUPPORT_MAIL}
                </li>
              </ul>
            </div>
          )}
          <div className="hard-age-verification-powered-by">
            <img
              src={imagesConstants.HARD_AGE_VERIFICATION_POWERED_BY_YOTI}
              alt="Powered By YOTI"
            />
          </div>
        </>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    session: state?.ageVerificationReducer?.session,
    uploadingIsComplete: state?.ageVerificationReducer?.uploadingIsComplete,
    verificationResult: state?.ageVerificationReducer?.verificationResult,
    userData: state?.loginReducer?.data?.userData || getUserData(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createSession: (successCb, failureCb) =>
      dispatch(ageVerificationActions.createSession(successCb, failureCb)),
    setUploadingIsComplete: (isComplete) =>
      dispatch(ageVerificationActions.setUploadingIsComplete(isComplete)),
    getVerificationResult: (sessionId, successCb, failureCb) =>
      dispatch(
        ageVerificationActions.getVerificationResult(
          sessionId,
          successCb,
          failureCb
        )
      ),
    resetSession: () => dispatch(ageVerificationActions.resetSession()),
    updateUserInfo: (data, successCb, failureCb) =>
      dispatch(userActions.updateUserInfo(data, successCb, failureCb)),
  };
}

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(HardAgeVerificationDocScan);
export { connectedComponent as HardAgeVerificationDocScan };
