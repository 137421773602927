
import { aemConstants } from '../_constants';

export const aemActions = {
    setDebug,
    setLanguages,
    addLanguage,
    setLocale,
    setPage,
    setComponent,
    setCurrentCountry,
    setCurrentLanguage
};

function setDebug(payload) {
    return { type: aemConstants.SET_DEBUG, payload };
}

function setLanguages(payload) {
    return { type: aemConstants.SET_LANGUAGES, payload };
}

function addLanguage(payload) {
    return { type: aemConstants.ADD_LANGUAGE, payload };
}

function setLocale(payload) {
    return { type: aemConstants.SET_LOCALE, payload };
}

function setPage(payload) {
    return { type: aemConstants.SET_PAGE, payload };
}

function setComponent(payload) {
    return { type: aemConstants.SET_COMPONENT, payload };
}

function setCurrentCountry(payload) {
    return { type: aemConstants.SET_CURRENT_COUNTRY, payload };
}

function setCurrentLanguage(payload) {
    return { type: aemConstants.SET_CURRENT_LANGUAGE, payload };
}