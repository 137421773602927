import React from 'react';
import { connect } from 'react-redux';
import { CustomLoadingButton } from '../../_components/CustomLoadingButton';
import {
    aemPages,
    AnalyticsEvents,
    flagStatus,
    imagesConstants,
    thingVuseProperties,
    routingConstants,
    AnalyticsTargetEventsNames
} from '../../_constants';
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyHeadingDefaultEmptyParsed, propertyTextDefaultEmpty, propertyTextDefaultEmptyParsed } from '../../_helpers/aem/aemhelper';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { Commons } from '../../_helpers/commons';
import { retrieveDeviceLocation } from '../../_helpers/device';
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';
import { Header, PageSelectorCTA } from '../../_components';

class FindMyVapeOptin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }

        this.goToTutorial = this._goToTutorial.bind(this);
        this.goToFindMyVape = this._goToFindMyVape.bind(this);
        this.goToInstructions = this._goToInstructions.bind(this);
        this.goToDashboard = this._goToDashboard.bind(this);

        this.aem = new AEMHelper();
        this.dictionary = this.aem.getDictionary(aemPages.FIND_MY_VAPE_OPTIN, {
            FMV_OPTIN_CONTENT: propertyTextDefaultEmptyParsed,
            FMV_OPTIN_BTN_ENABLE: propertyCtaItemsDefaultEmpty,
            FMV_OPTIN_LINK_PREFERENCES: propertyTextDefaultEmptyParsed,
            FMV_OPTIN_HEADER: propertyHeadingDefaultEmptyParsed,
            FMV_OPTIN_LINK_MANAGE_DATA: propertyTextDefaultEmpty,
            FMV_OPTIN_LINK_TUTORIAL: propertyTextDefaultEmpty,
        });
    }

    componentDidMount() {
        if (Commons.getCustomerProperty(thingVuseProperties.OPT_IN_FINDMYVAPE) === flagStatus.ACCEPTED) {
            const alreadySeenTutorial = Commons.getCustomerProperty(thingVuseProperties.FINDMYVAPE);
            history.push(
                buildURI(
                    alreadySeenTutorial !== flagStatus.ACCEPTED
                        ? routingConstants.FIND_MY_VAPE_TUTORIAL
                        : routingConstants.FIND_MY_VAPE
                )
            );
        }
    }

    componentDidUpdate(prevProps) { }

    _goToTutorial() {
        this.props.history.push({
            pathname: buildURI(routingConstants.FIND_MY_VAPE_TUTORIAL),
            state: {
                backUrl: routingConstants.FIND_MY_VAPE_OPTIN,
                type: 'slide'
            },
        });
    }

    _goToFindMyVape() {
        this.setState({ loading: true }, () => {
            Commons.acceptOptIn(thingVuseProperties.OPT_IN_FINDMYVAPE).then(() => {
        
                logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.ENABLE})
                logAnalyticsEvent(AnalyticsEvents.OPT_IN, {target: AnalyticsTargetEventsNames.FIND_MY_VAPE})

                //retrieve current location for all devices currently connected
                let devicesReducer = this.props.devices;
                devicesReducer.map(deviceReducer => retrieveDeviceLocation(deviceReducer.serialNumber))

                this.setState({ loading: false }, () => {
                    history.push(
                        buildURI(routingConstants.FIND_MY_VAPE)
                    );
                });
            });
        });
    }

    _goToInstructions() {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.LEARN_HOW})
        history.push(buildURI(routingConstants.FIND_MY_VAPE_OPTIN_INSTR));
    }

    _goToDashboard() {
        history.push(buildURI(routingConstants.DASHBOARD));
    }

    render() {

        return (
            <div className="optin-wrapper">
                <Header
                    leftButton={{
                        className: "play-tutorial",
                        icon: <span className="play-tutorial-icon" />,
                        onClick: this.goToTutorial,
                    }}
                    rightButton={{
                        icon: <span className="bat-icon-close" />,
                        onClick: this.goToDashboard,
                    }}
                    >
                    {this.dictionary.FMV_OPTIN_LINK_TUTORIAL}
                </Header>
                <div className="optin-content">
                    <div className="optin-icon"> <span style={{ backgroundImage: "url(" + imagesConstants.OPTIN_FIND_VAPE + ")" }}></span></div>

                    <div className="optin-title">
                        <h1>{this.dictionary.FMV_OPTIN_HEADER}</h1>
                    </div>

                    <div className="optin-description">
                        {this.dictionary.FMV_OPTIN_CONTENT}
                    </div>

                    <div className="optin-disclaimer" onClick={this.goToInstructions}>
                        {this.dictionary.FMV_OPTIN_LINK_PREFERENCES}
                    </div>
                </div>

                <PageSelectorCTA className="optin-selector-cta">
                    <div className="optin-branding">
                        Powered by
                        <img src={imagesConstants.GOOGLEMAPS_LOGO} style={{ height: "16px" }} alt="Google Maps logo" />
                        and
                        <img src={imagesConstants.MYVUSE_lOGO} style={{ height: "9px" }} alt="MUSE logo" />
                    </div>
                    <div className="d-grid">
                        <CustomLoadingButton isLoading={this.state.loading} validationMethod={null}
                            buttonLabel={this.dictionary.FMV_OPTIN_BTN_ENABLE_0_ctaLabel} onClickMethod={this.goToFindMyVape} />
                    </div>
                </PageSelectorCTA>

            </div>
        );

    }
}

function mapStateToProps(state) {

    return {
        devices: state.deviceReducer.devices
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)((FindMyVapeOptin));
export { connectedComponent as FindMyVapeOptin };