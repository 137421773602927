import React from 'react';
import { Redirect } from 'react-router-dom';
import { buildURI } from '../../_helpers/navigation';
import { routingConstants } from '../../_constants';


class RootView extends React.Component {
    render() {
        return (
            <Redirect to={{ pathname: buildURI(routingConstants.COUNTRY_SELECTOR) }} />
        );
    }
}

export { RootView };