import React, { useEffect } from "react";
import { routingConstants } from "../../_constants";
import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";
import { useFWUpdateContext } from "./FirmwareUpdateContext";
import { PageSelectorCTA } from "../PageSelectorCTA";

export function FirmwareUpdateSuccess({fromDashboard = false}) {
  const { dictionary, firmwareData, updateDevice, setTitle, close } =
    useFWUpdateContext();

  const handleFinish = () => {
    if (fromDashboard) {
      updateDevice({ ongoingUpdate: false });
      close()
    } else {
      updateDevice({ ongoingUpdate: false });
      history.push(buildURI(routingConstants.DASHBOARD));
    }
  };

  useEffect(() => {
    setTitle(dictionary.FW_UPDATE_COMPLETE_HEADER);
  }, [setTitle]);

  return (
    <>
      <div className="sliding-panel-mid">
        <div className="sliding-panel-mid-icon">
          <span className="bat-icon-download display-1" />
        </div>
        <div className="sliding-panel-mid-info">
          {dictionary.FW_UPDATE_COMPLETE_CONTENT}
        </div>
        {firmwareData?.latestFirmware && (
          <div className="sliding-panel-mid-value d-flex align-items-center justify-content-center mt-5">
            <span className="text-truncate">
              {dictionary.FW_UPDATE_VERS}
              {firmwareData?.latestFirmware}
            </span>
          </div>
        )}
      </div>
      <PageSelectorCTA sticky={false} className="mt-auto">
        <div className="d-grid">
          <button
            className="btn btn-warning text-uppercase"
            type="button"
            onClick={handleFinish}
          >
            {dictionary.FW_UPDATE_BTN_FINISH_0_ctaLabel}
          </button>
        </div>
      </PageSelectorCTA>
    </>
  );
}
