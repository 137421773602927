import cx from "classnames";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { Switch } from '../Switch';
import "react-toggle/style.css";
import {
  AnalyticsEvents,
  AnalyticsTargetEventsNames,
  AnalyticsTutorialNames,
  flagStatus,
  routingConstants,
  thingVuseProperties,
} from "../../_constants";
import { logAnalyticsEvent, logAnalyticsEventForDevice } from "../../_helpers/analytics/logAnalytics";
import { Commons } from "../../_helpers/commons";
import {
  getDeviceInstanceFromSN,
  isDeviceConnected,
} from "../../_helpers/device";

const LockSwitch = ({ className, device }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isTutorialCompleted, setIsTutorialCompleted] = useState(false);
  const [localLocked, setLocalLocked] = useState(false);
  const [connected, setConnected] = useState(false)
  const [waiting, setWaiting] = useState(false)

  const handleChange = async (checked, event) => {
    if (event.movementX === 0) {
      return
    }

    if (!isTutorialCompleted) {
      Commons.goTo(routingConstants.LOCK_SWITCH_TUTORIAL);
      return
    }

    setWaiting(true)
    setLocalLocked(!localLocked)

    const deviceInstance = await getDeviceInstanceFromSN(device?.serialNumber);

    if (deviceInstance) {
      const lockInfo = await deviceInstance.getLockInfo();

      await deviceInstance.setLockConfig({ locked: lockInfo?.locked ? 0 : 1/*, lockMode: 0, bleTimeout: null*/ }, true);

      setWaiting(false)

      logAnalyticsEvent(AnalyticsEvents.DEVICE_LOCK, {locked: !lockInfo?.locked})
    } else {
      setWaiting(false)
    }
  };

  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };

    setIsTutorialCompleted(
      Commons.getCustomerProperty(thingVuseProperties.LOCK_TUTORIAL) ===
        flagStatus.ACCEPTED
    );

    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useEffect(() => {
    setIsDisabled(!isDeviceConnected(device));
    
      getDeviceInstanceFromSN(device?.serialNumber).then((deviceInstance) => {
        if (deviceInstance) {
          if (!connected) {
            deviceInstance.getLockInfo().then((lockInfo) => {
              setLocalLocked(lockInfo?.locked)
              setConnected(true)
            })
          } else {
            if (!waiting) {
              setLocalLocked(device?.lockInfo?.locked)
            }
            setConnected(true)
          }
        } else {
          setLocalLocked(false)
          setConnected(false)
        }
      })
  }, [device]);

  return (
    <div
      className={cx(
        "lock-switch",
        { disabled: isDisabled, checked: localLocked },
        className
      )}
      onClick={!isDisabled && (() => logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.LOCK_SLIDER}))}
    >
      <Switch
        // We are using a dynamic key to force re-render of the component because the way the Switch
        // has been implemented does not allow to update some properties like 'transform, ...' that
        // are defined at the beginning and does not change anymore. This results in some issues.
        // This is the only reason why we use this kind of trick! Please feel free to update it if
        // you find a better solution :)
        key={width}
        disabled={isDisabled}
        checked={localLocked}
        uncheckedIcon={<span className="bat-icon-lock-outline" />}
        checkedIcon={<span className="bat-icon-unlock-outline" />}
        height={84}
        width={width - 48}
        handleDiameter={74}
        borderRadius={15}
        onChange={handleChange}
      />
      <div className="lock-switch-direction">
        <span className="bat-icon-angle-right" />
        <span className="bat-icon-angle-right" />
        <span className="bat-icon-angle-right" />
      </div>
    </div>
  );
};

export { LockSwitch };
