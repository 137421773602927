import React, { useEffect } from 'react';
import { routingConstants, aemPages, AnalyticsEvents, AnalyticsTargetEventsNames } from '../../_constants';
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';
import { Header, OnboardingHeader, PageSelectorCTA } from '../../_components';
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyImageSrcDefaultNull, propertyTextDefaultEmpty } from '../../_helpers/aem/aemhelper';
import { Utils } from '../../_helpers/graphic/utils';
import { getLegalAge } from '../../_actions/appConfig.actions';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';

export const UnderAge = (props) => {

    const aem = new AEMHelper();

    const dictionary = aem.getDictionary(aemPages.UNDERAGE, {
        AVKO_BTN_BACK: propertyCtaItemsDefaultEmpty,
        AVKO_DISCLAIMER_DETAIL: propertyTextDefaultEmpty,
        AVKO_DISCLAIMER: propertyTextDefaultEmpty
    });

    let legalAge = getLegalAge();

    useEffect(() => {
        document.body.classList.add("underage");
        return () => {
            document.body.classList.remove("underage");
        }
    }, []);

    const goBack = () => {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.GO_BACK});
        history.push(buildURI(routingConstants.SOFT_AGE_VERIFICATION));
    }

    return (
        <div className="page w-100 d-flex flex-column">
            <Header leftButton={{
                icon: <span className="bat-icon-back" />,
                onClick: goBack
            }} />
            <div className="underage-wrapper">
                <div className="page-disclaimer">
                    <div className="page-disclaimer-title">
                    {Utils.stringReplacePlaceholders(dictionary.AVKO_DISCLAIMER, [legalAge], true)}
                    </div>
                    <div className="page-disclaimer-subtitle">
                    {Utils.stringReplacePlaceholders(dictionary.AVKO_DISCLAIMER_DETAIL, [legalAge], true)}
                    </div>
                </div>
            </div>
            <PageSelectorCTA>
                <div className="d-grid">
                    <button className="btn btn-primary text-uppercase" onClick={goBack}>
                        {dictionary.AVKO_BTN_BACK_0_ctaLabel}
                    </button>
                </div>
            </PageSelectorCTA>
            <div className="page-linear-gradient-bg"></div>
        </div>
    );

}