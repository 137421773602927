import { get } from 'lodash/object';
import { map } from 'lodash/collection';
import { omit } from 'lodash/object';
import { keys } from 'lodash/object';
import { compact } from 'lodash/array';
import moment from 'moment';
import { Commons } from '../commons';
import { storiesElementModel } from '../../_models';
import { getAEMAssetsUrl } from '../../_actions/appConfig.actions';

/**
 * 
 * @param {*} source json
 */
export function parseVuseWorldSource(source) {
    console.log("source", source);

    const wrapper = get(source, 'data.languages[0].pages[0].globalDataWrapperList', []);
    const storiesWrapper = wrapper[0] ?? {};
    let storiesMap = get(storiesWrapper, 'globalDataMap.global-data[0]', {});
    const iconsMap = Object.assign({}, ...get(storiesMap, 'ICONS.items', []));

    storiesMap = omit(storiesMap, 'ICONS');

    // console.log("wrapper", wrapper);
    // console.log("storiesWrapper", storiesWrapper);
    // console.log("storiesMap", storiesMap);
    // console.log("iconsMap", iconsMap);

    const storiesCategories = compact(map(storiesMap, (el, idx) => {
        if (
            el !== null &&
            el !== undefined &&
            Commons.isValidArray(el.items)
        ) {
            const categoryTitle = get(iconsMap, `TEXT_${idx}.text`, '');
            const categoryIcon = get(iconsMap, `ICON_${idx}.imageSrc.desktop.src`, null);

            // console.log("categoryTitle", categoryTitle);
            // console.log("categoryIcon", categoryIcon);

            let model = new storiesElementModel(
                categoryTitle,
                categoryIcon ? `${getAEMAssetsUrl()}${categoryIcon}` : null
            );

            (el.items).forEach(story => {
                if (
                    keys(story) != null &&
                    keys(story) !== undefined &&
                    keys(story)[0] !== null &&
                    keys(story)[0] !== undefined &&
                    story[keys(story)[0]] !== null &&
                    story[keys(story)[0]] !== undefined
                ) {
                    let currentStory = story[keys(story)[0]];
                    //check story date validity 
                    if (
                        currentStory.dateFrom &&
                        currentStory.dateTo &&
                        moment().isBetween(currentStory.dateFrom, currentStory.dateTo)
                    ) {
                        model.addStory(currentStory);
                    }
                }
            })

            if(Commons.isValidArray(model.stories)){
                return model;
            }
        }

        return null;
    }))

    // console.log("storiesCategories", storiesCategories);

    const carouselsWrapper = wrapper[1] ?? {};
    const carouselsMap = get(carouselsWrapper, 'globalDataMap.global-data[0]', {});

    // console.log("carouselsWrapper", carouselsWrapper);
    // console.log("carouselsMap", carouselsMap);

    return {
        stories: storiesCategories,
        carousels: carouselsMap
    };

}