export const PERMISSIONS_ACTION_TYPE = {
  SET_NOTIFICATION_PERMISSION_STATUS: 'SET_NOTIFICATION_PERMISSION_STATUS',
  RESET_NOTIFICATION_PERMISSION_STATUS: 'RESET_NOTIFICATION_PERMISSION_STATUS',
  SET_OS_NOTIFICATION_PERMISSION_STATUS: 'SET_OS_NOTIFICATION_PERMISSION_STATUS',
}

export const PERMISSION_STATUS = {
  GRANTED:        'granted',
  DENIED:         'denied',
  DEFAULT:        'default'
}