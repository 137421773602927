import { isNil } from "lodash";
import moment from "moment";
import "moment/min/locales";
import { PROFILE_EPOD2, PROFILE_WAWE2 } from "../_constants";
import { Commons } from "../_helpers/commons";

/* Initial state */
const appConfig = {
  available_notification_preferences: [],
  country_iso: "",
  country_label: "",
  flags: [],
  locale: "",
  province_iso: "",
  province_label: "",
  supported_locales: [],
  supported_services: [],
  supported_devices: [],
  urls: [],
  mobile_prefix: null,
  light: null,
  legal_age: null,
  deviceSpec: null,
  num_max_devices: 0,
  genders: [],
  triggers: {}
};

export function initAppConfig() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (!_appConfigString || _appConfigString === "") {
    console.log("Init appConfig");
    localStorage.setItem("appConfig", JSON.stringify(appConfig));
  } else {
    console.log("appConfig already present");
  }
}

export function resetAppConfig() {
  localStorage.setItem("appConfig", JSON.stringify(appConfig));
}

export function getAppConfig() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig;
  } else {
    console.error("appConfig not initialized");
  }
}

export function setAppConfig(appConfig) {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    localStorage.setItem("appConfig", JSON.stringify(appConfig));
  } else {
    console.error("appConfig not initialized");
  }
}

export function getQualtricsProjectId() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.project_id;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getQualtricsBrandId() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.brand_id;
  } else {
    console.error("appConfig not initialized");
  }
}
export function getExtRefId() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.ext_ref_id;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getLocale() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    moment.locale(appConfig.locale);
    return appConfig.locale;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getSupportedLocales() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.supported_locales;
  } else {
    console.error("appConfig not initialized");
  }
}

export function setLocale(locale) {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    let currentAppConfig = JSON.parse(_appConfigString);
    currentAppConfig.locale = locale;
    moment.locale(locale);
    localStorage.setItem("appConfig", JSON.stringify(currentAppConfig));
  } else {
    console.error("appConfig not initialized");
  }
}

export function getDeviceSpec(profile) {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.supported_devices.find(
      (device) => device.profile === profile
    )?.cloudControl;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getTriggers() {
  let _appConfigString = localStorage.getItem("appConfig");
  if(_appConfigString){
    const appConfig = JSON.parse(_appConfigString);
    const triggers = appConfig.triggers;
    return triggers
  }else {
    console.error("appConfig not initialized")
  }
}

export function isCurrentCountryCanada() {
  const country = getCountry();
  return country && country === "CA";
}

export function isCurrentCountryUK() {
  const country = getCountry();
  return country && country === "GB";
}

export function isCurrentCountryGermany() {
  const country = getCountry();
  return country && country === "DE";
}

export function isCurrentCountrySouthAfrica() {
  const country = getCountry();
  return country && country === "ZA";
}

export function isCurrentCountryDemo() {
  const country = getCountry();
  return country && country === "DM";
}

export function getCountry() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.country_iso;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getCountryCurrency() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.country_currency_iso;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getCountryLabel() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.country_label;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getProvince() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.province_iso;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getProvinceLabel() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.province_label;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getProvinceShortForm() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.province_iso?.split('-')[1] ?? '';
  } else {
    console.error("appConfig not initialized");
  }
}

export function getMobilePrefix() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.mobile_prefix;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getLegalAge() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.legal_age;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getLegalAgeThreshold() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.age_legal_threshold;
  } else {
    console.error("appConfig not initialized");
  }
}

export function isLightMarket() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.light;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getTenantId() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.tenantId;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getAvailableNotificationsPrefs() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.available_notification_preferences;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getSupportedServices() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.supported_services;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getMarketPayloadCode(deviceType) {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    const supportedDevices = appConfig.supported_devices;
    const profile = deviceType === PROFILE_EPOD2 ? PROFILE_WAWE2 : deviceType;
    const deviceFamily = supportedDevices.find(
      (device) => device.profile === profile
    );

    if (isNil(deviceFamily)) {
      return appConfig.mrk_payload_code;
    }

    return deviceFamily?.mrk_payload_code || appConfig.mrk_payload_code;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getMarketCountryCode(deviceType) {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    const supportedDevices = appConfig.supported_devices;
    const profile = deviceType === PROFILE_EPOD2 ? PROFILE_WAWE2 : deviceType;
    const deviceFamily = supportedDevices.find(
      (device) => device.profile === profile
    );

    if (isNil(deviceFamily)) {
      return appConfig.mrk_country_code;
    }

    return deviceFamily?.mrk_country_code || appConfig.mrk_country_code;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getMarketUrl() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig?.urls[getLocale()]?.MARKET_ENABLE_URL;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getAsavUrl() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig?.urls[getLocale()]?.ASAV_URL;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getAEMUrl() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig?.urls[getLocale()]?.AEM;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getAEMAssetsUrl() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig?.urls[getLocale()]?.AEM_ASSETS;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getAEMVuseWorldUrl() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    const localeURls = appConfig?.urls[getLocale()];
    if (localeURls) {
      return appConfig?.urls[getLocale()]?.AEM_VUSE_WORLD;
    } else {
      return null;
    }
  } else {
    console.error("appConfig not initialized");
  }
}

export function getLoyaltyOptinUrl() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    const localeURls = appConfig?.urls[getLocale()];
    if (localeURls) {
      return appConfig?.urls[getLocale()]?.LOYALTY_OPTIN;
    } else {
      return null;
    }
  } else {
    console.error("appConfig not initialized");
  }
}

export function getLoyaltyDashboardUrl() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    const localeURls = appConfig?.urls[getLocale()];
    if (localeURls) {
      return appConfig?.urls[getLocale()]?.LOYALTY_REWARDS;
    } else {
      return null;
    }
  } else {
    console.error("appConfig not initialized");
  }
}

export function getUrl(url) {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    const localeURls = appConfig?.urls[getLocale()];
    if (localeURls) {
      return appConfig?.urls[getLocale()][url];
    } else {
      return null;
    }
  } else {
    console.error("appConfig not initialized");
  }
}

export function isSupportedService(service) {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    if (Commons.isValidArray(appConfig.supported_services)) {
      return (
        appConfig.supported_services.findIndex((el) => el === service) !== -1
      );
    }
    return false;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getNumMaxDevices() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.num_max_devices;
  }
}

export function getSupportedDevices() {
  let _appConfigString = localStorage.getItem("appConfig");

  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.supported_devices;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getDeviceFamilyInfo(deviceType) {
  const profile = deviceType === PROFILE_EPOD2 ? PROFILE_WAWE2 : deviceType;
  const _appConfigString = localStorage.getItem("appConfig");

  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    const supportedDevices = appConfig.supported_devices;

    if (supportedDevices.length > 1 && !!profile) {
      return supportedDevices.find((device) => device.profile === profile);
    }

    return supportedDevices[0];
  } else {
    console.error("appConfig not initialized");
  }
}

export function getGenders() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.gender;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getGoogleApiKey() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.maps_key;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getOnetrustKey() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.onetrust_webapp_key;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getNavbar() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.navbar_ios;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getMoreMenu() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.side_menu;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getHomepageDrawer() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.homepage_drawer;
  } else {
    console.error("appConfig not initialized");
  }
}

export function getCrmEnrichments() {
  let _appConfigString = localStorage.getItem("appConfig");
  if (_appConfigString) {
    const appConfig = JSON.parse(_appConfigString);
    return appConfig.crm_enrichments;
  } else {
    console.error("appConfig not initialized");
  }
}