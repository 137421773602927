import React from 'react';
import { WebView } from '..';
import { CustomModal } from '../CustomModal';

export const WebviewModal = (props) => {

    const {
        webviewId,
        webviewClassName,
        webviewTitle,
        webviewuUrl,
        handleBack,
        isModalOpen,
        modalClassName
    } = props;

    return (
        <CustomModal 
            isOpen={isModalOpen}
            className={modalClassName}>
            <WebView
                id={webviewId}
                title={webviewTitle}
                url={webviewuUrl}
                handleBack={handleBack}
                className={webviewClassName}
            />
        </CustomModal>
    );
};
