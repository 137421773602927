import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useState } from 'react';
import { getMobilePrefix } from '../../_actions/appConfig.actions';
import { getUserData } from '../../_actions';
import { CustomInlineAlert } from '..';
import { CustomLoadingButton } from '../CustomLoadingButton';
import { isValidString } from '../../_helpers/utils';
import { AEMHelper } from '../../_helpers/aem/aemhelper';
import { aemPages } from '../../_constants';

export const AccountEditDetailsForm = (props) => {

    const [dictionary, setDictionary] = useState({});

    const { register, handleSubmit, setValue, watch } = useForm();
    const { email, firstName, lastName, phoneNumber, oldPassword, newPassword, confirm, address } = watch();

    /** PASSWORD THINGS **/
    const [isPasswordLong, setIsPasswordLong] = useState(false);
    const [hasPasswordNumber, setHasPasswordNumber] = useState(false);
    const [hasPasswordUppercase, setHasPasswordUppercase] = useState(false);
    const [hasPasswordSpecialChar, setHasPasswordSpecialChar] = useState(false);
    const [hasPasswordLowercase, setHasPasswordLowercase] = useState(false);
    const [arePasswordMatching, setArePasswordMatching] = useState(false);


    const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false);
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

    const [isFormTouched, setIsFormTouched] = useState(null);

    const regexpSpecialChar = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;
    const regexpEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const regexpPhoneNum = /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/;

    


    useEffect(() => {
        setIsFormTouched(true);
    }, [phoneNumber, oldPassword, newPassword, confirm]);

    useEffect(() => {
        const aem = new AEMHelper();
        setDictionary(
            aem.getDictionary(aemPages.EDIT_DETAILS, {
                EDIT_DETAILS_PWD_UPPERCASE: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_NEW_PSW_PLACEHOLDER: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_FIRST_NAME_PLACEHOLDER: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_PHONE_LABEL: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_OLD_PSW_LABEL: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_EMAIL_LABEL: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_ADDRESS_LABEL: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_ADDRESS_PLACEHOLDER: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_BTN_SAVE: {
                    propertyName: 'ctaItems',
                    default: '',
                    subPropertyNames: ['ctaLabel'],
                },
                EDIT_DETAILS_LAST_NAME_PLACEHOLDER: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_PWD_MIN_LENGTH: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_NEW_PSW_LABEL: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_FIRST_NAME_LABEL: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_PHONE_PLACEHOLDER: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_PWD_MATCHING: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_PWD_SPECIAL_CHAR: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_EMAIL_PLACEHOLDER: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_CONF_NEW_PSW_LABEL: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_PWD_NUMBER: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_CONF_NEW_PSW_PLACEHOLDER: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_PWD_LOWERCASE: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_LAST_NAME_LABEL: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_OLD_PSW_PLACEHOLDER: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_BTN_SAVED: {
                    propertyName: 'ctaItems',
                    default: '',
                    subPropertyNames: ['ctaLabel'],
                },
                EDIT_DETAILS_FAILURE_MESSAGE: {
                    propertyName: 'text',
                    default: '',
                },
                EDIT_DETAILS_EMAIL_CHECK_FAILURE_MESSAGE: {
                    propertyName: 'text',
                    default: '',
                },
            })
        );

        const userData = getUserData();

        if (userData) {
            const email = userData?.email || "";
            const firstName = userData?.firstName || "";
            const lastName = userData?.lastName || "";
            const phoneNumber = userData?.phoneNumber || "";
            // const mobilePrefix = getMobilePrefix();

            setValue('email', email);
            setValue('firstName', firstName);
            setValue('lastName', lastName);
            // we need to remove the mobile prefix from the phone number
            // setValue('phoneNumber', phoneNumber.slice(mobilePrefix.length - 1));
            setValue('phoneNumber', phoneNumber);

            const streetObj = (userData.address)?.replace(/\{|\\|"|\}/gi, "").split(",") ?? "";
            setValue('address', `${streetObj[0] ?? ""} ${userData.city ?? ""}, ${userData.province ?? ""}, ${userData.postalCode ?? ""}`);
        }

    }, [])

    /**
     * Refresh password props on change password/confirm
     */
    useEffect(() => {

        if (newPassword != null && newPassword !== undefined) {
            const currentConfirm = confirm.trim();
            const currentNewPassword = newPassword.trim();
            setArePasswordMatching(currentConfirm !== "" && currentNewPassword !== "" && currentConfirm === currentNewPassword);
            setIsPasswordLong(currentNewPassword.length >= 8);
            setHasPasswordNumber(/\d/.test(currentNewPassword));
            setHasPasswordUppercase(!(currentNewPassword.toLowerCase() === currentNewPassword));
            setHasPasswordSpecialChar(regexpSpecialChar.test(currentNewPassword));
            setHasPasswordLowercase(!(currentNewPassword.toUpperCase() === currentNewPassword));
        }

    }, [newPassword, confirm]);


    /**
     * Does form submit 
     * @param {*} data 
     */
    const submitWithCheck = (data) => {
        setIsFormTouched(false);
        if (typeof props.onSubmit === 'function') {
            props.onSubmit(data);
        }
    }

    const isFormValid = () => {

        let passwordsAreValid = false;
        let isPhoneNumerValid = false;

        if (
            isValidString(newPassword)
        ) {
            //if new password is set also old and confirm must be set and resspecting validation rules
            if (isValidString(confirm) && isValidString(oldPassword)) {
                passwordsAreValid = isPasswordLong &&
                    hasPasswordNumber &&
                    hasPasswordUppercase &&
                    hasPasswordSpecialChar &&
                    hasPasswordSpecialChar &&
                    arePasswordMatching &&
                    hasPasswordLowercase;
            }
        } else {
            //if none of the passwords are set form is valid
            if (!isValidString(confirm) && !isValidString(oldPassword)) {
                passwordsAreValid = true;
            }
        }

        if (
            isValidString(phoneNumber) &&
            (
                regexpPhoneNum.test(phoneNumber) ||
                phoneNumber?.trim().length === 10 ||
                (phoneNumber?.trim().includes('+1') && phoneNumber?.trim().length === 12)
            )
        ) {
            isPhoneNumerValid = true;
        }

        return email &&
            firstName &&
            lastName &&
            address &&
            isPhoneNumerValid &&
            passwordsAreValid;
    }


    return (
        <div className="signup-wrapper h-100 pt-1">

            <form className="signup-form" onSubmit={handleSubmit(submitWithCheck)}>
                <div className="signup-form-wrapper">
                    {/* NAME AND LASTNAME */}
                    <div className="signup-form-row">
                        <label className="page-label" htmlFor="signupFirstName">{dictionary.EDIT_DETAILS_FIRST_NAME_LABEL}</label>
                        <input name="firstName" disabled
                            id="signupFirstName"
                            className='form-control'
                            placeholder={dictionary.EDIT_DETAILS_FIRST_NAME_PLACEHOLDER}
                            type="text"
                            ref={register()}
                        />
                    </div>

                    <div className="signup-form-row">
                        <label className="page-label" htmlFor="lastName">{dictionary.EDIT_DETAILS_LAST_NAME_LABEL}</label>
                        <input name="lastName" disabled
                            id="lastName"
                            className='form-control'
                            placeholder={dictionary.EDIT_DETAILS_LAST_NAME_PLACEHOLDER}
                            type="text"
                            ref={register()}
                        />
                    </div>

                    <div className="signup-form-row">
                        <label className="page-label" htmlFor="address">{dictionary.EDIT_DETAILS_ADDRESS_LABEL}</label>
                        <input name="address" disabled
                            id="address"
                            className='form-control'
                            placeholder={dictionary.EDIT_DETAILS_ADDRESS_PLACEHOLDER}
                            ref={register()}
                            type="text"
                        />
                    </div>

                    <div className="signup-form-row">
                        <label className="page-label" htmlFor="signupPhoneNumber">{dictionary.EDIT_DETAILS_PHONE_LABEL}</label>
                        <div className="d-flex">
                            {getMobilePrefix() &&
                                <input className='form-control phone-placeholder' value={`${getMobilePrefix()}`} disabled/>
                            }
                            <input name="phoneNumber"
                                id="signupPhoneNumber"
                                className='form-control'
                                // placeholder={dictionary.EDIT_DETAILS_PHONE_PLACEHOLDER}
                                type="number"
                                step="1" 
                                inputmode="numeric" 
                                digitOnly 
                                pattern="[0-9]*"
                                ref={register({ required: true })}
                            />
                        </div>
                    </div>

                    <div className="signup-form-row">
                        <label className="page-label" htmlFor="email">{dictionary.EDIT_DETAILS_EMAIL_LABEL}</label>
                        <input name="email" disabled
                            id="email"
                            className='form-control'
                            placeholder={dictionary.EDIT_DETAILS_EMAIL_PLACEHOLDER}
                            type="email"
                            ref={register({ required: true, pattern: regexpEmail })}
                        />
                    </div>

                    <div className="signup-form-row">
                        <label className="page-label" htmlFor="oldPassword">{dictionary.EDIT_DETAILS_OLD_PSW_LABEL}</label>
                        <div className="form-control-toggle">
                            <span id="togglePassswordEye" className={isCurrentPasswordVisible ? "toggle-icon toggle-pw show" : "toggle-icon toggle-pw hide"} onClick={() => setIsCurrentPasswordVisible(!isCurrentPasswordVisible)}></span>
                            <input name="oldPassword"
                                id="oldPassword"
                                className="form-control"
                                placeholder={dictionary.EDIT_DETAILS_OLD_PSW_PLACEHOLDER}
                                type={isCurrentPasswordVisible ? "text" : "password"}
                                autoComplete="new-password"
                                ref={register()} />
                        </div>
                    </div>

                    <div className="signup-form-row">
                        <label className="page-label" htmlFor="newPassword">{dictionary.EDIT_DETAILS_NEW_PSW_LABEL}</label>
                        <div className="form-control-toggle form-control-toggle-confirm">
                            <span id="togglePassswordEye" className={isNewPasswordVisible ? "toggle-icon toggle-pw show" : "toggle-icon toggle-pw hide"} onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}></span>
                            <input name="newPassword"
                                id="newPassword"
                                className="form-control"
                                placeholder={dictionary.EDIT_DETAILS_NEW_PSW_PLACEHOLDER}
                                type={isNewPasswordVisible ? "text" : "password"}
                                autoComplete="new-password"
                                ref={register()} />
                        </div>
                    </div>

                    <div className="signup-form-row">
                        <label className="page-label" htmlFor="confirmNewPassword">{dictionary.EDIT_DETAILS_CONF_NEW_PSW_LABEL}</label>
                        <div className="form-control-toggle form-control-toggle-confirm">
                            <span id="togglePassswordEye" className={isConfirmPasswordVisible ? "toggle-icon toggle-pw show" : "toggle-icon toggle-pw hide"} onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}></span>
                            <input name="confirm"
                                id="confirmNewPassword"
                                className="form-control"
                                placeholder={dictionary.EDIT_DETAILS_CONF_NEW_PSW_PLACEHOLDER}
                                type={isConfirmPasswordVisible ? "text" : "password"}
                                autoComplete="new-password"
                                ref={register()} />
                        </div>
                    </div>

                    <div className="password-validation-container">
                        <div className="password-validation-row">
                            <div className={`password-validation ${isPasswordLong && "active"}`}><span className="bat-icon-check"></span>{dictionary.EDIT_DETAILS_PWD_MIN_LENGTH}</div>
                            <div className={`password-validation ${hasPasswordSpecialChar && "active"}`}><span className="bat-icon-check"></span>{dictionary.EDIT_DETAILS_PWD_SPECIAL_CHAR}</div>
                        </div>
                        <div className="password-validation-row">
                            <div className={`password-validation ${hasPasswordNumber && "active"}`}><span className="bat-icon-check"></span>{dictionary.EDIT_DETAILS_PWD_NUMBER}</div>
                            <div className={`password-validation ${hasPasswordUppercase && "active"}`}><span className="bat-icon-check"></span>{dictionary.EDIT_DETAILS_PWD_UPPERCASE}</div>
                        </div>
                        <div className="password-validation-row">
                            <div className={`password-validation ${hasPasswordLowercase && "active"}`}><span className="bat-icon-check"></span>{dictionary.EDIT_DETAILS_PWD_LOWERCASE}</div>
                            <div className={`password-validation ${arePasswordMatching && "active"}`}><span className="bat-icon-check"></span>{dictionary.EDIT_DETAILS_PWD_MATCHING}</div>
                        </div>
                    </div>


                </div>

                <div className="form-group mt-3 p-4">

                    <CustomLoadingButton isLoading={props.loading} validationMethod={isFormValid()} buttonLabel={`${isFormTouched !== false ? dictionary.EDIT_DETAILS_BTN_SAVE_0_ctaLabel : dictionary.EDIT_DETAILS_BTN_SAVED_0_ctaLabel}`} />

                    <div className='mt-3'>
                        <CustomInlineAlert isShown={props.error} alertType="error">
                            <React.Fragment>{dictionary.EDIT_DETAILS_FAILURE_MESSAGE}</React.Fragment>
                        </CustomInlineAlert>
                        <CustomInlineAlert isShown={props.emailAlreadyExists} alertType="error">
                            <React.Fragment>{dictionary.EDIT_DETAILS_EMAIL_CHECK_FAILURE_MESSAGE}</React.Fragment>
                        </CustomInlineAlert>
                    </div>
                </div>

            </form>
        </div>
    )


}