import React, { useEffect, useState } from 'react';
import { PageSelectorCTA } from '../PageSelectorCTA';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { AnalyticsEvents, AnalyticsFieldNames, AnalyticsScreenEventNames, AnalyticsTargetEventsNames } from '../../_constants';

export const InstructionsStep = ({
    title,
    okButtonLabel,
    instructions,
    onConfirmInstructionsProcess,
    checkboxLabel,
    nextStep,
    goToStep,
    disableValidation,
    from
}) => {

    const [areAcceptedInstructions, setAreAcceptedInstructions] = useState(false);

    useEffect(() => {
        if(from === 'remove'){
            logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.REMOVE_DEVICE_BLUETOOTH})    
        } else {
            logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.FACTORY_RESET_BLUETOOTH})
        }
    }, [])

    const onConfirm = () => {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NEXT})
        onConfirmInstructionsProcess({ next: nextStep, goToStep: goToStep });
    }
    return (
        <div className="process-data-container">
            {title && <div className="process-data-title"><span>{title}</span></div>}
            {instructions}
            <PageSelectorCTA sticky={false} className="mt-auto ps-0 pe-0">
                <div className="d-grid gap-2">
                    {
                        checkboxLabel &&
                        <div className="form-check mb-4">
                            <input className="form-check-input" type="checkbox" id="flexCheckDefault"
                                checked={areAcceptedInstructions}
                                value={areAcceptedInstructions}
                                onChange={() => {
                                    if(from === 'remove'){
                                        logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.REMOVE_DEVICE_BLUETOOTH_CONFIRMATON, field_value: !areAcceptedInstructions}); 
                                    } else {
                                        logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.FACTORY_RESET_BLUETOOTH_CONFIRMATON, field_value: !areAcceptedInstructions}); 
                                    }
                                    setAreAcceptedInstructions(!areAcceptedInstructions)
                                    }
                                }
                            />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                {checkboxLabel}
                            </label>
                        </div>
                    }
                    <button className="btn btn-primary text-uppercase" disabled={!disableValidation && !areAcceptedInstructions}
                        onClick={onConfirm}>
                        {okButtonLabel}
                    </button>
                </div>
            </PageSelectorCTA>
        </div>
    );
}