import React from 'react';
import { connect } from 'react-redux';
import { dashboardActions } from '../../_actions';
import { getAEMAssetsUrl } from '../../_actions/appConfig.actions';
import { FeatureTutorial } from '../../_components';
import { routingConstants, aemPages } from '../../_constants';
import { AEMHelper, propertyHeadingDefaultEmpty } from '../../_helpers/aem/aemhelper';

class UnlockTutorial extends React.Component {

    constructor(props) {
        super(props);

        this.aem = new AEMHelper();
        this.dictionary = this.aem.getDictionary(aemPages.CLOUD_CONTROL_TUTORIAL, {
            CLOUD_TUTORIAL_HEADER: propertyHeadingDefaultEmpty, 
        });

        /* Retrieve the videos from AEM */
        this.ccTutPage = this.aem.getPage(aemPages.CLOUD_CONTROL_TUTORIAL);
        let carousel = this.ccTutPage?.getComponent(aemPages.CLOUD_CONTROL_TUTORIAL, 'carousel');
        let stories = [];

        if(carousel?.getRaw('items')?.length) {
            for(let i = 0; i < carousel.getRaw('items')?.length; i++) {
                let item = carousel.getRaw('items')?.[i];
                let assetUrl = item?.image?.imageSrc?.desktop?.src ?? null;

                if(assetUrl) {
                    console.log(`[AEMComponent][downloadAssets] Downloading ${getAEMAssetsUrl()}${assetUrl}`);
                    stories.push({
                        url: `${getAEMAssetsUrl()}${assetUrl}`,
                        type: 'video',
                        muted: true
                    });
                }
            }
        }
        
        /* Set the state */
        this.state = {stories};
        
    }

    render() {
        return (
            <FeatureTutorial title="UNLOCK MY DEVICE"
                featureIcon={"lock-outline-check"}
                stories={this.state.stories}
                iconAction={null}
                backAction={routingConstants.TUTORIALS}
                doneTutorialMethod={dashboardActions.setDoneTutorialUnlock}
                doneTutorialValue={this.props.tutorials.unlock}
            />
        )
    }
}


function mapStateToProps(state) {
    const {
        dashboardReducer: {
            tutorials
        }
    } = state;

    return {
        tutorials
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(UnlockTutorial);
export { connectedComponent as UnlockTutorial };