import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { isDeviceConnected } from "../../_helpers/device";
import { getDeviceIcon } from "../../_helpers/device/assets";

const DeviceIcon = ({
  device,
  selected = false,
  showConnectionState = false,
  className,
  onSelect = null,
}) => {
  const [isConnected, setIsConnected] = useState(false);
  const [isSelected, setIsSelected] = useState(false)

  const firstDivRef = useRef()

  useEffect(() => {
    if (showConnectionState) {
      setIsConnected(isDeviceConnected(device));
    }
  }, [device, showConnectionState]);

  useEffect(() => {
    let check = firstDivRef.current.classList.contains('selected')

    if(check){
      setIsSelected(true)
    }else {
      setIsSelected(false)
    }
  })

  return (
    <>
    <div
      ref={firstDivRef}
      className={cx(
        "device-icon",
        {
          connected: showConnectionState && isConnected,
          disconnected: showConnectionState && !isConnected,
          selected,
        },
        className
      )}
      onClick={onSelect}
    >
      <img src={getDeviceIcon(device)} alt="device icon" />
    </div>
    <div className={cx("device-pointer", 
      {
        connected: showConnectionState && isConnected,
        disconnected: showConnectionState && !isConnected,
        selected: isSelected,
      },
    )}></div>
    </>
  );
};

export { DeviceIcon };
