import React from 'react';
import { PageSelectorCTA } from '../PageSelectorCTA';

export const AdditionalIntroductionStep = ({
    introductionImage,
    title,
    okButtonLabel,
    cancelButtonLabel,
    onConfirmProcess,
    onCancelProcess,
    onEndProcess,
    endButtonLabel,
    nextStep
}) => {

    const handleStartProcess = () => {
        onConfirmProcess({ next: nextStep })
    }

    const handleCancelProcess = () => {
        onCancelProcess({ next: nextStep })
    }

    return (
        <div className="process-data-container">
            <div className="process-data-title"><span>{title}</span></div>
            {
                introductionImage &&
                <div className="process-data-image">
                    {introductionImage}
                </div>
            }
            <PageSelectorCTA sticky={false} ctaUrl={onEndProcess && typeof onEndProcess === "function"} className="mt-auto ps-0 pe-0">
                <div className='d-grid gap-2'>
                    <button className="btn btn-primary text-uppercase" onClick={handleStartProcess}>{okButtonLabel}</button>
                    {cancelButtonLabel && <button className="btn btn btn-outline-secondary text-uppercase" onClick={handleCancelProcess}>{cancelButtonLabel}</button>}
                </div>
                {
                    onEndProcess &&
                    typeof onEndProcess === "function" &&
                    <div className="page-selector-cta-url">
                        <span className="url-text text-capitalize" onClick={onEndProcess}>{endButtonLabel}</span>
                    </div>
                }
            </PageSelectorCTA>
        </div>
    );
}