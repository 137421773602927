import cx from "classnames";
import { isFunction } from "lodash";
import React, { useEffect, useState } from "react";
import { DeviceIcon } from "../DeviceIcon/DeviceIcon";

const DeviceIconGroup = ({
  devices,
  selectedDeviceIndex = 0,
  compact = false,
  deviceIconCount = 3,
  showConnectionState = false,
  showDeviceCount = false,
  onSelect = null,
}) => {
  const [visibleDevices, setVisibleDevices] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const updateSelectedIndex = (index) => {
    setSelectedIndex(index);

    if (isFunction(onSelect)) {
      onSelect(index);
    }
  };

  useEffect(() => {
    setVisibleDevices(devices);
  }, [devices]);

  useEffect(() => {
    if (compact) {
      setVisibleDevices(devices.slice(-deviceIconCount));
    }
  }, [compact, deviceIconCount, devices]);

  useEffect(() => {
    setSelectedIndex(selectedDeviceIndex);
  }, [selectedDeviceIndex]);

  return (
    <div
      className={cx("device-icon-group", {
        compact: !!compact,
        loose: !compact,
      })}
    >
      {visibleDevices.map((device, index) => (
        <DeviceIcon
          key={index}
          device={device}
          selected={index === selectedIndex}
          showConnectionState={showConnectionState}
          onSelect={() => updateSelectedIndex(index)}
        />
      ))}
      {showDeviceCount && <div className="device-count">{devices?.length}</div>}
    </div>
  );
};

export { DeviceIconGroup };
