import { getAEMAssetsUrl } from "../../_actions/appConfig.actions";
import { PROFILE_EPOD3 } from "../../_constants";
import { AEMHelper } from "../aem/aemhelper";

export function getTutorialComponentName(deviceType, componentName) {
  if (deviceType === PROFILE_EPOD3) {
    return componentName + "_PROSMART";
  }

  return componentName;
}

export function getTutorialsVideo(aemPage, deviceType, components) {
  const aem = new AEMHelper();
  const tutorialPage = aem.getPage(aemPage);

  return components.map((componentName) => {
    const name = getTutorialComponentName(deviceType, componentName);
    const aemComponent = tutorialPage.getComponent(aemPage, name);

    return `${getAEMAssetsUrl()}${
      aemComponent?.component?.imageSrc?.desktop?.src || ""
    }`;
  });
}

export function getAEMCarouselFor(deviceType, aemPage) {
   
}