import React, { useState, useEffect } from 'react';
import { CustomSlidingPanel } from '../CustomSlidingPanel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import _ from "lodash";
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyHeadingDefaultEmpty, propertyTextDefaultEmpty } from '../../_helpers/aem/aemhelper';
import { aemPages } from '../../_constants';

export const ShopFilters = ({
    filters,
    onApplyFilters,
    options
}) => {

    const [filtersExpandStatus, setFiltersExpandStatus] = useState({
        devices: true,
        profile: true,
        nicotineStrenght: true
    });

    const [thisFilters, setThisFilters] = useState({
        profile: [],
        nicotineStrenght: []
    });


    const [isOpen, setIsOpen] = useState(false);

    const [thisOptions, setThisOptions] = useState({});

    const [dictionary, setDictionary] = useState({});

    useEffect(() => {
        const aem = new AEMHelper();
        setDictionary(
            aem.getDictionary(aemPages.SHOP, {
                SHOP_FLAVOURS_PROFILE_TITLE: propertyTextDefaultEmpty,
                SHOP_NICOTINE_STRENGHT_TITLE: propertyTextDefaultEmpty,
                SHOP_BTN_APPLY_FILTERS: propertyCtaItemsDefaultEmpty,
                SHOP_FILTER_HEADER: propertyHeadingDefaultEmpty
            })
        );
    }, [])

    useEffect(() => {
        setThisOptions(options);
    }, [options])


    useEffect(() => {
        if (!_.isEmpty(filters)) {
            setThisFilters(filters);
        }
    }, [filters])

    const handlePanelClose = () => {
        setIsOpen(false);
    }

    const isActiveFilter = (filter, category) => {
        return thisFilters[category] && thisFilters[category].indexOf(filter) !== -1;
    }

    const updateFilters = (filter, category) => {

        const filterIdx = thisFilters[category].indexOf(filter);
        if (filterIdx !== -1) {
            thisFilters[category].splice(filterIdx, 1);
            setThisFilters({
                ...thisFilters,
                [category]: [...thisFilters[category]]
            });
        } else {
            thisFilters[category].push(filter);
            setThisFilters({
                ...thisFilters,
                [category]: [...thisFilters[category]]
            });
        }
    }

    const handleApplyFilters = () => {
        setIsOpen(false);
        if (typeof onApplyFilters === 'function') {
            onApplyFilters(thisFilters);
        }
    }

    const renderProfileOptions = () => {
        
        return thisOptions.profile.map((profile, idx) =>
            <div key={`filter-profile-option-${idx}`} className="form-check">
                <input className="form-check-input" type="checkbox" id={`checkFilterProfile${idx}`}
                    checked={isActiveFilter(profile, 'profile')}
                    value={isActiveFilter(profile, 'profile')}
                    onChange={() => updateFilters(profile, 'profile')}
                />
                <label className="form-check-label" htmlFor={`checkFilterProfile${idx}`}>
                    {profile}
                </label>
            </div>
        )
    }

    const renderNicotineOptions = () => {
        
        return thisOptions.nicotineStrenght.map((nicotine, idx) =>
            <div key={`filter-profile-option-${idx}`} className="form-check">
                <input className="form-check-input" type="checkbox" id={`checkFilterNic${idx}`}
                    checked={isActiveFilter(nicotine, 'nicotineStrenght')}
                    value={isActiveFilter(nicotine, 'nicotineStrenght')}
                    onChange={() => updateFilters(nicotine, 'nicotineStrenght')}
                />
                <label className="form-check-label" htmlFor={`checkFilterNic${idx}`}>
                    {nicotine}
                </label>
            </div>
        )
    }


return (
    <React.Fragment>

        <div className="shop-filter-icon d-flex align-items-center justify-content-center"
            onClick={() => setIsOpen(true)}>
            <span className="bat-icon-filter"></span>
        </div>

        <CustomSlidingPanel from="bottom"
            isOpen={isOpen}
            overlayClassName={"sliding-common-wrapper filter-panel-overlay"}
            className="filter-panel"
            title={dictionary.SHOP_FILTER_HEADER}
            closeIcon="bat-icon-close"
            onClose={handlePanelClose}>

            <div className="filters-wrapper">
                {
                    thisOptions?.profile?.length > 0 &&

                    <Accordion expanded={filtersExpandStatus.profile}
                        onChange={(e, status) => setFiltersExpandStatus({ ...filtersExpandStatus, profile: status })}>
                        <AccordionSummary
                            expandIcon={<span className={"bat-icon-chevron-down"}></span>}
                            aria-controls="profile-content"
                            id="profile-header"
                        >
                            <span>{dictionary.SHOP_FLAVOURS_PROFILE_TITLE}</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div> {renderProfileOptions()} </div>
                        </AccordionDetails>
                    </Accordion>
                }

                {
                    thisOptions?.nicotineStrenght?.length > 0 &&
                    <Accordion expanded={filtersExpandStatus.nicotineStrenght}
                        onChange={(e, status) => setFiltersExpandStatus({ ...filtersExpandStatus, nicotineStrenght: status })}>
                        <AccordionSummary
                            expandIcon={<span className={"bat-icon-chevron-down"}></span>}
                            aria-controls="nicotineStrenght-content"
                            id="nicotineStrenght-header"
                        >
                            <span>{dictionary.SHOP_NICOTINE_STRENGHT_TITLE}</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div> { renderNicotineOptions() } </div>
                        </AccordionDetails>
                    </Accordion>
                }

                <div className='filters-cta'>
                    <button className='btn btn-primary btn-block text-uppercase' onClick={handleApplyFilters}>{dictionary.SHOP_BTN_APPLY_FILTERS_0_ctaLabel}</button>
                </div>
            </div>
        </CustomSlidingPanel>
    </React.Fragment>
)

}