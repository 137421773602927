
import React, { useState, useEffect } from "react";
import Modal from "react-modal";

export function CustomSlidingPanel({
    isOpen,
    title,
    onClose,
    onAfterOpen,
    onAfterClose,
    children,
    className,
    overlayClassName,
    closeIcon,
    shouldCloseOnEsc,
    hideHeader = false,
    disableClosure = false,
    contentRef = null,
    overlayRef = null,
    setOverlayRef,
    setContentRef,
    setPanelHeaderRef,
    additionalStyles,
    backdropClose,
    hideBottom = true,
    bottomLabel = ''
}) {

    const [wasOpen, setWasOpen] = useState(false);
    const [isOpenPanel, setIsOpenPanel] = useState(false);

    useEffect(() => {
        if (isOpenPanel === true && isOpen === false) {
            setIsOpenPanel(false);
            document.body.style.overflow = '';
            handleAfterOpen();
        } else if (isOpenPanel === false && isOpen === true) {
            setIsOpenPanel(true);
            document.body.style.overflow = 'hidden';
        }
    }, [isOpen]);

    const handleAfterOpen = React.useCallback(() => {
        setTimeout(() => {
            setWasOpen(true);
            if (typeof onAfterOpen === 'function') {
                onAfterOpen();
            }
        }, 0);
    }, [onAfterOpen]);

    const handleAfterClose = React.useCallback(() => {
        if (disableClosure) {
            return;
        }
        setTimeout(() => {
            setWasOpen(false);

            if (typeof onAfterClose === 'function') {
                onAfterClose();
            }
        }, 0);
    }, [onAfterClose]);

    const setToParentContentRef = (ref) => {
        if (typeof setContentRef === 'function') {
            setContentRef(ref);
        }
    }

    const setToParentOverlayRef = (ref) => {
        if (typeof setOverlayRef === 'function') {
            setOverlayRef(ref);
        }
    }

    const setToParentHeaderRef = (ref) => {
        if (typeof setPanelHeaderRef === 'function') {
            setPanelHeaderRef(ref);
        }
    }

    return (
        <Modal
            ariaHideApp={false}
            overlayClassName={{
                base: `sliding-panel-overlay ${overlayClassName ?? ""}`,
                afterOpen: wasOpen ? "overlay-after-open" : '',
                beforeClose: "overlay-before-close"
            }}
            className={{
                base: `sliding-panel ${className ?? ""}`,
                afterOpen: wasOpen ? "content-after-open" : '',
                beforeClose: "content-before-close"
            }}
            style={{
                content: { width: "100%", ...(additionalStyles ?? {}) }
            }}
            closeTimeoutMS={500}
            isOpen={isOpenPanel}
            shouldCloseOnEsc={shouldCloseOnEsc ?? false}
            onAfterOpen={handleAfterOpen}
            onAfterClose={handleAfterClose}
            onRequestClose={onClose}
            contentLabel={`Modal "${title ?? ""}"`}
            contentRef={setToParentContentRef}
            overlayRef={setToParentOverlayRef}
            shouldCloseOnOverlayClick={backdropClose ?? true}
        >
            {!hideHeader && (
                <div className="sliding-panel-header" ref={setToParentHeaderRef}>
                    <div className="sliding-panel-icon-close" onClick={onClose}>
                        {<span className={`${closeIcon ?? 'bat-icon-chevron-down'}`}></span>}
                    </div>
                    <div className="sliding-panel-title-wrapper">
                        <h2 className="sliding-panel-title">{title}</h2>
                    </div>
                </div>
            )}
            <div className="sliding-panel-content d-flex flex-column h-100">{children}</div>
            {!hideBottom && (
                <div className="page-selector-cta" >
                    <div className="d-grid">
                        <button type="button" className="btn btn-primary text-uppercase" onClick={onClose}>
                            {bottomLabel}
                        </button>
                    </div>
                </div>
            )}
        </Modal>
    );
}

export default CustomSlidingPanel;