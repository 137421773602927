import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { logScreenViewAnalyticsEventBy, logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import BrowserHelper from '../../_helpers/browser/browser.helper';
import { getDoneCountrySelection } from "../../_actions/appData.actions";
import { getSupportedServices, getLocale, getOnetrustKey } from '../../_actions/appConfig.actions';
import { servicesConstants, AnalyticsEvents, AnalyticsFieldNames } from '../../_constants';
import { isNil } from 'lodash';
/**
 * AppContainer, container of a page
 * @param {*} props 
 */
export const PageContainer = (props) => {
  const {
    children
  } = props;

  const location = useLocation();
  const history = useHistory();

  const checkIfOnetrustIncluded = () => {
    return Array.from(document.scripts).some(script => script.src.includes('cdn.cookielaw.org'))
  }

  // When a page is changed
  useEffect(() => {
    const currentUrl = window.location.pathname;
    if (!isNil(currentUrl)) {
      if (
        currentUrl?.includes("android-pp") ||
        currentUrl?.includes("android-tac") ||
        currentUrl?.includes("gps-pp") ||
        currentUrl?.includes("account-deletion")
      ) {
        return;
      }
    }
    // Keep default behavior of restoring scroll position when user:
    // - clicked back button
    // - clicked on a link that programmatically calls `history.goBack()`
    // - manually changed the URL in the address bar (here we might want
    // to scroll to top, but we can't differentiate it from the others)
    // In all other cases, scroll to top
    if (history.action !== 'POP' && currentUrl !== '/advanced-settings') {
        window.scrollTo(0, 0);
    }
    
    logScreenViewAnalyticsEventBy(location)

    let doneCountrySelection = getDoneCountrySelection()

    const isOneTrustReady = checkIfOnetrustIncluded()

    if (getSupportedServices().includes(servicesConstants.ONE_TRUST_COOKIE) && !isOneTrustReady && doneCountrySelection) {
      const onetrustKey = getOnetrustKey()

      if (onetrustKey) {
        const autoblockScript = document.createElement('script')
        autoblockScript.setAttribute('type', 'text/javascript')
        autoblockScript.setAttribute('src', 'https://cdn.cookielaw.org/consent/' + onetrustKey + '/OtAutoBlock.js')
        document.head.appendChild(autoblockScript)

        window.OptanonWrapper = () => {
          const locale_iso = getLocale().slice(0, 2)
          window.OneTrust.changeLanguage(locale_iso)

          function refreshIfDisabled() {
            if (window.OnetrustActiveGroups.indexOf('C0002') === -1) {
              window.location.reload();
            }
          }

          var pcSaveBtn = document.getElementsByClassName("save-preference-btn-handler onetrust-close-btn-handler")[0];
          var pcDisableAllBtn = document.getElementsByClassName("ot-pc-refuse-all-handler")[0];
          var initDisableAllBtn = document.getElementById("onetrust-reject-all-handler");
          var cookieCheckbox = document.getElementsByClassName("ot-switch-nob")[0];

          var firing = false;

          if(pcSaveBtn){
            pcSaveBtn.addEventListener('click', function() {
              if (!firing){
                logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {
                  field_name: AnalyticsFieldNames.COOKIE_CONSENT,
                  field_value: cookieCheckbox.getAttribute("aria-checked")
                });
                firing = true;
              }
              //setTimeout(()=> {
              //  refreshIfDisabled()
              //}, 1000) //quick timeout so that the consent receipt can be sent and the cookie can be updated
            });
          }

          if (pcDisableAllBtn) {
            pcDisableAllBtn.addEventListener('click', function() {
              if (!firing){
                logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {
                  field_name: AnalyticsFieldNames.COOKIE_CONSENT,
                  field_value: false
                });
              }
              firing = true;
              refreshIfDisabled();
            });
          }

          if (initDisableAllBtn) {
            initDisableAllBtn.addEventListener('click', function() {
              refreshIfDisabled()
            });
          }
        }

        const sdkScript = document.createElement('script')
        sdkScript.setAttribute('type', 'text/javascript')
        sdkScript.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js')
        sdkScript.setAttribute('charset', 'UTF-8')
        sdkScript.setAttribute('data-document-language', 'true')
        sdkScript.setAttribute('data-domain-script', onetrustKey)
        document.head.appendChild(sdkScript)
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
    
  return <>{children}</>;
}