import {
  closestCenter,
  DndContext,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor, restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { arrayMove, horizontalListSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import cx from "classnames";
import React, { forwardRef, useEffect, useState } from "react";

export const SortableList = forwardRef(({
  items: defaultItems,
  customClass,
  children,
  onDragStart,
  onDragEnd,
  onReorder
}, ref) => {

  const [state, setState] = useState({
    items: defaultItems,
    index: 0,
  });
  
  const [isDragging, setIsDragging] = useState(false);
  const sensors = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: {
          delay: 250,
          tolerance: 5
      }
  }),
    // useSensor(PointerSensor),
    // useSensor(MouseSensor)
  );

  useEffect(() => onReorder && onReorder(state.items, state.index), [state]);

  const handleDragStart = ({ active }) => {
    setIsDragging(true);

    const activeIndex = state.items.findIndex((item) => item?.id === active.id);

    onDragStart && onDragStart(activeIndex);
  };

  const handleDragEnd = ({ active, over }) => {
    setIsDragging(false);

    if (!over) {
      return;
    }

    if (active.id !== over.id) {
      setState(({ items }) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return {
          items: arrayMove(items, oldIndex, newIndex),
          index: newIndex,
        };
      });
    }

    onDragEnd && onDragEnd();
  };

  return (
    <DndContext
      modifiers={[restrictToHorizontalAxis, restrictToFirstScrollableAncestor]}
      collisionDetection={closestCenter}
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext 
        items={state.items}
        strategy={horizontalListSortingStrategy}
      >
        <div
          ref={ref}
          className={cx("sortable-list sortable-list-horizontal", customClass, {
            "sortable-list-dragging": isDragging,
          })}
        >
          {children}
        </div>
      </SortableContext>
    </DndContext>
  );
})

