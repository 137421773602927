import { permissionsActions } from '../../_actions';
import { PERMISSION_STATUS } from '../../_constants';
import { store } from '../../_store';

export class PermissionsHelper {
  static hasGrantedStatus(status) {
    return status === PERMISSION_STATUS.GRANTED;
  }

  static hasDeniedStatus(status) {
    return status === PERMISSION_STATUS.DENIED;
  }

  static hasDefaultStatus(status) {
    return status === PERMISSION_STATUS.DEFAULT;
  }

  static hasSelectAValidStatus(status) {
    return (
      this.hasGrantedStatus(status) ||
      this.hasDeniedStatus(status) ||
      this.hasDefaultStatus(status)
    );
  }

  static setNotificationPermissionStatus(status) {
    return store.dispatch(
      permissionsActions.setNotificationPermissionStatus(status)
    );
  }
  static resetNotificationPermissionStatus() {
    return store.dispatch(
      permissionsActions.resetNotificationPermissionStatus()
    );
  }
}
