import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import {
  GPSPrivacyPolicyContent } from "../../_components";
import { imagesConstants } from "../../_constants";
import StepWizard from "react-step-wizard";
import AEMCreator from "../../_helpers/aem/aemCreator";
import { GPSAccountDeletionContent } from "../../_components/GPSAccountDeletionContent";
import { PublicPageCountrySelector } from "../../_components/PublicPageCountrySelector";
import { routingConstants } from "../../_constants";

const PublicPage = ({ match }) => {
  const [wizard, setWizard] = useState(null);
  const [selection, setSelection] = useState(null);
  const { path } = match;

  let content;

  if(path === routingConstants.GPS_PP){
    content = 
    <>
      <h1>Privacy Policy</h1>
      <p>
        Please select your country of residence to confirm access to the
        relevant MyVuse privacy policy.
      </p>
    </>
  } else if (path === routingConstants.ACCOUNT_DELETION) {
    content = 
    <>
      <h1>Delete Account</h1>
      <p>
        Please select your country of residence to confirm access to the relevant information.
      </p>
    </>
  }

  const handleSelect = async (value) => {
    await AEMCreator.initAEM(value.province, value.language);
    setSelection(value);
    wizard.nextStep();
  };

  const handleChangeLocation = () => {
    console.debug("handleChangeLocation");
    setSelection(null);
    wizard.firstStep();
  };

  useEffect(() => {
    document.body.classList.add("gps-pp-page");

    return () => {
      document.body.classList.remove("gps-pp-page");
    };
  }, []);

  return (
    <div className="gps-pp">
      <header className="gps-pp-header">
        <div className="gps-pp-wrapper">
          <div className="gps-pp-brand">
            <img src={imagesConstants.MYVUSE_lOGO} />
          </div>
          {!isNil(selection) && (
            <div
              className="gps-pp-change-location"
              onClick={handleChangeLocation}
            >
              <img
                alt="country flag"
                src={
                  imagesConstants?.[
                    `FLAG_${selection?.province?.country_iso}`
                  ] || imagesConstants.FLAG_CA
                }
              />
              Change Location
            </div>
          )}
        </div>
      </header>
      <StepWizard
        isLazyMount
        className="gps-pp-body"
        instance={(wizard) => setWizard(wizard)}
      >
        <PublicPageCountrySelector onSelect={handleSelect} content={content}/>
        {path === routingConstants.GPS_PP && <GPSPrivacyPolicyContent />}
        {path === routingConstants.ACCOUNT_DELETION && <GPSAccountDeletionContent />}
      </StepWizard>
    </div>
  );
};

export { PublicPage };
