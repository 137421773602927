export class VaultHelper {
  static getSecurityQuestionsBy(dictionary) {
    return Object.keys(dictionary)
      .filter(key => !!key.match(/^VAULT_STEP_QUESTION_\d+$/gi) && !!dictionary[key])
      .sort((nextKey, prevKey) => {
        const nextCount = parseInt(nextKey.match(/\d+/)[0]);
        const prevCount = parseInt(prevKey.match(/\d+/)[0]);
        return nextCount > prevCount ? 1 : -1;
      })
      .map((k, index) => ({
        item: index + 1,
        value: dictionary[k],
        placeholder: "",
        key: k
      }))
  }

  static getSecurityQuestionItemsBy(dictionary) {
    return VaultHelper.getSecurityQuestionsBy(dictionary).map(q => ({
      item: q,
      value: q.value,
      key: q.key
    }))
  }
}
