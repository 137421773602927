import { isNil } from "lodash";
import { deviceImages, imagesConstants } from "../../_constants";
import { Tenants } from "../tenants";

export function getAssetsForDevice(type) {
  return isNil(type) ? deviceImages.ePod2 : deviceImages[type];
}

export function getDeviceSelectionImg(deviceType) {
  const assets = getAssetsForDevice(deviceType);

  return Tenants.isCanadaDark()
    ? assets.DEVICE_SELECTION.DEVICE
    : assets.DEVICE_SELECTION.DEVICE_ORIGAMI;
}

export function getColorSelectionImg(deviceType, deviceColor, active = true) {
  const assets = getAssetsForDevice(deviceType);
  const state = active ? "ACTIVE" : "INACTIVE";

  return assets?.COLOR_SELECTION[`${deviceColor}_${state}`];
}

export function getPairingCompletedImg(device) {
  const assets = getAssetsForDevice(device?.deviceType);
  const origami = Tenants.isCanadaDark() ? "" : "_ORIGAMI";

  return assets?.PAIRING_COMPLETED[
    `${device?.deviceColor || "SKIN_COLOR_BLACK"}${origami}`
  ];
}

export function getDeviceHomeScreenImg(device) {
  const assets = getAssetsForDevice(device?.deviceType);

  return assets?.HOMESCREEN[`${device?.deviceColor}`];
}

export function getFindMyVapePanelImg(device) {
  const assets = getAssetsForDevice(device?.deviceType);

  return assets?.FIND_MY_VAPE[`PANEL_${device?.deviceColor}`];
}

export function getFindMyVapeMarkerImg(device, active = true) {
  const assets = getAssetsForDevice(device?.deviceType);
  const state = active ? "ACTIVE" : "INACTIVE";
  const market = Tenants.isCanadaDark() ? "DARK" : "GLOBAL";

  return assets?.FIND_MY_VAPE[
    `MARKER_${device?.deviceColor}_${state}_${market}`
  ];
}

export function getManageDevicesItemImg(device) {
  const assets = getAssetsForDevice(device?.deviceType);

  return assets?.MANAGE_DEVICES[device?.deviceColor];
}

export function getDeviceIconImg(device) {
  const assets = getAssetsForDevice(device?.deviceType);
  const origami = !Tenants.isCanadaDark() ? "_ORIGAMI" : "";

  return assets?.DEVICE_ICON[`${device?.deviceColor}${origami}`];
}

export function getDeviceIcon(device) {
  const assets = getAssetsForDevice(device?.deviceType);

  return assets?.ICON[`${device?.deviceColor}`];
}

export function getDeviceSwitcherImg(device) {
  const assets = getAssetsForDevice(device?.deviceType);
  const market = Tenants.isCanadaDark()
    ? "DARK"
    : Tenants.isCanadaLight()
    ? "LIGHT"
    : "GLOBAL";

  return assets?.DEVICE_SWITCHER[`${device?.deviceColor}_${market}`];
}

export function getFeatureWidgetImg(featureName, fullWidth = false) {
  return imagesConstants?.[
    `FEATURE_WIDGET_${featureName}${fullWidth ? "_FULL" : ""}`
  ];
}
