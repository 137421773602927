export const imagesConstants = {
  MYVUSE_lOGO: require("../_assets/img/MyVuseLogo.webp"),
  MYVUSE_lOGO_DARK: require("../_assets/img/MYVUSE_logo_dark.svg"),
  APP_STORE_LOGO: require("../_assets/img/app_store_icon.webp"),
  GOOGLE_PLAY_LOGO: require("../_assets/img/google-play-icon.webp"),
  NEW_EMAIL: require("../_assets/img/new-email.svg"),
  NOTIFICATION: require("../_assets/img/notification.svg"),
  FLAG_CA: require("../_assets/img/flags/CA.svg"),
  FLAG_GB: require("../_assets/img/flags/GB.svg"),
  FLAG_DE: require("../_assets/img/flags/DE.webp"),
  FLAG_ZA: require("../_assets/img/flags/ZA.svg"),
  DOWNLOAD: require("../_assets/img/download.svg"),
  DOWNLOAD_FAILURE: require("../_assets/img/download_failure.svg"),
  BLUETOOTH: require("../_assets/img/Bluetooth.svg"),
  //vault
  SPLASH_Loop: require("../_assets/video/PIN_Setup/1_SPLASH_Loop.mp4"),
  PIN_ScaleDown: require("../_assets/video/PIN_Setup/2-0_PIN_ScaleDown.mp4"),
  PIN_LOOP: require("../_assets/video/PIN_Setup/2-1_PIN_LOOP.mp4"),
  PIN_ScaleUp: require("../_assets/video/PIN_Setup/3_PIN_ScaleUp.mp4"),
  SECURED_Final: require("../_assets/video/PIN_Setup/4_SECURED_Final.mp4"),
  //fmv optin
  OPTIN_FIND_VAPE: require("../_assets/img/Group-307.svg"),
  GOOGLE_ICON: require("../_assets/img/google_icon.svg"),
  GOOGLEMAPS_LOGO: require("../_assets/img/GoogleMaps_logo.svg"),

  BLUETOOTH_ICON: require("../_assets/img/atoms-system-i-os-bluetooth.webp"),
  SYSTEM_ICON: require("../_assets/img/atoms-system-i-os-settings.webp"),
  PRIVACY_ICON: require("../_assets/img/atoms-system-i-os-privacy.webp"),
  LOCATION_ICON: require("../_assets/img/atoms-system-i-os-location.webp"),

  NOTIFICATIONS_ICON: require("../_assets/img/atoms-system-i-os-notifications.webp"),
  TOGGLE_ICON: require("../_assets/img/atoms-system-i-os-toggle.webp"),
  NUVIU_ICON: require("../_assets/img/atoms-system-i-os-nuviu.webp"),

  FMV_HANDLE_OPEN: require("../_assets/img/handle-fmv-open.svg"),
  FMV_HANDLE_CLOSE: require("../_assets/img/handle-fmv-close.svg"),

  EPOD2_DEVICE_SELECTION_DEVICE: require("../_assets/img/device-selection/ePod2/device.webp"),
  EPOD2_DEVICE_SELECTION_DEVICE_ORIGAMI: require("../_assets/img/device-selection/ePod2/device-origami.webp"),

  EPOD3_DEVICE_SELECTION_DEVICE: require("../_assets/img/device-selection/ePod3/device.webp"),
  EPOD3_DEVICE_SELECTION_DEVICE_ORIGAMI: require("../_assets/img/device-selection/ePod3/device-origami.webp"),

  EPOD2_COLOR_SELECTION_BLACK_ACTIVE: require("../_assets/img/color-selection/ePod2/black-active.webp"),
  EPOD2_COLOR_SELECTION_BLACK_INACTIVE: require("../_assets/img/color-selection/ePod2/black-inactive.webp"),
  EPOD2_COLOR_SELECTION_BLUE_ACTIVE: require("../_assets/img/color-selection/ePod2/blue-active.webp"),
  EPOD2_COLOR_SELECTION_BLUE_INACTIVE: require("../_assets/img/color-selection/ePod2/blue-inactive.webp"),
  EPOD2_COLOR_SELECTION_CYAN_ACTIVE: require("../_assets/img/color-selection/ePod2/cyan-active.webp"),
  EPOD2_COLOR_SELECTION_CYAN_INACTIVE: require("../_assets/img/color-selection/ePod2/cyan-inactive.webp"),
  EPOD2_COLOR_SELECTION_GOLD_ACTIVE: require("../_assets/img/color-selection/ePod2/gold-active.webp"),
  EPOD2_COLOR_SELECTION_GOLD_INACTIVE: require("../_assets/img/color-selection/ePod2/gold-inactive.webp"),
  EPOD2_COLOR_SELECTION_PINK_ACTIVE: require("../_assets/img/color-selection/ePod2/pink-active.webp"),
  EPOD2_COLOR_SELECTION_PINK_INACTIVE: require("../_assets/img/color-selection/ePod2/pink-inactive.webp"),
  EPOD2_COLOR_SELECTION_RED_ACTIVE: require("../_assets/img/color-selection/ePod2/red-active.webp"),
  EPOD2_COLOR_SELECTION_RED_INACTIVE: require("../_assets/img/color-selection/ePod2/red-inactive.webp"),
  EPOD2_COLOR_SELECTION_REFLEKTA_ACTIVE: require("../_assets/img/color-selection/ePod2/reflekta-active.webp"),
  EPOD2_COLOR_SELECTION_REFLEKTA_INACTIVE: require("../_assets/img/color-selection/ePod2/reflekta-inactive.webp"),

  EPOD3_COLOR_SELECTION_BLACK_ACTIVE: require("../_assets/img/color-selection/ePod3/black-active.webp"),
  EPOD3_COLOR_SELECTION_BLACK_INACTIVE: require("../_assets/img/color-selection/ePod3/black-inactive.webp"),
  EPOD3_COLOR_SELECTION_BLUE_ACTIVE: require("../_assets/img/color-selection/ePod3/blue-active.webp"),
  EPOD3_COLOR_SELECTION_BLUE_INACTIVE: require("../_assets/img/color-selection/ePod3/blue-inactive.webp"),
  EPOD3_COLOR_SELECTION_CYAN_ACTIVE: require("../_assets/img/color-selection/ePod3/cyan-active.webp"),
  EPOD3_COLOR_SELECTION_CYAN_INACTIVE: require("../_assets/img/color-selection/ePod3/cyan-inactive.webp"),
  EPOD3_COLOR_SELECTION_GOLD_ACTIVE: require("../_assets/img/color-selection/ePod3/gold-active.webp"),
  EPOD3_COLOR_SELECTION_GOLD_INACTIVE: require("../_assets/img/color-selection/ePod3/gold-inactive.webp"),
  EPOD3_COLOR_SELECTION_PINK_ACTIVE: require("../_assets/img/color-selection/ePod3/pink-active.webp"),
  EPOD3_COLOR_SELECTION_PINK_INACTIVE: require("../_assets/img/color-selection/ePod3/pink-inactive.webp"),
  EPOD3_COLOR_SELECTION_RED_ACTIVE: require("../_assets/img/color-selection/ePod3/red-active.webp"),
  EPOD3_COLOR_SELECTION_RED_INACTIVE: require("../_assets/img/color-selection/ePod3/red-inactive.webp"),

  EPOD2_FMV_MARKER_SKIN_COLOR_BLACK_DARK_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/black-active-ca-dark.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_BLACK_DARK_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/black-inactive-ca-dark.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_BLACK_GLOBAL_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/black-active-global.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_BLACK_GLOBAL_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/black-inactive-global.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_BLUE_DARK_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/blue-active-ca-dark.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_BLUE_DARK_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/blue-inactive-ca-dark.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_BLUE_GLOBAL_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/blue-active-global.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_BLUE_GLOBAL_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/blue-inactive-global.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_CYAN_DARK_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/cyan-active-ca-dark.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_CYAN_DARK_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/cyan-inactive-ca-dark.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_CYAN_GLOBAL_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/cyan-active-global.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_CYAN_GLOBAL_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/cyan-inactive-global.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_GOLD_DARK_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/gold-active-ca-dark.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_GOLD_DARK_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/gold-inactive-ca-dark.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_GOLD_GLOBAL_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/gold-active-global.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_GOLD_GLOBAL_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/gold-inactive-global.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_PINK_DARK_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/pink-active-ca-dark.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_PINK_DARK_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/pink-inactive-ca-dark.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_PINK_GLOBAL_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/pink-active-global.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_PINK_GLOBAL_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/pink-inactive-global.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_RED_DARK_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/red-active-ca-dark.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_RED_DARK_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/red-inactive-ca-dark.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_RED_GLOBAL_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/red-active-global.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_RED_GLOBAL_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/red-inactive-global.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_REFLEKTA_DARK_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/reflekta-active-ca-dark.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_REFLEKTA_DARK_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/reflekta-inactive-ca-dark.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_REFLEKTA_GLOBAL_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/reflekta-active-global.webp"),
  EPOD2_FMV_MARKER_SKIN_COLOR_REFLEKTA_GLOBAL_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod2/reflekta-inactive-global.webp"),

  EPOD3_FMV_MARKER_SKIN_COLOR_BLACK_DARK_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/black-active-ca-dark.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_BLACK_DARK_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/black-inactive-ca-dark.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_BLACK_GLOBAL_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/black-active-global.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_BLACK_GLOBAL_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/black-inactive-global.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_BLUE_DARK_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/blue-active-ca-dark.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_BLUE_DARK_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/blue-inactive-ca-dark.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_BLUE_GLOBAL_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/blue-active-global.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_BLUE_GLOBAL_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/blue-inactive-global.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_CYAN_DARK_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/cyan-active-ca-dark.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_CYAN_DARK_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/cyan-inactive-ca-dark.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_CYAN_GLOBAL_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/cyan-active-global.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_CYAN_GLOBAL_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/cyan-inactive-global.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_GOLD_DARK_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/gold-active-ca-dark.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_GOLD_DARK_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/gold-inactive-ca-dark.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_GOLD_GLOBAL_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/gold-active-global.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_GOLD_GLOBAL_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/gold-inactive-global.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_PINK_DARK_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/pink-active-ca-dark.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_PINK_DARK_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/pink-inactive-ca-dark.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_PINK_GLOBAL_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/pink-active-global.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_PINK_GLOBAL_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/pink-inactive-global.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_RED_DARK_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/red-active-ca-dark.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_RED_DARK_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/red-inactive-ca-dark.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_RED_GLOBAL_ACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/red-active-global.webp"),
  EPOD3_FMV_MARKER_SKIN_COLOR_RED_GLOBAL_INACTIVE: require("../_assets/img/find-my-vape/markers/ePod3/red-inactive-global.webp"),

  EPOD2_FMV_PANEL_SKIN_COLOR_BLACK: require("../_assets/img/find-my-vape/panels/ePod2/black.webp"),
  EPOD2_FMV_PANEL_SKIN_COLOR_BLUE: require("../_assets/img/find-my-vape/panels/ePod2/blue.webp"),
  EPOD2_FMV_PANEL_SKIN_COLOR_RED: require("../_assets/img/find-my-vape/panels/ePod2/red.webp"),
  EPOD2_FMV_PANEL_SKIN_COLOR_CYAN: require("../_assets/img/find-my-vape/panels/ePod2/cyan.webp"),
  EPOD2_FMV_PANEL_SKIN_COLOR_GOLD: require("../_assets/img/find-my-vape/panels/ePod2/gold.webp"),
  EPOD2_FMV_PANEL_SKIN_COLOR_PINK: require("../_assets/img/find-my-vape/panels/ePod2/pink.webp"),
  EPOD2_FMV_PANEL_SKIN_COLOR_REFLEKTA: require("../_assets/img/find-my-vape/panels/ePod2/reflekta.webp"),

  EPOD3_FMV_PANEL_SKIN_COLOR_BLACK: require("../_assets/img/find-my-vape/panels/ePod3/black.webp"),
  EPOD3_FMV_PANEL_SKIN_COLOR_BLUE: require("../_assets/img/find-my-vape/panels/ePod3/blue.webp"),
  EPOD3_FMV_PANEL_SKIN_COLOR_RED: require("../_assets/img/find-my-vape/panels/ePod3/red.webp"),
  EPOD3_FMV_PANEL_SKIN_COLOR_CYAN: require("../_assets/img/find-my-vape/panels/ePod3/cyan.webp"),
  EPOD3_FMV_PANEL_SKIN_COLOR_GOLD: require("../_assets/img/find-my-vape/panels/ePod3/gold.webp"),
  EPOD3_FMV_PANEL_SKIN_COLOR_PINK: require("../_assets/img/find-my-vape/panels/ePod3/pink.webp"),

  EPOD2_HOMESCREEN_SKIN_COLOR_BLACK: require("../_assets/img/homescreen/ePod2/black.webp"),
  EPOD2_HOMESCREEN_SKIN_COLOR_BLUE: require("../_assets/img/homescreen/ePod2/blue.webp"),
  EPOD2_HOMESCREEN_SKIN_COLOR_CYAN: require("../_assets/img/homescreen/ePod2/cyan.webp"),
  EPOD2_HOMESCREEN_SKIN_COLOR_GOLD: require("../_assets/img/homescreen/ePod2/gold.webp"),
  EPOD2_HOMESCREEN_SKIN_COLOR_PINK: require("../_assets/img/homescreen/ePod2/pink.webp"),
  EPOD2_HOMESCREEN_SKIN_COLOR_RED: require("../_assets/img/homescreen/ePod2/red.webp"),
  EPOD2_HOMESCREEN_SKIN_COLOR_REFLEKTA: require("../_assets/img/homescreen/ePod2/reflekta.webp"),

  EPOD3_HOMESCREEN_SKIN_COLOR_BLACK: require("../_assets/img/homescreen/ePod3/black.webp"),
  EPOD3_HOMESCREEN_SKIN_COLOR_BLUE: require("../_assets/img/homescreen/ePod3/blue.webp"),
  EPOD3_HOMESCREEN_SKIN_COLOR_CYAN: require("../_assets/img/homescreen/ePod3/cyan.webp"),
  EPOD3_HOMESCREEN_SKIN_COLOR_GOLD: require("../_assets/img/homescreen/ePod3/gold.webp"),
  EPOD3_HOMESCREEN_SKIN_COLOR_PINK: require("../_assets/img/homescreen/ePod3/pink.webp"),
  EPOD3_HOMESCREEN_SKIN_COLOR_RED: require("../_assets/img/homescreen/ePod3/red.webp"),

  EPOD2_PAIRING_COMPLETED_SKIN_COLOR_BLACK: require("../_assets/img/pairing-completed/ePod2/black.jpg"),
  EPOD2_PAIRING_COMPLETED_SKIN_COLOR_BLACK_ORIGAMI: require("../_assets/img/pairing-completed/ePod2/black-origami.jpg"),
  EPOD2_PAIRING_COMPLETED_SKIN_COLOR_BLUE: require("../_assets/img/pairing-completed/ePod2/blue.jpg"),
  EPOD2_PAIRING_COMPLETED_SKIN_COLOR_BLUE_ORIGAMI: require("../_assets/img/pairing-completed/ePod2/blue-origami.jpg"),
  EPOD2_PAIRING_COMPLETED_SKIN_COLOR_CYAN: require("../_assets/img/pairing-completed/ePod2/cyan.jpg"),
  EPOD2_PAIRING_COMPLETED_SKIN_COLOR_CYAN_ORIGAMI: require("../_assets/img/pairing-completed/ePod2/cyan-origami.jpg"),
  EPOD2_PAIRING_COMPLETED_SKIN_COLOR_GOLD: require("../_assets/img/pairing-completed/ePod2/gold.jpg"),
  EPOD2_PAIRING_COMPLETED_SKIN_COLOR_GOLD_ORIGAMI: require("../_assets/img/pairing-completed/ePod2/gold-origami.jpg"),
  EPOD2_PAIRING_COMPLETED_SKIN_COLOR_PINK: require("../_assets/img/pairing-completed/ePod2/pink.jpg"),
  EPOD2_PAIRING_COMPLETED_SKIN_COLOR_PINK_ORIGAMI: require("../_assets/img/pairing-completed/ePod2/pink-origami.jpg"),
  EPOD2_PAIRING_COMPLETED_SKIN_COLOR_RED: require("../_assets/img/pairing-completed/ePod2/red.jpg"),
  EPOD2_PAIRING_COMPLETED_SKIN_COLOR_RED_ORIGAMI: require("../_assets/img/pairing-completed/ePod2/red-origami.jpg"),
  EPOD2_PAIRING_COMPLETED_SKIN_COLOR_REFLEKTA: require("../_assets/img/pairing-completed/ePod2/reflekta.jpg"),
  EPOD2_PAIRING_COMPLETED_SKIN_COLOR_REFLEKTA_ORIGAMI: require("../_assets/img/pairing-completed/ePod2/reflekta-origami.jpg"),

  EPOD3_PAIRING_COMPLETED_SKIN_COLOR_BLACK: require("../_assets/img/pairing-completed/ePod3/black.webp"),
  EPOD3_PAIRING_COMPLETED_SKIN_COLOR_BLACK_ORIGAMI: require("../_assets/img/pairing-completed/ePod3/black-origami.webp"),
  EPOD3_PAIRING_COMPLETED_SKIN_COLOR_BLUE: require("../_assets/img/pairing-completed/ePod3/blue.webp"),
  EPOD3_PAIRING_COMPLETED_SKIN_COLOR_BLUE_ORIGAMI: require("../_assets/img/pairing-completed/ePod3/blue-origami.webp"),
  EPOD3_PAIRING_COMPLETED_SKIN_COLOR_CYAN: require("../_assets/img/pairing-completed/ePod3/cyan.webp"),
  EPOD3_PAIRING_COMPLETED_SKIN_COLOR_CYAN_ORIGAMI: require("../_assets/img/pairing-completed/ePod3/cyan-origami.webp"),
  EPOD3_PAIRING_COMPLETED_SKIN_COLOR_GOLD: require("../_assets/img/pairing-completed/ePod3/gold.webp"),
  EPOD3_PAIRING_COMPLETED_SKIN_COLOR_GOLD_ORIGAMI: require("../_assets/img/pairing-completed/ePod3/gold-origami.webp"),
  EPOD3_PAIRING_COMPLETED_SKIN_COLOR_PINK: require("../_assets/img/pairing-completed/ePod3/pink.webp"),
  EPOD3_PAIRING_COMPLETED_SKIN_COLOR_PINK_ORIGAMI: require("../_assets/img/pairing-completed/ePod3/pink-origami.webp"),
  EPOD3_PAIRING_COMPLETED_SKIN_COLOR_RED: require("../_assets/img/pairing-completed/ePod3/red.webp"),
  EPOD3_PAIRING_COMPLETED_SKIN_COLOR_RED_ORIGAMI: require("../_assets/img/pairing-completed/ePod3/red-origami.webp"),

  EPOD2_MANAGE_DEVICES_SKIN_COLOR_BLACK: require("../_assets/img/manage-devices/ePod2/black.webp"),
  EPOD2_MANAGE_DEVICES_SKIN_COLOR_BLUE: require("../_assets/img/manage-devices/ePod2/blue.webp"),
  EPOD2_MANAGE_DEVICES_SKIN_COLOR_CYAN: require("../_assets/img/manage-devices/ePod2/cyan.webp"),
  EPOD2_MANAGE_DEVICES_SKIN_COLOR_GOLD: require("../_assets/img/manage-devices/ePod2/gold.webp"),
  EPOD2_MANAGE_DEVICES_SKIN_COLOR_PINK: require("../_assets/img/manage-devices/ePod2/pink.webp"),
  EPOD2_MANAGE_DEVICES_SKIN_COLOR_RED: require("../_assets/img/manage-devices/ePod2/red.webp"),
  EPOD2_MANAGE_DEVICES_SKIN_COLOR_REFLEKTA: require("../_assets/img/manage-devices/ePod2/reflekta.webp"),

  EPOD3_MANAGE_DEVICES_SKIN_COLOR_BLACK: require("../_assets/img/manage-devices/ePod3/black.webp"),
  EPOD3_MANAGE_DEVICES_SKIN_COLOR_BLUE: require("../_assets/img/manage-devices/ePod3/blue.webp"),
  EPOD3_MANAGE_DEVICES_SKIN_COLOR_CYAN: require("../_assets/img/manage-devices/ePod3/cyan.webp"),
  EPOD3_MANAGE_DEVICES_SKIN_COLOR_GOLD: require("../_assets/img/manage-devices/ePod3/gold.webp"),
  EPOD3_MANAGE_DEVICES_SKIN_COLOR_PINK: require("../_assets/img/manage-devices/ePod3/pink.webp"),
  EPOD3_MANAGE_DEVICES_SKIN_COLOR_RED: require("../_assets/img/manage-devices/ePod3/red.webp"),

  EPOD2_DEVICE_ICON_SKIN_COLOR_BLACK: require("../_assets/img/device-icons/ePod2/black.webp"),
  EPOD2_DEVICE_ICON_SKIN_COLOR_BLACK_ORIGAMI: require("../_assets/img/device-icons/ePod2/black-origami.webp"),
  EPOD2_DEVICE_ICON_SKIN_COLOR_BLUE: require("../_assets/img/device-icons/ePod2/blue.webp"),
  EPOD2_DEVICE_ICON_SKIN_COLOR_BLUE_ORIGAMI: require("../_assets/img/device-icons/ePod2/blue-origami.webp"),
  EPOD2_DEVICE_ICON_SKIN_COLOR_CYAN: require("../_assets/img/device-icons/ePod2/cyan.webp"),
  EPOD2_DEVICE_ICON_SKIN_COLOR_CYAN_ORIGAMI: require("../_assets/img/device-icons/ePod2/cyan-origami.webp"),
  EPOD2_DEVICE_ICON_SKIN_COLOR_GOLD: require("../_assets/img/device-icons/ePod2/gold.webp"),
  EPOD2_DEVICE_ICON_SKIN_COLOR_GOLD_ORIGAMI: require("../_assets/img/device-icons/ePod2/gold-origami.webp"),
  EPOD2_DEVICE_ICON_SKIN_COLOR_PINK: require("../_assets/img/device-icons/ePod2/pink.webp"),
  EPOD2_DEVICE_ICON_SKIN_COLOR_PINK_ORIGAMI: require("../_assets/img/device-icons/ePod2/pink-origami.webp"),
  EPOD2_DEVICE_ICON_SKIN_COLOR_RED: require("../_assets/img/device-icons/ePod2/red.webp"),
  EPOD2_DEVICE_ICON_SKIN_COLOR_RED_ORIGAMI: require("../_assets/img/device-icons/ePod2/red-origami.webp"),
  EPOD2_DEVICE_ICON_SKIN_COLOR_REFLEKTA: require("../_assets/img/device-icons/ePod2/reflekta.webp"),
  EPOD2_DEVICE_ICON_SKIN_COLOR_REFLEKTA_ORIGAMI: require("../_assets/img/device-icons/ePod2/reflekta-origami.webp"),

  EPOD3_DEVICE_ICON_SKIN_COLOR_BLACK: require("../_assets/img/device-icons/ePod3/black.webp"),
  EPOD3_DEVICE_ICON_SKIN_COLOR_BLACK_ORIGAMI: require("../_assets/img/device-icons/ePod3/black-origami.webp"),
  EPOD3_DEVICE_ICON_SKIN_COLOR_BLUE: require("../_assets/img/device-icons/ePod3/blue.webp"),
  EPOD3_DEVICE_ICON_SKIN_COLOR_BLUE_ORIGAMI: require("../_assets/img/device-icons/ePod3/blue-origami.webp"),
  EPOD3_DEVICE_ICON_SKIN_COLOR_CYAN: require("../_assets/img/device-icons/ePod3/cyan.webp"),
  EPOD3_DEVICE_ICON_SKIN_COLOR_CYAN_ORIGAMI: require("../_assets/img/device-icons/ePod3/cyan-origami.webp"),
  EPOD3_DEVICE_ICON_SKIN_COLOR_GOLD: require("../_assets/img/device-icons/ePod3/gold.webp"),
  EPOD3_DEVICE_ICON_SKIN_COLOR_GOLD_ORIGAMI: require("../_assets/img/device-icons/ePod3/gold-origami.webp"),
  EPOD3_DEVICE_ICON_SKIN_COLOR_PINK: require("../_assets/img/device-icons/ePod3/pink.webp"),
  EPOD3_DEVICE_ICON_SKIN_COLOR_PINK_ORIGAMI: require("../_assets/img/device-icons/ePod3/pink-origami.webp"),
  EPOD3_DEVICE_ICON_SKIN_COLOR_RED: require("../_assets/img/device-icons/ePod3/red.webp"),
  EPOD3_DEVICE_ICON_SKIN_COLOR_RED_ORIGAMI: require("../_assets/img/device-icons/ePod3/red-origami.webp"),

  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_BLACK_DARK: require("../_assets/img/device-switcher/ePod2/black-ca-dark.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_BLACK_GLOBAL: require("../_assets/img/device-switcher/ePod2/black-global.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_BLACK_LIGHT: require("../_assets/img/device-switcher/ePod2/black-ca-light.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_BLUE_DARK: require("../_assets/img/device-switcher/ePod2/blue-ca-dark.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_BLUE_GLOBAL: require("../_assets/img/device-switcher/ePod2/blue-global.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_BLUE_LIGHT: require("../_assets/img/device-switcher/ePod2/blue-ca-light.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_CYAN_DARK: require("../_assets/img/device-switcher/ePod2/cyan-ca-dark.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_CYAN_GLOBAL: require("../_assets/img/device-switcher/ePod2/cyan-global.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_CYAN_LIGHT: require("../_assets/img/device-switcher/ePod2/cyan-ca-light.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_GOLD_DARK: require("../_assets/img/device-switcher/ePod2/gold-ca-dark.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_GOLD_GLOBAL: require("../_assets/img/device-switcher/ePod2/gold-global.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_GOLD_LIGHT: require("../_assets/img/device-switcher/ePod2/gold-ca-light.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_PINK_DARK: require("../_assets/img/device-switcher/ePod2/pink-ca-dark.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_PINK_GLOBAL: require("../_assets/img/device-switcher/ePod2/pink-global.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_PINK_LIGHT: require("../_assets/img/device-switcher/ePod2/pink-ca-light.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_RED_DARK: require("../_assets/img/device-switcher/ePod2/red-ca-dark.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_RED_GLOBAL: require("../_assets/img/device-switcher/ePod2/red-global.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_RED_LIGHT: require("../_assets/img/device-switcher/ePod2/red-ca-light.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_REFLEKTA_DARK: require("../_assets/img/device-switcher/ePod2/reflekta-ca-dark.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_REFLEKTA_GLOBAL: require("../_assets/img/device-switcher/ePod2/reflekta-global.webp"),
  // EPOD2_DEVICE_SWITCHER_SKIN_COLOR_REFLEKTA_LIGHT: require("../_assets/img/device-switcher/ePod2/reflekta-ca-light.webp"),

  // EPOD3_DEVICE_SWITCHER_SKIN_COLOR_BLACK_DARK: require("../_assets/img/device-switcher/ePod3/black-ca-dark.webp"),
  // EPOD3_DEVICE_SWITCHER_SKIN_COLOR_BLACK_GLOBAL: require("../_assets/img/device-switcher/ePod3/black-global.webp"),
  // EPOD3_DEVICE_SWITCHER_SKIN_COLOR_BLACK_LIGHT: require("../_assets/img/device-switcher/ePod3/black-ca-light.webp"),
  // EPOD3_DEVICE_SWITCHER_SKIN_COLOR_BLUE_DARK: require("../_assets/img/device-switcher/ePod3/blue-ca-dark.webp"),
  // EPOD3_DEVICE_SWITCHER_SKIN_COLOR_BLUE_GLOBAL: require("../_assets/img/device-switcher/ePod3/blue-global.webp"),
  // EPOD3_DEVICE_SWITCHER_SKIN_COLOR_BLUE_LIGHT: require("../_assets/img/device-switcher/ePod3/blue-ca-light.webp"),
  // EPOD3_DEVICE_SWITCHER_SKIN_COLOR_CYAN_DARK: require("../_assets/img/device-switcher/ePod3/cyan-ca-dark.webp"),
  // EPOD3_DEVICE_SWITCHER_SKIN_COLOR_CYAN_GLOBAL: require("../_assets/img/device-switcher/ePod3/cyan-global.webp"),
  // EPOD3_DEVICE_SWITCHER_SKIN_COLOR_CYAN_LIGHT: require("../_assets/img/device-switcher/ePod3/cyan-ca-light.webp"),
  // EPOD3_DEVICE_SWITCHER_SKIN_COLOR_GOLD_DARK: require("../_assets/img/device-switcher/ePod3/gold-ca-dark.webp"),
  // EPOD3_DEVICE_SWITCHER_SKIN_COLOR_GOLD_GLOBAL: require("../_assets/img/device-switcher/ePod3/gold-global.webp"),
  // EPOD3_DEVICE_SWITCHER_SKIN_COLOR_GOLD_LIGHT: require("../_assets/img/device-switcher/ePod3/gold-ca-light.webp"),
  // EPOD3_DEVICE_SWITCHER_SKIN_COLOR_PINK_DARK: require("../_assets/img/device-switcher/ePod3/pink-ca-dark.webp"),
  // EPOD3_DEVICE_SWITCHER_SKIN_COLOR_PINK_GLOBAL: require("../_assets/img/device-switcher/ePod3/pink-global.webp"),
  // EPOD3_DEVICE_SWITCHER_SKIN_COLOR_PINK_LIGHT: require("../_assets/img/device-switcher/ePod3/pink-ca-light.webp"),
  // EPOD3_DEVICE_SWITCHER_SKIN_COLOR_RED_DARK: require("../_assets/img/device-switcher/ePod3/red-ca-dark.webp"),
  // EPOD3_DEVICE_SWITCHER_SKIN_COLOR_RED_GLOBAL: require("../_assets/img/device-switcher/ePod3/red-global.webp"),
  // EPOD3_DEVICE_SWITCHER_SKIN_COLOR_RED_LIGHT: require("../_assets/img/device-switcher/ePod3/red-ca-light.webp"),

  // APP REDESIGN

  EPOD2_DEVICE_ICON_RE_SKIN_COLOR_BLACK: require("../_assets/img/device-icons-redesign/ePod2/black.webp"),
  EPOD2_DEVICE_ICON_RE_SKIN_COLOR_BLUE: require("../_assets/img/device-icons-redesign/ePod2/blue.webp"),
  EPOD2_DEVICE_ICON_RE_SKIN_COLOR_CYAN: require("../_assets/img/device-icons-redesign/ePod2/cyan.webp"),
  EPOD2_DEVICE_ICON_RE_SKIN_COLOR_GOLD: require("../_assets/img/device-icons-redesign/ePod2/gold.webp"),
  EPOD2_DEVICE_ICON_RE_SKIN_COLOR_PINK: require("../_assets/img/device-icons-redesign/ePod2/pink.webp"),
  EPOD2_DEVICE_ICON_RE_SKIN_COLOR_RED: require("../_assets/img/device-icons-redesign/ePod2/red.webp"),
  EPOD2_DEVICE_ICON_RE_SKIN_COLOR_REFLEKTA: require("../_assets/img/device-icons-redesign/ePod2/reflekta.webp"),

  EPOD3_DEVICE_ICON_RE_SKIN_COLOR_BLACK: require("../_assets/img/device-icons-redesign/ePod3/black.webp"),
  EPOD3_DEVICE_ICON_RE_SKIN_COLOR_BLUE: require("../_assets/img/device-icons-redesign/ePod3/blue.webp"),
  EPOD3_DEVICE_ICON_RE_SKIN_COLOR_CYAN: require("../_assets/img/device-icons-redesign/ePod3/cyan.webp"),
  EPOD3_DEVICE_ICON_RE_SKIN_COLOR_GOLD: require("../_assets/img/device-icons-redesign/ePod3/gold.webp"),
  EPOD3_DEVICE_ICON_RE_SKIN_COLOR_PINK: require("../_assets/img/device-icons-redesign/ePod3/pink.webp"),
  EPOD3_DEVICE_ICON_RE_SKIN_COLOR_RED: require("../_assets/img/device-icons-redesign/ePod3/red.webp"),

  FEATURE_WIDGET_CLOUD_CONTROL: require("../_assets/img/feature-widgets/cloud-control.webp"),
  FEATURE_WIDGET_CLOUD_CONTROL_FULL: require("../_assets/img/feature-widgets/cloud-control-full.webp"),
  FEATURE_WIDGET_FIND_MY_VAPE: require("../_assets/img/feature-widgets/find-my-vape.webp"),
  FEATURE_WIDGET_FIND_MY_VAPE_FULL: require("../_assets/img/feature-widgets/find-my-vape-full.webp"),
  FEATURE_WIDGET_USAGE_TRACKER: require("../_assets/img/feature-widgets/usage-tracker.webp"),
  FEATURE_WIDGET_USAGE_TRACKER_FULL: require("../_assets/img/feature-widgets/usage-tracker-full.webp"),
  FEATURE_WIDGET_RECHARGE_REMINDERS: require("../_assets/img/feature-widgets/recharge-reminders.webp"),
  FEATURE_WIDGET_RECHARGE_REMINDERS_FULL: require("../_assets/img/feature-widgets/recharge-reminders-full.webp"),

  // APP REDESIGN

  NOTIFICATION_SUGGESTIONS_BACKGROUND: require("../_assets/img/suggestion-notification_background.webp"),

  HOMEPAGE_PAIRING_BACKGROUND: require("../_assets/img/pairing-completed/device-added.jpg"),

  VUSE_WORLD_DEFAULT_DOT: require("../_assets/img/vuse_world_cat_013.webp"),

  DRAWER_REDUCE_ARROW: require("../_assets/img/drawer_reduce_arrow.webp"),

  NUVIU_BROWSER_ICON: require("../_assets/img/nuviu-icon.webp"),
  // FIRMWARE UPDATE
  FW_UPDATE: require("../_assets/img/firmware-update/fw-update-icon.svg"),
  FW_UPDATE_FAILED: require("../_assets/img/firmware-update/fw-update-failed-icon.svg"),
  FW_UPDATE_LOW_BATTERY_ERROR: require("../_assets/img/firmware-update/fw-update-low-battery-icon.svg"),
  FW_UPDATE_NOT_ENOUGH_STORAGE_ERROR: require("../_assets/img/firmware-update/fw-update-not-enough-storage-icon.svg"),
  FW_UPDATE_LINK_LOSS_ERROR: require("../_assets/img/firmware-update/fw-update-link-loss-icon.svg"),
  FW_UPDATE_WARNING_ERROR: require("../_assets/img/firmware-update/fw-update-warning-icon.svg"),

  PLAY_TUTORIAL_ICON: require("../_assets/img/play-tutorial-icon.svg"),
  UT_MONTHLY_CHART_INDICATOR: require("../_assets/img/usage-tracker/monthly-chart-indicator.svg"),

  SUCCESS_ICON: require("../_assets/img/success-icon.svg"),
  FAILURE_ICON: require("../_assets/img/failure-icon.svg"),
  HARD_AGE_VERIFICATION_CAMERA: require("../_assets/img/hard-age-verification/scan-tips-camera.svg"),
  HARD_AGE_VERIFICATION_HAT_ICON: require("../_assets/img/hard-age-verification/scan-tips-hat-icon.svg"),
  HARD_AGE_VERIFICATION_SUN_ICON: require("../_assets/img/hard-age-verification/scan-tips-sun-icon.svg"),
  HARD_AGE_VERIFICATION_SUCCESS_ICON: require("../_assets/img/hard-age-verification/success-icon.svg"),
  HARD_AGE_VERIFICATION_SUNGLASSES_ICON: require("../_assets/img/hard-age-verification/scan-tips-sunglasses-icon.svg"),
  HARD_AGE_VERIFICATION_POWERED_BY_YOTI: require("../_assets/img/hard-age-verification/powered-by-yoti.svg"),

  NOTIFICATIONS_CLOSE: require("../_assets/img/notifications/close.webp"),

  NOTIFICATIONS_PROSMART_FIRMWARE_UPDATE: require("../_assets/img/notifications/prosmart/Notification_Firmware_Purple_Square.webp"),
  NOTIFICATIONS_PROSMART_FIRMWARE_ERROR: require("../_assets/img/notifications/prosmart/Notification_Firmware_Red_Square.webp"),
  NOTIFICATIONS_PROSMART_BATTERY: require("../_assets/img/notifications/prosmart/Notification_Battery_Vuse_Grandient_Square.webp"),
  NOTIFICATIONS_PROSMART_CLOUD: require("../_assets/img/notifications/prosmart/Notification_Cloud Control_Vuse_Grandient_Square.webp"),
  NOTIFICATIONS_PROSMART_LOCATION: require("../_assets/img/notifications/prosmart/Notification_Find my Vape_Vuse_Grandient_Square.webp"),
  NOTIFICATIONS_PROSMART_USAGE: require("../_assets/img/notifications/prosmart/Notification_Usage Tracker_Vuse_Grandient_Square.webp"),
  NOTIFICATIONS_PROSMART_LOGO_BLACK: require("../_assets/img/notifications/prosmart/Notification_Logo_Vuse_Black_Square.webp"),
  NOTIFICATIONS_PROSMART_LOGO_GRADIENT: require("../_assets/img/notifications/prosmart/Notification_Logo_Vuse_Grandient_Square.webp"),

  NOTIFICATIONS_PROSMART_FIRMWARE_UPDATE_CLOSE: require("../_assets/img/notifications/prosmart/Notification_Firmware_Purple_Square_Close.webp"),
  NOTIFICATIONS_PROSMART_FIRMWARE_ERROR_CLOSE: require("../_assets/img/notifications/prosmart/Notification_Firmware_Red_Square_Close.webp"),
  NOTIFICATIONS_PROSMART_BATTERY_CLOSE: require("../_assets/img/notifications/prosmart/Notification_Battery_Vuse_Grandient_Square_Close.webp"),
  NOTIFICATIONS_PROSMART_CLOUD_CLOSE: require("../_assets/img/notifications/prosmart/Notification_Cloud Control_Vuse_Grandient_Square_Close.webp"),
  NOTIFICATIONS_PROSMART_LOCATION_CLOSE: require("../_assets/img/notifications/prosmart/Notification_Find my Vape_Vuse_Grandient_Square_Close.webp"),
  NOTIFICATIONS_PROSMART_USAGE_CLOSE: require("../_assets/img/notifications/prosmart/Notification_Usage Tracker_Vuse_Grandient_Square_Close.webp"),
  NOTIFICATIONS_PROSMART_LOGO_BLACK_CLOSE: require("../_assets/img/notifications/prosmart/Notification_Logo_Vuse_Black_Square_Close.webp"),
  NOTIFICATIONS_PROSMART_LOGO_GRADIENT_CLOSE: require("../_assets/img/notifications/prosmart/Notification_Logo_Vuse_Grandient_Square_Close.webp"),


  NOTIFICATIONS_PROSMART_BATTERY_AQUA: require("../_assets/img/notifications/prosmart/Notification_Battery_Aqua_Square.webp"),
  NOTIFICATIONS_PROSMART_BATTERY_BLACK: require("../_assets/img/notifications/prosmart/Notification_Battery_Black_Square.webp"),
  NOTIFICATIONS_PROSMART_BATTERY_BLUE: require("../_assets/img/notifications/prosmart/Notification_Battery_Blue_Square.webp"),
  NOTIFICATIONS_PROSMART_BATTERY_GOLD: require("../_assets/img/notifications/prosmart/Notification_Battery_Gold_Square.webp"),
  NOTIFICATIONS_PROSMART_BATTERY_PINK: require("../_assets/img/notifications/prosmart/Notification_Battery_Pink_Square.webp"),
  NOTIFICATIONS_PROSMART_BATTERY_RED: require("../_assets/img/notifications/prosmart/Notification_Battery_Red_Square.webp"),

  NOTIFICATIONS_PROSMART_BATTERY_AQUA_CLOSE: require("../_assets/img/notifications/prosmart/Notification_Battery_Aqua_Square_Close.webp"),
  NOTIFICATIONS_PROSMART_BATTERY_BLACK_CLOSE: require("../_assets/img/notifications/prosmart/Notification_Battery_Black_Square_Close.webp"),
  NOTIFICATIONS_PROSMART_BATTERY_BLUE_CLOSE: require("../_assets/img/notifications/prosmart/Notification_Battery_Blue_Square_Close.webp"),
  NOTIFICATIONS_PROSMART_BATTERY_GOLD_CLOSE: require("../_assets/img/notifications/prosmart/Notification_Battery_Gold_Square_Close.webp"),
  NOTIFICATIONS_PROSMART_BATTERY_PINK_CLOSE: require("../_assets/img/notifications/prosmart/Notification_Battery_Pink_Square_Close.webp"),
  NOTIFICATIONS_PROSMART_BATTERY_RED_CLOSE: require("../_assets/img/notifications/prosmart/Notification_Battery_Red_Square_Close.webp"),

  NOTIFICATIONS_EPOD2_BATTERY_AQUA: require("../_assets/img/notifications/epod2/Notification_Battery_Aqua_Square.webp"),
  NOTIFICATIONS_EPOD2_BATTERY_BLACK: require("../_assets/img/notifications/epod2/Notification_Battery_Black_Square.webp"),
  NOTIFICATIONS_EPOD2_BATTERY_BLUE: require("../_assets/img/notifications/epod2/Notification_Battery_Blue_Square.webp"),
  NOTIFICATIONS_EPOD2_BATTERY_GOLD: require("../_assets/img/notifications/epod2/Notification_Battery_Gold_Square.webp"),
  NOTIFICATIONS_EPOD2_BATTERY_PINK: require("../_assets/img/notifications/epod2/Notification_Battery_Pink_Square.webp"),
  NOTIFICATIONS_EPOD2_BATTERY_RED: require("../_assets/img/notifications/epod2/Notification_Battery_Red_Square.webp"),
  NOTIFICATIONS_EPOD2_BATTERY_REFLEKTA: require("../_assets/img/notifications/epod2/Notification_Battery_Reflekta_Square.webp"),

  NOTIFICATIONS_EPOD2_BATTERY_AQUA_CLOSE: require("../_assets/img/notifications/epod2/Notification_Battery_Aqua_Square_Close.webp"),
  NOTIFICATIONS_EPOD2_BATTERY_BLACK_CLOSE: require("../_assets/img/notifications/epod2/Notification_Battery_Black_Square_Close.webp"),
  NOTIFICATIONS_EPOD2_BATTERY_BLUE_CLOSE: require("../_assets/img/notifications/epod2/Notification_Battery_Blue_Square_Close.webp"),
  NOTIFICATIONS_EPOD2_BATTERY_GOLD_CLOSE: require("../_assets/img/notifications/epod2/Notification_Battery_Gold_Square_Close.webp"),
  NOTIFICATIONS_EPOD2_BATTERY_PINK_CLOSE: require("../_assets/img/notifications/epod2/Notification_Battery_Pink_Square_Close.webp"),
  NOTIFICATIONS_EPOD2_BATTERY_RED_CLOSE: require("../_assets/img/notifications/epod2/Notification_Battery_Red_Square_Close.webp"),
  NOTIFICATIONS_EPOD2_BATTERY_REFLEKTA_CLOSE: require("../_assets/img/notifications/epod2/Notification_Battery_Reflekta_Square_Close.webp"),

  EDIT_ICON: require("../_assets/img/edit-icon.svg"),
  DISCONNECTED_CLOUD: require("../_assets/img/disconnected-cloud.svg"),
};
