import React, { useState, useEffect } from 'react';

export const CustomInlineAlert = (props) => {

    
    useEffect(() => {
        setAlertType(props.alertType);
      }, [props.alertType])

    const [alertType, setAlertType] = useState("info"); //info, error

    return (
        <div className={`inline-alert inline-alert-${alertType} ${props.isShown ? 'showing' : 'hiding'} ${props.className ?? ""}`}>
            <span className="bat-icon-alert-circle"></span>
            {props.children}
        </div>
    );
};