import { isFunction } from "lodash";
import { FAILURE, REQUEST, SUCCESS } from "../_reducers/action-type.util";

export function getRequestActions(actionName) {
  return {
    request: () => ({
      type: REQUEST(actionName),
    }),
    success: (payload) => ({
      type: SUCCESS(actionName),
      payload,
    }),
    failure: (error) => ({
      type: FAILURE(actionName),
      error,
    }),
  };
}

export function createRequest({
  actionName,
  serviceCb,
  onSuccess,
  onDataReceived,
  onFailure,
}) {
  return async (dispatch, getState) => {
    const { request, success, failure } = getRequestActions(actionName);

    dispatch(request());

    try {
      const res = await serviceCb();

      if (!res?.data) {
        throw new Error(res.message);
      }

      const data = res.data?.data;

      dispatch(success(data));
      isFunction(onDataReceived) && onDataReceived(data, dispatch, getState);
      isFunction(onSuccess) && onSuccess(data);
    } catch (err) {
      dispatch(failure(err));
      isFunction(onFailure) && onFailure(err);
    }
  };
}
