import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Badge } from '@mui/material';
import { AnalyticsEvents, imagesConstants } from '../../../../_constants';
import { CustomModal } from '../../../../_components/CustomModal';
import HealthWarning from '../../../../_components/HealthWarning';
import { ShopUtils } from '../../../../_helpers/shop';
import { ShopCheckoutElement } from '../../../../_components/ShopCheckoutElement';
import { BasketElementModel } from '../../../../_models';
import { shopActions } from '../../../../_actions';
import { calculateTotalPriceFromBasket, calculateTotalQuantityFromBasket, logAnalyticsEventOfBasketVariantList } from '../../../../_helpers/basket';
import { Utils } from '../../../../_helpers/graphic/utils';

export const ShopOrderDetail = ({
    order,
    isOpen,
    dictionary,
    closeFeatureModal,
    onOpenBasketPanel,
    onStartCheckout
}) => {

    const basket = useSelector(state => state.shopReducer.basket);
    const loading = useSelector((state) => state.shopReducer.loadingCatalog);

    const dispatch = useDispatch();

    const [availableLines, setAvailableLines] = useState([]);
    const [unavailableLines, setUnavailableLines] = useState([]);
    const [basketQty, setBasketQty] = useState(0);
    const [orderTotal, setOrderTotal] = useState(0);
    const [orderQty, setOrderQty] = useState(0);

    const orderPanelContent = useRef(null);

    useEffect(() => {
        console.log("[SHOP] current order", order);

        //when order changes recalculate available and unavailable items lists of order
        let currentAvailableLines = [];
        let currentUnavailableLines = [];
        if (order && order.items) {
            order.items.forEach((line, idx) => {

                let variant = ShopUtils.getVariantFromSku(line.sku);
                if (variant) {
                    if (variant.quantity > 0) {
                        currentAvailableLines.push(new BasketElementModel({
                            ...variant,
                            basket: line.quantity
                        }))
                    } else {
                        currentUnavailableLines.push(new BasketElementModel({
                            ...variant,
                            basket: line.quantity
                        }))
                    }

                } else {
                    //atm we don't do anything if variant is no mode in catalog
                }
            })

            setAvailableLines(currentAvailableLines);
            setUnavailableLines(currentUnavailableLines);
        }
    }, [order, loading]);

    //when lines update recalculate quantity and total of current order
    useEffect(() => {
        setOrderQty(calculateTotalQuantityFromBasket(availableLines));
        setOrderTotal(calculateTotalPriceFromBasket(availableLines));
    }, [availableLines]);

    useEffect(() => {
        if(
            basket !== null && 
            basket !== undefined
        ){
            setBasketQty(basket.reduce((a, b) => a + (b.basket ?? 0), 0));
        }
    }, [basket]);

    const onCloseOrderPanel = () => {
        if (typeof closeFeatureModal === "function") {
            closeFeatureModal();
        }
        //make content scroll top when panel closes
        if (orderPanelContent?.current) {
            orderPanelContent.current.scrollTop = 0;
        }
    }

    const onAddLinesToCart = () => {
        Promise.all(
            availableLines.map((variant) => {
                return new Promise((resolve) => {
                    if(
                        basket !== null && 
                        basket !== undefined
                    ){
                        const basketIdx = basket.findIndex(basketEl => basketEl.variant_id === variant.variant_id);
                        if (basketIdx !== -1) {
                            //if variant is present in basket update it with new qty
                            dispatch(shopActions.updateQuantityInBasket({
                                ...basket[basketIdx],
                                basket: variant.basket //we don't sum here the quantity already in basket, it's done in reducer
                            }, resolve));
                        } else {
                            //if variant is not present in basket add to it
                            dispatch(shopActions.addVariantToBasket({
                                ...variant,
                                basket: variant.basket
                            }, resolve));
                        }
                    } else {
                        resolve();
                    }
                })
            })
        ).then(() => {
            // after adding all elements in the cart
            logAnalyticsEventOfBasketVariantList(
                AnalyticsEvents.ADD_TO_CART,
                availableLines,
                true
            )
            onCloseOrderPanel();
        });
    }

    const onStartOrderCheckout = () => {

        dispatch(shopActions.replaceBasket(availableLines, () => {
            if (typeof onStartCheckout === "function") {
                onStartCheckout();
            }
        }));
    }

    const updateVariantQuantity = (variant, increment) => {
        variant.basket = increment ? variant.basket + 1 : variant.basket - 1;
        const lineIdx = availableLines.findIndex(thisVariant => thisVariant.variant_id === variant.variant_id);
        if (lineIdx !== -1) {
            availableLines[lineIdx] = variant;
        }
        setAvailableLines([...availableLines]);
    }

    const removeVariant = (variant) => {
        const lineIdx = availableLines.findIndex(thisVariant => thisVariant.variant_id === variant.variant_id);
        if (lineIdx !== -1) {
            availableLines.splice(lineIdx, 1);
        }
        setAvailableLines([...availableLines]);
    }

    return (
        <CustomModal
            isOpen={isOpen}
            className={"pdp-shop-modal"}>

            <div className="shop-pdp" style={{ paddingBottom: orderQty > 0 ? '8.125rem' : '5.6875rem' }}>
                <div className="fixed-top">
                    <HealthWarning>{dictionary.HEALTH_WARNING}</HealthWarning>
                    <div className="page-header w-100">
                        <header className="d-flex align-items-center justify-content-center">
                            <button className="page-header-icon" onClick={onCloseOrderPanel}><span className="bat-icon-back"></span></button>
                            <div className="page-header-logo"><img src={imagesConstants.MYVUSE_lOGO} alt="MUSE logo" /></div>
                        </header>
                    </div>
                </div>
                <div className="shop-pdp-wrapper order-detail-wrapper" ref={orderPanelContent}>
                    <div className='order-details-label dark'>
                        <span>{dictionary.SHOP_REORDER_ORDER_LABEL} #{order?.number ?? ""}</span>
                    </div>
                    <div className='order-details-label light pt-3 px-4 pb-1'>
                        <h2>{order?.date && moment(order.date).format('DD/MM/yyyy')}</h2>
                        <h2>{`$${order?.total ?? ""}`}</h2>
                    </div>

                    <React.Fragment>
                        {
                            loading ?
                                <div className="d-flex justify-content-center mt-2">
                                    <div className="spinner-border text-secondary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                :
                                <React.Fragment>
                                    <div className='order-details-label light'>
                                        <span>{dictionary.SHOP_REORDER_ITEMS_AVAILABLE_REORDER_TITLE}</span>
                                    </div>
                                    {
                                        availableLines.map((variant, idx) =>
                                            <ShopCheckoutElement key={`order-line-${idx}`}
                                                variant={{...variant}}
                                                updateVariantQuantity={updateVariantQuantity}
                                                removeVariantFromBasket={removeVariant}
                                            />
                                        )
                                    }
                                    {
                                        unavailableLines.length > 0 &&

                                        <React.Fragment>
                                            <div className='order-details-label danger'>
                                                <span>{dictionary.SHOP_REORDER_UNAVAILABLE}</span>
                                            </div>
                                            {
                                                unavailableLines.map((variant, idx) =>
                                                    <ShopCheckoutElement key={`order-line-${idx}`}
                                                        variant={{...variant}}
                                                        updateVariantQuantity={updateVariantQuantity}
                                                        removeVariantFromBasket={removeVariant}
                                                    />
                                                )
                                            }
                                        </React.Fragment>
                                    }
                                </React.Fragment>

                        }
                    </React.Fragment>



                </div>

                <div className="shop-pdp-actions">
                    {
                        orderQty > 0 &&
                        <div className='row d-flex align-content-between mb-3'>
                            <div className='col-6'>
                                <div className="alert alert-secondary shop-product-tag d-inline" role="alert">
                                    {Utils.stringReplacePlaceholders(dictionary.SHOP_SELECTED_LABEL, [orderQty])}
                                </div>
                            </div>
                            <div className='col-6 text-end'><span className="price">$ {orderTotal}</span></div>
                        </div>
                    }
                    <div className='row d-flex align-items-center g-2'>
                        <div className='col-1' onClick={onOpenBasketPanel}>
                            <Badge color="secondary" badgeContent={basketQty}><span className='bat-icon-cart'></span></Badge>
                        </div>
                        <div className='col-6'><button className="btn btn-success flex-fill text-uppercase w-100"
                            disabled={orderQty <= 0}
                            onClick={onAddLinesToCart}>
                            {dictionary.SHOP_BTN_ADD_TO_CART_0_ctaLabel}
                        </button></div>
                        <div className='col-5'><button className="btn btn-primary flex-fill text-uppercase w-100"
                            disabled={orderQty <= 0}
                            onClick={onStartOrderCheckout}>
                            {dictionary.SHOP_BTN_BUY_NOW_0_ctaLabel}
                        </button></div>
                    </div>
                </div>
            </div>
        </CustomModal>
    );

}
