import cx from "classnames";
import { debounce } from "lodash";
import { bool, node, string } from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import useNavigatorOnline from "use-navigator-online";
import { DashboardContext } from "../../Views";
import { aemPages } from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";

const NetworkConnectionLost = ({ translucent = false, header, icon = "offline" }) => {
  const [dictionary, setDictionary] = useState({});
  const { isOnline, isOffline } = useNavigatorOnline();
  const [beforeWasOffline, setBeforeWasOffline] = useState(isOffline);
  const { navbarHeight = 0 } = useContext(DashboardContext) || {};

  const handleRetry = () => {
    if (isOnline && beforeWasOffline) {
      debounce(() => setBeforeWasOffline(false), 500)();
    }
  };

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.OFFLINE, {
        OFFLINE_HEADER: propertyTextDefaultEmpty,
        OFFLINE_CONTENT: propertyTextDefaultEmpty,
        OFFLINE_BTN_RETRY: propertyCtaItemsDefaultEmpty,
      })
    );

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  useEffect(() => {
    if (isOffline) {
      document.body.classList.add("overflow-hidden");
      setBeforeWasOffline(true);
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isOffline]);

  return (
    (isOffline || (isOnline && beforeWasOffline)) && (
      <div
        className={cx("page w-100 network-connection-lost", {
          translucent,
        })}
        style={{
          bottom: `${navbarHeight}px`,
          ...(!!header && { zIndex: 9998 }),
        }}
      >
        {header}
        <div className="page-wrapper h-100">
          <div className="network-connection-lost-icon">
            <span className={`bat-icon-${icon}`} />
          </div>
          <div className="network-connection-lost-title">
            {dictionary.OFFLINE_HEADER}
          </div>
          <div className="network-connection-lost-content">
            {dictionary.OFFLINE_CONTENT}
          </div>
          <div className="d-grid w-100">
            <button
              className="btn btn-outline-secondary text-uppercase"
              onClick={handleRetry}
            >
              {dictionary.OFFLINE_BTN_RETRY_0_ctaLabel}
            </button>
          </div>
        </div>
        {!translucent && <div className="page-linear-gradient-bg" />}
      </div>
    )
  );
};

NetworkConnectionLost.propTypes = {
  translucent: bool,
  header: node,
  icon: string,
};

export { NetworkConnectionLost };
