import PQueue from "p-queue";
import { AxiosInstanceHolder } from "./axiosInstanceHolder";
import { trace } from "firebase/performance";
import { isNil } from "lodash";

// Create a queue with P-Queue
const queue = new PQueue(); // Concurrency 1 to execute one request at a time

// save and load queue from localstorage
const QUEUE_STORAGE_KEY = 'API_REQUEST_QUEUE';

let _perf = null;

export const saveQueue = (queueItems) => {
    console.log("[PQueue] saveQueue", queueItems);
    try {
        localStorage.setItem(QUEUE_STORAGE_KEY, JSON.stringify(queueItems));
        if(!isNil(_perf)){
            const t = trace(_perf, "api-queue-size");

            // Tracks the number of IDs fetched (the metric could help you to optimize in the future)
            t.incrementMetric("queueSize", queueItems.length);

            // Measures the time it takes to request inventory based on the amount of inventory
            t.start();
            t.stop();
        }
    } catch (error) {
        console.error('Errore nel salvare la coda:', error);
    }
};

export const loadQueue = () => {
    try {
        const storedQueue = localStorage.getItem(QUEUE_STORAGE_KEY);
        console.log("[PQueue] loadQueue", storedQueue);

        return storedQueue ? JSON.parse(storedQueue) : [];
    } catch (error) {
        console.error('Errore nel caricare la coda:', error);
        return [];
    }
};

export const deleteSingleFromQueue = (requestId) => {
    const queueItems = loadQueue();
    const updatedQueue = queueItems.filter(item => item.requestId !== requestId);
    saveQueue(updatedQueue);
}

// load queue requests at app startup
export const initializeQueue = (perf) => {
    
    const client = AxiosInstanceHolder.get();
    const storedRequests = loadQueue();
    console.log("[PQueue] initializeQueue", storedRequests);

    storedRequests.forEach(request => {
        queue.add(() => client(request))
    });

    _perf = perf;
};

// add request to queue and save it in localstorage
export const enqueueRequest = async (requestConfig) => {
    const client = AxiosInstanceHolder.get();

    const queueItems = loadQueue();
    const requestId = requestConfig?.requestId ? requestConfig?.requestId : window.crypto.randomUUID() // generate unique id for the request
    const requestWithId = { ...requestConfig, requestId };
    queueItems.push(requestWithId);
    console.log("[PQueue] enqueueRequest", requestWithId);

    saveQueue(queueItems);
    queue.add(() => client(requestWithId))

    return {data: true}
};


export const clearQueue = () => {
    saveQueue([]);
    queue?.clear();
}