import { onboardingConstants, shopConstants } from '../_constants';
import { REQUEST, SUCCESS, FAILURE } from './action-type.util';
import _ from 'lodash';
import moment from 'moment';
import { OrderModel, SubscriptionModel } from '../_models';

export const initialState = {

    loading: false,
    loadingCatalog: false,
    error: null,
    basket: [],
    wishlist: [],
    catalog: [],
    orders: [],
    subscriptions: []

};

export function shopReducer(state = initialState, action) {

    switch (action.type) {

        case 'LOGOUT':
        case 'RESET_REDUCER': {
            return {
                loading: false,
                error: null,
                basket: [],
                wishlist: [],
                catalog: [],
                orders: [],
                subscriptions: []
            };
        }


        case shopConstants.ADD_VARIANT_IN_WISHLIST: {
            return {
                ...state,
                wishlist: _.uniqBy([...(state.wishlist ?? []), action.data], 'variant_id'),
                persistExpiresAt: moment().subtract(2, 'hours'), //set exp date in the past to force catalog to reload
            }
        }

        case shopConstants.UPDATE_VARIANT_IN_WISHLIST: {

            let newWishlist = state.wishlist;
            const variantIdx = newWishlist.findIndex(el => el.variant_id === action.data.variant_id);
            if (variantIdx !== -1) {
                newWishlist.splice(variantIdx, 1);
            } else {
                newWishlist.push(action.data);
            }

            return {
                ...state,
                wishlist: _.uniqBy([...newWishlist], 'variant_id')
            }
        }

        case shopConstants.RESET_WISHLIST: {
            let newWishlist = [];
            if (
                action.data !== null &&
                action.data !== undefined
            ) {
                newWishlist = action.data;
            }
            return {
                ...state,
                wishlist: newWishlist,
                persistExpiresAt: moment().subtract(2, 'hours'), //set exp date in the past to force catalog to reload
            }
        }

        case SUCCESS(onboardingConstants.GET_USER): {

            let orders = [];
            let subscriptions = [];
            if(action?.payload?.user?.orders){
                orders = (action?.payload?.user?.orders).map(order => new OrderModel(order))
            }
            if(action?.payload?.user?.subscription){
                subscriptions = [new SubscriptionModel(action.payload.user.subscription)];
            }

            return {
                ...state,
                orders: orders,
                subscriptions: subscriptions
            }
        }

        case SUCCESS(shopConstants.GET_CATALOG): {

            return {
                ...state,
                loading: false,
                loadingCatalog: false,
                catalog: action.payload.data,
                persistExpiresAt: moment().add(2, 'hours')
            }
        }

        case REQUEST(shopConstants.GET_CATALOG):
            return {
                ...state,
                loading: true,
                loadingCatalog: true
            };

        case FAILURE(shopConstants.GET_CATALOG):
            return {
                ...state,
                error: action.error,
                loading: false,
                loadingCatalog: false
            };


        case REQUEST(shopConstants.GET_WISHLIST):
        case REQUEST(shopConstants.SET_WISHLIST):
            return {
                ...state,
                loading: true
            };

        case SUCCESS(shopConstants.SET_WISHLIST):
            return {
                ...state,
                wishlist: action.payload.data.data,
                persistExpiresAt: moment().subtract(2, 'hours'), //set exp date in the past to force catalog to reload
                loading: false
            };

        case SUCCESS(shopConstants.GET_WISHLIST):
            return {
                ...state,
                wishlist: action.payload.data.data,
                loading: false
            };

        case FAILURE(shopConstants.SET_WISHLIST):
        case FAILURE(shopConstants.GET_WISHLIST):
            return {
                ...state,
                error: action.error,
                loading: false
            };

        case shopConstants.ADD_TO_BASKET: {
            return {
                ...state,
                basket: [...state.basket, action.data]
            }
        }


        case shopConstants.UPDATE_BASKET_QTY: {
            let newBasket = state.basket;

            const basketIdx = newBasket.findIndex(basketEl => basketEl.variant_id === action?.data?.variant_id);
            if (basketIdx !== -1) {
                newBasket[basketIdx] = {
                    ...action.data,
                    basket: action.data.basket + state.basket[basketIdx].basket
                }
            }

            return {
                ...state,
                basket: [...newBasket]
            }
        }

        case shopConstants.UPDATE_IN_BASKET: {

            let newBasket = state.basket;

            const basketIdx = newBasket.findIndex(basketEl => basketEl.variant_id === action?.data?.variant_id);
            if (basketIdx !== -1) {
                newBasket[basketIdx] = action.data;
            }

            return {
                ...state,
                basket: [...newBasket]
            }
        }

        case shopConstants.DELETE_FROM_BASKET: {

            let newBasket = state.basket;

            const basketIdx = newBasket.findIndex(basketEl => basketEl.variant_id === action?.data?.variant_id);
            if (basketIdx !== -1) {
                newBasket.splice(basketIdx, 1);
            }

            return {
                ...state,
                basket: [...newBasket]
            }
        }

        case shopConstants.REPLACE_BASKET: {
            return {
                ...state,
                basket: [...action.data]
            }
        }

        default:
            return state;
    }
}
