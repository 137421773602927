import React, { useEffect, useState } from "react";
import {
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  HARD_AGE_VERIFICATION_STEPS,
  aemPages,
  imagesConstants,
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { Header } from "../Header";
import { PageSelectorCTA } from "../PageSelectorCTA";
import { getRegistration } from "../../_actions";

export function HardAgeVerificationDocScanFailure({ goToStep }) {
  const [dictionary, setDictionary] = useState({});
  const [overLimit, setOverLimit] = useState(false)

  useEffect(() => {
    const aem = new AEMHelper();

    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
      screen_name: AnalyticsScreenEventNames.YOTI_DOC_SCAN_FAILURE,
    });

    setDictionary(
      aem.getDictionary(aemPages.HARD_AGE_VERIFICATION, {
        YOTI_AV_THIRD_ERROR_TITLE: propertyTextDefaultEmpty,
        YOTI_AV_THIRD_ERROR_DETAILS_CONTENT: propertyTextDefaultEmpty,
        YOTI_AV_THIRD_ERROR_SUPPORT_CONTENT: propertyTextDefaultEmpty,
        YOTI_AV_SUPPORT_NUMBER: propertyTextDefaultEmpty,
        YOTI_AV_SUPPORT_MAIL: propertyTextDefaultEmpty,
        YOTI_BTN_TRY_AGAIN: propertyCtaItemsDefaultEmpty,
        YOTI_AV_THIRD_ERROR_SUPPORT_CONTACT: propertyTextDefaultEmpty,
        YOTI_BTN_GO_BACK: propertyCtaItemsDefaultEmpty,
        YOTI_AV_THIRD_ERROR_RUN_OUT_ATTEMPTS: propertyTextDefaultEmpty
      })
    );

    const registration = getRegistration()

    if (registration?.docScanAttempts) {
      const attempt = registration?.docScanAttempts

      if (attempt >= 3) {
        if (registration?.docScanLast) {
          const lastAttempt = new Date(registration?.docScanLast)
  
          const twoDaysAgo = new Date();
          twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
  
          if (lastAttempt >= twoDaysAgo) {
            setOverLimit(true)
          }
        }
      }
    }
    
  }, []);

  return (
    <div className="page w-100" style={{background: 'none'}}>
      <Header />
      <div className="page-wrapper d-flex flex-column align-items-center h-100 overflow-auto">
        <div className="hard-age-verification-title alt">
          <h1>{dictionary.YOTI_AV_THIRD_ERROR_TITLE}</h1>
        </div>
        <div className="hard-age-verification-failure-desc alt">
          <div>{!overLimit ? dictionary.YOTI_AV_THIRD_ERROR_DETAILS_CONTENT : dictionary.YOTI_AV_THIRD_ERROR_RUN_OUT_ATTEMPTS}</div>
          <div>{dictionary.YOTI_AV_THIRD_ERROR_SUPPORT_CONTACT}</div>
        </div>
        <div className="hard-age-verification-support alt">
          <ul>
            <li>
              <span className="bat-icon-phone me-3" />
              {dictionary.YOTI_AV_SUPPORT_NUMBER}
            </li>
            <li>
              <img
                src={imagesConstants.NEW_EMAIL}
                alt="email icon"
                className="me-3"
                style={{ height: "14px" }}
              />
              {dictionary.YOTI_AV_SUPPORT_MAIL}
            </li>
          </ul>
        </div>
      </div>
      <PageSelectorCTA>
        <div className="d-grid">
          {!overLimit &&
          <button
            className="btn btn-primary text-uppercase"
            onClick={() => {
              logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.RETRY});
              goToStep(HARD_AGE_VERIFICATION_STEPS.DOC_SCAN);
            }}
          >
            {dictionary.YOTI_BTN_TRY_AGAIN_0_ctaLabel}
          </button>
          }
          {overLimit &&
          <button
            className="btn btn-primary text-uppercase"
            onClick={() => {
              logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.GO_BACK});
              goToStep(HARD_AGE_VERIFICATION_STEPS.CONSENT_CA)
            }}
          >
            {dictionary.YOTI_BTN_GO_BACK_0_ctaLabel}
          </button>
          }
        </div>
      </PageSelectorCTA>
    </div>
  );
}
