import React, { useEffect, useState } from 'react';
import StepWizard from 'react-step-wizard';
import { CustomSlidingPanel } from '..';

export const DataProcessPanel = ({
    className,
    overlayClassName,
    steps,
    title,
    isOpen,
    onWizardLoaded
}) => {
    
    const [wizardInstance, setWizardInstance] = useState(null);

    useEffect(() => {
        if (wizardInstance) {
            onWizardLoaded && onWizardLoaded(wizardInstance);
        }
    }, [wizardInstance]);

    return (
        <CustomSlidingPanel from="bottom"
            isOpen={isOpen}
            overlayClassName={overlayClassName || "sliding-common-wrapper"}
            className={className || "onboarding-panel mid-height-panel"}
            backdropClose={false}
            title={title}>

            <div className="h-100 d-grid overflow-scroll">
                <StepWizard
                    instance={(wizardInstance) => setWizardInstance(wizardInstance)}
                    transitions={{
                        enterRight: `animate__animated animate__fadeIn`,
                        enterLeft: `animate__animated animate__fadeIn`,
                        exitRight: `animate__animated animate__fadeOut`,
                        exitLeft: `animate__animated animate__fadeOut`,
                    }}
                    isLazyMount={true}
                    className="h-100"
                >
                    {steps}
                </StepWizard>

            </div>
        </CustomSlidingPanel>
    );
}