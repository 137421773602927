import React, { useState, useEffect, useCallback } from 'react';
import { ProductPDP } from '../../../../_components';
import { ShopUtils } from '../../../../_helpers/shop';
import { AnalyticsEvents, AnalyticsScreenEventNames, imagesConstants } from '../../../../_constants';
import { CustomModal } from '../../../../_components/CustomModal';
import HealthWarning from '../../../../_components/HealthWarning';
import { logAnalyticsEvent } from '../../../../_helpers/analytics/logAnalytics';

export const ShopManageFavourites = ({
    isOpen,
    dictionary,
    wishlist,
    closeFeatureModal,
    onSaveWishlist,
    onOpenBasket,
    onStartCheckout,
    navigateToFlavours
}) => {

    const [thisWishlist, setThisWishlist] = useState([]);
    const [isOpenProductPdp, setIsOpenProductPdp] = useState(false);
    const [currentFlavour, setCurrentFlavour] = useState(null);

    const toggleProductPdp = useCallback((flavour) => {

        setIsOpenProductPdp(!isOpenProductPdp);
        if (isOpenProductPdp) {
            setTimeout(() => {
                setCurrentFlavour(null);
            }, 500);
        } else {
            flavour = ShopUtils.getProductFromId(flavour.product_id);
            setCurrentFlavour({ ...flavour });
        }
    }, [isOpenProductPdp])


    useEffect(() => {

        console.log("[SHOP] wishlist", wishlist);

        let currentWishlist = ShopUtils.getUserWishlist();
        setThisWishlist(wishlist.map(favourite => {
            return ({
                ...favourite,
                favouriteQty: currentWishlist[favourite.variant_id] ?? 1
            })
        }))
    }, [wishlist])

    useEffect(() => {
        if (isOpen) {
            logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
                screen_name: AnalyticsScreenEventNames.MANAGE_FAVOURITES
            });
        }
    }, [isOpen])

    /**
     * Given a variant, update current wishlist with its new quantity. The update becomes effective
     * only when user saves it with click on CTA. 
     * 
     * @param {*} variant the variant which must be updated in wishlist
     * @param {Number} quantity new quantity of variant. If 0, variant is tagged as "removed" to be revoved on 
     * user's preferences saving.
     */
    const handleUpdateQuantityInWishlist = useCallback((variant, quantity) => {
        const variantIdx = thisWishlist.findIndex(thisVariant => thisVariant.variant_id === variant.variant_id);
        if (quantity === 0) {
            //flag it to be removed from wishlist
            //when user will save
            thisWishlist[variantIdx] = {
                ...variant,
                removed: true
            }
        } else {
            //update quantity in wishlist
            if (thisWishlist[variantIdx].removed) {
                thisWishlist[variantIdx] = {
                    ...variant,
                    removed: false
                }
            } else {
                thisWishlist[variantIdx] = {
                    ...variant,
                    favouriteQty: quantity
                }
            }
        }
        setThisWishlist([...thisWishlist]);
    }, [thisWishlist])

    const onSavePreferencesButtonClick = () => {
        onSaveWishlist(thisWishlist)
    }

    return (
        <React.Fragment>
            <CustomModal
                isOpen={isOpen}
                className={"pdp-shop-modal"}>

                <div className="shop-pdp" style={{ paddingBottom: '5.6875rem' }}>
                    <div className="fixed-top">
                        <HealthWarning>{dictionary.HEALTH_WARNING}</HealthWarning>
                        <div className="page-header w-100">
                            <header className="d-flex align-items-center justify-content-center">
                                <button className="page-header-icon" onClick={closeFeatureModal}><span className="bat-icon-back"></span></button>
                                <div className="page-header-logo"><img src={imagesConstants.MYVUSE_lOGO} alt="MUSE logo" /></div>
                            </header>
                        </div>
                    </div>
                    <div className="shop-pdp-wrapper shop-manage-favourites-wrapper">

                        <div className="shop-manage-favourites-image">
                            <img src={`${imagesConstants.SHOP_CATEGORY_FLAVOURS}`} alt="" />
                        </div>

                        <div className='shop-manage-favourites-title'>
                            <div className='row w-100'>
                                <div className='col-12'>
                                    <h2>{dictionary.SHOP_REORDER_MANAGE_FAV_HEADER}</h2>
                                </div>
                            </div>
                        </div>

                        {
                            thisWishlist.length > 0 ?

                                <div className='shop-manage-favourites-list'>
                                    {
                                        thisWishlist.map((variant, idx) =>
                                            <VariantElement variant={variant} key={`variant-el-${idx}`}
                                                onUpdateQuantityInWishlist={handleUpdateQuantityInWishlist}
                                                onElementClick={toggleProductPdp}
                                                dictionary={dictionary}
                                            />
                                        )
                                    }
                                </div>

                                :
                                <div className='shop-manage-favourites-empty'>
                                    <h1>{dictionary.SHOP_REORDER_MANAGE_FAV_ADD_FAV_TITLE}</h1>
                                    <span>{dictionary.SHOP_REORDER_MANAGE_FAV_ADD_FAV_BODY}</span>
                                    <button className='btn-primary btn-sm btn btn-bloclk text-uppercase'
                                        onClick={navigateToFlavours}>{dictionary.SHOP_REORDER_MANAGE_FAV_BTN_VIEW_FLAV_0_ctaLabel}</button>
                                </div>

                        }

                    </div>

                    <div className="shop-pdp-actions">
                        <button className='btn btn-primary text-uppercase w-100 ms-0'
                            disabled={thisWishlist.length <= 0}
                            onClick={onSavePreferencesButtonClick}>{dictionary.SHOP_REORDER_BTN_SAVE_0_ctaLabel}</button>
                    </div>
                </div>
            </CustomModal>

            <ProductPDP isOpen={isOpenProductPdp}
                onClosePdp={toggleProductPdp}
                product={currentFlavour}
                wishlist={thisWishlist}
                onOpenBasket={onOpenBasket}
                onStartCheckout={onStartCheckout}
            />
        </React.Fragment>



    );

}

/**
 * Variant element of list
 * 
 * @param {*} variant 
 * @param {Function} onUpdateQuantityInWishlist 
 * @param {Function} onElementClick 
 * @returns 
 */
const VariantElement = ({ variant, onUpdateQuantityInWishlist, onElementClick, dictionary }) => {

    let product = ShopUtils.getProductFromId(variant.product_id);

    return (
        <div className='shop-manage-favourites-el-wrapper'>
            <div className="shop-product d-flex" >
                <div className="shop-product-img" onClick={() => onElementClick(variant)}
                    style={{
                        ...(product.gradient && product.gradient !== "" && { background: product.gradient })
                    }}>
                    <img src={product?.featured_image} alt="" style={{
                        ...(product.id !== undefined && { width: '4.3594rem' })
                    }} />
                </div>
                <div className="shop-product-description d-flex flex-column" onClick={() => onElementClick(variant)}>
                    <div className="shop-product-title d-flex">{product?.title}</div>
                    <div className="d-flex mt-auto">
                        <span className="shop-pdp-list-item-nic ms-0">{dictionary.SHOP_REORDER_NIC_LABEL} {variant.title}</span>
                    </div>
                </div>
                <div className='shop-product-fav-star'>
                    {
                        !variant.removed ?
                            <span className="bat-icon-star-filled" onClick={() => onUpdateQuantityInWishlist(variant, 0)}></span>
                            :
                            <span className="bat-icon-star-outline" onClick={() => onUpdateQuantityInWishlist(variant, 1)}></span>
                    }
                </div>
            </div>
            <div className='shop-manage-favourites-qty'>
                <span className="shop-pdp-list-item-qty d-flex align-items-center ms-auto">
                    <button className="shop-pdp-list-item-qty-min"
                        disabled={variant.favouriteQty < 2}
                        onClick={() => onUpdateQuantityInWishlist(variant, variant.favouriteQty - 1)}>-</button>
                    <span className="shop-pdp-list-item-qty-num">{variant.favouriteQty}</span>
                    <button className="shop-pdp-list-item-qty-add"
                        onClick={() => onUpdateQuantityInWishlist(variant, variant.favouriteQty + 1)}>+</button>
                </span>
            </div>
        </div>
    )
}