import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { getAEMAssetsUrl } from "../../_actions/appConfig.actions";

const LoyaltyTierIndicator = ({
  currentValue,
  remainingValue,
  tierIcon,
  isLastTier = false,
  title,
  subtitle,
}) => {
  return (
    <div className="loyalty-tier-indicator">
      <CircularProgressbarWithChildren
        value={isLastTier ? 100 : currentValue}
        {...(!isLastTier && {
          maxValue: currentValue + remainingValue,
        })}
      >
        {tierIcon && (
          <img
            className="loyalty-tier-indicator-icon"
            src={getAEMAssetsUrl() + tierIcon}
            alt="Loyalty Tier Icon"
          />
        )}
        <div className="loyalty-tier-indicator-title">{title}</div>
        {!isLastTier && (
          <div className="loyalty-tier-indicator-subtitle">{subtitle}</div>
        )}
      </CircularProgressbarWithChildren>
    </div>
  );
};

export { LoyaltyTierIndicator };
