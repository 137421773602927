import React, { useState } from 'react';
import { CustomSlidingPanel } from '../CustomSlidingPanel';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { AnalyticsEvents, AnalyticsScreenEventNames, AnalyticsTargetEventsNames } from '../../_constants';

export const NeedHelpPanel = (props) => {
    const {
        link,
        body = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae, sed integer fringilla tortor. Eros, neque ipsum aenean diam porta sed sed neque placerat. Commodo id ultricies adipiscing ullamcorper fringilla at vitae. In ultrices fermentum condimentum auctor nunc.',
        title = 'Need help?',
        analyticsPin = false,
        analyticsPair = false 
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const onLinkClick = (...args) => {
        setIsOpen(true);
        let screen_view = localStorage.getItem('screen_view');
        localStorage.setItem('prev_screen_view', screen_view);
        if(analyticsPin){
            logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.FIND_OUT_MORE});
            logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.FIND_OUT_MORE});
        }
        if(analyticsPair){
            logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.PAIRING_BEFORE_WE_START_HELP});
            logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.HELP});
        }
        if (typeof props.onLinkClick === 'function') {
            props.onLinkClick(...args);    
        }
    }

    const handleClose = () => {
        setIsOpen(false);
        if(analyticsPair){
            logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CLOSE});
        }
        let prev_screen_view = localStorage.getItem('prev_screen_view');
        logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: prev_screen_view});
        localStorage.removeItem('prev_screen_view');
    }

    return !!link && (
        <React.Fragment>
            <span className="url-text" onClick={onLinkClick}>{link}</span>

            <CustomSlidingPanel
                from="bottom"
                overlayClassName={"sliding-common-wrapper priority"}
                isOpen={isOpen}
                className="onboarding-panel need-helper-panel"
                title={title}
                onClose={handleClose}
                closeIcon='bat-icon-close'
            >{body}</CustomSlidingPanel>
        </React.Fragment>
    );
}
