import React, { useState } from 'react';
import { ContactPanel } from '..';
import { CustomSlidingPanel } from "../CustomSlidingPanel";
import parse from 'html-react-parser';
import { useEffect } from 'react';
import { getUserData } from '../../_actions';
import { Tenants } from '../../_helpers/tenants';
import { CustomLoadingButton } from '../CustomLoadingButton';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { AnalyticsEvents, AnalyticsFieldNames, AnalyticsScreenEventNames, AnalyticsTargetEventsNames } from '../../_constants';
import { WebviewModal } from '../WebviewModal/WebviewModal';
import { getUrl } from '../../_actions/appConfig.actions';
import { webviewUrl } from '../../_constants';

export const MarketingNotificationsPanel = ({ isOpen, onClose, dictionary }) => {


    const [panelContentRef, setPanelContentRef] = useState(null);
    const [isPreferenceChecked, setIsPreferenceChecked] = useState(false);
    const [isContactPanelOpen, setIsContactPanelOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [isPrivacyPolicyPanelOpen, setIsPrivacyPolicyPanelOpen] = useState(false)

    useEffect(() => {
        setIsPreferenceChecked(getUserData()?.userOptedInForPushNotifications === true);
    }, [])

    const handleClick = () => {
        logAnalyticsEvent(AnalyticsEvents.CLICK,{target: AnalyticsTargetEventsNames.CONTACT_US})
        if (!Tenants.isGlobal()) {
            setIsContactPanelOpen(true);
        }
    };

    const onClick = () => {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.SAVE})
        logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.NOTIFICATION_PREFERENCES_INTRO})
        onClose(isPreferenceChecked)
        setLoading(true)
    }

    const logToggleAnalytics = (value) => {
        logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {
          field_name: AnalyticsFieldNames.NOTIFICATIONS,
          field_value: value ? "true" : "false"
        });
      };

    const openPrivacyPolicy = () => {
        if (Tenants.isSouthAfrica()) {
            window.open(getUrl(webviewUrl.WEBVIEW_MYVUSE_PRIVACY_POLICY))
        } else {
            setIsPrivacyPolicyPanelOpen(true)
        }
    }

    return (
        <React.Fragment>

            <CustomSlidingPanel from="bottom"
                isOpen={isOpen}
                overlayClassName={"sliding-common-wrapper priority"}
                className="onboarding-panel mid-height-panel marketing-panel"
                title={dictionary.NOTIF_PREF_MKT_INFO_HEADER}
                onClose={onClose}
                setContentRef={(ref) => setPanelContentRef(ref)}
                additionalStyles={{
                    marginTop: `calc(100vh - (${panelContentRef?.clientHeight ?? 0}px))`,
                    height: "auto"
                }}
                backdropClose={false}
            >
                <div className="marketing-notification-preferences mt-0">
                    <div className="info-text pb-4 px-4" onClick={handleClick}>
                        {parse(dictionary.NOTIF_PREF_MKT_INFO_CONTENT)}
                    </div>
                    <div>
                        <span className="marketing-check-wrapper">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                    checked={isPreferenceChecked}
                                    value={isPreferenceChecked} id="oldEmailCheck"
                                    name="emailSubscriptionVype" onChange={(event) => {logToggleAnalytics(event.target.checked);setIsPreferenceChecked(event.target.checked)}}
                                />
                                <label className="form-check-label" htmlFor="oldEmailCheck">
                                    {dictionary.NOTIF_PREF_MKT_INFO_CHECKBOX}
                                </label>
                            </div>
                        </span>
                    </div>
                    <div className='info-text py-4 px-4' onClick={() => openPrivacyPolicy()}>
                        {Tenants.isSouthAfrica() ? dictionary.NOTIF_HOW_USE_DATA_PRIVACY : dictionary.NOTIF_PREF_PP_LINK}
                    </div>
                </div>
                <div className='page-selector-cta'>
                    <CustomLoadingButton isLoading={loading}
                        validationMethod={null}
                        buttonLabel={dictionary.NOTIF_PREF_MKT_INFO_BTN_CHOOSE_0_ctaLabel}
                        onClickMethod={onClick} />
                </div>
            </CustomSlidingPanel>

            <ContactPanel isOpen={isContactPanelOpen} onClose={() => setIsContactPanelOpen(!isContactPanelOpen)} />

            <WebviewModal
                webviewId="ppIframe"
                webviewClassName="ppIframe"
                webviewuUrl={getUrl(webviewUrl.WEBVIEW_MYVUSE_PRIVACY_POLICY)}
                handleBack={() => setIsPrivacyPolicyPanelOpen(false)}
                isModalOpen={isPrivacyPolicyPanelOpen}
                modalClassName="webview-modal-priority"
            />
        </React.Fragment>


    );
}