import isNil from "lodash/isNil";
import { commonsActions, getUserData, softResetAppData } from "../../_actions";
import { store } from "../../_store";
import { debug } from "../debug";
import { sendCalculatedData } from "../usage-tracker";
import { removeDbInstance } from "../utils";
import { history } from "../history";
import { buildURI } from "../navigation";
import { routingConstants } from "../../_constants";

export class UserHelper {
  static isUserLoggedInByStoreState(storeState) {
    const {
      onboardingReducer: { userPin },
    } = storeState;

    const userData = getUserData();
    const isUserLoggedIn = !(isNil(userData) || isNil(userPin));
    debug(
      "isUserLoggedIn ",
      isUserLoggedIn,
      ", isNil(userData) ",
      isNil(userData),
      ", isNil(onboardingReducer.userPin) ",
      isNil(userPin)
    );
    return isUserLoggedIn;
  }

  static isUserLoggedIn() {
    const storeState = store.getState();
    return this.isUserLoggedInByStoreState(storeState);
  }

  static logout() {
    sendCalculatedData(true);
    removeDbInstance();
    store.dispatch(commonsActions.logout()).then(() => {
      softResetAppData();
      history.push(buildURI(routingConstants.BENEFITS));
    });
  }
}
