import { imagesConstants } from "../_constants";

class storiesElementModel {

    /**
     * Every story (storyModel) can be either seen or not seen. 
     * If all stories are seen storiesElement is completed, 
     * if at least one story is seen storiesElement is seen
     */
    stories = [];
    title = '';
    icon = imagesConstants.VUSE_WORLD_DEFAULT_DOT;
    seen = false;
    lastIndexSeen = 0;
    completed = false;

    constructor(title, icon) {
        this.title = title;
        this.icon = icon ?? imagesConstants.VUSE_WORLD_DEFAULT_DOT;
    }

    addStory = (data) => {
        this.stories.push(new storyModel(data));
    }

    setStories = (stories) => {
        this.stories = stories;
    }

    setCompleted = (completed = true) => {
        this.completed = completed;
    }

    setSeen = (seen = true) => {
        this.seen = seen;
    }

    setLastIndexSeen = (lastIndexSeen = 0) => {
        this.lastIndexSeen = lastIndexSeen;
    }
}

class storyModel {

    action = null;
    analytics = {};
    dateFrom = null;
    dateTo = null;
    id = null;
    image = null;
    video = null;
    storyType = "image";
    webviewRestricted = false;
    webviewTitle = null;
    link = null;
    ctaLabel = "Click here to see more";

    constructor(data) {

        this.action = data.action;
        this.analytics = data.analytics;
        this.dateFrom = data.dateFrom;
        this.dateTo = data.dateTo;
        this.id = data.id;
        this.image = data.image ?? null;
        this.video = data.video ?? null;
        this.storyType = data.storyType;
        this.webviewRestricted = data.webview_restricted;
        this.webviewTitle = data.webview_title;
        this.link = data.link;
        if(data.analytics && data.analytics["dtm-2"]){
            this.ctaLabel = data.analytics["dtm-2"];
        }
    }
}

export { storiesElementModel };
export { storyModel };
