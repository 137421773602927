import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCallbackState from '../../_hooks/callbackState.hook';
import { commonsActions, getUserData, getVuseUuid, setDoneNotificationPreferences, userActions } from '../../_actions';
import { Header, PageSelectorCTA } from '../../_components';
import { imagesConstants, routingConstants, aemPages, flagStatus, CUSTOMER, thingVuseProperties, AnalyticsEvents, crmUserSettings, AnalyticsScreenEventNames, AnalyticsTargetEventsNames } from '../../_constants';
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyHeadingDefaultEmpty, propertyTextDefaultEmpty, propertyTextDefaultEmptyParsed } from '../../_helpers/aem/aemhelper';
import parse from 'html-react-parser';
import { Tenants } from '../../_helpers/tenants';
import { Commons } from '../../_helpers/commons';
import { logAnalyticsEvent, logThingsInAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { store } from '../../_store';
import { CustomLoadingButton } from '../../_components/CustomLoadingButton';
import { Notifications } from '../../_helpers/notifications';
import { MarketingNotificationsPanel } from '../../_components/MarketingNotificationsPanel/MarketingNotificationsPanel';
import { ThingsHelper } from '../../_helpers/things';
import { NotificationPermissionHandler } from '../../_components/NotificationPermissionPanels/NotificationPermissionHandler';
import { configSalesforce } from '../../_helpers/salesforcce';
 
export const NotificationPreferencesIntro = (props) => {

    const [needToAskUserForNotificationPermission, setNeedToAskUserForNotificationPermission] = useState(false);
    const [isOpenMarketingPanel, setIsOpenMarketingPanel] = useState(false);
    const [callBack, setCallBack] = useCallbackState(null);
    const [preferences, setPreferences] = useCallbackState([]);
    const [loading, setLoading] = useState(false);

    const userPin = useSelector(state => state.onboardingReducer.userPin);
    const loadingReducer = useSelector(state => state.commonsReducer.loading);
    const dispatch = useDispatch();

    let isLoading = loading || loadingReducer;

    const aem = new AEMHelper();
    const dictionary = aem.getDictionary(aemPages.ONBOARD_NOTIF_PREF, {
        NOTIF_PREF_HEADER: propertyHeadingDefaultEmpty,
        NOTIF_PREF_MKT_INFO_BTN_CHOOSE: propertyCtaItemsDefaultEmpty,
        NOTIF_PREF_CONTENT: propertyTextDefaultEmpty,
        NOTIF_PREF_BTN_EN_ALL: propertyCtaItemsDefaultEmpty,
        NOTIF_PREF_BTN_CHOOSE: propertyCtaItemsDefaultEmpty,
        NOTIF_PREF_SKIP_LINK: propertyTextDefaultEmpty,
        NOTIF_PREF_MKT_INFO_CONTENT: propertyTextDefaultEmpty,
        NOTIF_PREF_MKT_INFO_CHECKBOX: propertyTextDefaultEmpty,
        NOTIF_PREF_MKT_INFO_HEADER: propertyHeadingDefaultEmpty,
        NOTIF_PREF_PP_LINK: propertyTextDefaultEmptyParsed,
        NOTIF_HOW_USE_DATA_PRIVACY: propertyTextDefaultEmptyParsed
    });

    const goToNotificationPreferences = () => {
        
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CUSTOMIZE})

        if(Tenants.isCanadaDark()){

            history.push(buildURI(routingConstants.NOTIFICATION_PREFERENCES));

        } else {
            
            setCallBack(
                () => {
                    history.push(buildURI(routingConstants.NOTIFICATION_PREFERENCES));
                }, () => {
                logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.MARKETING_NFORMATON})
                setIsOpenMarketingPanel(true);
            })
        }
    
    }

    const onClickEnableNotificationsLater = () => {
        
        setLoading(true);

        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.DECLINE});

        //update iot platform
        const tenantUserId = Commons.generateTenantUserId(userPin);
        store.dispatch(
            commonsActions.setThings(
                [
                    { type: thingVuseProperties.COMMUNICATION_EMAIL, data: getUserData().userOptedInForEmails === true ? flagStatus.ACCEPTED : flagStatus.REFUSED },
                    { type: thingVuseProperties.COMMUNICATION_NOTIFICATIONS, data: getUserData().userOptedInForPushNotifications === true ? flagStatus.ACCEPTED : flagStatus.REFUSED },
                ], tenantUserId, getVuseUuid(), CUSTOMER)
        )

        setPreferences(
            [],
            preferences => {
                if(ThingsHelper.areAcceptedAnyPushNotificationThings([])){
                    askUserTheNotificationPermission()
                } else {
                    //save only MANAGENOTIFICATION thing property
                    updateAndSavePreferences();
                }
            }
        );

    }

    const onClickEnableAllNotifications = () => {
    
        setLoading(true);

        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.ACCEPT_ALL})

        const availableOptions = Notifications.getAvailableNotificationsPrefsForCurrentOS();
        const preferences = availableOptions.map(option => { return { type: option.command, data: flagStatus.ACCEPTED }; });

        if(Tenants.isCanadaDark()) {
            setPreferences(
                preferences,
                preferences => { askUserTheNotificationPermission(); }
            );
        } else {
            setCallBack(
                () => { askUserTheNotificationPermission(); },
                callBack => {
                    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.MARKETING_NFORMATON});
                    setPreferences(
                        preferences,
                        preferences => { setIsOpenMarketingPanel(true); }
                    )
                }
            );
        }

    }

    const askUserTheNotificationPermission = () => {
    
        setNeedToAskUserForNotificationPermission(true);

    }

    const afterAskingTheUserForNotificationPermissionCallback = () => {
    
        setNeedToAskUserForNotificationPermission(false);
        logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.NOTIFICATION_PREFERENCES})

    }

    const updateAndSavePreferences = () => {
    
        return savePreferences(preferences);
    
    }

    const resetAndSavePreferences = () => {
    
        const resetPreferences = preferences.map(thing => {
            if (ThingsHelper.isAPushNotificationThing(thing)) {
                return {
                    ...thing,
                    data: flagStatus.REFUSED
                };
            } else {
                return thing;
            }
        });

        return new Promise((resolve) => {
            setCallBack(
                null,
                callBack => { resolve(); }
            )
        }).then(() => savePreferences(resetPreferences))
          .then(() => handleNotificationsPreferences(false));

    }

    const savePreferences = (preferences = []) => {

        const things = ThingsHelper.distinctPush(preferences, { type: thingVuseProperties.MANAGENOTIFICATION, data: flagStatus.ACCEPTED });
                    
        const tenantUserId = Commons.generateTenantUserId(userPin);
        
        return new Promise((resolve, reject) => {
            dispatch(commonsActions.setThings(
                things,
                tenantUserId,
                getVuseUuid(),
                CUSTOMER,
                resolve,
                reject
            ));
        }).then((response) => {
            if (response === true) {
                setDoneNotificationPreferences(true);

                setPreferences(preferences);

                configSalesforce();

                Notifications.handleSuggestionNotifications();
                logThingsInAnalyticsEvent(
                    AnalyticsEvents.ENABLE_ALL_NOTIFICATIONS,
                    things
                )
                history.push(buildURI(routingConstants.DASHBOARD));
            } else {
                store.dispatch(commonsActions.showErrorAlert(true));
            }
        }).catch(() => {
            store.dispatch(commonsActions.showErrorAlert(true));
        });
    
    }

    /**
     * Method invoked after user saves it's preference on marketing in-app notification
     * @param {Boolean} preference 
     */
    const handleNotificationsPreferences = (preference) => {
    
        return new Promise((resolve, reject) => {
            dispatch(userActions.updateUserInfo({ "userOptedInForPushNotifications": preference }, resolve, reject))    
        }).then(() => {
            //close panel
            setIsOpenMarketingPanel(false);

            //update crm enrichments
            store.dispatch(userActions.setUserSettings(
                crmUserSettings.OPT_IN_NOTIFICATIONS,
                preference
            ))

            //update iot platform
            const tenantUserId = Commons.generateTenantUserId(store.getState().onboardingReducer.userPin);
            store.dispatch(
                commonsActions.setThings(
                    [
                        { type: thingVuseProperties.COMMUNICATION_EMAIL, data: getUserData().userOptedInForEmails === true ? flagStatus.ACCEPTED : flagStatus.REFUSED },
                        { type: thingVuseProperties.COMMUNICATION_NOTIFICATIONS, data: preference === true ? flagStatus.ACCEPTED : flagStatus.REFUSED },
                    ], tenantUserId, getVuseUuid(), CUSTOMER)
            )

            //handle callback
            if (typeof callBack === "function") {
                callBack();
            }

        }).catch(() => {
            store.dispatch(commonsActions.showErrorAlert(true));
        })
    
    }

    return (
        <div className="page w-100">
            <Header subHeader={dictionary.NOTIF_PREF_HEADER} />
            <div className="notification-preferencas-intro-wrapper mb-4">
                <img src={imagesConstants.NOTIFICATION} alt="news" />
                {parse(dictionary.NOTIF_PREF_CONTENT)}
            </div>

            <PageSelectorCTA sticky={true} ctaUrl={true} className="mt-auto">
                <div className="d-grid gap-2">
                    <CustomLoadingButton isLoading={isLoading}
                        validationMethod={null}
                        buttonLabel={dictionary.NOTIF_PREF_BTN_EN_ALL_0_ctaLabel}
                        onClickMethod={onClickEnableAllNotifications} />
                    <button className="btn btn btn-outline-secondary text-uppercase" onClick={isLoading ? null : goToNotificationPreferences}>{dictionary.NOTIF_PREF_BTN_CHOOSE_0_ctaLabel}</button>
                </div>
                <div className="page-bottom-cta-url">
                    <span className="url-text" onClick={isLoading ? null : onClickEnableNotificationsLater}>{dictionary.NOTIF_PREF_SKIP_LINK}</span>
                </div>
            </PageSelectorCTA>

            <NotificationPermissionHandler
                needToAskUserForNotificationPermission={needToAskUserForNotificationPermission}
                afterAskingTheUserForNotificationPermissionCallback={afterAskingTheUserForNotificationPermissionCallback}
                askAfterUserInteraction={true}
                updateAndSavePreferences={updateAndSavePreferences}
                resetAndSavePreferences={resetAndSavePreferences}
            />

            <MarketingNotificationsPanel isOpen={isOpenMarketingPanel} dictionary={dictionary}
                onClose={handleNotificationsPreferences} />

            <div className="page-linear-gradient-bg" />
        </div>
    );

}