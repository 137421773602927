
export const aemPages = {
    ONBOARDING_START:                   'welcome',
    COUNTRY_SELECTOR:                   'country-selection',
    SOFT_AGE_VERIFICATION:              'age-verification',
    UNDERAGE:                           'age-verification-2',
    HARD_AGE_VERIFICATION:              'yoti',
    YOTI_AGE_VERIFICATION:              'yoti-age-verification',
    YOTI_FAQ:                           'yoti-faq',
    AV_FAILURE:                         'validate-identity',
    REG_LOGIN_CHOOSE:                   'registration',
    LOGIN:                              'login-2',
    RESET_PWD:                          'reset-password',
    REGISTRATION:                       'create-account',
    REGISTRATION_PWD:                   'create-account-2',
    REGISTRATION_DETAIL:                'your-details',
    REGISTRATION_DETAIL_DOB:            'your-details-2',
    REGISTRATION_DETAIL_ADDR:           'your-details-3',
    REGISTRATION_DETAIL_TAC:            'your-details-4',
    REGISTRATION_TAC:                   'terms-and-conditions',
    REGISTARTION_TAC_DEMO:              'terms-and-conditions-demo',
    EDIT_DETAILS:                       'edit-details',

    TAC_AND_PP:                         'tac_and_pp',
    ACCOUNT_DELETION:                   'account-deletion',
    
    VERIFY_ACCOUNT:                     'verify-account',
    ONBOARD_NOTIF_PREF:                 'notification-preferences',
    ONBOARD_NOTIF_DETAIL:               'notification-preferences-2',
    VAULT_FIRST:                        'vault-first',
    VAULT_AFTER:                        'vault-after',
    HOMEPAGE:                           'homepage',
    FIND_MY_VAPE:                       'find_my_vape',
    FIND_MY_VAPE_OPTIN:                 'find_my_vape_optin',
    FIND_MY_VAPE_TUTORIAL:              'find-my-vape-tutorial',
    FIND_MY_VAPE_TUTORIAL_PROSMART:     'find-my-vape-tutorial-prosmart',
    CLOUD_CONTROL:                      'cloud-control',
    CLOUD_CONTROL_TUTORIAL:             'cloud-control-tutorial',
    CLOUD_CONTROL_TUTORIAL_PROSMART:    'cloud-control-tutorial-prosmart',
    BATTERY_SETTINGS:                   'battery-preferences',
    BATTERY_SETTINGS_TUTORIAL:          'battery-tutorial',
    BATTERY_SETTINGS_TUTORIAL_PROSMART: 'battery-tutorial-prosmart',
    DEVICE_SETTINGS:                    'device-settings',
    DEVICE_NAME:                        'device-name',
    FW_UPDATE:                          'fw-update',
    BURGER_MENU:                        'burger_menu',
    MANAGE_FEATURES_AND_PREFERENCES:    'manage_features_and_preferences',
    MANAGE_ACCOUNT:                     'my-account',
    PRIVACY_POLICY:                     'privacy-policy',
    PRIVACY_POLICY_DEMO:                'privacy-policy-demo',
    COOKIE_POLICY:                      'cookie-policy',
    BLUETOOTH_POPUP:                    'bluetooth-popup',
    REMOVE_DEVICE:                      'remove-device',
    RESET_DEVICE:                       'reset-device',
    NOTIFICATION_PREFERENCES_MENU:      'notification-preferences-menu',
    CUSTOMIZE:                          'customize',
    UNLOCK_SCREEN:                      'unlock-screen',
    PAIRING_FAILURE:                    'pairing-failure',
    VUSE_WORLD:                         'vuse-world',
    SELECT_YOUR_DEVICE:                 'select-your-device',
    PAIRING:                            'pairing-new',
    MANAGE_DEVICES:                     'manage-devices',
    MAX_DEVICES_PAIRED:                 'maximum-devices-paired',
    LOYALTY_OPTIN:                      'Loyalty-optin',
    LOYALTY:                            'Loyalty',
    STORE_LOCATOR:                      'store-locator',

    SHOP:                               'shop',
    SHOP_SUBSCRIPTIONS:                 'shop-subscriptions',
    FAVOURITES_OPTIN:                   'favourites-optin',
    FLAVOUR_FOR_YOU_OPTIN:              'flavour-for-you-optin',

    NOTIFICATION_LIST:                  'notification-list',
    IOS_MKT_NOTIFICATIONS:              'IOS-mkt-notifications',

    PROSMART:                           '-prosmart',

    USAGE_TRACKER:                      'usage-tracker',
    USAGE_TRACKER_TUTORIAL:             'usage-tracker-tutorial',
    USAGE_TRACKER_TUTORIAL_PROSMART:    'usage-tracker-tutorial-prosmart',
    USAGE_TRACKER_OPTIN:                'usage-tracker-optin',
    
    LOCK_SWITCH_TUTORIAL:               'lock-unlock-tutorial',
    LOCK_SWITCH_TUTORIAL_PROSMART:      'lock-unlock-tutorial-prosmart',

    MSS:                                'mss',
    OFFLINE:                            'offline',

    DEVICE_FAQ:                         'device-faq'
    /*
    LOGIN:                      '
    SIGN_UP:                    '
    RESET_PASSWORD:             '
    TERMS_AND_CONDITIONS:       '
    NOTIFICATION_INTRO:         '
    NOTIFICATION_PREFERENCES:   '
    SIGNUP_FAILED:              '
    DASHBOARD:                  '*/
}

export const aemComponents = {
    PROSMART:                           '_PROSMART',
};

export const aemConstants = {
    SET_DEBUG: '(AEM) SET_DEBUG',
    SET_LANGUAGES: '(AEM) SET_LANGUAGES',
    ADD_LANGUAGE: '(AEM) ADD_LANGUAGE',
    SET_LOCALE: '(AEM) SET_LOCALE',
    SET_PAGE: '(AEM) SET_PAGE',
    SET_COMPONENT: '(AEM) SET_COMPONENT',
    SET_CURRENT_LANGUAGE: '(AEM) SET_CURRENT_LANGUAGE',
    SET_CURRENT_COUNTRY: '(AEM) SET_CURRENT_COUNTRY',
};