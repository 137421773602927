import React, { useEffect, useState } from "react";
import {
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  HARD_AGE_VERIFICATION_STEPS,
  aemPages
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import { PageSelectorCTA } from "../PageSelectorCTA";
import { Webview } from "../../_helpers/webview";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";

export function HardAgeVerificationInfo({ nextStep, goToStep }) {
  const [dictionary, setDictionary] = useState({});

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.YOTI_INTRO})
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.YOTI_AGE_VERIFICATION, {
        YOTI_AV_CONTENT: propertyTextDefaultEmpty,
        YOTI_AV_SUBCONTENT: propertyTextDefaultEmpty,
        YOTI_AV_LINK_PP: propertyTextDefaultEmptyParsed,
        YOTI_AV_LINK_FAQ: propertyTextDefaultEmptyParsed,
        YOTI_AV_BTN_NEXT: propertyCtaItemsDefaultEmpty
      })
    );
  }, []);

  return (
    <div className="page w-100 overflow-hidden" style={{background: 'none'}}>
      <div className="page-wrapper d-flex flex-column h-100 overflow-auto">
        <div className="d-flex flex-column justify-content-between flex-grow-1">
          <div className="hard-age-verification-title">
            <p className="info">{dictionary.YOTI_AV_CONTENT}</p>
            <p className="info">{dictionary.YOTI_AV_SUBCONTENT}</p>
          </div>
          
          <div className="hard-age-verification-info-bottom">
            <div onClick={() => Webview.goToMyVusePrivacyPolicy(false, true)}>{dictionary.YOTI_AV_LINK_PP}</div>
            <div onClick={() => {goToStep(HARD_AGE_VERIFICATION_STEPS.FAQ); logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.FAQ})}}>{dictionary.YOTI_AV_LINK_FAQ}</div>
          </div>
        </div>
        <PageSelectorCTA>
          <div className="d-grid">
            <button
              className="btn btn-primary text-uppercase"
              onClick={() => {
                //logAnalyticsEvent(AnalyticsEvents.YOTI_CONSENT_FACE_SCAN);
                goToStep(HARD_AGE_VERIFICATION_STEPS.CONSENT_CA);
                logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NEXT})
              }}
            >
              {dictionary.YOTI_AV_BTN_NEXT_0_ctaLabel}
            </button>
          </div>
        </PageSelectorCTA>
      </div>
    </div>
  );
}