import cx from "classnames";
import React from "react";
import { FormattedNumber } from "react-intl";

const LoyaltyStats = ({ className, title, value, currency }) => {
  return (
    <div className={cx("loyalty-stats", className)}>
      <div className="loyalty-stats-title">{title}</div>
      <div className="loyalty-stats-value">
        <FormattedNumber
          value={value}
          {...(currency && {
            style: "currency",
            currency,
          })}
        />
      </div>
    </div>
  );
};

export { LoyaltyStats };
