import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSupportedDevices } from "../../_actions/appConfig.actions";
import { deviceActions } from "../../_actions/device.actions";
import { Header } from "../../_components";
import {
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  PROFILE_EPOD2,
  PROFILE_EPOD3,
  PROFILE_WAWE2,
  aemPages,
  routingConstants,
} from "../../_constants";
import {
  AEMHelper,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { getDeviceSelectionImg } from "../../_helpers/device/assets";
import { buildURI } from "../../_helpers/navigation";
import { handleDeviceSelection } from "../../_helpers/device";

function SelectDevice({ history }) {
  const [devices] = useState(getSupportedDevices());
  const [dictionary, setDictionary] = useState({});

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
      screen_name: AnalyticsScreenEventNames.PAIRING_SELECT_DEVICE_TYPE,
    });

    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.SELECT_YOUR_DEVICE, {
        SELECT_YOUR_DEVICE_TITLE: propertyTextDefaultEmpty,
        SELECT_YOUR_DEVICE_ADD_NEW_DEVICE: propertyTextDefaultEmpty,
      })
    );
  }, []);

  const handleProductFamilyClick = (profile) => {
    const deviceProfile = profile === PROFILE_WAWE2 ? PROFILE_EPOD2 : profile;
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: deviceProfile});
    handleDeviceSelection(profile);
    history.push(buildURI(routingConstants.PAIR_DEVICE));
  };

  const handleGoBack = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CLOSE})
    if (history?.location?.state?.backTo) {
      history.push(buildURI(history.location.state.backTo), {type: 'slide-left'});
    } else {
      history.push(buildURI(routingConstants.DASHBOARD));
    }
  };

  return (
    <div className="page w-100 select-device-page">
      <Header
        rightButton={{
          icon: <span className="bat-icon-close" />,
          onClick: handleGoBack,
        }}
      >
        {dictionary.SELECT_YOUR_DEVICE_ADD_NEW_DEVICE}
      </Header>
      <div className="select-device-page-title">
        <h1>{dictionary.SELECT_YOUR_DEVICE_TITLE}</h1>
      </div>
      <div className="h-100 av-devices">
        <ul className="d-flex flex-column gap-3 p-0 m-0">
          {devices.map((device, i) => {
            if (device.profile === 'SmartBox') device.profile = PROFILE_EPOD3
            const bg = getDeviceSelectionImg(
              device?.profile === PROFILE_WAWE2
                ? PROFILE_EPOD2
                : device?.profile
            );

            return (
              <li
                className="d-flex align-items-end p-3"
                style={{
                  backgroundImage: `url(${bg})`,
                }}
                key={i}
                onClick={() => handleProductFamilyClick(device.profile)}
              >
                <div className="device-name">{device.label}</div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setDeviceProfile: (profile) =>
    dispatch(deviceActions.setDeviceProfile(profile)),
});

const connectedComponent = connect(null, mapDispatchToProps)(SelectDevice);
export { connectedComponent as SelectDevice };
