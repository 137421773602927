import React from 'react';
import { buildURI } from '../../_helpers/navigation';
import {
    routingConstants
} from '../../_constants';
import {
    resetAppData
} from '../../_actions/appData.actions';
import { connect } from 'react-redux';
import { resetAppConfig } from '../../_actions/appConfig.actions';
import { store } from '../../_store';
import { commonsActions, deviceActions } from '../../_actions';

class ClearData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}


    }

    componentDidMount() {
        store.dispatch(commonsActions.logout()).finally(() => {
            resetAppData();
            resetAppConfig();

            store.dispatch(deviceActions.resetDevices());
            store.dispatch(commonsActions.resetReducers());
            window.deviceList = [];
            this.props.history.push(buildURI(routingConstants.COUNTRY_SELECTOR));
        })
    }

    componentWillUnmount() { }

    render() {

        return (
            <div>All clean!</div>
        );

    }
}


function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

const connectedClearData = connect(mapStateToProps, mapDispatchToProps)(ClearData);
export { connectedClearData as ClearData };