import React from 'react';
import { AdditionalIntroductionStep, ConclusionStep, DataProcessPanel, InstructionsStep, IntroductionStep, ProgressStep } from "../../_components";
import { aemPages, AnalyticsEvents, AnalyticsScreenEventNames, CUSTOMER, DevicePropertyPlaceholder, imagesConstants, thingVuseProperties, servicesConstants, AnalyticsTargetEventsNames } from "../../_constants";
import { store } from "../../_store";
import { Commons } from "../../_helpers/commons";
import { commonsActions, deviceActions, getVuseUuid } from "../../_actions";
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyHeadingDefaultEmpty, propertyTextDefaultEmpty, propertyTextDefaultEmptyParsed } from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent, logAnalyticsEventForDevice } from "../../_helpers/analytics/logAnalytics";
import { getDeviceThingFromSN } from "../../_helpers/device";
import { Utils } from '../../_helpers/graphic/utils';
import { extractUniqueTypesAndConcatenate } from "../../_helpers/utils";
import { isSupportedService } from "../../_actions/appConfig.actions";


class RemoveDevice extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            progressPercentage: 0,
            deleteData: false
        }

        this.doRemoval = this._doRemoval.bind(this);
        this.onProcessFinish = this._onProcessFinish.bind(this);
        this.deleteData = this._deleteData.bind(this);

        this.aem = new AEMHelper();
        this.dictionary = this.aem.getDictionary(aemPages.REMOVE_DEVICE, {
            REMOVE_DEVICE_CONTENT: propertyTextDefaultEmptyParsed,
            REMOVE_DEVICE_LIST_FIRST: propertyTextDefaultEmptyParsed,
            REMOVE_DEVICE_BTN_CANCEL: propertyCtaItemsDefaultEmpty,
            REMOVE_DEVICE_ALERT_TITLE: propertyTextDefaultEmpty,
            REMOVE_DEVICE_ALERT_BODY: propertyTextDefaultEmpty,
            REMOVE_DEVICE_LIST_FOURTH: propertyTextDefaultEmptyParsed,
            REMOVE_DEVICE_CONFIRM_CONTENT: propertyTextDefaultEmpty,
            REMOVE_DEVICE_HEADER: propertyHeadingDefaultEmpty,
            REMOVE_DEVICE_BTN_DELETE: propertyCtaItemsDefaultEmpty,
            REMOVE_DEVICE_REMOVING_CONTENT: propertyTextDefaultEmptyParsed,
            REMOVE_DEVICE_BTN_UNPAIR: propertyCtaItemsDefaultEmpty,
            REMOVE_DEVICE_LIST_THIRD: propertyTextDefaultEmptyParsed,
            REMOVE_DEVICE_BTN_FINISH: propertyCtaItemsDefaultEmpty,
            REMOVE_DEVICE_COMPLETE: propertyTextDefaultEmpty,
            REMOVE_DEVICE_CHECKBOX: propertyTextDefaultEmpty,
            REMOVE_DEVICE_BTN_CONTINUE: propertyCtaItemsDefaultEmpty,
            REMOVE_DEVICE_NOTICE: propertyTextDefaultEmpty,
            REMOVE_DEVICE_LIST_SECOND: propertyTextDefaultEmptyParsed,
            REMOVE_DEVICE_BTN_SURE: propertyCtaItemsDefaultEmpty,
            REMOVE_DEVICE_BODY: propertyTextDefaultEmpty,
            REMOVE_DEVICE_CONFIRM_TITLE: propertyTextDefaultEmptyParsed,
            REMOVE_DEVICE_ASAV_ALERT_TITLE: propertyTextDefaultEmpty,
            REMOVE_DEVICE_ASAV_ALERT_BODY: propertyTextDefaultEmpty,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.isOpen && !prevProps.isOpen) {
            logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
                screen_name: AnalyticsScreenEventNames.REMOVE_DEVICE
            });
        }
    }

    _doRemoval(thisProps) {

        const { device, devices } = this.props;
        const { deleteData } = this.state;
    
        thisProps.next();

        setTimeout(() => {
            const deviceSN = device?.serialNumber;
            //1. remove device from reducer
            store.dispatch(deviceActions.removeDevice(deviceSN));

            const tenantUserId = Commons.generateTenantUserId(store.getState().onboardingReducer.userPin);

            if (deleteData) {
                
                this.setState({ progressPercentage: 50 }, () => {
                    //2. delete THING from BE
                    const deviceThing = getDeviceThingFromSN(deviceSN);

                    store.dispatch(commonsActions.deleteThing(tenantUserId, deviceThing?.uuid, () => {
                        this.setState({ progressPercentage: 100 });
                    }, () => {
                        store.dispatch(commonsActions.showErrorAlert(true));
                    }))
                });
            } else {
                setTimeout(() => {
                    this.setState({ progressPercentage: 100 });
                }, 1000);
            }

            if(isSupportedService(servicesConstants.QUALTRICS)){
                if (window?.dataLayer?.length > 0) {
                    console.log("[Qualtrics] paired_devices_counter: ", devices?.length - 1);
                    window.dataLayer.push({'paired_devices_counter': devices?.length - 1});
                    const devs = [
                        ...devices.filter(
                          (dev) => dev.serialNumber !== device?.serialNumber
                        ),
                      ];

                    console.log("[Qualtrics] deviceModels: ", devs?.length > 0 ? extractUniqueTypesAndConcatenate(devs) : '');
                    window.dataLayer.push({'deviceModels': devs?.length > 0 ? extractUniqueTypesAndConcatenate(devs) : ''});
                }
            }

            //extra: remove device type from customer thing
            store.dispatch(
                commonsActions.setThings(
                [{ type: thingVuseProperties.MKT_DEVICETYPE, data: "NO" }],
                tenantUserId,
                getVuseUuid(),
                CUSTOMER
            ));

        }, 1000);
    }


    _deleteData(thisProps) {
        this.setState({ deleteData: true }, () => {
            this.doRemoval(thisProps);
        })
    }

    _onProcessFinish(processEnded) {
        this.props.handleProcessEnd(processEnded);
    }

    onIntroductionStepStartProcess(thisProps) {
        // logAnalyticsEvent(AnalyticsEvents.I_AM_SURE_REMOVE_DEVICE);
        logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.REMOVE_DEVICE_DATA})
        thisProps.next();
    }

    onIntroductionStepCancelProcess() {
        // logAnalyticsEvent(AnalyticsEvents.CANCEL_REMOVE_DEVICE);
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CANCEL})
        this.onProcessFinish(false);
        logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.DEVICE_SETTINGS})
    }

    onAdditionalIntroductionStepConfirmProcess(thisProps) {
        // logAnalyticsEvent(AnalyticsEvents.DELETE_PREFS_REMOVE_DEVICE);
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CONFIRM})
        this.deleteData(thisProps)
    }

    onAdditionalIntroductionStepCancelProcess(thisProps) {
        // logAnalyticsEvent(AnalyticsEvents.SKIP_PREFS_REMOVE_DEVICE);
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.KEEP})
        this.doRemoval(thisProps)
    }

    onInstructionsStepConfirmInstructionsProcess(thisProps) {
        logAnalyticsEvent(AnalyticsEvents.SUCCESSFULLY_REMOVE_DEVICE);
        thisProps.next();
    }

    onConclusionStepEndProcess() {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CLOSE})
        this.onProcessFinish(true);
    }
    

    render() {

        const { isOpen } = this.props;
        const { progressPercentage } = this.state;
        const { device } = this.props;

        return (
            <DataProcessPanel title={this.dictionary.REMOVE_DEVICE_HEADER} isOpen={isOpen}
                steps={[
                    <IntroductionStep
                        key="intro"
                        title={Utils.stringReplacePlaceholdersFromConstants(this.dictionary.REMOVE_DEVICE_CONTENT, {
                            [DevicePropertyPlaceholder.DEVICE_NAME]: device?.deviceCustomName
                        })}
                        okButtonLabel={this.dictionary.REMOVE_DEVICE_BTN_SURE_0_ctaLabel}
                        cancelButtonLabel={this.dictionary.REMOVE_DEVICE_BTN_CANCEL_0_ctaLabel}
                        isOpenAlert={true}
                        alertContent={(
                            <>
                                <p className="mb-1"><b>{this.dictionary.REMOVE_DEVICE_ALERT_TITLE}</b></p>
                                {this.dictionary.REMOVE_DEVICE_ALERT_BODY}
                            </>
                        )}    
                        onStartProcess={(thisProps) => this.onIntroductionStepStartProcess(thisProps)}
                        onCancelProcess={() => this.onIntroductionStepCancelProcess()}
                    />
                    ,
                    <AdditionalIntroductionStep
                        key="additional-introduction"
                        title={this.dictionary.REMOVE_DEVICE_BODY}
                        okButtonLabel={this.dictionary.REMOVE_DEVICE_BTN_DELETE_0_ctaLabel}
                        cancelButtonLabel={this.dictionary.REMOVE_DEVICE_BTN_UNPAIR_0_ctaLabel}
                        introductionImage={<span className="bat-icon-trash"></span>}
                        onConfirmProcess={(thisProps) => this.onAdditionalIntroductionStepConfirmProcess(thisProps)}
                        onCancelProcess={(thisProps) => this.onAdditionalIntroductionStepCancelProcess(thisProps)}
                        onEndProcess={() => this.onIntroductionStepCancelProcess()}
                        endButtonLabel={this.dictionary.REMOVE_DEVICE_BTN_CANCEL_0_ctaLabel}
                    />
                    ,
                    <ProgressStep
                        key="progress"
                        from="remove"
                        title={Utils.stringReplacePlaceholdersFromConstants(this.dictionary.REMOVE_DEVICE_REMOVING_CONTENT, {
                            [DevicePropertyPlaceholder.DEVICE_NAME]: device?.deviceCustomName
                        })}
                        processCompleteLabel={this.dictionary.REMOVE_DEVICE_COMPLETE}
                        progressPerc={progressPercentage}
                        progressImage={progressPercentage === 100 ? <span className="process-end-icon"></span> : <span className="process-removal"></span>}
                    />
                    ,
                    <InstructionsStep
                        key="instuctions"
                        from="remove"
                        title={this.dictionary.REMOVE_DEVICE_NOTICE}
                        okButtonLabel={this.dictionary.REMOVE_DEVICE_BTN_CONTINUE_0_ctaLabel}
                        onConfirmInstructionsProcess={(thisProps) => this.onInstructionsStepConfirmInstructionsProcess(thisProps)}
                        checkboxLabel={this.dictionary.REMOVE_DEVICE_CHECKBOX}
                        instructions={
                            <div className="instructions-container">
                                <ol>
                                    <li><img src={imagesConstants.SYSTEM_ICON} alt="Settings logo" />{this.dictionary.REMOVE_DEVICE_LIST_FIRST}</li>
                                    <li><img src={imagesConstants.BLUETOOTH_ICON} alt="Bluetooth logo" />{this.dictionary.REMOVE_DEVICE_LIST_SECOND}</li>
                                    <li><span className="bat-icon-search"></span>{this.dictionary.REMOVE_DEVICE_LIST_THIRD}</li>
                                    <li><span className="bat-icon-info-circle"></span>{this.dictionary.REMOVE_DEVICE_LIST_FOURTH}</li>
                                </ol>
                            </div>
                        }
                    />
                    ,
                    <ConclusionStep
                        key="end"
                        from="remove"
                        title={this.dictionary.REMOVE_DEVICE_CONFIRM_TITLE}
                        description={this.dictionary.REMOVE_DEVICE_CONFIRM_CONTENT}
                        endButtonLabel={this.dictionary.REMOVE_DEVICE_BTN_FINISH_0_ctaLabel}
                        isOpenAlert={false}
                        alertContent={null}
                        image={<span className="process-end-icon"></span>}
                        onEndProcess={() => this.onConclusionStepEndProcess()}
                    />,
                ]
                } />
        )
    }
}


export { RemoveDevice };