import { PERMISSIONS_ACTION_TYPE, PERMISSION_STATUS } from '../_constants';

export const initialState = {
    hasUserAlreadyReplyForNotificationPermission: false,
    notificationPermissionStatus: null,
    OSNotificationPermissionStatus: PERMISSION_STATUS.DEFAULT
};

export function permissionsReducer(state = initialState, action) {
    switch (action.type) {

        case PERMISSIONS_ACTION_TYPE.SET_NOTIFICATION_PERMISSION_STATUS:
            return {
                ...state,
                hasUserAlreadyReplyForNotificationPermission: true,
                notificationPermissionStatus: action.data
            };

        case PERMISSIONS_ACTION_TYPE.SET_OS_NOTIFICATION_PERMISSION_STATUS:
            return {
                ...state,
                OSNotificationPermissionStatus: action.data
            };

            
        case PERMISSIONS_ACTION_TYPE.RESET_NOTIFICATION_PERMISSION_STATUS:
            return {
                hasUserAlreadyReplyForNotificationPermission: false,
                notificationPermissionStatus: null,
            };

        default:
            return state;
    }

}